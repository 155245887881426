import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
//import PreFinalRecomendationCreate from '../Maintenance/Modal/PreFinalRecomendationCreate';

 
class PreFinalRecomendation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            trainingRegisterTableList           :   [],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalTrainingRegisterShow           :   false,

            category:"",
            caseTypeList:[],
            selectedCasesType:[],
            preFinalDDL:[],
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetDataPreFinal()
    }

    GetDataPreFinal = async()=> {

        const dataParams = {

            "IpAddress" :   "0.0.0.0",
            "UserId"    :   this.state.userinfo.userId,
            "CompanyId" :   this.state.userinfo.companyId,
            "Name":""
        };
            
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRecommendations",  dataParams)
        .then(res => {
            const data = res.data;
            console.log("Get pre final");
            console.log(data);
            this.setState({ preFinalDDL  : data.recommendations});
        })
        
    } 
    
    handleSearchClick =(e)=> {

        const dataParams = {

            "IpAddress" :   "0.0.0.0",
            "UserId"    :   this.state.userinfo.userId,
            "CompanyId" :   this.state.userinfo.companyId,
            "Name"      :   this.state.selectedCasesType
        };
        console.log(dataParams)
            
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRecommendations",  dataParams)
        .then(res => {
            const data = res.data;
            console.log("Get pre final");
            console.log(data);
            this.setState({ preFinalDDL  : data.recommendations});
        })
        
    } 

    showTrainingRegisterModal = (e) => {
        this.setState({modalTrainingRegisterShow: true})
    }
    handleModalClose = (e) =>{
        //reload or refresh data here
        //alert("Data pass from child : " + e)
        this.setState({modalTrainingRegisterShow: false})
    }


    GridDataModified(oldValue, newValue, id, column) {
        ////console.log(id)
        this.state.trainingScheduleTableList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    onChangePreFinalName = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedCasesType     :   '',
            })
            return
        }
        this.state.selectedCasesType = e[0].name    
               
        this.setState({
            isshow              : false,
            selectedCasesType   : e[0].name,
            //isloading           : true,

        })
        this.loadScreenFalse();
       
    }
    loadScreenFalse=()=>{
        this.setState({
            isloading:false,
        })
    }

    render() {
        
        const trainingRegisterColumn = [
            {
                dataField   : 'name',
                text        : 'name',
                headerStyle : () => {
                    return { width  : "100%" };
                }
            },
          /*   {
                dataField   : 'code',
                text        : 'code',
                headerStyle : () => {
                    return { width  : "50%" };
                }
            }, */
            /* {
                dataField: 'training',
                text: 'Training',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'position',
                text        : 'Position',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'client',
                text        : 'Client',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'register',
                text        : 'Register',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'attended',
                text        : 'Attended',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.trainingRegisterTableList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>Maintenance >> Pre Final Recomendation </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                       <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Name
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangePreFinalName}
                                                options={this.state.preFinalDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                      {/*   <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Case Name
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeTraining}
                                                options={this.state.trainingAutocomplete}
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Category
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeTraining}
                                                options={this.state.trainingAutocomplete}
                                            />
                                        </Col> */}
                                    </Form.Group>
                                    
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                    
                                    </Form.Group>
                                   

                                    <ButtonToolbar className="mt-1">
                                        <Button variant="success" className="ml-auto" onClick={ this.handleSearchClick }>
                                            Search
                                        </Button>&nbsp;&nbsp;
                                        <NavLink to="/PreFinalRecomendationCreate">
                                            <Button variant="primary" href="/PreFinalRecomendationCreate" style={{minWidth:'60px'}}>
                                                Create
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>

                                    <Card.Header className="mt-3">List Type of Case</Card.Header>
                                    <div className="mt-1">
                                        <BootstrapTable
                                            /* caption={Noser.TableHeader({title:"RECORD"})} */
                                            keyField = "id"
                                            data = { this.state.preFinalDDL }
                                            columns = { trainingRegisterColumn }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                            selectRow = { selectRow }

                                        />
                                    </div>
                                    <ButtonToolbar sm={12}>
                                       {/*  <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleUpdateClick}>
                                            Generate Report
                                        </Button>&nbsp;&nbsp; */}
                                        <NavLink to="/home" className="ml-auto">
                                            <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                Close
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                   
            </div> 
        )
    }

}

export  default PreFinalRecomendation
