import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../../noser-hris-component';
import {CipherString} from '../../../noser-sec'
import CreateUser from './CreateUser'
import CreateUserClient from './CreateUserClient';
class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            disableSaveBtn  :   true,
            clientId        :   '',
            employeeId      :   '',
            clientList      :   [],
            employeeList    :   [],
            roleList        :   [],
            userList        :   [],
            userStatusList  :   []
        }
    } 
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetRoles()
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child1.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        if(this.state.clientId!==""){
            this.GetEmployees()
        }
        this.handleSearch(e)
    }

    handleModalClientShow = (e) =>{
        this.setState({openModalClient:true})
        let obj = {}
        this.child2.initialize(e)
    }
    handleModalClientClose = (e) =>{
        this.setState({openModalClient:false})
        if(this.state.clientId!==""){
            this.GetEmployees()
        }
        this.handleSearch(e)
    }
    // GetClients = async() =>{
    //     this.setState({isloading:true,isDisable:true})
    //     const params = {
    //         "IpAddress":"0.0.0.0",
    //         "CompanyId":this.state.userinfo.companyId,
    //         "UserId":this.state.userinfo.userId,
    //     }
        
    //     await axios
    //     .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
    //     .then(res => {
    //         const data = res.data
    //         this.setState({
    //             isloading   :   false,
    //             alerttype   :   data.status=="1" ? "Success!" : "Error!",
    //             isshow      :   data.status=="1" ? false : true,
    //             color       :   data.status=="1" ? "success" : "danger",
    //             message     :   data.message,
    //             fade        :   true,
    //             clientList :   data.clients,
    //         });
    //         this.GetRoles()
    //     })
    //     .catch(error=>{
    //         this.setState({
    //             isloading   :   false,
    //             alerttype   :   "Error!",
    //             isshow      :   true,
    //             color       :   "danger",
    //             message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
    //             fade        :   true,
    //         })
    //     })
    // }
    GetEmployees = () =>{
        this.setState({isloading:true,isDisable:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId
        }
        
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetConfigurationEmployees", params)
        .then(res => {
            const data = res.data
            //console.loglog(data)
            this.setState({
                isloading   :   false,
                alerttype   :   data.status=="1" ? "Success!" : "Error!",
                isshow      :   data.status=="1" ? false : true,
                color       :   data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                employeeList :   data.employees,
            });

        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    GetRoles = async() =>{
        this.setState({isloading:true,isDisable:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetRoles", params)
        .then(res => {
            const data = res.data
            let roles = []
            data.roles.map(function(itm){
                roles.push({
                    "value" : itm.id,
                    "label" : itm.name
                })
            })
            this.setState({roleList:roles});
            this.GetUserStatus()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    GetUserStatus = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "Code":"0023"
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences", params)
        .then(res => {
            const data = res.data
            let userStatus = []
            data.dataReferences.map(function(itm){
                userStatus.push({
                    "value" : itm.id,
                    "label" : itm.name
                })
            })
            this.setState({
                alerttype   :   data.status=="1" ? "Success!" : "Error!",
                isshow      :   data.status=="1" ? false : true,
                color       :   data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                userStatusList:userStatus
            });
            this.GetEmployees()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    handleChangeClient = (e) =>{
        this.setState({
            alerttype   :   "",
            isshow      :   false,
            color       :   "",
            message     :   "",
            fade        :   false
        });
        if(e.length==0)
        {
            this.state.clientId=''
            this.state.clientName=''
            return
        }
        this.state.clientId= e[0].id
        this.state.clientName=e[0].name
        this.GetEmployees()
    }
    handleChangeEmployee = (e) =>{
        this.setState({
            alerttype   :   "",
            isshow      :   false,
            color       :   "",
            message     :   "",
            fade        :   false
        });
        if(e.length==0)
        {
            this.state.employeeId= ''
            return
        }
        this.state.employeeId= e[0].id
    }
    handleSearch = (e) =>{
        this.setState({isloading:true,isDisable:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.employeeId,
        }
        
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetUsers", params)
        .then(res => {
            const data = res.data
            console.log(data)
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    userList    :   data.users
                });
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    userList    :   data.users
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    handleSubmit = (e) => {
        this.setState({isloading:true,isDisable:true,disableSaveBtn:true})

        let users = []
        this.state.userList.filter(x=>x.isModified==="1").map(function(itm){
            users.push({
                "Id":itm.id,
                "RoleId":itm.roleId,
                "StatusId":itm.statusId,
                "UserName":itm.userName,
                "Password":itm.password!=="" ? CipherString(itm.password) : "",
                "clients":itm.clients
            })
        })

        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "users":users,
        }
        
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/EditUser", params)
        .then(res => {
            const data = res.data
            if(data.status=="1")
                this.handleSearch(e)
            this.setState({
                isloading   :   false,
                alerttype   :   data.status=="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                disableSaveBtn: false
            });

        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
                disableSaveBtn: false
            })
        })
    }
    GridDataModified(oldValue, newValue, rowid, column) {
        let isDisable = true
        this.state.userList.map(function(item,i){
            if(item.id==rowid)
                item.isModified = "1"
            if(item.isModified=="1")
                isDisable = false
        })
        this.setState({disableSaveBtn:isDisable})
    }
    handlePasswordReset = async(e)=>{
        
        if (!window.confirm('You are about to reset the user name & password. Are you sure?'))
            return
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":e.employeeId
        }
        
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Authentication/ResetPassword", params)
        .then(res => {
            const data = res.data
            if(data.status=="1")
                this.handleSearch(e)
            this.setState({
                isloading   :   false,
                alerttype   :   data.status=="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                disableSaveBtn: false
            });

        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
                disableSaveBtn: false
            })
        })
    }
    render(){
        const usersCol = [
            // {
            //     text        :   "ACTION",
            //     editable    :   false,
            //     dataField   :   "databasePkey",
            //     formatter   :   (cell, row, isSelect) => {
            //         if(row.clientId==="111")
            //         return (
            //             <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
            //                 variant="link" onClick={e => this.handleModalClientShow(row)}
            //             >ADD CLIENT</Button>
            //         );
            //     },
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'10%'}},
            //     style:{textAlign:'center'}
            // },
            {
                text        :   "ACTION",
                editable    :   false,
                dataField   :   "databasePkey",
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={e => this.handlePasswordReset(row)}
                        >RESET</Button>
                    );
                },
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'6%'}},
                style:{textAlign:'center'}
            },
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE NAME',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'30%' }},
                style:{textAlign:'left',width:'30%'}
            },
            {
                dataField: 'roleId',
                text: 'ROLE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%' }},
                style:{textAlign:'left',width:'15%'},
                formatter: (cell, row) => {
                    //console.loglog(this.state.roleList.includes(x => x.value == cell))
                    if(row.roleId!='' && row.roleId!=null){
                        if(this.state.roleList.filter(x => x.value == cell).length>0)
                            return this.state.roleList.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.roleList
                }
            },
            {
                dataField: 'userName',
                text: 'USER NAME',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%' }},
                style:{textAlign:'left',width:'10%'},
                formatter: (cell, row) => {
                    return cell.toLowerCase();
                },
            },
            {
                dataField: 'statusId',
                text: 'STATUS',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center',width:'10%'},
                formatter: (cell, row) => {
                    if(row.statusId!='' && row.statusId!=null){
                        if(this.state.userStatusList.filter(x => x.value == cell).length>0){
                            return this.state.userStatusList.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.userStatusList
                }
            },
            {
                dataField: 'password',
                text: 'PASSWORD',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'31%' }},
                style:{textAlign:'left',width:'31%'}
            }
        ]

        const colClient = [
            {
                dataField: 'clientName',
                text: 'CLIENT NAME',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'100%' }},
                style:{textAlign:'left',width:'100%'}
            },
        ]
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        const selectRowClient = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                let arr = this.state.userList.filter(x=>x.employeeId===row.employeeId)
                this.state.userList.filter(x=>x.employeeId===row.employeeId)[0].clients.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                if(isDisable===false){
                    this.state.userList.map(function(itm){
                        if(itm.id===arr[0].id){
                            itm.isModified="1"
                        }
                    })
                }
                this.setState({disableSaveBtn:isDisable})
            }
        };
        const expandRow = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "id"
                    data = { row.clients }
                    columns = { colClient }
                    rowClasses="noser-table-row-class"
                    striped
                    expandRow
                    selectRow = { selectRowClient }
                    wrapperClasses="table-responsive"
                    noDataIndication={ () => <div>No record found.</div> }
                /> 
            ),
            showExpandColumn: true,
          };
        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header> ADMINISTRATOR >> USERS</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                {/* <Form.Row>
                                    <Form.Group as={Col} sm={12} controlId="formHorizontalEmail">
                                        <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeClient}
                                                options={this.state.clientList}
                                                placeholder="Select Client"
                                            />
                                    </Form.Group>
                                </Form.Row> */}
                                <Form.Row>
                                    <Form.Group as={Col} sm={12} controlId="formHorizontalEmail">
                                        <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleChangeEmployee}
                                                options={this.state.employeeList}
                                                placeholder="Select Employee"
                                            />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm={12} controlId="formHorizontalEmail">
                                        <div className="mt-1">
                                            <ButtonToolbar>
                                                <Button style={{minWidth:'60px',marginRight:'1pt'}} className="ml-auto" variant="success" variant="primary" onClick={this.handleSearch}>Search</Button>
                                            </ButtonToolbar>
                                        </div>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                        <Card.Header>LIST OF USERS</Card.Header>
                                        <div className="mt-1">
                                            <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.userList }
                                                columns = { usersCol }
                                                selectRow = { selectRow }
                                                cellEdit = { cellEditFactory({ 
                                                        mode: 'dbclick', 
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => { 
                                                            this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                loading={true}
                                                //expandRow={ expandRow }
                                                wrapperClasses="table-responsive"
                                                pagination={ paginationFactory({sizePerPageRenderer})}
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            />
                                        </div>
                                        
                                    </Form.Group>
                                </Form.Row>
                                <div className="mt-1">
                                    <ButtonToolbar>
                                        <Button style={{minWidth:'60px',marginRight:'1pt'}} className="ml-auto" variant="success" variant="success" onClick={this.handleSubmit} disabled={this.state.disableSaveBtn}>Save</Button>
                                        <Button style={{minWidth:'60px'}} variant="primary" variant="primary" onClick={this.handleModalShow}>Create</Button>
                                    </ButtonToolbar>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
                <CreateUser 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child1 = ref)}
                />
                <CreateUserClient 
                    show={this.state.openModalClient}
                    onHide={this.handleModalClientClose}
                    onRefModal={ref => (this.child2 = ref)}
                />
            </div>
        )
    }
}

export default Users;