import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../noser-hris-component';

class InclusionCreate extends Component {
    constructor(props) {
        super(props)
        this.module = React.createRef();
        this.submodule = React.createRef();

        this.state = {
            selected:[],
            deductionTypeList: [],
            selectedDeductionType: "",
            selectedDeductionTypeId: "",
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            selected: [],
            inclusionList: [],
            inclusionTypeList: [],
            selectedInclusionType: "",
            selectedInclusionTypeId: "",
            selectedInclusion: "",
            selectedInclusionId: "",
            userinfo: [],
            isLoading: true,
            AlertType: "",
            Show: false,
            Message: "",
            Color: "",
            Fade: true,

        }
        this.textInput = React.createRef();
    }
    handleCoverChangeInclusionType = this.handleCoverChangeInclusionType.bind(this)


    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
        this.GetInclusionType()
        this.GetInclusion()
        sleep(1000).then(() => {
        this.setState({isLoading:false})})
    };

    componentWillUnmount() {
        this.props.onRefModal(undefined)
    };

    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            name        :   '',
            description :   ''
        })
    };

    handleChange() {
        this.state.selectedInclusion= this.textInput.current.value;
    }

    handleCoverChangeInclusion(selectedOptions){
            this.state.selectedInclusion = this.textInput.current.value;
    };

    handleCoverChangeInclusionType (e) {
        if (e.length > 0) {
            this.state.selectedInclusionType = e[0].name
            this.state.selectedInclusionTypeId = this.GetInclusionTypeId(e[0].name)
        }
    };

    GetInclusionTypeId(name) {
        let inclusionTypeId = ''
        for (let i = 0; i <= this.state.inclusionTypeList.length; i++) {
            if (this.state.inclusionTypeList[i]["name"] === name) {
                inclusionTypeId = this.state.inclusionTypeList[i]['id'];
                break;
            }
        }
        return inclusionTypeId
    };

    GetInclusion() {
        const inclusionParams = {
        "IpAddress":"0.0.0.0",
        "CompanyId":this.state.userinfo.companyId,
        "UserId":this.state.userinfo.userId,
        "TypeId":"" ,
        "Name":  ""
        };
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetInclusions", inclusionParams
            )
            .then(res => {
                const data = res.data;
                console.log(data)
                this.setState({inclusionList: data.inclusions});
            })
    };

    GetInclusionId(name) {
        let InclusionId = ''
        for (let i = 0; i <= this.state.inclusionList.length; i++) {
            if (this.state.inclusionList[i]["name"] === name) {
                InclusionId = this.state.inclusionList[i]['id'];
                break;
            }
        }
        return InclusionId
    };


    GetInclusionType() {
    const typeParams = {
        "IpAddress":"0.0.0.0",
        "CompanyId":this.state.userinfo.companyId,
        "UserId":this.state.userinfo.userId,
        "Name":  ""
    };
    axios
        .post(
            AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetInclusionTypes", typeParams
        )
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({inclusionTypeList: data.inclusionTypes});
        })
    };

    handleSaveClick = event => {
        event.preventDefault();
        console.log(this.state.selectedInclusionId);
        console.log("textInput");
       
        const inclusionParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "TypeId":this.state.selectedInclusionTypeId,
            "Name":  this.state.selectedInclusion
        };

        axios
            .post( 
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/AddInclusion", inclusionParams
            )
            .then(res => {
                
                const data = res.data;
                console.log(res.data);
            this.setState({isLoading:false})
                    var alertType = (data.status=="1") ? "success" : "danger"
                this.setState(
                    {
                        isloading:false,
                        alerttype:"Success!",
                        isshow:true,
                        color:alertType,
                        message:data.message ,
                        fade:true
                    });
            })
            .catch(error=>{
                this.setState(
                {
                    isloading:false,
                    alerttype:"Error! ",
                    isshow:true,
                    color:"danger",
                    message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade:true
            })
        })
    };

    handleModalClose = () => {
        this.props.onHide();            
    };
   
    handleKeyUp = (e) =>{

    };

    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Inclusion - Create
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Form onSubmit={ this.handleSaveClick } ref={form => this.form = form}>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleCoverChangeInclusionType}
                                                options={this.state.inclusionTypeList}
                                                placeholder="Select Inclusion Type"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                    <Form.Control type="text" placeholder="Enter New Inclusion Type" 
                                    ref={this.textInput} onChange={() => this.handleChange()} autoComplete="off" />
                                    </Col>
                                </Form.Group>
                        </Form>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                   
                    <ButtonToolbar sm={12}>
                        <Button variant="success" className="ml-auto" onClick={this.handleSaveClick} style={{minWidth:'60px'}}>
                            Save
                        </Button>&nbsp;&nbsp;
                        <Button variant="danger" onClick={this.props.onHide} style={{minWidth:'60px'}}>
                            Close
                        </Button>
                    </ButtonToolbar>
                   
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default InclusionCreate