import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Container, Modal, DatePicker,Dropdown, DropdownButton, props, sizePerPageRenderer,Type,
} 
from '../../../noser-hris-component';

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';



class InsertCOCReference extends Component {
    constructor(props) {
        super(props)
        this.state ={
            userinfo        :   [],
            
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            trainingRegisterTableList : [],

            selectedClientName                  :"",
            getClientList                       :[],
            clientName                          :"",
            articleList                         :[],
            selectedArticleNo                   :'',
            selectedArticleName                 :'',
            selectedArticleId                   :'',
            selectedArticleNo2                   :'',
            selectedArticleName2                 :'',
            selectedArticleId2                   :'',
            sectionList                         :[],
            selectedSectionNo                   :'',
            selectedInfraction                  :'',
            selectedCategory                    :'',
            selectedSectionNo2                   :'',
            selectedInfraction2                  :'',
            selectedCategory2                   :'',
            selectedSectionNo3                   :'',
            selectedInfraction3                  :'',
            selectedCategory3                    :'',
            selectedArticleId                   :'',
            sectionNo                           :"",
            infraction                          :"",
            category                            :"",
            addedArticle:false,
            addedSection2:false,
            addedSection3:false,
            selectedClientId                            :"",
            articleData1                        :[],
            articleData2                        :[],
            articleData3                        :[],
            //articleData1   
            articleDataList:[],
            sectionDataList:[],
            articleDataList2:[],
            sectionDataList2:[],
            grid2:"0",
            articleDataList3:[],
            sectionDataList3:[],
            grid3:"0",
            articleDataList4:[],
            sectionDataList4:[],
            grid4:"0",
            articleDataList5:[],
            sectionDataList5:[],
            grid5:"0",
            
        }
    }

    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;
    }
    
    GetDepartment() {
        const departmentCodeParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "",
            "Name"          :   ""
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDepartments",  departmentCodeParams)
        .then(res => {
            const data = res.data;
            console.log("Get Departments Code");
            console.log(data);
            console.log("Get Departments Code");
            this.setState({
                departmentCodeAutocomplete  :   data.departments
            });
        })

        console.log(this.state.departmentCodeAutocomplete)
    }

    getClientList(){
        this.setState({isloading:true})

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {

            const data = res.data
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });

        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
        })

    }

    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.clientName = e[0].name
        this.setState({
           // isloading:true,
            isshow:false,
            color:"",
            message:"",
            fade:true ,
            clientName: e[0].name
        })
       
        this.getCOCArticle();;
    }

    getCOCArticle =()=>{
        this.setState({
            isloading:true
        })
       
        let filter_data ={ 'clientName' : this.state.clientName, "isDeleted":"0" }
        const getParams ={
            "_collection" : "COCArticle",
            "filter_data" : filter_data
        }
        console.log("axios start")
        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            console.log("res.data")
            console.log(res.data)
            if(res.data.COCArticle.length === 0 ){
                console.log("res.data.COCArticle.length === 0 ")
                this.setState({
                    isloading       :   false,
                    alerttype       :   "warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No Data " ,
                    fade            :   true
                })
            }else{
                const itemUsersDataLists = this.buildList(data["COCArticle"])
            
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                    let obj = {
                        'id'                    :   this.state.itemUserDataList[i]['id'],
                        'clientName'            :   this.state.itemUserDataList[i]['clientName'],
                        'articleNo'             :   this.state.itemUserDataList[i]['articleNo'],
                        'articleName'           :   this.state.itemUserDataList[i]['articleName'],
                        'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'            :   this.state.itemUserDataList[i]['isModified'],
                        'value'            :   this.state.itemUserDataList[i]['articleNo'],
                        'label'            :   this.state.itemUserDataList[i]['articleNo'],

                        
                    }
                    List.push(obj)
                    
                    this.setState({
                        articleList:List,
                        isloading:false
                    })
                    
                }
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState({isloading:false})
        })
    } 
        
    buildList(data) {
        //console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let clientNameList              =[]
        let articleNoList               =[]
        let articleNameList             =[]
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
    
        for (let i = 0; i < data.length; i++) {
    
            let s1 = data[i].split("':")

            let idClean                     = s1[1].replace("ObjectId(" ,"").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let clientNameTmp               = s1[2].split("',")[0]
            let articleNoTmp                = s1[3].split("',")[0]
            let articleNameTmp              = s1[4].split("',")[0]
            let isDraftListTmp              = s1[5].split("',")[0]
            let isDeletedListTmp            = s1[6].split("',")[0]
            let createdbyListTmp            = s1[7].split("',")[0]
            let createddateListTmp          = s1[8].split("',")[0]
            let modifiedbyListTmp           = s1[9].split("',")[0]
            let modifieddateListTmp         = s1[10].split("',")[0]
            let isModifiedListTmp           = s1[11].split("',")[0]
        
            idList.push(IdClean2.replace(" '",""))
            clientNameList.push(clientNameTmp.replace(" '",""))
            articleNoList.push(articleNoTmp.replace(" '",""))
            articleNameList.push(articleNameTmp.replace(" '",""))
            isDraftList.push(isDraftListTmp.replace(" '",""))
            isDeletedList.push(isDeletedListTmp.replace(" '",""))
            createdbyList.push(createdbyListTmp.replace(" '",""))
            createddateList.push(createddateListTmp.replace(" '",""))
            modifiedbyList.push(modifiedbyListTmp.replace(" '",""))
            modifieddateList.push(modifieddateListTmp.replace(" '",""))
            isModifiedList.push(isModifiedListTmp.replace(" '","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'clientName'            :   clientNameList[i],
                'articleNo'             :   articleNoList[i],
                'articleName'           :   articleNameList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }          
        return itemList
      
    }
    
    onChangeArticle = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedArticleName     :   '',
                selectedArticleId       :   '',
                selectedArticleNo       :   '',
            })
            return
        }
        this.state.selectedArticleNo = e[0].articleNo
        this.state.selectedArticleName = e[0].articleName
        this.state.selectedArticleId = e[0].id        
               
        this.setState({
            isshow              : false,
            selectedArticleName : e[0].articleName,
            selectedArticleNo   : e[0].articleNo,
            selectedArticleId   : e[0].id,
            isloading           : true,

        })
        console.log(this.state.selectedArticleName)
        console.log(this.state.selectedArticleId)
       
        this.getCOCSection();
       
    }

    /* onChangeArticle2 = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedArticleName2     :   '',
                selectedArticleId2       :   '',
                selectedArticleNo2       :   '',
            })
            return
        }
        this.state.selectedArticleNo2 = e[0].articleNo
        this.state.selectedArticleName2 = e[0].articleName
        this.state.selectedArticleId2 = e[0].id        
               
        this.setState({
            isshow                  : false,
            selectedArticleName2    : e[0].articleName,
            selectedArticleNo2      : e[0].articleNo,
            selectedArticleId2      : e[0].id,
            isloading               : true,

        })
        console.log(this.state.selectedArticleName2)
        console.log(this.state.selectedArticleId2)
       
        this.getCOCSection2();
       
    } */

    getCOCSection=(articleId)=>{

        console.log(articleId)
        this.setState({
            
            isloading:true
        })

        let filter_data ={ 'articleId' : articleId,"isDeleted":"0" }
        const getParams ={
            "_collection" : "COCSection",
            "filter_data" : filter_data
        }
        console.log("axios start")
        console.log(getParams)
        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            console.log("data start")
            console.log(data)
            if(res.data.COCSection.length === 0 ){
                console.log("res.data.COCSection.length === 0 ")
                this.setState({
                    isloading       :   false,
                    alerttype       :   "warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No Data " ,
                    fade            :   true
                })
            }else{
                console.log("res.data.COCSection.length != 0 ")
                const itemUsersDataLists = this.buildListCOCSection(data["COCSection"])
            
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                    let obj = {
                        'id'                    :   this.state.itemUserDataList[i]['id'],
                        'clientName'            :   this.state.itemUserDataList[i]['clientName'],
                        'articleId'             :   this.state.itemUserDataList[i]['articleId'],
                        "articleNo"             :   this.state.itemUserDataList[i]['articleNo'],
                        "articleName"           :   this.state.itemUserDataList[i]['articleName'],
                        'sectionNo'             :   this.state.itemUserDataList[i]['sectionNo'],
                        'infraction'            :   this.state.itemUserDataList[i]['infraction'],
                        'category'              :   this.state.itemUserDataList[i]['category'],
                        'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'            :   this.state.itemUserDataList[i]['isModified'],
                        'value'            :   this.state.itemUserDataList[i]['sectionNo'],
                        'label'            :   this.state.itemUserDataList[i]['sectionNo'],
                        
                    }
                    List.push(obj)
                    
                    

                             
                }

                this.setState({
                    sectionList:List,
                    isloading:false
                })
                console.log("List")
                console.log(List)
            }
            
            
        })
        .catch(error=>{
            console.log("error: " + error)
        })

    }

    getCOCSection2=()=>{

        let filter_data ={ 'articleId' : this.state.selectedArticleId2 }
        const getParams ={
            "_collection" : "COCSection",
            "filter_data" : filter_data
        }
        
        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            
            const itemUsersDataLists = this.buildListCOCSection(data["COCSection"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                let obj = {
                    'id'                    :   this.state.itemUserDataList[i]['id'],
                    'clientName'            :   this.state.itemUserDataList[i]['clientName'],
                    'articleId'             :   this.state.itemUserDataList[i]['articleId'],
                    "articleNo"             :   this.state.itemUserDataList[i]['articleNo'],
                    "articleName"           :   this.state.itemUserDataList[i]['articleName'],
                    'sectionNo'             :   this.state.itemUserDataList[i]['sectionNo'],
                    'infraction'            :   this.state.itemUserDataList[i]['infraction'],
                    'category'              :   this.state.itemUserDataList[i]['category'],
                    'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'            :   this.state.itemUserDataList[i]['isModified'],
                    
                }
                List.push(obj)
                
                this.setState({
                    sectionList:List,
                    isloading:false
                })
                         
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
        })

    }

    buildListCOCSection(data) {

        let itemList =[]
    
        let idList                      =[]
        let clientNameList              =[]
        let articleIdList               =[]
        let articleNoList               =[]
        let articleNameList             =[]
        let sectionNoList               =[]
        let infractionList              =[]
        let categoryList                =[]        
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
    
        for (let i = 0; i < data.length; i++) {
    
            let s1 = data[i].split("':")

            let idClean                     = s1[1].replace("ObjectId(" ,"").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let clientNameTmp               = s1[2].split("',")[0]
            let articleIdTmp                = s1[3].split("',")[0]
            let articleNoTmp                = s1[4].split("',")[0]
            let articleNameTmp              = s1[5].split("',")[0]
            let sectionNoTmp                = s1[6].split("',")[0]
            let infractionTmp               = s1[7].split("',")[0]
            let categoryTmp                 = s1[8].split("',")[0]
            let isDraftListTmp              = s1[9].split("',")[0]
            let isDeletedListTmp            = s1[10].split("',")[0]
            let createdbyListTmp            = s1[11].split("',")[0]
            let createddateListTmp          = s1[12].split("',")[0]
            let modifiedbyListTmp           = s1[13].split("',")[0]
            let modifieddateListTmp         = s1[14].split("',")[0]
            let isModifiedListTmp           = s1[15].split("',")[0]
        
            idList.push(IdClean2.replace(" '",""))
            clientNameList.push(clientNameTmp.replace(" '",""))
            articleIdList.push(articleIdTmp.replace(" '",""))
            articleNoList.push(articleNoTmp.replace(" '",""))
            articleNameList.push(articleNameTmp.replace(" '",""))
            sectionNoList.push(sectionNoTmp.replace(" '",""))
            infractionList.push(infractionTmp.replace(" '",""))
            categoryList.push(categoryTmp.replace(" '",""))
            isDraftList.push(isDraftListTmp.replace(" '",""))
            isDeletedList.push(isDeletedListTmp.replace(" '",""))
            createdbyList.push(createdbyListTmp.replace(" '",""))
            createddateList.push(createddateListTmp.replace(" '",""))
            modifiedbyList.push(modifiedbyListTmp.replace(" '",""))
            modifieddateList.push(modifieddateListTmp.replace(" '",""))
            isModifiedList.push(isModifiedListTmp.replace(" '","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'clientName'            :   clientNameList[i],
                'articleId'             :   articleIdList[i],
                'articleNo'             :   articleNoList[i],
                'articleName'           :   articleNameList[i],
                'sectionNo'             :   sectionNoList[i],
                'infraction'            :   infractionList[i],
                'category'              :   categoryList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }          
        return itemList
      
    }

    onChangeSection = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedSectionNo     :   '',
                selectedInfraction    :   '',
                selectedCategory      :   '',
            })
            return
        }
        this.state.selectedSectionNo = e[0].sectionNo
        this.state.selectedInfraction = e[0].infraction
        this.state.selectedCategory = e[0].category        
               
        this.setState({
            isshow              : false,
            selectedSectionNo   : e[0].sectionNo,
            selectedInfraction  : e[0].infraction,
            selectedCategory    : e[0].category,
            isloading           : false,

        })
        console.log(this.state.selectedSectionNo)
        console.log(this.state.selectedInfraction)
        console.log(this.state.selectedCategory)
        
    }

    onChangeSection2 = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedSectionNo2     :   '',
                selectedInfraction2    :   '',
                selectedCategory2      :   '',
            })
            return
        }
        this.state.selectedSectionNo2 = e[0].sectionNo
        this.state.selectedInfraction2 = e[0].infraction
        this.state.selectedCategory2 = e[0].category        
               
        this.setState({
            isshow              : false,
            selectedSectionNo2   : e[0].sectionNo,
            selectedInfraction2  : e[0].infraction,
            selectedCategory2   : e[0].category,
            isloading           : false,

        })
        console.log(this.state.selectedSectionNo2)
        console.log(this.state.selectedInfraction2)
        console.log(this.state.selectedCategory2)
       
    }

    onChangeSection3 = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedSectionNo3     :   '',
                selectedInfraction3    :   '',
                selectedCategory3      :   '',
            })
            return
        }
        this.state.selectedSectionNo3 = e[0].sectionNo
        this.state.selectedInfraction3 = e[0].infraction
        this.state.selectedCategory3 = e[0].category        
               
        this.setState({
            isshow              : false,
            selectedSectionNo3   : e[0].sectionNo,
            selectedInfraction3  : e[0].infraction,
            selectedCategory3   : e[0].category,
            isloading           : false,
        })
        console.log(this.state.selectedSectionNo3)
        console.log(this.state.selectedInfraction3)
        console.log(this.state.selectedCategory3)
       
    }

    handleSave=()=>{

      /*   let dataCOCRef = {
            "articleNo"           :   this.state.selectedArticleNo,
            "articleName"         :   this.state.selectedArticleName,
            "sectionNo"           :   this.state.selectedSectionNo,
            "infraction"          :   this.state.selectedInfraction,
            "category"            :   this.state.selectedCategory,

            "articleNo2"           :   this.state.selectedArticleNo2,
            "articleName2"         :   this.state.selectedArticleName2,
            "sectionNo2"           :   this.state.selectedSectionNo2,
            "infraction2"          :   this.state.selectedInfraction2,
            "category2"            :   this.state.selectedCategory2,

            "sectionNo3"           :   this.state.selectedSectionNo3,
            "infraction3"          :   this.state.selectedInfraction3,
            "category3"            :   this.state.selectedCategory3,


        } */

        sessionStorage.setItem("dataCOCRefarticle1", JSON.stringify(this.state.articleDataList))
        sessionStorage.setItem("dataCOCRefarticle2", JSON.stringify(this.state.articleDataList2))
        sessionStorage.setItem("dataCOCRefarticle3", JSON.stringify(this.state.articleDataList3))
        sessionStorage.setItem("dataCOCRefarticle4", JSON.stringify(this.state.articleDataList4))
        sessionStorage.setItem("dataCOCRefarticle5", JSON.stringify(this.state.articleDataList5))

        sessionStorage.setItem("dataCOCRefsection1", JSON.stringify(this.state.sectionDataList))
        sessionStorage.setItem("dataCOCRefsection2", JSON.stringify(this.state.sectionDataList2))
        sessionStorage.setItem("dataCOCRefsection3", JSON.stringify(this.state.sectionDataList3))
        sessionStorage.setItem("dataCOCRefsection4", JSON.stringify(this.state.sectionDataList4))
        sessionStorage.setItem("dataCOCRefsection5", JSON.stringify(this.state.sectionDataList5))
        let viewdataCOCRefTmp = "1"
        sessionStorage.setItem("viewdataCOCRefTmp", JSON.stringify(viewdataCOCRefTmp))
        this.props.onHide("Hello Parent! It's your turn parent"); 
    }

    onModalClose = () => {
        this.props.onHide("Hello Parent! It's your turn parent"); 
        let viewdataCOCRefTmp = "0"
        sessionStorage.setItem("viewdataCOCRefTmp", JSON.stringify(viewdataCOCRefTmp))       
        this.setState({
            articleDataList:[],
            sectionDataList:[],
            articleDataList2:[],
            sectionDataList2:[],
            articleDataList3:[],
            sectionDataList3:[],
            articleDataList4:[],
            sectionDataList4:[],
            articleDataList5:[],
            sectionDataList5:[],
        })    
    }

    handleAddArticle=()=>{

        if(this.state.clientName !==""){
            this.setState({
                grid2:"1",
            })
        }

        if(this.state.clientName !=="" && this.state.grid2=="1" ){
            this.setState({
                grid3:"1",
            })
        }
        if(this.state.clientName !=="" && this.state.grid3=="1" ){
            this.setState({
                grid4:"1",
            })
        }
        if(this.state.clientName !=="" && this.state.grid4=="1" ){
            this.setState({
                grid5:"1",
            })
        }
       
        
    }

    GridDataModified(oldValue, newValue, id, column) {
        ////console.log(id)
        let checktmp = "0"
        let filter = ""
        let filter2 = ""
        let articleId = ""
        let articleData = this.state.articleList
        //let employeeData = this.state.getEmployeeList
        this.state.articleDataList.map(function(item,i) {
            
            if (item.id===id){
                checktmp = "1"
                item.isModified = newValue!=oldValue ? "1" : "0"
                filter= item.articleNo
                 
                //filter2= item.employeeName

              /*   if(item.ClientName == newValue){
                    let newClientIdtmp = articleData.find(x => x.articleNo === filter);

                    item.ClientId = newClientIdtmp.id

                } */
                if(item.articleNo == newValue){
                    if(item.articleNo !== undefined){
                        if(item.articleNo !== ""){
                            let articleNametmp = articleData.find(x => x.articleNo === filter);
                            let articleDta = articleData.find(x => x.articleNo === filter);
                            articleId =  articleNametmp.id
                            item.articleName = articleNametmp.articleName
                         /*  item.position   = newemployeeNotmp.position
                            item.locationName = newemployeeNotmp.locationName */
                        }
                    }
                   
                }
                console.log("this.state.articleDataList")
                console.log(item)
                
            }
               
        })

        if(checktmp === "1"){
           // this.getEmployees(filter)
           console.log("articleId")
           console.log(articleId)
            this.getCOCSection(articleId);
        }
        

    }

    AddEmployee=()=>{
        
        this.setState({
            isloading:true
        })
        let IDnew = this.state.articleDataList.length + 1

        let addData = {
            id              :String(IDnew),
            gridID          : "0",
            articleNo      :"",
            articleName        :"",
            
           
        }

        this.reloadGrid(addData)
       
    }

    reloadGrid=(addData)=>{
       
        this.state.articleDataList.push(addData)
      
        this.setState({
            isloading : false
        })
    
    }

    GridDataModifiedsection(oldValue, newValue, id, column) {
        ////console.log(id)
        let checktmp = "0"
        let filter = ""
        let filter2 = ""
        let sectionData = this.state.sectionList
        //let employeeData = this.state.getEmployeeList
        this.state.sectionDataList.map(function(item,i) {
            
            if (item.id===id){
                checktmp = "1"
                item.isModified = newValue!=oldValue ? "1" : "0"
                filter= item.sectionNo
                //filter2= item.employeeName

              /*   if(item.ClientName == newValue){
                    let newClientIdtmp = sectionData.find(x => x.sectionNo === filter);

                    item.ClientId = newClientIdtmp.id

                } */
                if(item.sectionNo == newValue){
                    if(item.sectionNo !== undefined){
                        if(item.sectionNo !== ""){
                            let sectionNametmp = sectionData.find(x => x.sectionNo === filter);

                            item.infraction = sectionNametmp.infraction
                            item.category = sectionNametmp.category
                            /*  item.position   = newemployeeNotmp.position
                            item.locationName = newemployeeNotmp.locationName */
                        }
                    
                    }
                }
                console.log(item)
                
            }
               
        })

        // if(checktmp === "1"){
        //     this.getEmployees(filter)
        // }
        

    }

    AddSection=()=>{
        
        this.setState({
            isloading:true
        })
        let IDnew = this.state.sectionDataList.length + 1

        let sectionData = {
            id              :String(IDnew),
            gridID          : "0",
            sectionNo      :"",
            infraction        :"",
            category        :"",
            
           
        }

        this.reloadGridsection(sectionData)
       
    }

    reloadGridsection=(sectionData)=>{
       
        this.state.sectionDataList.push(sectionData)
      
        this.setState({
            isloading : false
        })
    
    }


    /* grid 2 */

    GridDataModified2(oldValue, newValue, id, column) {
        ////console.log(id)
        let checktmp = "0"
        let filter = ""
        let filter2 = ""
        let articleId = ""
        let articleData = this.state.articleList
        //let employeeData = this.state.getEmployeeList
        this.state.articleDataList2.map(function(item,i) {
            
            if (item.id===id){
                checktmp = "1"
                item.isModified = newValue!=oldValue ? "1" : "0"
                filter= item.articleNo
                 
                //filter2= item.employeeName

              /*   if(item.ClientName == newValue){
                    let newClientIdtmp = articleData.find(x => x.articleNo === filter);

                    item.ClientId = newClientIdtmp.id

                } */
                if(item.articleNo == newValue){
                    if(item.articleNo !== undefined){
                        if(item.articleNo !== ""){
                        let articleNametmp = articleData.find(x => x.articleNo === filter);
                        let articleDta = articleData.find(x => x.articleNo === filter);
                            articleId =  articleNametmp.id
                            item.articleName = articleNametmp.articleName
                        /*  item.position   = newemployeeNotmp.position
                            item.locationName = newemployeeNotmp.locationName */
                        }
                    }
                   
                }
                console.log(item)
                
            }
               
        })

        if(checktmp === "1"){
           // this.getEmployees(filter)
            this.getCOCSection(articleId);
        }
        

    }

    AddEmployee2=()=>{
        
        this.setState({
            isloading:true
        })
        let IDnew = this.state.articleDataList2.length + 1

        let addData = {
            id              :String(IDnew),
            gridID          : "1",
            articleNo      :"",
            articleName        :"",
            
           
        }

        this.reloadGrid2(addData)
       
    }

    reloadGrid2=(addData)=>{
       
        this.state.articleDataList2.push(addData)
      
        this.setState({
            isloading : false
        })
    
    }

    GridDataModifiedsection2(oldValue, newValue, id, column) {
        ////console.log(id)
        let checktmp = "0"
        let filter = ""
        let filter2 = ""
        let sectionData = this.state.sectionList
        //let employeeData = this.state.getEmployeeList
        this.state.sectionDataList2.map(function(item,i) {
            
            if (item.id===id){
                checktmp = "1"
                item.isModified = newValue!=oldValue ? "1" : "0"
                filter= item.sectionNo
                //filter2= item.employeeName

              /*   if(item.ClientName == newValue){
                    let newClientIdtmp = sectionData.find(x => x.sectionNo === filter);

                    item.ClientId = newClientIdtmp.id

                } */
                if(item.sectionNo == newValue){
                    if(item.sectionNo !== undefined){
                        if(item.sectionNo !== ""){
                        let sectionNametmp = sectionData.find(x => x.sectionNo === filter);

                        item.infraction = sectionNametmp.infraction
                        item.category = sectionNametmp.category
                       /*  item.position   = newemployeeNotmp.position
                        item.locationName = newemployeeNotmp.locationName */
                    }}
                   
                }
                console.log(item)
                
            }
               
        })

        // if(checktmp === "1"){
        //     this.getEmployees(filter)
        // }
        

    }

    AddSection2=()=>{
        
        this.setState({
            isloading:true
        })
        let IDnew = this.state.sectionDataList2.length + 1

        let sectionData = {
            id              :String(IDnew),
            gridID          : "1",
            sectionNo      :"",
            infraction        :"",
            category        :"",
            
           
        }

        this.reloadGridsection2(sectionData)
       
    }

    reloadGridsection2=(sectionData)=>{
       
        this.state.sectionDataList2.push(sectionData)
      
        this.setState({
            isloading : false
        })
    
    }

    /* grid 3 */

    GridDataModified3(oldValue, newValue, id, column) {
        ////console.log(id)
        let checktmp = "0"
        let filter = ""
        let filter3 = ""
        let articleId = ""
        let articleData = this.state.articleList
        //let employeeData = this.state.getEmployeeList
        this.state.articleDataList3.map(function(item,i) {
            
            if (item.id===id){
                checktmp = "1"
                item.isModified = newValue!=oldValue ? "1" : "0"
                filter= item.articleNo
                 
                //filter3= item.employeeName

              /*   if(item.ClientName == newValue){
                    let newClientIdtmp = articleData.find(x => x.articleNo === filter);

                    item.ClientId = newClientIdtmp.id

                } */
                if(item.articleNo == newValue){
                    if(item.articleNo !== undefined){
                        if(item.articleNo !== ""){
                        let articleNametmp = articleData.find(x => x.articleNo === filter);
                        let articleDta = articleData.find(x => x.articleNo === filter);
                        articleId =  articleNametmp.id
                        item.articleName = articleNametmp.articleName
                       /*  item.position   = newemployeeNotmp.position
                        item.locationName = newemployeeNotmp.locationName */
                    }}
                   
                }
                console.log(item)
                
            }
               
        })

        if(checktmp === "1"){
           // this.getEmployees(filter)
            this.getCOCSection(articleId);
        }
        

    }

    AddEmployee3=()=>{
        
        this.setState({
            isloading:true
        })
        let IDnew = this.state.articleDataList3.length + 1

        let addData = {
            id              :String(IDnew),
            gridID          : "2",
            articleNo      :"",
            articleName        :"",
            
           
        }

        this.reloadGrid3(addData)
       
    }

    reloadGrid3=(addData)=>{
       
        this.state.articleDataList3.push(addData)
      
        this.setState({
            isloading : false
        })
    
    }

    GridDataModifiedsection3(oldValue, newValue, id, column) {
        ////console.log(id)
        let checktmp = "0"
        let filter = ""
        let filter3 = ""
        let sectionData = this.state.sectionList
        //let employeeData = this.state.getEmployeeList
        this.state.sectionDataList3.map(function(item,i) {
            
            if (item.id===id){
                checktmp = "1"
                item.isModified = newValue!=oldValue ? "1" : "0"
                filter= item.sectionNo
                //filter3= item.employeeName

              /*   if(item.ClientName == newValue){
                    let newClientIdtmp = sectionData.find(x => x.sectionNo === filter);

                    item.ClientId = newClientIdtmp.id

                } */
                if(item.sectionNo == newValue){
                    if(item.sectionNo !== undefined){
                        if(item.sectionNo !== ""){
                        let sectionNametmp = sectionData.find(x => x.sectionNo === filter);

                        item.infraction = sectionNametmp.infraction
                        item.category = sectionNametmp.category
                       /*  item.position   = newemployeeNotmp.position
                        item.locationName = newemployeeNotmp.locationName */
                    }}
                   
                }
                console.log(item)
                
            }
               
        })

        // if(checktmp === "1"){
        //     this.getEmployees(filter)
        // }
        

    }

    AddSection3=()=>{
        
        this.setState({
            isloading:true
        })
        let IDnew = this.state.sectionDataList3.length + 1

        let sectionData = {
            id              :String(IDnew),
            gridID          : "2",
            sectionNo      :"",
            infraction        :"",
            category        :"",
            
           
        }

        this.reloadGridsection3(sectionData)
       
    }

    reloadGridsection3=(sectionData)=>{
       
        this.state.sectionDataList3.push(sectionData)
      
        this.setState({
            isloading : false
        })
    
    }

    /* grid 4 */

    GridDataModified4(oldValue, newValue, id, column) {
        ////console.log(id)
        let checktmp = "0"
        let filter = ""
        let filter4 = ""
        let articleId = ""
        let articleData = this.state.articleList
        //let employeeData = this.state.getEmployeeList
        this.state.articleDataList4.map(function(item,i) {
            
            if (item.id===id){
                checktmp = "1"
                item.isModified = newValue!=oldValue ? "1" : "0"
                filter= item.articleNo
                 
                //filter4= item.employeeName

              /*   if(item.ClientName == newValue){
                    let newClientIdtmp = articleData.find(x => x.articleNo === filter);

                    item.ClientId = newClientIdtmp.id

                } */
                if(item.articleNo == newValue){
                    if(item.articleNo !== undefined){
                        if(item.articleNo !== ""){
                        let articleNametmp = articleData.find(x => x.articleNo === filter);
                        let articleDta = articleData.find(x => x.articleNo === filter);
                        articleId =  articleNametmp.id
                        item.articleName = articleNametmp.articleName
                       /*  item.position   = newemployeeNotmp.position
                        item.locationName = newemployeeNotmp.locationName */
                    }}
                   
                }
                console.log(item)
                
            }
               
        })

        if(checktmp === "1"){
           // this.getEmployees(filter)
            this.getCOCSection(articleId);
        }
        

    }

    AddEmployee4=()=>{
        
        this.setState({
            isloading:true
        })
        let IDnew = this.state.articleDataList4.length + 1

        let addData = {
            id              :String(IDnew),
            gridID          : "3",
            articleNo      :"",
            articleName        :"",
            
           
        }

        this.reloadGrid4(addData)
       
    }

    reloadGrid4=(addData)=>{
       
        this.state.articleDataList4.push(addData)
      
        this.setState({
            isloading : false
        })
    
    }

    GridDataModifiedsection4(oldValue, newValue, id, column) {
        ////console.log(id)
        let checktmp = "0"
        let filter = ""
        let filter4 = ""
        let sectionData = this.state.sectionList
        //let employeeData = this.state.getEmployeeList
        this.state.sectionDataList4.map(function(item,i) {
            
            if (item.id===id){
                checktmp = "1"
                item.isModified = newValue!=oldValue ? "1" : "0"
                filter= item.sectionNo
                //filter4= item.employeeName

              /*   if(item.ClientName == newValue){
                    let newClientIdtmp = sectionData.find(x => x.sectionNo === filter);

                    item.ClientId = newClientIdtmp.id

                } */
                if(item.sectionNo == newValue){
                    if(item.sectionNo !== undefined){
                        if(item.sectionNo !== ""){
                        let sectionNametmp = sectionData.find(x => x.sectionNo === filter);

                        item.infraction = sectionNametmp.infraction
                        item.category = sectionNametmp.category
                       /*  item.position   = newemployeeNotmp.position
                        item.locationName = newemployeeNotmp.locationName */
                    }}
                   
                }
                console.log(item)
                
            }
               
        })

        // if(checktmp === "1"){
        //     this.getEmployees(filter)
        // }
        

    }

    AddSection4=()=>{
        
        this.setState({
            isloading:true
        })
        let IDnew = this.state.sectionDataList4.length + 1

        let sectionData = {
            id              :String(IDnew),
            gridID          : "3",
            sectionNo      :"",
            infraction        :"",
            category        :"",
            
           
        }

        this.reloadGridsection4(sectionData)
       
    }

    reloadGridsection4=(sectionData)=>{
       
        this.state.sectionDataList4.push(sectionData)
      
        this.setState({
            isloading : false
        })
    
    }

    /* grid 5 */

    GridDataModified5(oldValue, newValue, id, column) {
        ////console.log(id)
        let checktmp = "0"
        let filter = ""
        let filter5 = ""
        let articleId = ""
        let articleData = this.state.articleList
        //let employeeData = this.state.getEmployeeList
        this.state.articleDataList5.map(function(item,i) {
            
            if (item.id===id){
                checktmp = "1"
                item.isModified = newValue!=oldValue ? "1" : "0"
                filter= item.articleNo
                 
                //filter5= item.employeeName

              /*   if(item.ClientName == newValue){
                    let newClientIdtmp = articleData.find(x => x.articleNo === filter);

                    item.ClientId = newClientIdtmp.id

                } */
                if(item.articleNo == newValue){
                    if(item.articleNo !== undefined){
                        if(item.articleNo !== ""){
                        let articleNametmp = articleData.find(x => x.articleNo === filter);
                        let articleDta = articleData.find(x => x.articleNo === filter);
                        articleId =  articleNametmp.id
                        item.articleName = articleNametmp.articleName
                       /*  item.position   = newemployeeNotmp.position
                        item.locationName = newemployeeNotmp.locationName */
                    }
                   
                }}
                console.log(item)
                
            }
               
        })

        if(checktmp === "1"){
           // this.getEmployees(filter)
            this.getCOCSection(articleId);
        }
        

    }

    AddEmployee5=()=>{
        
        this.setState({
            isloading:true
        })
        let IDnew = this.state.articleDataList5.length + 1

        let addData = {
            id              :String(IDnew),
            gridID          : "4",
            articleNo      :"",
            articleName        :"",
            
           
        }

        this.reloadGrid5(addData)
       
    }

    reloadGrid5=(addData)=>{
       
        this.state.articleDataList5.push(addData)
      
        this.setState({
            isloading : false
        })
    
    }

    GridDataModifiedsection5(oldValue, newValue, id, column) {
        ////console.log(id)
        let checktmp = "0"
        let filter = ""
        let filter5 = ""
        let sectionData = this.state.sectionList
        //let employeeData = this.state.getEmployeeList
        this.state.sectionDataList5.map(function(item,i) {
            
            if (item.id===id){
                checktmp = "1"
                item.isModified = newValue!=oldValue ? "1" : "0"
                filter= item.sectionNo
                //filter5= item.employeeName

              /*   if(item.ClientName == newValue){
                    let newClientIdtmp = sectionData.find(x => x.sectionNo === filter);

                    item.ClientId = newClientIdtmp.id

                } */
                if(item.sectionNo == newValue){
                    if(item.sectionNo !== undefined){
                        if(item.sectionNo !== ""){
                        let sectionNametmp = sectionData.find(x => x.sectionNo === filter);

                        item.infraction = sectionNametmp.infraction
                        item.category = sectionNametmp.category
                       /*  item.position   = newemployeeNotmp.position
                        item.locationName = newemployeeNotmp.locationName */
                    }}
                   
                }
                console.log(item)
                
            }
               
        })

        // if(checktmp === "1"){
        //     this.getEmployees(filter)
        // }
        

    }

    AddSection5=()=>{
        
        this.setState({
            isloading:true
        })
        let IDnew = this.state.sectionDataList5.length + 1

        let sectionData = {
            id              :String(IDnew),
            gridID          : "4",
            sectionNo      :"",
            infraction        :"",
            category        :"",
            
           
        }

        this.reloadGridsection5(sectionData)
       
    }

    reloadGridsection5=(sectionData)=>{
       
        this.state.sectionDataList5.push(sectionData)
      
        this.setState({
            isloading : false
        })
    
    }

    render() {

        const articleColumn = [
            {

                dataField   : 'articleNo',
                text        : 'Article No',
                editable    : true,
                editor: {
                    type: Type.SELECT,
                    options: this.state.articleList,
                   
                },
                headerStyle : () => {
                    return { width  : "50%" };
                },
                style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }
                

            },
            {

                dataField   : 'articleName',
                text        : 'Article Name',
                editable    : false,
              /*   editor: {
                    type: Type.SELECT,
                    options: this.state.getEmployeeList
                }, */
                headerStyle : () => {
                    return { width  : "50%" };
                },
                /* formatter: (cell, row) => {
                    if(row.employeeName !=='' || typeof row.employeeName === 'undefined'){
                        if(this.state.employeeDetails.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.employeeDetails.find(x => x.value == cell).value;
                    }
                }, */
                style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }

            },
            // {

            //     dataField   : 'employeeNo',
            //     text        : 'Employee No',
            //     editable    : false,
            //     headerStyle : () => {
            //         return { width  : "14.28%" };
            //     },
            //     style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }

            // },
            // {

            //     dataField   : 'position',
            //     text        : 'Position',
            //     editable    : false,
            //     headerStyle : () => {
            //         return { width  : "14.28%" };
            //     },
            //     style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }

            // },
            // {

            //     dataField   : 'locationName',
            //     text        : 'Location',
            //     editable    : false,
            //     headerStyle : () => {
            //         return { width  : "14.28%" };
            //     },
            //     style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }

            // },
            // {

            //     dataField   : 'retailGroup',
            //     text        : 'Retail Group',
            //     editable    : true,
            //     editor: {
            //         type: Type.SELECT,
            //         options: this.state.retailgroupData
            //     },
            //     headerStyle : () => {
            //         return { width  : "14.28%" };
            //     },
            //     style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }

            // },
            // {

            //     dataField   : 'statusOfMember',
            //     text        : 'status Of Member',
            //     editable    : true,
            //     editor: {
            //         type: Type.SELECT,
            //         options: this.state.statusOfMemberData
            //     },
            //     headerStyle : () => {
            //         return { width  : "14.28%" };
            //     },
            //     style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }

            // },
        ]

        const selectRow = {

            mode: 'radio',
            //clickToSelectAndEditCell: true,
            //clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
                this.state.articleDataList.map(function(item,i){

                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                                           
                    }
                    
                })

            }

        };

        const selectRow2 = {

            mode: 'radio',
            //clickToSelectAndEditCell: true,
            //clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
                this.state.articleDataList2.map(function(item,i){

                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                                           
                    }
                    
                })

            }

        };

        const selectRow3 = {

            mode: 'radio',
            //clickToSelectAndEditCell: true,
            //clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
                this.state.articleDataList3.map(function(item,i){

                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                                           
                    }
                    
                })

            }

        };

        const selectRow4 = {

            mode: 'radio',
            //clickToSelectAndEditCell: true,
            //clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
                this.state.articleDataList4.map(function(item,i){

                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                                           
                    }
                    
                })

            }

        };

        const selectRow5 = {

            mode: 'radio',
            //clickToSelectAndEditCell: true,
            //clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
                this.state.articleDataList5.map(function(item,i){

                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                                           
                    }
                    
                })

            }

        };

        const rowEvents = {

            onClick: (e, row, rowIndex) => {

            }
            
        };

        const sectionColumn = [
            {

                dataField   : 'sectionNo',
                text        : 'sectionNo',
                editable    : true,
                editor: {
                    type: Type.SELECT,
                    options: this.state.sectionList,
                   
                },
                headerStyle : () => {
                    return { width  : "50%" };
                },
                style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }
                

            },
            {

                dataField   : 'infraction',
                text        : 'infraction',
                editable    : false,
              /*   editor: {
                    type: Type.SELECT,
                    options: this.state.getEmployeeList
                }, */
                headerStyle : () => {
                    return { width  : "50%" };
                },
                /* formatter: (cell, row) => {
                    if(row.employeeName !=='' || typeof row.employeeName === 'undefined'){
                        if(this.state.employeeDetails.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.employeeDetails.find(x => x.value == cell).value;
                    }
                }, */
                style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }

            },
            {

                dataField   : 'category',
                text        : 'category',
                editable    : false,
              /*   editor: {
                    type: Type.SELECT,
                    options: this.state.getEmployeeList
                }, */
                headerStyle : () => {
                    return { width  : "50%" };
                },
                /* formatter: (cell, row) => {
                    if(row.employeeName !=='' || typeof row.employeeName === 'undefined'){
                        if(this.state.employeeDetails.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.employeeDetails.find(x => x.value == cell).value;
                    }
                }, */
                style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }

            },
           
        ]

        const selectRowsection = {

            mode: 'radio',
            //clickToSelectAndEditCell: true,
            //clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
                this.state.sectionDataList.map(function(item,i){

                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                                           
                    }
                    
                })

            }

        };

        const selectRowsection2 = {

            mode: 'radio',
            //clickToSelectAndEditCell: true,
            //clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
                this.state.sectionDataList2.map(function(item,i){

                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                                           
                    }
                    
                })

            }

        };
        const selectRowsection3 = {

            mode: 'radio',
            //clickToSelectAndEditCell: true,
            //clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
                this.state.sectionDataList3.map(function(item,i){

                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                                           
                    }
                    
                })

            }

        };
        const selectRowsection4 = {

            mode: 'radio',
            //clickToSelectAndEditCell: true,
            //clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
                this.state.sectionDataList4.map(function(item,i){

                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                                           
                    }
                    
                })

            }

        };
        const selectRowsection5 = {

            mode: 'radio',
            //clickToSelectAndEditCell: true,
            //clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
                this.state.sectionDataList5.map(function(item,i){

                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                                           
                    }
                    
                })

            }

        };
        
        const rowEventssection = {

            onClick: (e, row, rowIndex) => {

            }
            
        };
        
        return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className="modal-90w"
                >
                <Modal.Header closeButton className="card-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        COC References
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="3" style={{fontWeight : "bold"}}>
                                    COC References
                                </Form.Label>
                                <Col sm="5" style={{marginLeft : "-65px"}}>
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.onChangeClientList}
                                        options={this.state.getClientList}
                                        //placeholder="Select Client"
                                    />
                                </Col>
                              
                            </Form.Group>
                        
                            {this.state.clientName !== "" ?
                                <BootstrapTable
                                keyField = "id"
                                data = { this.state.articleDataList }
                                columns = { articleColumn }
                                // pagination={ paginationFactory({sizePerPageRenderer}) }
                                striped
                                hover
                                condensed
                                noDataIndication={ () => <div>No record found.</div> }
                                cellEdit = { cellEditFactory({
                                    mode: 'click',
                                    blurToSave: true,
                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                        this.GridDataModified(oldValue, newValue, row.id, column.dataField , row)
                                        }
                                    })
                                }
                                //defaultSorted={ defaultSorted }
                                selectRow={selectRow}

                            />:null}
                            {this.state.clientName !== "" ?
                            <Form.Group as={Row} controlId="formPlaintextEmail" >
                                <Col  sm={12}>
                                    <ButtonToolbar className="mt-4">
                                        <Button className="ml-auto" variant="success" onClick = { this.AddEmployee } style={{minWidth:'60px',marginTop:"-20px"}}>Add</Button>
                                        
                                    </ButtonToolbar>                                          
                                </Col>                
                            </Form.Group>:null}

                            {this.state.clientName !== "" ?
                                <BootstrapTable
                                keyField = "id"
                                data = { this.state.sectionDataList }
                                columns = { sectionColumn }
                                // pagination={ paginationFactory({sizePerPageRenderer}) }
                                striped
                                hover
                                condensed
                                noDataIndication={ () => <div>No record found.</div> }
                                cellEdit = { cellEditFactory({
                                    mode: 'click',
                                    blurToSave: true,
                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                        this.GridDataModifiedsection(oldValue, newValue, row.id, column.dataField , row)
                                        }
                                    })
                                }
                                //defaultSorted={ defaultSorted }
                                selectRow={selectRowsection}

                            />:null}
                            {this.state.clientName !== "" ?
                            <Form.Group as={Row} controlId="formPlaintextEmail" >
                                <Col  sm={12}>
                                    <ButtonToolbar className="mt-4">
                                        <Button className="ml-auto" variant="success" onClick = { this.AddSection } style={{minWidth:'60px',marginTop:"-20px"}}>Add</Button>
                                        
                                    </ButtonToolbar>                                          
                                </Col>                
                            </Form.Group>:null}

                            {this.state.grid2 == "1" && this.state.clientName !== ""?
                                <BootstrapTable
                                keyField = "id"
                                data = { this.state.articleDataList2 }
                                columns = { articleColumn }
                                // pagination={ paginationFactory({sizePerPageRenderer}) }
                                striped
                                hover
                                condensed
                                noDataIndication={ () => <div>No record found.</div> }
                                cellEdit = { cellEditFactory({
                                    mode: 'click',
                                    blurToSave: true,
                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                        this.GridDataModified2(oldValue, newValue, row.id, column.dataField , row)
                                        }
                                    })
                                }
                                //defaultSorted={ defaultSorted }
                                selectRow={selectRow2}

                            />:null}
                            {this.state.grid2 == "1" && this.state.clientName !== ""?
                            <Form.Group as={Row} controlId="formPlaintextEmail" >
                                <Col  sm={12}>
                                    <ButtonToolbar className="mt-4">
                                        <Button className="ml-auto" variant="success" onClick = { this.AddEmployee2 } style={{minWidth:'60px',marginTop:"-20px"}}>Add</Button>
                                        
                                    </ButtonToolbar>                                          
                                </Col>                
                            </Form.Group>:null}

                            {this.state.grid2 == "1" && this.state.clientName !== ""?
                                <BootstrapTable
                                keyField = "id"
                                data = { this.state.sectionDataList2 }
                                columns = { sectionColumn }
                                // pagination={ paginationFactory({sizePerPageRenderer}) }
                                striped
                                hover
                                condensed
                                noDataIndication={ () => <div>No record found.</div> }
                                cellEdit = { cellEditFactory({
                                    mode: 'click',
                                    blurToSave: true,
                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                        this.GridDataModifiedsection2(oldValue, newValue, row.id, column.dataField , row)
                                        }
                                    })
                                }
                                //defaultSorted={ defaultSorted }
                                selectRow={selectRowsection2}

                            />:null}
                            {this.state.grid2 == "1" && this.state.clientName !== ""?
                            <Form.Group as={Row} controlId="formPlaintextEmail" >
                                <Col  sm={12}>
                                    <ButtonToolbar className="mt-4">
                                        <Button className="ml-auto" variant="success" onClick = { this.AddSection2 } style={{minWidth:'60px',marginTop:"-20px"}}>Add</Button>
                                        
                                    </ButtonToolbar>                                          
                                </Col>                
                            </Form.Group>:null} 

                            {this.state.grid3 == "1" && this.state.clientName !== ""?
                                <BootstrapTable
                                keyField = "id"
                                data = { this.state.articleDataList3 }
                                columns = { articleColumn }
                                // pagination={ paginationFactory({sizePerPageRenderer}) }
                                striped
                                hover
                                condensed
                                noDataIndication={ () => <div>No record found.</div> }
                                cellEdit = { cellEditFactory({
                                    mode: 'click',
                                    blurToSave: true,
                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                        this.GridDataModified3(oldValue, newValue, row.id, column.dataField , row)
                                        }
                                    })
                                }
                                //defaultSorted={ defaultSorted }
                                selectRow={selectRow3}

                            />:null}
                            {this.state.grid3 == "1" && this.state.clientName !== ""?
                            <Form.Group as={Row} controlId="formPlaintextEmail" >
                                <Col  sm={12}>
                                    <ButtonToolbar className="mt-4">
                                        <Button className="ml-auto" variant="success" onClick = { this.AddEmployee3 } style={{minWidth:'60px',marginTop:"-20px"}}>Add</Button>
                                        
                                    </ButtonToolbar>                                          
                                </Col>                
                            </Form.Group>:null}

                            {this.state.grid3 == "1" && this.state.clientName !== ""?
                                <BootstrapTable
                                keyField = "id"
                                data = { this.state.sectionDataList3 }
                                columns = { sectionColumn }
                                // pagination={ paginationFactory({sizePerPageRenderer}) }
                                striped
                                hover
                                condensed
                                noDataIndication={ () => <div>No record found.</div> }
                                cellEdit = { cellEditFactory({
                                    mode: 'click',
                                    blurToSave: true,
                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                        this.GridDataModifiedsection3(oldValue, newValue, row.id, column.dataField , row)
                                        }
                                    })
                                }
                                //defaultSorted={ defaultSorted }
                                selectRow={selectRowsection3}

                            />:null}
                            {this.state.grid3 == "1" && this.state.clientName !== ""?
                            <Form.Group as={Row} controlId="formPlaintextEmail" >
                                <Col  sm={12}>
                                    <ButtonToolbar className="mt-4">
                                        <Button className="ml-auto" variant="success" onClick = { this.AddSection3 } style={{minWidth:'60px',marginTop:"-20px"}}>Add</Button>
                                        
                                    </ButtonToolbar>                                          
                                </Col>                
                            </Form.Group>:null}

                            {this.state.grid4 == "1" && this.state.clientName !== ""?
                                <BootstrapTable
                                keyField = "id"
                                data = { this.state.articleDataList4 }
                                columns = { articleColumn }
                                // pagination={ paginationFactory({sizePerPageRenderer}) }
                                striped
                                hover
                                condensed
                                noDataIndication={ () => <div>No record found.</div> }
                                cellEdit = { cellEditFactory({
                                    mode: 'click',
                                    blurToSave: true,
                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                        this.GridDataModified4(oldValue, newValue, row.id, column.dataField , row)
                                        }
                                    })
                                }
                                //defaultSorted={ defaultSorted }
                                selectRow={selectRow4}

                            />:null}
                            {this.state.grid4 == "1" && this.state.clientName !== ""?
                            <Form.Group as={Row} controlId="formPlaintextEmail" >
                                <Col  sm={12}>
                                    <ButtonToolbar className="mt-4">
                                        <Button className="ml-auto" variant="success" onClick = { this.AddEmployee4 } style={{minWidth:'60px',marginTop:"-20px"}}>Add</Button>
                                        
                                    </ButtonToolbar>                                          
                                </Col>                
                            </Form.Group>:null}

                            {this.state.grid4 == "1" && this.state.clientName !== ""?
                                <BootstrapTable
                                keyField = "id"
                                data = { this.state.sectionDataList4 }
                                columns = { sectionColumn }
                                // pagination={ paginationFactory({sizePerPageRenderer}) }
                                striped
                                hover
                                condensed
                                noDataIndication={ () => <div>No record found.</div> }
                                cellEdit = { cellEditFactory({
                                    mode: 'click',
                                    blurToSave: true,
                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                        this.GridDataModifiedsection4(oldValue, newValue, row.id, column.dataField , row)
                                        }
                                    })
                                }
                                //defaultSorted={ defaultSorted }
                                selectRow={selectRowsection4}

                            />:null}
                            {this.state.grid4 == "1" && this.state.clientName !== ""?
                            <Form.Group as={Row} controlId="formPlaintextEmail" >
                                <Col  sm={12}>
                                    <ButtonToolbar className="mt-4">
                                        <Button className="ml-auto" variant="success" onClick = { this.AddSection4 } style={{minWidth:'60px',marginTop:"-20px"}}>Add</Button>
                                        
                                    </ButtonToolbar>                                          
                                </Col>                
                            </Form.Group>:null}

                            {this.state.grid5 == "1" && this.state.clientName !== ""?
                                <BootstrapTable
                                keyField = "id"
                                data = { this.state.articleDataList5 }
                                columns = { articleColumn }
                                // pagination={ paginationFactory({sizePerPageRenderer}) }
                                striped
                                hover
                                condensed
                                noDataIndication={ () => <div>No record found.</div> }
                                cellEdit = { cellEditFactory({
                                    mode: 'click',
                                    blurToSave: true,
                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                        this.GridDataModified5(oldValue, newValue, row.id, column.dataField , row)
                                        }
                                    })
                                }
                                //defaultSorted={ defaultSorted }
                                selectRow={selectRow5}

                            />:null}
                            {this.state.grid5 == "1" && this.state.clientName !== ""?
                            <Form.Group as={Row} controlId="formPlaintextEmail" >
                                <Col  sm={12}>
                                    <ButtonToolbar className="mt-4">
                                        <Button className="ml-auto" variant="success" onClick = { this.AddEmployee5 } style={{minWidth:'60px',marginTop:"-20px"}}>Add</Button>
                                        
                                    </ButtonToolbar>                                          
                                </Col>                
                            </Form.Group>:null}

                            {this.state.grid5 == "1" && this.state.clientName !== ""?
                                <BootstrapTable
                                keyField = "id"
                                data = { this.state.sectionDataList5 }
                                columns = { sectionColumn }
                                // pagination={ paginationFactory({sizePerPageRenderer}) }
                                striped
                                hover
                                condensed
                                noDataIndication={ () => <div>No record found.</div> }
                                cellEdit = { cellEditFactory({
                                    mode: 'click',
                                    blurToSave: true,
                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                        this.GridDataModifiedsection5(oldValue, newValue, row.id, column.dataField , row)
                                        }
                                    })
                                }
                                //defaultSorted={ defaultSorted }
                                selectRow={selectRowsection5}

                            />:null}
                            {this.state.grid5 == "1" && this.state.clientName !== ""?
                            <Form.Group as={Row} controlId="formPlaintextEmail" >
                                <Col  sm={12}>
                                    <ButtonToolbar className="mt-4">
                                        <Button className="ml-auto" variant="success" onClick = { this.AddSection5 } style={{minWidth:'60px',marginTop:"-20px"}}>Add</Button>
                                        
                                    </ButtonToolbar>                                          
                                </Col>                
                            </Form.Group>:null}


                            <ButtonToolbar>
                                <Button variant="success" onClick={this.handleAddArticle}>
                                    New
                                </Button>&nbsp;
                                <Button variant="success" className="btn btn-info ml-auto" onClick={this.handleSave}>
                                    Save
                                </Button>&nbsp;&nbsp;
                                <Button variant="danger" onClick={this.onModalClose} >
                                    Close
                                </Button>
                            </ButtonToolbar>

                        </Form>
                    </Container>
                </Modal.Body>
               
                <NoserLoading show={this.state.isloading} />
            </Modal>
        );
    }

}
export  default InsertCOCReference