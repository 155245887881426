import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Redirect
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import CreateCaseModal from './CreateCaseModal';

 
class ViewCaseScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            navigate        :   false,

            caseData        :   [],
            ListData        :   [],
            createddate	    : new Date(),
            newDays         : new Date(),
            type            :   "",
            title           :   "",
            description     :   "",
            status          :   "",
            reply           :   "",  
            image           :   "", 
            attachment      :   [],
            caseId          :   "",
            user            :   "",
            priorityLevel   :   "",
            deptAccess      :   "",
            daysOld         :   "",
            dataRowId       :   "",
            newRowList      :   [],
            rowDataList     :   [],
            CreateUpdateKey :   "",
            roleId          :   "",
            userId          :   "",

            orientationTableList :  [],
            CreateCaseModal    :   false,
            RowData : [],
            newRowDataList : [],
            createdUpdate   :   "0"


        }
    }

    componentDidMount(){
        this.getCaseList();
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        // console.log(this.state.userinfo)
        this.setState({ 
            roleId: this.state.userinfo.roleId,
            userId: this.state.userinfo.userId
        })
        let Create = "0"
        //this.createKey();
        //sessionStorage.setItem("CreateUpdateKey", JSON.stringify(this.state.createdUpdate))
        
    };

   /*  createKey () {
        
    } */


   
    GridDataModified(oldValue, newValue, id, column) {
        ////..//console.log(id)
        this.state.caseData.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    };


    showCreateCaseModal = () => {

        const obj = {
            createKey : "0",
        }

        this.child.initialize(obj)
        
        //sessionStorage.setItem("CreateUpdateKey", JSON.stringify(this.state.createdUpdate))
        this.setState({CreateCaseModal: true})
        //
      
    };

    closeModal = (e) => {
        this.setState({CreateCaseModal:false})
        //this.getCaseList();
    };

    selectButton = () => {
        let caseDataTmp = this.state.caseData
        //sessionStorage.setItem("CreateUpdateKey", JSON.stringify(0))
        sessionStorage.setItem("viewConcernData", JSON.stringify(this.state.rowDataList))

        for(let i = 0;i < caseDataTmp.length; i++){
            //..//console.log(caseDataTmp.length)
            if(caseDataTmp[i]['id'] == this.state.dataRowId){
                this.setState({navigate: true})

                sessionStorage.setItem("CreateUpdateKey", JSON.stringify(1))
                let create = sessionStorage.getItem("CreateUpdateKey")
            }
        }
        //this.setState({navigate: true,})
    };

    getCaseList =async() => {

        this.setState({isloading: true})
		let isDeleted = "0"
    
        let filter_data = {"createdby" : this.state.userId /*, "isDeleted" : isDeleted */}

        if (this.state.roleId == "1" || this.state.roleId == "27") {
            filter_data = {"isDeleted" : isDeleted}
            
        }
     
        const getParams = {
            "_collection" : "CreateCase",
            "filter_data" : filter_data
        }

        axios
            .post("http://43.255.218.198:8086/action/get" , getParams)
            .then(res => {
            const data = res.data

            this.componentDidMount();
            const itemDataLists = this.buildList(data["CreateCase"])

            let List = []
            let newCaseData = []
    
            for (let i = 0; i < itemDataLists.length; i++) {
                let priorityLevelTmp1=""
                let statusTmp1=""
               
                if (itemDataLists[i]['status'] === "" || itemDataLists[i]['priorityLevel'] === "") {
                    statusTmp1 = "New"
                    priorityLevelTmp1 = "1"

                } else if(itemDataLists[i]['status'] !== "" || itemDataLists[i]['priorityLevel'] !== ""){
                    statusTmp1 = itemDataLists[i]['status'].replace(" '","").replace("'","")
                    priorityLevelTmp1 = itemDataLists[i]['priorityLevel'].replace(" '","").replace("'","")
             
                } 
                 
                let obj = {
                    'id'            :   itemDataLists[i]['id'].replace(" '","").replace("'",""),
                    'type'          :   itemDataLists[i]['type'].replace(" '","").replace("'",""),
                    'title'         :   itemDataLists[i]['title'].replace(" '","").replace("'",""),
                    'description'   :   itemDataLists[i]['description'].replace(" '","").replace("'",""),
                    'reply'         :   "",
                    'user'          :   itemDataLists[i]['user'],
                    'status'        :   statusTmp1,
                    'priorityLevel' :   priorityLevelTmp1,
                    'employeeId'    :   itemDataLists[i]['employeeId'].replace(" '","").replace("'",""),
                    'deptAccess'    :   itemDataLists[i]['deptAccess'],//.replace(" '","").replace("'",""),
                    'isDeleted'     :   itemDataLists[i]['isDeleted'].replace(" '","").replace("'",""),
                    'isDraft'       :   itemDataLists[i]['isDraft'].replace(" '","").replace("'",""),
                    'createdby'     :   itemDataLists[i]['createdby'].replace(" '","").replace("'",""),
                    'createddate'   :   itemDataLists[i]['createddate'].replace(" '","").replace("'",""),
                    'modifiedby'    :   itemDataLists[i]['modifiedby'].replace(" '","").replace("'",""),
                    'modifieddate'  :   itemDataLists[i]['modifieddate'].replace(" '","").replace("'",""),
                    'isModified'    :   itemDataLists[i]['isModified'].replace(" '","").replace("'",""),
                    'clientid'    	:   itemDataLists[i]['clientid'],//.replace(" '","").replace("'",""),
                }
               //console.log(obj.createddate)
                List.push(obj)
            }   

            //console.log(List.id)
			
            this.setState({ caseData: List.sort((a, b) => new Date(a.createddate) < new Date(b.createddate) ? 1 : -1)})
            //this.setState({ caseData: List.sort((a, b) => a.daysOld < b.daysOld ? 1: -1)})
			
            //this.setState({ caseData : List.sort(function(a,b) { return parseInt(a.createddate)-parseInt(b.createddate)})})
            //this.setState({caseData : List})

            
            for (let i = 0; i < this.state.caseData.length; i++){
				
				//const test = new Date(endDate) - new Date(startDate)
				//const FormatDate = test / (1000 * 60 * 60 * 24);
				//console.log(FormatDate)
				
				if (this.state.caseData[i]['status'] === "CLOSED" || this.state.caseData[i]['status'] === "Closed" || this.state.caseData[i]['status'] === "closed"){
					
					const DiffToStopCounting   = new Date(this.state.caseData[i]['modifieddate']) - new Date(this.state.caseData[i]['createddate'])
					const stopDays = DiffToStopCounting / (1000 * 60 * 60 * 24);
					// const diffInMs   = new Date(endDate) - new Date(startDate)
					// const FormatDate = stopDays / (1000 * 60 * 60 * 24);
					
					let obj = {
                        'id'            :   this.state.caseData[i]['id'],
                        'type'          :   this.state.caseData[i]['type'],
                        'title'         :   this.state.caseData[i]['title'],
                        'description'   :   this.state.caseData[i]['description'],
                        'reply'         :   this.state.caseData[i]['reply'],
                        'user'          :   this.state.caseData[i]['user'],
                        'status'        :   this.state.caseData[i]['status'],
                        'priorityLevel' :   this.state.caseData[i]['priorityLevel'],
                        'employeeId'    :   this.state.caseData[i]['employeeId'],
                        'deptAccess'    :   this.state.caseData[i]['deptAccess'],
                        'clientid'    	:   this.state.caseData[i]['clientid'],
                        'isDeleted'     :   this.state.caseData[i]['isDeleted'],
                        'isDraft'       :   this.state.caseData[i]['isDraft'],
                        'createdby'     :   this.state.caseData[i]['createdby'],
                        'createdby'     :   this.state.caseData[i]['createdby'],
                        'createddate'   :   this.state.caseData[i]['createddate'],
                        'modifiedby'    :   this.state.caseData[i]['modifiedby'],
                        'modifieddate'  :   this.state.caseData[i]['modifieddate'],
                        'isModified'    :   this.state.caseData[i]['isModified'],
                        "daysOld"       :   String(stopDays).replace("-","")
                    }
					newCaseData.push(obj)
				}else {
					const startDate  = this.state.caseData[i]['createddate'];
					const endDate  = moment(this.state.newDays).format('MM/DD/YYYY');
					const diffInMs   = new Date(endDate) - new Date(startDate)
					const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
				
					let obj = {
                        'id'            :   this.state.caseData[i]['id'],
                        'type'          :   this.state.caseData[i]['type'],
                        'title'         :   this.state.caseData[i]['title'],
                        'description'   :   this.state.caseData[i]['description'],
                        'reply'         :   this.state.caseData[i]['reply'],
                        'user'          :   this.state.caseData[i]['user'],
                        'status'        :   this.state.caseData[i]['status'],
                        'priorityLevel' :   this.state.caseData[i]['priorityLevel'],
                        'employeeId'    :   this.state.caseData[i]['employeeId'],
                        'deptAccess'    :   this.state.caseData[i]['deptAccess'],
                        'clientid'    	:   this.state.caseData[i]['clientid'],
                        'isDeleted'     :   this.state.caseData[i]['isDeleted'],
                        'isDraft'       :   this.state.caseData[i]['isDraft'],
                        'createdby'     :   this.state.caseData[i]['createdby'],
                        'createddate'   :   this.state.caseData[i]['createddate'],
                        'modifiedby'    :   this.state.caseData[i]['modifiedby'],
                        'modifieddate'  :   this.state.caseData[i]['modifieddate'],
                        'isModified'    :   this.state.caseData[i]['isModified'],
                        "daysOld"       :   diffInDays
                    }
					newCaseData.push(obj)
				}

				
			}
            //console.log(new Date(newCaseData.createddate))
            this.setState({caseData : newCaseData,isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        
    }
    
    buildList = (data) => {
    
        let itemList =[]

        let idList              =[]
        let typeList            =[]
        let clientIdList        =[]
        let titleList           =[]
        let descriptionList     =[]
        let replyList           =[]
        let userList            =[]
        let statusList          =[]
        let priorityLevelList   =[]
        let employeeIdList      =[]
        let deptAccessList      =[]
        let isDeletedList       =[]
        let isDraftList         =[]
        let createdbyList       =[]
        let createddateList     =[]
        let modifiedbydList     =[]
        let modifieddateList    =[]
        let isModifiedList      =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
            let s1 = data[i].split(":")
            let idClean = s1[1].replace("ObjectId(","").replace(")","").replace("{","").split(",")[0]
            let typTmp = s1[2].split(",")[0]/* .replace("'" , "").replace("'" , "") */
            let titleTmp = s1[3].split(",")[0]
            let descrTmp = s1[4].split(",")[0]
            let replyTmp = s1[5].split(",")[0]
            let userTmp = s1[6].split("',")[0]
            let statusTmp = s1[7].split(",")[0]
            let priorityLevelTmp = s1[8].split(",")[0]
            let employeeIdTmp = s1[9].split(",")[0]
            let deptAccessTmp = s1[10].split(",")[0]
            let isDeletedTmp = s1[11].split(",")[0]
            let isDraftTmp = s1[12].split(",")[0]
            let createdbyTmp = s1[13].split(",")[0]
            let createddateTmp = s1[14].split(",")[0]
            let modifiedbydTmp = s1[15].split(",")[0]
            let modifieddateTmp = s1[16].split(",")[0]
            let isModifiedTmp = s1[17].split(",")[0]
            let clientid = s1[18].split(",")[0].replace("}","")
            
            // let clientid = s1[11].split(",")[0]

            idList.push(idClean)
            typeList.push(typTmp)
            titleList.push(titleTmp)
            descriptionList.push(descrTmp)
            replyList.push(replyTmp)
            userList.push(userTmp)
            statusList.push(statusTmp)
            priorityLevelList.push(priorityLevelTmp)
            employeeIdList.push(employeeIdTmp)
            deptAccessList.push(deptAccessTmp)
            clientIdList.push(clientid)
            isDeletedList.push(isDeletedTmp)
            isDraftList.push(isDraftTmp)
            createdbyList.push(createdbyTmp)
            createddateList.push(createddateTmp)
            modifiedbydList.push(modifiedbydTmp)
            modifieddateList.push(modifieddateTmp)
            isModifiedList.push(isModifiedTmp)
        }
        for (let i = 0; i < idList.length; i++) {
    
            let obj = {
                "id"            :   idList[i].replace(" ","").replace("'","").replace("'",""),
                "type"          :   typeList[i].replace(" ","").replace("'","").replace("'",""),
                "title"         :   titleList[i].replace(" ","").replace("'","").replace("'",""),
                "description"   :   descriptionList[i].replace(" ","").replace("'","").replace("'",""),
                "reply"         :   replyList[i].replace(" ","").replace("'","").replace("'",""),
                "user"          :   userList[i].replace(" ","").replace("'","").replace("'",""),
                "status"        :   statusList[i].replace(" ","").replace("'","").replace("'",""),
                "priorityLevel" :   priorityLevelList[i].replace(" ","").replace("'","").replace("'",""),
                "employeeId"    :   employeeIdList[i].replace(" ","").replace("'","").replace("'",""),
                "deptAccess"    :   deptAccessList[i].replace(" ","").replace("'","").replace("'",""),
                "clientid"    	:   clientIdList[i].replace(" ","").replace("'","").replace("'",""),
                "isDeleted"     :   isDeletedList[i].replace(" ","").replace("'","").replace("'",""),
                "isDraft"       :   isDraftList[i].replace(" ","").replace("'","").replace("'",""),
                "createdby"     :   createdbyList[i].replace(" ","").replace("'","").replace("'",""),
                "createddate"   :   createddateList[i].replace(" ","").replace("'","").replace("'",""),
                "modifiedby"    :   modifiedbydList[i].replace(" ","").replace("'","").replace("'",""),
                "modifieddate"  :   modifieddateList[i].replace(" ","").replace("'","").replace("'",""),
                "isModified"    :   isModifiedList[i].replace(" ","").replace("'","").replace("'",""),

            }
    
            itemList.push(obj)
    
        }
        //console.log(itemList.createddate)
        return itemList
    } 

    


    render() {

        const { navigate } = this.state;
            if (navigate) {
                return <Redirect to="/caseScreen" push={true} />;
            };
        
        const caseConcernColumn = [
            {
                dataField   : 'title',
                text        : 'Concern',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'user',
                text        :'User',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'createddate',
                text        :'Date',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'status',
                text        : 'Status',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'priorityLevel',
                text        : 'SLA',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'daysOld',
                text        : 'Aging',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
        ]

        const selectRow = {
            mode: 'radio',
            //hideSelectColumn: true,
            order: 'desc',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                //..//console.log(row)
                //..//console.log('row')
                this.setState({rowDataList:  row})
                this.state.dataRowId =  row.id
                //..//console.log(this.state.dataRowId)
                //..//console.log("this.state.dataRowId")
                this.state.caseData.map(function(item,idx){
                    if(idx==rowIndex)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })

                this.state.title = row.title
                this.state.user = row.user
                this.state.createddate = row.createddate
                this.state.status = row.status

                
                let newRowListTmp = this.state.newRowList
                //let listTmp =   [];
                let ArrayClear = [];

                if(isSelect){

                    let obj = {
                        "rowid"         :   rowIndex,
                        "title"         :   row.title,
                        "user"          :   row.user,
                        "createddate"   :   row.createddate,
                        "status"        :   row.status,

                        'id'            :   row.id,
                        'reply'         :   row.reply,
                        'priorityLevel' :   row.priorityLevel,
                        'employeeId'    :   row.employeeId,
                        'deptAccess'    :   row.deptAccess,
                        'isDeleted'     :   row.isDeleted,
                        'isDraft'       :   row.isDraft,
                        'createdby'     :   row.createdby,
                        'modifiedby'    :   row.modifiedby,
                        'modifieddate'  :   row.modifieddate,
                        'isModified'    :   row.isModified,
                    } 
                    newRowListTmp.push(obj)
                    //..//console.log(newRowListTmp)
                    //..//console.log(this.state.newRowList + " :newrowlist")
                    this.setState({newRowList: newRowListTmp})
                    //..//console.log("this.state.newRowList")
                    //..//console.log(this.state.newRowList)
                }
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card >
                            <Card.Header>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="10" style={{fontWeight : "bold"}}>
                                    LIST OF CONCERN
                                    </Form.Label>
                                    <Col sm="2">
                                   
                                    <ButtonToolbar >
                                        <Button variant="primary" className="ml-auto" style={{minWidth:'60px', fontWeight : "bold"}} /* disabled={(this.state.roleId === "1" || this.state.roleId === "27") ? true : false} */ onClick={ this.showCreateCaseModal }>
                                            Create Case
                                        </Button>
                                    </ButtonToolbar>
                                    </Col>
                                </Form.Group>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>

                                    <div className="mt-2">
                                        <BootstrapTable
                                            /* caption={Noser.TableHeader({title:"RECORD"})} */
                                            keyField = "id"
                                            data = { this.state.caseData }
                                            columns = { caseConcernColumn }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            rowClasses="noser-table-row-class"
                                            //bordered={ false }
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                            selectRow = { selectRow }
                                        />
                                    </div>
                                </Form>
                                <ButtonToolbar sm={12}>
                                    <Button variant="success" style={{minWidth:'60px'}} className="ml-auto" onClick={this.selectButton}>
                                        Select
                                    </Button>&nbsp;&nbsp;
                                    <Button variant="danger"  href="/home" style={{minWidth:'60px'}}>
                                        Close
                                    </Button>
                                </ButtonToolbar>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                   <NoserLoading show={this.state.isloading} />
                   <CreateCaseModal
                        show={this.state.CreateCaseModal}
                        onHide={this.closeModal}
                        onRefCaseModal={ref => (this.child = ref)}
                   />

            </div> 
        )
    }

}

export  default ViewCaseScreen
