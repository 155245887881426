import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

class EditPositionRates extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            clientDDL   :   [],
            clientId    :   "",
            postionDDL   :   [],
            positionId    :   "",
            position : "",
            area        :   "",
            minRate     :   "",
            areaDDL     : [],
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            clientDDL   :   [],
            postionDDL   :   [],
            area        :   "",
            areaDDL     : [],
            id : e.id,
            clientName : e.clientName,
            clientId    :   e.clientId,
            position : e.name,
            positionId    :   e.positionId,
            minRate : e.rates,
            isDeleted : e.isDeleted,
        })
        this.GetClients();
        this.GetPosition()
        this.GetData();
        console.log(e)
    }
    GetData = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "CompanyId"     :   this.state.userinfo.companyId,
            "positionId"    :   "",
            "Name"          :   "",
            "Rates"         :   "",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollPositionRates",  param)
        .then(res => {
            const data = res.data;
            console.log("GetPayrollMinimumRates")
            console.log(data)
            this.setState({areaDDL:data.payrollPosition,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    GetClients = async() => {
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                         this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            let arr = []
            
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL:arr,isloading:false})
            }
            else{
                this.setState({clientDDL:data.clients,isloading:false})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleChangedClient = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.clientId=""
            this.state.clientName=""
            return
        } 
        this.state.clientId = e[0].id
        this.state.clientName= e[0].name
        
    }

    GetPosition = () => {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  params)
        .then(res => {
            const data = res.data;
            this.setState({ postionDDL : data.positions });
         })
    }
    handleChangedPosition = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.positionId=""
            return
        } 
        this.state.positionId = e[0].id
        this.state.position = e[0].name
    }

    handleChangeMinRate = (e) => {
        //this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        //this.setState({ minRate: e.target.value });
        const minRate = e.target.value;
    
        if (!minRate || minRate.match(/^\d{1,}(\.\d{0,4})?$/)) {
          this.setState(() => ({ minRate }));
        }
    };
    
    IsDecimal(val)
    {
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        return !regex.test(val)
    }
    IsNumeric(evt){
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    handleSubmit = (e) =>{
        this.setState({isloading:true})
                
        // if(this.state.areaDDL.some(item =>item.clientId === this.state.clientId && item.name === this.state.position ) === true ){
        //     this.setState({
        //         isloading       :   false,
        //         alerttype       :   "Error!",
        //         isshow          :   true,
        //         color           :   "danger",
        //         message         :    "client and position name already exist!",
        //         fade            :   true
        //     })
        //     return  
        // }

        if(!this.state.clientId){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   true
            })
            return 
        }

        if(!this.state.positionId){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select position.",
                fade        :   true
            })
            return 
        }

        if(this.state.minRate==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter min. rate.",
                fade        :   true
            })
            return 
        }

        const param = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId,
            "payrollPosition":   [{
                    "Id"        :   this.state.id,
                    "ClientId"  :   this.state.clientId,
                    "PositionId":   this.state.positionId,
                    "Name"      :   this.state.position,
                    "Rates"     :   this.state.minRate,
                    "ModifiedBy":   this.state.userinfo.userId,
                    "IsDeleted" :   this.state.isDeleted,
                }
            ]
        }
        console.log("param")
        console.log(param)
        
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditPayrollPositionRates",  param)
            .then(res => {
                const data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true
                });
                this.GetData();
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }
    handleModalClose = () => {
        this.props.onHide();            
    }
    handleKeyUp = (e) =>{

    }
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        EDIT PAYROLL POSITION RATES
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                           {/*  <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                Company
                            </Form.Label>
                            <Col>
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangedClient}
                                    options={this.state.clientDDL}
                                    defaultSelected={[this.state.clientName]}
                                />
                            </Col> */}
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            POSITION
                            </Form.Label>
                            <Col>
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangedPosition}
                                    options={this.state.postionDDL}
                                    defaultSelected={[this.state.position]}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                MIN RATE
                            </Form.Label>
                            <Col>
                                <Form.Control 
                                    name='minRate'
                                    type="text" 
                                    onChange={this.handleChangeMinRate} 
                                    autoComplete="off" 
                                    value={this.state.minRate}
                                    //onKeyPress={this.IsNumeric.bind(this)}
                                />
                            </Col>
                        </Form.Group>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleSubmit } style={{marginRight:"5px"}}>Save</Button>
                        <Button variant="danger" className="noser-button-mr1" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default EditPositionRates