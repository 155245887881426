import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

class AnnualTaxTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            selected: [],

            annualTaxTableList:[],
            annualTaxTableListGrid:[],
            newAnnualTaxTableList: [],
            selectedSalaryFrom:"",
            selectedSalaryFromId:"",
            selectedSalaryToId:"",
            selectedSalaryTo:""
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetAnnualTaxTables();
        
        sleep(1000).then(() => {
        this.setState({isLoading:false})})
    }

    handleCoverChangeSalaryFrom = (e) => {
        if (e.length > 0) {
            this.state.selectedSalaryFrom = e[0].name
            this.state.selectedSalaryFromId = e[0].id

        } else {
            this.state.selectedSalaryFrom = ""
            this.state.selectedSalaryFromId = ""
        }
        
        console.log(this.state.selectedSalaryFrom);
       
    } 

    handleCoverChangeSalaryto = (e) => {
        if (e.length > 0) {
            this.state.selectedSalaryTo = e[0].name
            this.state.selectedSalaryToId = e[0].id

        } else {
            this.state.selectedSalaryTo = ""
            this.state.selectedSalaryToId = ""
        }
        
        console.log(this.state.selectedSalaryTo);
       
    } 

   

    GetAnnualTaxTables() {

        const getParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "SalaryFrom":this.state.selectedSalaryFrom,
            "SalaryTo":this.state.selectedSalaryTo
         };
 
         axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetAnnualTaxTables",  getParams
             )
             .then(res => {
                 const data = res.data;
                 console.log(data.taxTables);
                 this.setState({ annualTaxTableList: data.taxTables });
             })

    }

    handleSearchClick = event => {
        this.setState({annualTaxTableListGrid:[], isloading:true})

        const annualTaxParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "SalaryFrom":this.state.selectedSalaryFrom,
            "SalaryTo":this.state.selectedSalaryTo
            
        };
        console.log(annualTaxParams)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetAnnualTaxTables",  annualTaxParams)
            .then(res => {
            const data = res.data;
            console.log(res.data)
            this.setState({annualTaxTableListGrid: data.taxTables,  isloading:false})
            // this.processGrid();
            if(data.status=="1"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   data.message,
                    fade            :   true
                });
            }
            else{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }

    handleSaveClick = event => {
        this.setState({newAnnualTaxTableList: [],isLoading:true})

        
        console.log(this.state.newAnnualTaxTableList)
        for (let i = 0; i < this.state.annualTaxTableListGrid.length; i++) {
            if (this.state.annualTaxTableListGrid[i]["isModified"] == 1) {
                const obj = {
                    Id: this.state.annualTaxTableListGrid[i]["id"],
                    SalaryFrom: this.state.annualTaxTableListGrid[i]["salaryFrom"],
                    SalaryTo :this.state.annualTaxTableListGrid[i]["salaryTo"],
                    RatePercentage :this.state.annualTaxTableListGrid[i]["ratePercentage"],
                    RateAmount :this.state.annualTaxTableListGrid[i]["rateAmount"],
		            RateExcessOver :this.state.annualTaxTableListGrid[i]["rateExcessOver"],
                    IsDeleted: this.state.annualTaxTableListGrid[i]["isDeleted"].toString()
                };
               

                this.state.newAnnualTaxTableList.push(obj);
            }
        }
       


        
    const saveParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.userinfo.clientId,
        "UserId":this.state.userinfo.userId,
        "annualTaxTables":this.state.newAnnualTaxTableList
        };
        console.log(saveParams)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditAnnualTaxTable", saveParams)
            .then(res => {
                const data = res.data;
                this.setState({isLoading:false})
                this.refreshPage();
                var alertType = (data.status=="1") ? "success" : "danger"
                this.setState(
                    {
                        isLoading:false,
                        AlertType:"Success!",
                        show:true,
                        Color:alertType,
                        Message:data.message ,
                        Fade:true
                    });
            })
            .catch(error=>{
                this.setState(
                {
                    isLoading:false,
                    AlertType:"Error! ",
                    Show:true,
                    Color:"danger",
                    Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    Fade:true
                })
            })
    }  

    LeavePageWithourSavingChanges() {
        const isChanged=false
        for (let i = 0; i < this.state.annualTaxTableListGrid.length; i++) {
            if (this.state.annualTaxTableListGrid[i]["isModified"] == 1) {
                this.setState({isGridDataChanged: true})
                isChanged=true
                break;
            }
        }
        return isChanged
    }

    GridDataModified(oldValue, newValue,id, column) {
        console.log(id)
        this.state.annualTaxTableListGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }

    refreshPage(){
        this.setState({isloading:true})

        const refreshParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "SalaryFrom":"",
            "SalaryTo":""
        };

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetAnnualTaxTables",  refreshParams)
            .then(res => {
                const data = res.data;
                this.setState({
                    annualTaxTableListGrid: data.taxTables, 
                    isloading       :   false,
                })
                
            })
    
        }

    render() {
        const columns1 = [
            {
                dataField: 'salaryFrom',
                text: 'SALARY FROM'
            },
            {
                dataField: 'salaryTo',
                text: 'SALARY TO'
            },
            {
                dataField: 'ratePercentage',
                text: 'RATE PERCENTAGE'
            },
            {
                dataField: 'rateAmount',
                text: 'RATE AMOUNT'
            },
            {
                dataField: 'rateExcessOver',
                text: 'RATE EXCESS OVER'
            
            }] 
       
       
    const selectRow = {
        mode: 'checkbox',
        clickToSelectAndEditCell: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            this.state.annualTaxTableListGrid.map(function(item,i){
                if(item.id===row.id)
                {
                    item.isDeleted = isSelect ? "1" : "0"
                    item.isModified = isSelect ? "1" : "0"
                }
            })
         }
    };

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
          //alert(e.row);
        }
    };

        return(
            <div>
                <Banner />
                    <Container className="mt-5">
                        <Card>
                            <Card.Header>Annual Tax Table</Card.Header>
                            <Card.Body>
                                <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Row>
                                        <Col sm={6}>
                                            <Typeahead
                                                labelKey='salaryFrom'
                                                id="basic-example"
                                                onChange={this.handleCoverChangeSalaryFrom}
                                                options={this.state.annualTaxTableList}
                                                placeholder="Salary From"
                                            />  
                                        </Col>
                                        <Col sm={6}>
                                            <Typeahead
                                                labelKey='salaryTo'
                                                id="basic-example"
                                                onChange={this.handleCoverChangeSalaryTo}
                                                options={this.state.annualTaxTableList}
                                                placeholder="Salary To"
                                            /> 
                                        </Col>
                                    </Form.Row>
                                    
                                    <ButtonToolbar sm={12} className="mt-3">
                                        <Button variant="primary"  onClick={this.handleSearchClick}>
                                            Search
                                        </Button>&nbsp;&nbsp;
                                        <NavLink to="/AnnualTaxTableCreate">
                                            <Button  variant="primary" variant="success">
                                                Create
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                    <div className="mt-5">
                                        <BootstrapTable
                                            ref="tbl"
                                            caption={Noser.TableHeader({title:"Search Result"})}
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            keyField = "id"
                                            data = { this.state.annualTaxTableListGrid }
                                            columns = { columns1}
                                            selectRow = { selectRow }
                                            cellEdit = { cellEditFactory({
                                                    mode: 'dbclick',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                this.GridDataModified(oldValue, newValue, row.id, column)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }

                                            />
                                        <ButtonToolbar sm={12} className="mt-3">
                                                <Button variant="success" className="ml-auto" onClick={this.handleSaveClick}>
                                                    Save
                                                </Button>&nbsp;&nbsp;
                                                <NavLink to="/home">
                                                    <Button variant="danger" href="/banner">
                                                        Close
                                                    </Button>
                                                </NavLink>
                                            </ButtonToolbar>
                                    </div>
                                        
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
            </div> 
            
        )
    }
}

export default AnnualTaxTable;