import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../noser-hris-component';

class EmployeeContractCreate extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            name        :   '',
            disableBtn  :   true,

            contracttypeId:"",
            contracttype:"",
            clientId:"",
            clientName:"",
            employeeId:"",
            employeeName:"",
            lastName:"",
            branchId:"",
            branchName:"",
            positionId:"",
            positionName:"",
            employmentstatusId:"",
            contractStart:"",
            contractEnd:"",
            salaryRate:"",
            issuedbyId:"",
            issuerDDL:[],
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            name        :   '',
            disableBtn  :   true,

            id:e.id,
            contracttypeId:e.contractTypeId,
            contracttype:e.contractType,
            clientId:e.clientId,
            clientName:e.client,
            employeeId:e.employeeId,
            employeeName:e.employeeName,
            lastName:e.lastName,
            branchId:e.branchId,
            branchName:e.branch,
            positionId:e.positionId,
            positionName:e.position,
            employmentstatusId:e.employmentStatusId,
            contractStart:e.contractDateStart,
            contractEnd:e.contractDateEnd,
            salaryRate:e.salaryRate,
            issuedbyId:e.issuedById,
            returnedById:"",
            returnbyDDL:[],
        })
        //e.returnedDate = new Date()
        console.log(e)
        this.GetApprovers()
    }
    GetApprovers = async()=> {
        this.setState({isloading:true});
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   "111",
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeApprovers",  params)
        .then(res => {
            const data = res.data;
            this.setState({returnbyDDL:data.approvers,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleSubmit = async() => {
        
        if(this.state.returnedById==="")
        {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select return by name.",
                fade        :   true
            })
            return
        }

        this.setState({isloading:true,isDisable:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "contracts":[{
                "Id" : this.state.id,
                "EmployeeId" : this.state.employeeId,
                "ContractTypeId" : this.state.contracttypeId,
                "ContractDateStart" : this.state.contractStart,
                "ContractDateEnd" : this.state.contractEnd,
                "EmploymentStatusId" : this.state.employmentstatusId,
                "SalaryRate" : this.state.salaryRate,
                "IssuedById" : this.state.issuedbyId,
                "Remarks" : "RETURNED",
                "ReturnedById" : this.state.returnedById,
                "ReturnedDate" : moment(new Date()).format('MM/DD/YYYY'),
                "IsGenerated" : "1",
                "IsDeleted" : "0",
            }]
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/EditContract", params)
        .then(res => {
            const data = res.data
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                name        :   ''
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    handleChangedReturnBy = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.returnedById=""
            return
        }
        this.state.returnedById=e[0].id
    }
    render() {
        return(
            
                <Modal
                    {...this.props}
                    return
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                >
                    <Modal.Header closeButton className="card-header">
                        <Modal.Title id="contained-modal-title-vcenter">
                            GENERATE CONTRACT
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    CONTRACT TYPE
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control 
                                            name='contracttype'
                                            type="text" 
                                            autoComplete="off" 
                                            value={this.state.contracttype}
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    CLIENT NAME
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control 
                                            name='clientName'
                                            type="text" 
                                            autoComplete="off" 
                                            value={this.state.clientName}
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    BRANCH NAME
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control 
                                            name='branchName'
                                            type="text" 
                                            autoComplete="off" 
                                            value={this.state.branchName}
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    EMPLOYEE NAME
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control 
                                            name='employeeName'
                                            type="text" 
                                            autoComplete="off" 
                                            value={this.state.employeeName}
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    RETURN BY
                                </Form.Label>
                                <Col sm="10">
                                    <Typeahead
                                        id="returnedById"
                                        name="returnedById"
                                        labelKey='employeeName'
                                        onChange={this.handleChangedReturnBy}
                                        options={this.state.returnbyDDL}
                                        placeholder="Select return by name"
                                    />
                                </Col>
                            </Form.Group>
                            </Form>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonToolbar >
                            <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleSubmit }>SUBMIT</Button>
                            <Button variant="danger" className="noser-button-mr15" onClick={this.props.onHide}>CANCEL</Button>
                        </ButtonToolbar>
                    </Modal.Footer>
                    <NoserLoading show={this.state.isloading} />
            </Modal>
            );
        }

}

export  default EmployeeContractCreate