import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../noser-hris-component'

class BatchDownload extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            disable     :   true,
            
            clientId        :   "",
            clientName      :   "",
            locationId      :   "",
            employeeId      :   "",
            statusId        :   "",
            clientDDL       :   [],
            locationDDL     :   [],
            employeeDDL     :   [],
            statusDDL       :   [
                {"id":"","name":"ALL"},
                {"id":"0","name":"ACTIVE"},
                {"id":"1","name":"INACTIVE"}
            ],
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            disable     :   true,
            
            clientId        :   "",
            clientName      :   "",
            locationId      :   "",
            employeeId      :   "",
            statusId        :   "",
            clientDDL       :   [],
            locationDDL     :   [],
            employeeDDL     :   [],
            statusDDL       :   [
                {"id":"","name":"ALL"},
                {"id":"0","name":"ACTIVE"},
                {"id":"1","name":"INACTIVE"}
            ],
        })
        this.GetClients()
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL : data.clients ? data.clients : [], isloading:false})
            if(data.clients.length===0){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    disable     :   true
                });
            }    
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetLocations = async() =>{
        this.setState({isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.clientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", params)
        .then(res => {
            const data = res.data;
            this.setState({locationDDL : data.locations ? data.locations : [], isloading:false})
            if(data.locations.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message==="No record found." ? "No branch found" : data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    GetEmployees = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "LocationId":this.state.locationId,
            "IsDeleted":this.state.statusId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetConfigurationEmployees", params)
        .then(res => {
            const data = res.data;
            this.setState({employeeDDL : data.employees ? data.employees:[],disable:false, isloading:false})
            if(data.employees.length===0){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "No employee found",
                    fade        :   true,
                    disable     :   true,
                });
            }  
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        
    }
    handleChangeClient = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.clientId= ''
            this.state.clientName=''
            return
        }
        this.state.clientId= e[0].id
        this.state.clientName=e[0].name
        this.GetLocations()
        this.GetEmployees()
        this.state.disable=false
    }
    handleChangeLocation = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.locationId= ''
            return
        }
        this.state.locationId= e[0].id
        this.GetEmployees()
    }
    handleChangeStatus = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.statusId= ''
            return
        }
        this.state.statusId= e[0].id
        this.GetEmployees()
    }
    handleChangeEmployee = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.employeeId= ''
            return
        }
        this.state.employeeId= e[0].id
        this.GetEmployees()
    }
    handleDownloadTemplate = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:true})

        if(this.state.clientId==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   true,

                overlayShow:true,
                overlayTarget:this.refs.firstName
            })
            return 
        }
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "LocationId"    :   this.state.locationId,
            "StatusId"      :   this.state.statusId,
            "EmployeeId"    :   this.state.employeeId,
            "UserId"        :   this.state.userinfo.userId
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/ExportBatchUpdate", params,{'responseType': 'blob'})
        .then(res => {
            console.log(res.data)
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "EmployeeBatchUpload.xlsx");
            document.body.appendChild(link);
            link.click();
            this.setState({isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleModalClose = () => {
        this.props.onHide();            
    }
    handleChangeUpperCase = (e) =>{
        this.setState({[e.target.name]: e.target.value.toUpperCase()})
    }
    handleChange = (e) =>{
        this.setState({[e.target.name]: e.target.value})
    }
    handleChangeRole = (e) =>{
        this.setState({
            alerttype   :   "",
            isshow      :   false,
            color       :   "",
            message     :   "",
            fade        :   false
        });
        if(e.length==0)
        {
            this.state.roleId= ''
            return
        }
        this.state.roleId= e[0].id
    }
    render() {
    return(
            <>
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        DOWNLOAD BATCH EMPLOYEE TEMPLATE
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeClient}
                                    options={this.state.clientDDL.filter(x=>x.id!=="111")}
                                    placeholder="Select Client"

                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeLocation}
                                    options={this.state.locationDDL}
                                    placeholder="Select Location"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} controlId="formHorizontalEmail">
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeStatus}
                                    options={this.state.statusDDL}
                                    placeholder="Select Status"

                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Typeahead
                                    labelKey='employeeName'
                                    id="basic-example"
                                    onChange={this.handleChangeEmployee}
                                    options={this.state.employeeDDL}
                                    placeholder="Select Employee"
                                />
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button disabled={this.state.disable} variant="success" style={{minWidth:'60px',marginRight:'1pt'}} className="ml-auto" onClick = { this.handleDownloadTemplate }>Download</Button>
                        <Button variant="danger" style={{minWidth:'60px',marginRight:'15px'}} onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
                
        </Modal>
        </>
        );
    }
}
export default BatchDownload
