import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Tabs, Tab,
    Redirect
} 
from '../../noser-hris-component';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
class ManageEmployeeApprovals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            disablebutton   :   true,
            navigateApproval:   false,
            navigateView    :   false,
            
            clientId        :   "",
            clientName      :   "",
            locationId      :   "",
            employeeId      :   "",
            statusId        :   "",
            statusName      :   "",
            clientDDL       :   [],
            locationDDL     :   [],
            employeeDDL     :   [],
            statusDDL       :   [
                {"id":"","name":"ALL"},
                {"id":"0","name":"FOR APPROVAL"},
                {"id":"1","name":"APPROVED"},
                {"id":"2","name":"REJECTED"}
            ],
            employeeGRID    :   [],

            clientLst       :   [],
            branchLst       :   [],
            yesNoLst        :   [
                {"value":"0","label" : "NO"},
                {"value":"1","label" : "YES"}
            ],
            nationalityLst  :   [],
            religionLst     :   [],
            civilStatusLst  :   [
                {"label":"SINGLE","value":"1"},
                {"label":"MARRIED","value":"2"},
                {"label":"DIVORCED","value":"3"},
                {"label":"SEPERATED","value":"4"}

            ],
            arrangementLst  :   [],
            bloodTypeLst    :   [],
            positionLst     :   [],
            payrollTypeLst  :   [],
            payrollModeLst  :   [],
            employeeStatusLst:  [],
            paycardTypeLst  :   []
        }
    } 
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()
        this.state.disablebutton = this.state.userinfo.roleId!=="1" || this.state.userinfo.roleId!=="8" ? true : false
        
    }
    
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL : data.clients ? data.clients : []}) 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetSubmittedEmployees()
    }
    GetEmployees = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeList", params)
        .then(res => {
            const data = res.data;
            this.setState({employeeDDL : data.employees,isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        
    }
    
    handleChangeClient = (e) =>{
        //this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0)
        {
            this.state.clientId= ''
            this.state.clientName=''
            return
        }
        this.state.clientId=e[0].id
        this.state.clientName=e[0].name
        this.GetEmployees()
    }
    handleChangeStatus = (e) =>{
        //this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0)
        {
            this.state.statusId=""
            this.state.statusName=""
            return
        }
        this.state.statusId=e[0].id
        this.state.statusName=e[0].name
    }
    handleChangeEmployee = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.employeeId= ''
            return
        }
        this.state.employeeId= e[0].id
    }
    
    GetSubmittedEmployees = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.clientId,
            "RoleId":this.state.userinfo.roleId,
            "EmployeeId":this.state.employeeId,
            "ApprovedById":this.state.userinfo.employeeId,
            "StatusId":this.state.statusId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/GetLibrarianRequestForApprovals", params)
        .then(res => {
            const data = res.data;
            this.setState({employeeGRID : data.requests ? data.requests : [], isloading:false})
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }  
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleSearchClick = () =>{
        this.GetSubmittedEmployees()
    }
    handleSubmitClick = async(statusid) =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "RoleId":this.state.userinfo.roleId,
            "StatusId":statusid,
            "ApprovedById":this.state.userinfo.employeeId,
            "requests":this.state.employeeGRID.filter(x=>x.isModified==="1")
            
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/ApprovedLibrarianRequest", params)
        .then(res => {
            const data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   data.statusId==="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.statusId==="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                disablebutton:  false
            }); 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    render(){
        
        let roleId = this.state.userinfo.roleId
        const employeeCol = [
            {
                dataField: 'status',
                text: 'STATUS',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%',whiteSpace:'nowrap'}},
                style:{textAlign:'center',width:'8%',whiteSpace:'nowrap'},
            },
            {
                dataField: 'submittedByName',
                text: 'SUBMITTED BY',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%',whiteSpace:'nowrap'}},
                style:{textAlign:'center',width:'8%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'submittedDate',
                text: 'DATE SUBMITTED',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%',whiteSpace:'nowrap'}},
                style:{textAlign:'center',width:'8%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'approvedByName',
                text: 'APPROVED / REJECTED BY',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'19%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'19%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'approvedDate',
                text: 'APPR / REJ DATE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'}
            },
            /* {
                dataField: 'clientName',
                text: 'CLIENT',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'15%',whiteSpace:'nowrap'}
            }, */
            {
                dataField: 'cabinet',
                text: 'CABINET',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'15%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'drawer',
                text: 'DRAWER',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'15%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'requestType',
                text: 'Request Type',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'10%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'requestByName',
                text: 'Request By',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'15%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'requestDate',
                text: 'Request Date',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'10%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'branchName',
                text: 'OFFICE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'10%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'remarks',
                text: 'REMARKS',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'20%',whiteSpace:'nowrap'}
            },
        ]
        
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable=true
                this.state.employeeGRID.map(function(item,i){
                    if(item.id===row.id)
                    {
                        if(row.statusId==="0"){
                            item.isModified = isSelect ? "1" : "0"
                        }
                    }
                    if (item.isModified=="1")
                    disable =  false
                })
                //disable = this.state.userinfo.roleId!=="1" && this.state.userinfo.roleId!=="34" ? true : false
                this.setState({disablebutton:disable})
            }
        };
        const { ExportCSVButton } = CSVExport;
        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>Manning >> Employee File Pullout Request Approvals</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                               {/*  <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    CLIENT : 
                                    </Form.Label>
                                    <Col sm={11}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientDDL}
                                            //placeholder="Select Client"
                                            selected={[this.state.clientName]}
                                        />
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    EMPLOYEE : 
                                    </Form.Label>
                                    <Col sm={11}>
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.handleChangeEmployee}
                                            options={this.state.employeeDDL}
                                            //placeholder="Select Employee"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    STATUS : 
                                    </Form.Label>
                                    <Col sm={11}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeStatus}
                                            options={this.state.statusDDL}
                                            //placeholder="Select Status"
                                            selected={[this.state.statusName]}
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar className="mt-1">
                                    <Button variant="primary" className="ml-auto noser-button-mr1" onClick={ this.handleSearchClick }>Search</Button>
                                    <Button variant="success" className="noser-button" href="/createrequest">Create</Button>
                                </ButtonToolbar>
                                <div className="mt-2">
                                <ToolkitProvider
                                        keyField="id"   
                                        data={ this.state.employeeGRID }
                                        columns = { employeeCol }
                                        exportCSV={ {
                                            fileName: "Manage Employee Approvals.csv",
                                            noAutoBOM: false,
                                            separator: ',',
                                            blobType: "text/plain;charset=utf-8,%EF%BB%BF"
                                        } }
                                        >
                                        {
                                            props => (
                                            <div>
                                                
                                                <hr />
                                                <ExportCSVButton disabled={this.state.disableExport} className="btn btn-success" { ...props.csvProps }>Export CSV!!</ExportCSVButton>
                                                <Card.Header>EMPLOYEE APPROVAL LIST</Card.Header>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.employeeGRID }
                                                    columns = { employeeCol }
                                                    selectRow = { selectRow }
                                                    cellEdit = { cellEditFactory({ 
                                                            mode: 'dbclick', 
                                                            blurToSave: true,
                                                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                                                
                                                            }
                                                        })
                                                    }
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    loading={true}
                                                    wrapperClasses="table-responsive"
                                                    pagination={ paginationFactory({sizePerPageRenderer})}
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    
                                                />
                                            </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                </div>
                                <ButtonToolbar className="mt-1">
                                <Button disabled={this.state.disablebutton}  className="ml-auto noser-button-mr1" variant="success" onClick={() =>this.handleSubmitClick('1')}>APPROVE</Button>
                                <Button disabled={this.state.disablebutton}  className="noser-button-mr1" variant="danger" onClick={() =>this.handleSubmitClick('2')}>REJECT</Button>
                                </ButtonToolbar>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div>
        )
    }
}

export default ManageEmployeeApprovals;