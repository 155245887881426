import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer
}
from '../../noser-hris-component';
import CreateRateCard from './Modal/CreateRateCard';

class RateCard extends Component {
     constructor() {
        super() 
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,	
            openModal       :   false,
            isdisable       :   true,

            rateCode        :   "",
            rateName        :   "",

            codeDDL         :   [],
            nameDDL         :   [],
            rateGrid        :   [],
            yesNoDDL        :   [
                {"value" : "0", "label" : "NO"},
                {"value" : "1", "label" : "YES"}
            ]
        }

   }
 
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetRateElements();
    } 
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.GetRateElements()
        this.setState({isLoading:false,openModal:false})
    }
    handleChangedName = (e) => {
        if (e.length > 0) {

            this.state.name = e[0].name
            
        }  else {
            this.state.name = ""
        }  
    }
    handleChangedCode= (e) => {
        if (e.length > 0) {
            this.state.code =  e[0].code
            
        }else {
            this.state.code = ""
        }

    }
    GetRateElements = async() =>{
        this.setState({isLoading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId": this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "Code":this.state.code,
            "Name":this.state.name,
        };

        await axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRateElements",  params
            )
            .then(res => {
                const data = res.data;
                this.setState({ codeDDL:data.elements,
                                nameDDL:data.elements});
                this.handleSearchClick()
            })
            .catch(error=>{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })
    } 

    
    handleSearchClick = async() => {
        this.setState({isloading:true})
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "Code":this.state.code,
            "Name":this.state.name,
          };

        await axios
            .post(
                 AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRateElements",  params
                 
            )
            .then(res => {
                const data = res.data;
                this.setState({rateGrid:data.elements,isloading:false});
                if(data.status==="0"){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   data.message,
                        fade            :   true
                    });
                }
            })
            .catch(error=>{
               this.setState(  {
                   isloading       :   false,
                   alerttype       :   "Error!",
                   isshow          :   true,
                   color           :   "danger",
                   message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                   fade            :   true
               })
           })
 
    }
    handleSubmit = async() => {
        this.setState({isloading:true})
        
        if(this.state.rateGrid.filter(x=>x.isModified==="1").length===0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Warning!",
                isshow      :   true,
                color       :   "warning",
                message     :   "No record to save.",
                fade        :   true
            })
            return 
        }
        
        const  params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId, //this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "RateElements":this.state.rateGrid.filter(x=>x.isModified==="1")
        };
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditRateElement",   params)
            .then(res => {
                const data = res.data;
                this.handleSearchClick()
                this.setState({
                    isloading       :   false,
                    alerttype       :   data.status==="1" ? "Success!" : "Error!",
                    isshow          :   true,
                    color           :   data.status==="1" ? "success" : "danger",
                    message         :   data.message,
                    fade            :   true
                });
                
            })
            .catch(error=>{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })
    }
    GridDataModified(oldValue, newValue, id, column) {
        let isdisable = true
        this.state.rateGrid.map(function(item,i){
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
            }
            if(item.isModified=="1")
                isdisable=false
        })
        this.setState({isdisable:isdisable})
    }
    render() {
          const col = [
            {
                dataField: 'code',
                text: 'CODE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%' }},
                style:{textAlign:'left',width:'15%'},
                sort:true
            },
            {
                dataField: 'name',
                text: 'NAME',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%' }},
                style:{textAlign:'left',width:'20%'},
                sort:true
            },
            {
                dataField: 'percentage',
                text: 'PERCENTAGE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center',width:'10%'},
                validator: (newValue, row, column) => {
                    if (newValue==="") {
                      return {
                        valid: false,
                        message: 'Percentage cannot be empty.'
                      };
                    }
                    if (isNaN(parseFloat(newValue))) {
                      return {
                        valid: false,
                        message: 'Invalid percentage value.'
                      };
                    }
                    if (parseFloat(newValue)<=0) {
                        return {
                          valid: false,
                          message: 'Invalid percentage value.'
                        };
                      }
                    return true;
                  }
            },
            {
                dataField: 'isAddBasic',
                text: 'Add to Basic',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'55%' }},
                style:{textAlign:'left',width:'55%'},
                formatter: (cell, row) => {
                    if(row.isAddBasic!=='' && row.isAddBasic!==null){
                        if(this.state.yesNoDDL.filter(x => x.value === cell).length==0)
                        return ""
                    else
                        return this.state.yesNoDDL.find(x => x.value === cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.yesNoDDL
                }
            },
            // {
            //     dataField: 'isOverride',
            //     text: 'Override Standard',
            //     editable: true,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'10%' }},
            //     style:{textAlign:'center',width:'10%'},
            //     sort:true
            // },
            // {
            //     dataField: 'isStandard',
            //     text: 'Standard',
            //     editable: true,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'left',width:'35%' }},
            //     style:{textAlign:'left',width:'35%'},
            //     sort:true
            // }
        ]
       
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isdisable = true;
                this.state.rateGrid.map(function(item,i){
                    if(item.id===row.id)
                        {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted=="1")
                        isdisable=false
                })
                this.setState({isdisable:isdisable})
            }
        };
    return(
        <div>
            <Banner />
            <Container className="themed-container" fluid={true}>
                <Card className="mt-5">
                    <Card.Header>Rate Card Element</Card.Header>
                    <Card.Body>
                        <Form  >
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                         <Typeahead
                                            labelKey='code'
                                            id="basic-example"
                                            onChange={this.handleChangedCode}
                                            options={this.state.codeDDL}
                                            placeholder="Search by Element Code"
                                        />
                                    </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                     <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangedName}
                                        options={this.state.nameDDL}
                                        placeholder="Search by Element Name"
                                        />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <ButtonToolbar>
                                        <Button variant="primary" className="ml-auto noser-button-mr1" onClick={ this.handleSearchClick }>Search</Button>
                                    </ButtonToolbar>
                                </Col>
                            </Form.Group>
                            <Card.Header>LIST OF RATE ELEMENTS</Card.Header>
                            <div className="mt-1">
                                <BootstrapTable
                                    keyField = "id"
                                    data = { this.state.rateGrid }
                                    columns = { col }
                                    pagination={ paginationFactory({sizePerPageRenderer}) }
                                    rowClasses="noser-table-row-class"
                                    striped
                                    hover
                                    condensed
                                    cellEdit = { cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                            this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                            }
                                        })
                                    }
                                    selectRow = { selectRow }
                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }        
                                />
                            </div>
                            <ButtonToolbar sm={12}>
                                <Button variant="success" disabled={this.state.isdisable} className="ml-auto noser-button-mr1" onClick = { this.handleSubmit }>Save</Button>
                                <Button variant="primary" className="noser-button" onClick = { this.handleModalShow }>CREATE</Button>
                            </ButtonToolbar>
                        </Form>
                    </Card.Body>
                </Card>
                <NoserLoading show={this.state.isloading} />
                <CreateRateCard
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </Container>  

        </div>
    )}

}

export  default RateCard