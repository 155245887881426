import {
    React,Component,BootstrapTable, Type, Redirect,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

class PendingJobOffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            

            selected: [],
            jobOfferList:[],
            jobOfferListGrid:[],
            selectedClient:"",
            selectedClientId:"",
            selectedStatusId : "1",
            clientList:[],
            employeeDDL : [],
            selectedFullName:"",
            selectedFullNameId:"",
            positionId:"",
            position:"",
            referenceNo:"",
            selectedApplicationFormId:"",
            status:"",
            statusId:"",
            statusList:[
                {"name":"ALL","id":""},
                {"name":"FOR APPROVAL","id":"1"},
                {"name":"APPROVED","id":"2"},
                {"name":"REJECT","id":"3"},
            ],
            isDisableDisApprove     :   true,
            isDisableApprove        :   true,

            
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetJobOffers();
    }

    GetClient = async () => {
        this.setState({isloading:true,})
        const clientParams = {
            "IpAddress":"0.0.0.0",
           "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", clientParams)
        .then(res => {
            const data = res.data;
            this.setState({clientList : data.clients})
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetJobOffers();
    }
    GetJobOffers = async () =>{
        this.setState({isloading:true,})
        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "CompanyId":this.state.userinfo.companyId,
            "ReferenceNo":"" ,
            "ApplicationFormId":this.state.selectedApplicationFormId,
            "PositionId":"",
            "StatusId":this.state.selectedStatusId,
            "RoleId" : this.state.userinfo.roleId
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetJobOfferForApprovals",  params)
        .then(res => {
            const data = res.data;
            this.setState({ jobOfferListGrid: data.jobOffers, isloading:false});
            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
            
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetEmployee();
    }
    GetEmployee = async () =>{
        this.setState({isloading:true,})
        const jobParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "CompanyId":this.state.userinfo.companyId,
            "ReferenceNo":"" ,
            "ApplicationFormId":"",
            "PositionId":"",
            "StatusId":this.state.selectedStatusId,
            "RoleId" : this.state.userinfo.roleId
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetJobOfferFilter",  jobParams)
        .then(res => {
            const data = res.data;
            this.setState({ employeeDDL: data.jobOffers, isloading:false});
            
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangeClient= (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.userinfo.companyId=""
            return
        } 
        this.state.userinfo.companyId = e[0].id
        this.state.selectedClientName = e[0].name
        this.setState({isloading:false,})
        this.GetEmployee();
    }
    handleCoverChangeFullName = (e) => {
        if(e.length == 0) {
            this.setState   ({
                selectedFullName:"",
                positionId : "", 
                referenceNo: "",
                selectedApplicationFormId:""
            })
            return
        } 
        this.setState({
            selectedFullName : e[0].memberName,
            referenceNo : e[0].referenceNo,
            positionId : e[0].positionId,
            selectedApplicationFormId : e[0].applicationFormId
        })
        this.setState({isloading:false,})
    } 

    handleCoverChangeStatus= (e) => {
        if(e.length == 0) {
            this.state.status=""
            this.state.selectedStatusId=""
            return
        } 
        this.state.status = e[0].name
        this.state.selectedStatusId = e[0].id
        this.GetEmployee()
        if(this.state.status === "ALL") {
            this.state.isDisableDisApprove     =   false
            this.state.isDisableApprove        =   false
        }

        if(this.state.selectedStatusId === "1") {
            this.state.isDisableDisApprove     =   true
            this.state.isDisableApprove        =   true
        }

        if(this.state.selectedStatusId === "2") {
            this.state.isDisableDisApprove     =   true
            this.state.isDisableApprove        =   false
        }

        if(this.state.selectedStatusId === "3") {
            this.state.isDisableApprove     =   true
            this.state.isDisableDisApprove  =   false
        }
        this.setState({isloading:false,})
    }

   
    handleSearchClick = event => {
        this.GetJobOffers();
    }
    handleViewPending = (row) =>{
        sessionStorage.setItem("vwPendingJO" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
        this.setState({navigate: true,pendingdata:row})
    }

    handleViewApplicationClick = (row) => {
        sessionStorage.setItem("viewapplicationform_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
        this.setState({
            navigateviewapplication   :   true
        })
    }

    render() {
        const { navigate, navigateviewapplication  } = this.state;
        if (navigate) {
            return <Redirect to="/pendingjobofferedit" push={true} />;
        } 
        if (navigateviewapplication) {
            return <Redirect to="/viewapplication" push={true} />;
        } 
        const columns1 = [
            {
                dataField: 'memberName',
                text: 'FULL NAME',
                headerStyle: (colum, colIndex) => {
                    return { width:'25%', textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'},
                formatter   :   (cell, row, isSelect) => {
                    if(row)
                    return (
                        <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                            variant="link" onClick={e => this.handleViewApplicationClick(row)}
                    >{cell}</Button>
                    );
                }
            },
           /*  {
                dataField: 'client',
                text: 'CLIENT',
                headerStyle: (colum, colIndex) => {
                    return { width:'20%', textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            }, */
            {
                dataField: 'location',
                text: 'BRANCH',
                headerStyle: (colum, colIndex) => {
                    return { width:'25%', textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'position',
                text: 'JOB POSITION',
                headerStyle: (colum, colIndex) => {
                    return { width:'22%', textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'salaryOffered',
                text: 'SALARY RATE',
                headerStyle: (colum, colIndex) => {
                    return { width:'10%', textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'right',whiteSpace:'nowrap'}
            },
            {
                dataField: 'status',
                text: 'STATUS',
                headerStyle: (colum, colIndex) => {
                    return { width:'10%', textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'}
            },
            {
                dataField: 'action',
                text: 'ACTION',
                editable:false,
                formatter   :   (cell, row, isSelect) => {
                    if(row) 
                        return (
                            <Button variant="link" style={{height:'14px',paddingTop:'0',marginTop:'-6px',fontSize:'10px'}}
                            //href="/pendingjobofferedit"
                            onClick={e => this.handleViewPending(row)}
                            >View</Button>
                        );
                    },
                headerStyle: (colum, colIndex) => {
                    return { width:'8%', textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'}
                
            }] 
       
        

            const rowEvents = {
                onClick: (e, row, rowIndex) => {
                  //alert(e.row);
                }
            };
    
            const selectRow = {
                mode: 'checkbox',
                clickToSelectAndEditCell: true,
                onSelect: (row, isSelect, rowIndex, e) => {
                    this.state.jobOfferListGrid.map(function(item,i){
                        if(item.id===row.id)
                        {
                            item.isDeleted = isSelect ? "1" : "0"
                            item.isModified = isSelect ? "1" : "0"
                        }
                    })
                }
            };

        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>ADMINISTRATOR >> ADMIN >> JOB OFFER (FOR APPROVAL)</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        STATUS
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleCoverChangeStatus}
                                                options={this.state.statusList}
                                            /> 
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        FULL NAME
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='memberName'
                                                id="basic-example"
                                                onChange={this.handleCoverChangeFullName}
                                                options={this.state.employeeDDL}
                                            /> 
                                        </Col>
                                    </Form.Group>
                                    <ButtonToolbar sm={12} className="mt-3">
                                        <Button variant="primary" className="ml-auto" onClick={this.handleSearchClick}>
                                            Search
                                        </Button>&nbsp;&nbsp;
                                    </ButtonToolbar>

                                    <Card.Header className="mt-3">record list</Card.Header>
                                    <div className="mt-1">
                                        <BootstrapTable
                                            /* caption={Noser.TableHeader({title:"RECORD"})} */
                                            keyField = "id"
                                            data = { this.state.jobOfferListGrid }
                                            columns = { columns1 }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            rowClasses="noser-table-row-class no-checkbox"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                            selectRow = { selectRow }

                                        />
                                    </div>
                                    <ButtonToolbar sm={12} >
                                        <NavLink className="ml-auto" to="/home">
                                            <Button className="ml-auto" variant="danger" href="/home">
                                                Close
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                        
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div> 
            
        )
    }
}

export default PendingJobOffer;
