import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker,  Tabs, Tab
} 
from '../../noser-hris-component';
import NoserDataGrid from './PayrollGrid';

class ReportPayrollSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            disablereprocess:   false,
            
            tabkey: "",
            isSpecial:"0",
            payrollTypeId:"0",
            
            employeeDDL     :   [],
            periodDDL       :   [],

            payrollGRID     :   [],
            payrollSummary  :   [],
            payrollAdjGRID  :   [],
            payrollAdjSummary:  [],
            payrollOffGRID  :   [],
            payrollOffSummary:  [],
            
            premMain        :   [],
            dedMain         :   [],
            incMain         :   [],
            paycardTypes    :   [],
            
            inclusions      :   [],
            deductions      :   [],
            premiums        :   [] ,

            employeeId      :   '',
            employeeName    :   '',
            periodLists     :   [],
            periodId        :   ""

        }

    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetDeductions()
    }
    GetDeductions = async() => {
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId
         };
         
        await axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDeductions",  params
             )
             .then(res => {
                const data = res.data;
                data.deductions.push(
                    { "id":"-1","name":"SSS"},
                    { "id":"-2","name":"PHIC"},
                    { "id":"-3","name":"HDMF"}
                )
                console.log(data.deductions)
                this.setState({dedMain: data.deductions,isloading:false })
                this.GetInclusions()
             })
    }
    GetInclusions = async() => {
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId
         };
         
        await axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetInclusions",  params
             )
             .then(res => {
                const data = res.data;
                data.inclusions.push({
                    "id":"-1",
                    "name":"No of Days"
                })
                this.setState({incMain: data.inclusions,isloading:false })
                this.GetRateElements()
             })
    }
    GetRateElements = async() => {
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":"0",
            "UserId":this.state.userinfo.userId
         };
         
        await axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRateElements",  params
             )
             .then(res => {
                const data = res.data;
                data.elements.push({
                    "id":"-1",
                    "code":"No of Hrs",
                    "isStandard":"1"
                })
                this.setState({premMain: data.elements.filter(x=>x.isStandard==="1"),isloading:false })
                this.GetPayCardTypes()
             })
    }
    GetPayCardTypes = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":"0",
            "UserId":this.state.userinfo.userId
         };
         
        await axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes",  params
             )
             .then(res => {
                const data = res.data;
                this.setState({paycardTypes: data.payCardTypes,isloading:false })
             })
            this.GetEmployees()
    }
    GetEmployees = async() =>{
        //this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"      :   this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", params)
        .then(res => {
            const data = res.data
            this.setState({ employeeDDL : data.profiles}) 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetPeriods()
    }
    GetPeriods = async() => {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"      :   this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   this.state.employeeId ? this.state.employeeId : "",
            "IsProcessed"   :   "1"
         };
        await axios
             .post(
                AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods",  params
             )
             .then(res => {
                const data = res.data;
                console.log("data")
                console.log(data)
                this.setState({
                    periodDDL   :   data.payrollPeriods,
                    isloading       :   false 
                })
             })
    }

    onChangeEmployeesList = (e) => {
        if(e.length==0)
        {
            this.setState({
                getemployeeDDLData             :   null,
                disableNoOfIns                  :   true,
                selectedEmployeeLeadTime        :   '',
                selectedEmployeePayMode         :   '',
                selectedEmployeePayModeId       :   '',
                selectedWorkScheduleIdParam     :   '',
                selectedPayrollPeriodsId        :   '',
                employeeId              :   '',
                employeeName            :   '',
                selectedPayrollPeriodsName      :   '',
                selectedWorkScheduleId          :   ''
            })
            return
        }

        this.state.employeeId           =   e[0].id
        this.state.selectedEmployeePayModeId    =   e[0].payModeId
        this.state.selectedEmployeePayMode      =   e[0].payMode
        this.state.selectedPayrollPeriodsId     =   e[0].payPeriodId
        this.state.employeeName         =   e[0].employeeName
        this.state.selectedEmployeeNo           =   e[0].employeeNo
        this.state.selectedEmployeeBranch       =   e[0].locationName
        this.state.selectedEmployeeLeadTime     =   e[0].leadTime
    }

    onChangeEmployeesNo(e){
        this.setState({selectedEmployeeNo:e.target.value})
    }
    onChangeEmployeeBranch(e){
        this.setState({selectedEmployeeBranch:e.target.value})
    }
    
    onChangePayPeriod = (e) => {
        // let periodList = [];
        // for(let i = 0; i < e.length; i++){
        //     const obj = {
        //         "PayPeriodId" : e[i]["periodId"]
        //     }
        //     periodList.push(obj)
        //     this.setState({
        //         periodDDL : periodList,
        //         isshow      :   false
        //     })
        // }

        if(e.length==0)
        {
            this.setState({periodId:""})
            return
        }
        this.setState({periodId:e[0]["periodId"]})
    }
    handleSearchClick = async()=>{
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"     :   this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   this.state.employeeId,
            //"Periods"       :   this.state.periodLists,
            "PayPeriodId"   :   this.state.periodId,
            "LocationId"    :   this.state.selectedLocationId,
            "IsOffCycle"    :   "0",
            "IsProcessed"   :   "1",
            "PayrollTypeId" :   this.state.payrollTypeId
        }
        console.log("params")
        console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetPayrolls", params)
        .then(res => {
            const data = res.data
            console.log("payroll result")
            console.log(data)

            let tmppayroll = []
            data.payrolls.forEach((itm,idx) => {
                let payroll = {
                    hc:idx+1,
                    id:itm.id,
                    employeeName:itm.memberName,
                    employeeNo:itm.memberNo,
                    position:itm.position,
                    payCardNumber:itm.payCardNumber,
                    salaryOffered: parseFloat(itm.salaryOffered).toFixed(2),
                    colaRate:parseFloat(itm.colaRate).toFixed(2),
                    seaRate:parseFloat(itm.seaRate).toFixed(2),
                    eColaRate:parseFloat(itm.eColaRate).toFixed(2),
                    basicPayDays:parseFloat(itm.basicPayDays).toFixed(2),
                    basicPayHrs:parseFloat(itm.basicPayHrs).toFixed(2),
                    basicPayAmount:parseFloat(itm.basicPayAmount).toFixed(2),
                    colaDays:parseFloat(itm.colaDays).toFixed(2),
                    colaAmount:parseFloat(itm.colaAmount).toFixed(2),
                    eColaDays:parseFloat(itm.eColaDays).toFixed(2),
                    eColaAmount:parseFloat(itm.eColaAmount).toFixed(2),
                    seaDays:parseFloat(itm.seaDays).toFixed(2),
                    seaAmount:parseFloat(itm.seaAmount).toFixed(2),
                    lateMins:parseFloat(itm.lateMins).toFixed(2),
                    lateAmount:parseFloat(itm.lateAmount).toFixed(2),
                    undertimeMins:parseFloat(itm.undertimeMins).toFixed(2),
                    undertimeAmount:parseFloat(itm.undertimeAmount).toFixed(2),
                    absentDays:parseFloat(itm.absentDays).toFixed(2),
                    absentAmount:parseFloat(itm.absentAmount).toFixed(2),
                    netRegularPay:parseFloat(itm.netRegularPay).toFixed(2),
                    grossPay:parseFloat(itm.grossPay).toFixed(2),
                    totalPremiums:parseFloat(itm.totalPremiums).toFixed(2),
                    totalInclusions:parseFloat(itm.totalInclusions).toFixed(2),
                    totalGrossPay:parseFloat(itm.totalGrossPay).toFixed(2),
                    totalDeductions:parseFloat(itm.totalDeductions).toFixed(2),
                    netPay:parseFloat(itm.netPay).toFixed(2)
                }
                
                itm.premiums.forEach((prem,idx)=>{
                    payroll["No of Hrs"] = parseFloat(prem.nofHrs).toFixed(2)
                    payroll[prem.rateCode] = parseFloat(prem.amount).toFixed(2)
                })
                
                itm.inclusions.forEach(inc=>{
                    if(parseFloat(inc.noOfDays)>0){
                        payroll["No of Days"] = parseFloat(inc.noOfDays).toFixed(2)
                    }
                    payroll[inc.inclusion] = parseFloat(inc.amount).toFixed(2)
                    
                })
                
                itm.deductions.forEach(ded=>{
                    payroll[ded.deductionName] = parseFloat(ded.amount).toFixed(2)
                })
                
                this.state.paycardTypes.forEach(pc=>{
                    if(pc.id===itm.payCardTypeId){
                        payroll[pc.name] = parseFloat(itm.netPay).toFixed(2)
                    }
                })

                tmppayroll.push(payroll)
            });
            

            const res1 = tmppayroll.reduce((result, item) => {
            const keys = Object.keys(item);
            keys.forEach(key => {
                if (this.state.dedMain.filter(x=>x.name===key).length>0){
                    //result[key] = result[key] === undefined ? result[key] : result[key].replace(".00","")
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
                if (this.state.incMain.filter(x=>x.name===key).length>0){
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
                if (this.state.premMain.filter(x=>x.code===key).length>0){
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
                if (this.state.paycardTypes.filter(x=>x.name===key).length>0){
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
                if(key==="salaryOffered" || key==="colaRate" || key==="seaRate" || key==="eColaRate" || key==="basicPayDays" ||
                key==="basicPayHrs" || key==="basicPayAmount" || key==="colaDays" || key==="colaAmount" || key==="eColaDays" ||
                key==="eColaAmount" || key==="seaDays" || key==="seaAmount" || key==="lateMins" || key==="lateAmount" ||
                key==="undertimeMins" || key==="undertimeAmount" || key==="absentDays" || key==="absentAmount" || key==="netRegularPay" ||
                key==="grossPay" || key==="totalPremiums" || key==="totalInclusions" || key==="totalGrossPay" || key==="totalDeductions" || key==="netPay"){
                    
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
            });
            return result;
            }, { id:'0',hc:'',employeeName: '',position:'',payCardNumber:''});
            tmppayroll.push(res1);        

            console.log("regular")
            console.log(tmppayroll)
            this.setState({isloading:false,payrollGrID:tmppayroll,payrollSummary:data.paycardSummary})
            this.state.payrollGrID = tmppayroll
            console.log(this.state.payrollGrID)
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
	this.GetOffCyclePayrollSummary()
    }
    GetOffCyclePayrollSummary = async() =>{
        //this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"     :   this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   this.state.employeeId,
            //"Periods"       :   this.state.periodLists,
            "PayPeriodId"   :   this.state.periodId,
            "LocationId"    :   this.state.selectedLocationId,
            "IsProcessed"   :   "1",
            "IsOffCycle"    :   "1"
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetPayrolls", getParams)
        .then(res => {
            const data = res.data
            console.log("offcycle")
            console.log(data)
            let tmppayroll = []
            data.payrolls.forEach((itm,idx) => {
                let payroll = {
                    hc:idx+1,
                    id:itm.id,
                    employeeName:itm.memberName,
                    employeeNo:itm.memberNo,
                    position:itm.position,
                    payCardNumber:itm.payCardNumber,
                    salaryOffered: parseFloat(itm.salaryOffered).toFixed(2),
                    colaRate:parseFloat(itm.colaRate).toFixed(2),
                    seaRate:parseFloat(itm.seaRate).toFixed(2),
                    eColaRate:parseFloat(itm.eColaRate).toFixed(2),
                    basicPayDays:parseFloat(itm.basicPayDays).toFixed(2),
                    basicPayHrs:parseFloat(itm.basicPayHrs).toFixed(2),
                    basicPayAmount:parseFloat(itm.basicPayAmount).toFixed(2),
                    colaDays:parseFloat(itm.colaDays).toFixed(2),
                    colaAmount:parseFloat(itm.colaAmount).toFixed(2),
                    eColaDays:parseFloat(itm.eColaDays).toFixed(2),
                    eColaAmount:parseFloat(itm.eColaAmount).toFixed(2),
                    seaDays:parseFloat(itm.seaDays).toFixed(2),
                    seaAmount:parseFloat(itm.seaAmount).toFixed(2),
                    lateMins:parseFloat(itm.lateMins).toFixed(2),
                    lateAmount:parseFloat(itm.lateAmount).toFixed(2),
                    undertimeMins:parseFloat(itm.undertimeMins).toFixed(2),
                    undertimeAmount:parseFloat(itm.undertimeAmount).toFixed(2),
                    absentDays:parseFloat(itm.absentDays).toFixed(2),
                    absentAmount:parseFloat(itm.absentAmount).toFixed(2),
                    netRegularPay:parseFloat(itm.netRegularPay).toFixed(2),
                    grossPay:parseFloat(itm.grossPay).toFixed(2),
                    totalPremiums:parseFloat(itm.totalPremiums).toFixed(2),
                    totalInclusions:parseFloat(itm.totalInclusions).toFixed(2),
                    totalGrossPay:parseFloat(itm.totalGrossPay).toFixed(2),
                    totalDeductions:parseFloat(itm.totalDeductions).toFixed(2),
                    netPay:parseFloat(itm.netPay).toFixed(2)
                }
                
                itm.premiums.forEach((prem,idx)=>{
                    payroll["No of Hrs"] = parseFloat(prem.nofHrs).toFixed(2)
                    payroll[prem.rateCode] = parseFloat(prem.amount).toFixed(2)
                })
                
                itm.inclusions.forEach(inc=>{
                    if(parseFloat(inc.noOfDays)>0){
                        payroll["No of Days"] = parseFloat(inc.noOfDays).toFixed(2)
                    }
                    payroll[inc.inclusion] = parseFloat(inc.amount).toFixed(2)
                    
                })
                
                itm.deductions.forEach(ded=>{
                    payroll[ded.deductionName] = parseFloat(ded.amount).toFixed(2)
                })
                
                this.state.paycardTypes.forEach(pc=>{
                    if(pc.id===itm.payCardTypeId){
                        payroll[pc.name] = parseFloat(itm.netPay).toFixed(2)
                    }
                })

                tmppayroll.push(payroll)
            });
            
            
            const res1 = tmppayroll.reduce((result, item) => {
            const keys = Object.keys(item);
            keys.forEach(key => {
                if (this.state.dedMain.filter(x=>x.name===key).length>0){
                    //result[key] = result[key] === undefined ? result[key] : result[key].replace(".00","")
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
                if (this.state.incMain.filter(x=>x.name===key).length>0){
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
                if (this.state.premMain.filter(x=>x.code===key).length>0){
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
                if (this.state.paycardTypes.filter(x=>x.name===key).length>0){
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
                if(key==="salaryOffered" || key==="colaRate" || key==="seaRate" || key==="eColaRate" || key==="basicPayDays" ||
                key==="basicPayHrs" || key==="basicPayAmount" || key==="colaDays" || key==="colaAmount" || key==="eColaDays" ||
                key==="eColaAmount" || key==="seaDays" || key==="seaAmount" || key==="lateMins" || key==="lateAmount" ||
                key==="undertimeMins" || key==="undertimeAmount" || key==="absentDays" || key==="absentAmount" || key==="netRegularPay" ||
                key==="grossPay" || key==="totalPremiums" || key==="totalInclusions" || key==="totalGrossPay" || key==="totalDeductions" || key==="netPay"){
                    
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
            });
            return result;
            }, { id:'0',hc:'',employeeName: '',position:'',payCardNumber:''});
            tmppayroll.push(res1);        

            console.log("tmppayroll")
            console.log(tmppayroll)
            this.setState({isloading:false,payrollOffGRID:tmppayroll,payrollOffSummary:data.paycardSummary})
            console.log(this.state.payrollGRID)
            // this.setState({
            //     payrollOffGRID:data.payrolls,
            //     payrollOffSummary:data.paycardSummary,
            //     //isloading:false,
            // })
            this.GetAdjustmentPayrollSummary()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetAdjustmentPayrollSummary = async() =>{
        //this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"     :   this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   this.state.employeeId,
            //"Periods"       :   this.state.periodLists,
            "PayPeriodId"   :   this.state.periodId,
            "LocationId"    :   this.state.selectedLocationId,
            "IsProcessed"   :   "1",
            "IsOffCycle"    :   "2"
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetPayrolls", getParams)
        .then(res => {
            const data = res.data
            console.log("adj")
            console.log(data)
            let tmppayroll = []
            data.payrolls.forEach((itm,idx) => {
                let payroll = {
                    hc:idx+1,
                    id:itm.id,
                    employeeName:itm.memberName,
                    employeeNo:itm.memberNo,
                    position:itm.position,
                    payCardNumber:itm.payCardNumber,
                    salaryOffered: parseFloat(itm.salaryOffered).toFixed(2),
                    colaRate:parseFloat(itm.colaRate).toFixed(2),
                    seaRate:parseFloat(itm.seaRate).toFixed(2),
                    eColaRate:parseFloat(itm.eColaRate).toFixed(2),
                    basicPayDays:parseFloat(itm.basicPayDays).toFixed(2),
                    basicPayHrs:parseFloat(itm.basicPayHrs).toFixed(2),
                    basicPayAmount:parseFloat(itm.basicPayAmount).toFixed(2),
                    colaDays:parseFloat(itm.colaDays).toFixed(2),
                    colaAmount:parseFloat(itm.colaAmount).toFixed(2),
                    eColaDays:parseFloat(itm.eColaDays).toFixed(2),
                    eColaAmount:parseFloat(itm.eColaAmount).toFixed(2),
                    seaDays:parseFloat(itm.seaDays).toFixed(2),
                    seaAmount:parseFloat(itm.seaAmount).toFixed(2),
                    lateMins:parseFloat(itm.lateMins).toFixed(2),
                    lateAmount:parseFloat(itm.lateAmount).toFixed(2),
                    undertimeMins:parseFloat(itm.undertimeMins).toFixed(2),
                    undertimeAmount:parseFloat(itm.undertimeAmount).toFixed(2),
                    absentDays:parseFloat(itm.absentDays).toFixed(2),
                    absentAmount:parseFloat(itm.absentAmount).toFixed(2),
                    netRegularPay:parseFloat(itm.netRegularPay).toFixed(2),
                    grossPay:parseFloat(itm.grossPay).toFixed(2),
                    totalPremiums:parseFloat(itm.totalPremiums).toFixed(2),
                    totalInclusions:parseFloat(itm.totalInclusions).toFixed(2),
                    totalGrossPay:parseFloat(itm.totalGrossPay).toFixed(2),
                    totalDeductions:parseFloat(itm.totalDeductions).toFixed(2),
                    netPay:parseFloat(itm.netPay).toFixed(2)
                }
                
                itm.premiums.forEach((prem,idx)=>{
                    payroll["No of Hrs"] = parseFloat(prem.nofHrs).toFixed(2)
                    payroll[prem.rateCode] = parseFloat(prem.amount).toFixed(2)
                })
                
                itm.inclusions.forEach(inc=>{
                    if(parseFloat(inc.noOfDays)>0){
                        payroll["No of Days"] = parseFloat(inc.noOfDays).toFixed(2)
                    }
                    payroll[inc.inclusion] = parseFloat(inc.amount).toFixed(2)
                    
                })
                
                itm.deductions.forEach(ded=>{
                    payroll[ded.deductionName] = parseFloat(ded.amount).toFixed(2)
                })
                
                this.state.paycardTypes.forEach(pc=>{
                    if(pc.id===itm.payCardTypeId){
                        payroll[pc.name] = parseFloat(itm.netPay).toFixed(2)
                    }
                })

                tmppayroll.push(payroll)
            });
            

            const res1 = tmppayroll.reduce((result, item) => {
            const keys = Object.keys(item);
            keys.forEach(key => {
                if (this.state.dedMain.filter(x=>x.name===key).length>0){
                    //result[key] = result[key] === undefined ? result[key] : result[key].replace(".00","")
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
                if (this.state.incMain.filter(x=>x.name===key).length>0){
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
                if (this.state.premMain.filter(x=>x.code===key).length>0){
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
                if (this.state.paycardTypes.filter(x=>x.name===key).length>0){
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
                if(key==="salaryOffered" || key==="colaRate" || key==="seaRate" || key==="eColaRate" || key==="basicPayDays" ||
                key==="basicPayHrs" || key==="basicPayAmount" || key==="colaDays" || key==="colaAmount" || key==="eColaDays" ||
                key==="eColaAmount" || key==="seaDays" || key==="seaAmount" || key==="lateMins" || key==="lateAmount" ||
                key==="undertimeMins" || key==="undertimeAmount" || key==="absentDays" || key==="absentAmount" || key==="netRegularPay" ||
                key==="grossPay" || key==="totalPremiums" || key==="totalInclusions" || key==="totalGrossPay" || key==="totalDeductions" || key==="netPay"){
                    
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
            });
            return result;
            }, { id:'0',hc:'',employeeName: '',position:'',payCardNumber:''});
            tmppayroll.push(res1);        

            console.log("tmppayroll")
            console.log(tmppayroll)
            this.setState({isloading:false,payrollAdjGRID:tmppayroll,payrollAdjSummary:data.paycardSummary})
            
            // this.setState({
            //     payrollAdjGRID:data.payrolls,
            //     payrollAdjSummary:data.paycardSummary,
            //     isloading:false,
            // })
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleReProcessClick = async(e) => {

        if(this.state.userinfo.companyId==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   true
            })
            return 
        }

        if(this.state.periodLists.length===0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select atleast one payroll period.",
                fade        :   true
            })
            return 
        }
        if (!window.confirm('Do you want to re-process ledger transaction?'))
        return
        this.setState({isloading:true,disablereprocess:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"      :   this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   this.state.employeeId,
            "PayPeriodId"   :   this.state.periodLists[0]["PayPeriodId"],
            "LocationId"    :   this.state.selectedLocationId,
            "IsOffCycle": "0"
        }
        
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/ReProcessTransactions", params)
        .then(res => {
            const data = res.data
            this.setState({
                isloading   :   false,
                alerttype   :   data.status=="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                disablereprocess:false
            })
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleSelect = (key) => {
        this.setState({ tabkey: key });
    }
    render() {
        const columnPayrollCardSummary = [
            {
                dataField   :   'name',
                text        :   'Pay Card Type',
                editable    :    false,
                headerStyle : () => {
                    return { width  : "50%" };
                }
            },
            {
                dataField   :   'count',
                text        :   'Count',
                editable    :    false,
                headerStyle : () => {
                    return { width  : "50%" };
                }
            },
        ] 
        const colOffCycleSummary = [
            {
                dataField   :   'name',
                text        :   'Pay Card Type',
                editable    :    false,
                headerStyle : () => {
                    return { width  : "50%" };
                }
            },
            {
                dataField   :   'count',
                text        :   'Count',
                editable    :    false,
                headerStyle : () => {
                    return { width  : "50%" };
                }
            },
        ] 
        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>Generate Report Payroll Summary</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Row>
                                    <Form.Group as={Col} sm={4} controlId="formGridEmail">
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.onChangeEmployeesList}
                                            options={this.state.employeeDDL}
                                            placeholder="Select Employee"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} controlId="formGridPassword">
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Employee No" 
                                            autoComplete="off" 
                                            name="employeeNo"
                                            value={this.state.selectedEmployeeNo}
                                            onChange={this.onChangeEmployeesNo.bind(this)}
                                            readOnly/>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} controlId="formGridPassword">
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Employee Branch" 
                                            autoComplete="off" 
                                            name="employeeBranch"
                                            value={this.state.selectedEmployeeBranch}
                                            onChange={this.onChangeEmployeeBranch.bind(this)}
                                            readOnly/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='payPeriod'
                                            id="basic-example"
                                            onChange={this.onChangePayPeriod}
                                            options={this.state.periodDDL}
                                            placeholder="Select Payroll Cut-Off"
                                            //multiple
                                            //clearButton
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar sm={12}>
                                    <Button variant="primary" disabled={this.state.disablereprocess} className="ml-auto noser-button-mr1" onClick = { this.handleReProcessClick }>
                                        REPROCESS LEDGER
                                    </Button>
                                    <Button variant="success" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>
                                
                                <Tabs onSelect={this.handleSelect} activeKey={this.state.key} defaultActiveKey="default" transition={false} id="noanim-tab-example" >
                                    <Tab eventKey="default" title="PAYROLL SUMMARY">
                                        <Form.Group controlId="formGridPassword">
                                        {/* <NoserGrid
                                            data = { this.state.payrollSummaryListTable }
                                            exportCSV={true}
                                            pageSize={150}
                                        /> */}
                                        <NoserDataGrid 
                                            data={this.state.payrollGrID}
                                            deductions={this.state.dedMain}
                                            inclusions={this.state.incMain}
                                            premiums={this.state.premMain}
                                            paycards={this.state.paycardTypes}
                                        />
                                        </Form.Group>
                                        {/* <div className="mt-5">
                                            <BootstrapTable
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                keyField = "name"
                                                data = { this.state.payrollSummary }
                                                columns = { columnPayrollCardSummary}
                                                cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                            />
                                        </div>  */}
                                    </Tab>
                                    <Tab eventKey="offcycle" title="PAYROLL SUMMARY (OFF CYCLE)">
                                        <Form.Group controlId="formGridPassword">
                                        {/* <NoserGrid
                                            data = { this.state.payrollOffCycleList }
                                            exportCSV={true}
                                            pageSize={150}
                                        /> */}
                                        <NoserDataGrid 
                                            data={this.state.payrollOffGRID}
                                            deductions={this.state.dedMain}
                                            inclusions={this.state.incMain}
                                            premiums={this.state.premMain}
                                            paycards={this.state.paycardTypes}
                                        />
                                        </Form.Group>
                                        {/* <div className="mt-5">
                                            <BootstrapTable
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                keyField = "id"
                                                data = { this.state.payrollOffSummary }
                                                columns = { colOffCycleSummary}
                                                cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                            />
                                        </div>  */}
                                    </Tab>
                                    <Tab eventKey="adjustment" title="PAYROLL SUMMARY (ADJUSTMENT)">
                                        <Form.Group controlId="formGridPassword">
                                        {/* <NoserGrid
                                            data = { this.state.payrollAdjustmentList }
                                            exportCSV={true}
                                            pageSize={150}
                                        /> */}
                                        <NoserDataGrid 
                                            data={this.state.payrollAdjGRID}
                                            deductions={this.state.dedMain}
                                            inclusions={this.state.incMain}
                                            premiums={this.state.premMain}
                                            paycards={this.state.paycardTypes}
                                        />
                                        </Form.Group>
                                        {/* <div className="mt-5">
                                            <BootstrapTable
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                keyField = "id"
                                                data = { this.state.payrollAdjSummary }
                                                columns = { colOffCycleSummary}
                                                cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                            />
                                        </div>  */}
                                    </Tab>
                                </Tabs>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div>
        )
    }


}

export default ReportPayrollSummary;