import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

class TalentPoolModal extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            disableBtn  :   true,

            title       :   "",
            talentdata  :   [],
            venueDDL    :   [
                {id:"0",name:"ONSITE"},
                {id:"1",name:"ONLINE"},
            ],
            poolDDL     :   [
                {id:"1",name:"APPLICATION POOL"},
                {id:"3",name:"CANDIDATE POOL"}
            ],
            poolstatusid:   "",
            isPooling   :   true,
            disableDDL  :   false,

            applicationId:  "",
            scheduleDate:   "",
            startTime    :   "",
            endTime      :   "",
            venue       :   "",
        }
    }
    
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            
            title       :   e.title,
            talentdata  :   e.data,
            venueDDL    :   [
                {id:"0",name:"ONSITE"},
                {id:"1",name:"ONLINE"},
            ],
            poolDDL     :   [
                {id:"1",name:"APPLICATION POOL"},
                {id:"3",name:"CANDIDATE POOL"}
            ],
            poolstatusid:   "",
            isPooling   :   true,
            disableDDL  :   false,

            applicationId:  "",
            scheduleDate:   "",
            startTime    :   "",
            endTime      :   "",
            venue       :   "",
        })
        this.handleChangedEndTime=this.handleChangedEndTime.bind(this);
        this.handleChangedStartTime=this.handleChangedStartTime.bind(this);
    }
    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (('0' + (date.getMonth()+1)).slice(-2) + "/" + ('0' + (date.getDate())).slice(-2) + "/" + date.getFullYear()) : "")
    }
    handleChangedScheudleDate = date =>{
        this.setState({scheduleDate:date})
    }
    handleChangedStartTime(time) {
        console.log(time.format("HH:mm"))
        this.setState({startTime:time.format("HH:mm")})
    }

    handleChangedEndTime(time) {
        this.setState({endTime:time.format("HH:mm")})
    } 
    handleChangedVenueDDL = (e) =>{
        if(e.length == 0) {
            this.setState({isshow:false,venue:"",disableBtn:true})
            return
        }
        this.setState({isshow:false,venue:e[0].name,disableBtn:false})
    }
    handleChangedDDL = (e) =>{
        if(e.length == 0) {
            this.setState({isshow:false,poolstatusid:"",disableBtn:true})
            return
        }
        this.setState({isshow:false,poolstatusid:e[0].id,disableBtn:false})
    }
    handleChangeOption = (e) =>{
        this.setState({isPooling:e.target.checked})
        if(e.target.name==="pooling"){
            this.setState({isPooling:true,disableDDL:false,statusid:"",disableBtn:true})
            this.refs.venueRef.clear()
        }
        else{
            this.setState({isPooling:false,disableDDL:true,poolstatusid:"",disableBtn:true})
            this.refs.poolRef.clear()
        }
        
        
    }
    handleSubmitPreInterview = async()=>{
        this.setState({isloading:true})

        if(this.state.scheduleDate===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Warning!",
                isshow      :   true,
                color       :   "warning",
                message     :   "Please select schedule date",
                fade        :   true
            });
            return
        }
        if(this.state.startTime===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Warning!",
                isshow      :   true,
                color       :   "warning",
                message     :   "Please select schedule start time",
                fade        :   true
            });
            return
        }
        if(this.state.endTime===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Warning!",
                isshow      :   true,
                color       :   "warning",
                message     :   "Please select schedule end time",
                fade        :   true
            });
            return
        }
        if(this.state.venue===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Warning!",
                isshow      :   true,
                color       :   "warning",
                message     :   "Please select venue",
                fade        :   true
            });
            return
        }

        let paramdata = []
        this.state.talentdata.forEach(itm => {
            paramdata.push({
                "IpAddress":"0.0.0.0",
                "CompanyId":this.state.userinfo.companyId,
                "UserId":this.state.userinfo.userId,
                "TypeId":"1",
                "ApplicationFormId":itm.id,
                "ScheduleDate":this.FormatDate(this.state.scheduleDate),
                "StartTime":this.state.startTime,
                "EndTime":this.state.endTime,
                "Venue":this.state.venue,
            })
        });
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "poolingSchedules":paramdata
        };

        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Pooling/AddPoolingSchedule",  params)
            .then(res => {
            const data = res.data;
            
            this.setState({
                isloading:false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                disableBtn  :   res.data.status=="1" ? true : false,
            });
            
            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

        this.setState({
            isloading:false,
            alerttype   :   "Success!",
            isshow      :   true,
            color       :   "success",
            message     :   "Preliminary Interview scheduled successfully.",
            fade        :   true,
            disableBtn  :   "1"=="1" ? true : false,
        });
    }
    handleSubmitPool = async()=>{
        this.setState({isloading:true})

        let paramdata = []
        this.state.talentdata.forEach(itm => {
            paramdata.push({
                "TypeId":"0",
                "PoolingId":itm.poolingId,
                "StatusId":this.state.poolstatusid
            })
        });
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "updatePoolings":paramdata
        };
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Pooling/SubmitPooling",  params)
            .then(res => {
            const data = res.data;
            
            this.setState({
                isloading:false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                disableBtn  :   res.data.status=="1" ? true : false,
            });
            
            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton className="card-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.state.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <hr />
                        <Form.Group as={Row}>
                            <Col sm="12">
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="Preliminary Interview"
                                    name="pretest" 
                                    value={!this.state.isPooling}
                                    checked={!this.state.isPooling}
                                    onChange={this.handleChangeOption}
                                    style={{fontWeight : "bold"}}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm="1"></Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                DATE
                            </Form.Label>
                            <Col sm={8}>
                                <DatePicker
                                    id="scheduleDate"
                                    name="scheduleDate"
                                    onChange={this.handleChangedScheudleDate}
                                    dateFormat={"MM/dd/yyyy"}
                                    className="form-control"
                                    autoComplete="off"
                                    style={{width:"120px"}}
                                    disabled={!this.state.disableDDL}
                                    selected={this.state.scheduleDate}
                                    value={this.props.scheduleDate}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm="1"></Col>
                            <Form.Label column sm="2" style={{marginTop:"-7px",fontWeight : "bold"}}>
                                FROM TIME
                            </Form.Label>
                            <Col sm={2}>
                                <TimePicker 
                                showSecond={false}
                                //defaultValue={moment()}
                                className="xxx"
                                onChange={this.handleChangedStartTime}
                                disabled={!this.state.disableDDL}
                                />
                            </Col>
                            <Form.Label column sm="2" style={{marginTop:"-7px",fontWeight : "bold"}}>
                                TO TIME
                            </Form.Label>
                            <Col sm={2}>
                                <TimePicker 
                                showSecond={false}
                                //defaultValue={moment()}
                                className="xxx"
                                onChange={this.handleChangedEndTime}
                                disabled={!this.state.disableDDL}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm="1"></Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                VENUE
                            </Form.Label>
                            <Col sm={8}>
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangedVenueDDL}
                                    options={this.state.venueDDL}
                                    placeholder="Select Venue"
                                    disabled={!this.state.disableDDL}
                                    ref="venueRef"
                                />
                            </Col>
                        </Form.Group>
                        <hr />
                        <Form.Group as={Row}>
                            <Col sm="12">
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="Application Pooling"
                                    name="pooling" 
                                    value={this.state.isPooling}
                                    checked={this.state.isPooling}
                                    onChange={this.handleChangeOption}
                                    style={{fontWeight : "bold"}}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm="1"></Col>
                            <Form.Label column sm="3" style={{marginTop:"-7px",fontWeight : "bold"}}>
                                SELECT NEXT POOL
                            </Form.Label>
                            <Col sm={6}>
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangedDDL}
                                    options={this.state.poolDDL}
                                    placeholder="Select Next Status"
                                    disabled={this.state.disableDDL}
                                    ref="poolRef"
                                />
                            </Col>
                        </Form.Group>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = {this.state.isPooling ? this.handleSubmitPool : this.handleSubmitPreInterview} disabled={this.state.disableBtn}>Submit</Button>
                        <Button variant="danger" className="noser-button-mr15" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default TalentPoolModal
