import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker, LeaveModal, Tabs, Tab,
    sizePerPageRenderer,Modal,
} 
from '../../noser-hris-component';

class BloodTypeCreate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo  :   [],
            isloading :   false,
            isshow    :   false,
            alerttype :   "",
            message   :   "",
            color     :   "",
            fade      :   true,

            bloodType:"",
        }
         
    }

    componentDidMount() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        sleep(1000).then(() => {
            this.setState({isloading:false})}) 
    }

    onChangeBloodType(e){
        console.log(e)
        this.setState({bloodType:e.target.value})
    }

    
    handleSaveClick = event => {
        const addParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "CompanyId":this.state.userinfo.companyId,
            "Name":this.state.name
        }
        console.log(addParams)
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/AddBloodType",  addParams
            )
            .then(res => {
                const data = res.data;
                console.log("Get data");
                console.log(data)
                if(data.status=="1")
                {
                    this.setState(
                        { 
                            isshow:true,
                            color:"success",
                            message:data.message ,
                            fade:true
                        });
                    
                    }
                else
                {
                    this.setState(
                        { 
                            isshow:true,
                            color:"danger",
                            message:data.message,
                            fade:true
                        });
                }
            })
        
    }

    render() {
        

        return(

            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className="modal-90w"
            >
                <Modal.Header closeButton className="card-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                    Create Blood Type
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form>
                            <Alert color="success" isOpen={this.state.isshow} color={this.state.Ccolor} fade={this.state.fade}>
                                {this.state.message}
                            </Alert>
                            <Form.Row>
                                <Col sm={6} className="mt-3">
                                    <Form.Control 
                                        type="text"
                                        name="name"
                                        value={this.state.bloodType}
                                        placeholder="Blood Type"
                                        onChange={this.onChangeBloodType.bind(this)} 
                                        autoComplete="off"
                                    />
                                </Col>
                            </Form.Row>
                            <ButtonToolbar sm={12} className="mt-3">
                                <Button variant="primary" className="ml-auto" onClick={this.handleSaveClick} style={{minWidth:'60px', marginRight:"5px"}}>
                                    Submit
                                </Button>
                                <Button variant="danger" href="/BloodType" style={{minWidth:'60px'}}>
                                    Back
                                </Button>
                            </ButtonToolbar>
                        </Form>
                    </Container>
                </Modal.Body>

                <NoserLoading show={this.state.isloading} />
            </Modal>
            
        )
    }
}

export default BloodTypeCreate;