import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker, LeaveModal, Tabs, Tab,
    sizePerPageRenderer,Modal,
} 
from '../../noser-hris-component';
import NumberFormat from 'react-number-format';

class PayrollperiodModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo  :   [],
            isloading :   false,
            isshow    :   false,
            alerttype :   "",
            message   :   "",
            color     :   "",
            fade      :   true,
            fullName    :"",
            IsActive    :"false",
            payrollMode : [
                {
                    "id":"3",
                    "name":"Monthly"

                },
                {
                    "id":"1",
                    "name":"Semi-Monthly"
                },
                {
                    "id":"2",
                    "name":"Weekly"
                },
                {
                    "id":"4",
                    "name":"Daily"
                },
            ],
            selectedMode:"",
            leadTime:"",
            periodStart:"",
            modeId:"",
            paymodeSetup:"",
            payoutWeekly:[
                {
                    "id":"1",
                    "name":"MONDAY"
                },
                {
                    "id":"2",
                    "name":"TUESDAY"
                },
                {
                    "id":"3",
                    "name":"WEDNESDAY"
                },
                {
                    "id":"4",
                    "name":"THURSDAY"
                },
                {
                    "id":"5",
                    "name":"FRIDAY"
                },
                {
                    "id":"6",
                    "name":"saturday"
                },
                {
                    "id":"7",
                    "name":"SUNDAY"
                },

            ]
        }
         
    }

    componentDidMount() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        sleep(1000).then(() => { this.setState({isloading:false}) }) 

        const date = new Date();
        const options = { weekday: 'long' };
        const currentDayOfWeek = date.toLocaleString('en-US', options);
        let check1 = moment(date).format("DDD") +1
       
        console.log("currentDayOfWeek");
        console.log("check1:"+check1);

        let startOfWeek = moment().startOf('week').toDate();
        console.log("startOfWeek");
        console.log("startOfWeek:"+startOfWeek);
       

    }

    onChangeFullName(e){
        this.setState({fullName:e.target.value})
    }

    onChangeleadTime(e){
        this.setState({leadTime:e.target.value})
    }

    onChangeperiodStart(e){
        this.setState({periodStart:e.target.value})
    }

    handleChangeIsActive = (e) =>{
        console.log(e)
        if(e.target.unchecked) 
            this.setState({IsActive: "false"})
        else
        {
            this.setState({IsActive: ""})
            
        }
    }

    handleSaveClick = (e) => {

        let setPayout =""

        if(this.state.paymodeSetup == "1"){


            let dateMonth = moment(new Date()).format("MM")
            let yearNew = moment(new Date()).format("YYYY")

            let splitPayout0 = this.state.fullName.split("/")[0]
            let splitPayout1 = this.state.fullName.split("/")[1]
            let splitindex0 = splitPayout0.split("-")[1]
            let splitindex1 = splitPayout1.split("-")[1]

            let addDates0 = dateMonth+"/"+splitindex0+"/"+yearNew
            let prescription = {
                prescriptionDate: new Date(addDates0),  // Today // date ng plusan ko
                prescriptionExpirationDate: parseFloat(this.state.leadTime)  // Days to add
            };
            
            let dateindex1 = new Date(new Date(prescription.prescriptionDate).setDate(prescription.prescriptionDate.getDate() + prescription.prescriptionExpirationDate)).toDateString();
            
            let addDates1 = dateMonth+"/"+splitindex1+"/"+yearNew
            let prescription1 = {
                prescriptionDate: new Date(addDates1),  // Today // date ng plusan ko
                prescriptionExpirationDate: parseFloat(this.state.leadTime)  // Days to add
            };
            
            let dateindex0 = new Date(new Date(prescription1.prescriptionDate).setDate(prescription1.prescriptionDate.getDate() + prescription1.prescriptionExpirationDate)).toDateString();
            
            console.log("setToDate1:"+dateindex1);
            console.log("setToDate0:"+dateindex0);

            let gtDay1 =    moment(dateindex1).format("DD")  
            let gtDay0 =    moment(dateindex0).format("DD")  

            console.log("gtDay1:"+gtDay1);
            console.log("gtDay0:"+gtDay0);

            setPayout = String(gtDay1)+"/"+String(gtDay0)

            console.log("setPayout:"+setPayout);

        }else{

            let currentDate = moment(new Date()).format("MM/DD/YYY")


            
            setPayout = this.state.fullName
        }
        
        
        
        
        /* else if(this.state.paymodeSetup == "2" ){
            let check1 =  this.state.fullName

            this.state.payoutWeekly.map(function(d){

            })

        } */



        const Params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "CompanyId":this.state.userinfo.companyId,
            "PayModeId":this.state.modeId,
            "Name" :  this.state.fullName +" Payout "+"("+setPayout+")" + " Lead Time "+"("+this.state.leadTime+")",
            "PayOut" : this.state.fullName,
            "LeadTime" : this.state.leadTime,
            "PeriodStart" : this.state.fullName,
            "CreatedBy" : this.state.userinfo.userId,
        }
        console.log("handleSaveClick AddPeriodType")
        console.log(Params)
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/AddPeriodType",  Params
            )
            .then(res => {
                const data = res.data;
                console.log("AddPayrollPeriod");
                console.log(data)
                if(data.status=="1"){
                    this.setState({
                            isloading   :   false,
                            alerttype   :   "Success!",
                            isshow      :   true,
                            color       :   "success",
                            message     :   data.message,
                            fade        :   true
                        });
                    
                    }
                else {
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
                
            })
        
    }

    onChangepayrollMode = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedMode     :   '',
            })
            return
        }
        this.state.selectedMode = e[0].name 
        this.state.modeId = e[0].id    
               
        this.setState({
            selectedMode   : e[0].name,
            isloading:true,

        })

        this.showPayoutBox()

       
       
    }

    showPayoutBox=()=>{
        
        if( this.state.modeId == "3"){
            this.state.paymodeSetup = "3"
            this.setState({ isloading:false, })
        }
        if( this.state.modeId == "1"){
            this.state.paymodeSetup = "1"
            this.setState({ isloading:false, })
        }
        if( this.state.modeId == "2"){
            this.state.paymodeSetup = "2"
            this.setState({ isloading:false, })
        }
        if( this.state.modeId == "4"){
            this.state.paymodeSetup = "4"
            this.setState({ isloading:false, })
        }
    }

    onChangePayoutWeek = (e) => {
        if(e.length==0)
        {
            this.setState({
                fullName     :   '',
            })
            return
        }
        this.state.fullName = e[0].name 
        //this.state.modeId = e[0].id    
               
        this.setState({
            fullName   : e[0].name,
           

        })
       
    }

    handleModalClose = () => {
        this.props.onHide();            
    }

    


    render() {
        

        return(
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className="modal-90w"
                >
                <Modal.Header closeButton className="card-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create Payroll Periods / Cutoff
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="3" style={{fontWeight : "bold"}}>
                                    payroll Mode
                                </Form.Label>
                                <Col sm={9}>
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.onChangepayrollMode}
                                        options={this.state.payrollMode}
                                        placeholder='select payroll mode'
                                    />
                                </Col>

                            </Form.Group>

                            
                            {/* <Form.Group  as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="3" style={{fontWeight : "bold"}}>
                                    Payout
                                </Form.Label>
                                <Col sm={9}>
                                    <Form.Control 
                                        type="text"
                                        name="fullName"
                                        value={this.state.command}
                                        placeholder="input Payout"
                                        onChange={this.onChangeFullName.bind(this)} 
                                        autoComplete="off"
                                    />
                                </Col>

                            </Form.Group> */}
                            {this.state.paymodeSetup == "" ?
                                <Form.Group  as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="3" style={{fontWeight : "bold"}}>
                                        period type
                                    </Form.Label>
                                   

                                </Form.Group>:null
                            }
                            {this.state.paymodeSetup == "1" ?
                                <Form.Group  as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="3" style={{fontWeight : "bold"}}>
                                        Payout
                                    </Form.Label>
                                    <Col sm={9}>
                                        <NumberFormat 
                                            type="text"
                                            name="fullName"
                                            value={this.state.command}
                                            placeholder="input Payout"
                                            onChange={this.onChangeFullName.bind(this)} 
                                            autoComplete="off"
                                            format="##-##/##-##"
                                        />
                                    </Col>

                                </Form.Group>:null
                            }
                            {this.state.paymodeSetup == "2" ?
                                <Form.Group  as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="3" style={{fontWeight : "bold"}}>
                                        Payout
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangePayoutWeek}
                                            options={this.state.payoutWeekly}
                                            placeholder='select Payout'
                                        />
                                    </Col>

                                </Form.Group>:null
                            }
                            {this.state.paymodeSetup == "3" ?
                                <Form.Group  as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="3" style={{fontWeight : "bold"}}>
                                        Payout3
                                    </Form.Label>
                                    <Col sm={9}>
                                        {/* <NumberFormat 
                                            type="text"
                                            name="fullName"
                                            value={this.state.command}
                                            placeholder="input Payout"
                                            onChange={this.onChangeFullName.bind(this)} 
                                            autoComplete="off"
                                            format="##/##-##/##"
                                        /> */}
                                        <Form.Control 
                                            type="text"
                                            name="fullName"
                                            value={this.state.command}
                                            placeholder="input Payout"
                                            onChange={this.onChangeFullName.bind(this)} 
                                            autoComplete="off"
                                        />
                                    </Col>

                                </Form.Group>:null
                            }
                            {this.state.paymodeSetup == "4" ?
                                <Form.Group  as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="3" style={{fontWeight : "bold"}}>
                                        Payout4
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control 
                                            type="text"
                                            name="fullName"
                                            value={this.state.command}
                                            placeholder="input Payout"
                                            onChange={this.onChangeFullName.bind(this)} 
                                            autoComplete="off"
                                        />
                                    </Col>

                                </Form.Group>:null
                            }
                            
                            
                            <Form.Group  as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="3" style={{fontWeight : "bold"}}>
                                Lead Time
                                </Form.Label>
                                <Col sm={9}>
                                    <Form.Control 
                                        type="text"
                                        name="leadTime"
                                        value={this.state.leadTime}
                                        placeholder="input Lead Time"
                                        onChange={this.onChangeleadTime.bind(this)} 
                                        autoComplete="off"
                                    />
                                </Col>

                            </Form.Group>
                           
                            <ButtonToolbar sm={12} className="mt-3">
                                <Button variant="success" className="ml-auto" onClick={this.handleSaveClick}  style={{minWidth:'60px', marginRight:"5px"}}>
                                    Save
                                </Button>&nbsp;&nbsp;
                                <Button variant="danger" onClick={this.handleModalClose} style={{minWidth:'60px'}}>
                                    Back
                                </Button>
                            </ButtonToolbar>
                        </Form>
                    </Container>
                </Modal.Body>

                <NoserLoading show={this.state.isloading} />
            </Modal>
           
            
        )
    }
}

export default PayrollperiodModal;