import {
    React,Component, Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Typeahead,
} 
from '../../noser-hris-component';
import { Input } from 'reactstrap';

class MonitoringStatusCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

           
            dateFrom                    :   "",
            dateTo                      :   "",
            modalTrainingRegisterShow   :   false,
            status                      :   "",
            particularList: [
                {
                    "id" : "For Resolution",
                    "name" : "For Resolution"
                },
                {
                    "id" : "Pending",
                    "name" : "Pending"
                },
                {
                    "id" : "For Appeal",
                    "name" : "For Appeal"
                },
                {
                    "id" : "Closed",
                    "name" : "Closed"
                }
                
               
            ],
 	        selectedparticular      :   '',
            selectedparticularId    :   '',

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        
    }
    

    onChangestatus=(e)=>{
        this.setState({
            status: e.target.value
      
        })
        //console.log(this.state.status)
    }

    handleSave=(e)=>{
        //console.log(this.state.status)
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');

        let data = {
            
          "status"          :   this.state.selectedparticular,
          "isDraft"         :   "0",
          "isDeleted"       :   "0",
          "createdby"       :   "user007",
          "createddate"     :   createddate,
          "modifiedby"      :   'user007',
          "modifieddate"    :   '0',
          "isModified"      :   '0',    
        }
        console.log(data)
        
       /*  const addParams = {
          "_collection" : "TestMonitoringStatus",
          "doc_data"    : data
        }
          
        axios
        .post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
          const data = res.data
          console.log("success")
          console.log(data)
          this.setState({status:""})
        })
        .catch(error =>{
          console.log("error: " + error)
        }) */
    }
    onChangeparticular = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedparticular     :   '',
                selectedparticularId   :   ''
            })
            return
        }
        this.state.selectedparticular = e[0].name
        this.state.selectedparticularId = e[0].id
       
        this.setState({
            isshow   :   false,
           selectedparticular : e[0].id

        })
    }
   
    render() {        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>Maintenance >> Monitoring Status >> Create</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Status
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='id'
                                                id="basic-example"
                                                onChange={this.onChangeparticular}
                                                options={this.state.particularList}
                                                //placeholder="select penalty type"
                                            />
                                        </Col>
                                    </Form.Group>
                                    
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                    
                                    </Form.Group>
                                   

                                    <ButtonToolbar className="mt-1">
                                        <Button variant="success" className="ml-auto" onClick={ this.handleSave } style={{minWidth:'60px'}}>
                                            Save
                                        </Button>&nbsp;&nbsp;
                                        <NavLink to="/home">
                                            <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                Close
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>

                                    
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                    {/* <TrainingRegisterModal 
                        show={this.state.modalTrainingRegisterShow}
                        onHide={this.handleModalClose}
                    /> */}
            </div> 
        )
    }

}

export  default MonitoringStatusCreate
