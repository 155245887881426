import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Tabs, Tab
} 
from '../../noser-hris-component';

class ManageDraftEmployeeApprovals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            disablebutton   :   true,
            
            clientId        :   "",
            clientName      :   "",
            locationId      :   "",
            employeeId      :   "",
            statusId        :   "2",
            clientDDL       :   [],
            locationDDL     :   [],
            employeeDDL     :   [],
            employeeList    :   [],
            statusDDL       :   [
                {"id":"","name":"ALL"},
                //{"id":"1","name":"PENDING FOR APPROVAL (AM)"},
                {"id":"2","name":"PENDING FOR APPROVAL (ADMIN)"},
                //{"id":"3","name":"REJECTED BY AM"},
                {"id":"4","name":"APPROVED"},
                {"id":"5","name":"REJECTED BY ADMIN"}
            ],
            employeeGRID    :   [],

            clientLst       :   [],
            branchLst       :   [],
            yesNoLst        :   [
                {"value":"0","label" : "NO"},
                {"value":"1","label" : "YES"}
            ],
            nationalityLst  :   [],
            religionLst     :   [],
            civilStatusLst  :   [
                {"label":"SINGLE","value":"1"},
                {"label":"MARRIED","value":"2"},
                {"label":"DIVORCED","value":"3"},
                {"label":"SEPERATED","value":"4"}

            ],
            arrangementLst  :   [],
            bloodTypeLst    :   [],
            positionLst     :   [],
            payrollTypeLst  :   [],
            payrollModeLst  :   [],
            employeeStatusLst:  [],
            paycardTypeLst  :   [],

            firstName:"",
            middleName:"",
            lastName:""
        }
    } 
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        //this.GetClients()
        // this.state.statusId = this.state.userinfo.roleId === "1" ||  
        //                         this.state.userinfo.roleId === "8" ? "4" : "2"
        //this.GetSubmittedEmployees()
        
        let approverAdmin = this.state.userinfo.roleId === "4" || 
                            this.state.userinfo.roleId === "5" ||
                            this.state.userinfo.roleId === "6" ? true : false

        this.state.disablebutton = approverAdmin ? false : true
        this.GetPositions()
    }
  
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        if(this.state.clientId!==""){
            //this.GetEmployees()
        }
        //this.handleSearch(e)
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL : data.clients ? data.clients : []})
            if(data.clients.length===0){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
            else{
                let clientLst = []
                data.clients.map(function(itm){
                    clientLst.push({
                        "value" : itm.id,
                        "label" : itm.name
                    })
                })
                this.setState({clientLst:clientLst})
            }
            this.GetLocationList()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetLocationList = async() =>{
        //this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.clientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", params)
        .then(res => {
            const data = res.data;
            let branchLst = []
            data.locations.map(function(itm){
                branchLst.push({
                    "value" : itm.id,
                    "label" : itm.name
                })
            })
            this.setState({branchLst:branchLst})
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
       this.GetPositions()
    }
    GetPositions = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"      :   this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  params)
        .then(res => {
            const data = res.data;
            let positionLst = []
                data.positions.map(function(itm){
                    positionLst.push({
                        "value" : itm.id,
                        "label" : itm.name
                    })
            })
            this.setState({positionLst:positionLst});
        })
        this.GetPayrollTypes()
    }
    GetPayrollTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"      :   this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollTypes",  params)
        .then(res => {
            const data = res.data;
            let payrollTypeLst = []
                data.payrollTypes.map(function(itm){
                    payrollTypeLst.push({
                        "value" : itm.id,
                        "label" : itm.name
                    })
            })
            this.setState({payrollTypeLst:payrollTypeLst});
        })
        this.GetPayrollModes()
    }
    GetPayrollModes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"      :   this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollModes",  params)
        .then(res => {
            const data = res.data;
            let payrollModeLst = []
                data.payrollModes.map(function(itm){
                    payrollModeLst.push({
                        "value" : itm.id,
                        "label" : itm.name
                    })
            })
            this.setState({payrollModeLst:payrollModeLst});
        })
        this.GetEmployeeStatusTypes()
    }
    GetEmployeeStatusTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"      :   this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetEmployeeStatusTypes",  params)
        .then(res => {
            const data = res.data;
            let employeeStatusLst = []
                data.statusTypes.map(function(itm){
                    employeeStatusLst.push({
                        "value" : itm.id,
                        "label" : itm.name
                    })
            })
            this.setState({employeeStatusLst:employeeStatusLst});
        })
        this.GetPayCardTypes()
    }
    GetPayCardTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"      :   this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes",  params)
        .then(res => {
            const data = res.data;
            console.log(data)
            let paycardTypeLst = []
                data.payCardTypes.map(function(itm){
                    paycardTypeLst.push({
                        "value" : itm.id,
                        "label" : itm.name
                    })
            })
            this.setState({paycardTypeLst:paycardTypeLst});
        })
        this.GetSubmittedEmployees()
    }
    GetSubmittedEmployees = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            //"SubmittedBy":this.state.userinfo.employeeId,
            "CompanyId":this.state.userinfo.companyId,
            "LocationId":this.state.locationId,
            "EmployeeId":this.state.employeeId,
            "StatusId":this.state.statusId,
            "FirstName":this.state.firstName,
            "MiddleName":this.state.middleName,
            "LastName":this.state.lastName
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/GetDraftEmployees", params)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({employeeGRID : data.draftEmployees ? data.draftEmployees : [], isloading:false})
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            } 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetEmployeeFilters()
    }
    GetEmployeeFilters = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "LocationId":this.state.locationId,
            "StatusId":this.state.statusId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/GetDraftEmployeeFilters", params)
        .then(res => {
            const data = res.data;
            //console.log(data)
            this.setState({employeeDDL : data.employeeFilters,employeeList:data.employeeFilters, isloading:false})
            //console.log(this.state.employeeDDL)
            if(data.status===0){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }  
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        
    }
    GetLocations = async() =>{
        this.setState({isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.clientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", params)
        .then(res => {
            const data = res.data;
            this.setState({locationDDL : data.locations ? data.locations : [], isloading:false})
            if(data.locations.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    // GetEmployees = async() =>{
    //     this.setState({isloading:true})
    //     const params = {
    //         "IpAddress":"0.0.0.0",
    //         "CompanyId":this.state.clientId,
    //         "UserId":this.state.userinfo.userId,
    //         "LocationId":this.state.locationId,
    //         "IsDeleted":this.state.statusId
    //     }
    //     await axios
    //     .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetConfigurationEmployees", params)
    //     .then(res => {
    //         const data = res.data;
    //         this.setState({employeeDDL : data.employees ? data.employees : [], isloading:false})
    //         if(data.employees.length===0){
    //             this.setState({
    //                 isloading   :   false,
    //                 alerttype   :   "Error!",
    //                 isshow      :   true,
    //                 color       :   "danger",
    //                 message     :   data.message,
    //                 fade        :   true
    //             });
    //         }  
    //     })
    //     .catch(error=>{
    //         this.setState({
    //             isloading   :   false,
    //             alerttype   :   "Error!",
    //             isshow      :   true,
    //             color       :   "danger",
    //             message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
    //             fade        :   true
    //         })
    //     })
        
    // }
    
    handleChangeClient = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.clientId= ''
            this.state.clientName=''
            return
        }
        this.state.clientId= e[0].id
        this.state.clientName=e[0].name
        this.GetLocations()
        this.GetEmployeeFilters()
        // this.state.employeeDDL = this.state.employeeList.filter(x=>x.clientId===e[0].id &&
        //                                 x.locationId===this.state.locationId &&
        //                                 x.statusId===this.state.statusId  
        //                             )
        
    }
    handleChangeLocation = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.locationId= ''
            return
        }
        this.state.locationId= e[0].id
        this.GetEmployeeFilters()
    }
    handleChangeStatus = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.statusId= ''
            return
        }
        this.state.statusId= e[0].id
        this.GetEmployeeFilters()
    }
    handleChangeEmployee = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.employeeId= ''
            this.state.firstName=''
            this.state.middleName=''
            this.state.lastName=''
            return
        }
        this.state.employeeId= e[0].id
        this.state.firstName=e[0].firstName
        this.state.middleName=e[0].middleName
        this.state.lastName=e[0].lastName
        //this.GetEmployees()
    }
    
    handleSubmitClick = async(statusId)=>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "CompanyId":this.state.userinfo.companyId,
            "RoleId":this.state.userinfo.roleId,
            "ApprovedBy":this.state.userinfo.employeeId,
            "StatusId":statusId,
            "draftEmployees": this.state.employeeGRID.filter(x=>x.isModified==="1")
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/SubmitDraftEmployeeApproval", params)
        .then(res => {
            const data = res.data;
            this.setState({isloading:false})
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            } 
            else{
                this.GetSubmittedEmployees()
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            } 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleSearchClick = () =>{
        this.GetSubmittedEmployees()
    }
    render(){
        let roleId = this.state.userinfo.roleId
        const employeeCol = [
            {
                dataField: 'status',
                text: 'STATUS',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'},
                hidden: roleId === "1" || roleId==="8" ? true : false
            },
            {
                dataField: 'submittedByName',
                text: 'SUBMITTED BY',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'19%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'19%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'submittedDate',
                text: 'DATE SUBMITTED',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%',whiteSpace:'nowrap'}},
                style:{textAlign:'center',width:'10%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'approvedByName',
                text: 'APPROVED / REJECTED BY',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'19%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'19%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'approvedDate',
                text: 'APPR / REJ DATE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',whiteSpace:'nowrap'}},
                style:{textAlign:'center',width:'9%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'clientId',
                text: 'CLIENT',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'},
                validator: (newValue, row, column) => {
                    if (!isNaN(newValue)) {
                        this.state.clientName = this.state.clientLst.find(x => x.value == newValue).label
                        this.GetLocationList()
                    }
                    return true;
                },
                formatter: (cell, row) => {
                    if(row.clientId!='' && row.clientId!=null){
                        if(this.state.clientLst.filter(x => x.value == cell).length>0)
                            return this.state.clientLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.clientLst
                }
            },
            {
                dataField: 'locationId',
                text: 'BRANCH',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'},
                formatter: (cell, row) => {
                    if(row.locationId!='' && row.locationId!=null){
                        if(this.state.branchLst.filter(x => x.value == cell).length>0)
                            return this.state.branchLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.branchLst
                }
            },
            {
                dataField: 'positionId',
                text: 'POSITION',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'},
                formatter: (cell, row) => {
                    if(row.positionId!='' && row.positionId!=null){
                        if(this.state.positionLst.filter(x => x.value == cell).length>0)
                            return this.state.positionLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.positionLst
                }
            },
            {
                dataField: 'firstName',
                text: 'FIRST NAME',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'middleName',
                text: 'MIDDLE NAME',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'lastName',
                text: 'LAST NAME',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'suffixName',
                text: 'SUFFIX',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'dateOfBirth',
                text: 'DATE OF BIRTH',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'},
                formatter: (cell) => {
                    if(cell!=""){
                        let dateObj = cell;
                        if (typeof cell !== 'object') {
                        dateObj = new Date(cell);
                        }
                        let m = moment(dateObj, 'MM/DD/YYYY');
                        return (m.isValid() ? (dateObj.getMonth()+1 + "/" + dateObj.getDate() + "/" + dateObj.getFullYear()) : "")
                        //return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${('0' + dateObj.getUTCDate()).slice(-2)}/${dateObj.getUTCFullYear()}`;
                    }
                },
                editor: {
                type: Type.DATE
                }
            },
            {
                dataField: 'dateHired',
                text: 'DATE HIRED',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'},
                formatter: (cell) => {
                    if(cell!=""){
                        let dateObj = cell;
                        if (typeof cell !== 'object') {
                        dateObj = new Date(cell);
                        }
                        let m = moment(dateObj, 'MM/DD/YYYY');
                        return (m.isValid() ? (dateObj.getMonth()+1 + "/" + dateObj.getDate() + "/" + dateObj.getFullYear()) : "")
                        //return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${('0' + dateObj.getUTCDate()).slice(-2)}/${dateObj.getUTCFullYear()}`;
                    }
                },
                editor: {
                type: Type.DATE
                }
            },
            {
                dataField: 'duration',
                text: 'DURATION',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'},
                formatter: (cell) => {
                    if(cell!=""){
                        let dateObj = cell;
                        if (typeof cell !== 'object') {
                        dateObj = new Date(cell);
                        }
                        let m = moment(dateObj, 'MM/DD/YYYY');
                        return (m.isValid() ? (dateObj.getMonth()+1 + "/" + dateObj.getDate() + "/" + dateObj.getFullYear()) : "")
                        //return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${('0' + dateObj.getUTCDate()).slice(-2)}/${dateObj.getUTCFullYear()}`;
                    }
                },
                editor: {
                type: Type.DATE
                }
            },
            {
                dataField: 'mobileNumber',
                text: 'CONTACT NO',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'}
            },
            {
                dataField: 'salaryOffered',
                text: 'SALARY RATE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'right',whiteSpace:'nowrap'}},
                style:{textAlign:'right',whiteSpace:'nowrap'}
            },
            {
                dataField: 'payTypeId',
                text: 'PAYROLL TYPE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'},
                formatter: (cell, row) => {
                    if(row.payTypeId!='' && row.payTypeId!=null){
                        if(this.state.payrollTypeLst.filter(x => x.value == cell).length>0)
                            return this.state.payrollTypeLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.payrollTypeLst
                }
            },
            {
                dataField: 'payModeId',
                text: 'PAYROLL MODE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'},
                formatter: (cell, row) => {
                    if(row.payModeId!='' && row.payModeId!=null){
                        if(this.state.payrollModeLst.filter(x => x.value == cell).length>0)
                            return this.state.payrollModeLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.payrollModeLst
                }
            },
            {
                dataField: 'employmentStatusId',
                text: 'EMPLOYMENT STATUS',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'},
                formatter: (cell, row) => {
                    if(row.employmentStatusId!='' && row.employmentStatusId!=null){
                        if(this.state.employeeStatusLst.filter(x => x.value == cell).length>0)
                            return this.state.employeeStatusLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.employeeStatusLst
                }
            },
            {
                dataField: 'payCardTypeId',
                text: 'PAY CARD TYPE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'},
                formatter: (cell, row) => {
                    if(row.payCardTypeId!=='' && row.payCardTypeId!==null){
                        if(this.state.paycardTypeLst.filter(x => x.value == cell).length>0)
                            return this.state.paycardTypeLst.find(x => x.value === cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.paycardTypeLst
                }
            },
            {
                dataField: 'payCardNumber',
                text: 'PAY CARD NUMBER',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'}
            },
            {
                dataField: 'tinNumber',
                text: 'TIN NUMBER',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'}
            },
            {
                dataField: 'sssNumber',
                text: 'SSS NUMBER',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'}
            },
            {
                dataField: 'phicNumber',
                text: 'PHIC NUMBER',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'}
            },
            {
                dataField: 'hdmfNumber',
                text: 'HDMF NUMBER',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'}
            },
            {
                dataField: 'eColaRate',
                text: 'ECOLA RATE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'}
            },
            {
                dataField: 'colaRate',
                text: 'COLA RATE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'}
            },
            {
                dataField: 'seaRate',
                text: 'SEA RATE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'}
            },
        ]
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable=true
                this.state.employeeGRID.map(function(item,i){
                    if(item.id===row.id)
                    {
                        if(row.statusId==="2"){
                            item.isModified = isSelect ? "1" : "0"
                        }
                    }
                    if (item.isModified=="1")
                    disable =  false
                })
            }
        };
        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>Manning >> Manage Draft Employees</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientDDL}
                                            placeholder="Select Client"

                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeLocation}
                                            options={this.state.locationDDL}
                                            placeholder="Select Location"
                                        />
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeStatus}
                                            options={this.state.statusDDL}
                                            placeholder="Select Status"

                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.handleChangeEmployee}
                                            options={this.state.employeeDDL}
                                            placeholder="Select Employee Name"
                                        />
                                    </Col>
                                </Form.Group>
                                {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.handleChangeEmployee}
                                            options={this.state.employeeDDL}
                                            placeholder="Select Employee"
                                        />
                                    </Col>
                                </Form.Group> */}
                                <ButtonToolbar className="mt-1">
                                    <Button variant="primary" className="ml-auto noser-button-mr1" onClick={ this.handleSearchClick }>Search</Button>
                                </ButtonToolbar>
                                <div className="mt-2">
                                <Card.Header>DRAFT EMPLOYEE LIST</Card.Header>
                                    <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.employeeGRID }
                                            columns = { employeeCol }
                                            selectRow = { selectRow }
                                            cellEdit = { cellEditFactory({ 
                                                    mode: 'dbclick', 
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => { 
                                                        //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            loading={true}
                                            wrapperClasses="table-responsive"
                                            pagination={ paginationFactory({sizePerPageRenderer})}
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        />
                                </div>
                                <ButtonToolbar sm={12} className="mt-3">
                                    <Button disabled={this.state.disablebutton} 
                                            className="ml-auto noser-button-mr1"
                                            //style={this.state.hidebtn ? {display:'none'} : {display:'block'}}
                                            variant="success" 
                                            onClick={() =>this.handleSubmitClick('4')}>
                                        APPROVE
                                    </Button>
                                    <Button className="noser-button"  variant="danger"
                                        disabled={this.state.disablebutton}
                                        onClick={() =>this.handleSubmitClick('5')}
                                    >
                                        REJECT
                                    </Button>
                                </ButtonToolbar>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div>
        )
    }
}

export default ManageDraftEmployeeApprovals;