import { Label } from 'reactstrap';
import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Redirect,
} 
from '../../noser-hris-component';
import './SignUpLogIns.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { FormControl } from 'react-bootstrap';
import { GetRequest, PostRequest } from '../../noser-dataaccess';
import SignUpLogInForgotPassword from './SignUpLogInForgotPassword'
import SignUpLogInResetPassword from './SignUpLogInResetPassword'
import {CipherString} from '../../noser-sec'
import ApplicationFormLogin from './ApplicationFormLogin';
import { useLocation } from "react-router-dom";



class SignUpLogIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            disablebtn      :   true,
            clientId        :   "",
            trainingId      :   "",
            statusId        :   "",

            SignUpData      : [],
            lastname        : "",
            firstname       : "",
            middlename      : "",
            suffix          : "",
            email           : "",
            mobileNo        : "",
            username        : "",
            password        : "",
            isCheck         : false,
            disableSaveBtn : true,

            LOGINusername   : "",
            LOGINpassword   : "",
            resdata    :   JSON.parse(sessionStorage.getItem("resdata")),
            userinfo :  JSON.parse(sessionStorage.getItem("userData")),
            URLcompanyId : "",
            confirmPassword : "",
        };
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.resdata = JSON.parse(sessionStorage.getItem("resdata"))
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('w')
        const cy = CipherString(token)
       
        console.log(query)
        console.log(token)//123
        console.log(cy)
        this.state.URLcompanyId = token

        
    };

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}    
        console.log("userinfo")  
        console.log(this.state.userinfo)    
        //this.handleGetSignUpAplpllicant2();
        //this.handleLogIn();
       
        //sessionStorage.clear("NOSERUSRlogInApplicant");
        sessionStorage.removeItem('NOSERUSRlogInApplicant');
        sessionStorage.removeItem('resdata');

        // const { location } = this.props;
        // const query = new URLSearchParams(location.search);
        
        // const email = query.get('email');
        // const token = query.get('token');
        // console.log("email")
        // console.log(email)
        // console.log("token")
        // console.log(token)
    };

    handleGetSignUpAplpllicant2 = async() =>{
        console.log("handleGetSignUpAplpllicant2")
        this.setState({isloading:true,isDisable:true,isshow:false})
        const params = {
            "CompanyId":"1",
            "UserName":"",
            "Password":"", //--> encrypted
        }
       // console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Registration/SignUp", params)
        .then(res => {
            const data = res.data
            console.log(data)
            this.setState({
                isloading   :   false,
                isshow      :   false,
                fade        :   true,
            })
            
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    };

    lipat = () => {
       //window.location = "http://localhost:8080/jobApplicationStatus"
        window.location = "http://139.59.242.88/jobApplicationStatus"
        
    }

    handleLogIn = async() =>{
        console.log("handleLogIn")
        this.setState({isloading:true,isDisable:true,isshow:false})

        const params = {
            "companyId":this.state.URLcompanyId,
            "userName":this.state.LOGINusername,
            //"Password": this.state.LOGINpassword,//CipherString(this.state.LOGINpassword),
            "password": CipherString(this.state.LOGINpassword),
        };
        console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Registration/SignIn", params)
        .then(res => {
            const data = res.data
            console.log("pasok")
            console.log(data)
            console.log(res)
            
            if(res.data.status=="1"){
                sessionStorage.setItem("NOSERUSRlogInApplicant", JSON.stringify(data))
                
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true,
                    //navigate:true,
                })
                    //this.lipat()
                if(data.applicationStatusId == "0"){
                window.location = "http://165.22.100.217/ApplicationFormCreate"
               // window.location = "http://localhost:8080/ApplicationFormCreate"
                //http://localhost:8080/
                }else{
                    window.location = "http://165.22.100.217/jobApplicationStatus"
                    //window.location = "http://localhost:8080/jobApplicationStatus"
                }
            }else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true,
                    navigate:false,
                })
            }
            
            
        })
        .catch(error=>{
            console.log("ndi pasok")
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    };

    handleChange = (e) =>{
        console.log(e.target.value)
        this.setState({[e.target.name]: e.target.value})
    };
    onchangeCheck = (e) =>{
        this.setState({ isCheck : e.target.checked,disableSaveBtn: false })
    };

    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    };
 
    handleModalClose = (e) =>{
        this.setState({openModal:false})  
       
    };
    
    GetShortName1=(mobileNo)=> {
        let _list = mobileNo.split(" ")
        // console.log("GetShortName")
        // console.log(_list)
        let sname = ""
        if (_list[0].length > 1) {
            sname = _list[0].substring(0, 1)
        } else {
            sname = _list[0]
        }
        return sname
    }

    handleSubmit = async(e) =>{
       //let a = this.state.userinfo
        console.log("handleSubmit")
       // console.log(a.companyId)
       let _sname = this.GetShortName1(this.state.mobileNo)
       console.log("_sname")
       console.log(_sname)
        
        if(this.state.lastname==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "please enter last name.",
                fade        :   true
            })
            return 
        };
        if(this.state.firstname==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "please enter firstname name.",
                fade        :   true
            })
            return 
        };
        if(this.state.middlename==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "please enter middlename .",
                fade        :   true
            })
            return 
        };

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!this.state.email) {
            //errors.email = 'This field is required';
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "please enter email address.",
                fade        :   true
            })
            return 
        } else if (!pattern.test(this.state.email)) {
            //errors.email = 'Email address is invalid';
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Email address is invalid.",
                fade        :   true
            })
            return 
        };

        if(this.state.mobileNo==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "please enter mobile number .",
                fade        :   true
            })
            return 
        }

        if(_sname !== "9" && _sname !== "0" && _sname !== "+"){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   'mobile number is incorect',
                fade        :   true
            })
            return
        };

        if(_sname == 9){
            if(this.state.mobileNo.length < 10){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   'mobile number needs to be 10 characters',
                    fade        :   true
                })
                return
            };
            if(this.state.mobileNo.length > 10){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   'mobile number needs to be 10 characters',
                    fade        :   true
                })
                return
            };
        }else if(_sname == 0){
            if(this.state.mobileNo.length < 11){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   'mobile number needs to be 11 characters',
                    fade        :   true
                })
                return
            };
            if(this.state.mobileNo.length > 11){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   'mobile number needs to be 11 characters',
                    fade        :   true
                })
                return
            };
        }else if(_sname == "+"){
            if(this.state.mobileNo.length < 13){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   'mobile number needs to be 13 characters',
                    fade        :   true
                })
                return
            };
            if(this.state.mobileNo.length > 13){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   'mobile number needs to be 13 characters',
                    fade        :   true
                })
                return
            };
        };

        if (!this.state.username.trim()) {
            //errors.username = 'Username required';
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "please enter username name.",
                fade        :   true
            })
            return 
        }else if (!/^[A-Za-z]+/.test(this.state.username.trim())) {
           // errors.username = 'Enter a valid username';
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Enter a valid username.",
                fade        :   true
            })
            return 
        };

        if (!this.state.password) {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "please enter password.",
                fade        :   true
            })
            return 
        } else if (this.state.password.length < 6) {
            //errors.password = 'Password needs to be 6 characters or more';
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   'Password needs to be 6 characters or more',
                fade        :   true
            })
            return 
        };

        if(this.state.password !== this.state.confirmPassword){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "password does not match.",
                fade        :   true
            })
            return 
        }


        let d = new Date();
        let createddate = moment(d).format('MM/DD/YYYY');
        let w = d.toLocaleTimeString()
        //User for Client ID
        let today = new Date()
        let clientId = moment(today).format('MMYYYYhhmmss');
        let fullname1 = this.state.lastname + " " + this.state.firstname + " " + this.state.middlename + "," + this.state.suffix;
        
        const param = {
            // "IpAddress":"0.0.0.0",
            // "ClientId":this.state.userinfo.clientId,
            // "UserId":this.state.userinfo.userId,
            // "Name":this.state.name,
            // "Description":this.state.description
            "companyId": this.state.URLcompanyId,
            "firstname":this.state.firstname,
            "middleName":this.state.middlename,
            "lastname":this.state.lastname,
            "suffixName":this.state.suffix,
            "emailAddress":this.state.email,
            "userName":this.state.username,
            "applicantName": fullname1,
            "password":CipherString(this.state.password), //--> encrypted
            "createddate"   : d,
            "modifieddate"  : createddate,
            "secNO"         : clientId,
            "docNo"         : clientId,
            "timestamp"     : w,
        }
        console.log("param")
        console.log(param)

        await axios
       
        .post(AppConfiguration.Setting().noserapiendpoint + "Registration/SignUp", param)
        .then(res => {
            const data = res.data;
            console.log("axios register")
            console.log(data)
            console.log(res)

            if(res.data.status=="1"){
                sessionStorage.setItem("NOSERUSRlogInApplicant", JSON.stringify(data))
                
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message /* + "" + "Please Login below" */,
                    fade        :   true,
                    //navigate:true,
                })
             //this.lipat()
            }else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true,
                    navigate:false,
                })
            }
           /*  sessionStorage.setItem("NOSERUSRlogInApplicant", JSON.stringify(data))
             sessionStorage.setItem("NOSERUSRlogInApplicantParams", JSON.stringify(param))
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                navigate: true
            }); */

            //this.handleGetSignUpAplpllicant2();
            //this.handleClear();
        })
       /*  .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        }) */
    };
   

    handleClear=()=>{
        this.setState({
            lastname        : "",
            firstname       : "",
            middlename      : "",
            suffix          : "",
            email           : "",
            mobileNo        : "",
            username        : "",
            password        : "",

            LOGINusername   : "",
            LOGINpassword   : "",
        })
    }

    render() {
        const { navigate } = this.state;
        if(navigate){
            return <Redirect to="/ApplicationFormLogin" push={true}/>
        
        }

        return(
            <div className='div-container'>
                <Container className="mt-5" fluid >
                    <div style={{width:"100%",height:"10%"}}>
                        <Card className='card-div' style={{borderBottomLeftRadius:0,borderBottomRightRadius:0,}}>
                            <Label className='mt-2 ml-2 headerNoser whiteTxt'  > Noser Technology PH - HRIS</Label>
                        </Card>
                    </div>
                    <div style={{width:"100%",height:"90%"}}>
                        <Card className='CardBig aa' style={{borderTopRightRadius:0,borderTopLeftRadius:0,}}>
                            <Alert style={{marginTop: "1%"}} isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div>
                                {this.state.message}
                            </Alert>
                                <Row className='aa mt-5 mb-5'>
                                    {/* <Col sm={5}>
                                        <div className='a'>
                                            <Card className='aCard' style={{width:"700px", marginLeft:"-30px",}}>
                                             <Card >
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                                <Row><Col><Label className='whiteTxt'></Label></Col></Row>
                                               
                                            
                                             </Card>
                                            </Card>
                                        </div>
                                    </Col> */}
                                   {/*  <Col sm={1}></Col> */}
                                    {/* <Col sm={5}> */}
                                        <div className='b'>
                                        {/* <Label className='mt-2 ml-2 b '>Company name </Label> */}
                                            <Card className='' style={{width:"900px"}}>
                                                <Form>
                                                    <Card>
                                                        <Label className='SignLabel mt-2 ml-3'>Sign Up</Label>
                                                        <p className='paragraph mt-2 ml-3'>Please fill your information below to create account</p>
                                                        <div style={{width:"100%"}}>
                                                            <Form.Group as={Row}>
                                                                <Label className='wid fultxt mt-2 no-text-transform textko'>FULL NAME </Label> 
                                                                <Form.Control
                                                                    className='wid1 marginR'
                                                                    placeholder='Last Name'
                                                                    name='lastname'
                                                                    type="text" 
                                                                    onChange={this.handleChange} 
                                                                    autoComplete="off" 
                                                                    value={this.state.lastname}
                                                                    
                                                                />
                                                                <Form.Control
                                                                    className='wid1 marginR'
                                                                    placeholder='First Name'
                                                                    name='firstname'
                                                                    type="text" 
                                                                    onChange={this.handleChange} 
                                                                    autoComplete="off" 
                                                                    value={this.state.firstname}
                                                                />
                                                                <Form.Control
                                                                    className='wid1 marginR'
                                                                    placeholder='Middle Name'
                                                                    name='middlename'
                                                                    type="text" 
                                                                    onChange={this.handleChange} 
                                                                    autoComplete="off" 
                                                                    value={this.state.middlename}
                                                                />
                                                                <Form.Control
                                                                    className='wid1'
                                                                    placeholder='Suffix'
                                                                    name='suffix'
                                                                    type="text" 
                                                                    onChange={this.handleChange} 
                                                                    autoComplete="off" 
                                                                    value={this.state.suffix}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{width:"100%"}} className='no-text-transform'>
                                                            <Form.Group as={Row}>
                                                            <Label className='wid fultxt mt-2 no-text-transform textko'>EMAIL</Label> 
                                                                <Form.Control
                                                                    className=' wid2 no-text-transform'
                                                                    placeholder='Email Address'
                                                                    name='email'
                                                                    type="text" 
                                                                    onChange={this.handleChange} 
                                                                    autoComplete="off" 
                                                                    value={this.state.email}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{width:"100%"}}>
                                                            <Form.Group as={Row}>
                                                            <Label className='wid fultxt mt-2 no-text-transform textko'>MOBILE NO.</Label> 
                                                                <Form.Control
                                                                    className=' wid2'
                                                                    placeholder='MOBILE NUMBER'
                                                                    name='mobileNo'
                                                                    type="text" 
                                                                    onChange={this.handleChange} 
                                                                    autoComplete="off" 
                                                                    value={this.state.mobileNo}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{width:"100%"}}>
                                                            <Form.Group as={Row}>
                                                            <Label className='wid fultxt mt-2 no-text-transform textko'>USERNAME</Label> 
                                                                <Form.Control
                                                                    className=' wid2 no-text-transform'
                                                                    placeholder='USERNAME'
                                                                    name='username'
                                                                    type="text" 
                                                                    onChange={this.handleChange} 
                                                                    autoComplete="off" 
                                                                    value={this.state.username}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{width:"100%"}} className='mb-3'>
                                                            <Form.Group as={Row}>
                                                            <Label className='wid fultxt mt-2 no-text-transform textko'>PASSWORD</Label> 
                                                                <Form.Control
                                                                    className=' wid2 no-text-transform'
                                                                    placeholder='PASSWORD'
                                                                    type='password'
                                                                    name='password'
                                                                    onChange={this.handleChange} 
                                                                    autoComplete="off" 
                                                                    value={this.state.password}
                                                                />
                                                            </Form.Group>
                                                        </div><div style={{width:"100%"}} className='mb-3'>
                                                            <Form.Group as={Row}>
                                                            <Label className='wid fultxt mt-2 no-text-transform textko'>CONFIRM PASSWORD</Label> 
                                                                <Form.Control
                                                                    className=' wid2 no-text-transform'
                                                                    placeholder=' CONFIRM PASSWORD'
                                                                    type='password'
                                                                    name='confirmPassword'
                                                                    onChange={this.handleChange} 
                                                                    autoComplete="off" 
                                                                    value={this.state.confirmPassword}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <Form.Group as={Row}>
                                                                <Form.Check
                                                                    inline
                                                                    className='no-text-transform  formche'                                                                 
                                                                    label="I accept terms and condition & privacy policy"
                                                                    style={{fontWeight : "bold"}}
                                                                    type='radio'
                                                                    id="isCheck"
                                                                    name='isCheck'
                                                                    onChange={ this.onchangeCheck }
                                                                    checked = { this.state.isCheck }
                                                                    value={ this.state.isCheck }
                                                                />
                                                                <Button
                                                                    variant="success" 
                                                                    className="ml-auto noser-button-mr1 noser-button mr-5" 
                                                                    onClick = { this.handleSubmit }
                                                                    disabled={this.state.disableSaveBtn}
                                                                >
                                                                    Sign Up
                                                                </Button>
                                                        </Form.Group>
                                                    </Card>
                                                </Form>
                                                <Form>
                                                    <Card>
                                                        <Label className=' login-label mt-2 ml-3'> Log In</Label>
                                                        <p className='no-text-transform paragraph mt-1 ml-3'> Already have an account? Please log in to fill up Application Form or check the Status Update</p>
                                                        <Form.Group as={Row} className='mt-5'>
                                                        {/* <Button variant="outline-primary"className=" buttn ml-auto noser-button-mr1 noser-button mr-5" >
                                                            User Name
                                                        </Button>
                                                        <Button variant="outline-primary" className="buttn ml-auto noser-button-mr1 noser-button mr-5" >
                                                            Password
                                                        </Button> */}
                                                        <Form.Control
                                                                className=' ml-5 buttn no-text-transform text'
                                                                placeholder='USERNAME'
                                                                name='LOGINusername'
                                                                type="text" 
                                                                onChange={this.handleChange} 
                                                                autoComplete="off" 
                                                                value={this.state.LOGINusername}
                                                            />
                                                            <Form.Control
                                                                className=' ml-5 buttn no-text-transform text'
                                                                placeholder='PASSWORD'
                                                                name='LOGINpassword'
                                                                type="password" 
                                                                onChange={this.handleChange} 
                                                                autoComplete="off" 
                                                                value={this.state.LOGINpassword}
                                                            />
                                                        <Button variant="primary" className="ml-auto noser-button-mr1 noser-button mr-5" onClick={this.handleLogIn}>
                                                            Log In
                                                        </Button>
                                                        </Form.Group>
                                                        <div className='FORGETPASSWORD'>
                                                            <Button variant="outline-primary" 
                                                                style={{
                                                                    borderTopColor : "white",
                                                                    borderRightColor : "white",
                                                                    borderLeftColor : "white",
                                                                    width : "100%", 
                                                                    
                                                                
                                                                }}
                                                                className="no-text-transform marLLL mt-3 mb-5 ml-2 noser-button" 
                                                                onClick={this.handleModalShow}
                                                            >
                                                            Forgot Password?
                                                            </Button>
                                                        </div>
                                                    </Card>
                                                </Form>
                                            </Card>
                                        </div>
                                  {/*   </Col> */}
                                </Row>
                          
                            <Label className=' no-text-transform ml-auto' style={{fontWeight:"bold"}}> Noser HRIS &copy; 2019-2020. All Rights Reserved.</Label>
                        </Card>
                    </div>
                    
                </Container>
                <NoserLoading show={this.state.isloading} />
                <SignUpLogInForgotPassword 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
                <SignUpLogInResetPassword
                    show={this.state.openModal2}
                    onHide={this.handleModalClose2}
                    onRefModal={ref => (this.child = ref)}
                />
            </div>
        )

    };

}
export  default SignUpLogIn ;