import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Redirect
} 
from '../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

class PaySlip extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,   
            
            clientDDL   : [],
            clientId    : "",
            client      : "",
            employeeDDL : [],
            employeeId  :   "",
            employeeName:   "",
            profileId   :   "",
            employeeNo  :   "",
            position    :   "",
            location    :   "",
            payperiodDDL : [],
            periodId : "",
            payrollPeriodsData : [],
            dataGrid : [],

            modalShow : false,
        }
       

    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.clientId = this.state.userinfo.companyId
        this.GetClient(); 
        this.GetEmployees();
    }

    GetClient = async() => {
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                         this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        console.log("params")
        console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        //.post("http://209.97.173.181:8081/Client/GetClientList",  params)
        .then(res => {
            const data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    console.log("access")
                    console.log(access)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                console.log("arr")
                console.log(arr)
                this.setState({clientDDL : arr,isloading:false})
            }
            else{
                this.setState({clientDDL : data.clients,isloading:false})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
   }

   handleChangeClient = (e) => {
       if(e.length == 0) {
           this.state.clientId  =   ""
           this.state.client    =   ""
           return
       }
       this.state.clientId  =   e[0].id
       this.state.client    =   e[0].name
       this.GetEmployees();
       this.setState({
           isshow:false,
       })
   }


   GetEmployees = () => {
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetConfigurationEmployees",  params)
        //.post("http://209.97.173.181:8081/Employee/GetConfigurationEmployees",  params)
        .then(res => {
            const data = res.data.employees
            /* END LOOP FOR EMPLOYEE */
            this.setState({
                employeeDDL :   data,
                isloading   :   false,
            }) 
            
        })
    }

    handleChangeEmployee = (e) => {
        console.log("e")
        console.log(e)
        if(e.length == 0) {
            this.state.employeeId   =   ""
            this.state.employeeName =   ""
            this.state.profileId    =   ""
            this.state.employeeNo   =   ""
            this.state.position     =   ""
            this.state.location     =   ""
            return
        }
        this.state.employeeId   =   e[0].id
        this.state.employeeName =   e[0].employeeName
        this.state.profileId    =   e[0].employeeId
        this.state.employeeNo   =   e[0].employeeNo
        this.state.position     =   e[0].position
        this.state.location     =   e[0].locationName
       // this.GetPayrollPeriods();
        this.GetPayrollLedgers();
        this.setState({
            isshow:false,
        })
    }

    GetPayrollPeriods = () => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId"        :this.state.userinfo.userId,
            "EmployeeId"    :this.state.employeeId,
            "IsProcessed"   :"1"
        };
        console.log("params")
        console.log(params)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods",  params)
        //.post("http://209.97.173.181:8081/Timekeeping/GetPayrollPeriods",  params)
        .then(res => {
            const data = res.data.payrollPeriods;
            console.log("GetPayrollPeriods")
            console.log(data)
            this.setState({
                payperiodDDL : data,
                isloading   :  false,
            })
        })
    }  

    handleChangePayPeriod = (e) => {
        
        if(e.length == 0) {
            this.state.periodId   =   ""
            return
        }
        this.state.periodId   =   e[0].periodId
        //this.GetPayrolls();
        //this.GetPayrollLedgers();
        this.setState({
            isshow:false,
        })
    }

    handleSearchClick = () => {
        this.setState({isloading:true})
        if(!this.state.clientId){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client!",
                fade        :   true
            });
            return
        }
        if(this.state.employeeName === ""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select employee!",
                fade        :   true
            });
            return
        }
        const params = {
            "IpAddress"     :"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId"        :this.state.userinfo.userId,
            "EmployeeId"    :this.state.employeeId,
            "IsProcessed"   :"1"
        };

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods",  params)
        //.post("http://209.97.173.181:8081/Timekeeping/GetPayrollPeriods",  params)
        .then(res => {
            const data = res.data.payrollPeriods;
            const detArr = []
            const dataArrs = []
            console.log("GetPayrollPeriods")
            console.log(data)
            for(let x = 0; x < data.length; x++){
                const detObj = {
                    "client" : this.state.client,
                    "clientId"      :this.state.clientId,
                    "employee"      : this.state.employeeName,
                    "employeeId"    :this.state.employeeId,
                    "action"    :   "VIEW DETIALS",
                    "endDate"    :   data[x]["endDate"],
                    "isProcessed"    :   data[x]["isProcessed"],
                    "payPeriod"    :   data[x]["payPeriod"],
                    "periodId"    :   data[x]["periodId"],
                    "startDate"    :   data[x]["startDate"],
                }
                detArr.push(detObj)
            }
            const obj = {
                "client"        : this.state.client,
                "employee"      : this.state.employeeName,
                "payrollDetails": detArr
            }
            dataArrs.push(obj)
            this.setState({
                dataGrid : dataArrs,
                isloading   :   false,
            })
        })
    }  

    GetPayrollLedgers = () => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId"        :this.state.userinfo.userId,
            "EmployeeId"   :this.state.employeeId,
            "LedgerTypeId"   :"2",
            "ReferenceId"   :"3"
        };
        console.log("GetPayrollLedgers params")
        console.log(params)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetPayrolls",  params)
        //.post("http://209.97.173.181:8081/Payroll/GetPayrollLedgers",  params)
        .then(res => {
            const data = res.data;
            console.log("GetPayrollLedgers")
            console.log(data)
            this.setState({
                //payperiodDDL : data,
                isloading   :  false,
            })
        })
    }  

    handleModalShow = (row) => {
        console.log("row")
        console.log(row)
        sessionStorage.setItem("payslipdetails_", JSON.stringify(row))
        this.setState({navigate:true})
    }

    // handleModalClose = (e) =>{
    //     this.setState({modalShow:false})
    // }

    render() {
        const {navigate} = this.state;
        if (navigate) {
            return <Redirect to="/downloadpayslip" push={true} />;
        } 
      
        const column = [
           
            {
                dataField: 'employee',
                text: 'EMPLOYEE NAME',
                editable: false,
                headerStyle: () => {
                    return {width:'100%'}
                },
            }
        ] 
    
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.dataGrid.map(function(item,i){
                   
                    if(item.id===row.id)
                        {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })

            }
        };
            const rowEvents = {
            onClick: (e, row, rowIndex) => {

            }
        };

        const expandRow = {
            renderer: row => (
                <BootstrapTable
                    keyField = "periodId"
                    data = { row.payrollDetails }
                    columns = { payrollColumn }
                    pagination={ paginationFactory({sizePerPageRenderer}) }
                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                    rowClasses="noser-table-row-class no-checkbox"
                    striped
                    hover
                    condensed
                    wrapperClasses="table-responsive"
                    //selectRow = { selectRows }
                    //rowEvents={ requestMonitoringRowEvents }

                />
            ),
            showExpandColumn: true
        };
        const payrollColumn = [
            {
                dataField: 'payPeriod',
                text: 'PAY PERIOD',
                editable:false,
                headerStyle: () => {
                    return { width: "30%" };
                },
                
                
            },
            {
                dataField: 'startDate',
                text: 'START DATE',
                editable:false,
                headerStyle: () => {
                    return { width: "30%" };
                },
               
                
            },
            {
                dataField: 'endDate',
                text: 'END DATE',
                editable:false,
                headerStyle: () => {
                    return { width: "30%" };
                },
               
                
            },
            {
                dataField: 'action',
                text: 'ACTION',
                editable:false,
                headerStyle: () => {
                    return { width: "30%" };
                },
                
                formatter   :   (cell, row, isSelect) => {
                    return (
                        
                        <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link" onClick={e => this.handleModalShow(row)}
                        >{cell}</Button>
                        
                        );
                },
                
            }
        ] 
        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>PAYROLL >> PaySlip</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Row>
                                       
                                        <Form.Group as={Col} sm="6" controlId="formGridAddress1">
                                            <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                            <Typeahead 
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleChangeEmployee}
                                                options={this.state.employeeDDL}
                                                placeholder="Select Employee" 
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} sm="6" controlId="formGridAddress1">
                                            <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE NO.</Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                name="employeeNo"
                                                value={this.state.employeeNo}
                                                readOnly
                                            />
                                        </Form.Group>
                                     
                                        <Form.Group as={Col} sm="6" controlId="formGridAddress1">
                                            <Form.Label style={{fontWeight : "bold"}}>Position</Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                name="position"
                                                value={this.state.position}
                                                readOnly
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <ButtonToolbar className="mt-1">
                                        <Button variant="info" className="ml-auto" onClick={ this.handleSearchClick }>
                                            Search
                                        </Button>
                                    </ButtonToolbar>
                                    <div className="mt-5">  
                                        <div>
                                            <Card.Header>EMPLOYEE PAYSLIP</Card.Header>
                                            <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.dataGrid }
                                                columns = { column }
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                rowClasses="noser-table-row-class no-checkbox"
                                                striped
                                                hover
                                                condensed
                                                cellEdit = { cellEditFactory({
                                                    mode: 'dbclick',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                        //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                wrapperClasses="table-responsive"
                                                expandRow={ expandRow }
                                                //selectRow = { requestMonitoringSelectRow }
                                                //rowEvents={ requestMonitoringRowEvents }
                        
                                            />
                                        </div>
                                    </div>
                                    
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />
                    {/* <PayslipModal 
                        show={this.state.modalShow}
                        onHide={this.handleModalClose}
                        onRefModal={ref => (this.child = ref)}
                    /> */}
                  
            </div> 
        )
    }

}
export default PaySlip;