import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker,  Tabs, Tab
} 
from '../../noser-hris-component';
import NoserDataGrid from './PayrollGrid';
class GeneratePayroll extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            periodDDL       :   [],
            payrollGrID     :   [],
            premMain        :   [],
            dedMain         :   [],
            incMain         :   [],
            paycardTypes    :   [],
            
            inclusions      :   [],
            deductions      :   [],
            premiums        :   []   
        }
        this.dataGridRef = React.createRef();
    }

    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetPayrollPeriods()
        
    }
    GetPayrollPeriods = async() => {
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId": "",
            "IsProcessed":"0"
         };
         
        await axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods",  params
             )
             .then(res => {
                const data = res.data;
                this.setState({periodDDL: data.payrollPeriods,isloading:false })
                console.log(res.data)

                this.GetDeductions()
             })
    }
    GetDeductions = async() => {
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId
         };
         
        await axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDeductions",  params
             )
             .then(res => {
                const data = res.data;
                data.deductions.push(
                    { "id":"-1","name":"SSS"},
                    { "id":"-2","name":"PHIC"},
                    { "id":"-3","name":"HDMF"}
                )
                console.log(data.deductions)
                this.setState({dedMain: data.deductions,isloading:false })
                this.GetInclusions()
             })
    }
    GetInclusions = async() => {
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId
         };
         
        await axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetInclusions",  params
             )
             .then(res => {
                const data = res.data;
                data.inclusions.push({
                    "id":"-1",
                    "name":"No of Days"
                })
                
                this.setState({incMain: data.inclusions,isloading:false })
                this.GetRateElements()
             })
    }
    GetRateElements = async() => {
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId
         };
         
        await axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRateElements",  params
             )
             .then(res => {
                const data = res.data;
                data.elements.push({
                    "id":"-1",
                    "code":"No of Hrs",
                    "isStandard":"1"
                })
                console.log("data.elements")
                console.log(data.elements)
                this.setState({premMain: data.elements.filter(x=>x.isStandard==="1"),isloading:false })
                this.GetPayCardTypes()
             })
    }
    GetPayCardTypes = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":"0",
            "UserId":this.state.userinfo.userId
         };
         
        await axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes",  params
             )
             .then(res => {
                const data = res.data;
                this.setState({paycardTypes: data.payCardTypes,isloading:false })
             })
    }
    handleChangedPeriod = (e) => {
        this.setState({alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.periodId=""
            this.state.periodName=""
            return
        } 
        this.state.periodId = e[0].periodId
        this.state.periodName = e[0].payPeriod
        this.state.payrollDate = e[0].endDate
    }
    handleGenerateClick = async (e) => {
        
        this.setState({isloading:true,
                        alerttype:'',
                        isshow:false,
                        color:'',
                        message:'',
                        fade:true})
            const generateParams = {
                "IpAddress":"0.0.0.0",
                "CompanyId":this.state.userinfo.companyId,
                "UserId":this.state.userinfo.userId,
                "PayPeriodId": this.state.periodId,
                "LocationId": ""
            };
    
           await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/ProcessPayroll",  generateParams)
            .then(res => {
                //console.log(res.data)
                const data = res.data;
                    //console.log(data)
                    if(data.status=="1")
                    {
                        this.SearchGeneratePayroll();
                    }
                    else
                    {
                    //var alerttype = (data.status=="1") ? "success" : "danger"
                    this.setState(
                    {
                        isloading:false,
                        alerttype:"Error! ",
                        isshow:true,
                        color:"danger",
                        message:data.message ,
                        fade:true
                    });
                    }
                })
                .catch(error=>{
                this.setState(
                    {
                    isloading:false,
                    alerttype:"Error! ",
                    isshow:true,
                    color:"danger",
                    message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade:true
                })
            })
    
        }
    
    handleFinalizedClick = async (e) => {
	    if (!window.confirm('Do you want to finalize the selected payroll period?'))
            return
        if (!window.confirm('Are you really sure?'))
            return

        this.setState({isloading:true,})
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "PayPeriodId": this.state.periodId,
            "LocationId": ""
        };

       await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/FinalizePayroll",  params)
        .then(res => {
            const data = res.data;
            this.setState({
                isloading:false,
                alerttype:data.status=="1" ? "Success! " : "Error! ",
                isshow:true,
                color:data.status=="1" ? "success" : "danger",
                message:data.message ,
                fade:true
            });
        })
        .catch(error=>{
            this.setState({
                isloading:false,
                alerttype:"Error! ",
                isshow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })
        })
    }  
    SearchGeneratePayroll = async () => {
        this.setState({isloading:true,alerttype:'',isshow:false,color:'',message:'',fade:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "PayPeriodId": this.state.periodId,
            "IsProcessed":"0"
        }
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetPayrolls", params)
        .then(res => {
            const data = res.data;
            
            console.log("payroll")
            console.log(data)
            let tmppayroll = []
            let idxprem = 0;
            data.payrolls.forEach((itm,idx) => {
                let payroll = {
                    hc:idx+1,
                    id:itm.id,
                    employeeName:itm.memberName,
                    employeeNo:itm.memberNo,
                    position:itm.position,
                    payCardNumber:itm.payCardNumber,
                    salaryOffered: parseFloat(itm.salaryOffered).toFixed(2),
                    colaRate:parseFloat(itm.colaRate).toFixed(2),
                    seaRate:parseFloat(itm.seaRate).toFixed(2),
                    eColaRate:parseFloat(itm.eColaRate).toFixed(2),
                    basicPayDays:parseFloat(itm.basicPayDays).toFixed(2),
                    basicPayHrs:parseFloat(itm.basicPayHrs).toFixed(2),
                    basicPayAmount:parseFloat(itm.basicPayAmount).toFixed(2),
                    colaDays:parseFloat(itm.colaDays).toFixed(2),
                    colaAmount:parseFloat(itm.colaAmount).toFixed(2),
                    eColaDays:parseFloat(itm.eColaDays).toFixed(2),
                    eColaAmount:parseFloat(itm.eColaAmount).toFixed(2),
                    seaDays:parseFloat(itm.seaDays).toFixed(2),
                    seaAmount:parseFloat(itm.seaAmount).toFixed(2),
                    lateMins:parseFloat(itm.lateMins).toFixed(2),
                    lateAmount:parseFloat(itm.lateAmount).toFixed(2),
                    undertimeMins:parseFloat(itm.undertimeMins).toFixed(2),
                    undertimeAmount:parseFloat(itm.undertimeAmount).toFixed(2),
                    absentDays:parseFloat(itm.absentDays).toFixed(2),
                    absentAmount:parseFloat(itm.absentAmount).toFixed(2),
                    netRegularPay:parseFloat(itm.netRegularPay).toFixed(2),
                    grossPay:parseFloat(itm.grossPay).toFixed(2),
                    totalPremiums:parseFloat(itm.totalPremiums).toFixed(2),
                    totalInclusions:parseFloat(itm.totalInclusions).toFixed(2),
                    totalGrossPay:parseFloat(itm.totalGrossPay).toFixed(2),
                    totalDeductions:parseFloat(itm.totalDeductions).toFixed(2),
                    netPay:parseFloat(itm.netPay).toFixed(2)
                }

                itm.premiums.forEach((prem,idx)=>{
                    payroll["No of Hrs"] = parseFloat(prem.nofHrs).toFixed(2)
                    payroll[prem.rateCode] = parseFloat(prem.amount).toFixed(2)
                })
                
                itm.inclusions.forEach(inc=>{
                    if(parseFloat(inc.noOfDays)>0){
                        payroll["No of Days"] = parseFloat(inc.noOfDays).toFixed(2)
                    }
                    payroll[inc.inclusion] = parseFloat(inc.amount).toFixed(2)
                    
                })
                
                itm.deductions.forEach(ded=>{
                    payroll[ded.deductionName] = parseFloat(ded.amount).toFixed(2)
                })
                
                this.state.paycardTypes.forEach(pc=>{
                    if(pc.id===itm.payCardTypeId){
                        payroll[pc.name] = parseFloat(itm.netPay).toFixed(2)
                    }
                })

                tmppayroll.push(payroll)
            });
            

            const res1 = tmppayroll.reduce((result, item) => {
            const keys = Object.keys(item);
            keys.forEach(key => {
                
                if (this.state.dedMain.filter(x=>x.name===key).length>0){
                    //result[key] = result[key] === undefined ? result[key] : result[key].replace(".00","")
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
                if (this.state.incMain.filter(x=>x.name===key).length>0){
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
                if (this.state.premMain.filter(x=>x.code===key).length>0){
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
                if (this.state.paycardTypes.filter(x=>x.name===key).length>0){
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
                if(key==="salaryOffered" || key==="colaRate" || key==="seaRate" || key==="eColaRate" || key==="basicPayDays" ||
                key==="basicPayHrs" || key==="basicPayAmount" || key==="colaDays" || key==="colaAmount" || key==="eColaDays" ||
                key==="eColaAmount" || key==="seaDays" || key==="seaAmount" || key==="lateMins" || key==="lateAmount" ||
                key==="undertimeMins" || key==="undertimeAmount" || key==="absentDays" || key==="absentAmount" || key==="netRegularPay" ||
                key==="grossPay" || key==="totalPremiums" || key==="totalInclusions" || key==="totalGrossPay" || key==="totalDeductions" || key==="netPay"){
                    
                    result[key] = result[key] ? (parseFloat(result[key].toString().replace(".00","")) + parseFloat(item[key].toString().replace(".00",""))).toFixed(2) : parseFloat(item[key].toString().replace(".00","")).toFixed(2);
                }
            });
            console.log("result sum")
            console.log(result)
            return result;
            }, { id:'0',hc:'',employeeName: '',position:'',payCardNumber:''});
            tmppayroll.push(res1);        

            console.log("tmppayroll")
            console.log(tmppayroll)
            this.setState({isloading:false,payrollGrID:tmppayroll})
            
            if(data.payrolls.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    
    render() {
        
        return(
            <div>
            <Banner />
            <Container className="themed-container" fluid={true}>
                <Card className="mt-5">
                    <Card.Header>Generate Payroll</Card.Header>
                    <Card.Body>
                        <Form>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <Typeahead
                                        labelKey='payPeriod'
                                        id="basic-example"
                                        onChange={this.handleChangedPeriod}
                                        options={this.state.periodDDL}
                                        placeholder="Select Payroll Cut-Off"
                                    />
                                </Col>
                            </Form.Group>
                            <ButtonToolbar  >
                                <Button  className="ml-auto noser-button-mr1" variant="success"   onClick = { this.handleGenerateClick }>
                                    Generate
                                </Button>
                                <Button variant="info" className="noser-button-mr1" onClick = { this.handleFinalizedClick }>
                                    Finalized
                                </Button>
                                <Button className="noser-button" variant="primary" onClick = { this.SearchGeneratePayroll }>
                                    Search
                                </Button>
                            </ButtonToolbar>
                            <Form.Group  className="mt-4" controlId="formGridPassword">
                                
                                <Card.Header>Payroll Summary</Card.Header>
                                <NoserDataGrid 
                                    data={this.state.payrollGrID}
                                    deductions={this.state.dedMain}
                                    inclusions={this.state.incMain}
                                    premiums={this.state.premMain}
                                    paycards={this.state.paycardTypes}
                                />
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
                <NoserLoading show={this.state.isloading} />
        </div>
    );
}

}
export  default GeneratePayroll
