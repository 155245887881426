import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import CreatePayrollLocation from './Modal/CreatePayrollLocation';

class PositionRates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true,
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            locationDDL     : [],
            locationId      :   "",
            dataGrid        : [],
            arrList         : [],
            disableSaveBtn  : true,

        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }
    
    componentDidMount(){
        this.DataOnLoad();
        this.GetLocation();
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.DataOnLoad()
        this.GetLocation()
    }
    DataOnLoad = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress" :   "0.0.0.0",
            "UserId"    :   this.state.userinfo.userId,
            "CompanyId":this.state.userinfo.companyId,
            "Id"        :   ""
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollLocation",  param)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({dataGrid:data.payrollLocation,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    GetLocation = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress" :   "0.0.0.0",
            "UserId"    :   this.state.userinfo.userId,
            "CompanyId":this.state.userinfo.companyId,
            "Id"        :   ""
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollLocation",  param)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({locationDDL:data.payrollLocation,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangedLocation = (e) => {
        this.setState({isshow:false})
        if(e.length == 0) {
            this.state.locationId=""
            return
        } 
        this.state.locationId = e[0].id
        
    }
    handleSearchClick = async() =>{
        this.setState({isloading:true})
        const param = {
            "IpAddress" :   "0.0.0.0",
            "UserId"    :   this.state.userinfo.userId,
            "CompanyId":this.state.userinfo.companyId,
            "Id"        :   this.state.locationId
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollLocation",  param)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({dataGrid:data.payrollLocation,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleSaveClick = event => {
        this.setState({
            arrList     : [],
            isloading   : true,
            disableSaveBtn : true
        })

        for (let i = 0; i < this.state.dataGrid.length; i++) {
            if (this.state.dataGrid[i]["isModified"] == 1) {
                const obj = {
                    "Id"        :   this.state.dataGrid[i]["id"],
                    "Name"      :   this.state.dataGrid[i]["name"].toUpperCase(),
                    "ModifiedBy":   this.state.userinfo.userId,
                    "IsDeleted" :   this.state.dataGrid[i]["isDeleted"].toString()
                };

                this.state.arrList.push(obj);
            }
        }
                
        if(this.state.arrList.some(item =>item.Name === this.state.name ) === true ){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Location name already exist!",
                fade            :   true
            })
            return  
        }
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId,
            "payrollLocation":   this.state.arrList
        };
        console.log("Submit Edit Params")
        console.log(params)
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditPayrollLocation", params)
            .then(res => {
                const data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
                this.DataOnLoad();
                this.GetLocation();
             })
             .catch(error=>{
                this.setState(  {
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }

    GridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.dataGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
        this.setState({disableSaveBtn:disable})
    }
    render(){
        const columns = [
            {
                dataField: 'name',
                text: 'Location',
                editable    : true,
                headerStyle: (colum, colIndex) => {
                    return { whiteSpace:'nowrap',width:"100%"}},
                style:{whiteSpace:'nowrap',width:"100%"},
                validator: (newValue, row, column) => {
                  if (newValue === "") {
                    return {
                      valid: false,
                      message: 'Location should not empty'
                    };
                  }
                  return true;
                }
            },
        ]
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.dataGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableSaveBtn:isDisable})
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>MAINTENANCE >> PAYROLL >> PER TRIP - LOCATION</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        LOCATION
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedLocation}
                                            options={this.state.locationDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar sm={12}>
                                    <Button variant="success" className="ml-auto noser-button" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>
                                <div className="mt-3">
                                    <Card.Header>LOCATION LISTS</Card.Header>
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.dataGrid }
                                        columns = { columns }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div>No record found.</div> }
                                        rowClasses="noser-table-row-class"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        rowEvents={ rowEvents }
                                        selectRow = { selectRow }
                                    />
                                </div>
                                <div className="mt-1">
                                    <ButtonToolbar>
                                        <Button className="ml-auto noser-button-mr1" variant="success" onClick={this.handleSaveClick} disabled={this.state.disableSaveBtn} style={{marginRight:"5px"}}>Save</Button>
                                        <Button className="noser-button" variant="primary" onClick={this.handleModalShow}>Create</Button>
                                    </ButtonToolbar>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>  
                <NoserLoading show={this.state.isloading} />
                <CreatePayrollLocation 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div>
        )
    }

}

export  default PositionRates