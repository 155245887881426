import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Tabs, Tab, Redirect
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

class PreliminaryInterviewCreate extends Component {
        constructor(props) {
            super(props);
            this.state = {
                userinfo: [],
                isloading: true,
                AlertType: "",
                Show: false,
                Message: "",
                Color: "",
                Fade: true,
                navigate    : false,

                clientDDL : [],
                clientId : "",
                client : "",
                memberName : "",
                positionDDL : [],
                position : "",
                positionId : "",
                address : "",
                contactNumber : "",
                gender : "",
                age : "",
                civilStatus : "",
                freshGraduate : false,
                withWorkExperience:false,
                workExperienceYear : "",
                workExperienceMonths : "",
                referenceDDL : [],
                bodyId : "",
                hairId : "",
                complexionId : "",
                postureId : "",
                personalityId : "",
                mentalId : "",
                educationDDL:[
                    {"name":"Select Education Attainment","value":""},
                    {"name":"HIGH SCHOOL GRADUATE","value":"1"},
                    {"name":"2 YEAR COLLEGE LEVEL","value":"2"},
                    {"name":"COLLEGE GRADUATE","value":"3"},
                    {"name":"POST GRADUATE STUDIES","value":"4"},
                    {"name":"PROFESSIONAL LICENCE","value":"1"},
                ],
                educationId : "",
                professionalId : "",
                technicalId : "",
                storeOperationDDL : [],
                storeId : "",
                supportGroupDDL : [],
                supportGroupId : "",
                comments : "",
                assessmentDDL : [],
                assessmentId : "",
                preFinalDDL : [],
                preFinalId : "",
                assessedBy : "",
                //data : JSON.parse(sessionStorage.getItem("applicationformprelim_"))
            }
            this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
            this.state.data = JSON.parse(sessionStorage.getItem("application_" + this.state.userinfo.userId))
            
            this.state.applicationId   =   this.state.data.id
            this.state.profileId       =   this.state.data.profileId
            this.state.clientName      =   this.state.data.client
            this.state.clientId        =   this.state.data.clientId
            this.state.memberName      =   this.state.data.employeeName
            this.state.position        =   this.state.data.position
            this.state.positionId      =   this.state.data.positionId
           //this.state.address          = this.state.data.applicantAddresses[0].houseNumber + ", " + this.state.data.applicantAddresses[0].streetName+ ", " + this.state.data.applicantAddresses[0].barangay + ", " + this.state.data.applicantAddresses[0].city + ", " + this.state.data.applicantAddresses[0].province
            this.state.assessedBy = this.state.userinfo.fullName
           //this.state.contactNumber = this.state.data.mobileNumber
           //this.state.gender = this.state.data.gender
           //this.state.age = this.state.data.age
            //this.state.civilStatus = this.state.data.civilStatus
        }

    componentDidMount() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.GetClient();
        this.GetPosition();
        this.GetDataReferences();
        this.GetStoreOperation();
        this.GetSupportGroup();
        this.GetDataAssessment();
        this.GetDataPreFinal();
        this.GetApplicationList();
        this.GetApplicationFormDetails();
    }
    
    GetApplicationFormDetails = async()=> {
        //this.setState({isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ApplicationId" : this.state.applicationId,
        };
        console.log("applicationParams")
        console.log(params)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationFormDetails",  params)
            .then(res => {
            const data = res.data;
            console.log("GetApplicationFormDetails")
            console.log(data)
            if(data.status==="1"){
                this.setState({
                    address :    data.applicantAddresses[0]["houseNumber"] + ", " +
                                        data.applicantAddresses[0]["streetName"] + ", " + 
                                        data.applicantAddresses[0]["barangay"] + ", " + 
                                        data.applicantAddresses[0]["city"] + ", " + 
                                        data.applicantAddresses[0]["province"] + ", " +
                                        data.applicantAddresses[0]["region"]
                })
            }
            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    GetApplicationList = async()=> {
        //this.setState({isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ApplicationId" : this.state.applicationId,
        };
        console.log("applicationParams")
        console.log(params)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationInformation",  params)
            .then(res => {
            const data = res.data;
            this.state.contactNumber = data.mobileNumber
            this.state.gender = data.gender
            this.state.age = data.age
            this.state.civilStatus = data.civilStatus

            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    GetClient = async()=> {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  clientParams)
        .then(res => {
            const data = res.data;
            this.setState({
                clientDDL   :   data.clients,
                isloading   :   false
            });
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleChangeClient = (e) => {
        if(e.length == 0) {
            this.state.clientId     =   ""
            this.state.client   =   ""
            return
        }
        this.state.clientId     =   e[0].id
        this.state.client   =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    GetPosition = async()=> {
        this.setState({isloading:true})
        const positionParams = {
            "IpAddress"     : "0.0.0.0",
            "ClientId"      : this.state.userinfo.clientId,
            "UserId"        : this.state.userinfo.userId,
            "SectionId"     : "",
            "DepartmentId"  : "",
            "Name"          : ""
        };
        await
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  positionParams)
        .then(res => {
            const data = res.data;
            this.setState({
                positionDDL : data.positions,
                isloading   : false
            });
        })
        
    }
    
    handleChangePosition = (e) => {
        if(e.length == 0) {
            this.state.positionId     =   ""
            this.state.position   =   ""
            return
        }
        this.state.positionId     =   e[0].id
        this.state.position   =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    onChangeFreshGraduate= (e) => {
        // ////console.log(e)
        this.state.freshGraduate = e.target.checked
        if(this.state.freshGraduate == true)
        this.setState({
            withWorkExperience : false,
            workExperienceYear : "0",
            workExperienceMonths : "0"
        })
    }

    onChangeWithWorkExperience= (e) => {
        // ////console.log(e)
        this.state.withWorkExperience = e.target.checked
        this.setState({
            freshGraduate : false,
            workExperienceYear : "",
            workExperienceMonths : ""
        })
    }

    onChangeWorkExperienceYear = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ workExperienceYear: e.target.value,isshow:false, })
        }  
    };

    onChangeWorkExperienceMonths = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ workExperienceMonths: e.target.value,isshow:false, })
        }  
    };


    GetDataReferences = async()=> {
        const dataParams = {
            "IpAddress":"0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code":"0002"
        };
        await
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  dataParams)
        .then(res => {
            const data = res.data;
            // //console.log("Get Position Name");
            // //console.log(data);
            this.setState({ 
                referenceDDL  : data.dataReferences, 
            });
        })
        
    }

    onChangeBodyBuilt = (e) => {
        if(e.length == 0) {
            this.state.bodyId     =   ""
            return
        }
        this.state.bodyId     =   e[0].id
        this.setState({
            isshow:false,
        })
    }

    onChangeHair = (e) => {
        if(e.length == 0) {
            this.state.hairId     =   ""
            return
        }
        this.state.hairId     =   e[0].id
        this.setState({
            isshow:false,
        })
    }

    onChangeComplexion = (e) => {
        if(e.length == 0) {
            this.state.complexionId     =   ""
            return
        }
        this.state.complexionId     =   e[0].id
        this.setState({
            isshow:false,
        })
    }

    onChangePosture = (e) => {
        if(e.length == 0) {
            this.state.postureId     =   ""
            return
        }
        this.state.postureId     =   e[0].id
        this.setState({
            isshow:false,
        })
    }

    onChangePersonality = (e) => {
        if(e.length == 0) {
            this.state.personalityId     =   ""
            return
        }
        this.state.personalityId     =   e[0].id
        this.setState({
            isshow:false,
        })
    }

    onChangeMental = (e) => {
        if(e.length == 0) {
            this.state.mentalId     =   ""
            return
        }
        this.state.mentalId     =   e[0].id
        this.setState({
            isshow:false,
        })
    }

    handleChangeEducation = (e) => {
        this.setState({ educationId: e.target.value });
    };

    onChangeProfessional = (e) => {
        if(e.length == 0) {
            this.state.professionalId     =   ""
            return
        }
        this.state.professionalId     =   e[0].id
        this.setState({
            isshow:false,
        })
    }

    onChangeTechnical = (e) => {
        if(e.length == 0) {
            this.state.technicalId     =   ""
            return
        }
        this.state.technicalId     =   e[0].id
        this.setState({
            isshow:false,
        })
    }

    GetStoreOperation = async()=> {
        const dataParams = {
            "IpAddress":"0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code":"0006"
        };
        await
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  dataParams)
        .then(res => {
            const data = res.data;
            this.setState({ storeOperationDDL  : data.dataReferences});
        })
        
    }

    onChangeStoreOperation = (e) => {
        if(e.length == 0) {
            this.state.storeId     =   ""
            return
        }
        this.state.storeId     =   e[0].id
        this.setState({
            isshow:false,
        })
    }

    GetSupportGroup = async()=> {
        const dataParams = {
            "IpAddress":"0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code":"0004"
        };
        await
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  dataParams)
        .then(res => {
            const data = res.data;
            this.setState({ supportGroupDDL  : data.dataReferences});
        })
        
    }

    onChangeSupportGroup= (e) => {
            if (e.length > 0) {
            this.state.supportGroupId = e[0].id
        } else {
            this.state.supportGroupId = ""
        }
        this.setState({
            isshow:false,
        })
    }

    onChangeComments = (e) => {
        this.setState({ comments: e.target.value });
    };

    GetDataAssessment = async()=> {
        const dataParams = {
            "IpAddress":"0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code":"0005"
        };
        await
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  dataParams)
        .then(res => {
            const data = res.data;
            //console.log("Get assess");
            //console.log(data);
            this.setState({ assessmentDDL  : data.dataReferences});
        })
        
    } 

    onChangeAssessment= (e) => {
            if (e.length > 0) {
            this.state.assessmentId = e[0].id
        } else {
            this.state.assessmentId = ""
        }
        this.setState({
            isshow:false,
        })
    }

    GetDataPreFinal = async()=> {
        const dataParams = {
            "IpAddress":"0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code":"0003"
        };
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  dataParams)
        .then(res => {
            const data = res.data;
            //console.log("Get pre final");
            //console.log(data);
            this.setState({ preFinalDDL  : data.dataReferences});
        })
        
    } 

    onChangeRecommendation= (e) => {
            if (e.length > 0) {
            this.state.preFinalId = e[0].id
        } else {
            this.state.preFinalId = ""
        }
        this.setState({
            isshow:false,
        })
    }

    handleClickStatus = (statusId) => {

        this.setState({isloading:true });
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ApplicationFormId":this.state.applicationId,
            "EducationalId":this.state.educationId,
            "IsFreshGrad":(this.state.freshGraduate)? "1" : "0",
            "WorkExperienceMonths":this.state.workExperienceMonths,
            "WorkExperienceYears":this.state.workExperienceYear,
            "WithWorkExperience":(this.state.withWorkExperience)? "1" : "0",
            "BodyBuiltId":this.state.bodyId,
            "HairId":this.state.hairId,
            "ComplexionId":this.state.complexionId,
            "PostureId": this.state.postureId,
            "PersonalityId":this.state.personalityId,
            "MentalId":this.state.mentalId,
            "WorkExperienceId":this.state.professionalId,
            "TechnicalExperienceId":this.state.technicalId,
            "SupportGroupId":this.state.supportGroupId,
            "AssessmentId":this.state.assessmentId,
            "Comments":this.state.comments,
            "RecommendationId":this.state.preFinalId,
            "AssessedBy":this.state.userinfo.userId,
            "StoreOperationId":this.state.storeId,
            "PositionId":this.state.positionId,
            "StatusId":statusId
        }
        console.log(params)
        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Recruitment/AddPreliminaryInterview",  params)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
            this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true,
                    navigate    :   true,

                });
            }
            else {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: data.message,
                    fade:true
                });
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    render() {
        const { navigate } = this.state;
        if (navigate) {
            return <Redirect to="/applicationform" push={true} />;
        } 
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>RECRUITMENT >> PRELIMINARY INTERVIEWS (CREATE)</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                        <Typeahead 
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientDDL}
                                            placeholder="Select Client" 
                                            defaultSelected={[this.state.clientName]}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                name="memberName"
                                                value={this.state.memberName}
                                                //onChange={this.onChangeLastName} 
                                                disabled 
                                            />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridCity">
                                        <Form.Label style={{fontWeight : "bold"}}>POSITION APPLIED</Form.Label>
                                        <Typeahead
                                            ref='clearPosition'
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangePosition}
                                            options={this.state.positionDDL}
                                            placeholder="Select Position" 
                                            defaultSelected={[this.state.position]}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold",}}>CURRENT ADDRESS</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            name="address"
                                            value={this.state.address}
                                            //onChange={this.onChangeLastName} 
                                            disabled 
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridCity">
                                        <Form.Label style={{fontWeight : "bold"}}>CONTACT NUMBER</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            name="contactNumber"
                                            value={this.state.contactNumber}
                                            //onChange={this.onChangeLastName} 
                                            disabled 
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold",}}>GENDER</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            name="gender"
                                            value={this.state.gender}
                                            //onChange={this.onChangeLastName} 
                                            disabled 
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridCity">
                                        <Form.Label style={{fontWeight : "bold"}}>AGE</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            name="age"
                                            value={this.state.age}
                                            //onChange={this.onChangeLastName} 
                                            disabled 
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold",}}>CIVIL STATUS</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            name="civilStatus"
                                            value={this.state.civilStatus}
                                            //onChange={this.onChangeLastName} 
                                            disabled 
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridCity">
                                        <Form.Check 
                                            type="checkbox" 
                                            label="FRESH GRADUATE?" 
                                            name="freshGraduate"
                                            checked={this.state.freshGraduate}
                                            onChange={this.onChangeFreshGraduate}
                                            style={{fontWeight : "bold"}}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridCity">
                                        <Form.Check 
                                            type="checkbox" 
                                            label="WITH WORK EXPERIENCE?" 
                                            name="withWorkExperience"
                                            checked={this.state.withWorkExperience}
                                            onChange={this.onChangeWithWorkExperience}
                                            style={{fontWeight : "bold"}}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridCity">
                                        <Form.Label style={{fontWeight : "bold"}}>WORK EXPERIENCE YEARS</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            name="workExperienceYear"
                                            value={this.state.workExperienceYear}
                                            onChange={this.onChangeWorkExperienceYear} 
                                            autoComplete="off"
                                            placeholder="Enter Work Experience Years"
                                            maxLength="3" 
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridCity">
                                        <Form.Label style={{fontWeight : "bold"}}>WORK EXPERIENCE MONTHS</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            name="workExperienceMonths"
                                            value={this.state.workExperienceMonths}
                                            onChange={this.onChangeWorkExperienceMonths} 
                                            autoComplete="off"
                                            placeholder="Enter Work Experience Months" 
                                            maxLength="3"
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Card className="mt-3">
                                    <Card.Header>APPEARANCE</Card.Header>
                                    <Card.Body>
                                        <Form.Row className="mt-2">
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label style={{fontWeight : "bold"}}>BODY BUILT</Form.Label>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeBodyBuilt}
                                                    options={this.state.referenceDDL}
                                                    placeholder="Select Body Built" 
                                                /> 
                                            </Form.Group>
                                            <Form.Group as={Col} sm="1" controlId="formGridCity">
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label style={{fontWeight : "bold"}}>HAIR</Form.Label>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeHair}
                                                    options={this.state.referenceDDL}
                                                    placeholder="Select Hair" 
                                                /> 
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className="mt-2">
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label style={{fontWeight : "bold"}}>COMPLEXION</Form.Label>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeComplexion}
                                                    options={this.state.referenceDDL}
                                                    placeholder="Select Complexion" 
                                                /> 
                                            </Form.Group>
                                            <Form.Group as={Col} sm="1" controlId="formGridCity">
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label style={{fontWeight : "bold"}}>POSTURE</Form.Label>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangePosture}
                                                    options={this.state.referenceDDL}
                                                    placeholder="Select Posture" 
                                                /> 
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                                <Card className="mt-3">
                                    <Card.Header>PERSONALITY</Card.Header>
                                    <Card.Body>
                                        <Form.Row className="mt-2">
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label style={{fontWeight : "bold"}}>PERSONALITY</Form.Label>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangePersonality}
                                                    options={this.state.referenceDDL}
                                                    placeholder="Select Personality" 
                                                /> 
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                                <Card className="mt-3">
                                    <Card.Header>MENTAL/EDUCATIONAL/PROFESSIONAL</Card.Header>
                                    <Card.Body>
                                        <Form.Row className="mt-2">
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label style={{fontWeight : "bold"}}>MENTAL</Form.Label>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeMental}
                                                    options={this.state.referenceDDL}
                                                    placeholder="Select Mental" 
                                                /> 
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                                <Card className="mt-3">
                                    <Card.Header>EDUCATION</Card.Header>
                                    <Card.Body>
                                        <Form.Row className="mt-2">
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label style={{fontWeight : "bold"}}>EDUCATION ATTAINMENT</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    onChange={this.handleChangeEducation}
                                                    value={this.state.educationId}
                                                >
                                                {this.state.educationDDL.map(item => (
                                                    <option key={item.value} value={item.value}>
                                                    {item.name}
                                                    </option>
                                                ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                                <Card className="mt-3">
                                    <Card.Header>PERSONALITY</Card.Header>
                                    <Card.Body>
                                        <Form.Row className="mt-2">
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label style={{fontWeight : "bold"}}>PROFESSIONAL/WORK EXPERIENCE</Form.Label>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeProfessional}
                                                    options={this.state.referenceDDL}
                                                    placeholder="Select Professional/Work Experience" 
                                                /> 
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                                <Card className="mt-3">
                                    <Card.Header>TECHNICAL EXPERIENCE/REQUIREMENTS</Card.Header>
                                    <Card.Body>
                                        <Form.Row className="mt-2">
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label style={{fontWeight : "bold"}}>TECHNICAL EXPERIENCE</Form.Label>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeTechnical}
                                                    options={this.state.referenceDDL}
                                                    placeholder="Select Technical Experience" 
                                                /> 
                                            </Form.Group>
                                            <Form.Group as={Col} sm="1" controlId="formGridCity">
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label style={{fontWeight : "bold"}}>STORE OPERATIONS</Form.Label>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeStoreOperation}
                                                    options={this.state.storeOperationDDL}
                                                    placeholder="Select Store Operation" 
                                                /> 
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className="mt-2">
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label style={{fontWeight : "bold"}}>SUPPORT GROUPS</Form.Label>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeSupportGroup}
                                                    options={this.state.supportGroupDDL}
                                                    placeholder="Select Support Groups" 
                                                /> 
                                            </Form.Group>
                                            <Form.Group as={Col} sm="1" controlId="formGridCity">
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label style={{fontWeight : "bold"}}>COMMENTS</Form.Label>
                                                <Form.Control 
                                                    type="text"
                                                    name="comments"
                                                    value={this.state.comments}
                                                    onChange={this.onChangeComments} 
                                                    autoComplete="off"
                                                    placeholder="Enter Comments"
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className="mt-2">
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label style={{fontWeight : "bold"}}>ASSESSMENT</Form.Label>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeAssessment}
                                                    options={this.state.assessmentDDL}
                                                    placeholder="Select Assessment" 
                                                /> 
                                            </Form.Group>
                                            <Form.Group as={Col} sm="1" controlId="formGridCity">
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label style={{fontWeight : "bold"}}>PRE FINAL RECOMMENDATION</Form.Label>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeRecommendation}
                                                    options={this.state.preFinalDDL}
                                                    placeholder="Select Pre Final Recommendation" 
                                                /> 
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className="mt-2">
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label style={{fontWeight : "bold"}}>ASSESSED BY</Form.Label>
                                                <Form.Control 
                                                    type="text"
                                                    name="assessedBy"
                                                    value={this.state.userinfo.fullName}
                                                    //onChange={this.onChangeLastName} 
                                                    disabled 
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                                <ButtonToolbar className="mt-3">
                                    <Button className="ml-auto noser-button-mr1" variant="success" onClick = {() => this.handleClickStatus('1')}>Save</Button>
                                    <Button className="noser-button-mr1"   variant="primary" onClick = {() => this.handleClickStatus('3')}>Submit</Button>                                    
                                    <NavLink to="/applicationform">
                                        <Button  className="noser-button noser-button-mr1" variant="danger">Back</Button>
                                    </NavLink>
                                </ButtonToolbar>
                            </Form>
                        </Card.Body>
                    </Card>
                    <div style={{height : 20}}></div>
                </Container>
                <NoserLoading show={this.state.isloading} />

            </div>
        )
    }

}

export  default PreliminaryInterviewCreate
