import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

 
class CasesMonitoringStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            dateFrom                            :   "",
            dateTo                              :   "",
            modalTrainingRegisterShow           :   false,

            filterForResolution     :"For Resolution",
            filterPending           :"Pending",
            filterForAppeal         :"For Appeal",
            filterResolved          :"Closed",
            filterDoleCase          :"Dole Case",
            filterDoleCompliance    :"Dole Compliance",
            filterCriminalCase      :"Criminal Case",

            itemLisTs               :[],
            countForResolution      :[],
            countPending            :[],
            countForAppeal          :[],
            countResolved           :[],
            countTotal              :[],

            countDoleComplianceTotal            :[],
            countDoleCasesTotal                 :[],
            countCriminalCasesTotal             :[],

            countTotalClosedDoleCompliance      :[],
            countTotalClosedDoleCases           :[],
            countTotalClosedCriminalCases       :[],

            countPendingDoleCompliance      :[],
            countPendingDoleCases           :[],
            countPendingCriminalCases       :[],

            countForAppealDoleCompliance      :[],
            countForResolutionDoleCompliance      :[],
            
            ListForResolution       :[],
            ListPending             :[],
            ListForAppeal           :[],
            ListResolved            :[],

            statusTableList         :[],
            TotalStatus             :[],

        }
    }


    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getTestDoleComplianceTotalAll();;
        this.getDoleCasesTotalCount();;
        this.getCriminalCasesTotalAll();;
        this.getTestDoleComplianceClosed();;
        this.getCriminalCasesClosed();;
        this.getTotalClosedTestDoleCases();;
        this.getDoleCompliancePending();;
        this.getDoleCasesPendingCount();;
        this.getCriminalCasesPending();;
        this.getTestDoleComplianceForAppeal();;
        this.getTestDoleComplianceForResolution();;
        
    }
    GridDataModified(oldValue, newValue, id, column) {
        //////console.log(id)
        this.state.statusTableList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }
    getTestDoleComplianceTotalAll =()=>{
       
        let filter_data ={}
        const getParams ={
            "_collection" : "TestDoleCompliance",
            "filter_data" : filter_data
        }
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            const itemUsersDataLists = this.buildListDoleCompliance(data["TestDoleCompliance"])
            this.state.itemUserDataList  =  itemUsersDataLists
            let countTotalAllTmp = this.state.itemUserDataList.length
                  
            this.setState({
                countDoleComplianceTotal:countTotalAllTmp
            }) 
            this.getAllStatusTable();
        })
        .catch(error=>{
            ////console.log("error: " + error)
        })
    }
    getDoleCasesTotalCount =()=>{
    
        let filter_data ={}

        const getParams ={
            "_collection" : "TestDoleCases",
            "filter_data" : filter_data
        }
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            const itemUsersDataLists = this.buildListDoleCases(data["TestDoleCases"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            let countTmp = this.state.itemUserDataList.length; 

            this.setState({
                countDoleCasesTotal:countTmp
            })
            this.getAllStatusTable();

        })
        .catch(error=>{
            ////console.log("error: " + error)
        })
    }
    getCriminalCasesTotalAll =()=>{
       
        let filter_data ={}

        const getParams ={
            "_collection" : "TestCriminalCases",
            "filter_data" : filter_data
        }       
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data        
            const itemUsersDataLists = this.buildListCriminalCases(data["TestCriminalCases"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            let countTotalTmp = this.state.itemUserDataList.length
                              
            this.setState({
                countCriminalCasesTotal:countTotalTmp
            })
            this.getAllStatusTable();
          
        })
        .catch(error=>{
            ////console.log("error: " + error)
        })
    }
    getTestDoleComplianceClosed =()=>{
       
        let filter_data ={'updateStatus':this.state.filterResolved}

        const getParams ={
            "_collection" : "TestDoleCompliance",
            "filter_data" : filter_data
        }
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
        
            const itemUsersDataLists = this.buildListDoleCompliance(data["TestDoleCompliance"])

            this.state.itemUserDataList  =  itemUsersDataLists
            let countClosedTmp = this.state.itemUserDataList.length            
          
            this.setState({
                countTotalClosedDoleCompliance:countClosedTmp
            })                     
                                        
            this.getAllStatusTable();
        })
        .catch(error=>{
            ////console.log("error: " + error)
        })
    }
    getTotalClosedTestDoleCases =()=>{
    
        let filter_data ={'updateStatus':this.state.filterResolved}
        const getParams ={
            "_collection" : "TestDoleCases",
            "filter_data" : filter_data
        }
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
        
            const itemUsersDataLists = this.buildListDoleCases(data["TestDoleCases"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            
            let countTmp = this.state.itemUserDataList.length; 
            
            this.setState({
                countTotalClosedDoleCases:countTmp
            })
            this.getAllStatusTable();

        })
        .catch(error=>{
            ////console.log("error: " + error)
        })
    }
    getCriminalCasesClosed =()=>{
       
        let filter_data ={'status':this.state.filterResolved}

        const getParams ={
            "_collection" : "TestCriminalCases",
            "filter_data" : filter_data
        }
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data        
            const itemUsersDataLists = this.buildListCriminalCases(data["TestCriminalCases"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            let countClosedTmp = this.state.itemUserDataList.length

            this.setState({
                countTotalClosedCriminalCases:countClosedTmp
            })                                    
                                     
            this.getAllStatusTable();
        })
        .catch(error=>{
            ////console.log("error: " + error)
        })
    }
    getDoleCompliancePending =()=>{
       
        let filter_data ={'updateStatus':this.state.filterPending}
        const getParams ={
            "_collection" : "TestDoleCompliance",
            "filter_data" : filter_data
        }
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            const itemUsersDataLists = this.buildListDoleCompliance(data["TestDoleCompliance"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            let countPendingTmp = this.state.itemUserDataList.length

            this.setState({
                countPendingDoleCompliance:countPendingTmp
            }) 
            this.getAllStatusTable();
        })
        .catch(error=>{
            ////console.log("error: " + error)
        })
    }
    getDoleCasesPendingCount =()=>{
    
        let filter_data ={'updateStatus':this.state.filterPending}
        const getParams ={
            "_collection" : "TestDoleCases",
            "filter_data" : filter_data
        }
        
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            const itemUsersDataLists = this.buildListDoleCases(data["TestDoleCases"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            let countTmp = this.state.itemUserDataList.length;
            
            this.setState({
                countPendingDoleCases:countTmp
            })
            this.getAllStatusTable();

        })
        .catch(error=>{
            ////console.log("error: " + error)
        })
    }
    getCriminalCasesPending =()=>{
       
        let filter_data ={'status':this.state.filterPending}
        const getParams ={
            "_collection" : "TestCriminalCases",
            "filter_data" : filter_data
        }
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            const itemUsersDataLists = this.buildListCriminalCases(data["TestCriminalCases"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            let countPendingTmp = this.state.itemUserDataList.length
          
            
            this.setState({
                countPendingCriminalCases:countPendingTmp
            })
            this.getAllStatusTable();  
        })
        .catch(error=>{
            ////console.log("error: " + error)
        })
    }
    getTestDoleComplianceForAppeal =()=>{
       
        let filter_data ={'updateStatus':this.state.filterForAppeal}
        const getParams ={
            "_collection" : "TestDoleCompliance",
            "filter_data" : filter_data
        }
     
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
           
            const itemUsersDataLists = this.buildListDoleCompliance(data["TestDoleCompliance"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            let countForAppealTmp = this.state.itemUserDataList.length

            this.setState({
                countForAppealDoleCompliance:countForAppealTmp
            })
            this.getAllStatusTable();
        })
        .catch(error=>{
            //console.log("error: " + error)
        })
        
    }
    getTestDoleComplianceForResolution =()=>{
       
        let filter_data ={'updateStatus':this.state.filterForResolution}

        const getParams ={
            "_collection" : "TestDoleCompliance",
            "filter_data" : filter_data
        }
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            const itemUsersDataLists = this.buildListDoleCompliance(data["TestDoleCompliance"])
            this.state.itemUserDataList  =  itemUsersDataLists
            let countForResolutionTmp = this.state.itemUserDataList.length
                               
            this.setState({
                countForResolutionDoleCompliance:countForResolutionTmp
            }) 
            this.getAllStatusTable();                  
        })
        .catch(error=>{
            //console.log("error: " + error)
        })
    }
    getAllStatusTable=()=>{

        let allStatus           =[]
        
        let id1Tmp              ='1'
        let id2Tmp              ='2'
        let id3Tmp              ='3'
        let id4Tmp              ='4'
        let id5Tmp              ='5'
        let isDraftTmp          ='0'
        let isDeletedTmp        ='0'
        let createdbyTmp        ='0'
        let createddateTmp      ='0'
        let modifiedbyTmp       ='0'
        let modifieddateTmp     ='0'
        let isModifiedTmp       ='0'

        let itemTmp1 = "Dole Compliance"
        let countDoleComplianceTmp              = this.state.countDoleComplianceTotal
        let countClosedDoleComplianceTmp        = this.state.countTotalClosedDoleCompliance
        let countPendingDoleComplianceTmp       = this.state.countPendingDoleCompliance
        let countForAppealDoleComplianceTmp     = this.state.countForAppealDoleCompliance
        let countForResolutionDoleComplianceTmp = this.state.countForResolutionDoleCompliance

        let obj1 = {
            'id'                    :   id1Tmp,
            'items'                 :   itemTmp1,
            'countTotal'            :   countDoleComplianceTmp,
            'countClosed'           :   countClosedDoleComplianceTmp,
            'countPending'          :   countPendingDoleComplianceTmp,
            'countForAppeal'        :   countForAppealDoleComplianceTmp,
            'countForResolution'    :   countForResolutionDoleComplianceTmp,
            'isDraft'               :   isDraftTmp,
            'isDeleted'             :   isDeletedTmp,
            'createdby'             :   createdbyTmp,
            'createddate'           :   createddateTmp,
            'modifiedby'            :   modifiedbyTmp,
            'modifieddate'          :   modifieddateTmp,
            'isModified'            :   isModifiedTmp,
          
        }
        allStatus.push(obj1)

        let itemTmp2 = "Dole Cases"
        let countDoleCasesTotalTmp              = this.state.countDoleCasesTotal
        let countTotalClosedDoleCasesTmp        = this.state.countTotalClosedDoleCases
        let countPendingDoleCasesTmp            = this.state.countPendingDoleCases
        let countForAppealDoleCasesTmp          = '0'
        let countForResolutionDoleCasesTmp      = '0'

        let obj2 = {
            'id'                    :   id2Tmp,
            'items'                 :   itemTmp2,
            'countTotal'            :   countDoleCasesTotalTmp,
            'countClosed'           :   countTotalClosedDoleCasesTmp,
            'countPending'          :   countPendingDoleCasesTmp,
            'countForAppeal'        :   countForAppealDoleCasesTmp,
            'countForResolution'    :   countForResolutionDoleCasesTmp,
            'isDraft'               :   isDraftTmp,
            'isDeleted'             :   isDeletedTmp,
            'createdby'             :   createdbyTmp,
            'createddate'           :   createddateTmp,
            'modifiedby'            :   modifiedbyTmp,
            'modifieddate'          :   modifieddateTmp,
            'isModified'            :   isModifiedTmp,
          
        }
        allStatus.push(obj2)

        let itemTmp3 = "Criminal Cases"
        let countCriminalCasesTotalTmp              = this.state.countCriminalCasesTotal
        let countTotalClosedCriminalCasesTmp        = this.state.countTotalClosedCriminalCases
        let countPendingCriminalCasesTmp            = this.state.countPendingCriminalCases
        let countForAppealCriminalCasesTmp          = '0'
        let countForResolutionCriminalCasesTmp      = '0'

        let obj3 = {
            'id'                    :   id3Tmp,
            'items'                 :   itemTmp3,
            'countTotal'            :   countCriminalCasesTotalTmp,
            'countClosed'           :   countTotalClosedCriminalCasesTmp,
            'countPending'          :   countPendingCriminalCasesTmp,
            'countForAppeal'        :   countForAppealCriminalCasesTmp,
            'countForResolution'    :   countForResolutionCriminalCasesTmp,
            'isDraft'               :   isDraftTmp,
            'isDeleted'             :   isDeletedTmp,
            'createdby'             :   createdbyTmp,
            'createddate'           :   createddateTmp,
            'modifiedby'            :   modifiedbyTmp,
            'modifieddate'          :   modifieddateTmp,
            'isModified'            :   isModifiedTmp,
          
        }
        allStatus.push(obj3)

        this.setState({
            statusTableList:allStatus
        })
       
    }

    buildListDoleCompliance(data) {
      
        let itemList =[]
    
        let idList                          =[]
        let clientList                      =[]
        let locationList                    =[]
        let caseNoList                      =[]
        let MembersInvolvedList             =[]
        let refNoList                       =[]
        let dateOfAssessmentList            =[]
        let inspectorList                   =[]
        let findingsList                    =[]
        let dateOfConferenceList            =[]
        let submittedDocList                =[]
        let docDateNOIList                  =[]
        let dateReceivedList                =[]
        let hearingOfficerList              =[]
        let mandatoryFindingsList           =[]
        let mandatoryDateOfConferenceList   =[]
        let minutesOfMeetingList            =[]
        let mandatorySubmittedDocList       =[]
        let mandatoryDocDateList            =[]
        let decisionList                    =[]
        let counselList                     =[]
        let latestUpdateList                =[]
        let statusDateList                  =[]
        let remarksList                     =[]
        let updateStatusList                =[]
        let documentAttachedList            =[]        
        let isDraftList                     =[]
        let isDeletedList                   =[]
        let createdbyList                   =[]
        let createddateList                 =[]
        let modifiedbyList                  =[]
        let modifieddateList                =[]
        let isModifiedList                  =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
    
            let s1 = data[i].split("':")
            
            let idClean                         = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                        = idClean.split("',")[0]
            let clientTmp                       = s1[2].split("',")[0]
            let locationTmp                     = s1[3].split("',")[0]
            let caseNoTmp                       = s1[4].split("',")[0]
            let MembersInvolvedTmp              = s1[5].split("',")[0]
            let refNoTmp                        = s1[6].split("',")[0]
            let dateOfAssessmentTmp             = s1[7].split("',")[0]
            let inspectorTmp                    = s1[8].split("',")[0]
            let findingsTmp                     = s1[9].split("',")[0]
            let dateOfConferenceTmp             = s1[10].split("',")[0]
            let submittedDocTmp                 = s1[11].split("',")[0]
            let docDateNOITmp                   = s1[12].split("',")[0]
            let dateReceivedTmp                 = s1[13].split("',")[0]
            let hearingOfficerTmp               = s1[14].split("',")[0]
            let mandatoryFindingsTmp            = s1[15].split("',")[0]
            let mandatoryDateOfConferenceTmp    = s1[16].split("',")[0]
            let minutesOfMeetingTmp             = s1[17].split("',")[0]
            let mandatorySubmittedDocTmp        = s1[18].split("',")[0]
            let mandatoryDocDateTmp             = s1[19].split("',")[0]
            let decisionTmp                     = s1[20].split("',")[0]
            let counselTmp                      = s1[21].split("',")[0]
            let latestUpdateTmp                 = s1[22].split("',")[0]
            let statusDateTmp                   = s1[23].split("',")[0]
            let remarksTmp                      = s1[24].split("',")[0]
            let updateStatusTmp                 = s1[25].split("',")[0]
            let documentAttachedTmp             = s1[26].split("',")[0]            
            let isDraftListTmp                  = s1[27].split("',")[0]
            let isDeletedListTmp                = s1[28].split("',")[0]
            let createdbyListTmp                = s1[29].split("',")[0]
            let createddateListTmp              = s1[30].split("',")[0]
            let modifiedbyListTmp               = s1[31].split("',")[0]
            let modifieddateListTmp             = s1[32].split("',")[0]
            let isModifiedListTmp               = s1[33].split("',")[0]

        
            idList.push(IdClean2.replace("'",""))
            clientList.push(clientTmp.replace("'",""))
            locationList.push(locationTmp.replace("'",""))
            caseNoList.push(caseNoTmp.replace("'",""))
            MembersInvolvedList.push(MembersInvolvedTmp.replace("'",""))
            refNoList.push(refNoTmp.replace("'",""))
            dateOfAssessmentList.push(dateOfAssessmentTmp.replace("'",""))
            inspectorList.push(inspectorTmp.replace("'",""))
            findingsList.push(findingsTmp.replace("'",""))
            dateOfConferenceList.push(dateOfConferenceTmp.replace("'",""))
            submittedDocList.push(submittedDocTmp.replace("'",""))
            docDateNOIList.push(docDateNOITmp.replace("'",""))
            dateReceivedList.push(dateReceivedTmp.replace("'",""))
            hearingOfficerList.push(hearingOfficerTmp.replace("'",""))
            mandatoryFindingsList.push(mandatoryFindingsTmp.replace("'",""))
            mandatoryDateOfConferenceList.push(mandatoryDateOfConferenceTmp.replace("'",""))
            minutesOfMeetingList.push(minutesOfMeetingTmp.replace("'",""))
            mandatorySubmittedDocList.push(mandatorySubmittedDocTmp.replace("'",""))
            mandatoryDocDateList.push(mandatoryDocDateTmp.replace("'",""))
            decisionList.push(decisionTmp.replace("'",""))
            counselList.push(counselTmp.replace("'",""))
            latestUpdateList.push(latestUpdateTmp.replace("'",""))
            statusDateList.push(statusDateTmp.replace("'",""))
            remarksList.push(remarksTmp.replace("'",""))
            updateStatusList.push(updateStatusTmp.replace("'",""))
            documentAttachedList.push(documentAttachedTmp.replace("'",""))                       
            isDraftList.push(isDraftListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                            :   idList[i],
                'client'                        :   clientList[i],
                'location'                      :   locationList[i],
                'caseNo'                        :   caseNoList[i],
                'MembersInvolved'               :   MembersInvolvedList[i],
                'refNo'                         :   refNoList[i],
                'dateOfAssessment'              :   dateOfAssessmentList[i],
                'inspector'                     :   inspectorList[i],
                'findings'                      :   findingsList[i],
                'dateOfConference'              :   dateOfConferenceList[i],
                'submittedDoc'                  :   submittedDocList[i],
                'docDateNOI'                    :   docDateNOIList[i],
                'dateReceived'                  :   dateReceivedList[i],
                'hearingOfficer'                :   hearingOfficerList[i],
                'mandatoryFindings'             :   mandatoryFindingsList[i],
                'mandatoryDateOfConference'     :   mandatoryDateOfConferenceList[i],
                'minutesOfMeeting'              :   minutesOfMeetingList[i],
                'mandatorySubmittedDoc'         :   mandatorySubmittedDocList[i],
                'mandatoryDocDate'              :   mandatoryDocDateList[i],
                'decision'                      :   decisionList[i],
                'counsel'                       :   counselList[i],
                'latestUpdate'                  :   latestUpdateList[i],
                'statusDate'                    :   statusDateList[i],
                'remarks'                       :   remarksList[i],
                'updateStatus'                  :   updateStatusList[i],
                'documentAttached'              :   documentAttachedList[i],                
                'isDraft'                       :   isDraftList[i],
                'isDeleted'                     :   isDeletedList[i],
                'createdby'                     :   createdbyList[i],
                'createddate'                   :   createddateList[i],
                'modifiedby'                    :   modifiedbyList[i],
                'modifieddate'                  :   modifieddateList[i],
                'isModified'                    :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }        
      
        return itemList
    }
    buildListCriminalCases(data) {
        ////console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let clientList                  =[]
        let employeeNameList            =[]
        let positionList                =[]
        let employeeNoList              =[]
        let locationList                =[]
        let dateCaseFiledList           =[]
        let caseFiledList               =[]
        let venueList                   =[]
        let SchedOfHearingList          =[]
        let prosecutorList              =[]
        let legalList                   =[]
        let latestUpdateList            =[]
        let updateDateList              =[]
        let remarksList                 =[]
        let statusList                  =[]
        let fileList                    =[]
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
    
            let s1 = data[i].split("':")
            
            let idClean                     = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let clientTmp                   = s1[2].split("',")[0]
            let employeeNameTmp             = s1[3].split("',")[0]
            let positionTmp                 = s1[4].split("',")[0]
            let employeeNoTmp               = s1[5].split("',")[0]
            let locationTmp                 = s1[6].split("',")[0]
            let dateCaseFiledTmp            = s1[7].split("',")[0]
            let caseFiledTmp                = s1[8].split("',")[0]
            let venueTmp                    = s1[9].split("',")[0]
            let SchedOfHearingTmp           = s1[10].split("',")[0]
            let prosecutorTmp               = s1[11].split("',")[0]
            let legalTmp                    = s1[12].split("',")[0]
            let latestUpdateTmp             = s1[13].split("',")[0]
            let updateDateTmp               = s1[14].split("',")[0]
            let remarksTmp                  = s1[15].split("',")[0]
            let statusTmp                   = s1[16].split("',")[0]
            let fileTmp                     = s1[17].split("',")[0]            
            let isDraftListTmp              = s1[18].split("',")[0]
            let isDeletedListTmp            = s1[19].split("',")[0]
            let createdbyListTmp            = s1[20].split("',")[0]
            let createddateListTmp          = s1[21].split("',")[0]
            let modifiedbyListTmp           = s1[22].split("',")[0]
            let modifieddateListTmp         = s1[23].split("',")[0]
            let isModifiedListTmp           = s1[24].split("',")[0]

        
            idList.push(IdClean2.replace("'",""))
            clientList.push(clientTmp.replace("'",""))
            employeeNameList.push(employeeNameTmp.replace("'",""))
            positionList.push(positionTmp.replace("'",""))
            employeeNoList.push(employeeNoTmp.replace("'",""))
            locationList.push(locationTmp.replace("'",""))
            dateCaseFiledList.push(dateCaseFiledTmp.replace("'",""))
            caseFiledList.push(caseFiledTmp.replace("'",""))
            venueList.push(venueTmp.replace("'",""))
            SchedOfHearingList.push(SchedOfHearingTmp.replace("'",""))
            prosecutorList.push(prosecutorTmp.replace("'",""))
            legalList.push(legalTmp.replace("'",""))
            latestUpdateList.push(latestUpdateTmp.replace("'",""))
            updateDateList.push(updateDateTmp.replace("'",""))
            remarksList.push(remarksTmp.replace("'",""))
            statusList.push(statusTmp.replace("'",""))
            fileList.push(fileTmp.replace("'",""))                       
            isDraftList.push(isDraftListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'client'                :   clientList[i],
                'employeeName'          :   employeeNameList[i],
                'position'              :   positionList[i],
                'employeeNo'            :   employeeNoList[i],
                'location'              :   locationList[i],
                'dateCaseFiled'         :   dateCaseFiledList[i],
                'caseFiled'             :   caseFiledList[i],
                'venue'                 :   venueList[i],
                'SchedOfHearing'        :   SchedOfHearingList[i],
                'prosecutor'            :   prosecutorList[i],
                'legal'                 :   legalList[i],
                'latestUpdate'          :   latestUpdateList[i],
                'updateDate'            :   updateDateList[i],
                'remarks'               :   remarksList[i],
                'status'                :   statusList[i],
                'file'                  :   fileList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
        
       
        return itemList
    }
    buildListDoleCases(data) {
        //////console.log("data start")
        let itemList =[]
    
        let idList                          =[]
        let selectedClientNameList          =[]
        let selectedEmployeeNameList        =[]
        let selectedPositionList            =[]
        let selectedEmployeeNoList          =[]
        let selectedEmployeeBranchList      =[]
        let groundOfSeperationList          =[]
        let caseNumeberList                 =[]
        let dateFiledList                   =[]
        let dateReceivedList                =[]
        let complaintList                   =[]
        let scheduleOfHearingList           =[]
        let venueList                       =[]
        let hearingOfficerList              =[]
        let caseNoNLRCList                  =[]
        let mandatoryDateFiledList          =[]
        let mandatoryDateReceivedList       =[]
        let mandatoryComplaintList          =[]
        let mandatoryScheduleOfHearingList  =[]
        let mandatoryVenueList              =[]
        let mandatoryLaborArbiterList       =[]
        let particularsMomStConfMeetingList =[]
        let particularsMomNdConfMeetingList =[]
        let particularsQuitclaimList        =[]
        let particularsMonetaryClaimList    =[]
        let positionPaperList               =[]
        let replyList                       =[]
        let rejoinderList                   =[]
        let decisionList                    =[]
        let counselList                     =[]
        let latestUpdateList                =[]
        let statusDateList                  =[]
        let remarksList                     =[]
        let updateStatusList                =[]
        let attachedDocList                 =[]
        let reportedByEmployeeList          =[]
        let reportedByPositionList          =[]
        let reportedByClientList            =[]

        let isDraftList                     =[]
        let isDeletedList                   =[]
        let createdbyList                   =[]
        let createddateList                 =[]
        let modifiedbyList                  =[]
        let modifieddateList                =[]
        let isModifiedList                  =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
    
            let s1 = data[i].split("':")
            
            let idClean                         = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                        = idClean.split("',")[0]
            let selectedClientNameTmp           = s1[2].split("',")[0]
            let selectedEmployeeNameTmp         = s1[3].split("',")[0]
            let selectedPositionTmp             = s1[4].split("',")[0]
            let selectedEmployeeNoTmp           = s1[5].split("',")[0]
            let selectedEmployeeBranchTmp       = s1[6].split("',")[0]
            let groundOfSeperationTmp           = s1[7].split("',")[0]
            let caseNumeberTmp                  = s1[8].split("',")[0]
            let dateFiledTmp                    = s1[9].split("',")[0]
            let dateReceivedTmp                 = s1[10].split("',")[0]
            let complaintTmp                    = s1[11].split("',")[0]
            let scheduleOfHearingTmp            = s1[12].split("',")[0]
            let venueTmp                        = s1[13].split("',")[0]
            let hearingOfficerTmp               = s1[14].split("',")[0]
            let caseNoNLRCTmp                   = s1[15].split("',")[0]
            let mandatoryDateFiledTmp           = s1[16].split("',")[0]
            let mandatoryDateReceivedTmp        = s1[17].split("',")[0]
            let mandatoryComplaintTmp           = s1[18].split("',")[0]
            let mandatoryScheduleOfHearingTmp   = s1[19].split("',")[0]
            let mandatoryVenueTmp               = s1[20].split("',")[0]
            let mandatoryLaborArbiterTmp        = s1[21].split("',")[0]
            let particularsMomStConfMeetingTmp  = s1[22].split("',")[0]
            let particularsMomNdConfMeetingTmp  = s1[23].split("',")[0]
            let particularsQuitclaimTmp         = s1[24].split("',")[0]
            let particularsMonetaryClaimTmp     = s1[25].split("',")[0]
            let positionPaperTmp                = s1[26].split("',")[0]
            let replyTmp                        = s1[27].split("',")[0]
            let rejoinderTmp                    = s1[28].split("',")[0]
            let decisionTmp                     = s1[29].split("',")[0]
            let counselTmp                      = s1[30].split("',")[0]
            let latestUpdateTmp                 = s1[31].split("',")[0]
            let statusDateTmp                   = s1[32].split("',")[0]
            let remarksTmp                      = s1[33].split("',")[0]
            let updateStatusTmp                 = s1[34].split("',")[0]
            let attachedDocTmp                  = s1[35].split("',")[0]
            let reportedByEmployeeTmp           = s1[36].split("',")[0]
            let reportedByPositionTmp           = s1[37].split("',")[0]
            let reportedByClientTmp             = s1[38].split("',")[0]

            let isDraftListTmp                  = s1[39].split("',")[0]
            let isDeletedListTmp                = s1[40].split("',")[0]
            let createdbyListTmp                = s1[41].split("',")[0]
            let createddateListTmp              = s1[42].split("',")[0]
            let modifiedbyListTmp               = s1[43].split("',")[0]
            let modifieddateListTmp             = s1[44].split("',")[0]
            let isModifiedListTmp               = s1[45].split("',")[0]

        
            idList.push(IdClean2.replace("'",""))
            selectedClientNameList.push(selectedClientNameTmp.replace("'",""))
            selectedEmployeeNameList.push(selectedEmployeeNameTmp.replace("'",""))
            selectedPositionList.push(selectedPositionTmp.replace("'",""))
            selectedEmployeeNoList.push(selectedEmployeeNoTmp.replace("'",""))
            selectedEmployeeBranchList.push(selectedEmployeeBranchTmp.replace("'",""))
            groundOfSeperationList.push(groundOfSeperationTmp.replace("'",""))
            caseNumeberList.push(caseNumeberTmp.replace("'",""))
            dateFiledList.push(dateFiledTmp.replace("'",""))
            dateReceivedList.push(dateReceivedTmp.replace("'",""))
            complaintList.push(complaintTmp.replace("'",""))
            scheduleOfHearingList.push(scheduleOfHearingTmp.replace("'",""))
            venueList.push(venueTmp.replace("'",""))
            hearingOfficerList.push(hearingOfficerTmp.replace("'",""))
            caseNoNLRCList.push(caseNoNLRCTmp.replace("'",""))
            mandatoryDateFiledList.push(mandatoryDateFiledTmp.replace("'",""))
            mandatoryDateReceivedList.push(mandatoryDateReceivedTmp.replace("'",""))
            mandatoryComplaintList.push(mandatoryComplaintTmp.replace("'",""))
            mandatoryScheduleOfHearingList.push(mandatoryScheduleOfHearingTmp.replace("'",""))
            mandatoryVenueList.push(mandatoryVenueTmp.replace("'",""))
            mandatoryLaborArbiterList.push(mandatoryLaborArbiterTmp.replace("'",""))
            particularsMomStConfMeetingList.push(particularsMomStConfMeetingTmp.replace("'",""))
            particularsMomNdConfMeetingList.push(particularsMomNdConfMeetingTmp.replace("'",""))
            particularsQuitclaimList.push(particularsQuitclaimTmp.replace("'",""))
            particularsMonetaryClaimList.push(particularsMonetaryClaimTmp.replace("'",""))
            positionPaperList.push(positionPaperTmp.replace("'",""))
            replyList.push(replyTmp.replace("'",""))
            rejoinderList.push(rejoinderTmp.replace("'",""))
            decisionList.push(decisionTmp.replace("'",""))
            counselList.push(counselTmp.replace("'",""))
            latestUpdateList.push(latestUpdateTmp.replace("'",""))
            statusDateList.push(statusDateTmp.replace("'",""))
            remarksList.push(remarksTmp.replace("'",""))
            updateStatusList.push(updateStatusTmp.replace("'","")) 
            attachedDocList.push(attachedDocTmp.replace("'",""))
            reportedByEmployeeList.push(reportedByEmployeeTmp.replace("'",""))
            reportedByPositionList.push(reportedByPositionTmp.replace("'",""))
            reportedByClientList.push(reportedByClientTmp.replace("'",""))

            isDraftList.push(isDraftListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                            :   idList[i],
                'selectedClientName'            :   selectedClientNameList[i],
                'selectedEmployeeName'          :   selectedEmployeeNameList[i],
                'selectedPosition'              :   selectedPositionList[i],
                'selectedEmployeeNo'            :   selectedEmployeeNoList[i],
                'selectedEmployeeBranch'        :   selectedEmployeeBranchList[i],
                'groundOfSeperation'            :   groundOfSeperationList[i],
                'caseNumeber'                   :   caseNumeberList[i],
                'dateFiled'                     :   dateFiledList[i],
                'dateReceived'                  :   dateReceivedList[i],
                'complaint'                     :   complaintList[i],
                'scheduleOfHearing'             :   scheduleOfHearingList[i],
                'venue'                         :   venueList[i],
                'hearingOfficer'                :   hearingOfficerList[i],
                'caseNoNLRC'                    :   caseNoNLRCList[i],
                'mandatoryDateFiled'            :   mandatoryDateFiledList[i],
                'mandatoryDateReceived'         :   mandatoryDateReceivedList[i],
                'mandatoryComplaint'            :   mandatoryComplaintList[i],
                'mandatoryScheduleOfHearing'    :   mandatoryScheduleOfHearingList[i],
                'mandatoryVenue'                :   mandatoryVenueList[i],
                'mandatoryLaborArbiter'         :   mandatoryLaborArbiterList[i],
                'particularsMomStConfMeeting'   :   particularsMomStConfMeetingList[i],
                'particularsMomNdConfMeeting'   :   particularsMomNdConfMeetingList[i],
                'particularsQuitclaim'          :   particularsQuitclaimList[i],
                'particularsMonetaryClaim'      :   particularsMonetaryClaimList[i],
                'positionPaper'                 :   positionPaperList[i],
                'reply'                         :   replyList[i],
                'rejoinder'                     :   rejoinderList[i],
                'decision'                      :   decisionList[i],
                'counsel'                       :   counselList[i],
                'latestUpdate'                  :   latestUpdateList[i],
                'statusDate'                    :   statusDateList[i],
                'remarks'                       :   remarksList[i],
                'updateStatus'                  :   updateStatusList[i],
                'attachedDoc'                   :   attachedDocList[i],
                'reportedByEmployee'            :   reportedByEmployeeList[i],
                'reportedByPosition'            :   reportedByPositionList[i],
                'reportedByClient'              :   reportedByClientList[i],

                'isDraft'                       :   isDraftList[i],
                'isDeleted'                     :   isDeletedList[i],
                'createdby'                     :   createdbyList[i],
                'createddate'                   :   createddateList[i],
                'modifiedby'                    :   modifiedbyList[i],
                'modifieddate'                  :   modifieddateList[i],
                'isModified'                    :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
        
        return itemList
    }

    render() {
        const { ExportCSVButton } = CSVExport;
        
        const statusColumn = [
            {
                dataField   : 'items',
                text        : 'Items',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'countTotal',
                text        : 'Total',
                headerStyle : () => {
                    return { width  : "19%" };
                }
            },
            {
                dataField   : 'countClosed',
                text        : 'Resolve / Closed',
                headerStyle : () => {
                    return { width  : "19%" };
                }
            },
           {
                dataField: 'countPending',
                text: 'Pending',
                headerStyle : () => {
                    return { width  : "18%" };
                }
            },
            {
                dataField   : 'countForAppeal',
                text        : 'For Appeal',
                headerStyle : () => {
                    return { width  : "18%" };
                }
            },
            {
                dataField   : 'countForResolution',
                text        : 'For Resolution',
                headerStyle : () => {
                    return { width  : "22.5%" };
                }
            },
              /*
            {
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.statusTableList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>Cases Monitoring Summary </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    
                                    <ToolkitProvider
                                    rowEvents={ rowEvents }
                                    selectRow = { selectRow }
                                    keyField="id"   
                                    data={ this.state.statusTableList }
                                    columns={ statusColumn }
                                    exportCSV={ {
                                        fileName: "Cases Monitoring Summary.csv",
                                    } }
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <hr />
                                                    <BootstrapTable { ...props.baseProps } />
                                                    <ButtonToolbar>
                                                        <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export Report</ExportCSVButton>&nbsp;&nbsp;
                                                        <NavLink to="/home">
                                                            <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                                Close
                                                            </Button>
                                                        </NavLink>
                                                    </ButtonToolbar>
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

            </div> 
        )
    }

}

export  default CasesMonitoringStatus
