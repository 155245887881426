import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Tabs, Tab
} 
from '../../noser-hris-component';
import DraftEmployee from './DraftEmployee';

class ManageDraftEmployees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            openModal       :   false,
            uploadfile      :   "",
            disabledUpload  :   true,
            
            clientId        :   "",
            clientName      :   "",
            locationId      :   "",
            employeeId      :   "",
            statusId        :   "",
            clientDDL       :   [],
            locationDDL     :   [],
            employeeDDL     :   [],
            statusDDL       :   [
                {"id":"","name":"ALL"},
                //{"id":"1","name":"PENDING FOR APPROVAL (AM)"},
                {"id":"2","name":"PENDING FOR APPROVAL (ADMIN)"},
                //{"id":"3","name":"REJECTED BY AM"},
                {"id":"4","name":"APPROVED"},
                {"id":"5","name":"REJECTED BY ADMIN"}
            ],
            employeeGRID    :   []
        }
    } 
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        //this.GetClients()
        this.state.statusId = this.state.userinfo.roleId === "1" ||  
                                this.state.userinfo.roleId === "8" ? "4" : "2"
        this.GetDraftEmployees()
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL : data.clients ? data.clients : [], isloading:false})
            if(data.clients.length===0){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }    
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetLocations = async() =>{
        this.setState({isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.clientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", params)
        .then(res => {
            const data = res.data;
            this.setState({locationDDL : data.locations ? data.locations : [], isloading:false})
            if(data.locations.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    handleChangeClient = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.clientId= ''
            this.state.clientName=''
            return
        }
        this.state.clientId= e[0].id
        this.state.clientName=e[0].name
        this.GetLocations()
    }
    handleChangeLocation = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.locationId= ''
            return
        }
        this.state.locationId= e[0].id
    }
    handleChangeStatus = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.statusId= ''
            return
        }
        this.state.statusId= e[0].id
        //this.GetEmployees()
    }
    handleChangeEmployee = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.employeeId= ''
            return
        }
        this.state.employeeId= e[0].id
        this.GetEmployees()
    }
    handleDownloadReferenceDataClick = async() => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:true})

        const params = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/ExportReferenceData", params,{'responseType': 'blob'})
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "ReferenceData.xlsx");
            document.body.appendChild(link);
            link.click();
            this.setState({isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleDownloadDraftTemplateClick = async() => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:true})

        const params = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/ExportDraftTemplate", params,{'responseType': 'blob'})
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "DraftEmployees.xlsx");
            document.body.appendChild(link);
            link.click();
            this.setState({isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleBatchUpload = async() => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:true})
        const formData = new FormData();
        formData.append('IpAddress', "0.0.0.0");
        formData.append('ClientId', this.state.userinfo.clientId);
        formData.append('UserId', this.state.userinfo.userId);
        formData.append('RoleId', this.state.userinfo.roleId);
        formData.append('EmployeeId', this.state.userinfo.employeeId);
        formData.append('File', this.state.uploadfile);
        
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Manning/ImportDraftEmployees",  formData, {headers: {'Content-Type': 'multipart/form-data'}}
            )
            .then(res => {
                const data = res.data;
                if(data.status=="1")
                {
                    this.GetDraftEmployees()
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true
                    });
                }
                else{
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }
    SetFile = (e) => {
        if(e.target.value.length>0)
            this.setState({ uploadfile: e.target.files[0],disabledUpload:false});
        else
            this.setState({ uploadfile: "",disabledUpload:true });
    }
    GetDraftEmployees = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "SubmittedBy":this.state.userinfo.employeeId,
            "CompanyId":this.state.userinfo.companyId,
            "LocationId":"",
            "EmployeeId":this.state.employeeId,
            "StatusId":this.state.statusId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/GetDraftEmployees", params)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({employeeGRID : data.draftEmployees ? data.draftEmployees : [], isloading:false})
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }  
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        
    }
    handleSearchClick = () =>{
        this.GetDraftEmployees()
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(e)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.handleSearchClick()
    }
    handleGenerateCertificate = async() => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:true})

        const params = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/ExportDraftTemplate", params,{'responseType': 'blob'})
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "Training_Certificate.pdf");
            document.body.appendChild(link);
            link.click();
            this.setState({isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    render(){
        let roleId = this.state.userinfo.roleId
        let employeeCol = []
        let isAdmin = this.state.userinfo.roleId==="1" || 
                        this.state.userinfo.roleId==="8" ||
                        this.state.userinfo.roleId==="11" ||
                        this.state.userinfo.roleId==="19" ||
                        this.state.userinfo.roleId==="28" ||
                        this.state.userinfo.roleId==="31"
        if(isAdmin){
            employeeCol = [
                {
                    dataField: 'submittedDate',
                    text: 'SUBMITTED DATE',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'},
                    hidden: roleId === "1" || roleId==="8" ? true : false
                },
               /*  {
                    dataField: 'clientName',
                    text: 'CLIENT',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'left',whiteSpace:'nowrap'}},
                    style:{textAlign:'left',whiteSpace:'nowrap'}
                }, */
                /* {
                    dataField: 'locationName',
                    text: 'BRANCH',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'left',whiteSpace:'nowrap'}},
                    style:{textAlign:'left',whiteSpace:'nowrap'}
                }, */
                {
                    dataField: 'positionName',
                    text: 'POSITION',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'firstName',
                    text: 'FIRST NAME',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'left',whiteSpace:'nowrap'}},
                    style:{textAlign:'left',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'middleName',
                    text: 'MIDDLE NAME',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'left',whiteSpace:'nowrap'}},
                    style:{textAlign:'left',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'lastName',
                    text: 'LAST NAME',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'left',whiteSpace:'nowrap'}},
                    style:{textAlign:'left',whiteSpace:'nowrap'}
                },
                // {
                //     dataField: 'suffixName',
                //     text: 'SUFFIX',
                //     editable: false,
                //     headerStyle: (colum, colIndex) => {
                //         return { textAlign: 'left',whiteSpace:'nowrap'}},
                //     style:{textAlign:'left',whiteSpace:'nowrap'}
                // },
                {
                    dataField: 'dateOfBirth',
                    text: 'DATE OF BIRTH',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'dateHired',
                    text: 'DATE HIRED',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'duration',
                    text: 'DURATION',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'mobileNumber',
                    text: 'CONTACT NO',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'salaryOffered',
                    text: 'SALARY RATE',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'right',whiteSpace:'nowrap'}},
                    style:{textAlign:'right',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'payType',
                    text: 'PAYROLL TYPE',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'payMode',
                    text: 'PAYROLL MODE',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'employmentStatus',
                    text: 'EMPLOYMENT STATUS',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'left',whiteSpace:'nowrap'}},
                    style:{textAlign:'left',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'payCardType',
                    text: 'PAY CARD TYPE',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'payCardNumber',
                    text: 'PAY CARD NUMBER',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'tinNumber',
                    text: 'TIN NUMBER',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'sssNumber',
                    text: 'SSS NUMBER',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'phicNumber',
                    text: 'PHIC NUMBER',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'hdmfNumber',
                    text: 'HDMF NUMBER',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'eColaRate',
                    text: 'ECOLA RATE',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'colaRate',
                    text: 'COLA RATE',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'seaRate',
                    text: 'SEA RATE',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
            ]
        }
        else{
            employeeCol = [
                {
                    dataField: 'status',
                    text: 'STATUS',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'},
                    hidden: roleId === "1" || roleId==="8" ? true : false
                },
                {
                    dataField: 'submittedByName',
                    text: 'SUBMITTED BY',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'},
                    hidden: roleId === "1" || roleId==="8" ? true : false
                },
                {
                    dataField: 'submittedDate',
                    text: 'SUBMITTED DATE',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'},
                    hidden: roleId === "1" || roleId==="8" ? true : false
                },
                {
                    dataField: 'approvedByName',
                    text: 'APPROVED / REJECTED BY',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'},
                    hidden: roleId === "1" || roleId==="8" ? true : false
                },
                {
                    dataField: 'approvedDate',
                    text: 'APPR / REJ DATE',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'},
                    hidden: roleId === "1" || roleId==="8" ? true : false
                },
                // {
                //     dataField: 'clientName',
                //     text: 'CLIENT',
                //     editable: false,
                //     headerStyle: (colum, colIndex) => {
                //         return { textAlign: 'left',whiteSpace:'nowrap'}},
                //     style:{textAlign:'left',whiteSpace:'nowrap'}
                // },
                {
                    dataField: 'locationName',
                    text: 'BRANCH',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'left',whiteSpace:'nowrap'}},
                    style:{textAlign:'left',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'positionName',
                    text: 'POSITION',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'firstName',
                    text: 'FIRST NAME',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'left',whiteSpace:'nowrap'}},
                    style:{textAlign:'left',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'middleName',
                    text: 'MIDDLE NAME',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'left',whiteSpace:'nowrap'}},
                    style:{textAlign:'left',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'lastName',
                    text: 'LAST NAME',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'left',whiteSpace:'nowrap'}},
                    style:{textAlign:'left',whiteSpace:'nowrap'}
                },
                // {
                //     dataField: 'suffixName',
                //     text: 'SUFFIX',
                //     editable: false,
                //     headerStyle: (colum, colIndex) => {
                //         return { textAlign: 'left',whiteSpace:'nowrap'}},
                //     style:{textAlign:'left',whiteSpace:'nowrap'}
                // },
                {
                    dataField: 'dateOfBirth',
                    text: 'DATE OF BIRTH',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'dateHired',
                    text: 'DATE HIRED',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'duration',
                    text: 'DURATION',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'mobileNumber',
                    text: 'CONTACT NO',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'salaryOffered',
                    text: 'SALARY RATE',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'right',whiteSpace:'nowrap'}},
                    style:{textAlign:'right',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'payType',
                    text: 'PAYROLL TYPE',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'payMode',
                    text: 'PAYROLL MODE',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'employmentStatus',
                    text: 'EMPLOYMENT STATUS',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'left',whiteSpace:'nowrap'}},
                    style:{textAlign:'left',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'payCardType',
                    text: 'PAY CARD TYPE',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'payCardNumber',
                    text: 'PAY CARD NUMBER',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'tinNumber',
                    text: 'TIN NUMBER',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'sssNumber',
                    text: 'SSS NUMBER',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'phicNumber',
                    text: 'PHIC NUMBER',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'hdmfNumber',
                    text: 'HDMF NUMBER',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'eColaRate',
                    text: 'ECOLA RATE',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'colaRate',
                    text: 'COLA RATE',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
                {
                    dataField: 'seaRate',
                    text: 'SEA RATE',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',whiteSpace:'nowrap'}},
                    style:{textAlign:'center',whiteSpace:'nowrap'}
                },
            ]
        }
        
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        
        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>Manning >> Manage Draft Employees</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientDDL}
                                            placeholder="Select Client"

                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeLocation}
                                            options={this.state.locationDDL}
                                            placeholder="Select Location"
                                        />
                                    </Col>
                                </Form.Group> */}
                                {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.handleChangeEmployee}
                                            options={this.state.employeeDDL}
                                            placeholder="Select Employee"
                                        />
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeStatus}
                                            options={this.state.statusDDL}
                                            placeholder="Select Status"

                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar className="mt-1">
                                    <Button variant="primary" className="ml-auto noser-button-mr1" onClick={ this.handleSearchClick }>Search</Button>
                                    <Button variant="success" className="noser-button" onClick={ this.handleModalShow }>CREATE</Button>
                                </ButtonToolbar>
                                <hr/>
                                <ButtonToolbar className="mt-1">
                                    <Form.Group as={Row}>
                                        <Col sm="4">
                                        <Form.Control type="file" accept='.xlsx' onChange={this.SetFile} style={{border:"1pt solid #ccc"}}/>
                                        </Col>
                                        <Col sm="8">
                                        <Button disabled={this.state.disabledUpload} variant="info" className="noser-button-mr1" onClick={ this.handleBatchUpload }>BATCH UPLOAD</Button>
                                        <Button variant="info" className="noser-button-mr1" onClick={ this.handleDownloadDraftTemplateClick }>DOWNLOAD TEMPLATE</Button>
                                        {/* <Button variant="info" className="noser-button-mr1" onClick={ this.handleGenerateCertificate }>Generate Cert</Button> */}
                                        <Button variant="info" className="noser-button" onClick={ this.handleDownloadReferenceDataClick }>DOWNLOAD REFERENCE DATA</Button>
                                        </Col>
                                    </Form.Group>
                                </ButtonToolbar>
                                {/* <Form.Group as={Col} sm={12} controlId="formHorizontalEmail">
                                
                                </Form.Group> */}
                                <div className="mt-2">
                                <Card.Header>DRAFT EMPLOYEE LIST</Card.Header>
                                    <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.employeeGRID }
                                            columns = { employeeCol }
                                            selectRow = { selectRow }
                                            cellEdit = { cellEditFactory({ 
                                                    mode: 'dbclick', 
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => { 
                                                        this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowClasses="noser-table-row-class no-checkbox"
                                            striped
                                            hover
                                            condensed
                                            loading={true}
                                            wrapperClasses="table-responsive"
                                            rowClasses="noser-table-row-class no-checkbox"
                                            pagination={ paginationFactory({sizePerPageRenderer})}
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        />
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
                <DraftEmployee 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div>
        )
    }
}

export default ManageDraftEmployees;