import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer
}
from '../../noser-hris-component';
import DataGrid, {Editing, LoadPanel, Sorting, Summary, TotalItem, Export, Paging, Selection } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';

export default class NoserDataGrid extends React.Component {
    constructor(props) {
        super(props);
    }
    customizeColumns = (cols) => {
        let colHide = [
            "id", "inclusions", "premstart", "premend","incstart","incend",
            
        ]

        let premCols = []
        let dedCols = []
        let incCols = []
        let pcCols = []
        let empInfo = []
        let basic = []
        let aut = []
        let netregpay = {}
        let grossPay = {}
        let totalPremiums = {}
        let totalInclusions = {}
        let totalGrossPay = {}
        let totalDeductions = {}
        let netPay = {}

        let premstart = 0
        let premend = 0
        let incstart = 0
        let incend = 0

        let hidePrem = true
        let hideTotalPrem = true
        let hideinc = true
        let hideTotalInc = true
        let hideDed = true
        let hideTotalDed = true
        
        console.log("this.props.premiums")
        console.log(this.props.premiums)
        cols.forEach((fld,idx) => {
            //console.log(fld.dataField + " " + idx)
            if(fld.dataField==="premstart"){
                premstart = idx
                delete cols[idx];
            }
            if(fld.dataField==="premend"){
                premend = idx
                delete cols[idx];
            }
            if(fld.dataField==="incstart"){
                incstart = idx
                delete cols[idx];
            }
            if(fld.dataField==="incend"){
                incend = idx
                delete cols[idx];
            }
        })
        
        cols.forEach((fld,idx) => {
            
            fld.caption = fld.caption !== undefined ? fld.caption.toUpperCase() : fld.caption
            
            if(idx>=2 && idx<=9){
                delete cols[idx];
                empInfo.push(fld.dataField)
            }
            if(idx>=10 && idx<=18){
                delete cols[idx];
                basic.push(fld.dataField)
            }
            if(idx>=19 && idx<=24){
                delete cols[idx];
                aut.push(fld.dataField)
            }
            // if(idx>=premstart && idx <= premend){
            //     delete cols[idx];
            //     premCols.push(fld.dataField)
            // }
            // if(idx>=incstart && idx <= incend){
            //     delete cols[idx];
            //     incCols.push(fld.dataField)
            // }

            if(fld.dataField==="netRegularPay"){
                delete cols[idx];
                netregpay = fld.dataField
            }
            if(fld.dataField==="grossPay"){
                delete cols[idx];
                grossPay = fld.dataField
            }
            if(fld.dataField==="totalPremiums"){
                delete cols[idx];
                totalPremiums = fld.dataField
                //hideTotalPrem = fld.dataField === undefined ? true : false
            }
            if(fld.dataField==="totalInclusions"){
                delete cols[idx];
                totalInclusions = fld.dataField
            }
            if(fld.dataField==="totalGrossPay"){
                delete cols[idx];
                totalGrossPay = fld.dataField
            }
            if(fld.dataField==="totalDeductions"){
                delete cols[idx];
                totalDeductions = fld.dataField
            }
            if(fld.dataField==="netPay"){
                delete cols[idx];
                // fld.summaryItems =[{
                //     column:"netPay",
                //     summaryType:"sum"
                // }]
                netPay = fld.dataField
            }

            // if(fld.dataField==="No of Hrs"){
            //     delete cols[idx];
            //     premCols.push(fld.dataField)
            // }
            if(this.props.premiums.filter(x=>x.code === fld.dataField).length>0){
                delete cols[idx];
                premCols.push(fld.dataField)
            }
            if(this.props.inclusions.filter(x=>x.name === fld.dataField).length>0){
                delete cols[idx];
                incCols.push(fld.dataField)
            }
            if(this.props.deductions.filter(x=>x.name === fld.dataField).length>0){
                delete cols[idx];
                dedCols.push(fld.dataField)
            }
            if(this.props.paycards.filter(x=>x.name === fld.dataField).length>0){
                delete cols[idx];
                pcCols.push(fld.dataField)
            }

            if(colHide.includes(fld.dataField)){
                fld.visible = false
            }
        })
        cols.push({
            caption : "EMPLOYEE INFORMATION",
            columns : empInfo
        })
        cols.push({
            caption : "BASIC PAY",
            columns : basic
        })
        cols.push({
            caption : "LATES / UNDERTIME",
            columns : aut
        })
        cols[50] = netregpay
        
        if(premCols.length>0){
            cols.push({
                caption : "PREMIUMS",
                columns : premCols
            })
            cols[52] = totalPremiums
        }
        
        if(incCols.length>0){
            cols.push({
                caption : "INCLUSIONS",
                columns : incCols
            })
            cols[54] = totalInclusions
        }
        
        cols[55] = totalGrossPay
        
        if(dedCols.length>0){
            cols.push({
                caption : "DEDUCTIONS",
                columns : dedCols
            })
            cols[57] = totalDeductions
        }
        
        cols[58] = netPay
        cols.push({
            caption : "PAY CARDS",
            columns : pcCols
        })
    }
    cellPrepared = (e) => {
        //console.log(e)
        e.cellElement.style.cssText = "text-transform: lowercase;"
        if(e.rowType==="header"){
            e.cellElement.align = "center"
            e.cellElement.textContent = e.cellElement.textContent !==undefined ? e.cellElement.textContent.toUpperCase() : e.cellElement.textContent
        }
        
        if(e.rowType==="data"){
            if(e.column.dataField ==="position" || e.column.dataField ==="employeeName"){
                e.cellElement.style.cssText = "text-align: left"
            }
            else if(e.column.dataField ==="hc" || e.column.dataField ==="employeeNo" || e.column.dataField ==="payCardNumber"){
                e.cellElement.style.cssText = "text-align: center;width:200px"
            }
            else{
                e.cellElement.style.cssText = "text-align: right"
            }
            
            // if(e.column.dataField ==="basicPayDays"){
            //     e.summaryItems =[{
            //         column:"basicPayDays",
            //         summaryType:"sum"
            //     }]
            // }

        }

        // if(e.rowType==="totalFooter"){
        //     if(e.column.dataField!=="hc" && 
        //         e.column.dataField!=="position" &&
        //         e.column.dataField!=="employeeNo" &&
        //         e.column.dataField!=="employeeName" &&
        //         e.column.dataField!=="payCardNumber" &&
        //         e.column.dataField!=="salaryOffered" &&
        //         e.column.dataField!=="colaRate" &&
        //         e.column.dataField!=="seaRate" &&
        //         e.column.dataField!=="eColaRate"){
        //         e.summaryItems =[{
        //             column:e.column.dataField,
        //             summaryType:"sum",
        //             alignment:"right",
        //             displayFormat: "{0}"
        //         }]
                
        //         e.totalItem.summaryCells[e.columnIndex] = [{
        //             column:e.column.dataField,
        //             summaryType:"sum",
        //             alignment:"right",
        //             displayFormat: "{0}"
        //         }]
        //         //console.log("dsdsdsd")
        //         //console.log(this.state.payrollGrID.filter(object => e.column.dataField in object))
        //         e.cellElement.innerText = parseFloat(this.props.data.reduce(( total, currentValue) => total = parseFloat(total) + parseFloat(currentValue[e.column.dataField]),0)).toFixed(2);
        //         e.cellElement.textContent = parseFloat(this.props.data.reduce(( total, currentValue) => total = parseFloat(total) + parseFloat(currentValue[e.column.dataField]),0)).toFixed(2);
        //     }

        //     //e.cellElement.style.backgroundColor = 'red'
        //     e.cellElement.style.alignContent = 'right'
        //     e.cellElement.style.cssText = "text-align: right"
        //     e.cellElement.align = "right"

        // }
    }
    render(){
        console.log("this.props.data")
        console.log(this.props.data)
        return (
            <DataGrid
                id="grid"
                //ref={this.dataGridRef}
                customizeColumns={this.customizeColumns}
                onCellPrepared={this.cellPrepared}
                dataSource={this.props.data}
                keyExpr="id"
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
                rowAlternationEnabled={true}
                noDataText = "No record found."
                //height={340}
            >
                {/* <LoadPanel enabled={true} />    */}
                {/* <Export enabled={true} /> */}
                {/* <Selection mode="multiple" /> */}
                <Sorting mode="none" />
                <Paging defaultPageSize={15} defaultPageIndex={0} enabled={false}/>
                {/* <Summary calculateCustomSummary={this.calculateSelectedRow}>
                    <TotalItem
                        column="netPay"
                        summaryType="sum"
                        showInColumn=""
                        displayFormat="{0}"
                        alignment="right"
                    />
                </Summary> */}
            </DataGrid>
        )
    }
}