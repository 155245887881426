import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import DeductionCreate from './DeductionCreate';

class DeductionType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            roleDDL         :   [],
            roleList        :   [],
            roleName        :   '',
            departmentDDL   :   [],
            disableSaveBtn  :   true     ,

            selected: [],
            selectedDeductionType: "",
            selectedDeductionTypeId: "",
            deductionTypeList: [],
            deductionList: [],
            deductionListGrid: [], 
            newDeductionList: [],
          
          
            Show: false,
          
          
            isGridDataChanged: false
        }
    } ;

    componentDidMount(){
       

        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetDeductionType()
        sleep(1000).then(() => {
        this.setState({isLoading:false})})
    };

    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    };

    handleModalClose = (e) =>{
        this.setState({openModal:false})
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.handleSearchClick();
        sleep(1000).then(() => {
        this.setState({isLoading:false,
            alerttype   :   "",
            isshow      :   false,
            color       :   "success",
            message     :   "",
            fade        :   false
        })})
        
       
    };

    
    handleCoverChangeDeduction = (e) => {
        if (e.length > 0) {
            this.state.selectedDeduction = e[0].name
        }else {
            this.state.selectedDeduction = ""
            
        }
    };

    handleCoverChangeDeductionType = (e) => {
        if (e.length > 0) {
            this.state.selectedDeductionType = e[0].name
            this.state.selectedDeductionTypeId = e[0].id
        }
        else {
            this.state.selectedDeductionType = "" 
            this.state.selectedDeductionTypeId = ""
        }
        this.GetDeduction()
    }

    GetDeductionType(){
        const typeParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "Name":this.state.selectedDeduction
        };
        axios
        .post(
            AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDeductionTypes", typeParams
        )
        .then(res => {
            const data = res.data;
            console.log("deductionTypeList");
            console.log(data);
            this.setState({deductionTypeList: data.deductionTypes});
        })
    };

    GetDeduction() {
        const deductionParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "TypeId":this.state.selectedDeductionTypeId, 
            "Name":""
        };
        axios
        .post(
            AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDeductions", deductionParams
        )
        .then(res => {
            const data = res.data;
            
            console.log(data);
            this.setState({deductionList: data.deductions});
        })
    }

    handleSearchClick = event => {
        this.setState({
            deductionListGrid: [],
            newDeductionList: []
        });

        const deductionParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "TypeId":this.state.selectedDeductionTypeId,
            "Name":this.state.selectedDeduction
        }
        console.log("deductionParams")
        console.log(deductionParams)
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDeductions", deductionParams
            )
            .then(res => {
                const data = res.data;
                console.log(res.data)
                this.setState({ deductionListGrid: data.deductions });
                var alertType = (data.status=="1") ? "success" : "danger"
                this.setState(
                    {
                        isLoading:false,
                        AlertType:"Success!",
                        show:true,
                        Color:alertType,
                        Message:data.message ,
                        Fade:true
                    });
             })
             .catch(error=>{
                this.setState(
                {
                    isLoading:false,
                    AlertType:"Error! ",
                    Show:true,
                    Color:"danger",
                    Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    Fade:true
                })
            })

    }

    handleSaveClick = event => {
            this.setState({newDeductionList: [],isLoading:true})
            for (let i = 0; i < this.state.deductionListGrid.length; i++) {
                if (this.state.deductionListGrid[i]["isModified"] == 1) {
                    const obj = {
                        Id: this.state.deductionListGrid[i]["id"],
                        TypeId: this.state.deductionListGrid[i]["typeId"],
                        Name: this.state.deductionListGrid[i]["name"],
                        IsDeleted: this.state.deductionListGrid[i]["isDeleted"].toString()
                    };

                    this.state.newDeductionList.push(obj);
                }
            }

        const deductionParams = {

            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "Deductions":this.state.newDeductionList
        };
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditDeduction", deductionParams
            )
            .then(res => {
                console.log(deductionParams)
                const data = res.data;
                console.log(res.data)
                this.setState({isLoading:false})
                var alertType = (data.status=="1") ? "success" : "danger"
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
                this.handleSearchClick()
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }

    LeavePageWithourSavingChanges() {
        const isChanged=false
        for (let i = 0; i < this.state.deductionListGrid.length; i++) {
            if (this.state.deductionListGrid[i]["isModified"] == 1) {
                this.setState({isGridDataChanged: true})
                isChanged=true
                break;
            }
        }
        return isChanged
    };

    GridDataModified(oldValue, newValue,deductionid, column) {
        this.state.deductionListGrid.map(function(item,i){
            if(item.id===deductionid)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    render(){
        const columndeduction = [
            {
                dataField: 'typeName',
                text: 'Deduction Type',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'50%' }},
                style:{textAlign:'left',width:'50%'},
                editable: false
            },
            {
                dataField: 'name',
                text: 'Deduction ',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'50%' }},
                style:{textAlign:'left',width:'50%'},
            }
           
        ];

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.deductionListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
             }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              //alert(e.row);
            }
        };

        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header> Deduction</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Row>
                                    <Col sm={12} >
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleCoverChangeDeductionType}
                                            options={this.state.deductionTypeList}
                                            placeholder="Select Deduction Type"
                                        />
                                    </Col>&nbsp;&nbsp;
                                </Form.Row>
                                <Form.Row>
                                    <Col sm={12} >
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleCoverChangeDeduction}
                                            options={this.state.deductionList}
                                            placeholder="Select Deduction "
                                        />
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm={12} controlId="formHorizontalEmail">
                                        <div className="mt-1">
                                            
                                            <ButtonToolbar>
                                                <Button style={{minWidth:'60px'}} className='ml-auto' variant="primary"  onClick={ this.handleSearchClick } >
                                                    search
                                                </Button>&nbsp;&nbsp;
                                                <Button style={{minWidth:'60px'}} variant="success" onClick={this.handleModalShow}>
                                                    Create
                                                </Button>&nbsp;&nbsp;
                                               
                                            </ButtonToolbar>
                                        </div>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                        <Card.Header>Record</Card.Header>
                                        <div className="mt-1">
                                            <BootstrapTable
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                keyField = "id"
                                                data = { this.state.deductionListGrid}
                                                columns = { columndeduction}
                                                selectRow = { selectRow }
                                                cellEdit = { cellEditFactory({
                                                    mode: 'dbclick',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                this.GridDataModified(oldValue, newValue, row.id, column)
                                                            }
                                                        })
                                                    }
                                                    rowEvents={ rowEvents }
    
                                             /> 
                                    <ButtonToolbar sm={12}>
                                        <Button variant="success" className="ml-auto" onClick={this.handleSaveClick} style={{minWidth:'60px'}}>
                                                Save
                                            </Button>&nbsp;&nbsp;
                                        <NavLink to="/home">
                                            <Button variant="danger" href="/banner" style={{minWidth:'60px'}}>
                                                Close
                                            </Button>&nbsp;&nbsp;
                                        </NavLink>
                                    </ButtonToolbar>
                                </div>
                                        
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
                <DeductionCreate 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div>
        )
    }
}

export default DeductionType;