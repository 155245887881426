import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../noser-hris-component';

class FinalizeModal extends Component {
    constructor(props) {
        super(props)
        this.module = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            isSI        :   true,
            invoiceNo   :   ''
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            isSI        :   true,
            invoiceNo   :   ''
        })
        console.log("init")
    }
    handleSubmit = (e) =>{
        //this.setState({isloading:true})

        if(this.state.name==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter role name.",
                fade        :   true
            })
            return 
        }
        this.props.onHide({"isProceed" : "1","isSI" : this.state.isSI,"invoiceNo" : this.state.invoiceNo});
    }
    handleModalClose = () => {
        this.props.onHide({"isProceed" : "0","isSI" : "","invoiceNo" :""})            
    }
    handleChange = (e) =>{
        this.setState({[e.target.name]: e.target.value.toUpperCase()})
    }
    handleChangedRadio = (e) =>{
        this.setState({isSI:e.target.checked})
        if(e.target.name==="si")
        this.setState({isSI:true})
        else
        this.setState({isSI:false})
        
    }
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        INVOICE / STATEMENT
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Form.Group as={Col} sm={2} >
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="&nbsp; SERVICE INVOICE" 
                                    name="si"
                                    value={this.state.isSI}
                                    checked={this.state.isSI}
                                    onChange={this.handleChangedRadio}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={3} >
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="&nbsp; BILLING STATEMENT" 
                                    name="bs"
                                    value={!this.state.isSI}
                                    checked={!this.state.isSI}
                                    onChange={this.handleChangedRadio}
                                    
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='invoiceNo'
                                    type="text" 
                                    placeholder="Enter SI / BS No" 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.invoiceNo}
                                    
                                />
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleSubmit }>Submit</Button>
                        <Button variant="danger"  className="ml-auto noser-button-mr15" onClick={this.handleModalClose}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default FinalizeModal