import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import CreatePagIbigTable from './Modal/CreatePagIbigTable';
class PhilHealthTable extends Component {
    constructor() {
        super()
        this.state = {
            userinfo: [],
            isLoading: true,
            AlertType: "",
            Show: false,
            Message: "",
            Color: "",
            Fade: true,
            phicList:[],

            alerttype   :   "",
            isshow      :   false,
            color       :   "",
            message     :   "",
            fade        :   false,
            hdmfList:[],
            hdmfListnew:[],

        }
    }
    componentDidMount() {
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        console.log("this.state.userinfo")
        console.log(this.state.userinfo)
       this.GetHDMF()
    }

    GetHDMF() {
        const hdmfParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "isDeleted" : "0"
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetHDMFBrackets",  hdmfParams)
        .then(res => {
            console.log("Get HDMF Brackets")
             const data = res.data;
             console.log(res.data)
             this.setState({ hdmfList: data.brackets });
         })
    };

    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    };

    handleModalClose = (e) =>{
        this.setState({openModal:false})
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        //this.handleSearchClick();
        this.GetHDMF()
        sleep(1000).then(() => {
        this.setState({isLoading:false,
            alerttype   :   "",
            isshow      :   false,
            color       :   "success",
            message     :   "",
            fade        :   false
        })})
    };


    handleSaveClick = event => {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        
            this.setState({hdmfListnew: [],isLoading:true})
            this.state.hdmfList.filter(x=>x.isModified==="1") 
            /* for (let i = 0; i < this.state.hdmfList.length; i++) {
                if (this.state.hdmfList[i]["isModified"] == 1) {
                    const obj = {
                        Id: this.state.hdmfList[i]["id"],
                        //TypeId:this.state.phicList[i]["typeId"],
                        name: this.state.hdmfList[i]["name"],
                        eeShareLabel: this.state.hdmfList[i]["eeShareLabel"],
                        erShareLabel: this.state.hdmfList[i]["erShareLabel"],
                        //employeerShare: this.state.hdmfList[i]["employeerShare"],
                       // IsDeleted: this.state.phicList[i]["isDeleted"].toString()
                    };
    
                    this.state.hdmfListnew.push(obj);
                }
            } */
            const inclusionParams = {
                    "IpAddress":"0.0.0.0",
                    "CompanyId":this.state.userinfo.companyId,
                    "UserId":this.state.userinfo.userId,
                    "HDMFBrackets": this.state.hdmfList.filter(x=>x.isModified==="1") 
           };
            axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditHDMFBracket", inclusionParams
            )
            .then(res => {
                console.log(inclusionParams)
                const data = res.data;
                console.log(data)
                sleep(1000).then(() => {
                    this.setState({isLoading:false})})
                this.GetHDMF();
                    var alertType = (data.status=="1") ? "success" : "danger"
                    this.setState(
                        {
                            isloading:false,
                            alerttype:"Success!",
                            isshow:true,
                            color:alertType,
                            message:data.message ,
                            fade:true
                        });
                })
                .catch(error=>{
                    this.setState(
                    {
                        isloading:false,
                        alerttype:"Error! ",
                        isshow:true,
                        Color:"danger",
                        message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                        fade:true
                    })
                })
    };

    LeavePageWithourSavingChanges() {
        const isChanged=false
        for (let i = 0; i < this.state.hdmfList.length; i++) {
            if (this.state.hdmfList[i]["isModified"] == 1) {
                this.setState({isGridDataChanged: true})
                isChanged=true
                break;
            }
        }
        return isChanged
    }

    GridDataModified(oldValue, newValue, id, column) {
        console.log(id)
        this.state.hdmfList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }


    render() {
        const columns3 = [
            {
                dataField: 'name',
                text: 'Monthly Compensation',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'35%' }},
                style:{textAlign:'left',width:'35%'},
            },
            {
                dataField: 'eeShareLabel',
                editable: true,
                text: 'EMPLOYEE SHARE PERCENTAGE ',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'35%' }},
                style:{textAlign:'left',width:'35%'},
            },
            {
                dataField: 'erShareLabel',
                editable: true,
                text: 'EMPLOYEER SHARE PERCENTAGE ',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'35%' }},
                style:{textAlign:'left',width:'35%'},
            },
        ];

       
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                console.log("row")
                console.log(row)
                this.state.hdmfList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {}
        };
        

        return(
            <div >
                <Banner />
                <Container className="mt-5" >
                    <Card>
                        <Card.Header>PAGIBIG CONTRIBUTION TABLE</Card.Header>
                        <Card.Body>
                             <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                           {/*  <Form.Group as={Row} controlId="formHorizontalEmail" style={{marginLeft:'0px',}}>
                                <BootstrapTable
                                keyField = "month"
                                data = { this.state.phicList }
                                columns = { columns3 }
                                //selectRow = { selectRow }
                                cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                />
                            </Form.Group>&nbsp;&nbsp; */}
                            {/* <BootstrapTable width='175' data={ this.state.hdmfList } striped={true} hover={true} 
                                cellEdit={ cellEdit }>
                                <TableHeaderColumn  row='0' rowSpan='2' dataField='id' dataAlign="center"  isKey={true} width='360px' hidden>onthly Compensation</TableHeaderColumn>
                                <TableHeaderColumn row='0' rowSpan='2' dataField='name'  dataAlign='center'  width='360px'>Monthly Compensation</TableHeaderColumn>
                                <TableHeaderColumn row='0' colSpan='2' dataAlign='center' width='360px'>Percentage of Monthly Compensation</TableHeaderColumn>
                                <TableHeaderColumn row='1' dataField='eeShareLabel' width='360px' dataAlign='center'>Employee Share</TableHeaderColumn>
                                <TableHeaderColumn row='1' dataField='erShareLabel' width='360px' dataAlign='center'>Employer Share</TableHeaderColumn>
                            </BootstrapTable>&nbsp;&nbsp;
                            <ButtonToolbar sm={12}>
                               
                                <Button style={{minWidth:'60px'}} className='ml-auto' variant="primary"  onClick={this.handleModalShow} >
                                    create
                                </Button>&nbsp;&nbsp;
                                <Button variant="danger"  href="/home" style={{minWidth:'60px'}}>
                                    Close
                                </Button>

                            </ButtonToolbar> */}

                            <Form.Group as={Row}  
                                className="mt-2" controlId="formHorizontalEmail">
                                <Col sm={12} >
                                    <BootstrapTable
                                        rowClasses="noser-table-row-class"
                                        striped
                                        hover
                                        condensed
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        keyField = "id"
                                            data = { this.state.hdmfList}
                                            
                                            columns = { columns3 }
                                            selectRow = { selectRow }
                                        
                                            //selectRow = { selectRow }
                                            //cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                        

                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                                    }
                                                rowEvents={ rowEvents }
                                    /> 
                                </Col> 
                            </Form.Group>&nbsp;&nbsp;
                            <ButtonToolbar sm={12}>
                                <Button variant="success" className="ml-auto" onClick={this.handleSaveClick} style={{minWidth:'60px'}}>
                                    Save
                                </Button>&nbsp;&nbsp;
                               
                                <Button style={{minWidth:'60px'}} className='' variant="primary"  onClick={this.handleModalShow} >
                                    create
                                </Button>&nbsp;&nbsp;
                                <Button variant="danger"  href="/home" style={{minWidth:'60px'}}>
                                    Close
                                </Button>

                            </ButtonToolbar>

                        </Card.Body>
                    </Card>
                </Container>
                <CreatePagIbigTable 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div>
        )
    }

}



export  default PhilHealthTable