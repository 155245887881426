import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration, NumberFormat,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Tabs, Tab, Accordion
} 
from '../../noser-hris-component';

class EmployeeInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            disablecard     :   true,
            disableresigned :   true,
            disablebutton   :   false,
            
            approvalId      :   "",
            clientId        :   "",
            client          :   "",
            employeeId      :   "",
            profileId       :   "",
            employeeName    :   "",
            employeeNo      :   "",
            statusId        :   "",
            
            clientDDL       :   [],
            employeeDDL     :   [],

            /*PROFILE*/
            profileStatus   :   "",
            nickName        :   "",
            firstName       :   "",
            middleName      :   "",
            lastName        :   "",
            homeNumber      :   "",
            officeNumber    :   "",
            mobileNumber    :   "",
            emailAddress    :   "",
            facebook        :   "",
            twitter         :   "",
            dateofBirth     :   "",
            placeofBirth    :   "",
            height          :   "",
            weight          :   "",
            languageSpoken  :   "",
            hobbies         :   "",
            contactName     :   "",
            contactNumber   :   "",
            contactRelation :   "",
            sssNumber       :   "",
            tinNumber       :   "",
            phicNumber      :   "",
            hdmfNumber      :   "",
            
            arrangementId   :   "",
            arrangement     :   "",
            bloodtypeId     :   "",
            bloodtype       :   "",
            nationalityId   :   "",
            nationality     :   "",
            religionId      :   "",
            religion        :   "",
            genderId        :   "",
            gender          :   "",
            civilstatusId   :   "",
            civilstatus     :   "",

            arrangementDDL  :   [],
            bloodtypeDDL    :   [],
            nationalityDDL  :   [],
            religionDDL     :   [],
            genderDDL       :   [
                {"id":"1","name":"MALE"},
                {"id":"2","name":"FEMALE"}
            ],
            civilstatusDDL   :   [
                {"id":"1","name":"SINGLE"},
                {"id":"2","name":"MARRIED"},
                {"id":"3","name":"DIVORCED"},
                {"id":"4","name":"SEPARATED"}
            ],
            
            /*201*/
            locationId      :   "",
            positionId      :   "",
            employmentId    :   "",
            cardtypeId      :   "",
            paytypeId       :   "",
            paymodeId       :   "",
            periodtypeId    :   "",
            scheduleId      :   "",
            approver1Id     :   "",
            approver2Id     :   "",
            salaryRate      :   "",
            seaRate         :   "",
            ecolaRate       :   "",
            colaRate        :   "",
            isdefaultLocation:  "",
            cardNumber      :   "",
            dateHired       :   "",
            dateResigned    :   "",
            contractStart   :   "",
            contractEnd     :   "",

            location        :   "",
            position        :   "",
            employment      :   "",
            cardtype        :   "",
            paytype         :   "",
            paymode         :   "",
            periodtype      :   "",
            schedule        :   "",
            approver1       :   "",
            approver2       :   "",
            remarks         :   "",

            suffixName      :   "",
            sssNumberRemarks       :   "",
            tinNumberRemarks       :   "",
            phicNumberRemarks      :   "",
            hdmfNumberRemarks      :   "",

            requestDate            :    "",
            pullOutDate            :    "",
            returnDate             :    "",
            submittedById          :    "",

            locationDDL    :   [],
            positionDDL    :   [],
            employmentDDL  :   [],
            cardtypeDDL    :   [],
            paytypeDDL     :   [],
            paymodeDDL     :   [],
            scheduleDDL    :   [],
            periodtypeDDL  :   [],
            periodtypeList :   [],
            approver1DDL   :   [],
            approver2DDL   :   [],

            familyGRID     :   [],
            addressGRID    :   [],
            educationGRID  :   [],
            addrtypeDDL    :   [
                {"value":"0","label":"CURRENT ADDRESS"},
                {"value":"1","label":"REGISTERED ADDRESS"},
                {"value":"2","label":"PROVINCIAL ADDRESS"},
                {"value":"3","label":"CORRESPONDENCE ADDRESS"},
                {"value":"4","label":"FOREIGN ADDRESS"}
            ],
            roleDDL         :   [
                {"value":"0","label":"FATHER"},
                {"value":"1","label":"MOTHER"},
                {"value":"2","label":"SPOUSE"},
                {"value":"3","label":"BROTHER"},
                {"value":"4","label":"SISTER"},
                {"value":"5","label":"CHILD"},
            ],
            levelDDL         :   [
                {"value":"0","label":"COLLEGE"},
                {"value":"1","label":"HIGH SCHOOL"},
                {"value":"5","label":"SENIOR HIGH SCHOOL"},
                {"value":"4","label":"JUNIOR HIGH SCHOOL"},
                {"value":"2","label":"ELEMENTARY"},
                {"value":"3","label":"OTHERS"},
            ],
            regionDDL      :   [],
            provinceDDL    :   [],
            cityDDL        :   [],

            mobileNumberEffectivityDate:"",
            civilStatusEffectivityDate:"",
            locationEffectivityDate:"",
            positionEffectivityDate:"",
            employmentStatusEffectivityDate:"",
            dateResignedEffectivityDate:"",
            contractDateEndEffectivityDate:"",
            payCardTypeEffectivityDate:"",
            payCardNumberEffectivityDate:"",
            payTypeEffectivityDate:"",
            payModeEffectivityDate:"",
            workScheduleEffectivityDate:"",
            periodTypeEffectivityDate:"",
            salaryRateEffectivityDate:"",
            seaRateEffectivityDate:"",
            eColaRateEffectivityDate:"",
            colaRateEffectivityDate:"",
            approver1EffectivityDate:"",
            approver2EffectivityDate:"",

            hmoId:"0",
            cabinetId:"",
            drawerId:"",
            hmo:"",
            hmoDate:"",
            cabinet:"",
            drawer:"",

            employeedetails:[],
            data:[],
            hmoDDL:[
                {
                    "id":"0",
                    "name":"W/O"
                },
                {
                    "id":"1",
                    "name":"WITH"
                },
                {
                    "id":"2",
                    "name":"WAIVER"
                }
            ],
            cabinetDDL:[],
            drawerDDL:[]
        }
    } 
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.data = JSON.parse(sessionStorage.getItem("employeeapproval_" + this.state.userinfo.userId + this.state.userinfo.employeeId))
        console.log(this.state.data)
        this.state.approvalId = this.state.data.id
        this.state.client = this.state.data.clientName
        this.state.employeeName = this.state.data.employeeName
        this.state.employeeNo = this.state.data.employeeNo
        this.state.profileId = this.state.data.profileId
        this.state.employeeId = this.state.data.employeeId

        this.state.profileStatus = this.state.data.profileStatus
        this.state.firstName = this.state.data.firstName
        this.state.middleName = this.state.data.middleName
        this.state.lastName = this.state.data.lastName
        this.state.nickName = this.state.data.nickName
        this.state.homeNumber = this.state.data.phoneNumber
        this.state.officeNumber=""//this.state.employeedetails.nickName
        this.state.mobileNumber = this.state.data.mobileNumber
        this.state.emailAddress = this.state.data.emailAddress
        this.state.facebook = this.state.data.facebook
        this.state.twitter = this.state.data.twitter
        this.state.dateofBirth = this.state.data.dateOfBirth !=="" ? new Date(this.state.data.dateOfBirth) : ""
        this.state.placeofBirth = this.state.data.placeOfBirth
        this.state.height = this.state.data.height
        this.state.weight = this.state.data.weight
        this.state.languageSpoken = this.state.data.languageSpoken
        this.state.hobbies = this.state.data.hobbies
        this.state.contactName = this.state.data.emergencyContactName
        this.state.contactNumber = this.state.data.emergencyContactNumber
        this.state.contactRelation = this.state.data.emergencyContactRelation
        this.state.sssNumber = this.state.data.sssNumber
        this.state.tinNumber = this.state.data.tinNumber
        this.state.phicNumber = this.state.data.phicNumber
        this.state.hdmfNumber = this.state.data.hdmfNumber
        this.state.arrangementId = this.state.data.livingArrangementId
        this.state.arrangement = this.state.data.livingArrangement
        this.state.bloodtypeId = this.state.data.bloodTypeId
        this.state.bloodtype = this.state.data.bloodType
        this.state.nationalityId = this.state.data.nationalityId
        this.state.nationality = this.state.data.nationality
        this.state.religionId = this.state.data.religionId
        this.state.religion = this.state.data.religion
        this.state.genderId = this.state.data.genderId
        this.state.gender = this.state.data.gender
        this.state.civilstatusId = this.state.data.civilStatusId
        this.state.civilstatus = this.state.data.civilStatus

        this.state.locationId = this.state.data.locationId
        this.state.positionId = this.state.data.positionId
        this.state.employmentId = this.state.data.employmentStatusTypeId
        this.state.cardtypeId = this.state.data.payCardTypeId
        this.state.paytypeId = this.state.data.payTypeId
        this.state.paymodeId = this.state.data.payModeId
        this.state.periodtypeId = this.state.data.periodTypeId
        this.state.scheduleId = this.state.data.workScheduleId
        this.state.approver1Id = this.state.data.approverId1
        this.state.approver2Id = this.state.data.approverId2
        this.state.salaryRate = this.state.data.salaryOffered
        this.state.seaRate = this.state.data.seaValue
        this.state.ecolaRate = this.state.data.eColaValue
        this.state.colaRate = this.state.data.colaValue
        this.state.isdefaultLocation = this.state.data.isDefaultLocation
        this.state.cardNumber = this.state.data.payCardNumber
        this.state.dateHired = this.state.data.dateHired !=="" ? new Date(this.state.data.dateHired) : ""
        this.state.dateResigned = this.state.data.dateResigned !=="" ? new Date(this.state.data.dateResigned) : ""
        this.state.contractStart = this.state.data.contractDateStart !=="" ? new Date(this.state.data.contractDateStart) : ""
        this.state.contractEnd = this.state.data.contractDateEnd !=="" ? new Date(this.state.data.contractDateEnd) : ""
        this.state.location = this.state.data.locationName
        this.state.position = this.state.data.position
        this.state.employment = this.state.data.employmentStatusType
        this.state.cardtype = this.state.data.payCardType
        this.state.paytype = this.state.data.payType
        this.state.paymode = this.state.data.payMode
        this.state.periodtype = this.state.data.periodType
        this.state.schedule = this.state.data.workSchedule
        this.state.approver1Id = this.state.data.approverId1
        this.state.approver2Id = this.state.data.approverId2
        this.state.approver1 = this.state.data.approver1
        this.state.approver2 = this.state.data.approver2
        this.state.remarks = this.state.data.remarks

        this.state.mobileNumberEffectivityDate=this.state.data.mobileNumberEffectivityDate
        this.state.civilStatusEffectivityDate=this.state.data.civilStatusEffectivityDate
        this.state.locationEffectivityDate=this.state.data.locationEffectivityDate
        this.state.positionEffectivityDate=this.state.data.positionEffectivityDate
        this.state.employmentStatusEffectivityDate=this.state.data.employmentStatusEffectivityDate
        this.state.dateResignedEffectivityDate=this.state.data.dateResignedEffectivityDate
        this.state.contractDateEndEffectivityDate=this.state.data.contractDateEndEffectivityDate
        this.state.payCardTypeEffectivityDate=this.state.data.payCardTypeEffectivityDate
        this.state.payCardNumberEffectivityDate=this.state.data.payCardNumberEffectivityDate
        this.state.payTypeEffectivityDate=this.state.data.payTypeEffectivityDate
        this.state.payModeEffectivityDate=this.state.data.payModeEffectivityDate
        this.state.workScheduleEffectivityDate=this.state.data.workScheduleEffectivityDate
        this.state.periodTypeEffectivityDate=this.state.data.periodTypeEffectivityDate
        this.state.salaryRateEffectivityDate=this.state.data.salaryRateEffectivityDate
        this.state.seaRateEffectivityDate=this.state.data.seaRateEffectivityDate
        this.state.eColaRateEffectivityDate=this.state.data.eColaRateEffectivityDate
        this.state.colaRateEffectivityDate=this.state.data.colaRateEffectivityDate
        this.state.approver1EffectivityDate=this.state.data.approver1EffectivityDate
        this.state.approver2EffectivityDate=this.state.data.approver2EffectivityDate

        this.state.hmoId=this.state.data.hmoId
        this.state.cabinetId=this.state.data.cabinetId
        this.state.drawerId=this.state.data.drawerId
        this.state.hmo=this.state.data.hmo
        this.state.hmoDate=this.state.data.hmoDate
        this.state.cabinet=this.state.data.cabinet
        this.state.drawer=this.state.data.drawer

        this.state.suffixName = this.state.data.suffixName
        this.state.sssNumberRemarks = this.state.data.sssNumberRemarks
        this.state.tinNumberRemarks = this.state.data.tinNumberRemarks
        this.state.phicNumberRemarks = this.state.data.phicNumberRemarks
        this.state.hdmfNumberRemarks = this.state.data.hdmfNumberRemarks

        this.state.requestDate = this.state.data.requestDate
        this.state.pullOutDate = this.state.data.pullOutDate
        this.state.returnDate = this.state.data.returnDate
        this.state.submittedById = this.state.data.submittedById

        this.forceUpdate()
        this.GetRegions()
    }
    GetRegions = async () => {
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":""
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRegions",  params)
        .then(res => {
            
            let regionDDL = []
            res.data.regions.map(function(itm){
                regionDDL.push({
                    value : itm.id,
                    label : itm.name,
                })
            })
            this.setState({regionDDL:regionDDL})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetProvinces()
    }
    GetProvinces = async () => {
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Region": ""
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetProvinces",  params)
        .then(res => {
            
            let provinceDDL = []
            res.data.provinces.map(function(itm){
                provinceDDL.push({
                    value : itm.id,
                    label : itm.name,
                    regionId:itm.regionId
                })
            })
            this.setState({provinceDDL:provinceDDL})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetCities()
    }
    GetCities = async () => {

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":"",
            "ProvinceName": ""
        };
        await
         axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetCities",  params)
        .then(res => {
            
            let cityDDL = []
            res.data.cities.map(function(itm){
                cityDDL.push({
                    value : itm.id,
                    label : itm.name,
                    provinceId:itm.provinceId
                })
            })
            //this.state.addressGRID = this.state.data.addressesses
            //this.state.familyGRID = this.state.data.familyBackgrounds
            //this.state.educationGRID = this.state.data.educations 
            //this.forceUpdate()
            this.GetEmployeeAddress()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetEmployeeAddress = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ManningId":this.state.approvalId
        };
        await
         axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/GetManningEmployeeAddress",  params)
        .then(res => {
            const data = res.data
            this.setState({addressGRID:data.addressesses})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetEmployeeEducations()
    }
    GetEmployeeEducations = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ManningId":this.state.approvalId
        };
        await
         axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/GetManningEmployeeEducations",  params)
        .then(res => {
            const data = res.data
            this.setState({educationGRID:data.educations})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetEmployeeFamilyBackgrounds()
    }
    GetEmployeeFamilyBackgrounds = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ManningId":this.state.approvalId
        };
        await
         axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/GetManningEmployeeFamilyBackgrounds",  params)
        .then(res => {
            const data = res.data
            this.setState({familyGRID:data.familyBackgrounds,isloading:false})
            this.forceUpdate()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    
    render(){
        const addrCol = [
            {
                dataField: 'typeId',
                text: 'TYPE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'8%'}},
                style:{textAlign:'left',width:'8%'},
                formatter: (cell, row) => {
                    if(row.typeId!=='' || row.typeId!==null || typeof row.typeId!=='undefined'){
                        if(this.state.addrtypeDDL.filter(x => x.value == cell).length>0)
                            return this.state.addrtypeDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.addrtypeDDL
                },
                validator: (newValue, row, column) => {
                    if(this.state.addressGRID.filter(x=>x.typeId===newValue).length>0)
                    return {
                        valid: false,
                        message:"Select address type already exist."
                      };
                    return true;   
                }
            },
            {
                dataField: 'regionId',
                text: 'REGION',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%'}},
                style:{textAlign:'left',width:'15%'},
                formatter: (cell, row) => {
                    if(row.regionId!=='' || row.regionId!==null || typeof row.regionId!=='undefined'){
                        if(this.state.regionDDL.filter(x => x.value == cell).length>0)
                            return this.state.regionDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.regionDDL
                }
            },
            {
                dataField: 'provinceId',
                text: 'PROVINCE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%'}},
                style:{textAlign:'left',width:'15%'},
                formatter: (cell, row) => {
                    if(row.provinceId!=='' && row.provinceId!==null || typeof row.provinceId!=='undefined'){
                        if(this.state.provinceDDL.filter(x => x.value == cell).length>0)
                            return this.state.provinceDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    getOptions: (setOptions, { row, column }) => {
                        return this.state.provinceDDL.filter(x=>x.regionId==row.regionId);
                    }
                }
            },
            {
                dataField: 'cityId',
                text: 'CITY',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%'}},
                style:{textAlign:'left',width:'15%'},
                formatter: (cell, row) => {
                    if(row.cityId!=='' && row.cityId!==null || typeof row.cityId!=='undefined'){
                        if(this.state.cityDDL.filter(x => x.value == cell).length>0)
                            return this.state.cityDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    getOptions: (setOptions, { row, column }) => {
                        return this.state.cityDDL.filter(x=>x.provinceId==row.provinceId);
                    }
                }
            },
            {
                dataField: 'houseNumber',
                text: 'House Number',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'13%'}},
                style:{textAlign:'left',width:'13%'}
            },
            {
                dataField: 'streetName',
                text: 'STREET NAME',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%'}},
                style:{textAlign:'left',width:'10%'}
            },
            {
                dataField: 'barangay',
                text: 'BARANGAY',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%'}},
                style:{textAlign:'left',width:'10%'}
            },
            {
                dataField: 'postalCode',
                text: 'POSTAL CODE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'7%'}},
                style:{textAlign:'left',width:'7%'},
                validator: (newValue, row, column) => {
                    if (this.ValidNumeric(newValue)) {
                      return {
                        valid: false,
                        message:"Value must be numeric."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'action',
                text: 'ACTION',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'7%'}
                },
                style:{textAlign:'center',width:'7%'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button 
                            onClick={e => this.handleRemoveAddress(row.id)}
                            style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link"
                    >REMOVE</Button>
                    );
                },
            }
        ]
        const fbCol = [
            {
                dataField: 'roleId',
                text: 'ROLE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%'}},
                style:{textAlign:'left',width:'10%'},
                formatter: (cell, row) => {
                    if(row.roleId!=='' || row.roleId!==null || typeof row.roleId!=='undefined'){
                        if(this.state.roleDDL.filter(x => x.value == cell).length>0)
                            return this.state.roleDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.roleDDL
                },
                validator: (newValue, row, column) => {
                    if(this.state.addressGRID.filter(x=>x.typeId===newValue).length>0 && newValue==="0")
                    return {
                        valid: false,
                        message:"Cannot set multiple for role father."
                      };
                    if(this.state.addressGRID.filter(x=>x.typeId===newValue).length>0 && newValue==="1")
                    return {
                        valid: false,
                        message:"Cannot set multiple for role mother."
                    };
                    if(this.state.addressGRID.filter(x=>x.typeId===newValue).length>0 && newValue==="2")
                    return {
                        valid: false,
                        message:"Cannot set multiple for role spouse."
                    };
                    return true;   
                }
            },
            {
                dataField: 'name',
                text: 'Name',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%'}},
                style:{textAlign:'left',width:'20%'}
            },
            {
                dataField: 'age',
                text: 'AGE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'},
                validator: (newValue, row, column) => {
                    if (this.ValidNumeric(newValue)) {
                      return {
                        valid: false,
                        message:"Value must be numeric."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'occupation',
                text: 'OCCUPATION',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%'}},
                style:{textAlign:'center',width:'15%'}
            },
            {
                dataField: 'company',
                text: 'COMPANY',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'38%'}},
                style:{textAlign:'left',width:'38%'}
            },
            {
                dataField: 'action',
                text: 'ACTION',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'7%'}
                },
                style:{textAlign:'center',width:'7%'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button 
                        onClick={e=>this.handleRemoveBackground(row.id)}
                            style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link"
                    >REMOVE</Button>
                    );
                },
            }
        ]
        const eduCol = [
            {
                dataField: 'levelId',
                text: 'LEVEL',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%'}},
                style:{textAlign:'left',width:'10%'},
                formatter: (cell, row) => {
                    if(row.levelId!=='' || row.levelId!==null || typeof row.levelId!=='undefined'){
                        if(this.state.levelDDL.filter(x => x.value == cell).length>0)
                            return this.state.levelDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.levelDDL
                },
                validator: (newValue, row, column) => {
                    if(this.state.addressGRID.filter(x=>x.typeId===newValue).length>0)
                    return {
                        valid: false,
                        message:"Selected level already exist."
                      };
                    return true;   
                }
            },
            {
                dataField: 'schoolName',
                text: 'SCHOOL NAME',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%'}},
                style:{textAlign:'left',width:'20%'}
            },
            {
                dataField: 'course',
                text: 'COURSE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%'}},
                style:{textAlign:'left',width:'15%'}
            },
            {
                dataField: 'startYear',
                text: 'START YEAR',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'},
                validator: (newValue, row, column) => {
                    if (this.ValidNumeric(newValue)) {
                      return {
                        valid: false,
                        message:"Value must be numeric."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'endYear',
                text: 'END YEAR',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%'}},
                style:{textAlign:'left',width:'10%'},
                validator: (newValue, row, column) => {
                    if (this.ValidNumeric(newValue)) {
                      return {
                        valid: false,
                        message:"Value must be numeric."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'honorRecieved',
                text: 'HONOR',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'28%'}},
                style:{textAlign:'left',width:'28%'}
            },
            {
                dataField: 'action',
                text: 'ACTION',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'7%'}
                },
                style:{textAlign:'center',width:'7%'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button 
                            onClick={e=>this.handleRemoveEducation(row.id)}
                            style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link"
                    >REMOVE</Button>
                    );
                },
            }
        ]
        const selectrowAddr = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        const selectrowFB = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        const selectrowEdu = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>Manning >> Manage Employee </Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Form.Control 
                                            type="text"
                                            id="client"
                                            name="client"
                                            value={this.state.data.clientName}
                                            disabled
                                        /> 
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Form.Control 
                                            type="text"
                                            id="employeeName"
                                            name="employeeName"
                                            value={this.state.data.employeeName}
                                            disabled
                                        /> 
                                    </Col>
                                </Form.Group>
                            </Form>
                            <Tabs className="mt-2" defaultActiveKey="default" transition={false} id="noanim-tab-example">
                                <Tab eventKey="default" title="Employee Profiles">
                                        <Accordion className="mt-2"  defaultActiveKey="0">
                                        <Card className="card-tab">
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{color: "#FFFFFF"}}>
                                                        PROFILE INFORMATION
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                    <Form >
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        STATUS
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Form.Control 
                                                                type="text"
                                                                id="statusName"
                                                                name="statusName"
                                                                value={this.state.profileStatus}
                                                                disabled
                                                            /> 
                                                        </Col>
                                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                        HMO
                                                        </Form.Label>
                                                        <Col sm="1">
                                                            <Typeahead
                                                                labelKey='name'
                                                                id='hmoId'
                                                                name='hmoId'
                                                                //onChange={e => this.handleChangedDDL('nationalityId')}
                                                                //onChange={this.handleChangedNationality}
                                                                options={this.state.hmoDDL}
                                                                selected={[this.state.hmo]}
                                                                disabled
                                                            />
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        HMO DATE
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <DatePicker
                                                                id="hmoDate"
                                                                name="hmoDate"
                                                                value={this.state.hmoDate}
                                                                //selected={this.state.hmoDate}
                                                                //onChange={this.handleChangedHMODate}
                                                                //minDate={this.minDate}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                autoComplete="off"
                                                                disabled
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    FIRST NAME
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="firstName"
                                                            name="firstName"
                                                            value={this.state.firstName}
                                                            //onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled
                                                        /> 
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    MIDDLE NAME
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="middleName"
                                                            name="middleName"
                                                            value={this.state.middleName}
                                                            //onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    LAST NAME
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="lastName"
                                                            name="lastName"
                                                            value={this.state.lastName}
                                                            //onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    SUFFIX NAME
                                                    </Form.Label>
                                                    <Col sm="1">
                                                        <Form.Control 
                                                            type="text"
                                                            id="suffixName"
                                                            name="suffixName"
                                                            value={this.state.suffixName}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    NICK NAME
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="nickName"
                                                            name="nickName"
                                                            value={this.state.nickName}
                                                            //onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    HOME PHONE NUMBER
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="homeNumber"
                                                            name="homeNumber"
                                                            value={this.state.homeNumber}
                                                            //onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    OFFICE PHONE NUMBER
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="officeNumber"
                                                            name="officeNumber"
                                                            value={this.state.officeNumber}
                                                            //onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    FACEBOOK
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="facebook"
                                                            name="facebook"
                                                            value={this.state.facebook}
                                                            //onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    TWITTER
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="twitter"
                                                            name="twitter"
                                                            value={this.state.twitter}
                                                            //onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    NATIONALITY
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='nationalityId'
                                                            name='nationalityId'
                                                            //onChange={e => this.handleChangedDDL('nationalityId')}
                                                            //onChange={this.handleChangedNationality}
                                                            options={this.state.nationalityDDL}
                                                            selected={[this.state.nationality]}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    RELIGION
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='religionId'
                                                            name='religionId'
                                                            //onChange={e => this.handleChangedDDL('religionId')}
                                                            //onChange={this.handleChangedReligion}
                                                            options={this.state.religionDDL}
                                                            selected={[this.state.religion]}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    GENDER
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            name="genderId"
                                                            id="genderId"
                                                            //onChange={this.handleChangedDDL("genderId")}
                                                            //onChange={this.handleChangedGender}
                                                            options={this.state.genderDDL}
                                                            selected={[this.state.gender]}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    EMAIL ADDRESS
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="emailAddress"
                                                            name="emailAddress"
                                                            value={this.state.emailAddress}
                                                            //onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    DATE OF BIRTH
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <DatePicker
                                                            id="dateofBirth"
                                                            name="dateofBirth"
                                                            value={this.state.dateofBirth}
                                                            selected={this.state.dateofBirth}
                                                            //onChange={this.handleChangedDate('dateofBirth')}
                                                            minDate={this.minDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    PLACE OF BIRTH
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="placeofBirth"
                                                            name="placeofBirth"
                                                            value={this.state.placeofBirth}
                                                            //onChange={this.handleChanged} 
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    LIVING ARRANGEMENT
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='arrangementId'
                                                            name='arrangementId'
                                                            //onChange={this.handleChangedArrangement}
                                                            options={this.state.arrangementDDL}
                                                            selected={[this.state.arrangement]}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    BLOOD TYPE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='bloodtypeId'
                                                            name='bloodtypeId'
                                                            //onChange={this.handleChangedBloodType}
                                                            options={this.state.bloodtypeDDL}
                                                            selected={[this.state.bloodtype]}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    HEIGHT
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="height"
                                                            name="height"
                                                            value={this.state.height}
                                                            autoComplete="off"
                                                            //onChange={this.handleChanged}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    WEIGHT
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="weight"
                                                            name="weight"
                                                            value={this.state.weight}
                                                            autoComplete="off"
                                                            //onChange={this.handleChanged}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    LANGUAGE SPOKEN
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="languageSpoken"
                                                            name="languageSpoken"
                                                            value={this.state.languageSpoken}
                                                            //onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    HOBBIES
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="hobbies"
                                                            name="hobbies"
                                                            //onChange={this.handleChanged}
                                                            value={this.state.hobbies}
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    EMERGENCY CONTACT NAME
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="contactName"
                                                            name="contactName"
                                                            value={this.state.contactName}
                                                            //onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    EMERGENCY CONTACT NUMBER
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="contactNumber"
                                                            name="contactNumber"
                                                            value={this.state.contactNumber}
                                                            //onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    EMERGENCY CONTACT RELATION
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="contactRelation"
                                                            name="contactRelation"
                                                            value={this.state.contactRelation}
                                                            //onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    SSS NUMBER
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <NumberFormat
                                                            id="sssNumber"
                                                            name="sssNumber"
                                                            value={this.state.sssNumber}
                                                            //onChange={this.handleChanged} 
                                                            autoComplete="off"
                                                            format="##-#######-#"
                                                            className="form-control"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    REMARKS
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Form.Control 
                                                            type="text"
                                                            id="sssNumberRemarks"
                                                            name="sssNumberRemarks"
                                                            value={this.state.sssNumberRemarks}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            maxLength="2000"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    TIN NUMBER 
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <NumberFormat
                                                            id="tinNumber"
                                                            name="tinNumber"
                                                            value={this.state.tinNumber}
                                                            //onChange={this.handleChanged} 
                                                            autoComplete="off"
                                                            format="###-###-###-###"
                                                            className="form-control"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    REMARKS
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Form.Control 
                                                            type="text"
                                                            id="tinNumberRemarks"
                                                            name="tinNumberRemarks"
                                                            value={this.state.tinNumberRemarks}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            maxLength="2000"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    PHIC NUMBER
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <NumberFormat
                                                            id="phicNumber"
                                                            name="phicNumber"
                                                            value={this.state.phicNumber}
                                                            //onChange={this.handleChanged} 
                                                            autoComplete="off"
                                                            format="##-#########-#"
                                                            className="form-control"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    REMARKS
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Form.Control 
                                                            type="text"
                                                            id="phicNumberRemarks"
                                                            name="phicNumberRemarks"
                                                            value={this.state.phicNumberRemarks}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            maxLength="2000"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    HDMF NUMBER 
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <NumberFormat
                                                            id="hdmfNumber"
                                                            name="hdmfNumber"
                                                            value={this.state.hdmfNumber}
                                                            //onChange={this.handleChanged} 
                                                            autoComplete="off"
                                                            format="####-####-####"
                                                            className="form-control"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    REMARKS
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Form.Control 
                                                            type="text"
                                                            id="hdmfNumberRemarks"
                                                            name="hdmfNumberRemarks"
                                                            value={this.state.hdmfNumberRemarks}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            maxLength="2000"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                        MOBILE NUMBER
                                                        </Form.Label>
                                                        <Col sm="2">
                                                            <Form.Control 
                                                                type="text"
                                                                id="mobileNumber"
                                                                name="mobileNumber"
                                                                value={this.state.mobileNumber}
                                                                onChange={this.handleChanged}
                                                                autoComplete="off"
                                                                disabled
                                                            />
                                                        </Col>
                                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                        EFFECTIVITY DATE
                                                        </Form.Label>
                                                        <Col sm="2">
                                                            <DatePicker
                                                                id='mobileNumberEffectivityDate'
                                                                name='mobileNumberEffectivityDate'
                                                                value={this.state.mobileNumberEffectivityDate}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                placeholder="Effectivity Date"
                                                                //minDate={this.state.effectivityMinDate}
                                                                autoComplete="off"
                                                                disabled
                                                            />
                                                        </Col>
                                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                        CIVIL STATUS
                                                        </Form.Label>
                                                        <Col sm="2">
                                                            <Typeahead
                                                                labelKey='name'
                                                                id='civilstatusId'
                                                                name='civilstatusId'
                                                                options={this.state.civilstatusDDL}
                                                                selected={[this.state.civilstatus]}
                                                                disabled
                                                            />
                                                        </Col>
                                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                        EFFECTIVITY
                                                        </Form.Label>
                                                        <Col sm="2">
                                                            <DatePicker
                                                                id='civilStatusEffectivityDate'
                                                                name='civilStatusEffectivityDate'
                                                                value={this.state.civilStatusEffectivityDate}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                placeholder="Effectivity Date"
                                                                autoComplete="off"
                                                                disabled
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    </Form>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <div className="card-header-tab">
                                                <Accordion.Toggle as={Button} variant="link" eventKey="1" style={{color: "#FFFFFF"}}>
                                                        EMPLOYEE ADDRESSESS
                                                </Accordion.Toggle>
                                            </div>
                                            <Accordion.Collapse eventKey="1">
                                                <div>
                                                <BootstrapTable
                                                    striped
                                                    hover
                                                    condensed
                                                    keyField = "id"
                                                    data = { this.state.addressGRID }
                                                    columns = { addrCol}
                                                    selectRow = { selectrowAddr }
                                                    cellEdit = { cellEditFactory({ 
                                                        mode: 'dbclick', 
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => { 
                                                            //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }})
                                                    }
                                                    rowClasses="noser-table-row-class no-checkbox"
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                />
                                                {/* <div style={{marginLeft:'5px',marginBottom:'10x'}}>
                                                    <Button id="addrid"
                                                    className="noser-button" 
                                                    variant="success" 
                                                    onClick = {this.handleAddAddress}
                                                    >ADD</Button>
                                                </div> */}
                                                </div> 
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <div className="card-header-tab">
                                                <Accordion.Toggle as={Button} variant="link" eventKey="2" style={{color: "#FFFFFF"}}>
                                                        FAMILY BACKGROUND
                                                </Accordion.Toggle>
                                            </div>
                                            <Accordion.Collapse eventKey="2">
                                                <div>
                                                <BootstrapTable
                                                        striped
                                                        hover
                                                        condensed
                                                        keyField = "id"
                                                        data = { this.state.familyGRID }
                                                        columns = { fbCol}
                                                        selectRow = { selectrowFB }
                                                        cellEdit = { cellEditFactory({ 
                                                            mode: 'dbclick', 
                                                            blurToSave: true,
                                                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                                                //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                            }})
                                                        }
                                                        rowClasses="noser-table-row-class no-checkbox"
                                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    /> 
                                                    {/* <div style={{marginLeft:'5px',marginBottom:'10x'}}>
                                                        <Button id="bgid"
                                                        className="noser-button" 
                                                        variant="success" 
                                                        onClick = {this.handleAddBackground}
                                                        >ADD</Button>
                                                    </div> */}
                                                </div>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <div className="card-header-tab">
                                                <Accordion.Toggle as={Button} variant="link" eventKey="3" style={{color: "#FFFFFF"}}>
                                                        EDUCATION ATTAINMENT
                                                </Accordion.Toggle>
                                            </div>
                                            <Accordion.Collapse eventKey="3">
                                                <div>
                                                    <BootstrapTable
                                                        striped
                                                        hover
                                                        condensed
                                                        keyField = "id"
                                                        data = { this.state.educationGRID }
                                                        columns = { eduCol}
                                                        selectRow = { selectrowEdu }
                                                        cellEdit = { cellEditFactory({ 
                                                            mode: 'dbclick', 
                                                            blurToSave: true,
                                                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                                                //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                            }})
                                                        }
                                                        rowClasses="noser-table-row-class no-checkbox"
                                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    />
                                                    {/* <div style={{marginLeft:'5px',marginBottom:'10x'}}>
                                                        <Button id="eduid"
                                                        className="noser-button" 
                                                        variant="success" 
                                                        onClick = {this.handleAddEducation}
                                                        >ADD</Button>
                                                    </div>  */}
                                                </div>
                                            </Accordion.Collapse>
                                        </Card>
                                        </Accordion>
                                </Tab>
                                <Tab eventKey="201" title="Employee Records">
                                    <Accordion className="mt-2"  defaultActiveKey="0">
                                    <Card className="card-tab">
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{color: "#FFFFFF"}}></Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <Form>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    {/* <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                        CLIENT
                                                    </Form.Label>
                                                    <Col sm="5">
                                                        <Form.Control 
                                                            type="text" 
                                                            id="clientName"
                                                            name="clientName"
                                                            value={this.state.client}
                                                            disabled
                                                        />
                                                    </Col> */}
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EMPLOYEE NUMBER
                                                    </Form.Label>
                                                    <Col sm="5">
                                                        <Form.Control 
                                                            type="text" 
                                                            id="employeeNumber"
                                                            name="employeeNumber"
                                                            value={this.state.employeeNo}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    BRANCH
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Typeahead 
                                                            labelKey='name'
                                                            id='locationId'
                                                            name='locationId'
                                                            onChange={this.handleChangedLocation}
                                                            options={this.state.locationDDL}
                                                            selected={[this.state.location]}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='locationEffectivityDate'
                                                            name='locationEffectivityDate'
                                                            value={this.state.locationEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="4">
                                                        <Form.Check type="checkbox" 
                                                            label="IS DEFAULT BRANCH" 
                                                            id="isdefaultLocation"
                                                            name="isdefaultLocation"
                                                            onChange={this.handleChangedCB}
                                                            style={{fontWeight : "bold",marginLeft:"2px"}}
                                                            checked={this.state.isdefaultLocation == "1" ? true:false}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    POSITION
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='positionId'
                                                            name='positionId'
                                                            onChange={this.handleChangedPosition}
                                                            options={this.state.positionDDL}
                                                            selected={[this.state.position]}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='positionEffectivityDate'
                                                            name='positionEffectivityDate'
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.positionEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EMPLOYMENT
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='employmentId'
                                                            name='employmentId'
                                                            //onChange={e => this.handleChangedDDL('employmentId')}
                                                            onChange={this.handleChangedEmployment}
                                                            options={this.state.employmentDDL}
                                                            selected={[this.state.employment]}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='employmentStatusEffectivityDate'
                                                            name='employmentStatusEffectivityDate'
                                                            value={this.state.employmentStatusEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    DATE HIRED
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='dateHired'
                                                            name='dateHired'
                                                            //onChange={this.handleChangedDate('dateHired')}
                                                            minDate={this.minDate}
                                                            value={this.state.dateHired}
                                                            selected={this.state.dateHired}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='dateHired'
                                                            name='dateHired'
                                                            minDate={this.minDate}
                                                            value={this.state.dateHired}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    DATE RESIGNED
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='dateResigned'
                                                            name='dateResigned'
                                                            selected={this.state.dateResigned}
                                                            minDate={this.minDate}
                                                            value={this.state.dateResigned}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            //disabled={this.state.disableresigned}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='dateResignedEffectivityDate'
                                                            name='dateResignedEffectivityDate'
                                                            value={this.state.dateResignedEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    CONTRACT START
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='contractStart'
                                                            name='contractStart'
                                                            minDate={this.minDate}
                                                            value={this.state.contractStart}
                                                            selected={this.state.contractStart}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='contractDateStartEffectivityDate'
                                                            name='contractDateStartEffectivityDate'
                                                            value={this.state.contractDateStartEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    CONTRACT END
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='contractEnd'
                                                            name='contractEnd'
                                                            onChange={this.handleChangedEOC}
                                                            minDate={this.minDate}
                                                            value={this.state.contractEnd}
                                                            selected={this.state.contractEnd}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='contractDateEndEffectivityDate'
                                                            name='contractDateEndEffectivityDate'
                                                            value={this.state.contractDateEndEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    PAY CARD TYPE
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='cardtypeId'
                                                            name='cardtypeId'
                                                            onChange={this.handleChangedPayCardType}
                                                            options={this.state.cardtypeDDL}
                                                            selected={[this.state.cardtype]}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='payCardTypeEffectivityDate'
                                                            name='payCardTypeEffectivityDate'
                                                            value={this.state.payCardTypeEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    PAY CARD NUMBER
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Form.Control 
                                                            type="text" 
                                                            id="cardNumber"
                                                            name="cardNumber"
                                                            onChange={this.handleChanged}
                                                            value={this.state.cardNumber}
                                                            autoComplete="off" 
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='payCardNumberEffectivityDate'
                                                            name='payCardNumberEffectivityDate'
                                                            value={this.state.payCardNumberEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    PAY TYPE
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='paytypeId'
                                                            name='paytypeId'
                                                            onChange={this.handleChangedPayType}
                                                            options={this.state.paytypeDDL}
                                                            selected={[this.state.paytype]}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='payTypeEffectivityDate'
                                                            name='payTypeEffectivityDate'
                                                            value={this.state.payTypeEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    WORK SCHEDULE
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Typeahead
                                                            labelKey='description'
                                                            id='scheduleId'
                                                            name='scheduleId'
                                                            //onChange={this.handleChangedSchedule}
                                                            options={this.state.scheduleDDL}
                                                            selected={[this.state.schedule]}
                                                            //disabled={this.state.recordDisable}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='workScheduleEffectivityDate'
                                                            name='workScheduleEffectivityDate'
                                                            value={this.state.workScheduleEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    PAY MODE
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='paymodeId'
                                                            name='paymodeId'
                                                            onChange={this.handleChangedPayMode}
                                                            options={this.state.paymodeDDL}
                                                            selected={[this.state.paymode]}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='payModeEffectivityDate'
                                                            name='payModeEffectivityDate'
                                                            value={this.state.payModeEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    SALARY RATE
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Form.Control 
                                                            type="text"
                                                            id="salaryRate"
                                                            name="salaryRate"
                                                            value={this.state.salaryRate}
                                                            autoComplete="off"
                                                            disabled
                                                        /> 
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='salaryRateEffectivityDate'
                                                            name='salaryRateEffectivityDate'
                                                            value={this.state.salaryRateEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    PERIOD TYPE
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='periodtypeId'
                                                            name='periodtypeId'
                                                            //onChange={this.handleChangedPeriodType}
                                                            options={this.state.periodtypeDDL}
                                                            selected={[this.state.periodtype]}
                                                            //disabled={this.state.recordDisable}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="3"></Col>
                                                    {/* <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='periodTypeEffectivityDate'
                                                            name='periodTypeEffectivityDate'
                                                            value={this.state.periodTypeEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col> */}
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    SEA RATE
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Form.Control 
                                                            type="text"
                                                            id="seaRate"
                                                            name="seaRate"
                                                            value={this.state.seaRate}
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='seaRateEffectivityDate'
                                                            name='seaRateEffectivityDate'
                                                            value={this.state.seaRateEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    APPROVER 1
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Typeahead 
                                                            labelKey='employeeName'
                                                            id='approver1Id'
                                                            name='approver1Id'
                                                            onChange={this.handleChangedApprover1}
                                                            options={this.state.approver1DDL}
                                                            selected={[this.state.approver1]}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="3"></Col>
                                                    {/* <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='approver1EffectivityDate'
                                                            name='approver1EffectivityDate'
                                                            value={this.state.approver1EffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col> */}
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    COLA RATE
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Form.Control 
                                                            type="text"
                                                            id="colaRate"
                                                            name="colaRate"
                                                            value={this.state.colaRate}
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='colaRateEffectivityDate'
                                                            name='colaRateEffectivityDate'
                                                            value={this.state.colaRateEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    APPROVER 2
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Typeahead 
                                                            labelKey='employeeName'
                                                            id='approver2Id'
                                                            name='approver2Id'
                                                            onChange={this.handleChangedApprover2}
                                                            options={this.state.approver2DDL}
                                                            selected={[this.state.approver2]}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="3"></Col>
                                                    {/* <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='approver2EffectivityDate'
                                                            name='approver2EffectivityDate'
                                                            value={this.state.approver2EffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col> */}
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    ECOLA RATE
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Form.Control 
                                                            type="text"
                                                            id="ecolaRate"
                                                            name="ecolaRate"
                                                            value={this.state.ecolaRate}
                                                            autoComplete="off"
                                                            disabled
                                                        /> 
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='eColaRateEffectivityDate'
                                                            name='eColaRateEffectivityDate'
                                                            value={this.state.eColaRateEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    REMARKS
                                                    </Form.Label>
                                                    <Col sm="11">
                                                        <Form.Control 
                                                            //type="text"
                                                            as="textarea"
                                                            id="remarks"
                                                            name="remarks"
                                                            value={this.state.remarks}
                                                            onChange={this.handleChanged} 
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Form>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    </Accordion>
                                </Tab>
                                <Tab eventKey="librarian" title="Librarian">
                                    <Accordion className="mt-2"  defaultActiveKey="0">
                                        <Card className="card-tab">
                                        <div className="card-header-tab">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{color: "#FFFFFF"}}></Accordion.Toggle>
                                        </div>
                                        <Card.Body style={{height:'280px'}}>
                                            <Form>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    CABINET
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='cabinetId'
                                                            name='cabinetId'
                                                            onChange={this.handleChangedCabinet}
                                                            options={this.state.cabinetDDL}
                                                            selected={[this.state.cabinet]}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    DRAWER
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='drawerId'
                                                            name='drawerId'
                                                            onChange={this.handleChangedDrawer}
                                                            options={this.state.drawerDDL}
                                                            selected={[this.state.drawer]}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    REQUEST DATE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <DatePicker
                                                            id='requestDate'
                                                            name='requestDate'
                                                            //onChange={this.handleChangedDate('requestDate')}
                                                            //minDate={this.state.effectivityMinDate}
                                                            value={this.state.requestDate}
                                                            //selected={this.state.requestDate}
                                                            //dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Request Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    PULL OUT DATE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <DatePicker
                                                            id='pullOutDate'
                                                            name='pullOutDate'
                                                            //onChange={this.handleChangedDate('pullOutDate')}
                                                            //minDate={this.state.effectivityMinDate}
                                                            value={this.state.pullOutDate}
                                                            //selected={this.state.pullOutDate}
                                                            //dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Pull Out Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    RETURN DATE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <DatePicker
                                                            id='returnDate'
                                                            name='returnDate'
                                                            //onChange={this.handleChangedDate('returnDate')}
                                                            //minDate={this.state.effectivityMinDate}
                                                            value={this.state.returnDate}
                                                            ///selected={this.state.returnDate}
                                                            //dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Return Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Form>
                                        </Card.Body>
                                        </Card>
                                    </Accordion>
                                </Tab>
                            </Tabs>
                        </Card.Body>
                        <ButtonToolbar mt="5" style={{marginRight:'13px',marginBottom:'10px'}}>
                            <Button  href={this.state.data.origin==="1" ? "/manageemployees" : "/manageemployeeapprovals"} className="ml-auto noser-button" variant="warning">Back</Button>
                        </ButtonToolbar>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div>
        )
    }

}
export default EmployeeInformation;
