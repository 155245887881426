import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer
} 
from '../../../noser-hris-component';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { object } from 'prop-types';

/* 
import WorkScheduleEdit from  "./WorkScheduleEdit" */

class DTRStatusMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            DisableExport   :   true,
            uploadfile      : "",
            clientLocationListAutocomplete	:   [],
            clientListAutocomplete	        :   [],
            periodListAutocomplete	        :   [],
            employeeLisAutocomplete	        :   [],
            dtrMonitoringListTable	        :   [],
            dtrMonitoringSummaryListTable	:   [],
            selectedLocationId              :   "",
            selectedEmployeeId              :   ""
        } 
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        sleep(1000).then(() => {
            this.setState({isLoading:false})
        })
        this.getClientList();
        this.getEmployees()
    }

    getClientList = async() => {
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId" : this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            this.setState({
                isloading:false,
                clientListAutocomplete : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }

    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name

        this.getClientLocation();
    };

    getClientLocation = async() => {
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId": this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.selectedClientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", getParams)
        .then(res => {
            ////console.log("Client Location");
            ////console.log(res.data.locations);
            this.setState({clientLocationListAutocomplete : res.data.locations ? res.data.locations : []})
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
       this.GetPayPeriodList();
    }
    GetPayPeriodList = async() => {
        const periodListParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId": this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":"",
            "IsProcessed":""
         };
 
        await axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods",  periodListParams
            )
            .then(res => {
                const data = res.data;
                this.setState({periodListAutocomplete: data.payrollPeriods})
                
            })
            this.getEmployees();
    };

    getEmployees = async() => {
        const getParams = {
            "IpAddress":"0.0.0.0", 
            "CompanyId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            //console.log("Employee List ");
            //console.log(res.data);
            const data = res.data
            if(data.status=="1")
                this.setState({employeeLisAutocomplete : data.employees, isloading:false,}) 
            else
                this.setState({employeeLisAutocomplete : [], isloading:false}) 
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
        
    }

    onChangeEmployeesList = (e) => {
        if(e.length==0)
        {
            this.setState({selectedEmployeeId : ""})
          
            return
        }
        
        this.state.selectedEmployeeId = e[0].id
    }

    

    onChangeLocation = (e) => {
        if(e.length == 0) {
            this.state.selectedLocationName = ""
            this.state.selectedLocationId = ""
            return
        }  
        this.state.selectedLocationName = e[0].name
        this.state.selectedLocationId = e[0].id
    }

    onChangePayPeriod = (e) => {

        if(e.length == 0) {
            this.state.payPeriodSelectedId = ""
            this.state.selectedPayPeriodName = ""
            return
        } 

        this.state.payPeriodSelectedId = e[0].periodId
        this.state.selectedPayPeriodName = e[0].payPeriod
    }
    handleDownloadFile = () =>{
        const getParams = {
            "IpAddress"     :   "0.0.0.0", 
            "CompanyId"      :    this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "PayrollTypeId" :   "2",
            "FileName"      :   "BDO-CONVERTED.pay"
        }

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Accounting/DownloadPayrollFile", getParams,{'responseType': 'blob'})
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'BDO-CONVERTED.pay');
            document.body.appendChild(link);
            link.click();
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleSearchClick = async() => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0", 
            "CompanyId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "LocationId"    :   this.state.selectedLocationId,
            "PayPeriodId"   :   this.state.payPeriodSelectedId,
            "EmployeeId"    :   this.state.selectedEmployeeId,
            "StatusId"      :   "",
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetDTRMonitorings", params)
        .then(res => {
            const data = res.data
            
            data.dTRMonitorings.forEach(itm => {
                const paramdets = {
                    "IpAddress"     :   "0.0.0.0",
                    "CompanyId"      :   this.state.userinfo.clientId,
                    "UserId"        :   this.state.userinfo.userId,
                    "LocationId"    :   "",
                    "PayPeriodId"   :   itm.payPeriodId,
                    "EmployeeId"    :   itm.employeeId,
                    "StatusId"      :   "",
                }
                axios
                .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetDTRMonitoringDetails", paramdets)
                .then(res => {
                    const data1 = res.data
                    itm.details = data1.monitoringDetails
                })
                .catch(error=>{
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                        fade        :   true
                    })
                })
            });

            this.setState({isloading:false,dtrMonitoringListTable:data.dTRMonitorings,dtrMonitoringSummaryListTable:data.dTRSummaries})
            if(data.status=="0"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true,
                    DisableExport   :   true
                })
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })

    }
    
    setFile = (e) => {
        if(e.target.value.length>0)
            this.setState({ uploadfile: e.target.files[0]});
        else
            this.setState({ uploadfile: "" });
      }
    handleImportManningClick = () =>{
        const formData = new FormData();
        //formData.append('IpAddress', "0.0.0.0");
        //formData.append('ClientId', this.state.userinfo.clientId);
        //formData.append('UserId', this.state.userinfo.userId);
        formData.append('formFile', this.state.uploadfile);

        
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Manning/ImportBatchUpdate",  formData, {headers: {'Content-Type': 'multipart/form-data'}}
            )
            .then(res => {
                const data = res.data;
                if(data.status=="1")
                {
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true
                    });
                }
                else{
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }
    handleExportManningClick = () => {
        this.setState({isloading:true})

        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"      :    this.state.userinfo.clientId,
            "LocationId"      :   this.state.selectedLocationId,
            "UserId"        :   this.state.userinfo.userId
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/ExportBatchUpdate", getParams,{'responseType': 'blob'})
        .then(res => {
            //console.log(res.data)
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "EmployeeBatchUpload.xlsx");
            document.body.appendChild(link);
            link.click();
             this.setState({isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleExportClick = () => {
        this.setState({isloading:true})

        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"      :    this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "DTRMonitorings":   this.state.dtrMonitoringListTable.filter(x=>x.isSelected==true)
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/ExportDTRMonitorings", getParams,{'responseType': 'blob'})
        .then(res => {
            //console.log(res.data)
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "DTRMonitoring.xlsx");
            document.body.appendChild(link);
            link.click();
             this.setState({isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleExportDetailsClick = () => {
        this.setState({isloading:true})

        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"      :    this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "DTRMonitorings":   this.state.dtrMonitoringListTable.filter(x=>x.isSelected==true)
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/ExportDTRMonitoringDetails", getParams,{'responseType': 'blob'})
        .then(res => {
            ////console.log(res.data)
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "DTRMonitoringDetails.xlsx");
            document.body.appendChild(link);
            link.click();
             this.setState({isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleOnSelectAll = (isSelect, rows) => {
        let disable = true
        this.state.dtrMonitoringListTable.map(function(itm){
            itm.isSelected = isSelect
            if(itm.isSelected)
            disable=false
        })
        this.setState({DisableExport:disable})
        if (isSelect) {
            return this.state.dtrMonitoringListTable.map(r => r.id);
        }
        else{ 
            return []
        }
        
      }
    render() {
        const columnSummary =[
            {
                dataField   :   'status',
                text        :   'STATUS',
                editable    :    false,
                headerStyle : () => {
                    return { fontSize:'10px' };
                  },
                style:{fontSize:'10px'}
            },
            {
                dataField   :   'count',
                text        :   'COUNT',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign: 'center',fontSize:'10px' };
                  },
                style:{textAlign:'right',fontSize:'10px'}
            }
        ]
        const columnDTRMonitoring = [
            {
                dataField   :   'period',
                text        :   'PERIOD',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "15%",fontSize:'10px' };
                  },
                style:{textAlign:'center',fontSize:'10px'}
            },
            // {
            //     dataField   :   'client',
            //     text        :   'CLIENT NAME',
            //     editable    :    false,
            //     headerStyle : () => {
            //         return { width  : "25%",fontSize:'10px' };
            //       },
            //       style:{fontSize:'10px'}
            // },
            {
                dataField   :   'location',
                text        :   'LOCATION / BRANCH',
                editable    :    false,
                sort        :   true,
                headerStyle : () => {
                    return { width  : "25%",fontSize:'10px' };
                  },
                  style:{fontSize:'11px'}
            },
            {
                dataField   :   'employeeNo',
                text        :   'MEMBER NO',
                editable    :    false,
                sort        :   true,
                headerStyle : () => {
                    return { width  : "15%",fontSize:'10px', textAlign: 'center' };
                  },
                  style:{fontSize:'12',textAlign: 'center'}
            },
            {
                dataField   :   'employeeName',
                text        :   'MEMBER NAME',
                editable    :    false,
                sort        :   true,
                headerStyle : () => {
                    return { width  : "25%",fontSize:'10px' };
                  },
                  style:{fontSize:'10px'}
            },
            {
                dataField   :   'currentStatus',
                text        :   'WITH DTR',
                editable    :    false,
                sort        :   true,
                headerStyle : () => {
                    return { width  : "15%",fontSize:'10px', textAlign:'center' };
                  },
                  style:{fontSize:'10px', textAlign:'center'}
            },
            // {
            //     dataField   :   'currentApprover',
            //     text        :   'NEXT APPROVER',
            //     editable    :    false,
            //     headerStyle : () => {
            //         return { width  : "15%",fontSize:'10px' };
            //       },
            //       style:{fontSize:'10px'}
            // },

        ] 
        const detailCols = [
            {
                dataField   :   'scheduleDate',
                text        :   'DTR DATE',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "4%",fontSize:'10px',whiteSpace:'nowrap' };
                  },
                style:{width  : "4%",textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'submittedDate',
                text        :   'DATE SUBMITTED',
                editable    :    false,
                headerStyle : () => {
                    return {width:"10%",fontSize:'10px',textAlign:'center',whiteSpace:'nowrap' };
                  },
                  style:{width:"10%",fontSize:'10px',textAlign:'center'}
            },
            {
                dataField   :   'dateReturnedByTL',
                text        :   'DATE RETURNED',
                editable    :    false,
                headerStyle : () => {
                    return { width:"10%",fontSize:'10px',textAlign:'center',whiteSpace:'nowrap' };
                  },
                  style:{width:"10%",fontSize:'10px',textAlign:'center'}
            },
            {
                dataField   :   'dateApprovedByTL',
                text        :   'DATE APPROVED (TL)',
                editable    :    false,
                headerStyle : () => {
                    return { width:"11%",fontSize:'10px',textAlign:'center',whiteSpace:'nowrap' };
                  },
                  style:{width:"11%",fontSize:'10px',textAlign:'center'}
            },
            {
                dataField   :   'dateReturnedByCOOR',
                text        :   'DATE RETURNED',
                editable    :    false,
                headerStyle : () => {
                    return { width:"10%",fontSize:'10px',textAlign:'center',whiteSpace:'nowrap' };
                  },
                  style:{width:"10%",fontSize:'10px',textAlign:'center'}
            },
            {
                dataField   :   'dateApprovedByCOOR',
                text        :   'DATE APPROVED (COOR)',
                editable    :    false,
                headerStyle : () => {
                    return { width:"13%",fontSize:'10px',textAlign:'center',whiteSpace:'nowrap' };
                  },
                  style:{width:"13%",fontSize:'10px',textAlign:'center'}
            },
            {
                dataField   :   'dateReturnedByTK',
                text        :   'DATE RETURNED',
                editable    :    false,
                headerStyle : () => {
                    return { width:"10%",fontSize:'10px',textAlign:'center',whiteSpace:'nowrap' };
                  },
                  style:{width:"10%",fontSize:'10px',textAlign:'center'}
            },
            {
                dataField   :   'dateProcessedByTK',
                text        :   'DATE PROCESSED (TK)',
                editable    :    false,
                headerStyle : () => {
                    return { width:"12%",fontSize:'10px',textAlign:'center',whiteSpace:'nowrap' };
                  },
                  style:{width:"12%",fontSize:'10px',textAlign:'center'}
            },
            {
                dataField   :   'dateApprovedByTK',
                text        :   'DATE FINALIZED',
                editable    :    false,
                headerStyle : () => {
                    return { width:"10%",fontSize:'10px',textAlign:'center',whiteSpace:'nowrap' };
                  },
                  style:{width:"10%",fontSize:'10px',textAlign:'center'}
            },
            {
                dataField   :   'payrollProcessed',
                text        :   'PAYROLL PROCESSED',
                editable    :    false,
                headerStyle : () => {
                    return {width:"10%",fontSize:'10px',textAlign:'center',whiteSpace:'nowrap' };
                  },
                  style:{width:"10%",fontSize:'10px',textAlign:'center'}
            },
            {
                dataField   :   'status',
                text        :   'STATUS',
                editable    :    false,
                headerStyle : () => {
                    return {width:"10%",fontSize:'10px',textAlign:'left',whiteSpace:'nowrap' };
                  },
                  style:{width:"10%",fontSize:'10px',textAlign:'left'}
            },
            {
                dataField   :   'approver',
                text        :   'NEXT APPROVER',
                editable    :    false,
                headerStyle : () => {
                    return {width:"10%",fontSize:'10px',textAlign:'left',whiteSpace:'nowrap' };
                  },
                  style:{width:"10%",fontSize:'10px',textAlign:'left',whiteSpace:'nowrap'}
            },
        ]

        const { ExportCSVButton } = CSVExport;
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                row.isSelected = isSelect
                this.state.dtrMonitoringListTable.map(function(itm){
                    if(itm.isSelected)
                    disable=false
                })
                this.setState({DisableExport:disable})
            },
            onSelectAll: this.handleOnSelectAll
            /*onSelectAll: (isSelect, rows) => {
                let disable = true
                if (isSelect) {
                    return rows.map(row => row.id);
                }
                this.state.dtrMonitoringListTable.map(function(itm){
                    itm.isSelected = isSelect
                    if(itm.isSelected)
                    disable=false
                })
                this.setState({DisableExport:disable})
            }*/
        };
        const selectRowDetail = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        const expandRow = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "id"
                    data = { row.details }
                    columns = { detailCols }
                    rowClasses="noser-table-row-class"
                    striped
                    expandRow
                    selectRow = { selectRowDetail }
                    wrapperClasses="table-responsive"
                    noDataIndication={ () => <div>No record found.</div> }
                /> 
            ),
            showExpandColumn: true,
          };
        return (
            <div>

                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>DTR Monitoring</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    {/* <Col sm={6}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClientList}
                                            options={this.state.clientListAutocomplete}
                                            placeholder="Select Company"
                                            style={{fontSize:'10px'}}
                                        />
                                    </Col> */}
                                    <Col sm={6}>
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.onChangeEmployeesList}
                                            options={this.state.employeeLisAutocomplete}
                                            placeholder="Select Employee"
                                            autocomplete="false"
                                            style={{fontSize:'10px'}}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={6}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            /* onChange={selectedClientLocation => this.setState({ selectedClientLocation })} */
                                            onChange={this.onChangeLocation}
                                            options={this.state.clientLocationListAutocomplete}
                                            placeholder="Select Branch"
                                            style={{fontSize:'10px'}}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Typeahead
                                            labelKey='payPeriod'
                                            id="basic-example"
                                            onChange={this.onChangePayPeriod}
                                            options={this.state.periodListAutocomplete}
                                            placeholder="Select Pay Period"
                                            style={{fontSize:'10px'}}
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar sm={12}>
                                    <Button style={{fontSize:'10px'}} variant="success" className="ml-auto" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>
                                <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.dtrMonitoringListTable }
                                    columns = { columnDTRMonitoring }
                                    //exportCSV={{fileName: "DTRMonitoring.csv",}}
                                    >
                                    {
                                        props => (
                                                <div className="mt-2">
                                                    <hr />
                                                    {/* <ExportCSVButton style={{fontSize:'10px'}} className="btn btn-success" { ...props.csvProps }>Export CSV!!</ExportCSVButton> */}
                                                    <ButtonToolbar sm={12}>
                                                        <Button disabled={this.state.DisableExport} style={{fontSize:'10px',marginRight:'2px'}} variant="success" onClick = { this.handleExportClick }>
                                                            EXPORT MONITORING SUMMARIZED
                                                        </Button>
                                                        <Button disabled={this.state.DisableExport} style={{fontSize:'10px'}} variant="success" onClick = { this.handleExportDetailsClick }>
                                                            EXPORT MONITORING DETAILED
                                                        </Button>
                                                    </ButtonToolbar>
                                                    <Card.Header className="mt-1">DTR MONITORING LIST</Card.Header>
                                                    <BootstrapTable
                                                        rowClasses="noser-table-row-class"
                                                        striped
                                                        hover
                                                        condensed
                                                        pagination={ paginationFactory({sizePerPage:20,hideSizePerPage:true,hidePageListOnlyOnePage:true})}
                                                        keyField = "id"
                                                        data = { this.state.dtrMonitoringListTable }
                                                        columns = { columnDTRMonitoring}
                                                        selectRow = { selectRow }
                                                        cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                                        expandRow={ expandRow }
                                                        noDataIndication={ () => <div>No record found.</div> }
                                                    />
                                                </div>
                                    )}
                                </ToolkitProvider>
                                <div className="mt-2">
                                    <BootstrapTable
                                        rowClasses="noser-table-row-class"
                                        striped
                                        hover
                                        condensed
                                        /* pagination={ paginationFactory(options) } */
                                        keyField = "id"
                                        data = { this.state.dtrMonitoringSummaryListTable }
                                        columns = { columnSummary}
                                        /* selectRow = { selectRow } */
                                        cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                        /* rowEvents={ rowEvents } */

                                    />
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div>
        );
    }
}

export default DTRStatusMonitoring;
