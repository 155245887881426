import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer
} 
from '../../noser-hris-component';

class DeploymentMonitorings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,      
            opendocModal    :   false,
            opentrainModal  :   false,
            
            clientId        :   "",
            locationId      :   "",
            positionId      :   "",
            profileId       :   "",
            typeId          :   "",

            clientDDL       :   [],
            positionDDL     :   [],
            employeeDDL     :   [],
            requestTypeDDL  :   [],
            monitoringGRID  :   [],
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients();
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL : data.clients})
            this.GetPositions()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetPositions = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions", params)
        .then(res => {
            const data = res.data;
            this.setState({positionDDL : data.positions})
            this.GetEmployees()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetEmployees = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "LocationId":this.state.locationId,
            "PositionId":this.state.positionId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeList", params)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({employeeDDL : data.employees})
            this.GetMonitoringTypes()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetMonitoringTypes = async() => {
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Code":"0028"
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences", params)
        .then(res => {
            const data = res.data;
            this.setState({requestTypeDDL : data.dataReferences.filter(x=>x.id!=="8")})  
            this.GetMonitorings()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    } 
    GetMonitorings = async() => {
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "PositionId":this.state.positionId,
            "ProfileId":this.state.profileId,
            "RequestTypeId":this.state.typeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetManningTrainingRequestMonitorings", params)
        .then(res => {
            const data = res.data;
            this.setState({monitoringGRID : data.requestMonitorings, isloading:false})  
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }  
     
    handleChangeClient = async(e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:false});
        if(e.length===0)
            this.state.clientId= ''
        else
            this.state.clientId= e[0].id
            const params = {
                "IpAddress":"0.0.0.0",
                "ClientId":this.state.clientId,
                "UserId":this.state.userinfo.userId,
                "LocationId":this.state.locationId,
                "PositionId":this.state.positionId,
            }
            await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeList", params)
            .then(res => {
                const data = res.data;
                this.setState({employeeDDL : data.employees, isloading:false})
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }
    handleChangePosition = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.positionId= ''
            return
        }
        this.state.positionId= e[0].id
    }
    handleChangeEmployee = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.profileId= ''
            return
        }
        this.state.profileId= e[0].profileId
    }
    handleChangeType = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.typeId= ''
            return
        }
        this.state.typeId= e[0].id
    }
    handleSearchClick = event => {
        this.setState({isloading:true})
        this.GetMonitorings()
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(e)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.handleSearchClick()
    }
    render() { 
        
        const cols = [
        {
            dataField: 'submittedDate',
            text: 'DATE',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'11%',whiteSpace:'nowrap'}
            },
            style:{textAlign:'center',width:'11%',whiteSpace:'nowrap'}
        },
        {
            dataField: 'requestType',
            text: 'REQUEST TYPE',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'11%',whiteSpace:'nowrap'}
            },
            style:{textAlign:'center',width:'11%',whiteSpace:'nowrap'}
        },
        /* {
            dataField: 'client',
            text: 'CLIENT',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'10%',whiteSpace:'nowrap'}
            },
            style:{textAlign:'left',width:'10%',whiteSpace:'nowrap'}
        }, */
        {
            dataField: 'employeeName',
            text: 'EMPLOYEE NAME',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'11%',whiteSpace:'nowrap'}
            },
            style:{textAlign:'left',width:'11%',whiteSpace:'nowrap'}
        },
        {
            dataField: 'position',
            text: 'POSITION',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'11%',whiteSpace:'nowrap'}
            },
            style:{textAlign:'left',width:'11%',whiteSpace:'nowrap'}
        },
        {
            dataField: 'location',
            text: 'LOCATION',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'11%',whiteSpace:'nowrap'}
            },
            style:{textAlign:'left',width:'11%',whiteSpace:'nowrap'}
        },
        {
            dataField: 'deploymentDate',
            text: 'DEPLOYMENT DATE',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'11%',whiteSpace:'nowrap'}
            },
            style:{textAlign:'center',width:'11%',whiteSpace:'nowrap'}
        },
        {
            dataField: 'scheduleName',
            text: 'SCHEDULE',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'11%',whiteSpace:'nowrap'}
            },
            style:{textAlign:'center',width:'11%',whiteSpace:'nowrap'}
        },
        {
            dataField: 'batchNo',
            text: 'BATCH NO',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'11%',whiteSpace:'nowrap'}
            },
            style:{textAlign:'center',width:'11%',whiteSpace:'nowrap'}
        },
        {
            dataField: 'status',
            text: 'STATUS',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'11%',whiteSpace:'nowrap'}
            },
            style:{textAlign:'center',width:'11%',whiteSpace:'nowrap'}
        }] 
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: false,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>DEPLOYMENT MONITORING</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                {/* <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                       CLIENT NAME :
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientDDL}
                                        />
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row}>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        POSITION :
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangePosition}
                                            options={this.state.positionDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE NAME :
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.handleChangeEmployee}
                                            options={this.state.employeeDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        MONITORING TYPE :
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeType}
                                            options={this.state.requestTypeDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar sm={12} className="mt-1">
                                    <Button variant="primary" className="ml-auto" onClick={this.handleSearchClick}>
                                        Search
                                    </Button>
                                </ButtonToolbar>
                                <Card.Header className="mt-5">EMPLOYEE LIST</Card.Header>
                                <div >
                                    <BootstrapTable
                                        keyField = "id"
                                        data = {this.state.monitoringGRID }
                                        columns = { cols }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        rowClasses="noser-table-row-class no-checkbox"
                                        striped
                                        hover
                                        condensed
                                        selectRow={selectRow}
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                    />
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                    
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }

}

export  default DeploymentMonitorings
