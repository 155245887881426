import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import InclusionCreate from './InclusionCreate';

class Inclusion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            roleDDL         :   [],
            roleList        :   [],
            roleName        :   '',
            departmentDDL   :   [],
            disableSaveBtn  :   true     ,

            selected: [],
            selectedInclusionType:"",
            selectedInclusionTypeId: "",
            inclusionTypeList: [],
            inclusionList: [],
            inclusionListGrid: [],
            newInclusionList: [],
             isLoading:true,
            AlertType:"",
            Show:false,
            Message:"",
            Color:"",
            Fade:true,
            userinfo:[],
            isGridDataChanged: false
        }
    } 
    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetInclusionType()
        this.GetInclusion()
        sleep(1000).then(() => {
            this.setState({isLoading:false})})
    };

    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    };

    handleModalClose = (e) =>{
        this.setState({openModal:false})
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.handleSearchClick();
        sleep(1000).then(() => {
        this.setState({isLoading:false,
            alerttype   :   "",
            isshow      :   false,
            color       :   "success",
            message     :   "",
            fade        :   false
        })})
    };

    GetInclusionType() {
        const typeParams = {

            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "Name":this.state.selectedInclusion
        };
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetInclusionTypes", typeParams
            )
            .then(res => {
                const data = res.data;
                console.log(res.data)
                this.setState({ inclusionTypeList: data.inclusionTypes})
            })
    };

    GetInclusion() {

        const inclusionParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "TypeId":this.state.selectedInclusionType, 
            "Name":this.state.selectedInclusion 
        };
        axios.post(
            AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetInclusions", inclusionParams
        )
        .then(res => {
            const data = res.data;
            console.log(res.data)
            this.setState({inclusionList: data.inclusions});
        })
    };

    handleCoverChangeInclusion = (e) => {
        if (e.lenght > 0) {
            this.state.selectedInclusion = e[0].name
        }else{
            this.state.selectedInclusion = ""
        }
        
    };

    handleCoverChangeInclusionType = (e) => {
        if (e.length > 0) {
            this.state.selectedInclusionType = e[0].name
            this.state.selectedInclusionTypeId = e[0].id
        } else {
            this.state.selectedInclusionType = ""
            this.state.selectedInclusionTypeId = ""
        }
       
    };

    handleSearchClick = event => {
        this.setState({
            inclusionListGrid: [],
            newInclusionList: []
        });
        const inclusionParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "TypeId":this.state.selectedInclusionTypeId,
            "Name": this.state.selectedInclusion 
        };
        axios
        .post(
            AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetInclusions", inclusionParams
        )
        .then(res => {
            const data = res.data;
            console.log(inclusionParams)
            this.setState({ inclusionListGrid: data.inclusions});
            var alertType = (data.status=="1") ? "success" : "danger"
       this.setState(
                    {
                        isLoading:false,
                        AlertType:"Success!",
                        show:true,
                        Color:alertType,
                        Message:data.message ,
                        Fade:true
                    });
             })
             .catch(error=>{
                this.setState(
                {
                    isLoading:false,
                    AlertType:"Error! ",
                    Show:true,
                    Color:"danger",
                    Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    Fade:true
                })
            })

    };

    refreshData(){
        this.setState({
            inclusionListGrid: [],
            newInclusionList: []
        });
        const inclusionParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "TypeId":this.state.selectedInclusionTypeId,
            "Name": ""
        };
        axios
        .post(
            AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetInclusions", inclusionParams
        )
        .then(res => {
            const data = res.data;
            console.log(inclusionParams)
            this.setState({ inclusionListGrid: data.inclusions});
            var alertType = (data.status=="1") ? "success" : "danger"
       this.setState(
                    {
                        isLoading:false,
                        AlertType:"Success!",
                        show:true,
                        Color:alertType,
                        Message:data.message ,
                        Fade:true
                    });
             })
             .catch(error=>{
                this.setState(
                {
                    isLoading:false,
                    AlertType:"Error! ",
                    Show:true,
                    Color:"danger",
                    Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    Fade:true
                })
            })
    };

handleSaveClick = event => {
    const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
    
        this.setState({newInclusionList: [],isLoading:true})
        for (let i = 0; i < this.state.inclusionListGrid.length; i++) {
            if (this.state.inclusionListGrid[i]["isModified"] == 1) {
                const obj = {
                    Id: this.state.inclusionListGrid[i]["id"],
                    TypeId:this.state.inclusionListGrid[i]["typeId"],
                    Name: this.state.inclusionListGrid[i]["name"],
                    IsDeleted: this.state.inclusionListGrid[i]["isDeleted"].toString()
                };

                this.state.newInclusionList.push(obj);
            }
        }
        const inclusionParams = {
                "IpAddress":"0.0.0.0",
                "CompanyId":this.state.userinfo.companyId,
                "UserId":this.state.userinfo.userId,
                "Inclusions":this.state.newInclusionList
       };
        axios
        .post(
            AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditInclusion", inclusionParams
        )
        .then(res => {
            console.log(inclusionParams)
            const data = res.data;
            console.log(data)
            sleep(1000).then(() => {
                this.setState({isLoading:false})})
            this.refreshData();
                var alertType = (data.status=="1") ? "success" : "danger"
                this.setState(
                    {
                        isloading:false,
                        alerttype:"Success!",
                        isshow:true,
                        color:alertType,
                        message:data.message ,
                        fade:true
                    });
            })
            .catch(error=>{
                this.setState(
                {
                    isloading:false,
                    alerttype:"Error! ",
                    isshow:true,
                    Color:"danger",
                    message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade:true
                })
            })
    };

    LeavePageWithoutSavingChanges() {
        const isChanged=false
        for (let i = 0; i < this.state.inclusionListGrid.length; i++) {
            if (this.state.inclusionListGrid[i]["isModified"] == 1) {
                this.setState({isGridDataChanged: true})
                isChanged=true
                break;
            }
        }
        return isChanged
    };

    GridDataModified(oldValue, newValue,inclusionid, column) {
        this.state.inclusionListGrid.map(function(item,i){
            if(item.id===inclusionid)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    };

    

    

    render(){
        const columntype = [
           
            {
                dataField: 'typeName',
                text: 'Inclusion Type',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'50%' }},
                style:{textAlign:'left',width:'50%'},
            },
            {
                dataField: 'name',
                text: 'Inclusion ',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'50%' }},
                style:{textAlign:'left',width:'50%'},
            }
        ]
        
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.inclusionListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
             }
        };
        
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              //alert(e.row);
            }
        };

        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header> Inclusion</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                 <Form.Row>
                                    <Col sm={12} >
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleCoverChangeInclusionType}
                                            options={this.state.inclusionTypeList}
                                            placeholder="Select Inclusion Type"
                                        />
                                    </Col>
                                </Form.Row>&nbsp;&nbsp;
                                <Form.Row>
                                    <Col sm={12} >
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange = { this.handleCoverChangeInclusion }
                                            options={this.state.inclusionList}
                                            placeholder="Select Inclusion"
                                        />
                                    </Col>
                                </Form.Row>&nbsp;&nbsp;
                                <Form.Row>
                                    <Form.Group as={Col} sm={12} controlId="formHorizontalEmail">
                                        <div className="mt-1">
                                            
                                            <ButtonToolbar>
                                                <Button style={{minWidth:'60px'}} className='ml-auto' variant="primary"  onClick={ this.handleSearchClick } >
                                                    search
                                                </Button>&nbsp;&nbsp;
                                                <Button style={{minWidth:'60px'}} variant="success" onClick={this.handleModalShow}>
                                                    Create
                                                </Button>&nbsp;&nbsp;
                                               
                                            </ButtonToolbar>
                                        </div>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                        <Card.Header>Record</Card.Header>
                                        <div className="mt-1">
                                            <BootstrapTable
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                keyField = "id"
                                                data = { this.state.inclusionListGrid}
                                                columns = { columntype}
                                                selectRow = { selectRow }
                                                cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.GridDataModified(oldValue, newValue, row.id, column)
                                                        }
                                                    })
                                                }
                                                rowEvents={ rowEvents }

                                            />
                                    <ButtonToolbar sm={12}>
                                        <Button variant="success" className="ml-auto" onClick={this.handleSaveClick} style={{minWidth:'60px'}}>
                                            Save
                                        </Button>&nbsp;&nbsp;
                                        <NavLink to="/home">
                                            <Button variant="danger" href="/banner" style={{minWidth:'60px'}}>
                                                Close
                                            </Button>&nbsp;&nbsp;
                                        </NavLink>
                                    </ButtonToolbar>
                                </div>
                                        
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
                <InclusionCreate 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div>
        )
    }
}

export default Inclusion;