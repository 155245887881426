import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Container, Modal, DatePicker,Dropdown, DropdownButton, props, Redirect
} 
from '../../../noser-hris-component';


class AdminModal extends Component {
    constructor(props) {
        super(props)
        this.state ={
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            date         : "",
            remarks  : "",
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }
    componentDidMount() {
        this.setState({isloading:true})
        this.props.onRefModal(this)
    }

    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }

    initialize = (e) => {
         this.setState({
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            details     : e,
            headerId    : e.id,
            createdBy   : e.createdBy,
            employeeName: e.employeeName,

         })
         console.log("initialize")
         console.log(e)
         if(e.natureId === "1" || e.natureId === "3"){
            this.state.headerLabel = "ACKNOWLEDGEMENT OF APPROVE/REJECT PAYCARD APPLICATION & PAYCARD KIT BY FINANCE"
         } else {
            this.state.headerLabel = "ACKNOWLEDGEMENT OF APPROVE/REJECT PAYCARD APPLICATION / PAYCARD KIT"
         }
         
         if(e.statusId === "4"){
            this.setState({ 
                disabledCardOneReject : true,
                disabledCardOneApproved : true,
                disabledCardTwoReceive : true,
                disabledCardTwoReject : true,
                disabledCardTwoTransmit : true,
                disabledCardTwo         : true,
                disabledCardThree   : true,
                disabledCardFour    : true,
            })
         }
         
         if(e.statusId === "14"){
            this.setState({ 
                disabledCardOneReceive : true,
                disabledCardOneReject : true,
                disabledCardOneApproved : true,
                disabledCardTwoReceive : false,
                disabledCardTwoReject : true,
                disabledCardTwoTransmit : true,
                disabledCardThree   : true,
                disabledCardFour    : true,
            })
         }
         
         if(e.statusId === "16"){
            this.setState({ 
                disabledCardOneReceive : true,
                disabledCardOneReject : true,
                disabledCardOneApproved : true,
                disabledCardTwoReceive : false,
                disabledCardTwoReject : true,
                disabledCardTwoTransmit : true,
                disabledCardThree   : true,
                disabledCardFour    : true,
            })
         }
         
         if(e.statusId === "5"){
            this.setState({ 
                disabledCardOneReject : false,
                disabledCardOneApproved : false,
                disabledCardOneReceive : true,
                disabledCardTwo     : true,
                disabledCardThree   : true,
                disabledCardFour    : true,
            })
         }
         
         if(e.statusId === "6"){
            this.setState({ 
                disabledCardOneReject : true,
                disabledCardOneApproved : true,
                disabledCardTwo     : true,
                disabledCardThree   : true,
                disabledCardFour    : true,
            })
         }
         
         if(e.statusId === "16"){
            this.setState({ 
                disabledCardOne     : true,
                disabledCardThree   : true,
                disabledCardFour    : true,
                disabledCardFive    : true
            })
         }
         
         if(e.statusId === "11"){
            this.setState({ 
                disabledCardOneReject : true,
                disabledCardOneApproved : true,
                disabledCardOneReceive : true,
                disabledCardTwoReject : true,
                disabledCardTwoTransmit : true,
                disabledCardOne     : true,
                disabledCardThree   : true,
                disabledCardFour    : true,
                disabledCardFive    : true
            })
         }
         
         if(e.statusId === "17"){
            this.setState({ 
                disabledCardOneReject : true,
                disabledCardOneApproved : true,
                disabledCardOneReceive : true,
                disabledCardTwoReceive : true,
                disabledCardTwoReject : false,
                disabledCardTwoTransmit : false,
                disabledCardOne     : true,
                disabledCardThree   : true,
                disabledCardFour    : true,
                disabledCardFive    : true
            })
         }


         if(e.statusId === "7" || e.statusId === "18"){
            this.setState({
                disabledCardOne     : true,
                disabledCardThree   : true,
                disabledCardFour    : true,
            })
         }

         if(e.statusId === "19"){
            this.setState({
                disabledCardOne     : true,
                disabledCardTwo     : true,
                disabledCardFour    : true,
                disabledCardFive    : true
            })
         }

         if(e.statusId === "23"){
            this.setState({
                disabledCardOneReject : true,
                disabledCardOneApproved : true,
                disabledCardOneReceive : true,
                disabledCardTwoReceive : true,
                disabledCardTwoReject : true,
                disabledCardTwoTransmit : true,
                disabledCardOne     : true,
                disabledCardTwo     : true,
                disabledCardThree   : false,
                disabledCardFour    : true,
                disabledCardFive    : true
            })
         }

         if(e.statusId === "24"){
            this.setState({
                disabledCardOneReject : true,
                disabledCardOneApproved : true,
                disabledCardOneReceive : true,
                disabledCardTwoReceive : true,
                disabledCardTwoReject : true,
                disabledCardTwoTransmit : true,
                disabledCardOne     : true,
                disabledCardTwo     : true,
                disabledCardThree   : true,
                disabledCardFour    : false,
                disabledCardFive    : true
            })
         }

    }

    onModalClose = () => {
        this.props.onHide();
    }
 
    handleChangeDate = date => {
        this.setState({
            date: date,
            isshow:false,
        })
    }

    handleChangeRemarks = (e) => {
        this.setState({ remarks: e.target.value.toUpperCase(),isshow:false });
    }
    
    handleRejectClick = () => {
        this.setState({isloading:true})
        if(this.state.remarks === ""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please input remarks!",
                fade        :   true
            })
            return
        }

        const areaParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "HeaderId"      :   this.state.headerId,
            "StatusId"      :   "6",
            "RequestDate"   :   moment(this.state.date).format('MM/DD/YYYY'),
            "CreatedBy"     :   this.state.createdBy,
            "Remarks"       :   this.state.remarks,
         };

        console.log("Submit Params")
        console.log(areaParams)

        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Paycard/AddApplicationStatus",  areaParams)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
            this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })

    }
    
    handleSubmitClick = (statusId) => {
        this.setState({isloading:true})

        const areaParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "HeaderId"      :   this.state.headerId,
            "StatusId"      :   statusId,
            "RequestDate"   :   moment(this.state.date).format('MM/DD/YYYY'),
            "CreatedBy"     :   this.state.createdBy,
            "Remarks"       :   this.state.remarks,
         };

        console.log("Submit Params")
        console.log(areaParams)

        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Paycard/AddApplicationStatus",  areaParams)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
            this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })

    }

    handleClickDetails = () => {
        console.log("details")
        console.log(this.state.details)
        sessionStorage.setItem("_paycardviewdetails", JSON.stringify(this.state.details))
        this.setState({navigate:true})
    }



    render() {
        const {navigate} = this.state;
        if (navigate) {
            return <Redirect to="/paycardviewdetails" push={true} />;
        } 
        return(
        
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                 >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        RECEIVING / TRANSMITTAL / APPROVAL
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="name"
                                    value={this.state.employeeName}
                                    disabled 
                                /> 
                            </Form.Group>
                        </Form.Row>
                        <Card className="mt-5">
                            <Card.Header>ADMIN</Card.Header>
                            <Card.Body className="mt-3">
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>OFFICE IN-CHARGE</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.userinfo.fullName}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>POSTION</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.userinfo.roleName}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                        
                        <Card className="mt-5">
                            <Card.Header>Acknowledgement of Transmitted PayCard Application by AMT</Card.Header>
                            <Card.Body className="mt-3">
                                <Form.Row>
                                    <Form.Group as={Col} sm="3"  controlId="formGridEmail">
                                        <DatePicker
                                            ref='date'
                                            selected={this.state.date}
                                            onChange={this.handleChangeDate}
                                            minDate={this.minDate}
                                            value={this.props.date}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                            placeholderText="Select Date" 
                                            disabled={this.state.disabledCardOne}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Remarks" 
                                            onChange={this.handleChangeRemarks} 
                                            value={this.state.remarks}
                                            autoComplete="off" 
                                            disabled={this.state.disabledCardOne}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <ButtonToolbar>
                                            <Button disabled={this.state.disabledCardOneReceive} variant="success" className="ml-auto noser-button" onClick={ () => this.handleSubmitClick('5')} >Receive</Button>&nbsp;&nbsp;
                                            <Button disabled={this.state.disabledCardOneReject} variant="success" className="noser-button-mr1" onClick={ this.handleRejectClick} >Reject</Button>&nbsp;&nbsp;
                                            <Button disabled={this.state.disabledCardOneApproved} variant="success" className="noser-button" onClick={ () => this.handleSubmitClick('7')} >Approved</Button>
                                        </ButtonToolbar> 
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                        
                        <Card className="mt-5">
                            <Card.Header>{this.state.headerLabel}</Card.Header>
                            <Card.Body className="mt-3">
                                <Form.Row>
                                    <Form.Group as={Col} sm="3"  controlId="formGridEmail">
                                        <DatePicker
                                            ref='date'
                                            selected={this.state.date}
                                            onChange={this.handleChangeDate}
                                            minDate={this.minDate}
                                            value={this.props.date}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                            placeholderText="Select Date" 
                                            disabled={this.state.disabledCardTwo}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <ButtonToolbar>
                                            <Button disabled={this.state.disabledCardTwoReceive} variant="success" className=" noser-button" onClick={ () => this.handleSubmitClick('17')} >Receive</Button>&nbsp;&nbsp;
                                            <Button disabled={this.state.disabledCardTwoReject} variant="success" className="noser-button-mr1" onClick={ () => this.handleSubmitClick('18')} >Reject</Button>&nbsp;&nbsp;
                                            <Button disabled={this.state.disabledCardTwoTransmit} variant="success" className="noser-button" onClick={ () => this.handleSubmitClick('19')} >TRANSMIT</Button>
                                        </ButtonToolbar> 
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                        
                        <Card className="mt-5">
                            <Card.Header>ACKNOWLEDGEMENT OF EMPLOYEE RECEIVED / NOT RECEIVED APPROVED PAYCARD</Card.Header>
                            <Card.Body className="mt-3">
                                <Form.Row>
                                    <Form.Group as={Col} sm="3"  controlId="formGridEmail">
                                        <DatePicker
                                            ref='date'
                                            selected={this.state.date}
                                            onChange={this.handleChangeDate}
                                            minDate={this.minDate}
                                            value={this.props.date}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                            placeholderText="Select Date" 
                                            disabled={this.state.disabledCardThree}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <ButtonToolbar>
                                            <Button disabled={this.state.disabledCardThree} variant="success" className=" noser-button" onClick={ () => this.handleSubmitClick('24')} >Receive</Button>
                                        </ButtonToolbar> 
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                        
                        <Card className="mt-5">
                            <Card.Header>TRANSMITTAL OF EMPLOYEE RECEIVED / NOT RECEIVED APPROVED PAYCARD</Card.Header>
                            <Card.Body className="mt-3">
                                <Form.Row>
                                    <Form.Group as={Col} sm="3"  controlId="formGridEmail">
                                        <DatePicker
                                            ref='date'
                                            selected={this.state.date}
                                            onChange={this.handleChangeDate}
                                            minDate={this.minDate}
                                            value={this.props.date}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                            placeholderText="Select Date" 
                                            disabled={this.state.disabledCardFour}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <ButtonToolbar>
                                            <Button disabled={this.state.disabledCardFour} variant="success" className=" noser-button" onClick={ () => this.handleSubmitClick('25')} >TRANSMIT</Button>
                                        </ButtonToolbar> 
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button className="ml-auto" variant="primary" onClick={this.handleClickDetails}>
                            VIEW APPLICATION DETAILS
                        </Button>&nbsp;&nbsp;
                        <Button variant="danger" onClick={this.onModalClose}>
                            Close
                        </Button>
                    </ButtonToolbar>
            </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }

}
export  default AdminModal