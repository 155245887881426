import { Label } from 'reactstrap';
import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Redirect, Modal,
} 
from '../../noser-hris-component';
import './SignUpLogIns.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { FormControl } from 'react-bootstrap';
import './SignUpLogIns.css'
import SignUpLogInResetPassword from './SignUpLogInResetPassword'
import {CipherString} from '../../noser-sec'



class SignUpLogInForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.module = React.createRef();
        this.submodule = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),

            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            disablebtn      :   true,
            clientId        :   "",
            trainingId      :   "",
            statusId        :   "",

            SignUpData      : [],
            lastname        : "",
            firstname       : "",
            middlename      : "",
            suffix          : "",
            email           : "",
            mobileNo        : "",
            username        : "",
            password        : "",
            isCheck         : false,
            disableSaveBtn : true,

            LOGINusername   : "",
            LOGINpassword   : "",
            otp : "",
            NewPassword: "",
            ConfirmPassword: "",

            ishowemail:true,
            ishowpassword:false
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            email        :   '',
            password :   ''
        })
    }
    handleSubmit = async(e) =>{
        this.setState({isloading:true,})
        const param = {
            "CompanyId":"1",
            "EmailAddress":this.state.email
        }
        
        await axios
            .post("http://167.172.68.202:8081/Registration/VerifyRegistration",  param)
            .then(res => {
                console.log(res.data)
                const data = res.data;
                console.log("data referenceCod")
                console.log(data.referenceCode)
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true
                });
                sessionStorage.setItem("applicantEmail", (this.state.email))
                sessionStorage.setItem("referenceCode", (data.referenceCode))
                sessionStorage.setItem("resdata", JSON.stringify(res.data))
                
                if(res.data.status=="1"){
                    console.log("if(res.data.status==1")
                    //this.handleModalClose2()
                    //this.handleModalShow2()
                    //localStorage.setItem()
                    this.setState({ ishowemail:false,ishowpassword:true,resdata:res.data})
                    sessionStorage.setItem("applicantEmail", (this.state.email))
                    sessionStorage.setItem("referenceCode", (data.referenceCode))
                    sessionStorage.setItem("resdata", JSON.stringify(res.data))
                    

                }else{
                    console.log("error modal 2nd")
                }
                

            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }
    handleModalClose = () => {
        this.props.onHide();            
    };
    handleChange = (e) =>{
        this.setState({[e.target.name]: e.target.value})
    };

    /* handleModalShow2 = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
        this.handleModalClose()
    }; */
    handleModalClose2 = (e) =>{
        this.setState({openModal:false})
        
    };
    handleSubmitpass = async(e) =>{
        this.setState({isloading:true})
        console.log("otp")

        console.log(this.state.otp)
        console.log("referenceCode")
        console.log(this.state.referenceCode)
        const param = {
            "CompanyId":"1",
            "EmailAddress":this.state.email,
            "NewPassword":CipherString(this.state.NewPassword), //--> encrypted
            "ConfirmPassword":CipherString(this.state.ConfirmPassword), //--> encrypted
            "OTPCode":this.state.otp,
            "ReferenceCode":this.state.resdata.referenceCode
        }
        
        await axios
            .post("http://167.172.68.202:8081/Registration/ResetPassword",  param)
            .then(res => {
                console.log(res.data)
                const data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true
                });


            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }

    
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            > 
            
            <div style={{height:"500px",}}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        FORGET PASSWORD
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <div className={this.state.ishowemail ? 'display-block' : 'display-none'}>
                            <Form.Row>
                                <div className='FORGETPASSWORD2  mb-3' style={{marginTop:"150px"}}>
                                    <h4 >Forgot Password?</h4>
                                </div>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm={12} >
                                    <Form.Control 
                                        name='email'
                                        type="text" 
                                        placeholder="&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Enter Email Address" 
                                        onChange={this.handleChange} 
                                        autoComplete="off" 
                                        value={this.state.email}
                                        style={{width:"70%",margin:"auto"}}
                                        className='mb-5 mt-3 no-text-transform'
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Button variant="success" style={{minWidth:'60px',marginRight:'1pt',margin:"auto"}} className="mt-5" onClick = { this.handleSubmit }>OK</Button>
                            </Form.Row>
                        </div>
                        <div className={this.state.ishowpassword ? 'display-block' : 'display-none'}>
                            <Form.Row>
                                <div className='FORGETPASSWORD2  mb-3' style={{marginTop:"100px"}}>
                                <h4 >Reset Password?</h4>
                                </div>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm={12} >
                                    <Form.Control 
                                        name='otp'
                                        type="text" 
                                        placeholder="&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Verification Password" 
                                        onChange={this.handleChange} 
                                        autoComplete="off" 
                                        value={this.state.otp}
                                        style={{width:"70%",margin:"auto"}}
                                        className=' mt-3 no-text-transform'
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm={12} >
                                    <Form.Control 
                                        name='NewPassword'
                                        type="password" 
                                        placeholder="&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Enter New Password" 
                                        onChange={this.handleChange} 
                                        autoComplete="off" 
                                        value={this.state.NewPassword}
                                        style={{width:"70%",margin:"auto"}}
                                        className='mt-3 no-text-transform'
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm={12} >
                                    <Form.Control 
                                        name='ConfirmPassword'
                                        type="password" 
                                        placeholder="&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Confirm New Password" 
                                        onChange={this.handleChange} 
                                        autoComplete="off" 
                                        value={this.state.ConfirmPassword}
                                        style={{width:"70%",margin:"auto"}}
                                        className=' mt-3 no-text-transform'
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Button variant="success" style={{minWidth:'60px',marginRight:'1pt',margin:"auto"}} className="mt-5" onClick = { this.handleSubmitpass }>OK</Button>
                            </Form.Row>
                        </div>
                        
                    </Container>
                </Modal.Body>
                </div>
                <NoserLoading show={this.state.isloading} />
                {/* <SignUpLogInResetPassword
                    show={this.state.openModal}
                    onHide={this.handleModalClose2}
                    onRefModal={ref => (this.child = ref)}
                /> */}
                
        </Modal>
       
        );
    }
}
export default SignUpLogInForgotPassword