import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

class EmployeeDeploymentClearanceCreate extends Component {
    constructor(props) {
        super(props);
            this.state = {
                fade            :   true,
                color           :   "",
                isshow          :   false,
                message         :   "",
                userinfo        :   [],
                isloading       :   false,
                alerttype       :   "",

                date            :   new Date(),
                branch          :   "",
                position        :   "",
                employeeNo      :   "",
                employeeName    :   "",

                addDocumentsLists               :   [],
                documentsListGrids  :   [],
                trainingRecommendationListGrid  :   [],
                /* documentsListGrid    :   [
                    { "id" : "1", "document" : "", "type" : "", "received" : "", "date" : "", "suspended" : "" },
                    { "id" : "2", "document" : "", "type" : "", "received" : "", "date" : "", "suspended" : "" },
                    { "id" : "3", "document" : "", "type" : "", "received" : "", "date" : "", "suspended" : "" },
                    { "id" : "4", "document" : "", "type" : "", "received" : "", "date" : "", "suspended" : "" },
                    { "id" : "5", "document" : "", "type" : "", "received" : "", "date" : "", "suspended" : "" },
                ], */
                documentsListGrid : [],
                tblAReceivedArrLst : [],
                addReceivedLists    :   [
                    { "id" : "1", "name" : "YES" },
                    { "id" : "0", "name" : "NO" },
                ],
                tblUploadedArrLst : [],
                addUploadedLists    :   [
                    { "id" : "0", "name" : "NO" },
                    { "id" : "1", "name" : "YES" },
                ],
                tblSuspendedArrLst : [],
                addSuspendedLists    :   [
                    { "id" : "1", "name" : "YES" },
                    { "id" : "0", "name" : "NO" },
                ],
                addTrainingLists : [],
                trainingListGrid : [],
                /* trainingListGrid    :   [
                    { "id" : "1", "training" : "", "type" : "", "dateRegistered" : "", "submit" : "", "suspended" : "" },
                    { "id" : "2", "training" : "", "type" : "", "dateRegistered" : "", "submit" : "", "suspended" : "" },
                    { "id" : "3", "training" : "", "type" : "", "dateRegistered" : "", "submit" : "", "suspended" : "" },
                    { "id" : "4", "training" : "", "type" : "", "dateRegistered" : "", "submit" : "", "suspended" : "" },
                    { "id" : "5", "training" : "", "type" : "", "dateRegistered" : "", "submit" : "", "suspended" : ""},
                ], */
                tblSubmitArrLst : [],
                addSubmitLists    :   [
                    { "id" : "1", "name" : "YES" },
                    { "id" : "0", "name" : "NO" },
                ],
                tblTrainingSuspendedArrLst : [],
                addTrainingSuspendedLists    :   [
                    { "id" : "0", "name" : "NO" },
                    { "id" : "1", "name" : "YES" },
                ],
                tblSubmitForTrainingArrLst : [],
                addSubmitForTrainingLists    :   [
                    { "id" : "0", "SubmitForTraining" : "NO" },
                    { "id" : "1", "SubmitForTraining" : "YES" },
                ],

                tblRequiredArrLst : [],
                addRequiredLists    :   [
                    { "id" : "0", "isRequired" : "MANDATORY" },
                    { "id" : "1", "isRequired" : "OPTIONAL" },
                ],
                tblRequiredTypeLst : [],
                // addRequiredTypeLists    :   [
                //     { "id" : "0", "isRequired" : "MANDATORY" },
                //     { "id" : "1", "isRequired" : "OPTIONAL" },
                // ],
                
                client:"",
                employeeName:"",
                employeeNo:"",
                branch:"",
                position:"",
                profileid:"",
                tblDocumentsArrLst : [],
                newRequiredDocsDG : [],
                newTrainingListGrid : [],
                newTrainingListGridSave : [],
                newRequiredDocsSave : [],

                employeeDeployment : JSON.parse(sessionStorage.getItem("employeeDeploymentClearanceDataRow")),
            }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetReceivedLists()
        this.GetUploadedLists()
        this.GetSuspendedLists()
        this.GetSubmitLists()
        this.GetTrainingSuspendedLists()
        this.GetSubmitForTrainingLists()
        this.GetRequiredLists()
        this.GetRequiredTrainingLists()
        this.setState({
            client          :   this.state.employeeDeployment.client,
            clientId          :   this.state.employeeDeployment.clientId,
            employeeName    :   this.state.employeeDeployment.employeeName,
            employeeNo      :   this.state.employeeDeployment.employeeNo,
            branch          :   this.state.employeeDeployment.location,
            position        :   this.state.employeeDeployment.position,
            selectedId      :   this.state.employeeDeployment.id,
            deploymentId    :   this.state.employeeDeployment.deploymentId
        })
        this.GetDeploymentDetails()
        //console.log("employeeDeployment")
        //console.log(this.state.employeeDeployment)
    }


    GetDeploymentDetails() {
        this.setState({isloading:true})

        const params = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.employeeDeployment.clientId,
            "ProfileId"    :   this.state.employeeDeployment.profileId
        };

        //console.log("Params")
        //console.log(params)

         axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Deployment/GetDeploymentDetails", params)
        .then(res => {
            const data = res.data;
            //console.log("GetDeploymentDetails")
            //console.log(data)
            this.setState({ 
                documentsListGrid   :   data.documents, 
                trainingListGrid    :   data.trainings,
                isloading           : false 
            })
            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
                
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    // handleChangeProfile= (e) => {
    //     ////////console.log(e)
    //         if (e.length == 0) {
    //             this.setState({profileid: ''})
    //         return
    //     }
    //     this.state.profileid = this.state.employeeDeploymentData.profileid
       

    //     this.GetDeploymentDetails();
    //     this.setState({isloading:false,})
        
    // }


    GetRequiredLists() {

        for (let i = 0; i < this.state.addRequiredLists.length; i++) {
            const obj = {
                value : this.state.addRequiredLists[i]["id"],
                label : this.state.addRequiredLists[i]["name"],
            };
            this.state.tblRequiredArrLst.push(obj);
        }
    }


    GetReceivedLists() {

        for (let i = 0; i < this.state.addReceivedLists.length; i++) {
            const obj = {
                value : this.state.addReceivedLists[i]["id"],
                label : this.state.addReceivedLists[i]["name"],
            };
            this.state.tblAReceivedArrLst.push(obj);
        }
    }

    GetUploadedLists() {

        for (let i = 0; i < this.state.addUploadedLists.length; i++) {
            const obj = {
                value : this.state.addUploadedLists[i]["id"],
                label : this.state.addUploadedLists[i]["name"],
            };
            this.state.tblUploadedArrLst.push(obj);
        }
    }


    GetSuspendedLists() {

        for (let i = 0; i < this.state.addSuspendedLists.length; i++) {
            const obj = {
                value : this.state.addSuspendedLists[i]["id"],
                label : this.state.addSuspendedLists[i]["name"],
            };
            this.state.tblSuspendedArrLst.push(obj);
        }
    }

    GetSubmitLists() {

        for (let i = 0; i < this.state.addSubmitLists.length; i++) {
            const obj = {
                value : this.state.addSubmitLists[i]["id"],
                label : this.state.addSubmitLists[i]["name"],
            };
            this.state.tblSubmitArrLst.push(obj);
        }
    }

    GetSubmitForTrainingLists() {

        for (let i = 0; i < this.state.addSubmitForTrainingLists.length; i++) {
            const obj = {
                value : this.state.addSubmitForTrainingLists[i]["id"],
                label : this.state.addSubmitForTrainingLists[i]["submit"],
            };
            this.state.tblRequiredTypeLst.push(obj);
        }
    }

    GetRequiredTrainingLists() {

            for (let i = 0; i < this.state.addRequiredLists.length; i++) {
                const obj = {
                    value : this.state.addRequiredLists[i]["id"],
                    label : this.state.addRequiredLists[i]["isRequired"],
                };
                this.state.tblRequiredArrLst.push(obj);
            }
        }

    GetTrainingSuspendedLists() {

        for (let i = 0; i < this.state.addTrainingSuspendedLists.length; i++) {
            const obj = {
                value : this.state.addTrainingSuspendedLists[i]["id"],
                label : this.state.addTrainingSuspendedLists[i]["name"],
            };
            this.state.tblTrainingSuspendedArrLst.push(obj);
        }
    }

    LeavePageWithourSavingChanges() {
        const isChanged=false
        for (let i = 0; i < this.state.documentsListGrid.length; i++) {
            if (this.state.documentsListGrid[i]["isModified"] == 1) {
                this.setState({isGridDataChanged: true})
                isChanged=true
                break;
            }
        }
        return isChanged
    }

    DocumentsGridDataModified(oldValue, newValue, applicationid, column) {
        //////console.log(applicationid)
        this.state.documentsListGrid.map(function(item,i) {
            if (item.id===applicationid)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }

    handleAddDocumentClick = () => {
       for (let i = 0; i < this.state.documentsListGrid.length; i++) {
            if (this.state.documentsListGrid[i]["isModified"] == 1) {
                const obj = {
                    Id          :   this.state.deploymentId,
                    IsReceived  :   this.state.documentsListGrid[i]["received"],
                    IsSuspended :   this.state.documentsListGrid[i]["suspended"],
                };

                this.state.addDocumentsLists.push(obj);
            }
        }


        if(this.state.addDocumentsLists.length === 0) {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please input values for Documents",
                fade        :   true
            });
            return
        } else {
            this.setState({
                isloading   :   false,
                isshow      :   false,
            });
        }
        
    }

    handleAddTrainingClick = () => {
        for (let i = 0; i < this.state.trainingListGrid.length; i++) {
             if (this.state.trainingListGrid[i]["isModified"] == 1) {
                 const obj = {
                     Id             :   this.state.deploymentId,
                     IsSuspended    :   this.state.trainingListGrid[i]["suspended"],
                     IsSubmit       :   this.state.trainingListGrid[i]["submit"],
                 };
 
                 this.state.addTrainingLists.push(obj);
             }
         }

         if(this.state.addTrainingLists.length === 0) {
             this.setState({
                 isloading   :   false,
                 alerttype   :   "Error!",
                 isshow      :   true,
                 color       :   "danger",
                 message     :   "Please input values for Training",
                 fade        :   true
             });
             return
         } else {
             this.setState({
                 isloading   :   false,
                 isshow      :   false,
             });
         }
    }

    LeavePageWithourSavingChanges() {
        const isChanged=false
        for (let i = 0; i < this.state.trainingListGrid.length; i++) {
            if (this.state.trainingListGrid[i]["isModified"] == 1) {
                this.setState({isGridDataChanged: true})
                isChanged=true
                break;
            }
        }
        return isChanged
    }

    TrainingGridDataModified(oldValue, newValue, applicationid, column) {
        //////console.log(applicationid)
        this.state.trainingListGrid.map(function(item,i) {
            if (item.id===applicationid)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }

    handleStatusClick = (statusId) => {

        this.setState({isloading   :   true })
        
        let documentsGrid = []
        this.state.documentsListGrid.map(function(itm,a) {
            let dateReceived = itm.dateReceived
            documentsGrid.push({
                "DateReceived"  :   (dateReceived) ? moment(dateReceived).format('MM/DD/YYYY') : "",
                "DeploymentId"  :   itm.deploymentId,
                "Document"      :   itm.document,
                "DocumentCode"  :   itm.documentCode,
                "DocumentId"    :   itm.documentId,
                "Id"            :   itm.id,
                "IsDeleted"     :   itm.isDeleted,
                "IsReceived"    :   itm.isReceived,
                "IsRequired"    :   itm.isRequired,
                //"IsSubmitted"   :"",
                "IsSuspended"   :   itm.isSuspended,
                "IsUploaded"    :   itm.isUploaded,
                "ProfileId"     :   itm.profileId,
                "Required"      :   itm.required,
                "StatusId"      :   itm.statusId,
            })
        })
        ////console.log("Documents")
        ////console.log(documentsGrid)


        let trainingsGrid = []
        this.state.trainingListGrid.map(function(itm,a) {
            let dateAttended = itm.dateAttended
            let dateSubmitted = itm.dateSubmitted
            let dateDeployment = itm.dateDeployment
            trainingsGrid.push({
                "Id"                :   itm.id,
                "DeploymentId"      :   itm.deploymentId,
                "ProfileId"         :   itm.profileId,
                "Training"          :   itm.training,
                "DateAttended"      :   (dateAttended) ? moment(dateAttended).format('MM/DD/YYYY') : "",
                "DateSubmitted"     :   (dateSubmitted) ? moment(dateSubmitted).format('MM/DD/YYYY') : "",
                "DateDeployment"    :   (dateDeployment) ? moment(dateDeployment).format('MM/DD/YYYY') : "",
                "Required"          :   itm.required,
                "IsRequired"        :   itm.isRequired,
                "IsSubmitted"       :   itm.isSubmitted,
                "IsSuspended"       :   itm.isSuspended,
                "StatusId"          :   itm.statusId,
            })
        })
        ////console.log("Training")
        ////console.log(trainingsGrid)

        const saveParams = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.clientId,
            "Id"                :   this.state.selectedId,
            "DeploymentId"      :   this.state.deploymentId,
            "IsDeployed"        :   statusId,
            "trainings"         :   trainingsGrid,
            "documents"         :   documentsGrid,
            "recommendations" : []
        };

        ////console.log("Save Params")
        ////console.log(saveParams)

       axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Deployment/SaveEmployeeDeployment",  saveParams)
        .then(res => {
            const data = res.data;
            ////console.log("Save Results")
            ////console.log(data)
            if(data.status=="1"){
            this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    render() {

        // DOCUMENTS TABLE

        const documentsColumns = [
            {
                dataField: 'document',
                text: 'Documents',
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'required',
                text: 'Type',
                headerStyle: () => {
                    return { width: "20%" };
                },
                /* formatter: (cell, row) => {
                    if(row.isRequired!='' && row.isRequired!=null){
                        if(this.state.tblRequiredArrLst.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.tblRequiredArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblRequiredArrLst
                } */
            },
            {
                dataField: 'isReceived',
                text: 'Received',
                headerStyle: () => {
                    return { width: "15%" };
                },
                formatter: (cell, row) => {
                    if(row.isReceived!='' && row.isReceived!=null){
                        if(this.state.tblAReceivedArrLst.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.tblAReceivedArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblAReceivedArrLst
                },
                validator: (newValue, row, column) => {
                    if (newValue =="1" && row.isSuspended=="1") {
                      return {
                        valid: false,
                        message: "Please select correctly at Column RECEIVED / SUSPENDED"
                      };
                    }
                    return true;
                }
            },
            {
                dataField: 'dateReceived',
                text: 'Date Received',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%'}},
                style:{textAlign:'center',width:'16%'},
                formatter: (cell) => { 
                    /* if(cell!=""){
                        let dateObj = cell;
                        if (typeof cell !== 'object') {
                        dateObj = new Date(cell);
                        }
                        if (cell == null) {
                        return
                        }
                        return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${('0' + dateObj.getUTCDate()).slice(-2)}/${dateObj.getUTCFullYear()}`;  
                    } */
                    let dateObj = cell;
                    if(cell === "") return
                    if (typeof cell !== 'object') dateObj = new Date(cell);
                    if (cell != null) return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${('0' + dateObj.getUTCDate()).slice(-2)}/${dateObj.getUTCFullYear()}`; 
                },
                    editor: {
                    type: Type.DATE
                }
            },
            {
                dataField: 'isUploaded',
                text: ' Documents Uploaded',
                headerStyle: () => {
                    return { width: "15%" };
                },
                formatter: (cell, row) => {
                    if(row.isUploaded!='' && row.isUploaded!=null){
                        if(this.state.tblUploadedArrLst.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.tblUploadedArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblUploadedArrLst
                }
            },
            {
                dataField: 'isSuspended',
                text: 'Suspended',
                headerStyle: () => {
                    return { width: "15%" };
                },
                formatter: (cell, row) => {
                    if(row.isSuspended!='' && row.isSuspended!=null){
                        if(this.state.tblSuspendedArrLst.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.tblSuspendedArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblSuspendedArrLst
                },
                validator: (newValue, row, column) => {
                    if (newValue =="1" && row.isReceived=="1") {
                      return {
                        valid: false,
                        message: "Please select correctly at Column RECEIVED / SUSPENDED"
                      };
                    }
                    return true;
                }
            },
        ]
    
        const documentsSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.documentsListGrid.map(function(item,i){
                    if(item.id===row.id)
                        {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    //////console.log(item.id)
                })

            }
        };

        const documentsRowEvents = {
            onClick: (e, row, rowIndex) => {

            }
        };


        // TRAINING TABLE


        const trainingColumns = [
            {
                dataField: 'training',
                text: 'Training',
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'required',
                text: 'Type',
                headerStyle: () => {
                    return { width: "20%" };
                },
                /* formatter: (cell, row) => {
                    if(row.isRequired!='' && row.isRequired!=null){
                        if(this.state.tblRequiredArrLst.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.tblRequiredArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblRequiredArrLst
                } */
            },
            {
                dataField: 'dateAttended',
                text: 'Attended',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%'}},
                style:{textAlign:'center',width:'15%'},
                formatter: (cell) => { 
                    /* if(cell!=""){
                        let dateObj = cell;
                        if (typeof cell !== 'object') {
                        dateObj = new Date(cell);
                        }
                        if (cell == null) {
                        return
                        }
                        return `${('0' + dateObj.getUTCMonth()).slice(-2)}/${('0' + (dateObj.getUTCDate() - 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
                    } */
                    let dateObj = cell;
                    if(cell === "") return
                    if (typeof cell !== 'object') dateObj = new Date(cell);
                    if (cell != null) return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${('0' + dateObj.getUTCDate()).slice(-2)}/${dateObj.getUTCFullYear()}`; 
                },
                    editor: {
                    type: Type.DATE
                }
            },
            {
                dataField: 'dateDeployment',
                text: 'Deployment Date',
                headerStyle: () => {
                    return { width: "15%" };
                },
                style:{textAlign:'center',width:'15%'},
                
                formatter: (cell) => { 
                    let dateObj = cell;
                    if (typeof cell !== 'object') {
                    dateObj = new Date(cell);
                    }
                    if (cell == "") {
                    return
                    }
                    return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${('0' + dateObj.getUTCDate()).slice(-2)}/${dateObj.getUTCFullYear()}`; 
                },
                    editor: {
                    type: Type.DATE
                },
                validator: (newValue, row, column) => {
                    if (row.isReceived=="0") {
                      return {
                        valid: false,
                        message: "Not allowed"
                      };
                    }
                    return true;
                }
            },
            // {
            //     dataField: 'dateOfValidity',
            //     text: 'Validity Date',
            //     headerStyle: () => {
            //         return { width: "50%" };
            //     },
            // },
            // {
            //     dataField: 'submitForTraining',
            //     text: 'Submit For Training',
            //     headerStyle: () => {
            //         return { width: "50%" };
            //     },
            //     formatter: (cell, row) => {
            //         if(row.submitForTraining!='' && row.submitForTraining!=null){
            //             if(this.state.tblSubmitForTrainingArrLst.filter(x => x.value == cell).length==0)
            //             return ""
            //             else
            //                 return this.state.tblSubmitForTrainingArrLst.find(x => x.value == cell).label;
            //         }
            //     },
            //     editor: {
            //         type: Type.SELECT,
            //         options: this.state.tblSubmitForTrainingArrLst
            //     }
            // },
            {
                dataField: 'isSubmitted',
                text: 'Submit For Training',
                headerStyle: () => {
                    return { width: "15%" };
                },
                formatter: (cell, row) => {
                    if(row.isSubmitted!='' && row.isSubmitted!=null){
                        if(this.state.tblSubmitArrLst.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.tblSubmitArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblSubmitArrLst
                },
                validator: (newValue, row, column) => {
                    if (newValue =="1" && row.isSuspended=="1") {
                      return {
                        valid: false,
                        message: "Please select correctly at Column RECEIVED / SUSPENDED"
                      };
                    }
                    return true; 
                }
            },
            {
                dataField: 'isSuspended',
                text: 'Suspended',
                headerStyle: () => {
                    return { width: "15%" };
                },
                formatter: (cell, row) => {
                    if(row.isSuspended!='' && row.isSuspended!=null){
                        if(this.state.tblTrainingSuspendedArrLst.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.tblTrainingSuspendedArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblTrainingSuspendedArrLst
                },
                validator: (newValue, row, column) => {
                    if (newValue =="1" && row.isSubmitted=="1") {
                      return {
                        valid: false,
                        message: "Please select correctly at Column RECEIVED / SUSPENDED"
                      };
                    }
                    return true;
                }
            },
        ]
    
        const trainingSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.trainingListGrid.map(function(item,i){
                    if(item.id===row.id)
                        {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    //////console.log(item.id)
                })

            }
        };

        const trainingRowEvents = {
            onClick: (e, row, rowIndex) => {

            }
        };

        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>TRAINING >> ADMIN >> EMPLOYEE DEPLOYMENT CLEARANCE - (CREATE)</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                    </Form.Label>
                                    <Col sm="4">
                                        <Form.Control 
                                            type="text"
                                            name="client"
                                            value={this.state.client}
                                            onChange={this.onChangeClient} 
                                            autoComplete="off"
                                        />
                                    </Col>
                                    <Col sm="2">
                                    </Col>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE NAME
                                    </Form.Label>
                                    <Col sm="4">
                                        <Form.Control 
                                            type="text"
                                            name="employeeName"
                                            value={this.state.employeeName}
                                            onChange={this.onChangeEmployeeName} 
                                            autoComplete="off"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE NO.
                                    </Form.Label>
                                    <Col sm="4">
                                        <Form.Control 
                                            type="text"
                                            name="employeeNo"
                                            value={this.state.employeeNo}
                                            onChange={this.onChangeEmployeeNumber} 
                                            autoComplete="off"
                                        />
                                    </Col>
                                    <Col sm="2">
                                    </Col>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        POSITION
                                    </Form.Label>
                                    <Col sm="4">
                                        <Form.Control 
                                            type="text"
                                            name="position"
                                            value={this.state.position}
                                            onChange={this.onChangePosition} 
                                            autoComplete="off"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        BRANCH
                                    </Form.Label>
                                    <Col sm="4">
                                        <Form.Control 
                                            type="text"
                                            name="branch"
                                            value={this.state.branch}
                                            onChange={this.onChangeBranch} 
                                            autoComplete="off"
                                        />
                                    </Col>
                                    <Col sm="2">
                                    </Col>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        DATE
                                    </Form.Label>
                                    <Col sm="4">
                                        <DatePicker
                                            ref='date'
                                            selected={this.state.date}
                                            onChange={this.handleChangeDate}
                                            minDate={this.minDate}
                                            value={this.props.date}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />
                                    </Col>
                                </Form.Group>
                                <Card.Header className="mt-5">Pre-Deployment Requirement</Card.Header>
                                <BootstrapTable
                                    keyField = "id"
                                    data = { this.state.documentsListGrid }
                                    columns = { documentsColumns }
                                    /* pagination={ paginationFactory({sizePerPageRenderer}) } */
                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                    rowClasses="noser-table-row-class"
                                    striped
                                    hover
                                    condensed
                                    cellEdit = { cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                            this.DocumentsGridDataModified(oldValue, newValue, row.id, column.dataField)
                                            }
                                        })
                                    }
                                    rowEvents={ documentsRowEvents }
                                    selectRow = { documentsSelectRow }
                                />

                                <div className="mt-5">
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.trainingListGrid }
                                        columns = { trainingColumns }
                                        /* pagination={ paginationFactory({sizePerPageRenderer}) } */
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        rowClasses="noser-table-row-class"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.TrainingGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        selectRow = { trainingSelectRow }
                                        rowEvents={ trainingRowEvents }
                                    />
                                </div>
                                {/* <ButtonToolbar>
                                    <Button className="ml-auto" variant="success" onClick = { this.handleAddTrainingClick }>Add</Button>
                                </ButtonToolbar> */}
                                <ButtonToolbar sm={12} className="mt-3">
                                    <Button variant="success" className="ml-auto" onClick={() => this.handleStatusClick('0')}>
                                        Submit
                                    </Button>&nbsp;&nbsp;
                                    <Button variant="info" onClick={() => this.handleStatusClick('1')}>
                                        Deploy
                                    </Button>&nbsp;&nbsp;
                                    <Button variant="danger" href="/EmployeeDeploymentClearance">
                                        Back
                                    </Button>
                                </ButtonToolbar>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>  
                <NoserLoading show={this.state.isloading} />       
            </div> 
        )
    }

}

export  default EmployeeDeploymentClearanceCreate
