import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../noser-hris-component';

class DeductionCreate extends Component {
    constructor(props) {
        super(props)
        this.module = React.createRef();
        this.submodule = React.createRef();

        this.state = {
            selected:[],
            deductionTypeList: [],
        selectedDeductionType: "",
        selectedDeductionTypeId: "",
        isloading       :   false,
        isshow          :   false,
        alerttype       :   "",
        message         :   "",
        color           :   "",
        fade            :   true,
        }
        this.textInput = React.createRef();
      
    }
    handleCoverChangeDeductionType = this.handleCoverChangeDeductionType.bind(this)
    
    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
        this.GetDeductionType()
        this.GetDeduction()
        sleep(1000).then(() => {
            this.setState({isLoading:false})})
    };

    componentWillUnmount() {
        this.props.onRefModal(undefined)
    };

    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            name        :   '',
            description :   ''
        })
    };

    handleChange() {
        this.state.selectedDeduction= this.textInput.current.value;
    };

    handleCoverChangeInclusion(selectedOptions){
                this.state.selectedDeduction = this.textInput.current.value;
    };

    GetDeduction() {
    const deductionParams = {
        "IpAddress":"0.0.0.0",
        "CompanyId":this.state.userinfo.companyId, 
        "UserId":"35000",
        "TypeId":"",
        "Name":""
    };
    axios
        .post(
            AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDeductions", deductionParams
        )
            .then(res => {
                const data = res.data;
                console.log(data)
                this.setState({deductionList: data.deductions});
            })
    };

    GetDeductionId(name) {
        let DeductionId = ''
        for (let i = 0; i <= this.state.deductionList.length; i++) {
            if (this.state.deductionList[i]["name"] === name) {
                DeductionId = this.state.inclusionList[i]['id'];
                break;
            }
        }
        return DeductionId
    }

    GetDeductionType() {
        const typeParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId, 
            "UserId":"35000",
            "Name":""
        };
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDeductionTypes", typeParams
            )
            .then(res => {
                const data = res.data;
                console.log(data)
                this.setState({deductionTypeList: data.deductionTypes});
            })
    }

    handleCoverChangeDeductionType (e) {
        if (e.length > 0) {
            this.state.selectedDeductionType = e[0].name
                this.state.selectedDeductionTypeId = this.GetDeductionTypeId(e[0].name)
        }
    }

    GetDeductionTypeId(name) {
        let DeductionTypeId = ''
        for (let i = 0; i <= this.state.deductionTypeList.length; i++) {
            if (this.state.deductionTypeList[i]["name"] === name) {
                DeductionTypeId = this.state.deductionTypeList[i]['id'];
                break;
            }
        }
        return DeductionTypeId
    }

    handleSaveClick = event => {
        event.preventDefault();
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId, 
            "UserId":"35000",
            "TypeId":this.state.selectedDeductionTypeId,
            "Name":this.state.selectedDeduction
        };
        console.log(params)
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/AddDeduction", params
            )
            .then(res => {
                const data = res.data;
                console.log(params);
                this.setState({isLoading:false})
                var alertType = (data.status=="1") ? "success" : "danger"
                if(data.status=="1")
                {
                    this.setState(
                        { 
                            isshow:true,
                            color:"success",
                            message:data.message ,
                            fade:true
                        });
                    
                    }
                else
                {
                    this.setState(
                        { 
                            show:true,
                            color:"danger",
                            message:data.message,
                            fade:true
                        });
                }
            })
            .catch(error=>{
                this.setState(
                {
                    isLoading:false,
                    AlertType:"Error! ",
                    Show:true,
                    Color:"danger",
                    Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    Fade:true
            })
        })

    };

    handleModalClose = () => {
        this.props.onHide();            
    };
   
    handleKeyUp = (e) =>{

    };

    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Deduction - Create
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Form onSubmit={ this.handleSaveClick } ref={form => this.form = form}>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleCoverChangeDeductionType}
                                        options={this.state.deductionTypeList}
                                        placeholder="Select Deduction Type"
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <Form.Control type="text" placeholder="Enter New Deduction Type" ref={this.textInput} onChange={() => this.handleChange()} autoComplete="off" />
                                </Col>
                            </Form.Group>
                        </Form>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                   
                    <ButtonToolbar sm={12}>
                        <Button variant="success" className="ml-auto" onClick={this.handleSaveClick} style={{minWidth:'60px'}}>
                            Save
                        </Button>&nbsp;&nbsp;
                        <Button variant="danger" onClick={this.props.onHide} style={{minWidth:'60px'}}>
                            Close
                        </Button>
                    </ButtonToolbar>
                   
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default DeductionCreate