import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { Input  } from 'reactstrap';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import  PeroidTypeModal from "../Maintenance/PeroidTypeModal";
 
class PeriodType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            payrollperiod   :[],
            payrollPeriodData:[],
            selectedperiod:"",
            selectedperiodID:"",
            modalShow: false,
            dateStart:"",
            dateEnd:"",
            periodName:"",
            fullName:"",
            leadTime:"",
            periodStart:"",
            selectedMode:"",
            payrollMode : [
                {
                    "id":"3",
                    "name":"Monthly"

                },
                {
                    "id":"1",
                    "name":"Semi-Monthly"
                },
                {
                    "id":"2",
                    "name":"Weekly"
                },
                {
                    "id":"4",
                    "name":"Daily"
                },
            ],
            selectedRemoved:[],

        }
    }

    componentDidMount(){
        this.setState({
            isloading:true
        })
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetPayrollPeriods()
        //this.GetEmployeePayModesList()

    }

    GridDataModified(oldValue, newValue, id, column) {
        this.state.payrollperiod.map(function(item,i) {
            if(item.id===id)
            item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    GetEmployeePayModesList(){
        const getParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId" : this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
        }
    
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollModes", getParams)
            .then(res => {
                const data = res.data
                console.log("GetEmployeePayModesList")
                console.log(data)
                this.setState({
                    payrollPeriodData: data.payrollModes
                })
            })
    
    }

    GetPayrollPeriods(){
        const getParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId" : this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
	        "Name":"",
            "isDeleted" : "0"
        }

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPeriodTypes", getParams)
        .then(res => {
            const data = res.data
            console.log("GetPayrollPeriods")
            console.log(data)
            console.log("data2121212")
            data.periodTypes.map(function(d){
                if(d.payModeId == "1"){
                    d.modeName = "Semi-Monthly"
                }
                if(d.payModeId == "2"){
                    d.modeName = "Weekly"
                }
                if(d.payModeId == "3"){
                    d.modeName = "Monthly"
                }
                if(d.payModeId == "4"){
                    d.modeName = "Daily"
                }

                if(d.isDefault == "1"){
                    d.tag = "YES"
                }else{
                    d.tag = "No"
                }
            })

            this.setState({
                payrollperiod: data.periodTypes,
                isloading:false
            })
            
            
        })
    }

    searchPayrollPeriods=()=>{
       /*  const getParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId" : this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
	        "payModeId": this.state.selectedperiodID,
            "name": this.state.selectedperiodID,
            "PeriodStart" : this.state.periodStart,
            "PeriodEnd" : this.state.periodEnd,
            //"Name": this.state.selectedperiod,
            
        } */

        const getParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId" : this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "Name":this.state.fullName,
            
        }
        

        console.log("GetPayrollPeriods")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPeriodTypes", getParams)
        .then(res => {
            const data = res.data
            console.log("GetPayrollPeriods")
            console.log(data)
            this.setState({
                payrollperiod: data.periodTypes
            })
            
            
        })
    }

    GridDataModified(oldValue, newValue, id, column) {
        ////console.log(id)
        this.state.trainingScheduleTableList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    onChangePeriod = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedperiod     :   '',
            })
            return
        }
        this.state.selectedperiod = e[0].name    
        this.state.selectedperiodID = e[0].id    
               
        this.setState({
            selectedperiod   : e[0].name

        })
       
    }

    handleModalSHOW = (e) =>{
    
        this.setState({modalShow: true})
        
    }
    
    handleModalClose = (e) =>{
        
        this.setState({modalShow: false})
        this.GetPayrollPeriods()
    }

    handleChangedateStart =date=>{
        this.setState({
            dateStart: date
        })
    }

    handleChangedateEnd =date=>{
        this.setState({
            dateEnd: date
        })
    }

    onChangeTypeName=(e)=>{
        if(e.length==0)
        {
            this.setState({
                fullName     :   '',
            })
            return
        }
        this.state.fullName = e[0].name 
        this.state.fullNameId = e[0].id    
        this.setState({fullName: e[0].name })
    }

    onChangeleadTime=(e)=>{
        if(e.length==0)
        {
            this.setState({
                leadTime     :   '',
            })
            return
        }
        this.state.leadTime = e[0].leadTime 
        this.setState({leadTime: e[0].leadTime })
    }

    onChangeperiodStart=(e)=>{
        if(e.length==0)
        {
            this.setState({
                periodStart     :   '',
            })
            return
        }
        this.state.periodStart = e[0].periodStart 
        this.setState({periodStart: e[0].periodStart })
    }

    onChangepayrollMode = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedMode     :   '',
            })
            return
        }
        this.state.modeId = e[0].id    
           
       
    }

    handleUpdate = (e) => {
        
        console.log(this.state.selectedRemoved)
        let newUserid = this.state.userinfo.userId
        let newCompanyId = this.state.userinfo.companyId

        const Params = {

            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   newUserid,
            "CompanyId"     :   newCompanyId,
            "periodtypes"   :   this.state.selectedRemoved
            
        }

        console.log("handleClick EditPeriodType")
        console.log(Params)

        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditPeriodType",  Params
            )
            .then(res => {
                const data = res.data;
                console.log("AddPayrollPeriod");
                console.log(data)
                if(data.status=="1"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true
                    });
                    this.GetPayrollPeriods()
                }else{
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
                
            })

    }

    handleChangeDefault = (e) => {
        
        console.log(this.state.payrollperiod)
        let newUserid = this.state.userinfo.userId
        let newCompanyId = this.state.userinfo.companyId

        this.state.payrollperiod.map(function(d){
            if(d.isDeleted == "1"){
                d.isDeleted = "0"
                d.isDefault = "1"
            }else{
                d.isDeleted = "0"
                d.isDefault = "0"
            }
        })

        const Params = {

            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   newUserid,
            "CompanyId"     :   newCompanyId,
            "periodtypes"   :   this.state.payrollperiod
            
        }

        console.log("handleClick EditPeriodType")
        console.log(Params)

        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditPeriodType",  Params
            )
            .then(res => {
                const data = res.data;
                console.log("AddPayrollPeriod");
                console.log(data)
                if(data.status=="1"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true
                    });
                    this.GetPayrollPeriods()
                }else{
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
                
            })

    }

    render() {

        const payrollperiod = [
            {
                dataField: '#',
                //text: 'Payroll Mode',
               
            },
            {
                dataField: 'name',
                text: 'Period Name',
                headerStyle: () => {
                    return { width: "24%",textAlign:'left' };
                },
                style:()=>{
                    return {textAlign:'left'}
                }
            },
            {
                dataField: 'modeName',
                text: 'Payroll Mode',
                headerStyle: () => {
                    return { width: "20%", textAlign:'center' };
                },
                style:()=>{
                    return {textAlign:'left'}
                }
            },
            {
                dataField: 'payOut',
                text: 'payOut',
                headerStyle: () => {
                    return { width: "20%", textAlign:'center' };
                },
                style:()=>{
                    return {textAlign:'left'}
                }
            },
            {
                dataField: 'leadTime',
                text: 'lead Time',
                headerStyle: () => {
                    return { width: "20%", textAlign:'center' };
                },
                style:()=>{
                    return {textAlign:'left'}
                }
            },
            {
                dataField: 'periodStart',
                text: 'period Start',
                headerStyle: () => {
                    return { width: "20%", textAlign:'center' };
                },
                style:()=>{
                    return {textAlign:'left'}
                }
            },
            {
                dataField: 'tag',
                text: 'Default',
                headerStyle: () => {
                    return { width: "20%", textAlign:'center' };
                },
                style:()=>{
                    return {textAlign:'left'}
                }
            },
        ]

        const selectRowPayrollConfig = {
            mode: 'radio',
            clickToSelectAndEditCell: false,
            onSelect: (row, isSelect, rowIndex, e) => {
                //console.log(isSelect)
                let newData = []
                this.state.payrollperiod.map(function(item,idx){
                    if(idx==rowIndex)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        console.log(item)
                       
                    }
                    if(item.isDeleted == "1"){
                        newData.push(item)
                    }
                    
                }) 

                this.state.selectedRemoved = newData
            }
        };

    return(
            <div>
                <Card>
                    <Card.Header></Card.Header>
                    <Card.Body>
                        <Form>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Col sm={10}>
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.onChangepayrollMode}
                                        options={this.state.payrollMode}
                                        placeholder='select payroll mode'
                                    />
                                </Col>

                            </Form.Group>

                            <Form.Group  as={Row} controlId="formPlaintextEmail">
                                <Col sm={10}>
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.onChangeTypeName}
                                        options={this.state.payrollperiod}
                                        placeholder='select period name'
                                    />
                                </Col>

                            </Form.Group>
                            
                            <Form.Group  as={Row} controlId="formPlaintextEmail">
                                <Col sm={10}>
                                    <Typeahead
                                        labelKey='leadTime'
                                        id="basic-example"
                                        onChange={this.onChangeleadTime}
                                        options={this.state.payrollperiod}
                                        placeholder='select lead time'
                                    />
                                </Col>

                            </Form.Group>

                            <Form.Group  as={Row} controlId="formPlaintextEmail">
                                <Col sm={10}>
                                    <Typeahead
                                        labelKey='periodStart'
                                        id="basic-example"
                                        onChange={this.onChangeperiodStart}
                                        options={this.state.payrollperiod}
                                        placeholder='select period start'
                                    />
                                </Col>

                            </Form.Group>

                            <ButtonToolbar sm={12}>
                            
                                <Button variant="primary" className="ml-auto" style={{minWidth:'60px', marginBottom:"5px" }} onClick={this.searchPayrollPeriods}>
                                    Search
                                </Button>&nbsp;&nbsp;
                                <Button variant="success"  style={{minWidth:'60px', marginBottom:"5px"}} onClick={this.handleModalSHOW}>
                                    Create
                                </Button>
                                
                            </ButtonToolbar>

                            <BootstrapTable
                                keyField = "id"
                                data = { this.state.payrollperiod }
                                columns = { payrollperiod }
                                //selectRow = { selectRowLeaveConfig }
                                cellEdit = { cellEditFactory({
                                        mode: 'dbclick', 
                                        blurToSave: true,
                                        afterSaveCell: (oldValue, newValue, row, column) => { 
                                            //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                        }
                                    })
                                }
                                striped
                                hover
                                condensed
                                loading={true}
                                rowClasses="noser-table-row-class"
                                wrapperClasses="table-responsive"
                                pagination={ paginationFactory({sizePerPage:15,hideSizePerPage:true,hidePageListOnlyOnePage:true})}
                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                selectRow = { selectRowPayrollConfig }
                            /> 
                            
                            <ButtonToolbar sm={12}>
                                <Button className='ml-auto' variant="success" style={{minWidth:'60px'}} onClick={this.handleChangeDefault}>Change Default</Button>&nbsp;
                                <Button variant="danger" style={{minWidth:'60px'}} onClick={this.handleUpdate}>Remove</Button>
                            </ButtonToolbar>
                        </Form>
                    </Card.Body>
                </Card>
                    
                <PeroidTypeModal
                    show={this.state.modalShow}
                    onHide={this.handleModalClose}
                />

                   
            </div> 
            
        )
    }

}

export  default PeriodType
