import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker,  Tabs, Tab,
    sizePerPageRenderer,Type
} 
from '../../noser-hris-component';
import { GetRequest, PostRequest } from '../../noser-dataaccess'
import NumberFormat from 'react-number-format';

class PayCardApplication extends Component {
    constructor(props) {
        super(props)
        // this.minDate = new moment(props.minDate)
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            startDate        : new Date(),
            selectedClientId :   "",
            selectedClient   :   "",
            selectedEmployeeId   :   '',
            selectedEmployeeName :   '',
            selectedProfileId    : "",
            selectedPosition     : "",
            selectedStatus       : "", 
            newpayCardNumber     : "",  
            oldpayCardNumber     : "",
            value                : "",
            description          : "",
            selectedReason       :"",
            selectedReasonId     :"",
            otherReason          :"",
            

            clientList      : [],
            employeeList    : [],
            natureList      : [],
            reasonList      : [],
            documents       : [],
            DocumentId      : [],
            docArr          : [],
           
            payrollForm     : false,
            loiForm         : false,
            validId         : false,
            affidavit       : false,
            cashCard        : false,
            atd             : false,
            dateTransmit    : "",
            today           : "",
            applicationDate            : "",
            payCardList     : [],
            reasonDDl       : [],
            docArrayList    : [],
            newPayCardApplication :[],
            statusid : "5",
            applicationId : "",
            dateStart : new Date(),
            payCardApplicationt : [],
            dateHired : "",
            disabledButton : false,

        }
        // this.state.assessedBy = this.state.userinfo.fullName
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.getClient();
        ////console.log("user")
        ////console.log(this.state.userinfo)
        this.getNature();
        this.getReason();
        this.getPayCard();
        this.GetApplicationList();
        this.GetStatusList()
        
        if(this.state.userinfo.roleId === "8"){
            this.setState({
                saveStatusId        : "28",
                cancelStatusId      : "30",
                transmittedStatusId : "31",
            })
        }
        
        if(this.state.userinfo.roleId === "26"){
            this.setState({
                saveStatusId        : "1",
                cancelStatusId      : "3",
                transmittedStatusId : "4",
            })
        }
        
        if(this.state.userinfo.roleId === "4"){
            this.setState({
                saveStatusId        : "1",
                cancelStatusId      : "3",
                transmittedStatusId : "4",
            })
        }
        
        if(this.state.userinfo.roleId === "5"){
            this.setState({
                saveStatusId        : "1",
                cancelStatusId      : "3",
                transmittedStatusId : "4",
            })
        }

        if(this.state.userinfo.roleId === "32"){
            this.setState({
                disabledButton : true
            })
        }

        if(this.state.userinfo.roleId === "5"){
            this.setState({
                disabledButton : false
            })
        }
       
    }

    getClient = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                         this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        console.log("params")
        console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        //.post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientPerAccess", params)
        .then(res => {
            const data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    console.log("access")
                    console.log(access)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                console.log("arr")
                console.log(arr)
                this.setState({clientList : arr})
            }
            else{
                this.setState({clientList : data.clients})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
   }

    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.setState({isshow:false,selectedClientId:"",selectedClientName:""})
            return
        }
        this.state.selectedClientId=e[0].id
        this.state.selectedClientName=e[0].name
        this.getEmployees();
        this.getClientLocation();
    }

    GetLocation(name) {
        let GetLocationName = ''
        for (let i = 0; i <= this.state.clientLocationList.length; i++) {
            if (this.state.clientLocationList[i]["name"] === name) {
                GetLocationName = this.state.clientLocationList[i]['id']; 
                break;
            }
        }
        return GetLocationName
    }
    onChangeLocation = (e) => {
        if(e.length == 0) {
                this.state.selectedLocationName = ""
                this.state.selectedLocationId = ""
                return
        }  
        this.state.selectedLocationName = e[0].name
        this.state.selectedLocationId = e[0].id
    }

    getClientLocation(){

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.selectedClientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", getParams)
        .then(res => {
            let branches = []
            res.data.locations.map(function(itm){
                branches.push({"value" : itm.id,"label" : itm.name})
            })
            ////console.log("location")
            ////console.log(res.data.locations)
            this.setState({clientLocationList : res.data.locations ? res.data.locations : [], locationDDL : branches})
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }

    getEmployees =() =>{
        this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.selectedClientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeName"  :   "",
            "EmployeeNo"    :   "",
            "ClientName"    :   ""
        }
        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees",  getParams)
        .then(res => {
            const data = res.data
            //console.log("GetEmployees")
            //console.log(data)
            if(data.status=="1")
                this.setState({
                    employeeList        :   data.employees,
                    isloading           :   false,
                }) 
            else
                this.setState({
                    employeeList        :   [],
                    isloading           :   false
                }) 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        
    }

    getNature = () =>{
      
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "0034"
        };

        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  getParams)
        .then(res => {
            const data = res.data;
            //console.log("Get Nature");
            //console.log(data);
            this.setState({ natureList : data.dataReferences });
         })
    }

    getReason = () =>{
        this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "0033"
        };

        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  getParams)
        .then(res => {
            const data = res.data;
            //console.log("Get Reason");
            //console.log(data);
            this.setState({ reasonList : data.dataReferences });
         })
    }

    getPayCard = () =>{
        this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        }

        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes",  getParams)
        .then(res => {
            const data = res.data;
            ////console.log("Get Reason");
            ////console.log(data);
            this.setState({ payCardList : data.payCardTypes });
         })
    }
   
    onChangeEmployeesNo(e){
        this.setState({selectedEmployeeNo:e.target.value})
    }

    onChangeEmployeePosition(e){
        this.setState({selectedPosition:e.target.value})
    }

    onChangeDateStart(e){
        this.setState({selectedDateStart:e.target.value})
    }

    onChangeStatus(e){
        this.setState({selectedStatus:e.target.value})
    }

    onChangeEmployeesList = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedEmployeeId              :   '',
                selectedEmployeeName            :   '',
            })
            return
        }

        this.setState({
            isLoading   :   true
        })
        this.state.selectedEmployeeId      =   e[0].id
        this.state.selectedEmployeeName    =   e[0].employeeName
        this.state.selectedEmployeeNo      =   e[0].employeeNo
        this.state.selectedProfileId        =   e[0].profileId
        this.state.selectedPosition       =   e[0].position
        this.state.selectedStatus        =   e[0].employeeStatusType
        this.state.selectedLocationName = e[0].locationName
        this.state.payCardNumber = e[0].payCardNumber
        if(e[0].dateHired === ""){
            this.state.dateHired = ""
        } else {
            this.state.dateHired = new Date(e[0].dateHired)
        }
        this.setState({
            isloading   :   false,
            isshow      :   false
        })

    }

    onChangeNature = (e) => {
        if(e.length == 0) {
            this.setState({isshow:false,selectedNatureId:"",selectedNature:"",oldpayCardNumber: "" })
            return
        }
        this.state.selectedNatureId=e[0].id
        this.state.selectedNature=e[0].name
        if(e[0].id === "2" || e[0].id === "3" || e[0].id === "4" || e[0].id === "5" ){
            this.setState({
                oldpayCardNumber : this.state.payCardNumber
            })
        }
        
    }

    onChangeReasonList = (e) => {
        if(e.length == 0) {
            this.setState({isshow:false,selectedReasonId:"",selectedReason:""})
            return
        }
        this.state.selectedReasonId=e[0].id
        this.state.selectedReason=e[0].name
        
    }

    onChangePayCardList = (e) => {
        if(e.length == 0) {
            this.setState({isshow:false,selectedPayCardId:"",selectedPayCard:""})
            return
        }
        this.state.selectedPayCardId=e[0].id
        this.state.selectedPayCard=e[0].name
        
    }

    onChangeNewPayCardNumber = (e) => {
        this.setState({ newpayCardNumber: e.target.value} );
    }

    onChangeOldPayCardNumber = (e) => {
        this.setState({ oldpayCardNumber: e.target.value} );
    }

    onChangeDescription = (e) => {
        this.setState({ value: e.target.value });
    };

    onChangeValue = (e) => {
        this.setState({ value: e.target.value });
    };

    onChangeOtherReason  = (e) => {
        this.setState({ otherReason: e.target.value });
    };

    GetStatusList(){
        //this.setState({payCardApplicationList:[], isloading:true})

        const getParams = {
            "IpAddress": "0,0,0,0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "HeaderId" : "38144",
            "StatusId" : "88",
            "RequestDate" : "38144",
            "UserId" : "38144",
            "Remarks" : "1"
        };
        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Paycard/AddApplicationStatus",  getParams)
   
            .then(res => {
            const data = res.data;
            ////console.log("data")
            ////console.log(data)
            this.setState({
                payCardStatusList: data.status,
                isloading   :   false
            })
        })
    }    


    handleChangeCheckbox(e) {
       
        this.setState({[e.target.name]: e.target.checked})
        
        const obj = {
            "DocumentId"  : e.target.value
        }
        this.state.docArrayList.push(obj)
    }
   

    handleChangeAMTSubDate = date => {
        this.setState({
            amtSubDate: date,
            isshow:false,
        })
    }

    handleChangeDate = date => {
        this.setState({dateStart: date})
    };
   
    handleChangeDateTransmit = date => {
        this.setState({dateTransmit: date})
    };

    handleChangeApplicationDate = date => {
        this.setState({applicationDate: date})
    };

    handleClickUpload = e => {
        alert("In Progress...!!")
    }
    
    IsDecimal(val)
    {
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        return !regex.test(val)
    }
    IsNumeric(evt){
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    GetApplicationList = () =>{
        this.setState({isloading:true})

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":"" ,
            "UserId":this.state.userinfo.userId,
            "EmployeeId" : "",
            "StatusId" : ""
        };
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Paycard/GetApplication",  getParams)
            .then(res => {
            const data = res.data;
            ////console.log("data")
            ////console.log(data)
            let arr = []
            for(let x =0; x < data.length; x++){
                const obj = {
                    "clientName" : data[x]["clientName"],
                    "employeeId" : data[x]["employeeId"],
                }
                arr.push(obj)
            }
            this.setState({
                payCardApplicationt: arr,
                isloading   :   false
            })
        })
    }    


    handleSaveClick = () => {
        this.setState({isloading:true})
        let valueTransfer = ""
        if( this.state.payCardApplicationt.some(item =>item.employeeId === this.state.selectedEmployeeId ) === true ){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    this.state.selectedEmployeeName + ", "  + "already exist!",
                fade            :   true
            })
            return
        }

        if(this.state.applicationDate === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "please select application date!",
                fade            :   true
            })
            return  
        }
        if(this.state.selectedNatureId === "1") {
            if(this.state.selectedReasonId !== "1" && this.state.selectedReasonId !== "2"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "invalid reason of application for new application!",
                    fade            :   true
                })
                return  
            }
        }
        if(this.state.selectedNatureId === "2") {
            if(this.state.selectedReasonId !== "3"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "invalid reason of application for new application!",
                    fade            :   true
                })
                return  
            }
        }
        if(this.state.selectedNatureId === "3") {
            if(this.state.selectedReasonId !== "4" && this.state.selectedReasonId !== "5" && this.state.selectedReasonId !== "6" && this.state.selectedReasonId !== "7" && this.state.selectedReasonId !== "8" && this.state.selectedReasonId !== "10"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "invalid reason of application for new application!",
                    fade            :   true
                })
                return  
            }
        }
        // if(this.state.selectedNatureId === "5") {
        //     if(this.state.selectedReasonId !== "4" && this.state.selectedReasonId !== "5" && this.state.selectedReasonId !== "6" && this.state.selectedReasonId !== "7" && this.state.selectedReasonId !== "8" && this.state.selectedReasonId !== "10"){
        //         this.setState({
        //             isloading       :   false,
        //             alerttype       :   "Error!",
        //             isshow          :   true,
        //             color           :   "danger",
        //             message         :    "invalid reason of application for new application!",
        //             fade            :   true
        //         })
        //         return  
        //     }
        // }
        if(this.state.selectedNatureId === "4") {
            if(this.state.selectedReasonId !== "9"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "invalid reason of application for new application!",
                    fade            :   true
                })
                return  
            }
        }
        if(this.state.selectedNatureId === "5") {
            if(this.state.value === ""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Please input value transfer!",
                    fade            :   true
                })
                return  
            }
        } else {
            this.state.value = "0"
        }
        const addParams = {
            "IpAddress"            :   "0.0.0.0",
            "UserId"           : this.state.userinfo.userId,
            "ClientId"         : this.state.selectedClientId,
            "EmployeeId"       : this.state.selectedEmployeeId,
            "ReferenceNo"       : this.state.selectedEmployeeNo,
            "ProfileId"        : this.state.selectedProfileId,
            "ApplicationDate"  : moment(this.state.applicationDate).format('MM/DD/YYYY'),
            "DateStart"        : moment(this.state.applicationDate).format('MM/DD/YYYY'),
            "PayCardTypeId"    : this.state.selectedPayCardId,
            "PayCardNumber"    : this.state.newpayCardNumber,
            "OldOayCardNumber" : this.state.oldpayCardNumber,
            "NatureId"         : this.state.selectedNatureId,
            "ReasonId"         : this.state.selectedReasonId,
            "Description"      : this.state.otherReason,
            "CardValue"        : this.state.value,
            "Remarks"          : "",
            "StatusId"         : this.state.saveStatusId,

            "CreatedBy"        : this.state.userinfo.userId,
            "documents"        : this.state.docArrayList
        };
        //console.log("addParams")
        //console.log(addParams)
        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Paycard/AddApplication",  addParams)
        //.post("http://104.248.57.90:8081/Paycard/AddApplication",  addParams)
        .then(res => {
            const data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   "Success!",
                isshow      :   true,
                color       :   "success",
                message     :   data.message,
                fade        :   true,
                applicationId : data.applicationId
            });
            this.GetApplicationList();
            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })

    }
    
    handleClickCancel = () => {
        this.setState({isloading:true})

        const cancelParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "HeaderId"      :   this.state.applicationId,
            "StatusId"      :   this.state.cancelStatusId,
            "RequestDate"   :   moment(new Date()).format('MM/DD/YYYY'),
            "CreatedBy"     :   this.state.userinfo.userId,
            "Remarks"       :   "",
         };

        //console.log("cancel Params")
        //console.log(cancelParams)

        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Paycard/AddApplicationStatus",  cancelParams)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
            this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })

    }
    
    handleClickTransmitted = () => {
        this.setState({isloading:true})

        const cancelParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "HeaderId"      :   this.state.applicationId,
            "StatusId"      :   this.state.transmittedStatusId,
            "RequestDate"   :   moment(new Date()).format('MM/DD/YYYY'),
            "CreatedBy"     :   this.state.userinfo.userId,
            "Remarks"       :   "",
         };

        //console.log("cancel Params")
        //console.log(cancelParams)

        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Paycard/AddApplicationStatus",  cancelParams)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
            this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })

    }

//     handleClickEdit = (e) => {

//         this.setState({newPayCardApplication: [],isLoading:true})
//         for (let i = 0; i < this.state.payCardApplicationList.length; i++) {
//             if (this.state.payCardApplicationList[i]["isModified"] == 1) {
//                 const obj = {
                   
//                     Id: this.state.payCardApplicationList[i]["id"],
//                     EmployeeId: this.state.payCardApplicationList[i]["id"],
//                     ProfileId: this.state.payCardApplicationList[i]["id"],
//                     ApplicationDate: this.state.payCardApplicationList[i]["id"],
//                     DateStart: this.state.payCardApplicationList[i]["id"],
//                     PayCardTypeId: this.state.payCardApplicationList[i]["id"],
//                     PayCardNumber: this.state.payCardApplicationList[i]["id"],
//                     OldOayCardNumber: this.state.payCardApplicationList[i]["id"],
//                     NatureId: this.state.payCardApplicationList[i]["id"],
//                     ReasonId: this.state.payCardApplicationList[i]["id"],
//                     Description: this.state.payCardApplicationList[i]["id"],
//                     CardValue: this.state.payCardApplicationList[i]["id"],
//                     StatusId: "31",
//                     Remarks: this.state.payCardApplicationList[i]["id"],
//                     documents: this.state.payCardApplicationList[i]["id"],
//                     IsDeleted: this.state.payCardApplicationList[i]["isDeleted"].toString()
//                 };

//                 this.state.newPayCardApplication.push(obj);

//             }
//         }

//     const params = {

//         "IpAddress":"0.0.0.0",
//         "ClientId":this.state.userinfo.clientId,
//         "UserId":this.state.userinfo.userId,
//         "Deductions":this.state.newPayCardApplication
//     };
//     ////console.log("params")
//     ////console.log(params)
//     return
//     axios
//         .post("http://104.248.57.90:8081/Paycard/EditApplication" , params)
//         .then(res => {
//             // ////console.log(deductionParams)
//             const data = res.data;
//             ////console.log(res.data)
//             this.setState({isLoading:false})
//             var alertType = (data.status=="1") ? "success" : "danger"
//             this.setState(
//                 {
//                     isLoading:false,
//                     AlertType:"Success!",
//                     show:true,
//                     Color:alertType,
//                     Message:data.message ,
//                     Fade:true
//                 });
//         })
//         .catch(error=>{
//             this.setState(
//             {
//                 isLoading:false,
//                 AlertType:"Error! ",
//                 Show:true,
//                 Color:"danger",
//                 Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
//                 Fade:true
//             })
//         })
// }

    // FormatDate(date) {
    //     let m = moment(date, 'MM-DD-YYYY');
    //     return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    // }
    formatDate(date) {
        var day = date.getDate();
        var month = date.getMonth()+1;
        var year = date.getFullYear();
        return month + '/' + day + '/' + year;
    }
    
    render() {
      
        return(
            <div>
            <Banner />
            <Container className="themed-container" fluid={true}>
                <Card className="mt-5">
                    <Card.Header >PAYCARD APPLICATION</Card.Header>
                        <Card.Body >
                            <Form className="mt-5">
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                                <Card>
                                    <Card.Header>EMPLOYEE DETAILS</Card.Header>
                                    <Card.Body>
                                        <Form.Row className="mt-2">
                                            <Form.Group as={Col} controlId="formGridAddress1">
                                                <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeClientList}
                                                    options={this.state.clientList}
                                                    placeholder="Select Client"
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridAddress1">
                                                <Form.Label style={{fontWeight : "bold"}}>Employee</Form.Label>
                                                <Typeahead
                                                    labelKey='employeeName'
                                                    id="basic-example"
                                                    onChange={this.onChangeEmployeesList}
                                                    options={this.state.employeeList}
                                                    placeholder="Select Employee"
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className="mt-2">
                                            <Form.Group as={Col} controlId="formGridAddress1">
                                                <Form.Label style={{fontWeight : "bold"}}>Emp No.</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Employee No" 
                                                    name="employeeNo"
                                                    value={this.state.selectedEmployeeNo}
                                                    readOnly
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridAddress1">
                                                <Form.Label style={{fontWeight : "bold"}}>LOCATION / BRANCH</Form.Label>
                                                <Form.Control 
                                                    type="text"
                                                    name="locationName"
                                                    value={this.state.selectedLocationName}
                                                    // onChange={this.onChangeLocation}
                                                    placeholder="Branch"
                                                    disabled
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className="mt-2">
                                            <Form.Group as={Col} controlId="formGridAddress1">
                                                <Form.Label style={{fontWeight : "bold"}}>Position</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Position" 
                                                    name="position"
                                                    value={this.state.selectedPosition}
                                                    // onChange={this.onChangeEmployeePosition.bind(this)}
                                                    readOnly
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridAddress1">
                                                <Form.Label style={{fontWeight : "bold", marginLeft : 15}}>DATE Start</Form.Label>
                                                <Form.Group as={Col} controlId="formGridCity">
                                                <DatePicker
                                                    ref='dateHired'
                                                    selected={this.state.dateHired}
                                                    onChange={this.handleChangeDate}
                                                    minDate={this.minDate}
                                                    value={this.props.dateHired}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    className="form-control"
                                                    wrapperClassName="datepicker"
                                                    disabled
                                                />
                                                </Form.Group>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className="mt-2">
                                            <Form.Group as={Col} controlId="formGridAddress1">
                                                <Form.Label style={{fontWeight : "bold"}}>Status</Form.Label>
                                                <Form.Control 
                                                    type="text"
                                                    placeholder="Status" 
                                                    name="employeeStatusType"
                                                    value={this.state.selectedStatus}
                                                    disabled
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>

                                <Card className="mt-5">
                                    <Card.Header>PayCard DETAILS</Card.Header>
                                    <Card.Body>
                                        <Form.Row className="mt-2">
                                            <Form.Group as={Col} controlId="formGridAddress1">
                                                <Form.Label style={{fontWeight : "bold", marginLeft : 15}}>Application Date</Form.Label>
                                                <Form.Group as={Col} controlId="formGridCity">
                                                <DatePicker
                                                    ref='applicationDate'
                                                    selected={this.state.applicationDate}
                                                    onChange={this.handleChangeApplicationDate}
                                                    minDate={this.minDate}
                                                    value={this.props.applicationDate}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    className="form-control"
                                                    wrapperClassName="datepicker"
                                                    // disabled

                                                />
                                                </Form.Group>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className="mt-2">
                                            <Form.Group as={Col} controlId="formGridAddress1">
                                                <Form.Label style={{fontWeight : "bold"}}>PayCard Type</Form.Label>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangePayCardList}
                                                    options={this.state.payCardList}
                                                    placeholder="Select PayCard"
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridAddress1">
                                                <Form.Label style={{fontWeight : "bold"}}>PayCard No. Old</Form.Label>
                                                <Form.Control 
                                                    type="text"
                                                    placeholder="Enter Old No." 
                                                    ref="oldpayCardNumber"
                                                    autoComplete="off"
                                                    maxLength="16"
                                                    name="oldpayCardNumber"
                                                    value={this.state.oldpayCardNumber}
                                                    onChange={this.onChangeOldPayCardNumber}
                                                    onKeyPress={this.IsNumeric.bind(this)}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridAddress1">
                                                <Form.Label style={{fontWeight : "bold"}}>PayCard No. New</Form.Label>
                                                <Form.Control 
                                                    type="text"
                                                    placeholder="Enter New No." 
                                                    ref="newpayCardNumber"
                                                    autoComplete="off"
                                                    maxLength="16"
                                                    name="newpayCardNumber"
                                                    value={this.state.newpayCardNumber}
                                                    onChange={this.onChangeNewPayCardNumber}
                                                    onKeyPress={this.IsNumeric.bind(this)}

                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className="mt-2">
                                            <Form.Group as={Col} controlId="formGridAddress1">
                                                <Form.Label style={{fontWeight : "bold"}}>Nature of Application</Form.Label>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeNature}
                                                    options={this.state.natureList}
                                                    placeholder="Select Nature"
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridAddress1">
                                                <Form.Label style={{fontWeight : "bold"}}>Value Transfer</Form.Label>
                                                <Form.Control 
                                                    type="text"
                                                    name="value"
                                                    value={this.state.value}
                                                    onChange={this.onChangeValue} 
                                                    autoComplete="off"
                                                    placeholder="Enter Value" 
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className="mt-2">
                                            <Form.Group as={Col} controlId="formGridAddress1">
                                                <Form.Label style={{fontWeight : "bold"}}>Reason of Application</Form.Label>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeReasonList}
                                                    options={this.state.reasonList}
                                                    placeholder="Select Reason"
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridAddress1">
                                                <Form.Label style={{fontWeight : "bold"}}>Others Specify</Form.Label>
                                                <Form.Control 
                                                    type="text"
                                                    name="otherReason"
                                                    value={this.state.otherReason}
                                                    onChange={this.onChangeOtherReason} 
                                                    autoComplete="off"
                                                    placeholder="Enter Reason" 
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <fieldset className="border p-2 mt-5">
                                        <legend className="w-auto">Attachments </legend>
                                            <Form.Group as={Row} className="mt-3" controlId="formHorizontalEmail">
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <Col sm={1}>
                                                    <Form.Check 
                                                        type="checkbox" 
                                                        label="Payroll Form"
                                                        onChange={e => this.handleChangeCheckbox(e)}
                                                        checked={this.state.checkbox}
                                                        // disabled={true}
                                                        value= "1"
                                                        name="payrollForm"
                                                    />
                                                </Col>
                                                <Col sm={1}>
                                                    <Form.Check 
                                                        type="checkbox" 
                                                        label="LOI Form"
                                                        onChange={e => this.handleChangeCheckbox(e)}
                                                        checked={this.state.checkbox}
                                                        // disabled={true}
                                                        value= "4"
                                                        name="loiForm"
                                                    />
                                                </Col>
                                                <Col sm={1}>
                                                    <Form.Check 
                                                        type="checkbox" 
                                                        label="Valid Id"
                                                        onChange={e => this.handleChangeCheckbox(e)}
                                                        checked={this.state.checkbox}
                                                        // disabled={true}
                                                        value= "2"
                                                        name="validId"
                                                    />
                                                </Col>
                                                <Col sm={1}>
                                                    <Form.Check 
                                                        type="checkbox" 
                                                        label="BDO APPLICATION FORM"
                                                        onChange={e => this.handleChangeCheckbox(e)}
                                                        checked={this.state.checkbox}
                                                        // disabled={true}
                                                        value= "7"
                                                        name="bdoApplicationForm"
                                                    />
                                                </Col>
                                                <Col sm={2}></Col>
                                                <Col sm={1}>
                                                    <Form.Label style={{fontWeight : "bold"}}>Upload DOCUMENTS</Form.Label>
                                                </Col>
                                                <Col sm={2}>
                                                <ButtonToolbar className="mt-1">
                                                    <Button variant="success" className="noser-button" onClick={ this.handleClickUpload }>Upload</Button>
                                                </ButtonToolbar>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mt-1" controlId="formHorizontalEmail">
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <Col sm={1}>
                                                    <Form.Check 
                                                        type="checkbox" 
                                                        label="Affidavit Loss"
                                                        onChange={e => this.handleChangeCheckbox(e)}
                                                        checked={this.state.checkbox}
                                                        // disabled={true}
                                                        value= "5"
                                                        name="affidavit"
                                                    />
                                                </Col>
                                                <Col sm={1}>
                                                    <Form.Check 
                                                        type="checkbox" 
                                                        label="Cash Card"
                                                        onChange={e => this.handleChangeCheckbox(e)}
                                                        checked={this.state.checkbox}
                                                        // disabled={true}
                                                        value= "3"
                                                        name="cashCard"
                                                    />
                                                </Col>
                                                <Col sm={1}>
                                                    <Form.Check 
                                                        type="checkbox" 
                                                        label="ATD"
                                                        onChange={e => this.handleChangeCheckbox(e)}
                                                        checked={this.state.checkbox}
                                                        // disabled={true}
                                                        value= "6"
                                                        name="atd"
                                                    />
                                                </Col>
                                                <Col sm={1}>
                                                    <Form.Check 
                                                        type="checkbox" 
                                                        label="CIF FORM"
                                                        onChange={e => this.handleChangeCheckbox(e)}
                                                        checked={this.state.checkbox}
                                                        // disabled={true}
                                                        value= "8"
                                                        name="cifForm"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mt-1" controlId="formHorizontalEmail">
                                                
                                                
                                                
                                            </Form.Group>
                                        </fieldset>
                                        <Form.Group as={Row} className="mt-5"  controlId="formHorizontalEmail">
                                            <Col sm={1}>
                                            <Form.Label style={{fontWeight : "bold"}}>Prepared By</Form.Label>
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Control 
                                                type="text" 
                                                autoComplete="off" 
                                                /* name="selectedClient" */
                                                value={this.state.userinfo.fullName}
                                                readOnly
                                                />
                                            </Col>
                                            <Col sm={1}>
                                            <Form.Label style={{fontWeight : "bold"}}>Position</Form.Label>
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Control 
                                                    type="text" 
                                                    autoComplete="off" 
                                                    value={this.state.userinfo.roleName}
                                                    readOnly
                                                />
                                            </Col>
                                            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <Col sm={2} className="ml-auto">
                                                <DatePicker
                                                    ref='dateTransmit'
                                                    selected={this.state.dateTransmit}
                                                    onChange={this.handleChangeDateTransmit}
                                                    minDate={this.minDate}
                                                    value={this.props.dateTransmit}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    className="form-control"
                                                    wrapperClassName="datepicker"
                                                    // disabled
                                                />
                                            </Col> */}
                                            
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formHorizontalEmail">
                                            <Col sm={1}>
                                                <Form.Label style={{fontWeight : "bold"}}>Department</Form.Label>
                                            </Col>
                                        
                                            <Col sm={3}>
                                                <Form.Control 
                                                    type="text"
                                                    name="position"
                                                    value={this.state.department}
                                                    disabled
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <ButtonToolbar className="mt-5">
                                    <Button disabled={this.state.disabledButton} variant="success" className="ml-auto noser-button-mr1" onClick={ this.handleSaveClick }>Save</Button>&nbsp;&nbsp;
                                    <Button disabled={this.state.disabledButton} variant="success" className="noser-button" onClick={ this.handleClickCancel } >Cancel</Button>&nbsp;&nbsp;
                                    <Button disabled={this.state.disabledButton} variant="success" className="noser-button"  onClick={ this.handleClickTransmitted }>Transmit</Button>&nbsp;&nbsp;
                                    <Button disabled={this.state.disabledButton} variant="success" className="noser-button" onClick={ this.handleClickback }>Back</Button>&nbsp;&nbsp;
                                    <Button href='/home' variant="danger" className="noser-button">Close</Button>
                                </ButtonToolbar>
                            </Form>
                        </Card.Body>
                       
                </Card>
            </Container>
            <NoserLoading show={this.state.isloading} />
            </div>
        )
    }
}
export  default PayCardApplication
