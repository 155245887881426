import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker, LeaveModal, Tabs, Tab,
    sizePerPageRenderer,Modal,
} 
from '../../noser-hris-component';
import NumberFormat from 'react-number-format';

class PayrollperiodModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo  :   [],
            isloading :   false,
            isshow    :   false,
            alerttype :   "",
            message   :   "",
            color     :   "",
            fade      :   true,
            fullName    :"",
            IsActive    :"false",
            payrollMode : [
                {
                    "id":"3",
                    "name":"Monthly"

                },
                {
                    "id":"1",
                    "name":"Semi-Monthly"
                },
                {
                    "id":"2",
                    "name":"Weekly"
                },
                {
                    "id":"4",
                    "name":"Daily"
                },
            ],
            selectedMode:"",
            leadTime:"",
            periodStart:"",
            modeId:"",
            dateEnd:"",
            dateStart:"",
            paymodeSetup:"",
            payoutWeekly:[
                {
                    "id":"1",
                    "name":"MONDAY"
                },
                {
                    "id":"2",
                    "name":"TUESDAY"
                },
                {
                    "id":"3",
                    "name":"WEDNESDAY"
                },
                {
                    "id":"4",
                    "name":"THURSDAY"
                },
                {
                    "id":"5",
                    "name":"FRIDAY"
                },
                {
                    "id":"6",
                    "name":"saturday"
                },
                {
                    "id":"7",
                    "name":"SUNDAY"
                },

            ]
        }
         
    }

    componentDidMount() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        sleep(1000).then(() => { this.setState({isloading:false}) }) 
       
    }

    onChangeFullName(e){
        this.setState({fullName:e.target.value})
    }

    onChangeleadTime(e){
        this.setState({leadTime:e.target.value})
    }

    onChangeperiodStart(e){
        this.setState({periodStart:e.target.value})
    }

    handleChangeIsActive = (e) =>{
        console.log(e)
        if(e.target.unchecked) 
            this.setState({IsActive: "false"})
        else
        {
            this.setState({IsActive: ""})
            
        }
    }

    handleChangedateStart =date=>{
        this.setState({
            dateStart: date
        })
    }

    handleChangedateEnd =date=>{
        this.setState({
            dateEnd: date
        })

        let newName =""
        let Periodmonth = moment(this.state.dateStart).format('YYYY');
        let Startmonth = moment(this.state.dateStart).format('MMM');
        let Endmonth = moment(date).format('MMM');

       

        if(Startmonth == Endmonth){

            let PeriodStart = moment(this.state.dateStart).format('DD');
            let PeriodEnd = moment(date).format('DD');
            newName = Endmonth +"-"+ PeriodStart +"/"+PeriodEnd+", "+Periodmonth 

        }else{

            let PeriodStart = moment(this.state.dateStart).format('MMM-DD');
            let PeriodEnd = moment(date).format('MMM-DD');
            newName = PeriodStart +"/"+PeriodEnd+","+Periodmonth   

        }

         

        console.log("newName")
        console.log(newName)
        
        this.state.command = newName
        this.state.fullName = newName

    }

    handleSaveClick = (e) => {
        let PeriodStart = moment(this.state.dateStart).format('MM/DD/YYYY');
        let PeriodEnd = moment(this.state.dateEnd).format('MM/DD/YYYY');

        const Params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "CompanyId":this.state.userinfo.companyId,
            "PayrollModeId":this.state.modeId,
            "PeriodName" :  this.state.fullName,
            "StartDate" : PeriodStart,
            "EndDate" : PeriodEnd,
            "CreatedBy" : this.state.userinfo.userId,
        }
        console.log("handleSaveClick AddPayrollPeriod")
        console.log(Params)

        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/AddPayrollPeriod",  Params
            )
            .then(res => {
                const data = res.data;
                console.log("AddPayrollPeriod");
                console.log(data)
                if(data.status=="1"){
                    this.setState({
                            isloading   :   false,
                            alerttype   :   "Success!",
                            isshow      :   true,
                            color       :   "success",
                            message     :   data.message,
                            fade        :   true,
                            command:"",
                            fullName:""
                        });
                    
                    }
                else {
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
                
            })
        
    }

    onChangepayrollMode = (e) => {
        if(e.length==0)
        {
            this.setState({
                fullName     :   '',
            })
            return
        }
        this.state.fullName = e[0].name 
        this.state.modeId = e[0].id    
               
        this.setState({
            fullName   : e[0].name,
            isloading:true,

        })

        this.showPayoutBox()

       
       
    }

    showPayoutBox=()=>{
        
        if( this.state.modeId == "3"){
            this.state.paymodeSetup = ""
            this.setState({ isloading:false, })
        }
        if( this.state.modeId == "1"){
            this.state.paymodeSetup = "1"
            this.setState({ isloading:false, })
        }
        if( this.state.modeId == "2"){
            this.state.paymodeSetup = "2"
            this.setState({ isloading:false, })
        }
        if( this.state.modeId == "4"){
            this.state.paymodeSetup = ""
            this.setState({ isloading:false, })
        }
    }

    onChangePayoutWeek = (e) => {
        if(e.length==0)
        {
            this.setState({
                fullName     :   '',
            })
            return
        }
        this.state.fullName = e[0].name 
        //this.state.modeId = e[0].id    
               
        this.setState({
            fullName   : e[0].name,
        })
       
    }

    handleModalClose = () => {
        this.props.onHide();            
    }


    render() {
        

        return(
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className="modal-90w"
                >
                <Modal.Header closeButton className="card-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create Payroll Periods / Cutoff
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Col sm={9}>
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.onChangepayrollMode}
                                        options={this.state.payrollMode}
                                        placeholder='select payroll mode'
                                    />
                                </Col>

                            </Form.Group>

                            
                                <Form.Group  as={Row} controlId="formPlaintextEmail">
                                    <Col sm={9}>
                                        <Form.Control 
                                            type="text"
                                            name="fullName"
                                            value={this.state.command}
                                            onChange={this.onChangeFullName.bind(this)} 
                                            autoComplete="off"
                                            placeholder='enter period name'
                                            disabled={true}
                                        />
                                    </Col>

                                </Form.Group>
                          

                            {/* this.state.paymodeSetup == "1" ?
                                <Form.Group  as={Row} controlId="formPlaintextEmail">
                                    <Col sm={9}>
                                        <NumberFormat 
                                            type="text"
                                            name="fullName"
                                            value={this.state.command}
                                            placeholder="input Payout"
                                            onChange={this.onChangeFullName.bind(this)} 
                                            autoComplete="off"
                                            format="##/##"
                                        />
                                    </Col>

                                </Form.Group>:null */
                            }

                            {/* this.state.paymodeSetup == "2" ?
                                <Form.Group  as={Row} controlId="formPlaintextEmail">
                                    <Col sm={9}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangePayoutWeek}
                                            options={this.state.payoutWeekly}
                                            placeholder='select Payout'
                                        />
                                    </Col>

                                </Form.Group>:null */
                            }

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    Start Date
                                </Form.Label>
                                <Col sm="2">
                                    <DatePicker
                                        ref='date'
                                        selected={this.state.dateStart}
                                        onChange={this.handleChangedateStart}
                                        value={this.state.dateStart}
                                        dateFormat={"MM/dd/yyyy"}
                                        style={{height:'40px !important'}}
                                        size="40"
                                        className="form-control"
                                    />
                                </Col>

                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    End Date
                                </Form.Label>
                                <Col sm="2">
                                    <DatePicker
                                        ref='date'
                                        selected={this.state.dateEnd}
                                        onChange={this.handleChangedateEnd}
                                        value={this.state.dateEnd}
                                        dateFormat={"MM/dd/yyyy"}
                                        style={{height:'40px !important'}}
                                        size="40"
                                        className="form-control"
                                    />
                                </Col>
                                
                            </Form.Group>
                           
                            <ButtonToolbar sm={12} className="mt-3">
                                <Button variant="success" className="ml-auto" onClick={this.handleSaveClick}  style={{minWidth:'60px'}}>
                                    Save
                                </Button>&nbsp;&nbsp;
                                <Button variant="danger"  onClick={this.handleModalClose}  style={{minWidth:'60px'}}>
                                    Back
                                </Button>
                            </ButtonToolbar>
                        </Form>
                    </Container>
                </Modal.Body>

                <NoserLoading show={this.state.isloading} />
            </Modal>
           
            
        )
    }
}

export default PayrollperiodModal;