import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Modal, DatePicker, NumberFormat, Image
} 
from '../../../noser-hris-component';

import ReactToPrint from "react-to-print";
import paramountHeader from '../../../icon/contract-header.PNG'
import paramountFooter from '../../../icon/contract-footer.PNG'

class RegularCoopContract extends React.Component {
    
    render() {
      return (
        <div style={{backgroundColor:"#FFF",height:"100%"}}>
            <div style={{width:"100%"}}>
                <Col sm="3" className="noser-button" as={Row} style={{width:"150px",
                                                height:"25px",
                                                marginLeft:"auto",
                                                marginRight:"auto"}}>
                    <ReactToPrint 
                        trigger={() => <button style={{width:"150px",
                                                height:"25px",
                                                marginLeft:"auto",
                                                marginRight:"auto",
                                                color:"#ffffff",
                                                fontWeight:"bold",
                                                backgroundColor:"rgb(8, 139, 30)",
                                                border:".5pt solid rgba(17, 99, 31, 0.644)"}}>Print Preview</button>}
                        content={() => this.componentRef}
                    />
                </Col>
            </div>
            <ComponentToPrint ref={(el) => (this.componentRef = el)} />  
        </div>
      );
    }
  }
export  default RegularCoopContract

class ComponentToPrint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,      
            disable         :   true,

            employeeName    :   "",
            lastName        :   "",
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        document.title = "Regular Coop Contract";
        let data = JSON.parse(sessionStorage.getItem("contract_" + this.state.userinfo.userId + this.state.userinfo.employeeId))
        this.setState({
            employeeName : data.employeeName,
            lastName : data.lastName,
            issueDate : moment(new Date(data.issueDate)).format('MMMM DD, YYYY')
        })
    }
    render() {
        return( 
            <>
            <Container className="mt-0 noser-a4-body-page" fluid>
                <div className='noser-a4-page' >
                <Form style={{fontFamily:'Century Gothic'}}>
              {/*   <Col style={{marginLeft:'-30px', marginTop:"50px"}} >
                    <Image src={paramountHeader} style={{width:"900px", height:"200px"}} />
                </Col> */}
                <Form.Group as={Row} controlId="formPlaintextEmail" className='mt-5'>
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontSize: 25}}>
                        To
                        <span className="no-text-transform" style={{fontFamily:'Century Gothic',fontWeight:'bold',marginLeft:"42px",marginRight:"40px",fontSize: 25,whiteSpace: "pre-wrap"}}>
                        :
                        </span>
                        <span className="no-text-transform" style={{fontFamily:'Century Gothic',fontWeight:'bold',textAlign:'justify',fontSize: 25,whiteSpace: "pre-wrap"}}>
                        Mr. / Ms. {this.state.employeeName}
                        </span>
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontSize: 25}}>
                        From
                        <span className="no-text-transform" style={{fontFamily:'Century Gothic',fontWeight:'bold',marginLeft:"10px",marginRight:"40px",fontSize: 25,whiteSpace: "pre-wrap"}}>
                        :
                        </span>
                        <span className="no-text-transform" style={{fontFamily:'Century Gothic',fontWeight:'bold',textAlign:'justify',fontSize: 25,whiteSpace: "pre-wrap"}}>
                        {/* {this.state.employeeName} */}
                         HUMAN RESOURCE MULTI-PURPOSE COOPERATIVE
                        </span>
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-1">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontSize: 25}}>
                        Re
                        <span className="no-text-transform" style={{fontFamily:'Century Gothic',fontWeight:'bold',marginLeft:"42px",marginRight:"40px",fontSize: 25,whiteSpace: "pre-wrap"}}>
                        :
                        </span>
                        <span className="no-text-transform" style={{fontFamily:'Century Gothic',fontWeight:'bold',textAlign:'justify',fontSize: 25,whiteSpace: "pre-wrap"}}>
                        NOTICE OF MEMBERSHIP REGULARIZATION
                        </span>
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <h1  style={{backgroundColor:'#000000', width:"100%", height:"5px",marginTop:"40px" }} > </h1>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-5">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontSize: 25}}>
                        Dear, &nbsp;
                        <span className="no-text-transform" style={{fontFamily:'Century Gothic',fontWeight:'bold',textAlign:'justify',fontSize: 25,whiteSpace: "pre-wrap"}}>
                        Mr. / Ms. {this.state.lastName},
                        </span>
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-5">
                    <Col>
                        <p style={{fontFamily:'Century Gothic',textAlign:'justify',fontSize: 25,whiteSpace: "pre-wrap" }}>
                        We are pleased to inform you that you are qualified to be a REGULAR member of &nbsp;
                        <span className="no-text-transform" style={{fontStyle:'italic',fontWeight:'bold',textAlign:'justify',fontSize: 25,whiteSpace: "pre-wrap"}}>
                         Human Resource Multi-Purpose Cooperative. 
                        </span>
                        </p>
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{fontFamily:'Century Gothic',textAlign:'justify',fontSize: 25,whiteSpace: "pre-wrap" }}>
                        Kindly accomplish the attached application for regular membership.
                        </p>
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{fontFamily:'Century Gothic',textAlign:'justify',fontSize: 25,whiteSpace: "pre-wrap" }}>
                        Thank you for your continuous support to our cooperative.
                        </p>
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontFamily:'Century Gothic',width:"95%", fontSize: 26, whiteSpace: "pre-wrap",marginTop:"40px" }}>
                        Sincerely,
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontFamily:'Century Gothic',marginTop:"40px", fontSize: 25 }}>
                        Ms. Rodilyn T. Botenes
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" >
                    <Col>
                        <Form.Label className="no-text-transform" row style={{ fontFamily:'Century Gothic',fontWeight:"bold", fontSize: 25 }}>
                        Chairman-Board of Director
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{ fontFamily:'Century Gothic',marginTop:"40px", fontSize: 25 }}>
                        Ms. Jessieca G. Peña
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" >
                    <Col>
                        <Form.Label className="no-text-transform" row style={{ fontFamily:'Century Gothic',fontWeight:"bold",fontSize: 25 }}>
                        General Manager 
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                {/* <Col style={{width:'100%'}}>
                    <Image src={paramountFooter} 
                        style={{width:"400px", 
                                height:"100px", 
                                marginLeft:'210px',
                                marginTop:"100px"}}/>
                </Col> */}
            </Form>
                </div>
            </Container>
            <NoserLoading show={this.state.isloading} />
            </>
        )
    }
}

