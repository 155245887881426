import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Redirect
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

class ApplicationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,      

            clientDDL : [],
            companyId : "",
            employeeDDL : [],
            referenceNo : "",
            statusId : "",
            tinNumber : "",
            applicationFormNo : "",
            applicationGrid : [],
            companyName : "",
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        console.log("this.state.userinfo")
        console.log(this.state.userinfo)
        this.GetClient(); 
    }
    GetClient = async()=> {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"      :   this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  clientParams)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL:data.clients});
            this.GetApplicationFormFilter()
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    GetApplicationFormFilter = async()=> {
        this.setState({isloading:true})
        const params = {
            "IpAddress" :   "0.0.0.0",
            "CompanyId"  :   this.state.userinfo.companyId,
            "UserId"    :   this.state.userinfo.userId,
            "TypeId"    :   "1",
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationFormFilter",  params)
        .then(res => {
            const data = res.data;
            this.setState({employeeDDL:data.applicationForms,isloading:false})
            //console.log(this.state.employeeDDL)
            this.GetApplicationList();
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }
    GetApplicationList = async()=> {
        this.setState({applicationFormListGrid:[], applicationsList:[], isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "ReferenceNo":this.state.referenceNo,
            "PositionId":"",
            "StatusId":this.state.statusId,
            "TINNumber":this.state.tinNumber,
            "ApplicationFormId" : this.state.applicationFormId,
        };
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationForms",  params)
            .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({applicationGrid:data.applicationForms,isloading:false});
            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    
    
    handleChangeClient = (e) => {
        if(e.length == 0) {
            this.state.companyId     =   ""
            this.state.companyName   =   ""
            return
        }
        this.state.companyId     =   e[0].id
        this.state.companyName   =   e[0].name
        this.setState({
            isshow:false,
        })
        this.GetApplicationFormFilter();
    }

    handleChangeEmployee = (e) => {
        
        if(e.length == 0) {
            this.state.applicationFormId    =   ""
            this.state.profileId            =   ""
            this.state.referenceNo          =   ""
            this.state.tinNumber            =   ""
            this.state.statusId             =   ""
            return
        }
        this.state.applicationFormId    =   e[0].applicationFormId
        this.state.profileId            =   e[0].profileId
        this.state.referenceNo          =   e[0].referenceNo
        this.state.tinNumber            =   e[0].tinNumber
        this.state.statusId             =   e[0].statusId
        this.setState({
            isshow:false,
        })
        //this.GetApplicationList();
    }

    handleSearchClick = () => {
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "ReferenceNo":this.state.referenceNo,
            "ProfileId":this.state.profileId,
            "PositionId":"",
            "StatusId":this.state.statusId,
            "TINNumber":this.state.tinNumber,
            "ApplicationFormId" : this.state.applicationFormId,
        };
        //console.log(params)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationForms",  params)
            .then(res => {
            const data = res.data;
            this.setState({
                applicationGrid :   data.applicationForms,
                isloading       :   false
            });

            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleClickedRedirect = (row) => {
        if(row.statusId==="3"){
            sessionStorage.setItem("applicationformprelim_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigatepreliminary:true})
        }
        else if(row.statusId==="4"){
            sessionStorage.setItem("applicationformexam_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigateexam:true})
        }
        else if(row.statusId==="6"){
            sessionStorage.setItem("applicationformbackground_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigatebackground:true})
            // console.log(row)
        }
        else if(row.statusId==="7"){
            sessionStorage.setItem("applicationforminterviews_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigateinterviews:true})
        }
        else if(row.statusId==="8"){
            sessionStorage.setItem("applicationform_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigatejoboffer:true})
        }
        else if(row.statusId==="9"){
            sessionStorage.setItem("CreateLandDTraining_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigateCreateLandDTraining:true})
        }
        else if(row.statusId==="13"){
            sessionStorage.setItem("applicationformendorsement_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigateendorsement:true})
        }
        else if(row.statusId==="19"){
            sessionStorage.setItem("applicationformrehire_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigateapplication:true})
        }
        else {
            sessionStorage.setItem("editapplicationform_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigate:true})
        }
    }
    handleClickedEdit = (row) =>{
        if(row.statusId!=="19"){
            sessionStorage.setItem("editapplicationform_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigate:true})
        }
    }
    render() {
        const {navigate, navigatejoboffer,  navigateexam, navigatepreliminary, navigatebackground, navigateendorsement, navigateinterviews, navigateapplication, navigateCreateLandDTraining} = this.state;
        if (navigate) {
            return <Redirect to="/applicationformedit" push={true} />;
        } 
        
        if (navigatejoboffer) {
            return <Redirect to="/joboffercreate" push={true} />;
        } 
        
        if (navigateexam) {
            return <Redirect to="/examresultcreate" push={true} />;
        } 
        
        if (navigatepreliminary) {
            return <Redirect to="/preliminaryinterview" push={true} />;
        } 
        
        if (navigatebackground) {
            return <Redirect to="/BackgroundInvestigation" push={true} />;
        }  
        
        if (navigateendorsement) {
            return <Redirect to="/clientendorsement" push={true} />;
        } 

        if (navigateinterviews) {
            return <Redirect to="/finalinterviewcreate" push={true} />;
        } 
        if (navigateapplication){
            return <Redirect to="/ClientEndorsementApproval" push={true} />;
        } 
        if (navigateCreateLandDTraining){
            return <Redirect to="/CreateLandDTraining" push={true} />;
        } 
        // if (navigateClientEndorsementApproval){
        //     return <Redirect to="/ClientEndorsementApproval" push={true} />;
        // } 
        
        const applicationColumn = [
            {
                dataField: 'employeeName',
                text: 'FULL NAME',
                headerStyle: () => {
                    return { width: "20%" };
                },

                formatter   :   (cell, row, isSelect) => {
                    if(row.statusId!=="19"){
                        return (
                            <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                                variant="link" onClick={e => this.handleClickedEdit(row)}
                        >{cell}</Button>
                        );
                    }
                    else{
                        return (
                            cell
                        );
                    }
                } 
            },
            {
                dataField: 'applicationFormNo',
                text: 'REFERENCE NO.',
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'client',
                text: 'CLIENT/S',
                headerStyle: () => {
                    return { width: "25%" };
                },
            },
            {
                dataField: 'position',
                text: 'POSITION/s APPLIED',
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'status',
                text: 'STATUS',
                editable:false,
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'action',
                text: 'ACTION',
                headerStyle: () => {
                    return { width: "10%" };
                },
                editable:false,
                formatter   :   (cell, row, isSelect) => {
                    if(row.statusId==="3" || 
                        row.statusId==="4" ||
                        row.statusId==="6" ||
                        row.statusId==="7" ||
                        row.statusId==="8" ||
                        row.statusId==="9" ||
                        row.statusId==="13" || 
                        row.statusId==="19")
                    return (
                        <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link" onClick={e => this.handleClickedRedirect(row)}
                        >Create</Button>
                        );
                },
            }   
        ] 
         
    
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.applicationFormListGrid.map(function(item,i){
                   
                    if(item.id===row.id)
                        {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })

            }
        };
            const rowEvents = {
            onClick: (e, row, rowIndex) => {

            }
        };

    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>RECRUITMENT &#62;&#62; APPLICATION FORM</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                            <Typeahead 
                                                labelKey='memberName'
                                                id="basic-example"
                                                onChange={this.handleChangeEmployee}
                                                options={this.state.employeeDDL}
                                                placeholder="Select Employee" 
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                        </Form.Group>
                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="mt-2">
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <Form.Label style={{fontWeight : "bold"}}>TIN NUMBER</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                name="tinNumber"
                                                value={this.state.tinNumber}
                                                disabled
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <Form.Label style={{fontWeight : "bold"}}>REFERENCE NO</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                name="referenceNo"
                                                value={this.state.referenceNo}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <ButtonToolbar className="mt-1">
                                        <Button variant="info" className="ml-auto" onClick={ this.handleSearchClick }>
                                            Search
                                        </Button>
                                    </ButtonToolbar>
                                    <div className="mt-5">  
                                        <div>
                                            <Card.Header>APPLICATION FORM LIST</Card.Header>
                                            <BootstrapTable
                                                /* caption={Noser.TableHeader({title:"RECORD"})} */
                                                keyField = "id"
                                                data = { this.state.applicationGrid }
                                                columns = { applicationColumn }
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                rowClasses="noser-table-row-class no-checkbox"
                                                striped
                                                hover
                                                condensed
                                                cellEdit = { cellEditFactory({
                                                    mode: 'dbclick',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                        //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                //selectRow = { requestMonitoringSelectRow }
                                                //rowEvents={ requestMonitoringRowEvents }
                        
                                            />
                                        </div>
                                    </div>
                                    <ButtonToolbar sm={12}>
                                        <NavLink className="ml-auto" to="/ApplicationFormCreate">
                                            <Button className="noser-button-mr1 noser-button"  variant="success">Create</Button>
                                        </NavLink>
                                    
                                        <Button  className="noser-button"  variant="danger" href="/home">Close </Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }

}

export  default ApplicationForm 
