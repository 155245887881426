import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

class ManageAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            selected: [],
            statusList:[],
            employeeProfileList:[],
            employeeProfileListGrid:[],
            selectedSortBy:"",
            selectedSortById:"",
            selectedEmployeeName:"",
            selectedEmployeeId:"",
            selectedProfileId:"",
            selectedClientName:"",
            selectedClientId:"",
            getClientList:[]

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetSubmittedEmployeeProfiles();
        this. getStatus();
        // this. getClient();
        
        sleep(1000).then(() => {
        this.setState({isLoading:false})})
        }

    handleCoverChangeSortBy = (e) => {
        if (e.length > 0) {
            this.state.selectedSortBy = e[0].name
            this.state.selectedSortById = e[0].id

        } else {
            this.state.selectedSortBy = ""
            this.state.selectedSortById = ""
        }
        
        console.log(this.state.selectedSortBy);
        
    } 
    
    handleChangeEmployee= (e) => {
        console.log(e)
            if (e.length == 0) {
                this.setState({selectedEmployeeName: null, selectedEmployeeNameId:"", selectedProfileId:''})
            return
        }
         this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeNameId = e[0].id
        this.state.selectedProfileId =e[0].employeeName

        // this.GetSubmittedEmployeeProfiles();
        this.setState({isloading:false,})
    }

    GetSubmittedEmployeeProfiles() {
        this.setState({
            employeeProfileList:[]
            
        })
        const employeeParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "ProfileId":"",
            "StatusId":"2"
        };
        console.log("Test 123");
        console.log(employeeParams)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetEmployeeProfileForApprovals",  employeeParams)
        .then(res => {
            const data = res.data;
            console.log("Test app234");
            console.log(data);
            this.setState({ employeeProfileList: data.employeeProfiles});
            if(data.status=="1"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   data.message,
                    fade            :   true
                });
            }
            else{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
            })
            .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }

    getStatus(){
        const statusParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Code":"0021"
        };
        

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences", statusParams)
            .then(res => {
                const data = res.data;
                this.setState({
                    statusList : res.data.dataReferences})
                    
                })
                
            }

        handleSearchClick = event => {
            this.setState({employeeProfileListGrid:[], isloading:true})
    
            const searchParams = {
                "IpAddress":"0.0.0.0",
                "ClientId":this.state.userinfo.clientId,
                "UserId":this.state.userinfo.userId,
                "RoleId":this.state.userinfo.roleId,
                "ProfileId":"",
                "StatusId":"2"
                
            };
            console.log(searchParams)
            axios
                .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetEmployeeProfileForApprovals",  searchParams)
                .then(res => {
                const data = res.data;
                console.log(res.data)
                this.setState({ employeeProfileListGrid: data.employeeProfiles});
                // this.processGrid();
                if(data.status=="1"){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Success!",
                        isshow          :   true,
                        color           :   "success",
                        message         :   data.message,
                        fade            :   true
                    });
                }
                else{
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   data.message,
                        fade            :   true
                    })
                }
                })
                .catch(error=>{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })
    
        }

    render() {
        const columns1 = [
            {
                dataField: 'employeeName',
                text: 'FULL NAME',
                headerStyle: () => {
                    return { width: "40%" };
                },
            },
            {
                dataField: 'status',
                text: 'APPROVAL STATUS',
                headerStyle: () => {
                    return { width: "25%" };
                },
            },
            {
                dataField: 'createdDate',
                text: 'SUBMITTED DATE',
                headerStyle: () => {
                    return { width: "25%" };
                },
            },
            ] 
       
        return(
            <div>
                <Banner />
                    <Container className="mt-5">
                        <Card>
                            <Card.Header>Profile Search</Card.Header>
                            <Card.Body>
                                <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Row>
                                        <Col sm={6} className="mt-3">
                                        <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleChangeEmployee}
                                                options={this.state.employeeProfileList}
                                                placeholder="EMPLOYEE"
                                            />                           
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                             <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.handleCoverChangeSortBy}
                                                    options={this.state.statusList}
                                                    placeholder="SORT BY"
                                                /> 
                                        </Col>
                                    </Form.Row>
                                    
                                    <ButtonToolbar sm={12} className="mt-3">
                                        <Button variant="primary" className="ml-auto" onClick={this.handleSearchClick}>
                                            Search
                                        </Button>&nbsp;&nbsp;
                                        <NavLink to="/AdminApproved">
                                            <Button  variant="primary" variant="success">
                                                Batch Approved
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                    <div className="mt-5">
                                        <BootstrapTable
                                            keyField='id'
                                            data={ this.state.employeeProfileListGrid }
                                            columns={ columns1 }
                                            // selectRow={ selectRow }
                                        />
                                      
                                    </div>
                                        
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                    <NoserLoading show={this.state.isLoading} />
            </div> 
            
        )
    }
}

export default ManageAdmin;