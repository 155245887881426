import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Redirect
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import { ja } from 'date-fns/locale';

class ClientEndorsementCreate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            navigate    : false,
            clientDDL : [],
            clientId : "",
            positionDDL : [],
            positionId : "",
            date : "",
            responseDDL : [],
            attention : "",
            remarks : "",
            responseId : "",
            //data : JSON.parse(sessionStorage.getItem("applicationformendorsement_"))
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.data = JSON.parse(sessionStorage.getItem("applicationformendorsement_" + this.state.userinfo.userId + this.state.userinfo.employeeId))
        
        this.state.applicationId   =   this.state.data.id
        this.state.profileId       =   this.state.data.profileId
        this.state.clientName      =   this.state.data.client
        this.state.clientId        =   this.state.data.clientId
        this.state.memberName      =   this.state.data.employeeName
        this.state.position        =   this.state.data.position
        this.state.positionId      =   this.state.data.positionId
        //this.state.address          = this.state.data.applicantAddresses[0].houseNumber + ", " + this.state.data.applicantAddresses[0].streetName+ ", " + this.state.data.applicantAddresses[0].barangay + ", " + this.state.data.applicantAddresses[0].city + ", " + this.state.data.applicantAddresses[0].province
       // this.state.address          = this.state.data.applicantAddresses[0].houseNumber.concat(', ', this.state.data.applicantAddresses[0].streetName, ', ', this.state.data.applicantAddresses[0].barangay, ', ', this.state.data.applicantAddresses[0].city, ', ',this.state.data.applicantAddresses[0].province)
        this.state.assessedBy = this.state.userinfo.fullName

    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.GetClient();
        this.GetPosition();
        this.GetResponse();
        this.GetApplicationFormDetails();
    }
    
    GetApplicationFormDetails = async()=> {
        //this.setState({isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ApplicationId" : this.state.applicationId,
        };
        console.log("applicationParams")
        console.log(params)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationFormDetails",  params)
            .then(res => {
            const data = res.data;
            console.log("GetApplicationFormDetails")
            console.log(data)
            if(data.status==="1"){
                this.setState({
                    address :    data.applicantAddresses[0]["houseNumber"] + ", " +
                                        data.applicantAddresses[0]["streetName"] + ", " + 
                                        data.applicantAddresses[0]["barangay"] + ", " + 
                                        data.applicantAddresses[0]["city"] + ", " + 
                                        data.applicantAddresses[0]["province"] + ", " +
                                        data.applicantAddresses[0]["region"]
                })
            }
            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    GetClient = async()=> {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  clientParams)
        .then(res => {
            const data = res.data;
            this.setState({
                clientDDL   :   data.clients,
                isloading   :   false
            });
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleChangeClient = (e) => {
        if(e.length == 0) {
            this.state.clientId     =   ""
            this.state.client   =   ""
            return
        }
        this.state.clientId     =   e[0].id
        this.state.client   =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    GetPosition = async()=> {
        this.setState({isloading:true})
        const positionParams = {
            "IpAddress"     : "0.0.0.0",
            "ClientId"      : this.state.userinfo.clientId,
            "UserId"        : this.state.userinfo.userId,
            "SectionId"     : "",
            "DepartmentId"  : "",
            "Name"          : ""
        };
        await
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  positionParams)
        .then(res => {
            const data = res.data;
            this.setState({
                positionDDL : data.positions,
                isloading   : false
            });
        })
        
    }
    
    handleChangePosition = (e) => {
        if(e.length == 0) {
            this.state.positionId     =   ""
            this.state.position   =   ""
            return
        }
        this.state.positionId     =   e[0].id
        this.state.position   =   e[0].name
        this.setState({
            isshow:false,
        })
    }
    handleChangedDDL = name => e =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:false});
        if(e.length===0) {this.setState({[name]:""}); return}
        this.setState({[name]:e[0].id})
    }

    handleChangeDate = date => {
        this.setState({
            date: date,
            isshow:false,
        })
    }

    handleChangeAttention  = (e) =>{
        this.setState({attention:e.target.value,isshow:false,})
    }

    GetResponse = async()=> {
        const departmentCodeParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "0010"
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  departmentCodeParams)
        .then(res => {
            const data = res.data;
            //console.log("Get Response");
            //console.log(data);
            this.setState({ responseDDL  : data.dataReferences });
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    
    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    handleChangeRemarks  = (e) =>{
        this.setState({remarks:e.target.value,isshow:false,})
    }

    handleClickStatus = (statusId) => {
        this.setState({isloading:true})

        const params = {
            "IpAddress"         :   "0.0.0.0",
            "ClientId"          :   this.state.clientId,
            "UserId"            :   this.state.userinfo.userId,
            "ApplicationFormId" :   this.state.applicationId,
            "PositionId"        :   this.state.positionId,
            "Attention"         :   this.state.attention,
            "StaffingId"        :   this.state.userinfo.userId,
            "ResponseId"        :   this.state.responseId,
            "DateEndorsed"      :   this.FormatDate(this.state.date),
            "Remarks"           :   this.state.remarks,
            "StatusId"          :   statusId,
         };

        console.log(params)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/AddEndorsement",  params)
            .then(res => {
                const data = res.data;
                if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true,
                    navigate    :   true,
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })

    }


    render() {
        const { navigate } = this.state;
        if (navigate) {
            return <Redirect to="/applicationform" push={true} />;
        } 
        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>RECRUITMENT >> CLIENT ENDORSEMENT (CREATE)</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                            <Typeahead 
                                                labelKey='name'
                                                id="basic-example"
                                                name="clientId"
                                                onChange={this.handleChangedDDL("clientId")}
                                                options={this.state.clientDDL}
                                                placeholder="Select Client" 
                                                defaultSelected={[this.state.clientName]}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                                <Form.Control 
                                                    type="text"
                                                    name="memberName"
                                                    value={this.state.memberName}
                                                    //onChange={this.onChangeLastName} 
                                                    disabled 
                                                />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="mt-2">
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <Form.Label style={{fontWeight : "bold"}}>POSITION APPLIED</Form.Label>
                                            <Typeahead
                                                ref='clearPosition'
                                                name="positionId"
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangedDDL("positionId")}
                                                options={this.state.positionDDL}
                                                placeholder="Select Position" 
                                                defaultSelected={[this.state.position]}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label style={{fontWeight : "bold",}}>CURRENT ADDRESS</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                name="address"
                                                value={this.state.address}
                                                //onChange={this.onChangeLastName} 
                                                disabled 
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="mt-2">
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label style={{fontWeight : "bold", marginLeft : 15}}>DATE ENDORSED</Form.Label>
                                            <Form.Group as={Col} controlId="formGridCity">
                                            <DatePicker
                                                ref='date'
                                                selected={this.state.date}
                                                onChange={this.handleChangeDate}
                                                minDate={this.minDate}
                                                value={this.state.date}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                wrapperClassName="datepicker"
                                                placeholderText="Select Date" 
                                            />
                                            </Form.Group>
                                        </Form.Group>
                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <Form.Label style={{fontWeight : "bold"}}>ATTENTION</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                name="attention"
                                                value={this.state.attention}
                                                onChange={this.handleChangeAttention}  
                                                autoComplete="off"
                                                placeholder="Enter ATTENTION" 
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="mt-2">
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label style={{fontWeight : "bold"}}>STAFFING</Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                autoComplete="off" 
                                                /* name="selectedClient" */
                                                value={this.state.userinfo.fullName}
                                                readOnly
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <Form.Label style={{fontWeight : "bold"}}>RESPONSE</Form.Label>
                                            <Typeahead
                                                name="responseId"
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangedDDL("responseId")}
                                                options={this.state.responseDDL}
                                                placeholder="Select Response" 
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="mt-2">
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label style={{fontWeight : "bold"}}>REMARKS</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows="3"
                                                placeholder="Enter Remarks"
                                                onChange={this.handleChangeRemarks}
                                                autoComplete="off"
                                                value={this.state.remarks}
                                                name={this.state.remarks}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <ButtonToolbar className="mt-3">
                                        <Button className="ml-auto " variant="success" onClick = {() => this.handleClickStatus('1')}>Save</Button>
                                        <Button className="noser-button-mr1"   variant="primary" onClick = {() => this.handleClickStatus('13')}>Submit</Button>                                    
                                        <NavLink to="/applicationform">
                                            <Button  className="noser-button noser-button-mr1" variant="danger">Back</Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }

}

export  default ClientEndorsementCreate
