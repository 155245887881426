import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker,  Tabs, Tab
} 
from '../../noser-hris-component';
import NoserGrid from './Modal/PayrollSummaryTable';

class OffCyclePayroll extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            selectedClientId: "",
            selectedClient:"",
            clientList: [],
            selectedPayrollName:"",
            selectedPayrollId:"",
            payrollList:[],
            employeePayrollListGrid:[],
            selectedGetPayTypesId:'',
            PeriodTypesId : [],
            getEmployeeList : [],
            payPeriodList: [],
            locationList    :   [],
        }

    }

    searchGeneratePayroll = () => {
        this.setState({isloading:true,
                        alerttype:'',
                        isshow:false,
                        color:'',
                        message:'',
                        fade:true})
                const clientParams = {
                    "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "ClientName":this.state.userinfo.companyName,
                    "EmployeeId": "",
                    "ClientName":this.state.userinfo.companyName,
                    "PayPeriodId": this.state.selectedPayPeriodId,
                    "LocationId": "",
                    "IsProcessed": "0",
                    "IsOffCycle": "1"
            }
            axios
                .post(
                    AppConfiguration.Setting().noserapiendpoint + "Payroll/GetEmployeeOffCyclePayrolls", clientParams
                )
                .then(res => {
                    const data = res.data;
                    console.log("GetEmployeePayrolls");
                    console.log(data);
                    this.setState({
                        employeePayrollListGrid: data.payrolls, isloading:false
                    })
                    if(data.payrolls.length=="0"){
                        this.setState({
                            isloading   :   false,
                            alerttype   :   "Error!",
                            isshow      :   true,
                            color       :   "danger",
                            message     :   data.message,
                            fade        :   true
                        });
                    }
                })
                .catch(error=>{
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                        fade        :   true
                    })
                })
    }
    
    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
       //this.GetClient();
       this.GetPayPeriodList()
    }

   /*  GetClient() {
        this.setState({isloading:true,})
        const clientParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.employeeId
       }
       axios
           .post(
               AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", clientParams
           )
           .then(res => {
            const data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    console.log("access")
                    console.log(access)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        console.log("brr")
                        console.log(brr)
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientList : arr})
            }
            else{
                this.setState({clientList : data.clients})
            }
               //this.setState({clientList: data.clients, isloading:false})
           })
   } */

   /*  onChangeClientList = (e) => {
        this.setState({alerttype:'',
                        isshow:false,
                        color:'',
                        message:'',
                        fade:true})
        if(e.length == 0) {
            this.state.selectedClientName = ""
            this.state.selectedClientId = ""
            return
        }
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        this.GetPayPeriodList();
        
    } */

    onChangePayPeriod = (e) => {
        this.setState({alerttype:'',
                        isshow:false,
                        color:'',
                        message:'',
                        fade:true})
        if(e.length == 0) {
            this.state.selectedPayPeriodId=""
            this.state.selectedPayPeriodName=""
            return
        } 
        this.state.selectedPayPeriodId = e[0].periodId
        this.state.selectedPayPeriodName = e[0].payPeriod
    };

    GetPayPeriodList() {
        const periodListParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId": "",
            "IsProcessed": "1",
         };
         console.log(periodListParams)
         axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods",  periodListParams
             )
             .then(res => {
                const data = res.data;
                this.setState({payPeriodList: data.payrollPeriods,isloading:false })
                console.log(data)
             })
    }
    

    handleFinalizedClick = event => {
        this.setState({isloading:true,})
        const generateParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "ClientName":this.state.userinfo.companyName,
            "PayPeriodId": this.state.selectedPayPeriodId,
            "LocationId": "",
            "IsOffCycle": "1"
        };
       axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/FinalizePayroll",  generateParams)
        .then(res => {
            const data = res.data;
                var alerttype = (data.status=="1") ? "success" : "danger"
                this.setState(
                {
                    isloading:false,
                    alerttype:(data.status=="1") ? "Success! " : "Error! ",
                    isshow:true,
                    color:alerttype,
                    message:data.message ,
                    fade:true
                });
            })
            .catch(error=>{
            this.setState(
                {
                isloading:false,
                alerttype:"Error! ",
                isshow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })
        })
    }

   handleGenerateClick = event => {
        
    this.setState({isloading:true,
                    alerttype:'',
                    isshow:false,
                    color:'',
                    message:'',
                    fade:true})
        const generateParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "ClientName":this.state.userinfo.companyName,
            "PayPeriodId": this.state.selectedPayPeriodId,
            "LocationId": ""
        };
        console.log("offcycle")
        console.log(generateParams)

       axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/ProcessPayrollOffCycle",  generateParams)
        .then(res => {
            console.log(res.data)
            const data = res.data;
                if(data.status=="1"){
                    this.searchGeneratePayroll();
                }
                else{
                this.setState(
                {
                    isloading:false,
                    alerttype:"Error! ",
                    isshow:true,
                    color:"danger",
                    message:data.message ,
                    fade:true
                });
                }
            })
            .catch(error=>{
            this.setState(
                {
                isloading:false,
                alerttype:"Error! ",
                isshow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })
        })

    }
    
    
        
    render() {
        return(
            <div>
            <Banner />
            <Container className="themed-container" fluid={true}>
                <Card className="mt-5">
                    <Card.Header>Process Off Cycle Payroll </Card.Header>
                    <Card.Body>
                        <Form>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.onChangeClientList}
                                        options={this.state.clientList}
                                        placeholder="Select Client"
                                    />
                                </Col>
                            </Form.Group> */}
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <Typeahead
                                        labelKey='payPeriod'
                                        id="basic-example"
                                        onChange={this.onChangePayPeriod}
                                        options={this.state.payPeriodList}
                                        placeholder="Select Payroll Cut-Off"
                                    />
                                </Col>
                            </Form.Group>
                            <ButtonToolbar  >
                                <Button  className="ml-auto" variant="success"   onClick = { this.handleGenerateClick }>
                                    Generate
                                </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                <Button variant="info" onClick = { this.searchGeneratePayroll }>
                                    Search
                                </Button>
                            </ButtonToolbar>
                            <Form.Group  className="mt-4" controlId="formGridPassword">
                                <Card.Header>Payroll Summary</Card.Header>
                                <NoserGrid data={this.state.employeePayrollListGrid} 
                                    exportCSV={false}
                                    pageSize={20}
                                />
                            </Form.Group>
                            <ButtonToolbar sm={12}>
                                <Button variant="success" className="ml-auto" onClick = { this.handleFinalizedClick }>
                                    Finalized
                                </Button>&nbsp;&nbsp;
                                <Button variant="danger" href="/banner" >
                                    Close
                                </Button>
                            </ButtonToolbar>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
                <NoserLoading show={this.state.isloading} />
        </div>
    );
}

}
export  default OffCyclePayroll
