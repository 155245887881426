import { Label } from 'reactstrap';
import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Redirect, Modal,
} 
from '../../noser-hris-component';
import './SignUpLogIns.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { FormControl } from 'react-bootstrap';
import './SignUpLogIns.css'
import {CipherString} from '../../noser-sec'



class SignUpLogInForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.module = React.createRef();
        this.submodule = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            applicantEmail    :  (sessionStorage.getItem("applicantEmail")),
            referenceCode    :   sessionStorage.getItem("referenceCode"),
            resdata    :   JSON.parse(sessionStorage.getItem("resdata")),

            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            disablebtn      :   true,
            clientId        :   "",
            trainingId      :   "",
            statusId        :   "",

            SignUpData      : [],
            lastname        : "",
            firstname       : "",
            middlename      : "",
            suffix          : "",
            email           : "",
            mobileNo        : "",
            username        : "",
            password        : "",
            isCheck         : false,
            disableSaveBtn : true,

            LOGINusername   : "",
            LOGINpassword   : "",

            otp : "",
            NewPassword: "",
            ConfirmPassword: "",


        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.resdata  =JSON.parse(sessionStorage.getItem("resdata"))
        this.state.applicantEmail  = (sessionStorage.getItem("applicantEmail"))

    }
    componentDidMount(){
       // this.handleGet()   
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.resdata  =JSON.parse(sessionStorage.getItem("resdata"))

        let a =JSON.parse(sessionStorage.getItem("resdata"))
        console.log("a")
        console.log(a)
        this.props.onRefModal(this)
        //this.handleGet()      
         // console.log(this.state.resdata.message)
      // this.handleAlert();
      /* this.setState({
        isloading   :   false,
            alerttype   :  "Success!",
            isshow      :   true,
            color       : "success",
            message     :    this.state.a.message,
            fade        :   true,
      }) */
    };

  
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    };
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            resdata    :   JSON.parse(sessionStorage.getItem("resdata")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            //isloading   :   false,
            // alerttype   :   this.state.resdata.status=="1" ? "Success!" : "!Error",
            // isshow      :   true,
            // color       :   this.state.resdata.status=="1" ? "success" : "danger",
            // message     :    this.state.resdata.message,
            // fade        :   true,
            email        :   '',
            password :   ''
        })
    };

    handleSubmit = async(e) =>{
        this.setState({isloading:true})
        console.log("otp")

        console.log(this.state.otp)
        console.log("referenceCode")
        console.log(this.state.referenceCode)
        const param = {
            "CompanyId":"1",
            "EmailAddress":this.state.applicantEmail,
            "NewPassword":CipherString(this.state.NewPassword), //--> encrypted
            "ConfirmPassword":CipherString(this.state.ConfirmPassword), //--> encrypted
            "OTPCode":this.state.otp,
            "ReferenceCode":this.state.referenceCode
        }
        
        await axios
            .post("http://167.172.68.202:8081/Registration/ResetPassword",  param)
            .then(res => {
                console.log(res.data)
                const data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true
                });


            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }
    handleModalClose = () => {
        this.props.onHide();            
    };
    handleChange = (e) =>{
        this.setState({[e.target.name]: e.target.value})
    };

    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    };
    handleModalClose2 = (e) =>{
       // this.setState({openModal:false})    
        window.location.reload();
    };

    handleAlert=(e)=>{
        this.setState({
            isloading   :   false,
            alerttype   :   "Success!",
            isshow      :   true,
            color       :    "Success!",
            message     :    this.state.resdata.message,
            fade        :   true
        });

    };

    // handleResednd=async(e)=>{
    //     this.setState({isloading:true})
        
    //     const param = {
    //         "CompanyId":"1",
    //         "EmailAddress":this.state.applicantEmail
    //     }
        
    //     await axios
    //         .post("http://167.172.68.202:8081/Registration/VerifyRegistration",  param)
    //         .then(res => {
    //             console.log(res.data)
    //             const data = res.data;
    //             this.setState({
    //                 isloading   :   false,
    //                 alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
    //                 isshow      :   true,
    //                 color       :   res.data.status=="1" ? "success" : "danger",
    //                 message     :   data.message,
    //                 fade        :   true,
    //                 referenceCode: data.referenceCode,
    //                 resdata: res.data
    //             });
    //             sessionStorage.setItem("applicantEmail", (this.state.applicantEmail))
    //             sessionStorage.setItem("referenceCode", (data.referenceCode))
    //             sessionStorage.setItem("resdata", JSON.stringify(res.data))
                
    //         })
    //         .catch(error=>{
    //             this.setState({
    //                 isloading   :   false,
    //                 alerttype   :   "Error!",
    //                 isshow      :   true,
    //                 color       :   "danger",
    //                 message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
    //                 fade        :   true
    //             })
    //         })
    // }

    handleGet = async(e) =>{
        //this.setState({isloading:true})
        console.log("handleGET")
        console.log(this.state.applicantEmail)

        const param = {
            "CompanyId":"1",
            "EmailAddress":this.state.applicantEmail
        }
        
        await axios
        .post("http://167.172.68.202:8081/Registration/VerifyRegistration",  param)
        .then(res => {
            console.log(res.data)
            const data = res.data;
            console.log("data referenceCod")
            console.log(data.referenceCode)
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }

    
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            > 
            <div >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        RESET PASSWORD
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <div className='FORGETPASSWORD2 mt-5 mb-3'>
                             <h4 >Reset Password?</h4>
                            </div>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='otp'
                                    type="text" 
                                    placeholder="&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Verification Password" 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.otp}
                                    style={{width:"70%",margin:"auto"}}
                                    className=' mt-3 no-text-transform'
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='NewPassword'
                                    type="password" 
                                    placeholder="&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Enter New Password" 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.NewPassword}
                                    style={{width:"70%",margin:"auto"}}
                                    className='mt-3 no-text-transform'
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='ConfirmPassword'
                                    type="password" 
                                    placeholder="&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Confirm New Password" 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.ConfirmPassword}
                                    style={{width:"70%",margin:"auto"}}
                                    className=' mt-3 no-text-transform'
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Button variant="success" style={{minWidth:'60px',marginRight:'1pt',margin:"auto"}} className="mt-5" onClick = { this.handleSubmit }>OK</Button>
                        </Form.Row>
                        {/* <Form.Row>
                            <Button variant="success" style={{minWidth:'60px',marginRight:'1pt',margin:"auto"}} className="mt-5" onClick = { this.handleResednd }>Resend</Button>
                        </Form.Row> */}
                    </Container>
                </Modal.Body>
                </div>
                <NoserLoading show={this.state.isloading} />
                
        </Modal>
       
        );
    }
}
export default SignUpLogInForgotPassword