import {
  React,Component, Nav, Navbar, NavDropdown, NavDropdownMenu, DropdownSubmenu , AppConfiguration, axios
  , Redirect
} 
from '../../noser-hris-component';
import '../../noser-hris.css';
import { Form, FormControl } from 'react-bootstrap';

//import 'react-push-menu/styles/component.css';
//import { PushMenu, usePushMenu } from 'react-push-menu';
//import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

//import IdleTimer from 'react-idle-timer';

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state={
      userinfo:[],
      featuresLst:[],
      loginName:'',
      lastloginDate:'',

      logout:false,
      timeout:1000 * 5 * 1,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false
    }
    this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
  }
componentDidMount(){
  this.setState({
    userinfo : JSON.parse(sessionStorage.getItem("userData")),
    featuresLst : JSON.parse(sessionStorage.getItem("userAccess"))
  })
  console.log(JSON.parse(sessionStorage.getItem("userData")))
}
handleOnAction (event) {
  console.log('user did something', event)
}

handleOnActive (event) {
console.log('user is active', event)
console.log('time remaining', this.idleTimer.getRemainingTime())
}

handleOnIdle (event) {
  //this.props.history.push('/')
  this.setState({logout:true})
// console.log('user is idle', event)
// console.log('last active', this.idleTimer.getLastActiveTime())
}

  render() {
    if (this.state.logout) {
        return <Redirect to="/" push={true} />;
    }
    // const Content = () =>{
    //   const { toggleMenu } = usePushMenu()
    //   return (
    //     <div
    //       onClick={() => {
    //         toggleMenu();
    //       }}
    //     >
    //       trigger
    //     </div>
    //   );
    // };
    // const data = {
    //   menu: {
    //     header: 'All Categories',
    //     children: [
    //       {
    //         name: 'About',
    //         id: 1,
    //         link: '/about',
    //         children: [
    //           { name: 'Mission', id: 11, link: null, children: [] },
    //           { name: 'Objectives', id: 12, link: null, children: [] },
    //           {
    //             name: 'Goals',
    //             id: 13,
    //             link: '/about/goals',
    //             children: [
    //               { name: 'Charity', id: 131, link: null, children: [] },
    //               { name: 'Clean Environment Plan', id: 132, link: null, children: [] },
    //             ],
    //           },
    //         ],
    //       },
    //       { name: 'Services', id: 2, link: '/services', children: [] },
    //       { name: 'People', id: 3, link: '/people', children: [] },
    //       { name: 'Careers', id: 4, link: '/careers', children: [] },
    //       { name: 'Contact', id: 5, link: null, children: [] },
    //     ],
    //   },
    // };
    return (
    //   <PushMenu 
    //     backIcon={<FaChevronLeft />}
    //     openOnMount
    //     expanderComponent={FaChevronRight}
    //     nodes={data.menu}
    //     propMap={{ url: 'link' }}
    //     type={'cover'}
    //     menuTrigger='rpm-trigger'
    //   >
    //     <Content />
    //   </PushMenu>
    // );
      <>
        {/* <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 360 * 1}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        /> */}
        <Navbar expand="lg" fixed="top" >
          <Navbar.Brand href="/home">
            <div>
              <div style={{textAlign:'left',fontSize:'14px'}}>{this.state.userinfo.companyName}</div>
              <div className="no-text-transform">Human Resource Information System</div>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav >
              <Nav.Link href="/home">HOME</Nav.Link>
              {
                this.state.featuresLst.map((menu) =>
                {
                  if(menu.isActive){
                    return (<NavDropdownMenu title={menu.name} id="basic-nav-dropdown">
                        {
                          menu.subModules.map((submenu) =>
                          {
                            if(submenu.isActive){
                              if(submenu.isParent=="1")
                              {
                                if(submenu.hasDivider=="1"){
                                  return (<div>
                                            <NavDropdown.Divider />
                                            <DropdownSubmenu title={submenu.name} id="basic-nav-dropdown" style={{marginLeft:'10px'}}>
                                            {
                                              submenu.items.map((item) =>
                                                {
                                                  if(item.isActive){
                                                    if(item.hasDivider=="1"){
                                                      return (<div>
                                                              <NavDropdown.Divider />
                                                              <NavDropdown.Item id={item.id} href={item.url}>{item.name}</NavDropdown.Item>
                                                            </div>)
                                                    }
                                                    else{
                                                      return <NavDropdown.Item id={item.id} href={item.url}>{item.name}</NavDropdown.Item>
                                                    }
                                                  }
                                                  else{
                                                    return <NavDropdown.Item id={item.id} style={{display:'none'}}></NavDropdown.Item>
                                                  }
                                                }
                                              )
                                            }
                                          </DropdownSubmenu>
                                          </div>)
                                }
                                else{
                                  return <DropdownSubmenu title={submenu.name} id="basic-nav-dropdown" style={{marginLeft:'10px'}}>
                                        {
                                          submenu.items.map((item) =>
                                            {
                                              if(item.isActive){
                                                if(item.hasDivider=="1"){
                                                  return (<div>
                                                          <NavDropdown.Divider />
                                                          <NavDropdown.Item id={item.id} href={item.url}>{item.name}</NavDropdown.Item>
                                                         </div>)
                                                }
                                                else{
                                                  return <NavDropdown.Item id={item.id} href={item.url}>{item.name}</NavDropdown.Item>
                                                }
                                              }
                                              else{
                                                return <NavDropdown.Item id={item.id} style={{display:'none'}}></NavDropdown.Item>
                                              }
                                            }
                                          )
                                        }
                                      </DropdownSubmenu>
                                }
                              }
                              else
                              {
                                if(submenu.hasDivider=="1"){
                                  return (<div>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href={submenu.url}>{submenu.name}</NavDropdown.Item>
                                   </div>)
                                }
                                else{
                                  return <NavDropdown.Item href={submenu.url}>{submenu.name}</NavDropdown.Item>
                                }
                              }
                            }
                            else{
                              return (<NavDropdown.Item style={{display:'none'}}></NavDropdown.Item>)
                            }
                            
                          }
                        )}
                    </NavDropdownMenu>)
                  }
                  else
                  {
                    return (<NavDropdown.Item style={{display:'none'}}></NavDropdown.Item>)
                  }
                })
              }
              </Nav>
            {/* <Nav className="ml-auto" style={{fontWeight:'bold',color:'yellow'}}>
              <div>
                <span>WELCOME! {this.state.userinfo.fullName}</span><br />
                <span>LAST LOGIN DATE : {this.state.userinfo.lastLogInDate}</span>
              </div>
            </Nav> */}
            {/* <Nav className="ml-auto nav-link-no-css">
              <Nav.Link href="/ChangePassword" style={{width:'36px'}}><img src={img_cp}/></Nav.Link>
              <Nav.Link href="/" style={{marginTop:'4px'}} tooltip='log out'><img src={img_logout}/></Nav.Link>
            </Nav> */}
            <Nav className="ml-auto">
              <Form>
              <Form.Group controlId="formHorizontalEmail">

              </Form.Group>
              </Form>
            </Nav>
            <Nav className="ml-auto">
              <Nav.Link href="/">Logout</Nav.Link>
              <Nav.Link href="/ChangePassword">Change Password</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div style={{height: "50px"}}></div>
      </>
    );
  }
}

export default Banner;
