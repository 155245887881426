import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

class Tax extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            selected: [],
            taxTableList:[],
            selectedTaxRate:"",
            selectedTaxRateId:"",
            taxTableListGrid:[],
            newtaxTableList: []
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetTaxTable();
        
        sleep(1000).then(() => {
        this.setState({isLoading:false})})
    }

    handleCoverChangeTaxRate= (e) => {
        if (e.length > 0) {
            this.state.selectedTaxRate = e[0].taxRate
            this.state.selectedTaxRateId = e[0].id

        } else {
            this.state.selectedTaxRate = ""
            this.state.selectedTaxRateId = ""
        }
        
        console.log(this.state.selectedTaxRate);
       
    } 

    GetTaxTable() {
        this.setState({
            taxTableList:[],
            isloading:true
        })
        const taxParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "TaxRateId":this.state.selectedTaxRateId,
            
        };
        console.log("taxParams");
        console.log(taxParams)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetTaxTables",  taxParams)
        .then(res => {
            const data = res.data;
            console.log("Test app");
            console.log(data);
            this.setState({ taxTableList: data. taxTables, isloading:false});
            if(data.status=="1"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   data.message,
                    fade            :   true
                });
            }
            else{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
            })
            .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }

    handleSearchClick = event => {
        this.setState({taxTableListGrid:[], isloading:true})

        const tablesParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "TaxRateId":this.state.selectedTaxRateId,
        };
        console.log(tablesParams)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetTaxTables",  tablesParams)
            .then(res => {
            const data = res.data;
            console.log(res.data)
            this.setState({taxTableListGrid: data. taxTables,  isloading:false})
            // this.processGrid();
            if(data.status=="1"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   data.message,
                    fade            :   true
                });
            }
            else{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }

    handleSaveClick = event => {
        this.setState({newtaxTableList: [],isLoading:true})

        
        console.log(this.state.newtaxTableList)
        for (let i = 0; i < this.state.taxTableListGrid.length; i++) {
            if (this.state.taxTableListGrid[i]["isModified"] == 1) {
                const obj = {
                    Id: this.state.taxTableListGrid[i]["id"],
                    TaxRateId: this.state.taxTableListGrid[i]["taxRateId"],
                    SalaryFrom :this.state.taxTableListGrid[i]["salaryFrom"],
                    SalaryTo :this.state.taxTableListGrid[i]["salaryTo"],
                    Percentage :this.state.taxTableListGrid[i]["percentage"],
		            TaxDue :this.state.taxTableListGrid[i]["taxDue"],
                    IsDeleted: this.state.taxTableListGrid[i]["isDeleted"].toString()
                };
               

                this.state.newtaxTableList.push(obj);
            }
        }

    const codeParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.userinfo.clientId,
        "UserId":this.state.userinfo.userId,
        "taxTables":this.state.newtaxTableList
        };
        console.log(codeParams)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditTaxCode", codeParams)
            .then(res => {
                const data = res.data;
                this.setState({isLoading:false})
                this.refreshPage();
                var alertType = (data.status=="1") ? "success" : "danger"
                this.setState(
                    {
                        isLoading:false,
                        AlertType:"Success!",
                        show:true,
                        Color:alertType,
                        Message:data.message ,
                        Fade:true
                    });
            })
            .catch(error=>{
                this.setState(
                {
                    isLoading:false,
                    AlertType:"Error! ",
                    Show:true,
                    Color:"danger",
                    Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    Fade:true
                })
            })
    }  

    LeavePageWithourSavingChanges() {
        const isChanged=false
        for (let i = 0; i < this.state.taxTableListGrid.length; i++) {
            if (this.state.taxTableListGrid[i]["isModified"] == 1) {
                this.setState({isGridDataChanged: true})
                isChanged=true
                break;
            }
        }
        return isChanged
    }

    GridDataModified(oldValue, newValue,id, column) {
        console.log(id)
        this.state.taxTableListGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }

    refreshPage(){
        this.setState({isloading:true})

        const refreshParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "TaxRateId":"",
        };

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetTaxTables",  refreshParams)
            .then(res => {
                const data = res.data;
                this.setState({
                    taxTableListGrid: data. taxTables,
                    isloading       :   false,
                })
                
            })
    
        }


    render() {
        const columns1 = [
            {
                dataField: 'taxRate',
                text: 'TAX RATE NAME'
            },
            {
                dataField: 'salaryFrom',
                text: 'SALARY FROM'
            },
            {
                dataField: 'salaryTo',
                text: 'SALARY TO'
            },
            {
                dataField: 'percentage',
                text: 'PERCENTAGE'
            },
            {
                dataField: 'taxDue',
                text: 'TAX DUE',
                
            }] 
       
            const selectRow = {
                mode: 'checkbox',
                clickToSelectAndEditCell: true,
                onSelect: (row, isSelect, rowIndex, e) => {
                    this.state.taxTableListGrid.map(function(item,i){
                        if(item.id===row.id)
                        {
                            item.isDeleted = isSelect ? "1" : "0"
                            item.isModified = isSelect ? "1" : "0"
                        }
                    })
                 }
            };
    
            const rowEvents = {
                onClick: (e, row, rowIndex) => {
                  //alert(e.row);
                }
            };

        return(
            <div>
                <Banner />
                    <Container className="mt-5">
                        <Card>
                            <Card.Header>Tax</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Col sm={6} className="mt-3">
                                            <Typeahead
                                                labelKey='taxRate'
                                                id="basic-example"
                                                onChange={this.handleCoverChangeTaxRate}
                                                options={this.state.taxTableList}
                                                placeholder="Tax Rate"
                                            /> 
                                        </Col>
                                    </Form.Row>
                                    
                                    <ButtonToolbar sm={12} className="mt-3">
                                        <Button variant="primary" className="ml-auto" onClick={this.handleSearchClick}>
                                            Search
                                        </Button>&nbsp;&nbsp;
                                        <NavLink to="/TaxCreate">
                                            <Button  variant="primary" variant="success">
                                                Create
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                    <div className="mt-5">
                                        <BootstrapTable
                                            ref="tbl"
                                            caption={Noser.TableHeader({title:"Search Result"})}
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            keyField = "id"
                                            data = { this.state.taxTableListGrid }
                                            columns = { columns1}
                                            selectRow = { selectRow }
                                            cellEdit = { cellEditFactory({
                                                    mode: 'dbclick',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                this.GridDataModified(oldValue, newValue, row.id, column)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }

                                            />
                                        <ButtonToolbar sm={12} className="mt-3">
                                                <Button variant="success" className="ml-auto" onClick={this.handleSaveClick}>
                                                    Save
                                                </Button>&nbsp;&nbsp;
                                                <NavLink to="/home">
                                                    <Button variant="danger" href="/banner">
                                                        Close
                                                    </Button>
                                                </NavLink>
                                            </ButtonToolbar>
                                    </div>
                                        
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
            </div> 
            
        )
    }
}

export default Tax;