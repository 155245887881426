import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Accordion,
    Tabs, Tab, Redirect
} 
from '../../noser-hris-component';
import ApplicationPoolModal from './Modal/ApplicationPoolModal';
import CandidatePoolModal from './Modal/CandidatePoolModal';
import TalentPoolModal from './Modal/TalentPoolModal';

class ApplicationPooling extends Component {
    state = {
        userinfo        :   [],
        isloading       :   false,
        isshow          :   false,
        alerttype       :   "",
        message         :   "",
        color           :   "",
        fade            :   true,
        openApplicationModal:   false,
        openTalentModal:false,
        openCandidateModal:false,
        
        applicationList :   [],
        poolGRID        :   [],
        talentGRID      :   [],
        candidateGRID   :   [],

        filterDDL       :   [],
        civilStatusDDL  : [
            {name:"SINGLE",         value:"SINGLE"},
            {name:"MARRIED",        value:"MARRIED"},
            {name:"DIVORCED",       value:"DIVORCED"},
            {name:"SEPARATED",      value:"SEPARATED"}
        ],

        profileId       :   "",
        dateApplied     :   "",
        positionId      :   "",
        civilStatus     :   "",
        referenceNo     :   "",
        statusId        :   "",

        typeId          :   "",
        key             :   "1",
        
        disablePoolBtn  :   true,
        disableTalBtn   :   true,
        disableCanBtn   :   true,

        ageFrom         :   "",
        ageTo           :   ""
    }

    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        let tabactivekey = JSON.parse(sessionStorage.getItem("poolingtabkey_"  + this.state.userinfo.userId + this.state.userinfo.employeeId))
        if(tabactivekey!==null){
            this.state.key = tabactivekey
        }
        this.GetApplicationList()
    }
    handleModalShow = (typeId) =>{
        let obj = {}
        if(typeId==="1"){
            this.setState({openApplicationModal:true,typeId:typeId})
            obj = {
                title : "PRELIMINARY TEST SCHEDULE",
                data: this.state.poolGRID.filter(x=>x.isModified==="1")
            }
            this.child1.initialize(obj)
        }
        if(typeId==="2"){
            this.setState({openTalentModal:true,typeId:typeId})
            obj = {
                title : "PRELIMINARY INTERVIEW SCHEDULE",
                data: this.state.talentGRID.filter(x=>x.isModified==="1")
            }
            this.child2.initialize(obj)
        }
        if(typeId==="3"){
            this.setState({openCandidateModal:true,typeId:typeId})
            obj = {
                title : "ASSIGN NEXT STATUS",
                data: this.state.candidateGRID.filter(x=>x.isModified==="1")
            }
            this.child3.initialize(obj)
        }
    }
    handleModalClose = (e) =>{
        if(this.state.typeId==="1"){
            this.setState({openApplicationModal:false})
        }
        if(this.state.typeId==="2"){
            this.setState({openTalentModal:false})
        }
        if(this.state.typeId==="3"){
            this.setState({openCandidateModal:false})
        }
        this.GetApplicationList()
    }
    GetApplicationList = async()=> {
        this.setState({isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "ProfileId":this.state.profileId,
            "DateApplied":this.state.dateApplied,
            "CivilStatus":this.state.civilStatus,
            "PositionId":this.state.positionId,
            "ReferenceNo" : this.state.referenceNo,
            "StatusId" : "",
            //"PoolingStatusId" : this.state.key,
        };
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Pooling/GetApplicationPoolings",  params)
            .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({
                applicationList:data.applicationPools,
                filterDDL:data.applicationPools.filter(x=>x.poolingStatusId==="1"),
                poolGRID:data.applicationPools.filter(x=>x.poolingStatusId==="1"),
                talentGRID:data.applicationPools.filter(x=>x.poolingStatusId==="2"),
                candidateGRID:data.applicationPools.filter(x=>x.poolingStatusId==="3"),
                isloading:false
            });
            
            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangedDate = name => date =>{
        this.setState({[name]:date})
    }
    handleChangedDDL = name => e =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:false});
        if(e.length===0) {
            this.setState({profileId:"",positionId:"",referenceNo:"",civilStatus:""}); return
        }
        console.log(name)
        if(name == "applicantName"){
            this.setState({profileId:e[0].profileId})
        }
        if(name == "position"){
            this.setState({positionId:e[0].positionId})
        }
        if(name == "referenceNo"){
            this.setState({referenceNo:e[0].referenceNo})
        }
        if(name == "civilStatus"){
            this.setState({civilStatus:e[0].name})
        }
    }
    handleRedirect = (row) =>{
        sessionStorage.setItem("poolingtabkey_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(this.state.key))
        if(row.statusId==="3"){
            sessionStorage.setItem("application_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigatepreliminary:true})
        }
        else if(row.statusId==="4"){
            sessionStorage.setItem("application_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigateexam:true})
        }
        else if(row.statusId==="6"){
            sessionStorage.setItem("application_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigatebackground:true})
        }
        else if(row.statusId==="7"){
            sessionStorage.setItem("application_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigateinterviews:true})
        }
        else if(row.statusId==="8"){
            sessionStorage.setItem("application_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigatejoboffer:true})
        }
        else if(row.statusId==="13"){
            sessionStorage.setItem("application_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigateendorsement:true})
        }
        else if(row.statusId==="19"){
            sessionStorage.setItem("application_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigateapplication:true})
        }
        else {
            sessionStorage.setItem("application_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigate:true})
        }
        
    }
    handleSelect = (key) => {
        this.setState({
            filterDDL:this.state.applicationList.filter(x=>x.poolingStatusId==key),
            key:key
        });
    }
    handleSearchClick = async() =>{
        this.setState({isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "ProfileId":this.state.profileId,
            "DateApplied":this.state.dateApplied,
            "CivilStatus":this.state.civilStatus,
            "PositionId":this.state.positionId,
            "ReferenceNo" : this.state.referenceNo,
            "StatusId" : "",
            "PoolingStatusId" : this.state.key,
            "AgeFrom" : this.state.ageFrom,
            "AgeTo": this.state.ageTo
        };
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Pooling/GetApplicationPoolings",  params)
            .then(res => {
            const data = res.data;
            
            if(this.state.ageFrom!=="")
            {
                this.state.ageTo = this.state.ageTo==="" ? this.state.ageFrom : this.state.ageTo
                data.applicationPools = data.applicationPools.filter(x=> parseInt(x.age) >= parseInt(this.state.ageFrom) &&
                                                                         parseInt(x.age) <= parseInt(this.state.ageTo)
                                                                    )
            }
            if(this.state.key=="1"){
                this.setState({
                    poolGRID:data.applicationPools.filter(x=>x.poolingStatusId==="1"),
                    isloading:false
                });
            }
            if(this.state.key=="2"){
                this.setState({
                    talentGRID:data.applicationPools.filter(x=>x.poolingStatusId==="2"),
                    isloading:false
                });
            }
            if(this.state.key=="3"){
                this.setState({
                    candidateGRID:data.applicationPools.filter(x=>x.poolingStatusId==="3"),
                    isloading:false
                });    
            }
            
            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleClickedView = async(row) =>{
        sessionStorage.setItem("poolingtabkey_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(this.state.key))
        sessionStorage.setItem("viewapplicationform_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
        this.setState({navigateview:true})
    }
    IsNumeric(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
        
    }
    handleChanged = (e) =>{
        this.setState({[e.target.name]:e.target.value})
    }
    render() {
        const {navigate, navigateview, navigatejoboffer,  navigateexam, navigatepreliminary, navigatebackground, navigateendorsement, navigateinterviews, navigateapplication, navigateCreateLandDTraining} = this.state;
        
        if (navigate) {
            return <Redirect to="/applicationformedit" push={true} />;
        } 
        
        if (navigateview) {
            return <Redirect to="/applicationformview" push={true} />;
        }

        if (navigatejoboffer) {
            return <Redirect to="/jobofferscreate" push={true} />;
        } 
        
        if (navigateexam) {
            return <Redirect to="/examresultcreate" push={true} />;
        } 
        
        if (navigatepreliminary) {
            return <Redirect to="/preliminaryinterview" push={true} />;
        } 
        
        if (navigatebackground) {
            return <Redirect to="/BackgroundInvestigation" push={true} />;
        }  
        
        if (navigateendorsement) {
            return <Redirect to="/clientendorsement" push={true} />;
        } 

        if (navigateinterviews) {
            return <Redirect to="/finalinterviewcreate" push={true} />;
        } 
        if (navigateapplication){
            return <Redirect to="/ClientEndorsementApproval" push={true} />;
        } 
        if (navigateCreateLandDTraining){
            return <Redirect to="/CreateLandDTraining" push={true} />;
        }
        const poolCol = [
            { dataField: 'referenceNo', text: 'Reference No',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%', }
                },
                style:{textAlign:'center'} 
            },
            { dataField: 'applicantName', text: 'Applicant Name',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'25%', }
                },
                style:{textAlign:'left'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                            variant="link" onClick={e => this.handleClickedView(row)}
                        >{cell}</Button>
                        );
                }
                
            },
            { dataField: 'position', text: 'Position Applied',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'21%', }
                },
                style:{textAlign:'left'} 
            },
            { dataField: 'dateApplied', text: 'Date Applied',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%', }
                },
                style:{textAlign:'center'} 
            },
            { dataField: 'civilStatus', text: 'CIVIL STATUS',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%', }
                },
                style:{textAlign:'center'} 
            },
            { dataField: 'age', text: 'AGE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'6%', }
                },
                style:{textAlign:'center'} 
            },
            { dataField: 'examDate', text: 'EXAM DATE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%', }
                },
                style:{textAlign:'center'} 
            },
            { dataField: 'examDate', text: 'EXAM TIME',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'24%', }
                },
                style:{textAlign:'left'} 
            },
        ]
        const talentCol = [
            { dataField: 'referenceNo', text: 'Reference No',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%', }
                },
                style:{textAlign:'center'} 
            },
            { dataField: 'applicantName', text: 'Applicant Name',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'25%', }
                },
                style:{textAlign:'left'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                            variant="link" onClick={e => this.handleClickedView(row)}
                        >{cell}</Button>
                        );
                }
            },
            { dataField: 'position', text: 'Position Applied',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'21%', }
                },
                style:{textAlign:'left'} 
            },
            { dataField: 'dateApplied', text: 'Date Applied',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%', }
                },
                style:{textAlign:'center'} 
            },
            { dataField: 'civilStatus', text: 'CIVIL STATUS',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%', }
                },
                style:{textAlign:'center'} 
            },
            { dataField: 'age', text: 'AGE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'6%', }
                },
                style:{textAlign:'center'} 
            },
            { dataField: 'scheduleDate', text: 'INTERVIEW DATE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%', }
                },
                style:{textAlign:'center'} 
            },
            { dataField: 'timeVenue', text: 'TIME & VENUE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'26%', }
                },
                style:{textAlign:'left'} 
            },
        ]
        const candidateCol = [
            { dataField: 'referenceNo', text: 'Reference No',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%', }
                },
                style:{textAlign:'center'} 
            },
            { dataField: 'applicantName', text: 'Applicant Name',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'16%', }
                },
                style:{textAlign:'left'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                            variant="link" onClick={e => this.handleClickedView(row)}
                        >{cell}</Button>
                        );
                }
            },
            { dataField: 'position', text: 'Position Applied',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'13%', }
                },
                style:{textAlign:'left'} 
            },
            { dataField: 'dateApplied', text: 'Date Applied',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%', }
                },
                style:{textAlign:'center'} 
            },
            { dataField: 'civilStatus', text: 'CIVIL STATUS',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'6%', }
                },
                style:{textAlign:'center'} 
            },
            { dataField: 'age', text: 'AGE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'6%', }
                },
                style:{textAlign:'center'} 
            },
            { dataField: 'statusId', text: 'CURRENT STATUS',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'13%', }
                },
                style:{textAlign:'left'},
                formatter   :   (cell, row, isSelect) => {
                    if(row.statusId==="3" || 
                        row.statusId==="4" ||
                        row.statusId==="6" ||
                        row.statusId==="7" ||
                        row.statusId==="8" ||
                        row.statusId==="13" || 
                        row.statusId==="17" ||
                        row.statusId==="18" ||
                        row.statusId==="19")
                        {
                            let ns = ""
                            if(row.statusId==="3"){
                                ns = "FOR  REVIEW"
                            }
                            if(row.statusId==="3"){
                                ns = "FOR  REVIEW"
                            }
                            if(row.statusId==="6"){
                                ns = "PRELIMINARY INTERVIEW"
                            }
                            if(row.statusId==="13"){
                                ns = "BACKGROUND CHECK"
                            }
                            if(row.statusId==="19"){
                                ns = "DEPARTMENT ENDORSEMENT"
                            }
                            if(row.statusId==="7"){
                                ns = "ENDORSEMENT APPROVAL"
                            }
                            if(row.statusId==="8"){
                                ns = "FINAL INTERVIEW"
                            }
                            if(row.statusId==="17"){
                                ns = "JOB OFFER"
                            }
                            if(row.statusId==="18"){
                                ns = "JOB OFFER"
                            }
                            return (
                                ns
                                );
                        }
                },
            },
            { dataField: 'remarks', text: 'REMARKS',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'17%', }
                },
                style:{textAlign:'left'} 
            },
            { dataField: '', text: 'ACTION',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'13%', }
                },
                style:{textAlign:'left'} ,
                formatter   :   (cell, row, isSelect) => {
                    if(row.statusId==="3" || 
                        row.statusId==="4" ||
                        row.statusId==="6" ||
                        row.statusId==="7" ||
                        row.statusId==="8" ||
                        row.statusId==="13" || 
                        row.statusId==="19")
                        {
                            let ns = ""

                            return (
                                <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}} 
                                    variant="link" onClick={e => this.handleRedirect(row)}
                                >{row.applicantStatus}</Button>
                                );
                        }
                },
            },
        ]
        const selectpoolRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.poolGRID.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isModified=="1")
                        isDisable=false
                })
                this.setState({disablePoolBtn:isDisable})
            }
        };
        const selecttalentRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.talentGRID.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isModified=="1")
                        isDisable=false
                })
                this.setState({disableTalBtn:isDisable})
            }
        };
        const selectcandidateRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.candidateGRID.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isModified=="1")
                        isDisable=false
                })
                this.setState({disableCanBtn:isDisable})
            }
        };
    return(
            <div>
                <Banner />
                    <Container className="themed-container" fluid={true}>
                        <Card className="mt-3" style={{minHeight:"520px"}}>
                            <Card.Header>APPLICANT POOLING</Card.Header>
                            <Card.Body >
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Card>
                                    <Accordion defaultActiveKey="1">
                                        <div className="card-header-tab">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="1" style={{color: "#FFFFFF"}}>
                                                SEARCH FILTER
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <Form>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            NAME
                                                        </Form.Label>
                                                        <Col sm={4}>
                                                            <Typeahead
                                                                labelKey='applicantName'
                                                                id="basic-example"
                                                                name='applicantName'
                                                                onChange={this.handleChangedDDL("applicantName")}
                                                                options={this.state.filterDDL}
                                                                placeholder="Select Applicant"
                                                            />
                                                        </Col>
                                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            DATE APPLIED
                                                        </Form.Label>
                                                        <Col sm={5}>
                                                            <DatePicker
                                                                id="dateApplied"
                                                                name="dateApplied"
                                                                value={this.state.dateApplied}
                                                                selected={this.state.dateApplied}
                                                                onChange={this.handleChangedDate('dateApplied')}
                                                                //minDate={this.minDate}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                autoComplete="off"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            POSITION
                                                        </Form.Label>
                                                        <Col sm={4}>
                                                            <Typeahead
                                                                labelKey='position'
                                                                id="basic-example"
                                                                onChange={this.handleChangedDDL("position")}
                                                                options={this.state.filterDDL}
                                                                placeholder="Select Position"
                                                            />
                                                        </Col>
                                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            CIVIL STATUS
                                                        </Form.Label>
                                                        <Col sm={2}>
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangedDDL("civilStatus")}
                                                                options={this.state.civilStatusDDL}
                                                                placeholder="Select Civil Status"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            REFERENCE NO
                                                        </Form.Label>
                                                        <Col sm={4}>
                                                            <Typeahead
                                                                labelKey='referenceNo'
                                                                id="basic-example"
                                                                onChange={this.handleChangedDDL("referenceNo")}
                                                                options={this.state.filterDDL}
                                                                placeholder="Select Reference No"
                                                            />
                                                        </Col>
                                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            AGE FROM
                                                        </Form.Label>
                                                        <Col sm={1}>
                                                            <Form.Control 
                                                                type="text"
                                                                id="ageFrom"
                                                                name="ageFrom"
                                                                value={this.state.ageFrom}
                                                                onChange={this.handleChanged}
                                                                autoComplete="off"
                                                                maxLength="100"
                                                                onKeyPress={this.IsNumeric.bind(this)}
                                                            />
                                                        </Col>
                                                        <Form.Label column sm="0" style={{fontWeight : "bold"}}>
                                                            AGE TO
                                                        </Form.Label>
                                                        <Col sm={1}>
                                                            <Form.Control 
                                                                type="text"
                                                                id="ageTo"
                                                                name="ageTo"
                                                                value={this.state.ageTo}
                                                                onChange={this.handleChanged}
                                                                autoComplete="off"
                                                                maxLength="100"
                                                                onKeyPress={this.IsNumeric.bind(this)}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <ButtonToolbar>
                                                                <Button variant="primary" className="ml-auto noser-button" onClick={ this.handleSearchClick }>Search</Button>
                                                            </ButtonToolbar>
                                                        </Col>
                                                    </Form.Group>
                                                </Form>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Accordion>
                                    </Card>
                                    <Tabs style={{marginTop:"5px"}} onSelect={this.handleSelect} activeKey={this.state.key} defaultActiveKey="1" transition={false} id="noanim-tab-example" >
                                        <Tab eventKey="1" title="APPLICATION POOL">
                                            <Card.Header className="card-tab"></Card.Header>
                                            <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.poolGRID }
                                                columns = { poolCol }
                                                selectRow = { selectpoolRow }
                                                cellEdit = { cellEditFactory({ 
                                                        mode: 'dbclick', 
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => { 
                                                            this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                striped
                                                hover
                                                condensed
                                                loading={true}
                                                wrapperClasses="table-responsive"
                                                pagination={ paginationFactory({sizePerPageRenderer})}
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            />
                                            <ButtonToolbar sm={12}>
                                                <Button disabled={this.state.disablePoolBtn} variant="primary" className="ml-auto noser-button" onClick={() => this.handleModalShow('1')}>CREATE STATUS</Button>
                                            </ButtonToolbar>
                                        </Tab>
                                        <Tab eventKey="2" title="TALENT POOL">
                                        <Card.Header className="card-tab"></Card.Header>
                                            <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.talentGRID }
                                                columns = { talentCol }
                                                selectRow = { selecttalentRow }
                                                cellEdit = { cellEditFactory({ 
                                                        mode: 'dbclick', 
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => { 
                                                            this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                striped
                                                hover
                                                condensed
                                                loading={true}
                                                wrapperClasses="table-responsive"
                                                pagination={ paginationFactory({sizePerPageRenderer})}
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            />
                                            <ButtonToolbar sm={12}>
                                            <Button disabled={this.state.disableTalBtn} variant="primary" className="ml-auto noser-button" onClick={() => this.handleModalShow('2')}>CREATE STATUS</Button>
                                            </ButtonToolbar>
                                        </Tab>
                                        <Tab eventKey="3" title="CANDIDATE POOL">
                                        <Card.Header className="card-tab"></Card.Header>
                                            <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.candidateGRID }
                                                columns = { candidateCol }
                                                selectRow = { selectcandidateRow }
                                                cellEdit = { cellEditFactory({ 
                                                        mode: 'dbclick', 
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => { 
                                                            this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                striped
                                                hover
                                                condensed
                                                loading={true}
                                                wrapperClasses="table-responsive"
                                                pagination={ paginationFactory({sizePerPageRenderer})}
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            />
                                            <ButtonToolbar sm={12}>
                                            <Button disabled={this.state.disableCanBtn} variant="primary" className="ml-auto noser-button" onClick={() => this.handleModalShow('3')}>CREATE STATUS</Button>
                                            </ButtonToolbar>
                                        </Tab>
                                    </Tabs>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                <NoserLoading show={this.state.isloading} />
                <ApplicationPoolModal 
                    show={this.state.openApplicationModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child1 = ref)}
                />
                <TalentPoolModal 
                    show={this.state.openTalentModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child2 = ref)}
                />
                <CandidatePoolModal 
                    show={this.state.openCandidateModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child3 = ref)}
                />
            </div> 
        )
    }

}

export  default ApplicationPooling 