import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker, LeaveModal, Tabs, Tab,
    sizePerPageRenderer,Modal,
} 
from '../../noser-hris-component';

class StoreOperationCreate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo  :   [],
            isloading :   false,
            isshow    :   false,
            alerttype :   "",
            message   :   "",
            color     :   "",
            fade      :   true,
            name:"",
            
        }
         
    }

    componentDidMount() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        sleep(1000).then(() => {
            this.setState({isLoading:false})
        }) 
       
    }

    onChangeSupportGroup(e){
        console.log(e)
        this.setState({name:e.target.value})
    }

    handleSaveClick = event => {
        const storeParams = {
            "IpAddress":"0.0.0.0",
            "UserId":"1",
            "CompanyId":this.state.userinfo.companyId,
            "Name":this.state.name
        }
        console.log(storeParams)
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/AddStoreOperation",  storeParams
            )
            .then(res => {
                const data = res.data;
                console.log("Get data");
                console.log(data)
                this.setState({isloading:false})

                    var alertType = (data.status=="1") ? "success" : "danger"
                this.setState(
                    {
                        isloading:false,
                        alertType:"Success!",
                        isshow:true,
                        color:alertType,
                        message:data.message ,
                        fade:true
                    });
            })
            .catch(error=>{
                this.setState(
                {
                    isloading:false,
                    alertType:"Error! ",
                    isshow:true,
                    color:"danger",
                    message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade:true
            })
        })

    }
    render() {
        

        return(

            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className="modal-90w"
            >
                <Modal.Header closeButton className="card-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create Store Operation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                    <Form>
                        <Alert color="success" isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade}>
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Col sm={6} className="mt-3">
                                <Form.Control 
                                    name="name"
                                    type="text" 
                                    placeholder="Name" 
                                    value={this.state.name} 
                                    onChange={this.onChangeSupportGroup.bind(this)} 
                                    autoComplete="off" 
                            
                                />
                            </Col>

                        </Form.Row>
                        <ButtonToolbar sm={12} className="mt-3">
                            <Button variant="primary" className="ml-auto" onClick={this.handleSaveClick} style={{minWidth:'60px', marginRight:"5px"}}>
                                Submit
                            </Button>
                            <Button variant="danger" href="/StoreOperation" style={{minWidth:'60px', }}>
                                Back
                            </Button>
                        </ButtonToolbar>
                    </Form>
                    </Container>
                </Modal.Body>

                <NoserLoading show={this.state.isloading} />
            </Modal>
            
        )
    }
}

export default StoreOperationCreate;