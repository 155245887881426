import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker, LeaveModal, Tabs, Tab,
    sizePerPageRenderer,Modal
} 
from '../../noser-hris-component';

class ReasonCreate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo  :   [],
            isloading :   false,
            isshow    :   false,
            alerttype :   "",
            message   :   "",
            color     :   "",
            fade      :   true,
            name:""
            
        }
         
    }

    componentDidMount() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        sleep(1000).then(() => {
            this.setState({isLoading:false})}) 
    }

    onChangeName(e){
        console.log(e)
        this.setState({name:e.target.value})
    }

    handleSaveClick = event => {
        const taxParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "Name":this.state.name,
            
        }
        console.log(taxParams)
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/AddReason",  taxParams
            )
            .then(res => {
                const data = res.data;
                console.log("Get data");
                console.log(data)
                if(data.status=="1")
                {
                    this.setState(
                        { 
                            show:true,
                            Color:"success",
                            Message:data.message ,
                            Fade:true
                        });
                    
                    }
                else
                {
                    this.setState(
                        { 
                            show:true,
                            Color:"danger",
                            Message:data.message,
                            Fade:true
                        });
                }
            })
        
    }

    render() {
        

        return(
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className="modal-90w"
            >
                <Modal.Header closeButton className="card-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create Reason
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Row>
                                <Col sm={6} className="mt-3">
                                    <Form.Control 
                                        type="text"
                                        name="name"
                                        value={this.state.name}
                                        placeholder="Name"
                                        onChange={this.onChangeName.bind(this)} 
                                        autoComplete="off"
                                    />
                                </Col>

                            </Form.Row>
                            <ButtonToolbar sm={12} className="mt-3">
                                <Button variant="primary"  onClick={this.handleSaveClick} style={{minWidth:'60px', marginRight:"5px" }}>
                                    Submit
                                </Button>
                                <Button variant="danger" href="/Reason" style={{minWidth:'60px', }}>
                                    Back
                                </Button>
                            </ButtonToolbar>
                        </Form>
                    </Container>
                </Modal.Body>

                <NoserLoading show={this.state.isloading} />
            </Modal>
            
            
        )
    }
}

export default ReasonCreate;