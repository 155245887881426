import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker, LeaveModal, Tabs, Tab,
    sizePerPageRenderer
} 
from '../../noser-hris-component';

class ClientCreate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo  :   [],
            isloading :   false,
            isshow    :   false,
            alerttype :   "",
            message   :   "",
            color     :   "",
            fade      :   true,

            name:"",
            ruleName:"",
            ruleDescription:"",
            periodMinutes:"",
            lateGrouping:"",
            allowableMinutes:"",
            breakLate:"",
            tin:"",
            costCenter:"",
            costCenterGroup:"",
            regionList:[],
            selectedRegion:"",
            selectedRegionId:"",
            provinceList:[],
            selectedProvince:"",
            selectedProvinceId:"",
            selectedCity:"",
            selectedCityId:"",
            barangay:"",
            postalCode:"",
            streetName:"",
            listCityTown:[],
            getPayModesList:[]

        }
    }

    componentDidMount() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetRegion();
        this.GetProvince();
        this.GetCityTown();
        sleep(1000).then(() => {
            this.setState({isLoading:false})}) 
    }

    onChangeName(e){
        console.log(e)
        this.setState({name:e.target.value})
    }

    onChangeRuleName(e){
        console.log(e)
        this.setState({ruleName:e.target.value})
    }

    onChangeRuleDescription(e){
        console.log(e)
        this.setState({ruleDescription:e.target.value})
    }

    onChangeLateGrouping(e){
        console.log(e)
        this.setState({lateGrouping:e.target.value})
    }

    onChangeGracePeriod(e){
        console.log(e)
        this.setState({periodMinutes:e.target.value})
    }

    onChangeAllowableMinutes(e){
        console.log(e)
        this.setState({allowableMinutes:e.target.value})
    }

    onChangeBreakLate(e){
        console.log(e)
        this.setState({breakLate:e.target.value})
    }

    onChangeTIN(e){
        console.log(e)
        this.setState({tin:e.target.value})
    }

   
   /*  onChangeCostCenter(e){
        console.log(e)
        this.setState({costCenter:e.target.value})
    } */

    IsDecimal(val)
    {
        var regexp = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;
        return !regexp.test(val)
    }
    onChangeCostCenter = (e) => {
        
        if(e.target.value.length>0){if(this.IsDecimal(e.target.value)) return}
        
        this.setState({ costCenter: e.target.value} );
    }

    onChangeCostCenterGroup(e){
        console.log(e)
        this.setState({costCenterGroup:e.target.value})
    }

    GetRegion() {
        const regionParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":this.state.selectedRegion
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRegions",  regionParams)
        .then(res => {
             const data = res.data;
             console.log("Test region");
            console.log(data);
             this.setState({ regionList: data.regions });
         })
    }

    handleCoverChangeRegion = (e) => {
        console.log(e)
            if (e.length > 0) {
            this.state.selectedRegion = e[0].name
            this.state.selectedRegionId = e[0].id

        } else {
            this.state.selectedRegion = ""
            this.state.selectedRegionId = ""
        }
        console.log(this.state.selectedRegion);
       
    }

    GetProvince() {
        const provinceParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Region": this.state.selectedRegion
        };

         axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetProvinces",  provinceParams)
        .then(res => {
            const data = res.data;
            this.setState({ provinceList: data.provinces });
        })

    }

    handleCoverChangeProvince = (e) => {
        if (e.length > 0) {
            this.state.selectedProvince = e[0].name
            this.state.selectedProvinceId = e[0].id
        } else {
            this.state.selectedProvince = ""
            this.state.selectedProvinceId = ""
        }
        console.log(this.state.selectedProvince)
    }

    GetCityTown() {

        const cityParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":this.state.selectedCity,
            "ProvinceName": this.state.selectedProvince
         };
 
         axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetCities",  cityParams
             )
             .then(res => {
                 const data = res.data;
                 //console.log("List of Cities");
                 //console.log(data.cities);
                 this.setState({ listCityTown: data.cities });
             })

    }

    handleCoverChangeCity = (e) => {
        if (e.length > 0) {
            this.state.selectedCity = e[0].name
            this.state.selectedCityId = e[0].id
        } else {
            this.state.selectedCity = ""
            this.state.selectedCityId = ""
        }
        console.log(this.state.selectedCity)
    }

    onChangeBarangay(e){
        console.log(e)
        this.setState({barangay:e.target.value})
    }

    onChangeStreetName(e){
        console.log(e)
        this.setState({streetName:e.target.value})
    }

    onChangePostalCode(e){
        console.log(e)
        this.setState({postalCode:e.target.value})
    }

    handleSaveClick = event => {
        const clientParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":this.state.name,
            "CostCenter":this.state.costCenter,
            "CostCenterGroup":this.state.costCenterGroup,
            "RegionId":this.state.selectedRegionId,
            "ProvinceId":this.state.selectedProvinceId,
            "CityId":this.state.selectedCityId,
            "Barangay":this.state.barangay,
            "PostalCode":this.state.postalCode,
            "TIN":this.state.tin,
            "StreetName":this.state.streetName,
            "DeductionBasisSSSId":"0",
            "DeductionBasisHDMFId":"0",
            "DeductionBasisPHICId":"0",
            "FixedAmountEmployeeSSS":"0",
            "FixedAmountEmployeeHDMF":"0",
            "FixedAmountEmployeePHIC":"0",
            "FixedAmountEmployerSSS":"0",
            "FixedAmountEmployerHDMF":"0",
            "FixedAmountEmployerPHIC":"0",
            "FixedAmountECEmployerSSS":"0"
        }
        console.log(clientParams)
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Client/AddClient",  clientParams
            )
            .then(res => {
                const data = res.data;
                console.log("Get data");
                console.log(data)
                if(data.status=="1")
                {
                    this.setState(
                        { 
                            show:true,
                            Color:"success",
                            Message:data.message ,
                            Fade:true
                        });
                    
                    }
                else
                {
                    this.setState(
                        { 
                            show:true,
                            Color:"danger",
                            Message:data.message,
                            Fade:true
                        });
                }
            })
        
    }

    render() {
        return(
            <div>
                <Banner />
                    <Container className="mt-5">
                        <Card>
                            <Card.Header>Create Client</Card.Header>
                            <Card.Body>
                                <Form>
                                <Alert color="success" isOpen={this.state.show} color={this.state.Color} fade={this.state.Fade}>
                                        {this.state.Message}
                                    </Alert>
                                    <Form.Row>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.name}
                                                placeholder="Name"
                                                onChange={this.onChangeName.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.ruleName}
                                                placeholder="Schedule Rule Name"
                                                onChange={this.onChangeRuleName.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.ruleDescription}
                                                placeholder="Schedule Rule Description"
                                                onChange={this.onChangeRuleDescription.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.periodMinutes}
                                                placeholder="Grace Period Minutes"
                                                onChange={this.onChangeGracePeriod.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.lateGrouping}
                                                placeholder="Late Grouping Minutes"
                                                onChange={this.onChangeLateGrouping.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.allowableMinutes}
                                                placeholder="Allowable Minutes Late Per Month"
                                                onChange={this.onChangeAllowableMinutes.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.breakLate}
                                                placeholder="Break Late Grouping Minutes"
                                                onChange={this.onChangeBreakLate.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.tin}
                                                placeholder="TIN"
                                                onChange={this.onChangeTIN.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>

                                    </Form.Row>
                                   {/*  <Form.Row>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.deductionBasisSSS}
                                                placeholder="Deduction Basis SSS"
                                                onChange={this.onChangeDeductionBasisSSS.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.basisHDMF}
                                                placeholder="Deduction Basis HDMF"
                                                onChange={this.onChangeDeductionBasisHDMF.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.basisPHIC}
                                                placeholder="Deduction Basis PHIC"
                                                onChange={this.onChangeDeductionBasisPHIC.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        </Form.Row>
                                        <Form.Row>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.fixedAmountSSS}
                                                placeholder="Fixed Amount Employee SSS"
                                                onChange={this.onChangeFixedAmountEmployeeSSS.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.FixedAmountEmployerSSS}
                                                placeholder="Fixed Amount Employer SSS"
                                                onChange={this.onChangeFixedAmountEmployerSSS.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.fixedAmountEmployeeHDMF}
                                                placeholder="Fixed Amount Employee HDMF"
                                                onChange={this.onChangeFixedAmountEmployeeHDMF.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.FixedAmountEmployerHDMF}
                                                placeholder="Fixed Amount Employer HDMF"
                                                onChange={this.onChangeFixedAmountEmployerHDMF.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.fixedAmountEmployeePHIC}
                                                placeholder="Fixed Amount Employee PHIC"
                                                onChange={this.onChangeFixedAmountEmployeePHIC.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.FixedAmountEmployerPHIC}
                                                placeholder="Fixed Amount Employer PHIC"
                                                onChange={this.onChangeFixedAmountEmployerPHIC.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.fixedAmountECEmployerSSS}
                                                placeholder="Fixed Amount EC Employer SSS"
                                                onChange={this.onChangeFixedAmountECEmployerSSS.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>

                                    </Form.Row> */}
                                    <Card.Header className="mt-3"></Card.Header>
                                    <Form.Row>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.costCenter}
                                                placeholder="Cost Center"
                                                onChange={this.onChangeCostCenter.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.costCenterGroup}
                                                placeholder="Cost Center Group"
                                                onChange={this.onChangeCostCenterGroup.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Row>
                                    <Card.Header className="mt-3"></Card.Header>
                                    <Form.Row>
                                        <Col sm={6} className="mt-3">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleCoverChangeRegion}
                                                options={this.state.regionList}
                                                placeholder="Select Region"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange = { this.handleCoverChangeProvince }
                                                options={this.state.provinceList}
                                                placeholder="Select Province"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Typeahead                                  
                                                labelKey='name'
                                                id="basic-examplex"
                                                onChange={this.handleCoverChangeCity}
                                                options={this.state.listCityTown}
                                                placeholder="City/Town"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                             <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.barangay}
                                                placeholder="Barangay"
                                                onChange={this.onChangeBarangay.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                             <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.streetName}
                                                placeholder="Street Name"
                                                onChange={this.onChangeStreetName.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                             <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.postalCode}
                                                placeholder="Postal Code"
                                                onChange={this.onChangePostalCode.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Row>
                                    
                                    <ButtonToolbar sm={12} className="mt-5">
                                        <Button variant="primary" className="ml-auto" onClick={this.handleSaveClick}>
                                            Submit
                                        </Button>&nbsp;&nbsp;
                                        <Button variant="danger" href="/Client">
                                            Back
                                        </Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
            </div> 
            
        )
    }
}

export default ClientCreate;