import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';


class BillingTransaction extends Component {
    state = {
        userinfo        :   [],
        isloading       :   false,
        isshow          :   false,
        alerttype       :   "",
        message         :   "",
        color           :   "",
        fade            :   true,
        disableBtn      :   true,
        disableresetBtn :   true,
        
        transactionGrid :   [],
        clientDDL       :   [],
        periodDDL       :   [],
        locationDDL     :   [],
        employeeDDL     :   [],

        clientId        :   "",
        clientName      :   "",
        payperiodId     :   "",
        locationId      :   "",
        employeeId      :   "",
    }

    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        }
        
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data
            this.setState({clientDDL:res.data.clients ? res.data.clients:[],isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetPayrollPeriods = async() =>{
        this.setState({isloading:true});
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "IsProcessed":"0"
         };
         
        await axios
             .post(AppConfiguration.Setting().noserapiendpoint + "Billing/GetBillingPeriods", params)
             .then(res => {
                const data = res.data;
                this.setState({periodDDL: data.billingPeriods,isloading:false })
             })
        this.GetEmployees()
    }
    GetEmployees = async() =>{
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId": this.state.userinfo.roleId==="1" || 
                          this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", params)
        .then(res => {
            const data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        console.log("brr")
                        console.log(brr)
                        arr = data.profiles.filter(f => brr.some(item => item.referenceId === f.clientId))
                    }
                }
                this.setState({employeeDDL:arr.filter(x=>x.status==="ACTIVE"),isloading:false})
            }
            else{
                this.setState({employeeDDL:data.profiles.filter(x=>x.status==="ACTIVE"),isloading:false})
            }
            
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetLocations()
    }
    GetLocations = async() => {
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.clientName,
        };
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations",  params)
            .then(res => {
                const data = res.data;
                this.setState({ locationDDL: data.locations,isloading:false })
            })
            .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangedClient = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.clientId= ''
            this.state.client=''
            this.state.employeeDDL=[]
            return
        }
        this.state.clientId=e[0].id
        this.state.clientName=e[0].name
        this.GetPayrollPeriods()
    }
    handleChangedPeriod = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.payperiodId=''
            return
        }
        this.state.payperiodId=e[0].periodId
    }
    handleChangedEmployee = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.employeeId=''
            return
        }
        this.state.employeeId=e[0].id
    }
    handleChangedLocation = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.locationId=''
            return
        }
        this.state.locationId=e[0].id
    }
    handleSearchClick = async() => {
        this.setState({isloading:true});
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "PayPeriodId":this.state.payperiodId,
            "LocationId":this.state.locationId,
            "EmployeeId":this.state.employeeId,
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Billing/GetBillingMandatoryTrasnactions",  params)
        .then(res => {
            const data = res.data
            this.setState({isloading:false,
                            transactionGrid:data.billingMandatories,
                            disableresetBtn:data.billingMandatories.length===0 ? true:false});
            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    transactionGrid: []
                });
            }
         })
         .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }

    handleSaveClick = async() => {
        this.setState({isloading:true,disableBtn:true});

        if(this.state.clientId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client",
                fade        :   true,
            })
            return
        }
        if(this.state.payperiodId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select cut-off",
                fade        :   true,
            })
            return
        }
        if(this.state.locationId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select Branch/Location",
                fade        :   true,
            })
            return
        }
        if(this.state.employeeId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select Employee",
                fade        :   true,
            })
            return
        }

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "billingTransactions":this.state.transactionGrid
        };
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Billing/SaveBillingTransaction", params)
            .then(res => {
                const data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   data.status=="1" ? "Success!" : "Error!",
                    isshow      :   true,
                    color       :   data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true,
                    disableBtn  :   true
                });
             })
             .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true,
                })
            })
    }
    handleClearClick = async()=>{
        this.state.transactionGrid.forEach(item => {
            item.amount = "0.00"
            this.setState({disableBtn:false})
        })
    }
    handleResetClick = async()=>{
        this.setState({isloading:true,disableBtn:true});
        if(this.state.clientId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client",
                fade        :   true,
            })
            return
        }
        if(this.state.payperiodId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select cut-off",
                fade        :   true,
            })
            return
        }
        if(this.state.locationId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select Branch/Location",
                fade        :   true,
            })
            return
        }
        if(this.state.employeeId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select Employee",
                fade        :   true,
            })
            return
        }
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "PayPeriodId":this.state.payperiodId,
            "LocationId":this.state.locationId,
            "EmployeeId":this.state.employeeId,
        };

        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Billing/ResetBillingTransaction", params)
            .then(res => {
                const data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   data.status=="1" ? "Success!" : "Error!",
                    isshow      :   true,
                    color       :   data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true,
                    disableBtn  :   true
                });
             })
             .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true,
                })
            })
    }
    GridDataModified(oldValue, newValue, id, column) {
        let isDisable = true
        this.state.transactionGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            if(item.isModified=="1")
                isDisable = false
        })
        this.setState({disableBtn:isDisable})
    }
    IsDecimal(val)
    {
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        return !regex.test(val)
    }
    render() {
        const cols = [
            {
                dataField: 'mandatory',
                text: 'mandatory',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%' }},
                style:{textAlign:'left',width:'10%'},
                footerAlign: (column, colIndex) => 'right',
                footer: 'TOTAL'
            },
            {
                dataField: 'amount',
                text: 'AMOUNT',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'90%' }},
                style:{textAlign:'left',width:'90%'},
                validator: (newValue, row, column) => {
                    
                    if (this.IsDecimal(newValue)) {
                      return {
                        valid: false
                      };
                    }
                    if(newValue.split(".").length>2)
                    return {
                        valid: false
                      };
                    return true;
                },
                //footerFormatter: this.FormatAmount,
                footerAlign: (column, colIndex) => 'left',
                footer: columnData => columnData.reduce((acc, item) => parseFloat(parseFloat(acc) + parseFloat(item)).toFixed(2), 0)
            }
        ]
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            hideSelectAll: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.regionListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableBtn:isDisable})
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
        }
    };

    return(
            <div>
                <Banner />
                    <Container className="themed-container" fluid={true}>
                        <Card className="mt-5">
                            <Card.Header>BILLING >> BILLING TRANSACTIONS</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangedClient}
                                                options={this.state.clientDDL}
                                                placeholder="Select Client"
                                            />
                                        </Col>
                                    </Form.Group> */}
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <Typeahead
                                                labelKey='payPeriod'
                                                id="basic-example"
                                                onChange={this.handleChangedPeriod}
                                                options={this.state.periodDDL}
                                                placeholder="Select CUT OFF"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleChangedEmployee}
                                                options={this.state.employeeDDL}
                                                placeholder="Select Employee"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangedLocation}
                                                options={this.state.locationDDL}
                                                placeholder="Select BRANCH"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <ButtonToolbar>
                                                <Button variant="primary" className="ml-auto noser-button" onClick={ this.handleSearchClick }>Search</Button>
                                            </ButtonToolbar>
                                        </Col>
                                    </Form.Group>
                                    <div className="mt-1">
                                    <Card.Header>MANDATORIES</Card.Header>
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.transactionGrid }
                                            columns = { cols }
                                            selectRow = { selectRow }
                                            cellEdit = { cellEditFactory({ 
                                                    mode: 'dbclick', 
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => { 
                                                        this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                            striped
                                            hover
                                            condensed
                                            loading={true}
                                            wrapperClasses="table-responsive"
                                            rowClasses="noser-table-row-class no-checkbox"
                                            pagination={ paginationFactory({sizePerPageRenderer})}
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        />
                                        <ButtonToolbar sm={12}>
                                        <Button variant="primary" className="ml-auto noser-button-mr1" disabled={this.state.disableresetBtn} onClick={this.handleClearClick}>Clear</Button>
                                        <Button variant="danger" className="noser-button-mr1" disabled={this.state.disableresetBtn} onClick={this.handleResetClick}>Reset</Button>
                                            <Button variant="success" className="noser-button-mr1" disabled={this.state.disableBtn} onClick={this.handleSaveClick}>Save</Button>
                                        </ButtonToolbar>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                <NoserLoading show={this.state.isloading} />
                
            </div> 
        )
    }

}

export  default BillingTransaction 