import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

 
class LateAbsentSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            selectedClientName                  :   [],
            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            LateAbsentTableList                 :   [],
            getClientList                       :   [],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalTrainingRegisterShow           :   false,

            category                    :"",
        
            clientName                  :"",
            absentAllowed               :"",
            latesAllowed                :"",
            latesAllowance              :"",

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;
        /* this.getAllowableAbsentAndLate() */
    }
    GetDepartment() {
        const departmentCodeParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "",
            "Name"          :   ""
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDepartments",  departmentCodeParams)
        .then(res => {
            const data = res.data;
            console.log("Get Departments Code");
            console.log(data);
            console.log("Get Departments Code");
            this.setState({
                departmentCodeAutocomplete  :   data.departments
            });
        })

        console.log(this.state.departmentCodeAutocomplete)
    }

    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            console.log("Client List start ");
            console.log(res.data);
            console.log("Client List end");
            const data = res.data
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }
    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.client = e[0].name
        this.setState({
           // isloading:true,
            isshow:false,
            color:"",
            message:"",
            fade:true ,
            clientName: e[0].name
        })
       let clientTmp = e[0].name
        this.getAllowableAbsentAndLate(clientTmp)
        
    }

    showTrainingRegisterModal = (e) => {
        this.setState({modalTrainingRegisterShow: true})
    }
    handleModalClose = (e) =>{
        //reload or refresh data here
        //alert("Data pass from child : " + e)
        this.setState({modalTrainingRegisterShow: false})
    }


    GridDataModified(oldValue, newValue, id, column) {
        ////console.log(id)
        this.state.LateAbsentTableList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    getAllowableAbsentAndLate =(clientTmp)=>{
       
        let filter_data ={'clientName': clientTmp }
        const getParams ={
            "_collection" : "TestLateAbsent",
            "filter_data" : filter_data
        }
            console.log("axios start")
            axios
            .post("http://134.209.99.190:8088/action/get" , getParams)
            .then(res =>{
                let data = res.data
                console.log("res.data start")
                console.log(res.data)
                console.log("res.data end")
            
                const itemUsersDataLists = this.buildList(data["TestLateAbsent"])
                
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                    let obj = {
                        'id'                    :   this.state.itemUserDataList[i]['id'],
                        'clientName'                :   this.state.itemUserDataList[i]['clientName'],
                        'particulars'                :   this.state.itemUserDataList[i]['particulars'],
                        'absentAllowed'                :   this.state.itemUserDataList[i]['absentAllowed'],
                        'latesAllowed'                :   this.state.itemUserDataList[i]['latesAllowed'],
                        'latesAllowance'                :   this.state.itemUserDataList[i]['latesAllowance'],
                        'UTAllowed'                :   this.state.itemUserDataList[i]['UTAllowed'],
                        'UTAllowance'                :   this.state.itemUserDataList[i]['UTAllowance'],

                        'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'            :   this.state.itemUserDataList[i]['isModified'],
                        
                    }
                    List.push(obj)
                    console.log(List)
                    
                    this.setState({
                        LateAbsentTableList:List

                    })
                   
                }

            })
            .catch(error=>{
                console.log("error: " + error)
            })
        } 
        
    buildList(data) {
        //console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let clientNameList              =[]
        let particularsList             =[]
        let absentAllowedList           =[]
        let latesAllowedList            =[]
        let latesAllowanceList          =[]
        let UTAllowedList               =[]
        let UTAllowanceList             =[]

        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
    
            let s1 = data[i].split("':")
            
            let idClean                     = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let clientNameTmp               = s1[2].split("',")[0]
            let particularsTmp              = s1[3].split("',")[0]
            let absentAllowedTmp            = s1[4].split("',")[0]
            let latesAllowedTmp             = s1[5].split("',")[0]
            let latesAllowanceTmp           = s1[6].split("',")[0]
            let UTAllowedTmp                = s1[7].split("',")[0]
            let UTAllowanceTmp              = s1[8].split("',")[0]

            let isDraftListTmp              = s1[9].split("',")[0]
            let isDeletedListTmp            = s1[10].split("',")[0]
            let createdbyListTmp            = s1[11].split("',")[0]
            let createddateListTmp          = s1[12].split("',")[0]
            let modifiedbyListTmp           = s1[13].split("',")[0]
            let modifieddateListTmp         = s1[14].split("',")[0]
            let isModifiedListTmp           = s1[15].split("',")[0]

        
            idList.push(IdClean2.replace("'",""))
            clientNameList.push(clientNameTmp.replace("'",""))
            particularsList.push(particularsTmp.replace("'",""))
            absentAllowedList.push(absentAllowedTmp.replace("'",""))
            latesAllowedList.push(latesAllowedTmp.replace("'",""))
            latesAllowanceList.push(latesAllowanceTmp.replace("'",""))
            UTAllowedList.push(UTAllowedTmp.replace("'",""))
            UTAllowanceList.push(UTAllowanceTmp.replace("'",""))
           
            isDraftList.push(isDraftListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'clientName'            :   clientNameList[i],
                "particulars"           :    particularsList[i],
                'absentAllowed'         :   absentAllowedList[i],
                'latesAllowed'          :   latesAllowedList[i],
                'latesAllowance'        :   latesAllowanceList[i],
                'UTAllowed'             :   UTAllowedList[i],
                'UTAllowance'           :   UTAllowanceList[i],

                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
      
        return itemList
    }


    render() {
        
        const LateAbsentColumn = [           
            {
                dataField   : 'absentAllowed',
                text        : 'Allowed(monthly)',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'latesAllowed',
                text        : 'Lates Allowed (monthly)',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'latesAllowance',
                text        : 'Late Allowance (minutes)',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'UTAllowed',
                text        : 'Under Time Allowed (monthly)',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'UTAllowance',
                text        : 'Under Time Allowance (minutes)',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            /* {
                dataField: 'training',
                text: 'Training',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'position',
                text        : 'Position',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'client',
                text        : 'Client',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'register',
                text        : 'Register',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'attended',
                text        : 'Attended',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.LateAbsentTableList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>Maintenance >> Allowable Absent and Late >> Search </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                   {/*  <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.getClientList}
                                                placeholder="Select Client"
                                            />
                                        </Col>
                                    </Form.Group> */}
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                    
                                    </Form.Group>
                                    
                                    <ButtonToolbar className="mt-1 ml-auto">
                                        {/* <Button variant="success" className="ml-auto" onClick={ this.handleSearchClick } style={{minWidth:'60px'}}>
                                            Search
                                        </Button> */}
                                        <NavLink to="/LateAbsentCreate" className="ml-auto" >
                                            <Button variant="success" className="ml-auto" href="/LateAbsentCreate" style={{minWidth:'60px'}}>
                                            Create
                                            </Button>
                                        </NavLink>
                                       
                                    </ButtonToolbar>

                                    <Card.Header className="mt-3">List of Allowable Lates</Card.Header>
                                    <div className="mt-1">
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.LateAbsentTableList }
                                            columns = { LateAbsentColumn }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                            selectRow = { selectRow }

                                        />
                                    </div>
                                    <ButtonToolbar sm={12}>
                                        <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleUpdateClick}>
                                           Save
                                        </Button>&nbsp;&nbsp;
                                        <NavLink to="/home">
                                            <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                Close
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

            </div> 
        )
    }

}

export  default LateAbsentSearch
