
import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, useState, Tabs, Tab
} 
from '../../noser-hris-component';
import PayrollPeriods from "./PayrollPeriods";
import PeriodType from "./PeriodType";
import WorkSchedDefaut from "./WorkSchedDefaut";

const DateToformat = 'h:mm';
const DateFromformat = 'h:mm';

const showSecond = false;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';

const now = moment().hour(0).minute(0);

class ClientConfig extends Component {
 
    constructor(props) {
        super(props)
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            getClientList: [],
            isCheckedLate: "0",
            isCheckedSunday: "0",
            lateAllowanceMins: '10',
            isCheckedPermanent: "0",
            isCheckedDeduction: "0",
            payableOvertimeMins: '10',
            holidayGridList: [],
            setHolidayId: [],
            DateHired: '',
            txtMaxLeaves: '',
            txtRangeFrom: '',
            txtRangeTo: '',
            OBBracket: [],
            OBBracketDetails: [],
            PayrollConfiguration: [],
            PayrollConfigurationTable: [],

            checkIsBasic: true,
            disabledIsBasic: true,
            IsBasic: true,
            IsPremium: false,
            IsColaRate:false,
            IsEColaRate:false,
            IsSeaRate:false,
            workingDays: '',
            payDay: '0',
            ceilingAmount: '',
            fixedAmount:'',
            FirstCutOff: false,
            SecondCutOff: false,
            ThirdCutOff: false,
            FourthCutOff: false,
            FifthCutOff: false,


            contributionTypeList: [],
            payModesList: [],
            periodTypesList: [],
            disabledPeriodType: false,
            
            checkFirst : false,
            checkSecond : false,
            checkThird : false,
            checkFourth : false,

            isDateHired : false,
            isFistOfTheYear : false,

            isExcessDeduction   :   true,
            isLateDeduction     :   false,
            LateDeduction       :   '',

            disabledFirst: true,
            disabledSecond: true,
            disabledThird: true,
            disabledFourth: true,
            disabledFifth: true,

            disabledPayDay: true,
            disabledWorkingDays: true,

            selectedLateAllowance    : '',
            selectedPayableOvertime  : '',
            selectedNightDiffStart   : '',
            selectedNightDiffEnd     : '',
            selectedMaxLeaves        : '',
            selectedContributionType : '',
            periodTyped : '',
            selectedPeriodType : [],

            valueFrom: moment(),
            valueTo: moment(),

            DateHired : "",
            PayrollConfigurationAddList : [],
            CreatedOBBracket : [],

            semiMonthly: '',
            valueTimeFrom : '',
            valueTimeTo : '',
            CreatedOBBrackets:[],
            PayrollConfigurations : [],
            checkIsAllLateDeduction     :   true,

            selectedPeriodTypeId:"",
            selectedPeriodTypeName:"",

            clientLeavesList : [],
            leaveTypesList : [],
            leaveTypesListGRID : [],
            leaveTypeOptions:[
                {"value":"0","label":"1ST OF THE YEAR"},
                {"value":"1","label":"DATE HIRED"},
            ],
            leaveTypeId : "",
            leaveType : "",
            maxLeaves : "",
            isDateHired:true,

        }
    }

    state = {
        selectedClient: [],
    };
    handleChangeCheckbox(e) {
        /* let isCheckedIsBasicSalary = e.target.checked ? "1" : "0";
        //console.log(isCheckedIsBasicSalary) */
        this.setState({[e.target.name]: e.target.checked})

        //console.log(e.target.name + " " + e.target.checked)
    }

    handleChangeCheckboxIsBasicSalary(e) {
        /* let isCheckedIsBasicSalary = e.target.checked ? "1" : "0";
        //console.log(isCheckedIsBasicSalary) */
        this.setState({
            IsBasicSalary: e.target.checked
        })
    }

    handleChangeCheckboxIsPremium(e) {
        /* let isCheckedIsPremium = e.target.checked ? "1" : "0";
        //console.log(isCheckedIsPremium) */
        this.setState({
            IsPremium: e.target.checked
        })
    }

    handleChangeCheckboxFirstCutOff(e) {
        /* let isCheckedSSSFirstCutOff = e.target.checked ? "1" : "0";
        //console.log(isCheckedSSSFirstCutOff) */
        
        this.setState({
            FirstCutOff: e.target.checked
        })
    }

    handleChangeCheckboxSecondCutOff(e) {
        /* let isCheckedSSSecondCutOff = e.target.checked ? "1" : "0";
        //console.log(isCheckedSSSecondCutOff) */
        this.setState({
            SecondCutOff: e.target.checked
        })
    }

    handleChangeCheckboxThirdCutOff(e) {
        /* let isCheckedSSSThirdCutOff = e.target.checked ? "1" : "0";
        //console.log(isCheckedSSSThirdCutOff) */
        this.setState({
            ThirdCutOff: e.target.checked
        })
    }

    handleChangeCheckboxFourthCutOff(e) {
        /* let isCheckedSSSFourthCutOff = e.target.checked ? "1" : "0";
        //console.log(isCheckedSSSFourthCutOff) */
        this.setState({
            FourthCutOff: e.target.checked
        })
    }

    handleChangeCheckboxFifthCutOff(e) {
        /* let isCheckedSSSFourthCutOff = e.target.checked ? "1" : "0";
        //console.log(isCheckedSSSFourthCutOff) */
        this.setState({
            FifthCutOff: e.target.checked
        })
    }

    OnChangeIsFistOfTheYear = () =>{
        this.setState({
            isDateHired : false,
            isFistOfTheYear : true,
            DateHired : "0"
        })
    }

    OnChangeIsDateHired = (e) => {
        this.setState({
            isFistOfTheYear : false,
            isDateHired : true,
            DateHired : e.target.value
        })
    }

    handleChangeCheckboxUse(e) {
        this.setState({
            Use: e.target.checked
        })
    }

    OnChangeIsExcessDeduction = () =>{
        this.setState({
            isExcessDeduction : true,
            isLateDeduction : false,
            LateDeduction : "0"
        })
    }

    OnChangeIsLateDeduction = (e) => {
        this.setState({
            isExcessDeduction : false,
            isLateDeduction : true,
            LateDeduction : e.target.value
        })
    }

    /* handleCheckboxIsAllLateDeduction(e) {
        this.setState({
            lateDeduction: e.target.checked
        })

        if(this.state.checkIsAllLateDeduction == true) {
            this.setState({
                checkIsAllLateDeduction     :   false
            })
        }
        if(this.state.checkIsAllLateDeduction == false) {
            this.setState({
                checkIsAllLateDeduction     :   true
            })
        }
    } */


    getClientConfigRefresh(){
        const getParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId" : this.state.userinfo.companyId,
            //"ClientId": this.state.selectedClientId ,
            "UserId":this.state.userinfo.userId,
        }

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientConfigurations", getParams)
            .then(res => {
                const data = res.data
                console.log(data)
                this.setState({

                    lateAllowanceMins           :   data.lateAllowance,
                    payableOvertimeMins         :   data.payableOvertime,
                    valueTimeFrom               :   data.nightDiffStart,
                    valueTimeTo                 :   data.nightDiffEnd,
                    txtMaxLeaves                :   data.maxLeaves,
                    PayrollConfigurationTable   :   data.contributions,
                    OBBracket                   :   data.overbreakBrackets,
                    clientLeavesList            :   data.clientLeaves,
                    isloading                   :   false,
                })
                
                if(data.isDateHired == 0){
                    this.setState({
                        isFistOfTheYear : true,
                        DateHired : "0"
                    })
                }
                if(data.isDateHired == 1){
                    this.setState({
                        isDateHired : true,
                        DateHired : "1"
                    })
                }
                var alertType = (data.status=="1") ? "success" : "danger"
                this.setState(
                {
                    isloading   :   false,
                    AlertType   :   "Success!", 
                    show        :   true,
                    color       :   alertType,
                    message     :   data.message ,
                    fade        :   true,
                });
            })
    }


    onSubmitSaveClientConfig = () => {

        this.setState({isloading:true,})
        

        if(this.state.lateAllowanceMins=="")
        {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter minutes for late allowance.",
                fade        :   true
            });
            return
        }
        if(this.state.payableOvertimeMins=="")
        {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter range minutes payable overtime",
                fade        :   true
            });
            return
        }
        if(this.state.txtMaxLeaves=="")
        {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter max day leaves for allowable leaves",
                fade        :   true
            });
            return
        }
        if(this.state.checkIsAllLateDeduction == true){
            this.state.lateDeduction = "0"
        }else {
            this.state.lateDeduction = "1"
        }

        let newOBLst=[]
        if(this.state.OBBracket.length>0)
        {
            for(let i=0;i<this.state.OBBracket.length;i++)
            {
                let newOB ={
                    "Id":this.state.OBBracket[i]["id"].toString(),
                    "RangeFrom":this.state.OBBracket[i]["rangeFrom"].toString(),
                    "RangeTo":this.state.OBBracket[i]["rangeTo"].toString(),
                    "IsDeleted":this.state.OBBracket[i]["isDeleted"].toString(),
                }
                newOBLst.push(newOB)
            }
        }
        const params = {
            "IpAddress":"0.0.0.0",
            //"ClientId":this.state.selectedClientId,
            "CompanyId" : this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "LateAllowance":this.state.lateAllowanceMins,
            "PayableOvertime": this.state.payableOvertimeMins,
            "NightDiffStart": this.state.Fromvalue,
            "NightDiffEnd": this.state.Tovalue,
            "MaxLeaves": "0",
            /* "IsDateHired": (this.state.DateHired)? "1" : "0", */
            "IsDateHired": "0",
            "OverbreakBrackets":newOBLst,
            "Contributions": this.state.PayrollConfigurationTable,
            "IsAllLateDeduction" : this.state.LateDeduction,
            "ClientLeaves" : this.state.clientLeavesList
        }
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Client/SaveClientConfiguration", params)
            .then(res => {
                const data = res.data;
                this.setState({
                    isloading       :   false,
                    isshow          :   false,
                })
                this.getClientConfig()
                if(data.status=="1")
                {
                    this.setState({ 
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true
                    });
                }
                else {
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
            })
            .catch(error=>{
               this.setState(  {
                   isloading       :   false,
                   alerttype       :   "Error!",
                   isshow          :   true,
                   color           :   "danger",
                   message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                   fade            :   true
               })
           })

    }


    onChangetxtMaxLeaves = (e) => {
        this.setState({ txtMaxLeaves: e.target.value} )
    }
    onChangeOB = (e) => {
        this.setState({
            alerttype   :   "",
            isshow      :   false,
            color       :   "",
            message     :   "",
            fade        :   true
        });
        this.setState({ [e.target.name]: e.target.value} );
    }
    FormatCommas(x) {
        x = x.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x))
            x = x.replace(pattern, "$1,$2");
        return x;
    }
    IsNumeric(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }
	IsDecimal(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    getContributionType(){
        const getParams = {
            "IpAddress":"0.0.0.0",
            //"ClientId":this.state.selectedClientId,
            "CompanyId" : this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
        }

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetMandatoryContributionTypes", getParams)
            .then(res => {
               
                const data = res.data
                this.setState({
                    isloading:false,
                    contributionTypeList : data.contributionTypes
                })
            })
    }

    onChangeContributionType = (e) => {

        if(e.length == 0) {
            this.state.selectedContributionTypeId=""
            this.state.selectedContributionTypeName=""
            return
        } 
        this.state.selectedContributionTypeId = e[0].id
        this.state.selectedContributionTypeName = e[0].name

        if(this.state.selectedContributionTypeName == "HDMF") {
            this.setState({ceilingAmount : "100"})
        }
        if(this.state.selectedContributionTypeName == "SSS") {
            this.setState({ceilingAmount : "0"})
        }
        if(this.state.selectedContributionTypeName == "PHIC") {
            this.setState({ceilingAmount : "0"})
        }
    }

    GetEmployeePayModesList(){
        const getParams = {
            "IpAddress":"0.0.0.0",
            //"ClientId":this.state.selectedClientId,
            "CompanyId" : this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
        }
    
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollModes", getParams)
            .then(res => {
                this.setState({
                    payModesList : res.data.payrollModes
                })
            })
    
    }



    onChangeEmployeesPayModesList = (e) =>{
        if(e.length == 0) {
            this.state.selectedPayModeId=""
            this.state.selectedPayModeName=""
            return
        } 
        this.state.selectedPayModeId = e[0].id
        this.state.selectedPayModeName = e[0].name
        this.GetPeriodTypes();
        if(this.state.selectedPayModeId == 1){
            this.setState({
                workingDays : "26",
                payDay : "",
                disabledPeriodType : false,
                disabledFirst: false,
                disabledSecond: false,
                disabledThird: true,
                disabledFourth: true,
                disabledFifth: true,

                disabledPayDay: false,
                disabledWorkingDays: false,

                FirstCutOff : false,
                SecondCutOff : true,
                ThirdCutOff : false,
                FourthCutOff : false,
                FifthCutOff : false,

                checkFirst : false,
                checkSecond : true,
                checkThird : false,
                checkFourth : false,
                checkFifth : false,
            })
        }
      
        if(this.state.selectedPayModeId == 2){
            this.setState({
                periodTypesList: [],
                /* selectedPeriodTypeId : "", */
                workingDays : "0",
                payDay : "0",
                disabledPeriodType : true,
                disabledFirst: false,
                disabledSecond: false,
                disabledThird: false,
                disabledFourth: false,
                disabledFifth: false,

                disabledPayDay: true,
                disabledWorkingDays: true,

                checkFirst : true,
                checkSecond : true,
                checkThird : true,
                checkFourth : true,
                checkFifth : true,

                FirstCutOff : true,
                SecondCutOff : true,
                ThirdCutOff : true,
                FourthCutOff : true,
                FifthCutOff : true,
            })
        }

        if(this.state.selectedPayModeId == 3){
            this.setState({
                periodTypesList: [],
                workingDays : "0",
                payDay : "0",
                disabledPeriodType : true,
                disabledFirst: false,
                disabledSecond: false,
                disabledThird: false,
                disabledFourth: false,
                disabledFifth: false,

                disabledPayDay: true,
                disabledWorkingDays: true,

                checkFirst : true,
                checkSecond : true,
                checkThird : true,
                checkFourth : true,
                checkFifth : true,

                FirstCutOff : true,
                SecondCutOff : true,
                ThirdCutOff : true,
                FourthCutOff : true,
                FifthCutOff : true,
            })
        }

        if(this.state.selectedPayModeId == 4){
            this.setState({
                periodTypesList: [],
                workingDays : "0",
                payDay : "0",
                disabledPeriodType : true,
                disabledFirst: false,
                disabledSecond: false,
                disabledThird: false,
                disabledFourth: false,
                disabledFifth: false,

                disabledPayDay: true,
                disabledWorkingDays: true,

                checkFirst : true,
                checkSecond : true,
                checkThird : true,
                checkFourth : true,
                checkFifth : true,

                FirstCutOff : true,
                SecondCutOff : true,
                ThirdCutOff : true,
                FourthCutOff : true,
                FifthCutOff : true,
            })
        }
    }

    GetPeriodTypes(){
        const getParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId" : this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
	        "Name":"",
            "isDeleted" : "0"
            
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint +  "Maintenance/GetPeriodTypes", getParams)
        .then(res => {
            const data = res.data
            console.log("GetPeriodTypes1111111")
            console.log(data)
            this.setState({periodTypesList : data.periodTypes}) 
        })
    }

    handleChangePeriodTypes = (e) =>{
        if(e.length == 0) {
            this.state.selectedPeriodTypeId=""
            this.state.selectedPeriodTypeName=""
            this.state.selectedPayout = ""
            return
        } 
        this.state.selectedPeriodTypeId = e[0].id
        this.state.selectedPeriodTypeName = e[0].name
        this.state.selectedPayout = e[0].payOut

    }
    
    componentDidMount() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.selectedClientId = this.state.userinfo.companyId
        sleep(1000).then(() => {
            this.setState({isloading:false})
        })
        this.getClient();
        this.GetLeaveTypes();
        this.getContributionType();
        this.GetEmployeePayModesList();
        this.getClientConfig();
        //this.getClientData();
    }

    getClientData(){
        const getParams = {
            "IpAddress":"0.0.0.0",
            //"ClientId": this.state.selectedClientId ,
            "CompanyId" : this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
        }

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientConfigurations", getParams)
            .then(res => {
                console.log("Get Client Configurations");
                const data = res.data
                console.log(data);
            })
    }

    getClient(){
        this.setState({isloading:true,})

        const getParams = {
            "IpAddress":"0.0.0.0",
            //"ClientId":this.state.userinfo.clientId,
            "CompanyId" : this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
        }

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
            .then(res => {
                this.setState({
                    isloading:false,
                    getClientList : res.data.clients
                })
            })
    }

    GetClientId(name) {
        let GetClientId = ''
        for (let i = 0; i <= this.state.getClientList.length; i++) {
            if (this.state.getClientList[i]["name"] === name) {
                GetClientId = this.state.getClientList[i]['id'];
                break;
            }
        }
        return GetClientId
    }

    getClientConfig(){
        const getParams = {
            "IpAddress":"0.0.0.0",
            //"ClientId": this.state.selectedClientId ,
            "CompanyId" : this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
        }

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientConfigurations", getParams)
            .then(res => {
              
                const data = res.data
                console.log("getClientConfig")
                console.log(data)
                let today = new Date();
                let valueFrom = new moment();
                let valueTo = new Date();
                let dd = String(today.getDate()).padStart(2, '0');
                let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                let yyyy = today.getFullYear();

                valueFrom = moment(mm + '/' + dd + '/' + yyyy + ' ' + data.nightDiffStart);
                valueTo = moment(mm + '/' + dd + '/' + yyyy + ' ' + data.nightDiffEnd);

                this.setState({

                    lateAllowanceMins           :   data.lateAllowance,
                    payableOvertimeMins         :   data.payableOvertime,
                    valueFrom                   :   valueFrom,//data.nightDiffStart,
                    valueTo                     :   valueTo,//data.nightDiffEnd,
                    txtMaxLeaves                :   data.maxLeaves,
                    PayrollConfigurationTable   :   data.contributions,
                    OBBracket                   :   data.overbreakBrackets,
                    clientLeavesList            :   data.clientLeaves,
                    isloading                   :   false,
                })
                // if(data.isDateHired == 0){
                //     this.setState({
                //         isFistOfTheYear : true,
                //         DateHired : "0"
                //     })
                // }
                // if(data.isDateHired == 1){
                //     this.setState({
                //         isDateHired : true,
                //         DateHired : "1"
                //     })
                // }
                
                if(data.isAllLateDeduction == 0){
                    this.setState({
                        isExcessDeduction : true,
                        isLateDeduction : false,
                        LateDeduction : "0"
                    })
                }
                if(data.isAllLateDeduction == 1){
                    this.setState({
                        isLateDeduction : true,
                        isExcessDeduction : false,
                        LateDeduction : "1"
                    })
                }
            })
    }

    onChangeClientList = (e) => {
       
        if(e.length == 0) {
            this.state.selectedClientId         =''
            this.state.lateAllowanceMins        = ''
            this.state.selectedPayableOvertime  = ''
            this.state.selectedNightDiffStart   = ''
            this.state.selectedNightDiffEnd     = ''
            this.state.selectedMaxLeaves        = ''
            this.state.selectedContributionType = ''

            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.lateAllowanceMins = e[0].lateAllowance

        this.GetLeaveTypes();
        this.getContributionType();
        this.GetEmployeePayModesList();
        this.getClientConfig();
    }

    onChangelateAllowanceMins = (e) => {
        this.setState({ lateAllowanceMins: e.target.value} );
    }

    onChangeWorkingDays = (e) => {
        this.setState({ workingDays: e.target.value} );
    }

    

    onChangePayDay = (e) => {
        this.setState({ payDay: e.target.value} );
    }


    onChangepayableOvertimeMins = (e) => {
        this.setState({ payableOvertimeMins: e.target.value} );
    }
  

    onChangeFrom = (Fromvalue) => {
        this.state.Fromvalue = Fromvalue && Fromvalue.format(str)
    } 

    onChangeTo = (Tovalue) => {
        this.state.Tovalue = Tovalue && Tovalue.format(str)
    }
    
    onSubmitOB = (e) => {

        let RangeFrom = this.state.txtRangeFrom
        let RangeTo = this.state.txtRangeTo
        
       /*  if(!this.state.selectedClientId){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   true
            });
            return
        } */

        if(RangeFrom=="" || RangeTo=="")
        {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Range from or Range to is invalid.",
                fade        :   true
            });
            return
        }

        if(parseInt(RangeFrom)>=parseInt(RangeTo) ){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Range from or Range to is invalid.",
                fade        :   true
            });
            return
        }


        let lstRange = this.state.OBBracket
        
        if(lstRange.length>0)
        {
            let lastRangeTo = lstRange[lstRange.length-1].rangeTo
            
            if(parseInt(RangeFrom) <=parseInt(lastRangeTo))
            {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "Range from or Range to is overlapping from previous OB.",
                    fade        :   true
                });
                return
            }
        }        
        
        let newOB ={
            "id":"0",
            "rangeFrom":RangeFrom.toString(),
            "rangeTo":RangeTo.toString(),
            "isDeleted":"0"
        }
        lstRange.push(newOB)
        this.setState({OBBracket: lstRange})
    }


    setMaxDayLeaves(event) {
        this.setState({
            DateHired : event.target.value
        })
      }

    onChangeCeilingAmount = (e) => {
        this.setState({ ceilingAmount: e.target.value} );
    }

    onSubmitPayrollConfig = (e) => {
        
        if(!this.state.selectedClientId){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
               // message     :   "Please select client.",
                fade        :   true
            });
            return
        }
        
        if(!this.state.selectedContributionTypeId){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select contribution type.",
                fade        :   true
            });
            return
        }

        if(!this.state.selectedPayModeId){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select pay mode.",
                fade        :   true
            });
            return
        }

        if(this.state.selectedPayModeId == 1){
            if(!this.state.selectedPeriodTypeId) {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "Please select period type.",
                    fade        :   true
                });
                return
            }

            if(this.state.workingDays == "" || parseInt(this.state.workingDays)<=0) {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "Invalid working days in 1 Month.",
                    fade        :   true
                });
                return
            }
        }
        
        const obj = {
            "id":"0",
            "contributionTypeId" : this.state.selectedContributionTypeId,
            "payModeId" : this.state.selectedPayModeId,
            "periodTypeId" : this.state.selectedPeriodTypeId,
            "isBasic" : (this.state.IsBasic)? "1" : "0",
            "isPremium" : (this.state.IsPremium)? "1" : "0",
            "isColaRate" : (this.state.IsColaRate)? "1" : "0",
            "isEColaRate" : (this.state.IsEColaRate)? "1" : "0",
            "isSeaRate" : (this.state.IsSeaRate)? "1" : "0",
            "ceilingAmount" : this.state.ceilingAmount ? this.FormatCommas(parseFloat(this.state.ceilingAmount).toFixed(2)) : "0",
            "fixedAmount" : this.state.fixedAmount ? this.FormatCommas(parseFloat(this.state.fixedAmount).toFixed(2)) : "0",
            "workingDaysInMonth" : this.state.workingDays,
            "payDay" : this.state.payDay,
            "firstCutOff" : (this.state.FirstCutOff)? "1" : "0",
            "secondCutOff" : (this.state.SecondCutOff)? "1" : "0",
            "thirdCutOff" : (this.state.ThirdCutOff)? "1" : "0",
            "fourthCutOff" : (this.state.FourthCutOff)? "1" : "0",
            "fifthCutOff" : (this.state.FifthCutOff)? "1" : "0",
            "isDeleted" : "0",

            "contributionType":this.state.selectedContributionTypeName,
            "payrollMode":this.state.selectedPayModeName,
            "periodType":this.state.selectedPeriodTypeName,
            "basic":(this.state.IsBasic)? "YES" : "NO",
            "premium":(this.state.IsPremium)? "YES" : "NO",
            "colaRate":(this.state.IsColaRate)? "YES" : "NO",
            "eColaRate":(this.state.IsEColaRate)? "YES" : "NO",
            "seaRate":(this.state.IsSeaRate)? "YES" : "NO",
            "firstCutOffLabel":(this.state.FirstCutOff)? "YES" : "NO",
            "secondCutOffLabel":(this.state.SecondCutOff)? "YES" : "NO",
            "thirdCutOffLabel":(this.state.ThirdCutOff)? "YES" : "NO",
            "fourthCutOffLabel":(this.state.FourthCutOff)? "YES" : "NO",
            "fifthCutOffLabel":(this.state.FifthCutOff)? "YES" : "NO",
        }
        
        let isNew = true;
        const lstRange = this.state.PayrollConfigurationTable
        lstRange.forEach(itm => {
            if(itm.contributionTypeId==obj.contributionTypeId &&
                itm.payModeId==obj.payModeId)
                {
                    itm.periodTypeId=obj.periodTypeId
                    itm.isBasic=obj.isBasic
                    itm.isPremium=obj.isPremium
                    itm.isColaRate=obj.isColaRate
                    itm.isEColaRate=obj.isEColaRate
                    itm.isSeaRate=obj.isSeaRate
                    itm.ceilingAmount=obj.ceilingAmount
                    itm.fixedAmount=obj.fixedAmount
                    itm.workingDaysInMonth=obj.workingDaysInMonth
                    itm.payDay=obj.payDay
                    itm.FirstCutOff=obj.firstCutOff
                    itm.SecondCutOff=obj.secondCutOff
                    itm.ThirdCutOff=obj.thirdCutOff
                    itm.FourthCutOff=obj.fourthCutOff
                    itm.FifthCutOff=obj.fifthCutOff

                    itm.contributionType = obj.contributionType
                    itm.payrollMode = obj.payrollMode
                    itm.periodType = obj.periodType
                    itm.basic = obj.basic
                    itm.premium = obj.premium
                    itm.colaRate=obj.colaRate
                    itm.eColaRate=obj.eColaRate
                    itm.seaRate=obj.seaRate
                    itm.firstCutOffLabel = obj.firstCutOffLabel
                    itm.secondCutOffLabel = obj.secondCutOffLabel
                    itm.thirdCutOffLabel = obj.thirdCutOffLabel
                    itm.fourthCutOffLabel = obj.fourthCutOffLabel
                    itm.fifthCutOffLabel = obj.fifthCutOffLabel
                    isNew=false
                }
        });
        if(isNew){
            lstRange.push(obj)
        }
        this.setState({PayrollConfigurationTable: lstRange}) 
    }

    LeavePagewithoutSavingChanges() {
        const isChanged=false
        for (let i = 0; i < this.state.PayrollConfigurationTable.length; i++) {
            if(this.state.PayrollConfigurationTable[i]["isModified"] ==1) {
                this.setState({isGridDataChanged: true})
                isChanged=true
                break;
            }
        }
        return isChanged
    }
    GridDataModified(oldValue, newValue, id, column) {
        this.state.PayrollConfigurationTable.map(function(item,i) {
            if(item.id===id)
            item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    onRemovePayrollConfig = () => {

        let listPayroll = this.state.PayrollConfigurationAddList
        for( var i = 0; i < listPayroll.length; i++){ 
            if ( listPayroll[i].IsSelected === 1) {
                listPayroll.splice(i, 1); 
                i--;
            }
            }
        
        this.setState({PayrollConfigurationAddList:listPayroll})
    }
    IsValidAmount(val)
    {
        var regexp = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;
        return !regexp.test(val)
    }
    onChangeFixedAmount = (e) => {
        if(e.target.value.length>0){if(this.IsValidAmount(e.target.value)) return}
        this.setState({ fixedAmount: e.target.value} );
    }
    handleChangeLeaveType = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length==0){ this.state.leaveTypeId= ''; return }
        this.state.leaveTypeId=e[0].id
        this.state.leaveType=e[0].name
    }
    handleChangeMaxLeave = (e) =>{
        this.setState({maxLeaves : e.target.value,alerttype:"",isshow:false,color:"",message:"",fade:false})
    }
    handleAddLeave = (e) =>{
        this.setState({isloading:true})
        if(!this.state.leaveTypeId){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select leave type.",
                fade        :   true
            });
            return
        }
        
        if(!this.state.maxLeaves){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter value for max leave(s).",
                fade        :   true
            });
            return
        }
        if(parseInt(this.state.maxLeaves) == 0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter a valid value for max leave.",
                fade        :   true
            });
            return
        }

        const ids = this.state.clientLeavesList.map(x => x.id);
        const sorted = ids.sort((a, b) => a - b);
        let maxId = parseInt(sorted[sorted.length-1]) + 1;
        const obj = {
            "id":maxId.toString(),
            "leaveTypeId" : this.state.leaveTypeId,
            "leaveType" : this.state.leaveType,
            "isDateHired": this.state.isDateHired? "1" : "0",
            "maxLeaves" : this.state.maxLeaves,
            "isDeleted" : "0",
        }
        
        let isNew = true;
        const lstRange = this.state.clientLeavesList
        lstRange.forEach(itm => {
            if(itm.leaveTypeId==obj.leaveTypeId)
            {
                itm.id=obj.id
                itm.leaveTypeId=obj.leaveTypeId
                itm.leaveType=obj.leaveType
                itm.maxLeaves=obj.maxLeaves
                itm.isDateHired=obj.isDateHired
                itm.isDeleted="0"
                isNew=false
            }
        });
        if(isNew){
            lstRange.push(obj)
        }
        this.state.clientLeavesList = []
        this.setState({clientLeavesList: lstRange,isloading:false,maxLeaves:''})
        this.refs.leaveRef.clear() 
    }
    GetLeaveTypes(){
        const params = 
        {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetLeaveTypes", params)
        .then(res => {
            const data = res.data
            let leaveTypesListGRID=[]
            data.leaveTypes.map(function(itm,idx){
                leaveTypesListGRID.push({
                    "value" : itm.id,
                    "label" : itm.name
                })
            })
            this.setState({
                isloading:false,
                leaveTypesList : data.leaveTypes,
                leaveTypesListGRID:leaveTypesListGRID
            })
        })
    }
    handleChangeDateHired = (e) =>{
        this.setState({isDateHired:e.target.checked})
        if(e.target.name==="datehired")
        this.setState({isDateHired:true})
        else
        this.setState({isDateHired:false})
        
    }
    render() {
        const columnOBBracket = [
            {
                dataField: 'rangeFrom',
                text: 'From',
                headerStyle: () => {
                    return { width: "6%", textAlign:'center' };
                  },
                style:()=>{
                    return {textAlign:'center'}
                }
            },
            {
                dataField: 'rangeTo',
                text: 'To',
                headerStyle: () => {
                    return { width: "94%",textAlign:'left' };
                  },
                  style:()=>{
                    return {textAlign:'left'}
                }
            },
        ]

        const columnCreatedOBBracket = [
            {
                dataField: 'RangeFrom',
                text: 'From',
                headerStyle: () => {
                    return { width: "10%" };
                  }
            },
            {
                dataField: 'RangeTo',
                text: 'To',
                headerStyle: () => {
                    return { width: "90%", textAlign:'left' };
                  }
            },
        ]

        const employeeOBBracketRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.OBBracket.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }

                })
             }
        };

        const leaveColumns = [
            {
                dataField: 'leaveTypeId',
                text: 'LEAVE TYPE',
                headerStyle: () => {
                    return { width:'12%',textAlign:'left' };
                  },
                style:()=>{
                    return {textAlign:'left'}
                },
                formatter: (cell, row) => {
                    if(row.leaveTypeId!='' && row.leaveTypeId!=null){
                        return this.state.leaveTypesListGRID.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.leaveTypesListGRID
                }
            },
            {
                dataField: 'maxLeaves',
                text: 'MAX LEAVES (DAYS)',
                headerStyle: () => {
                    return { width: "12%",textAlign:'center' };
                  },
                  style:()=>{
                    return {textAlign:'center'}
                }
            },
            {
                dataField: 'isDateHired',
                text: 'RESET TYPE',
                headerStyle: () => {
                    return { width: "76%",textAlign:'left' };
                  },
                  style:()=>{
                    return {textAlign:'left'}
                },
                formatter: (cell, row) => {
                    if(row.isDateHired!='' && row.isDateHired!=null){
                        return this.state.leaveTypeOptions.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.leaveTypeOptions
                }
            },
        ]
        const payrollConfigurationColumn = [
            {
                dataField: 'contributionType',
                text: 'CONTRIBUTION',
                headerStyle: () => {
                    return { width:'7%',textAlign:'left' };
                  },
                style:()=>{
                    return {textAlign:'center'}
                }
            },
            {
                dataField: 'payrollMode',
                text: 'PAYROLL MODE',
                headerStyle: () => {
                    return { width: "8%",textAlign:'center' };
                  },
                  style:()=>{
                    return {textAlign:'center'}
                }
            },
            ,
            {
                dataField: 'periodType',
                text: 'PERIOD TYPE',
                headerStyle: () => {
                    return { width: "22%",textAlign:'center' };
                  },
                  style:()=>{
                    return {textAlign:'left'}
                }
            },
            {
                dataField: 'basic',
                text: 'BASIC',
                headerStyle: () => {
                    return { width: "5%",textAlign:'center' };
                  },
                  style:()=>{
                    return {textAlign:'center'}
                }
            },
            {
                dataField: 'premium',
                text: 'PREMIUM',
                headerStyle: () => {
                    return { width: "5%",textAlign:'center' };
                  },
                  style:()=>{
                    return {textAlign:'center'}
                }
            },
            {
                dataField: 'colaRate',
                text: 'COLA',
                headerStyle: () => {
                    return { width: "5%",textAlign:'center' };
                  },
                  style:()=>{
                    return {textAlign:'center'}
                }
            },
            {
                dataField: 'eColaRate',
                text: 'ECOLA',
                headerStyle: () => {
                    return { width: "5%",textAlign:'center' };
                  },
                  style:()=>{
                    return {textAlign:'center'}
                }
            },
            {
                dataField: 'seaRate',
                text: 'SEA',
                headerStyle: () => {
                    return { width: "5%",textAlign:'center' };
                  },
                  style:()=>{
                    return {textAlign:'center'}
                }
            },
            {
                dataField: 'ceilingAmount',
                text: 'CEILING',
                headerStyle: () => {
                    return { width: "5%",textAlign:'center' };
                  },
                  style:()=>{
                    return {textAlign:'right'}
                }
            },
            {
                dataField: 'fixedAmount',
                text: 'FIXED AMOUNT',
                headerStyle: () => {
                    return { width: "6%",textAlign:'center' };
                  },
                  style:()=>{
                    return {textAlign:'right'}
                }
            },
            {
                dataField: 'workingDaysInMonth',
                text: 'DAYS',
                headerStyle: () => {
                    return { width: "5%",textAlign:'center' };
                  },
                  style:()=>{
                    return {textAlign:'center'}
                }
            },
            // {
            //     dataField: 'payDay',
            //     text: 'PAY DAY',
            //     headerStyle: () => {
            //         return { width: "8%",textAlign:'center' };
            //       },
            //       style:()=>{
            //         return {textAlign:'center'}
            //     }
            // },
            {
                dataField: 'firstCutOffLabel',
                text: '1ST',
                headerStyle: () => {
                    return { width: "5%",textAlign:'center' };
                  },
                  style:()=>{
                    return {textAlign:'center'}
                }
            },
            {
                dataField: 'secondCutOffLabel',
                text: '2ND',
                headerStyle: () => {
                    return { width: "5%",textAlign:'center' };
                  },
                  style:()=>{
                    return {textAlign:'center'}
                }
            },
            {
                dataField: 'thirdCutOffLabel',
                text: '3RD',
                headerStyle: () => {
                    return { width: "5%",textAlign:'center' };
                  },
                  style:()=>{
                    return {textAlign:'center'}
                }
            },
            {
                dataField: 'fourthCutOffLabel',
                text: '4TH',
                headerStyle: () => {
                    return { width: "5%",textAlign:'center' };
                  },
                  style:()=>{
                    return {textAlign:'center'}
                }
            },
            {
                dataField: 'fifthCutOffLabel',
                text: '5TH',
                headerStyle: () => {
                    return { width: "5%",textAlign:'center' };
                  },
                  style:()=>{
                    return {textAlign:'center'}
                }
            }
        ]

        const selectRowPayrollConfig = {
            mode: 'checkbox',
            clickToSelectAndEditCell: false,
            onSelect: (row, isSelect, rowIndex, e) => {
                //console.log(isSelect)
                this.state.PayrollConfigurationTable.map(function(item,idx){
                    if(idx==rowIndex)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                }) 
            }
        };
        const selectRowLeaveConfig = {
            mode: 'checkbox',
            clickToSelectAndEditCell: false,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.clientLeavesList.map(function(item,idx){
                    if(idx==rowIndex)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                }) 
            }
        };

        const sizePerPageRenderer = ({
            options,
            currSizePerPage,
            onSizePerPageChange
            }) => (
            <div className="btn-group" role="group">
                {
                options.map((option) => {
                    const isSelect = currSizePerPage === `${option.page}`;
                    return (
                    <button
                        key={ option.text }
                        type="button"
                        onClick={ () => onSizePerPageChange(option.page) }
                        className={ `btn ${isSelect ? 'btn-primary' : 'btn-success'}` }
                    >
                        { option.text }
                    </button>
                    );
                })
                }
            </div>
            );
        const options = {
            sizePerPageRenderer
        };

        const formatFromTime = 'HH:mm';
        
        
    return(
        <div>
            <Banner />
            <Container fluid>
                <Card className="mt-5" >
                    <Card.Header>Timekeeping >> Company Configuration</Card.Header>
                    <Card.Body>
                        <Form>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={5}>
                                    <Typeahead
                                    
                                        labelKey='name'
                                        id="basic-example"
                                        //value={this.state.clientType}
                                        onChange={this.onChangeClientList}
                                        options={this.state.getClientList}
                                        placeholder="Select Company"
                                    />
                                </Col>
                            </Form.Group> */}
                            <Tabs defaultActiveKey="default" transition={false} id="noanim-tab-example">
                            <Tab eventKey="default" title="Default Configurations">
                                <Form.Row>
                                <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                    <Card className="card-tab">
                                        <div className="card-header-tab"></div>
                                        <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={3}>
                                                <fieldset className="border p-2">
                                                    <legend className="w-auto">Late Allowance</legend>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridEmail">
                                                            <Form.Control 
                                                                type="text"
                                                                onKeyPress={this.IsNumeric.bind(this)}
                                                                ref="lateAllowanceMins"
                                                                autoComplete="off" 
                                                                name="lateAllowanceMins"
                                                                value={this.state.lateAllowanceMins}
                                                                onChange={this.onChangelateAllowanceMins.bind(this)}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="0" controlId="formGridPassword">
                                                            <Form.Label>
                                                                mins
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Col sm="12">
                                                            <Form.Check 
                                                                inline
                                                                type="radio" 
                                                                label="Excess GP Deduction"
                                                                value="0"
                                                                name="excessDeduction" 
                                                                checked={this.state.isExcessDeduction}
                                                                onChange={this.OnChangeIsExcessDeduction}
                                                            />
                                                            <Form.Check 
                                                                inline
                                                                type="radio" 
                                                                label="All Late Deduction"
                                                                value="1" 
                                                                name="lateDeduction"
                                                                checked={this.state.isLateDeduction}
                                                                onChange={this.OnChangeIsLateDeduction}
                                                            />
                                                        </Col>
                                                        <Col md="auto">
                                                            
                                                        </Col>
                                                    </Form.Row>
                                                </fieldset>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={3}>
                                                <fieldset className="border p-2">
                                                    <legend className="w-auto">Payable Overtime</legend>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridEmail">
                                                                <Form.Control 
                                                                    onKeyPress={this.IsNumeric.bind(this)}
                                                                    type="text" 
                                                                    ref="payableOvertimeMins"
                                                                    autoComplete="off" 
                                                                    name="payableOvertimeMins"
                                                                    value={this.state.payableOvertimeMins}
                                                                    onChange={this.onChangepayableOvertimeMins.bind(this)}
                                                                />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="0" controlId="formGridPassword">
                                                            <Form.Label>
                                                                mins
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </fieldset>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={3}>
                                                <fieldset className="border p-2">
                                                    <legend className="w-auto">Night Differential</legend>
                                                    <Form.Group as={Col} controlId="formGridEmail">
                                                    <Form.Row>
                                                            <Form.Group as={Col} sm={0} controlId="formGridEmail">
                                                                <Form.Label>
                                                                    From
                                                                </Form.Label>
                                                            </Form.Group>
                                                            <Form.Group as={Col} sm={3} controlId="formGridEmail">
                                                                <TimePicker 
                                                                    showSecond={showSecond}
                                                                    value={this.state.valueFrom}
                                                                    defaultValue={this.state.valueFrom}
                                                                    className="xxx"
                                                                    onChange={this.onChangeFrom}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} sm={2} controlId="formGridEmail">
                                                            </Form.Group>

                                                            <Form.Group as={Col} sm={0} controlId="formGridPassword">
                                                                <Form.Label>
                                                                    To
                                                                </Form.Label>
                                                            </Form.Group>
                                                            
                                                            <Form.Group as={Col} sm={3} controlId="formGridPassword">
                                                                <TimePicker 
                                                                    showSecond={showSecond}
                                                                    value={this.state.valueTo}
                                                                    defaultValue={this.state.valueTo}
                                                                    className="xxx"
                                                                    onChange={this.onChangeTo}
                                                                />
                                                            </Form.Group>
                                                        </Form.Row>
                                                    </Form.Group>
                                                </fieldset>
                                            </Form.Group>
                                            {/* <Form.Group as={Col} sm={3}>
                                                <fieldset className="border p-2">
                                                    <legend className="w-auto">Allowable Leaves</legend>
                                                    <Form.Group as={Col}controlId="formGridPassword">
                                                        <Form.Control 
                                                            type="text" 
                                                            onKeyPress={this.IsNumeric.bind(this)}
                                                            ref="txtMaxLeaves"
                                                            autoComplete="off" 
                                                            name="txtMaxLeaves"
                                                            value={this.state.txtMaxLeaves}
                                                            onChange={this.onChangetxtMaxLeaves.bind(this)}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col}>
                                                    <div >
                                                        <input 
                                                            inline
                                                            type="radio" 
                                                            value="0" 
                                                            name="MaxDayLeaves" 
                                                            checked={this.state.isFistOfTheYear}
                                                            onChange={this.OnChangeIsFistOfTheYear}
                                                        /> 1st of the year &nbsp;&nbsp;&nbsp;
                                                        <input 
                                                            inline
                                                            type="radio" 
                                                            value="1" 
                                                            name="MaxDayLeaves"
                                                            checked={this.state.isDateHired}
                                                            onChange={this.OnChangeIsDateHired}
                                                            /> Date Hired
                                                    </div>
                                                    </Form.Group>
                                                </fieldset>
                                            </Form.Group> */}
                                        </Form.Row>
                                        </Card.Body>
                                    </Card>
                                </Form.Group>
                            </Form.Row>
                            </Tab>
                            <Tab eventKey="payroll" title="Payroll Configurations">
                            <Card className="card-tab">
                                <div className="card-header-tab"></div>
                                <Card.Body>
                                    <Form.Group as={Col} sm={12}>
                                        <fieldset className="border p-2" sm={6}>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={6} controlId="formHorizontalEmail">
                                                <Typeahead
                                                        
                                                        labelKey='name'
                                                        id="basic-example"
                                                        onChange={this.onChangeContributionType}
                                                        options={this.state.contributionTypeList}
                                                        placeholder="Select Contribution Type"
                                                    />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={6} controlId="formHorizontalEmail">
                                                <Typeahead
                                                        labelKey='name'
                                                        id="basic-example"
                                                        onChange={this.onChangeEmployeesPayModesList}
                                                        options={this.state.payModesList}
                                                        placeholder="Select Pay Mode"
                                                    />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={6} controlId="formHorizontalEmail">
                                                <Typeahead
                                                        labelKey='name'
                                                        id="basic-example"
                                                        onChange={this.handleChangePeriodTypes}
                                                        options={this.state.periodTypesList}
                                                        placeholder="Select Period Type"
                                                        disabled={this.state.disabledPeriodType}
                                                    />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={1}>
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label="Basic"
                                                    onChange={e => this.handleChangeCheckbox(e)}
                                                    checked={this.state.IsBasic}
                                                    disabled={true}
                                                    name="IsBasicSalary"
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>
                                            <Form.Check 
                                                    type="checkbox" 
                                                    label="Premium"
                                                    onChange={e => this.handleChangeCheckbox(e)}
                                                    name="IsPremium"
                                                    checked={this.state.IsPremium}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>
                                            <Form.Check 
                                                    type="checkbox" 
                                                    label="Cola"
                                                    onChange={e => this.handleChangeCheckbox(e)}
                                                    name="IsColaRate"
                                                    checked={this.state.IsColaRate}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>
                                            <Form.Check 
                                                    type="checkbox" 
                                                    label="E-Cola"
                                                    onChange={e => this.handleChangeCheckbox(e)}
                                                    name="IsEColaRate"
                                                    checked={this.state.IsEColaRate}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>
                                            <Form.Check 
                                                    type="checkbox" 
                                                    label="Sea"
                                                    onChange={e => this.handleChangeCheckbox(e)}
                                                    name="IsSeaRate"
                                                    checked={this.state.IsSeaRate}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={1}>
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label="1st"
                                                    onChange={e => this.handleChangeCheckbox(e)}
                                                    checked={this.state.FirstCutOff}
                                                    disabled={this.state.disabledFirst}
                                                    name="FirstCutOff"
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label="2nd"
                                                    onChange={e => this.handleChangeCheckbox(e)}
                                                    checked={this.state.SecondCutOff}
                                                    disabled={this.state.disabledSecond}
                                                    name="SecondCutOff"
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label="3rd" 
                                                    onChange={e => this.handleChangeCheckbox(e)}
                                                    checked={this.state.ThirdCutOff}
                                                    disabled={this.state.disabledThird}
                                                    name="ThirdCutOff"
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label="4th"
                                                    onChange={e => this.handleChangeCheckbox(e)}
                                                    checked={this.state.FourthCutOff}
                                                    disabled={this.state.disabledFourth}
                                                    name="FourthCutOff"
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label="5th"
                                                    onChange={e => this.handleChangeCheckbox(e)}
                                                    checked={this.state.FifthCutOff}
                                                    disabled={this.state.disabledFifth}
                                                    name="FifthCutOff"
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            {/* <Form.Group as={Col} sm={0}>
                                                <Form.Label >Pay Day :</Form.Label>        
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>
                                                <Form.Control 
                                                    type="text"
                                                    ref="payDay"
                                                    autoComplete="off" 
                                                    name="payDay"
                                                    value={this.state.payDay}
                                                    onChange={this.onChangePayDay}
                                                    disabled={this.state.selectedPayModeId=="1" ? false : true }//{this.state.disabledPayDay}
                                                    onKeyPress={this.IsNumeric.bind(this)}
                                                />
                                            </Form.Group> */}
                                            <Form.Group as={Col} sm={0}>
                                                <Form.Label >Ceiling Amount :</Form.Label>        
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>
                                                <Form.Control 
                                                    type="text"
                                                    ref="ceilingAmount"
                                                    autoComplete="off" 
                                                    name="ceilingAmount"
                                                    value={this.state.ceilingAmount}
                                                    onChange={this.onChangeCeilingAmount.bind(this)}
                                                    onKeyPress={this.IsNumeric.bind(this)}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={0}>
                                                <Form.Label >Fixed Amount :</Form.Label>        
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>
                                                <Form.Control 
                                                    type="text"
                                                    ref="fixedAmount"
                                                    autoComplete="off" 
                                                    name="fixedAmount"
                                                    value={this.state.fixedAmount}
                                                    onChange={this.onChangeFixedAmount.bind(this)}
                                                    //onKeyPress={this.IsDecimal.bind(this)}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={0}>
                                                <Form.Label >Days in 1 Month :</Form.Label>        
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>
                                                <Form.Control 
                                                    type="text"
                                                    ref="workingDays"
                                                    autoComplete="off" 
                                                    name="workingDays"
                                                    value={this.state.workingDays}
                                                    onChange={this.onChangeWorkingDays}
                                                    className="ml-auto"
                                                    disabled={this.state.selectedPayModeId=="1" ? false : true }
                                                    onKeyPress={this.IsDecimal.bind(this)}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        </fieldset>
                                        <Form.Row>
                                                <Form.Group as={Col} sm={12}>
                                                    <ButtonToolbar className="mt-2">
                                                        <Button style={{minWidth:'60px'}} variant="success" onClick={this.onSubmitPayrollConfig}>Add</Button>
                                                    </ButtonToolbar>
                                                </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={12}>
                                                <Card.Header>Payroll Configuration List</Card.Header>
                                                <BootstrapTable
                                                    striped
                                                    hover
                                                    condensed
                                                    keyField = "id"
                                                    data = { this.state.PayrollConfigurationTable }
                                                    columns = { payrollConfigurationColumn }
                                                    selectRow = { selectRowPayrollConfig }
                                                    pagination={ paginationFactory({sizePerPage:15,hideSizePerPage:true,hidePageListOnlyOnePage:true})}
                                                    cellEdit = { cellEditFactory({ 
                                                            mode: 'dbclick', 
                                                            blurToSave: true,
                                                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                                                this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    /> 
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                            </Tab>
                            <Tab eventKey="overbreak" title="Overbreak Configurations">
                                <Card className="card-tab">
                                        <div className="card-header-tab"></div>
                                        <Card.Body>
                                            <Form.Row>
                                            <Form.Group as={Col} sm={0}>
                                                <Form.Label>
                                                    From :
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>
                                                <Form.Control 
                                                    type="text" 
                                                    onKeyPress={this.IsNumeric.bind(this)}
                                                    ref="txtRangeFrom"
                                                    autoComplete="off" 
                                                    name="txtRangeFrom"
                                                    value={this.state.txtRangeFrom}
                                                    onChange={this.onChangeOB}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={0}>
                                                <Form.Label>
                                                    To :
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>
                                                <Form.Control 
                                                    type="text" 
                                                    onKeyPress={this.IsNumeric.bind(this)}
                                                    ref="txtRangeTo"
                                                    autoComplete="off" 
                                                    name="txtRangeTo"
                                                    value={this.state.txtRangeTo}
                                                    onChange={this.onChangeOB}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>
                                                <ButtonToolbar>
                                                    <Button style={{minWidth:'60px'}} variant="success" onClick={this.onSubmitOB}>Add</Button>
                                                </ButtonToolbar>
                                            </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} sm={12}>
                                                    <Card.Header>Overbreak Bracker List</Card.Header>
                                                    <BootstrapTable
                                                        keyField = "id"
                                                        data = { this.state.OBBracket }
                                                        columns = { columnOBBracket }
                                                        selectRow = { employeeOBBracketRow }
                                                        cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                                        pagination={ paginationFactory({sizePerPage:15,hideSizePerPage:true,hidePageListOnlyOnePage:true})}
                                                        rowClasses="noser-table-row-class"
                                                        striped
                                                        hover
                                                        condensed
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Card.Body>
                                    </Card>
                                </Tab>
                            <Tab eventKey="leaves" title="Leaves Configurations">
                                <Card className="card-tab">
                                    <div className="card-header-tab"></div>
                                    <Card.Body>
                                        <Form.Group as={Col} sm={12}>
                                            <fieldset className="border p-2" sm={6}>
                                            <Form.Row>
                                                <Form.Group as={Col} sm={6} controlId="formHorizontalEmail">
                                                    <Typeahead
                                                            ref="leaveRef"
                                                            labelKey='name'
                                                            id="basic-example"
                                                            onChange={this.handleChangeLeaveType}
                                                            options={this.state.leaveTypesList}
                                                            placeholder="Select Leave Type"
                                                        />
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} sm={6} controlId="formHorizontalEmail">
                                                    <Form.Control 
                                                        type="text"
                                                        ref="maxLeaves"
                                                        autoComplete="off" 
                                                        name="maxLeaves"
                                                        value={this.state.maxLeaves}
                                                        onChange={this.handleChangeMaxLeave.bind(this)}
                                                        onKeyPress={this.IsNumeric.bind(this)}
                                                        maxLength="3"
                                                        placeholder="Maximum no. of leave(s)"
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Col sm="12">
                                                    <Form.Check 
                                                        inline
                                                        type="radio" 
                                                        label="DATE HIRED" 
                                                        name="datehired"
                                                        value={this.state.isDateHired}
                                                        checked={this.state.isDateHired}
                                                        onChange={this.handleChangeDateHired}
                                                        value={this.state.isDateHired}
                                                    />
                                                    <Form.Check 
                                                        inline
                                                        type="radio" 
                                                        label="1ST OF THE YEAR" 
                                                        name="firstofyear"
                                                        value={!this.state.isDateHired}
                                                        checked={!this.state.isDateHired}
                                                        onChange={this.handleChangeDateHired}
                                                        value={!this.state.isDateHired}
                                                        
                                                    />
                                                </Col>
                                                <Col md="auto">
                                                    
                                                </Col>
                                            </Form.Row>
                                            </fieldset>
                                            <Form.Row>
                                                    <Form.Group as={Col} sm={12}>
                                                        <ButtonToolbar className="mt-2">
                                                            <Button style={{minWidth:'60px'}} variant="success" onClick={this.handleAddLeave}>Add</Button>
                                                        </ButtonToolbar>
                                                    </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} sm={12}>
                                                    <Card.Header>Leaves Configuration List</Card.Header>
                                                    <BootstrapTable
                                                        keyField = "id"
                                                        data = { this.state.clientLeavesList }
                                                        columns = { leaveColumns }
                                                        selectRow = { selectRowLeaveConfig }
                                                        cellEdit = { cellEditFactory({ 
                                                                mode: 'dbclick', 
                                                                blurToSave: true,
                                                                afterSaveCell: (oldValue, newValue, row, column) => { 
                                                                    //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                }
                                                            })
                                                        }
                                                        striped
                                                        hover
                                                        condensed
                                                        loading={true}
                                                        rowClasses="noser-table-row-class"
                                                        wrapperClasses="table-responsive"
                                                        rowClasses="noser-table-row-class"
                                                        pagination={ paginationFactory({sizePerPage:15,hideSizePerPage:true,hidePageListOnlyOnePage:true})}
                                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                        /> 
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </Tab>
                            <Tab eventKey="payrollmainte" title="Payroll Periods / Cutoff">
                          
                                <PayrollPeriods/>
                            
                            </Tab>
                            <Tab eventKey="PeriodType" title="Period Types">
                          
                                <PeriodType/>
                            
                            </Tab>
                            <Tab eventKey="WorkSchedDefaut" title="Work Sched">
                          
                                <WorkSchedDefaut/>
                            
                            </Tab>
                           
                            </Tabs>
                        </Form>
                        <ButtonToolbar className="mt-2" style={{marginLeft:"-8px"}}>
                            <Button variant="success" style={{minWidth:'60px'}} onClick={this.onSubmitSaveClientConfig}>Save</Button>&nbsp;&nbsp;
                            <Button variant="danger"  style={{minWidth:'60px'}} href="/banner" >Close</Button>
                        </ButtonToolbar>
                    </Card.Body>
                </Card>
            </Container>
                <NoserLoading show={this.state.isloading} />

        </div>
        )
    }

}

export  default ClientConfig
