import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker,
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, FormControl} from 'react-bootstrap';


import { Input } from 'reactstrap';
 
class DoleCases extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            
            batchNoAutocomplete                 :   [],
            
            
            modalCoCReferenceShow               :   false,

            category                :"",

            isLoading               :"",

           
            selectedEmployeeId      :[],
            selectedEmployeeNo      :[],
            selectedEmployeeBranch  :[],
            selectedPosition        :[],

            getClientList           :[],
            getEmployeeList         :[],
            getEmployeeNoList       :[],
            position                :[],
            location                :[],

           
            values                  :[],
            
            dateTo             :"",
            dateFrom           :"",
            year               :"",

            selectedClientName      :"",
            selectedEmployeeName    :[],
            selectedEmployeeNo      :[],

            groundOfSeperation              :"",
            caseNumeber                     :"",
            dateFiled                       :"",
            dateReceived                    :"",
            complaint                       :"",
            scheduleOfHearing               :"",
            venue                           :"",
            hearingOfficer                  :"",
            
            caseNoNLRC                      :"",
            mandatoryDateFiled              :"",
            mandatoryDateReceived           :"",
            mandatoryComplaint              :"",
            mandatoryScheduleOfHearing      :"",
            mandatoryVenue                  :"",
            mandatoryLaborArbiter           :"",
            mandatoryDateReceived           :"",

            particularsMomStConfMeeting     :"",
            particularsMomNdConfMeeting     :"",
            particularsQuitclaim            :"",
            particularsMonetaryClaim        :"",

            positionPaper                   :"",
            reply                           :"",
            rejoinder                       :"",
            decision                        :"",
            counsel                         :"",
            latestUpdate                    :"",
            statusDate                      :"",
            remarks                         :"",
            updateStatus                    :"",
            AttachedDoc                     :"",
            reportedByEmployee              :"",
            reportedByClient                :"",
            reportedByPosition              :"",

            particularList: [
                {
                    "id"    : "Pending",
                    "name"  : "Pending"
                },
                {
                    "id"    : "Closed",
                    "name"  : "Closed"
                }
               
            ],
            selectedparticular      :   '',
            selectedparticularId    :   '',


        }
        /* this.handleSubmit = this.handleSubmit.bind(this); */
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})

        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;
    }
    handleChangeDateTo = date => {
        ////console.log(date)
        this.setState({
            dateTo: date,
            isshow:false,
        })
        console.log(this.state.dateTo)
    }
    handleChangeDateFrom = date => {
        ////console.log(date)
        this.setState({
            dateFrom: date,
            isshow:false,
        })
        console.log(this.state.dateFrom)
    }

    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }
    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        console.log("Client selectedClientId " + this.state.selectedClientId );
        console.log("Client selectedClientName " + this.state.selectedClientName );
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        
        this.getEmployees();
    }
    getEmployees(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data
            if(data.status=="1")
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            else
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
        
    }

    onChangeEmployeesList = (e) => {       

        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.selectedPosition = e[0].position
       
        console.log("Work selectedEmployeeName   " + this.state.selectedEmployeeNo );
        console.log("Employee List start ");
        console.log(this.state.selectedEmployeeBranch);
        console.log("Employee List end");

        this.setState({
            position:this.state.selectedPosition,
            location:this.state.selectedEmployeeBranch

        })
        this.setState({isloading:false})
    }
    onChangeEmployeesNo(e){
        this.setState({selectedEmployeeNo : e.target.value})
        //console.log(e.target.value)
    }
    onChangePosition(e){
        this.setState({position : e.target.value})
        //console.log(e.target.value)
    }
    onChangeLocation(e){
        this.setState({location : e.target.value})
        //console.log(e.target.value)
    }  

    handleSaveClick=()=>{
        
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');
        
        let data = {
            "client"                        :   this.state.selectedClientName,
            "employeeName"                  :   this.state.selectedEmployeeName,
            "position"                      :   this.state.selectedPosition,
            "employeeNo"                    :   this.state.selectedEmployeeNo,
            "location"                      :   this.state.selectedEmployeeBranch,
            "groundOfSeperation"            :   this.state.groundOfSeperation,
            "caseNumeber"                   :   this.state.caseNumeber,
            "dateFiled"                     :   this.state.dateFiled,
            "dateReceived"                  :   this.state.dateReceived,
            "complaint"                     :   this.state.complaint,
            "scheduleOfHearing"             :   this.state.scheduleOfHearing,
            "venue"                         :   this.state.venue,
            "hearingOfficer"                :   this.state.hearingOfficer,
            "caseNoNLRC"                    :   this.state.caseNoNLRC,
            "mandatoryDateFiled"            :   this.state.mandatoryDateFiled,
            "mandatoryDateReceived"         :   this.state.mandatoryDateReceived,
            "mandatoryComplaint"            :   this.state.mandatoryComplaint,
            "mandatoryScheduleOfHearing"    :   this.state.mandatoryScheduleOfHearing,
            "mandatoryVenue"                :   this.state.mandatoryVenue,
            "mandatoryLaborArbiter"         :   this.state.mandatoryLaborArbiter,            
            "particularsMomStConfMeeting"   :   this.state.particularsMomStConfMeeting,
            "particularsMomNdConfMeeting"   :   this.state.particularsMomNdConfMeeting,
            "particularsQuitclaim"          :   this.state.particularsQuitclaim,
            "particularsMonetaryClaim"      :   this.state.particularsMonetaryClaim,
            "positionPaper"                 :   this.state.positionPaper,
            "reply"                         :   this.state.reply,
            "rejoinder"                     :   this.state.rejoinder,
            "decision"                      :   this.state.decision,
            "counsel"                       :   this.state.counsel,
            "latestUpdate"                  :   this.state.latestUpdate,
            "statusDate"                    :   this.state.statusDate,
            "remarks"                       :   this.state.remarks,
            "updateStatus"                  :   this.state.selectedparticular,
            "attachedDoc"                   :   this.state.AttachedDoc,
            "reportedByEmployee"            :   this.state.reportedByEmployee,
            "reportedByPosition"            :   this.state.reportedByPosition,
            "reportedByClient"              :   this.state.reportedByClient,
           /*  "totalStatus"                   :   "", */
            "isDraft"                       :   "0",
            "isDeleted"                     :   "0",
            "createdby"                     :   "user007",
            "createddate"                   :   createddate,
            "modifiedby"                    :   'user007',
            "modifieddate"                  :   '0',
            "isModified"                    :   '0',    
        }
        console.log(data)
        
       
        const addParams = {
          "_collection" : "TestDoleCases",
          "doc_data"    : data
        }
          
        axios
        .post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(res.data)
            this.setState({
                selectedClientName:"",
                selectedEmployeeName:"",
                selectedPosition:"",
                selectedEmployeeNo:"",
                selectedEmployeeBranch:"",
                groundOfSeperation:"",
                caseNumeber:"",
                dateFiled:"",
                dateReceived:"",
                complaint:"",
                scheduleOfHearing:"",
                venue:"",
                hearingOfficer:"",
                caseNoNLRC:"",
                mandatoryDateFiled:"",
               
                mandatoryComplaint:"",
                mandatoryScheduleOfHearing:"",
                mandatoryVenue:"",
                mandatoryLaborArbiter:"",
                mandatoryDateReceived:"",
                particularsMomStConfMeeting:"",
                particularsMomNdConfMeeting:"",
                particularsQuitclaim:"",
                particularsMonetaryClaim:"",
                positionPaper:"",
                reply:"",
                rejoinder:"",
                decision:"",
                counsel:"",
                latestUpdate:"",
                statusDate:"",
                remarks:"",
                updateStatus:"",
                reportedByEmployee:"",
                reportedByClient:"",
                reportedByPosition:"",
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Data had successfully Save" ,
                fade            :   true
            
            })
        
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    
    handleOnChangeGroundOfSeperation=(e)=>{
        this.setState({
            groundOfSeperation   : e.target.value
        })
        console.log(this.state.groundOfSeperation)
    }
    handleOnChangeCaseNumeber=(e)=>{
        this.setState({
            caseNumeber   : e.target.value
        })
        console.log(this.state.caseNumeber)
    }
    handleOnChangeDateFiled=(e)=>{
        this.setState({
            dateFiled   : e.target.value
        })
        console.log(this.state.dateFiled)
    }
    handleOnChangedateReceived=(e)=>{
        this.setState({
            dateReceived   : e.target.value
        })
        console.log(this.state.dateReceived)
    }
    handleOnChangecomplaint=(e)=>{
        this.setState({
            complaint   : e.target.value
        })
        console.log(this.state.complaint)
    }
    handleOnChangescheduleOfHearing=(e)=>{
        this.setState({
            scheduleOfHearing   : e.target.value
        })
        console.log(this.state.scheduleOfHearing)
    }
    handleOnChangevenue=(e)=>{
        this.setState({
            venue   : e.target.value
        })
        console.log(this.state.venue)
    }
    handleOnChangehearingOfficer=(e)=>{
        this.setState({
            hearingOfficer   : e.target.value
        })
        console.log(this.state.hearingOfficer)
    }
    handleOnChangecaseNoNLRC=(e)=>{
        this.setState({
            caseNoNLRC   : e.target.value
        })
        console.log(this.state.caseNoNLRC)
    }
    handleOnChangemandatoryDateFiled=(e)=>{
        this.setState({
            mandatoryDateFiled   : e.target.value
        })
        console.log(this.state.mandatoryDateFiled)
    }
    handleOnChangemandatoryDateReceived=(e)=>{
        this.setState({
            mandatoryDateReceived   : e.target.value
        })
        console.log(this.state.mandatoryDateReceived)
    }
    handleOnChangemandatoryComplaint=(e)=>{
        this.setState({
            mandatoryComplaint   : e.target.value
        })
        console.log(this.state.mandatoryComplaint)
    }
    handleOnChangemandatoryScheduleOfHearing=(e)=>{
        this.setState({
            mandatoryScheduleOfHearing   : e.target.value
        })
        console.log(this.state.mandatoryScheduleOfHearing)
    }
    handleOnChangemandatoryVenue=(e)=>{
        this.setState({
            mandatoryVenue   : e.target.value
        })
        console.log(this.state.mandatoryVenue)
    }
    handleOnChangemandatoryLaborArbiter=(e)=>{
        this.setState({
            mandatoryLaborArbiter   : e.target.value
        })
        console.log(this.state.mandatoryLaborArbiter)
    }   
    handleOnChangeparticularsMomStConfMeeting=(e)=>{
        this.setState({
            particularsMomStConfMeeting   : e.target.value
        })
        console.log(this.state.particularsMomStConfMeeting)
    }
    handleOnChangeparticularsMomNdConfMeeting=(e)=>{
        this.setState({
            particularsMomNdConfMeeting   : e.target.value
        })
        console.log(this.state.particularsMomNdConfMeeting)
    }
    handleOnChangeparticularsQuitclaim=(e)=>{
        this.setState({
            particularsQuitclaim   : e.target.value
        })
        console.log(this.state.particularsQuitclaim)
    }
    handleOnChangeparticularsMonetaryClaim=(e)=>{
        this.setState({
            particularsMonetaryClaim   : e.target.value
        })
        console.log(this.state.particularsMonetaryClaim)
    }
    handleOnChangepositionPaper=(e)=>{
        this.setState({
            positionPaper   : e.target.value
        })
        console.log(this.state.positionPaper)
    }
    handleOnChangereply=(e)=>{
        this.setState({
            reply   : e.target.value
        })
        console.log(this.state.reply)
    }
    handleOnChangerejoinder=(e)=>{
        this.setState({
            rejoinder   : e.target.value
        })
        console.log(this.state.rejoinder)
    }
    handleOnChangedecision=(e)=>{
        this.setState({
            decision   : e.target.value
        })
        console.log(this.state.decision)
    }
    handleOnChangecounsel=(e)=>{
        this.setState({
            counsel   : e.target.value
        })
        console.log(this.state.counsel)
    }
    handleOnChangelatestUpdate=(e)=>{
        this.setState({
            latestUpdate   : e.target.value
        })
        console.log(this.state.latestUpdate)
    }
    handleOnChangestatusDate=(e)=>{
        this.setState({
            statusDate   : e.target.value
        })
        console.log(this.state.statusDate)
    }
    handleOnChangeremarks=(e)=>{
        this.setState({
            remarks   : e.target.value
        })
        console.log(this.state.remarks)
    }
    handleOnChangeupdateStatus=(e)=>{
        this.setState({
            updateStatus   : e.target.value
        })
        console.log(this.state.updateStatus)
    }
    handleOnChangeAttachedDoc=(e)=>{
        let fileTmp = e.target.value
        this.setState({
            AttachedDoc   : fileTmp
        })
        console.log(this.state.AttachedDoc)
    }
    onChangeReportedByClient=(e)=>{

        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.reportedByClient = e[0].name
        this.setState({
            isloading:true,
            isshow:false,
            color:"",
            message:"",
            fade:true ,
        })
        
        this.getEmployees();

    }
    onChangeReportedEmployee=(e)=>{

        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.reportedByEmployee = e[0].employeeName
        this.state.reportedByPosition = e[0].position

        this.setState({isloading:false})
    }
    onChangeparticular = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedparticular     :   '',
                selectedparticularId   :   ''
            })
            return
        }
        this.state.selectedparticular = e[0].name
        this.state.selectedparticularId = e[0].id
       
        this.setState({
            isshow   :   false,
           selectedparticular : e[0].id

        })
       
    }


    render() {        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>DOLE CASES</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Card.Header className="mt-3">EMPLOYEE INFORMATION</Card.Header>
                                    {/* <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.getClientList}
                                                placeholder="Select Client"
                                            />
                                        </Col>
                                    </Form.Group> */}
                                    <Form.Group as={Row} className="mt-4" controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee
                                        </Form.Label>
                                        <Col sm="7">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.onChangeEmployeesList}
                                                options={this.state.getEmployeeList}
                                                placeholder="Select Employee"
                                                autocomplete="false"
                                            />
                                        </Col>

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee No.
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeTraining}
                                                options={this.state.trainingAutocomplete}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName" 
                                                value={this.state.selectedPosition}
                                                onChange={this.onChangePosition} 
                                                autoComplete="off"
                                                placeholder="Autofill"
                                            />
                                        </Col>
                                        <Col sm="2"></Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Location
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.selectedEmployeeBranch}
                                                onChange={this.onChangeLocation} 
                                                autoComplete="off"
                                                placeholder="Auto Fill"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Ground Of Seperation
                                        </Form.Label>
                                        <Col sm="4" /* style={{marginLeft : "-1px"}} */>
                                            <Input
                                                labelKey='year'
                                                id="basic-example"
                                                value={this.state.groundOfSeperation}
                                                className="form-control"
                                                onChange={this.handleOnChangeGroundOfSeperation}
                                                placeholder="Enter Ground Of Seperation"
                                                autocomplete="off"
                                                
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Card.Header className="mt-3">SINGLE ENTRY NOTICE APPROACH (SENA)</Card.Header>
                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Case Numeber
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.caseNumeber}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeCaseNumeber}
                                                    //placeholder="Enter Case Numeber"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Date Filed
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.dateFiled}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeDateFiled}
                                                    //placeholder="Enter Date Filed"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Date Received
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.dateReceived}
                                                    className="form-control"
                                                    onChange={this.handleOnChangedateReceived}
                                                    //placeholder="Enter Date Received"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Complaint
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='Complaint'
                                                    id="basic-example"
                                                    value={this.state.complaint}
                                                    className="form-control"
                                                    onChange={this.handleOnChangecomplaint}
                                                    //placeholder="Enter Complaint"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Schedule Of Hearing
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.scheduleOfHearing}
                                                    className="form-control"
                                                    onChange={this.handleOnChangescheduleOfHearing}
                                                    //placeholder="Enter Schedule Of Hearing"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Venue
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.venue}
                                                    className="form-control"
                                                    onChange={this.handleOnChangevenue}
                                                    //placeholder="Enter Venue"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Hearing Officer
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.hearingOfficer}
                                                    className="form-control"
                                                    onChange={this.handleOnChangehearingOfficer}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>

                                    </div>

                                    <Card.Header className="mt-3">MANDATORY CONFERENCE</Card.Header>

                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                NLRC Case No
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.caseNoNLRC}
                                                    className="form-control"
                                                    onChange={this.handleOnChangecaseNoNLRC}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Date Filed
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.mandatoryDateFiled}
                                                    className="form-control"
                                                    onChange={this.handleOnChangemandatoryDateFiled}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Date Received
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.mandatoryDateReceived}
                                                    className="form-control"
                                                    onChange={this.handleOnChangemandatoryDateReceived}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Complaint
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.mandatoryComplaint}
                                                    className="form-control"
                                                    onChange={this.handleOnChangemandatoryComplaint}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Schedule Of Hearing
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.mandatoryScheduleOfHearing}
                                                    className="form-control"
                                                    onChange={this.handleOnChangemandatoryScheduleOfHearing}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Venue
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.mandatoryVenue}
                                                    className="form-control"
                                                    onChange={this.handleOnChangemandatoryVenue}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Labor Arbiter
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.mandatoryLaborArbiter}
                                                    className="form-control"
                                                    onChange={this.handleOnChangemandatoryLaborArbiter}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                    </div>

                                    <Card.Header className="mt-3">PARTICULARS</Card.Header>

                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Mom St Conf Meeting
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.particularsMomStConfMeeting}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeparticularsMomStConfMeeting}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Mom Nd Conf Meeting
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.particularsMomNdConfMeeting}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeparticularsMomNdConfMeeting}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Quitclaim
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.particularsQuitclaim}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeparticularsQuitclaim}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Monetary Claim
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.particularsMonetaryClaim}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeparticularsMonetaryClaim}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>

                                    </div>

                                    <Card.Header className="mt-3">SCHEDULE OF SUBMISSION</Card.Header>

                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Position Paper
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.positionPaper}
                                                    className="form-control"
                                                    onChange={this.handleOnChangepositionPaper}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Reply
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.reply}
                                                    className="form-control"
                                                    onChange={this.handleOnChangereply}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Rejoinder
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.rejoinder}
                                                    className="form-control"
                                                    onChange={this.handleOnChangerejoinder}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        
                                    </div>

                                    <Card.Header className="mt-3">UPDATE AND STATUS</Card.Header>

                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Decision
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.decision}
                                                    className="form-control"
                                                    onChange={this.handleOnChangedecision}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Counsel
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.counsel}
                                                    className="form-control"
                                                    onChange={this.handleOnChangecounsel}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Latest Update
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.latestUpdate}
                                                    className="form-control"
                                                    onChange={this.handleOnChangelatestUpdate}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                DATE
                                            </Form.Label>
                                            <Col sm="2" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.statusDate}
                                                    className="form-control"
                                                    onChange={this.handleOnChangestatusDate}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                REMARKS
                                            </Form.Label>
                                            <Col sm="4" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.remarks}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeremarks}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <ButtonToolbar sm={2}>
                                                {/* <Col className="mr-auto">
                                                    <ButtonToolbar className="mt-1">                                                   
                                                        <Button className="mr-auto" variant="success" onClick={this.handleToExportNTE} href="/ExportNTE" style={{minHeight:"18px",minWidth:'60px', marginLeft:"129px", marginTop:"-1px"}}>ADD</Button>                                                   
                                                    </ButtonToolbar>                                          
                                                </Col>   */}                                                                    
                                            </ButtonToolbar>
                                        </Form.Group>

                                        { <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Status
                                            </Form.Label>
                                            <Col sm="11">
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeparticular}
                                                    options={this.state.particularList}
                                                    //placeholder="select penalty type"
                                                />
                                            </Col>
                                        </Form.Group>}
                                        
                                    </div>
                                    <Card.Header className="mt-5">REPORTED BY </Card.Header>

                                    {/* <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeReportedByClient}
                                                options={this.state.getClientList}
                                                placeholder="Select Client"
                                            />
                                        </Col>
                                    </Form.Group> */}

                                    <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.onChangeReportedEmployee}
                                                options={this.state.getEmployeeList}
                                                placeholder="Select Employee"
                                                autocomplete="false"
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position 
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                type="text"
                                                name="reportedByPosition"
                                                value={this.state.reportedByPosition}
                                                onChange={this.onChangeReportedPosition} 
                                                autoComplete="off"
                                                placeholder="Auto Fill"
                                            />
                                        </Col>
                                      
                                    </Form.Group>

                                    <Card.Header className="mt-3">DOCUMENTS ATTACHED</Card.Header>

                                    <div className="mt-3">
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Col sm="4" >                                                 
                                                <Input type="file" className="file" placeholder="attached file" variant="primary"  onChange={this.handleOnChangeAttachedDoc}/>                                          
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">

                                          {/*   <ButtonToolbar sm={2}>
                                                <Col className="mr-auto">
                                                    <ButtonToolbar className="mt-5">                                                   
                                                        <Button className="mr-auto" variant="success" onClick={this.handleToExportNTE} href="/ExportNTE" style={{minHeight:"18px",minWidth:'60px',}}>Add</Button>                                                   
                                                    </ButtonToolbar>                                          
                                                </Col>                                                                      
                                            </ButtonToolbar> */}
                                        
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formPlaintextEmail" className="mt-4">
                                            
                                            <ButtonToolbar sm={12}>
                                                <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleSaveClick}>
                                                    Save
                                                </Button>&nbsp;&nbsp;
                                                <NavLink to="/home">
                                                    <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                        Close
                                                    </Button>
                                                </NavLink>
                                            </ButtonToolbar>
                                            
                                        </Form.Group>
                                    </div>

                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                   
            </div> 
        )
    }

}

export  default DoleCases
