import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker,
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, FormControl} from 'react-bootstrap';

import { FormGroup, Input } from 'reactstrap';
 
class ApplicationFormMainScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employeeName : ""
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("NOSERUSRlogInApplicant"))
    }

    componentDidMount(){
        this.setState({
            employeeName : this.state.userinfo.applicantName,
        })
    };

    render() {        
    return(
            <div>
                {/* <Banner /> */}
                <Container className="mt-5" fluid>
                    <div  style={{border:"solid" , backgroundColor:"#ffff" , borderWidth:1,/* borderTopRightRadius:4,borderTopLeftRadius:4, */ borderRadius:5}}>
                        <header title="Folder" style={{backgroundColor:"rgb(8, 139, 30)", width:"flex", border:"solid", borderWidth:1, height:"30px",}}>
                            <label style={{fontSize:"17px", fontWeight : "bold", marginLeft:"5px"}}> Noser Technology PH - HRIS </label>
                        </header>
                        {/* <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div>
                            {this.state.message}
                        </Alert> */}
                        <Row className='mt-5'>
                            <Col sm="6">
                                <Card style={{background : "#ffff",borderTopRightRadius:4,borderTopLeftRadius:4, marginLeft : "13%", width:"85%", height : "90%"}}>
                                    <div style={{ height : "450px"}}>
                                        <Card.Body >
                                            <Form>
                                                <Form.Group className="mt-4" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                        Company/Details
                                                    </Form.Label>
                                                    
                                                </Form.Group>
                                            </Form>
                                        </Card.Body>
                                    </div>
                                </Card>
                            </Col>
                            <Col sm="6" >
                                <Card style={{background : "#ffff",borderTopRightRadius:4,borderTopLeftRadius:4, width:"85%", height : "90%"}}>
                                    <div style={{ height : "450px"}}>
                                        <Card.Body>
                                            <Form.Group /* className="mt-4" */ as={Row} controlId="formPlaintextEmail">
                                                <Col  sm="12">
                                                    <Form.Label style={{fontWeight : "bolder", fontSize:"25px"}}>
                                                        WELCOME!
                                                    </Form.Label>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group className="mt-3" as={Row} controlId="formPlaintextEmail">
                                                <Col sm="12">
                                                    <Form.Label style={{fontWeight : "bolder", fontSize:"25px"}}>
                                                    HI,&nbsp;&nbsp; {this.state.employeeName}
                                                    </Form.Label>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                                                <Col sm="12">
                                                    <Form.Label style={{fontWeight : "bold", fontSize:"20"}}>
                                                        . please click the link below Application form to apply for a job
                                                    </Form.Label>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                                                <Col  sm="2">
                                                </Col>
                                                <Col sm="8">
                                                    <NavLink /* to="/applicationform" */ to="/ApplicationFormCreate">
                                                        <Form.Label>
                                                        <u style={{fontSize:'25px', fontWeight : "bolder", color : "fffff"}}> Application form </u>
                                                        </Form.Label>
                                                    </NavLink>
                                                </Col>
                                                <Col  sm="2">
                                                </Col>
                                            </Form.Group>
                                            <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                                                <Col sm="12">
                                                    <Form.Label style={{fontWeight : "bold", fontSize:"20"}}>
                                                        . thank you,
                                                    </Form.Label>
                                                </Col>
                                            </Form.Group>
                                        </Card.Body>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <Form.Group as={Row} style ={{marginTop : "-2%", }}>
                            <Col  sm="8"></Col>
                            <Col  sm="4">
                                <Form.Label style={{fontWeight : "bolder",  marginLeft : "35%"}}>
                                    Noser HRIS © 2019 - 2020. All Rights Reserved
                                </Form.Label>
                            </Col>
                        </Form.Group>
                    </div>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }
}

export  default ApplicationFormMainScreen
