import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

class ApplicationPoolModal extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            disableBtn  :   true,

            title       :   "",
            poolDDL     :   [
                {id:"2",name:"TALENT POOL"},
                {id:"3",name:"CANDIDATE POOL"}
            ],
            poolstatusid:   ""
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            
            title       :   e.title,
            pooldata    :   e.data,
            poolDDL     :   [
                {id:"2",name:"TALENT POOL"},
                {id:"3",name:"CANDIDATE POOL"}
            ],
            poolstatusid:   ""
        })
        console.log(e.data)
    }
    handleChangedDDL = (e) =>{
        if(e.length == 0) {
            this.setState({isshow:false,poolstatusid:"",disableBtn:true})
            return
        }
        this.setState({isshow:false,poolstatusid:e[0].id,disableBtn:false})
    }
    handleSubmitPool = async()=>{
        this.setState({isloading:true})

        let paramdata = []
        this.state.pooldata.forEach(itm => {
            paramdata.push({
                "TypeId":"0",
                "PoolingId":itm.poolingId,
                "StatusId":this.state.poolstatusid
            })
        });
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "updatePoolings":paramdata
        };
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Pooling/SubmitPooling",  params)
            .then(res => {
            const data = res.data;
            
            this.setState({
                isloading:false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                disableBtn  :   res.data.status=="1" ? true : false,
            });
            
            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton className="card-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.state.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <hr />
                        <Form.Group as={Row}>
                            <Col sm="12">
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="Preliminary Test"
                                    value="0"
                                    name="pretest" 
                                    checked={this.state.isExcessDeduction}
                                    onChange={this.OnChangeIsExcessDeduction}
                                    style={{fontWeight : "bold"}}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm="1"></Col>
                            <Form.Label disabled column sm="1" style={{fontWeight : "bold"}}>
                                DATE
                            </Form.Label>
                            <Col sm={8}>
                                <DatePicker
                                    id="dateApplied"
                                    name="dateApplied"
                                    //value={this.state.dateApplied}
                                    //selected={this.state.dateofBirth}
                                    //onChange={this.handleChangedDate('dateApplied')}
                                    //minDate={this.minDate}
                                    dateFormat={"MM/dd/yyyy"}
                                    className="form-control"
                                    autoComplete="off"
                                    style={{width:"120px"}}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm="1"></Col>
                            <Form.Label disabled column sm="1" style={{fontWeight : "bold"}}>
                                TIME
                            </Form.Label>
                            <Col sm={8}>
                                <TimePicker 
                                showSecond={false}
                                //defaultValue={moment()}
                                className="xxx"
                                onChange={this.onChangeFrom}
                                disabled
                                />
                            </Col>
                        </Form.Group>
                        <hr />
                        <Form.Group as={Row}>
                            <Col sm="12">
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="Application Pooling"
                                    value="1"
                                    name="pretest" 
                                    checked={true}
                                    //onChange={this.OnChangeIsExcessDeduction}
                                    style={{fontWeight : "bold"}}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm="1"></Col>
                            <Form.Label column sm="3" style={{marginTop:"-7px",fontWeight : "bold"}}>
                                SELECT NEXT POOL
                            </Form.Label>
                            <Col sm={6}>
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangedDDL}
                                    options={this.state.poolDDL}
                                    placeholder="Select Next Status"
                                />
                            </Col>
                        </Form.Group>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = {this.handleSubmitPool} disabled={this.state.disableBtn}>Submit</Button>
                        <Button variant="danger" className="noser-button-mr15" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default ApplicationPoolModal