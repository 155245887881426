import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker,
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, FormControl} from 'react-bootstrap';


import { Input } from 'reactstrap';
 
class DoleCompliance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            
            batchNoAutocomplete                 :   [],
            
            
            modalCoCReferenceShow               :   false,

            category                :"",

            isLoading               :"",

           
            selectedEmployeeId      :[],
            selectedEmployeeNo      :[],
            selectedEmployeeBranch  :[],
            selectedPosition        :[],

            getClientList           :[],
            getEmployeeList         :[],
            getEmployeeNoList       :[],
       
            values                  :[],
            
            dateTo                  :"",
            dateFrom                :"",
            year                    :"",

            selectedClientName      :"",
            selectedEmployeeName    :[],
            selectedEmployeeNo      :[],

            outlet                      :"",
            caseNo                      :"",
            MembersInvolved             :"",
            refNo                       :"",
            dateOfAssessment            :"",
            inspector                   :"",
            findings                    :"",
            dateOfConference            :"",
            submittedDoc                :"",
            docDateNOI                  :"",            
            dateReceived                :"",
            hearingOfficer              :"",
            mandatoryFindings           :"",
            mandatoryDateOfConference   :"",
            minutesOfMeeting            :"",
            mandatorySubmittedDoc       :"",
            mandatoryDocDate            :"",         
            decision                    :"",
            counsel                     :"",
            latestUpdate                :"",
            statusDate                  :"",
            remarks                     :"",
            updateStatus                :"",
            documentAttached            :"",

            particularList: [
                {
                    "id" : "For Resolution",
                    "name" : "For Resolution"
                },
                {
                    "id" : "Pending",
                    "name" : "Pending"
                },
                {
                    "id" : "For Appeal",
                    "name" : "For Appeal"
                },
                {
                    "id" : "Closed",
                    "name" : "Closed"
                }
                
            ],
 	        selectedparticular      :   '',
            selectedparticularId    :   '',

        }
        /* this.handleSubmit = this.handleSubmit.bind(this); */
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})

        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;
    }
    handleChangeDateTo = date => {
        ////console.log(date)
        this.setState({
            dateTo: date,
            isshow:false,
        })
        console.log(this.state.dateTo)
    }
    handleChangeDateFrom = date => {
        ////console.log(date)
        this.setState({
            dateFrom: date,
            isshow:false,
        })
        console.log(this.state.dateFrom)
    }

    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }
    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        console.log("Client selectedClientId " + this.state.selectedClientId );
        console.log("Client selectedClientName " + this.state.selectedClientName );
        this.setState({isloading:false,isshow:false,color:"",message:"",fade:true})
        
       /*  this.getEmployees(); */
    }
    getEmployees(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data
            if(data.status=="1")
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            else
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
        
    }

    onChangeEmployeesList = (e) => {       

        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.selectedPosition = e[0].position
       
        console.log("Work selectedEmployeeName   " + this.state.selectedEmployeeNo );
        console.log("Employee List start ");
        console.log(this.state.selectedEmployeeBranch);
        console.log("Employee List end");

      /*   if(this.state.selectedPayrollpayPeriod == ""){
            this.state.selectedPayrollPeriodsId = e[0].payPeriodId
            this.state.selectedPayrollpayPeriod = e[0].payPeriod
        } */

        //this.employeeTransactionsList();


        this.setState({
            position:this.state.selectedPosition,
            location:this.state.selectedEmployeeBranch

        })
        this.setState({isloading:false})
    }
    onChangeEmployeesNo(e){
        this.setState({selectedEmployeeNo : e.target.value})
        //console.log(e.target.value)
    }
       

    handleSaveClick=()=>{
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');
        let data = {
            "client"                        :   this.state.selectedClientName,
            "location"                      :   this.state.outlet,
            "caseNo"                        :   this.state.caseNo,
            "MembersInvolved"               :   this.state.MembersInvolved,
            "refNo"                         :   this.state.refNo,
            "dateOfAssessment"              :   this.state.dateOfAssessment,
            "inspector"                     :   this.state.inspector,
            "findings"                      :   this.state.findings,
            "dateOfConference"              :   this.state.dateOfConference,
            "submittedDoc"                  :   this.state.submittedDoc,
            "docDateNOI"                    :   this.state.docDateNOI,
            "dateReceived"                  :   this.state.dateReceived,
            "hearingOfficer"                :   this.state.hearingOfficer,
            "mandatoryFindings"             :   this.state.mandatoryFindings,
            "mandatoryDateOfConference"     :   this.state.mandatoryDateOfConference,
            "minutesOfMeeting"              :   this.state.minutesOfMeeting,
            "mandatorySubmittedDoc"         :   this.state.mandatorySubmittedDoc,
            "mandatoryDocDate"              :   this.state.mandatoryDocDate,
            "decision"                      :   this.state.decision,
            "counsel"                       :   this.state.counsel,
            "latestUpdate"                  :   this.state.latestUpdate,
            "statusDate"                    :   this.state.statusDate,
            "remarks"                       :   this.state.remarks,
            "updateStatus"                  :   this.state.selectedparticular,
            "documentAttached"              :   this.state.documentAttached,
            "isDraft"                       :   "0",
            "isDeleted"                     :   "0",
            "createdby"                     :   "user007",
            "createddate"                   :   createddate,
            "modifiedby"                    :   'user007',
            "modifieddate"                  :   '0',
            "isModified"                    :   '0',    
        }
        console.log(data)               
        
        const addParams = {
          "_collection" : "TestDoleCompliance",
          "doc_data"    : data
        }
          
        axios
        .post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(res.data)
            this.setState({
                selectedClientName:"",
                outlet:"",
                caseNo:"",
                MembersInvolved:"",
                refNo:"",
                dateOfAssessment:"",
                inspector:"",
                findings:"",
                dateOfConference:"",
                submittedDoc:"",
                docDateNOI:"",
                dateReceived:"",
                hearingOfficer:"",
                mandatoryFindings:"",
                mandatoryDateOfConference:"",
                minutesOfMeeting:"",
                mandatorySubmittedDoc:"",
                mandatoryDocDate:"",
                decision:"",
                counsel:"",
                latestUpdate:"",
                statusDate:"",
                remarks:"",
                updateStatus:"",
                documentAttached:"",
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Data had successfully Save" ,
                fade            :   true
            
            })
          
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleOnChangeoutlet=(e)=>{
      
        this.setState({
            outlet   : e.target.value
        })
        console.log(this.state.outlet)
    }    
    handleOnChangecaseNo=(e)=>{
        this.setState({
            caseNo   : e.target.value
        })
        console.log(this.state.caseNo)
    }
    handleOnChangeMembersInvolved=(e)=>{
        this.setState({
            MembersInvolved   : e.target.value
        })
        console.log(this.state.MembersInvolved)
    }
    handleOnChangerefNo=(e)=>{
        this.setState({
            refNo   : e.target.value
        })
        console.log(this.state.refNo)
    }
    handleOnChangedateOfAssessment=(e)=>{
        this.setState({
            dateOfAssessment   : e.target.value
        })
        console.log(this.state.dateOfAssessment)
    }
    handleOnChangeinspector=(e)=>{
        this.setState({
            inspector   : e.target.value
        })
        console.log(this.state.inspector)
    }
    handleOnChangefindings=(e)=>{
        this.setState({
            findings   : e.target.value
        })
        console.log(this.state.findings)
    }
    handleOnChangedateOfConference=(e)=>{
        this.setState({
            dateOfConference   : e.target.value
        })
        console.log(this.state.dateOfConference)
    }
    handleOnChangehearingOfficer=(e)=>{
        this.setState({
            hearingOfficer   : e.target.value
        })
        console.log(this.state.hearingOfficer)
    }
    handleOnChangesubmittedDoc=(e)=>{
        this.setState({
            submittedDoc   : e.target.value
        })
        console.log(this.state.submittedDoc)
    }
    handleOnChangedocDateNOI=(e)=>{
        this.setState({
            docDateNOI   : e.target.value
        })
        console.log(this.state.docDateNOI)
    }
    handleOnChangedateReceived=(e)=>{
        this.setState({
            dateReceived   : e.target.value
        })
        console.log(this.state.dateReceived)
    }
    handleOnChangehearingOfficer=(e)=>{
        this.setState({
            hearingOfficer   : e.target.value
        })
        console.log(this.state.hearingOfficer)
    }
    handleOnChangemandatoryFindings=(e)=>{
        this.setState({
            mandatoryFindings   : e.target.value
        })
        console.log(this.state.mandatoryFindings)
    }
    handleOnChangemandatoryDateOfConference=(e)=>{
        this.setState({
            mandatoryDateOfConference   : e.target.value
        })
        console.log(this.state.mandatoryDateOfConference)
    }
    handleOnChangeminutesOfMeeting=(e)=>{
        this.setState({
            minutesOfMeeting   : e.target.value
        })
        console.log(this.state.minutesOfMeeting)
    }
    handleOnChangemandatorySubmittedDoc=(e)=>{
        this.setState({
            mandatorySubmittedDoc   : e.target.value
        })
        console.log(this.state.mandatorySubmittedDoc)
    }
    handleOnChangemandatoryDocDate=(e)=>{
        this.setState({
            mandatoryDocDate   : e.target.value
        })
        console.log(this.state.mandatoryDocDate)
    }
    handleOnChangedecision=(e)=>{
        this.setState({
            decision   : e.target.value
        })
        console.log(this.state.decision)
    }
    handleOnChangecounsel=(e)=>{
        this.setState({
            counsel   : e.target.value
        })
        console.log(this.state.counsel)
    }
    handleOnChangelatestUpdate=(e)=>{
        this.setState({
            latestUpdate   : e.target.value
        })
        console.log(this.state.latestUpdate)
    }
    handleOnChangestatusDate=(e)=>{
        this.setState({
            statusDate   : e.target.value
        })
        console.log(this.state.statusDate)
    }
    handleOnChangeremarks=(e)=>{
        this.setState({
            remarks   : e.target.value
        })
        console.log(this.state.remarks)
    }
    handleOnChangeupdateStatus=(e)=>{
        this.setState({
            updateStatus   : e.target.value
        })
        console.log(this.state.updateStatus)
    }  
    handleOnChangeAttachedDoc=(e)=>{
        let fileTmp = e.target.value
        this.setState({
            documentAttached   : fileTmp
        })
        console.log(this.state.documentAttached)
    }
    onChangeparticular = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedparticular     :   '',
                selectedparticularId   :   ''
            })
            return
        }
        this.state.selectedparticular = e[0].name
        this.state.selectedparticularId = e[0].id
       
        this.setState({
            isshow   :   false,
           selectedparticular : e[0].id

        })
       
    }
   
    render() {        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>DOLE COMPLIANCE</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Card.Header className="mt-3">PRICIPAL / CLIENT INVOLVED</Card.Header>
                                    {/* <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.getClientList}
                                                placeholder="Select Client"
                                            />
                                        </Col>
                                    </Form.Group> */}
                                    <Form.Group as={Row} className="mt-4" controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Outlet
                                        </Form.Label>
                                        <Col sm="11">
                                            <Input
                                                type="text"
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleOnChangeoutlet}
                                                value={this.state.outlet}
                                                autoComplete="off"
                                            />
                                        </Col>                 
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Case No.
                                        </Form.Label>
                                        <Col sm="5">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName" 
                                                value={this.state.caseNo}
                                                onChange={this.handleOnChangecaseNo} 
                                                autoComplete="off"
                                               
                                            />
                                        </Col>
                                        <Col sm="2"></Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            No. Of Members Involved
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.MembersInvolved}
                                                onChange={this.handleOnChangeMembersInvolved} 
                                                autoComplete="off"
                                                placeholder="Auto Fill"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Ref No.
                                        </Form.Label>
                                        <Col sm="4" /* style={{marginLeft : "-1px"}} */>
                                            <Input
                                                labelKey='year'
                                                id="basic-example"
                                                value={this.state.refNo}
                                                className="form-control"
                                                onChange={this.handleOnChangerefNo}
                                                //placeholder="Enter Ground Of Seperation"
                                                autocomplete="off"
                                                
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Card.Header className="mt-3">NOTICE OF INSPECTION</Card.Header>
                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                DATE OF ASSESSMENT
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.dateOfAssessment}
                                                    className="form-control"
                                                    onChange={this.handleOnChangedateOfAssessment}
                                                    //placeholder="Enter Case Numeber"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                               INSPECTOR
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.inspector}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeinspector}
                                                    //placeholder="Enter Date Filed"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                FINDINGS
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.findings}
                                                    className="form-control"
                                                    onChange={this.handleOnChangefindings}
                                                    //placeholder="Enter Date Received"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                DATE OF CONFERENCE
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='Complaint'
                                                    id="basic-example"
                                                    value={this.state.dateOfConference}
                                                    className="form-control"
                                                    onChange={this.handleOnChangedateOfConference}
                                                    //placeholder="Enter Complaint"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                SUBMITTED DOCUMENTS
                                            </Form.Label>
                                            <Col sm="9" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.submittedDoc}
                                                    className="form-control"
                                                    onChange={this.handleOnChangesubmittedDoc}
                                                    //placeholder="Enter Schedule Of Hearing"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                            <Col sm="1" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='dATE'
                                                    id="basic-example"
                                                    value={this.state.docDateNOI}
                                                    className="form-control"
                                                    onChange={this.handleOnChangedocDateNOI}
                                                    placeholder="Enter Date"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>                                       

                                    </div>

                                    <Card.Header className="mt-3">MANDATORY CONFERENCE</Card.Header>

                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                DATE RECEIVED
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.dateReceived}
                                                    className="form-control"
                                                    onChange={this.handleOnChangedateReceived}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                HEARING OFFICER
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.hearingOfficer}
                                                    className="form-control"
                                                    onChange={this.handleOnChangehearingOfficer}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                FINDINGS
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.mandatoryFindings}
                                                    className="form-control"
                                                    onChange={this.handleOnChangemandatoryFindings}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                DATE OF CONFERENCE
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.mandatoryDateOfConference}
                                                    className="form-control"
                                                    onChange={this.handleOnChangemandatoryDateOfConference}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                MINUTES OF THE MEETING
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.minutesOfMeeting}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeminutesOfMeeting}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                SUBMITTED DOCUMENTS
                                            </Form.Label>
                                            <Col sm="9" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.mandatorySubmittedDoc}
                                                    className="form-control"
                                                    onChange={this.handleOnChangemandatorySubmittedDoc}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                            <Col sm="1" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.mandatoryDocDate}
                                                    className="form-control"
                                                    onChange={this.handleOnChangemandatoryDocDate}
                                                    placeholder="Enter DATE"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        
                                    </div>

                                    <Card.Header className="mt-3">UPDATE AND STATUS</Card.Header>

                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Decision
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.decision}
                                                    className="form-control"
                                                    onChange={this.handleOnChangedecision}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Counsel
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.counsel}
                                                    className="form-control"
                                                    onChange={this.handleOnChangecounsel}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Latest Update
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.latestUpdate}
                                                    className="form-control"
                                                    onChange={this.handleOnChangelatestUpdate}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                DATE
                                            </Form.Label>
                                            <Col sm="2" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.statusDate}
                                                    className="form-control"
                                                    onChange={this.handleOnChangestatusDate}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                REMARKS
                                            </Form.Label>
                                            <Col sm="4" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.remarks}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeremarks}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                        {/* <ButtonToolbar sm={2}>
                                            <Col className="mr-auto">
                                                <ButtonToolbar className="mt-1">                                                   
                                                    <Button className="mr-auto" variant="success" onClick={this.handleToExportNTE} href="/ExportNTE" style={{minHeight:"18px",minWidth:'60px', marginLeft:"129px", marginTop:"-1px"}}>ADD</Button>                                                   
                                                </ButtonToolbar>                                          
                                            </Col>                                                                      
                                        </ButtonToolbar> */}
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Status
                                            </Form.Label>
                                            <Col sm="11">
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeparticular}
                                                    options={this.state.particularList}
                                                    //placeholder="select penalty type"
                                                />
                                            </Col>
                                        </Form.Group>
                                       

                                    </div>

                                    <Card.Header className="mt-3">DOCUMENTS ATTACHED</Card.Header>

                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Col sm="4">                                                 
                                                <Input type="file" className="file" placeholder="attached file" variant="primary"  onChange={this.handleOnChangeAttachedDoc}/>                                          
                                            </Col>                                           
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                           {/*  <ButtonToolbar sm={2}>
                                                <Col className="mr-auto">
                                                    <ButtonToolbar className="mt-5">                                                   
                                                        <Button className="mr-auto" variant="success" onClick={this.handleToExportNTE} href="/ExportNTE" style={{minHeight:"18px",minWidth:'60px',}}>Add</Button>                                                   
                                                    </ButtonToolbar>                                          
                                                </Col>                                                                      
                                            </ButtonToolbar>    */}                                     
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formPlaintextEmail" className="mt-4">
                                            
                                            <ButtonToolbar sm={12}>
                                                <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleSaveClick}>
                                                    Save
                                                </Button>&nbsp;&nbsp;
                                                <NavLink to="/home">
                                                    <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                        Close
                                                    </Button>
                                                </NavLink>
                                            </ButtonToolbar>
                                            
                                        </Form.Group>
                                    </div>

                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                   
            </div> 
        )
    }

}

export  default DoleCompliance
