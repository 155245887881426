import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer,Modal,
} 
from '../../noser-hris-component';


class DepartmentSectionCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo    :   [],
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            
            code        :   '',
            description :   '',
            departmentSelected  :   "",
            departmentCodeAutocomplete:[],
            departmentSelectedId:"",
        }
    }
    

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetDepartment();

    }

    GetDepartment() {
        const departmentCodeParams = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "",
            "Name"          :   ""
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDepartments",  departmentCodeParams)
        .then(res => {
            const data = res.data;
            console.log("Get Departments Code");
            console.log(data);
            this.setState({
                departmentCodeAutocomplete  :   data.departments
            });
         })
    }

    onChangeSectionCode = (e) => {
        this.setState({
            code : e.target.value
        })
    }

    onChangeSectionDescription = (e) => {
        this.setState({
            description : e.target.value
        })
    }

    handleSaveClick = event => {
        this.setState({isloading:true})

        const areaParams = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"     :   this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId,
            "DepartmentId"    :   this.state.departmentSelectedId,
            "Code"          :   this.state.code,
            "Name"          :   this.state.description,
         };

        console.log("Submit Params")
        console.log(areaParams)

         axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Maintenance/AddSection",  areaParams
             )
             .then(res => {
                 const data = res.data;
                console.log("Submit AddSection")
                console.log(data)
                 if(data.status=="1"){
                this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true
                    });
                
                }
                else {
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
            })

    }

    handleEventDepartmentCode = (e) => {
        console.log(e)
            if (e.length > 0) {
            this.state.departmentSelected = e[0].code
            this.state.departmentSelectedId = e[0].id
        } else {
            this.state.departmentSelected = ""
            this.state.departmentSelectedId = e[0].code
        }
        
    }

    

    render() {


        return(

        <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className="modal-90w"
                >
                <Modal.Header closeButton className="card-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                    Section - Create
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>

                           
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <Typeahead
                                        labelKey='code'
                                        id="basic-example"
                                        onChange={this.handleEventDepartmentCode}
                                        options={this.state.departmentCodeAutocomplete}
                                        placeholder="Select Department Code"
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <Form.Control 
                                        type="text" 
                                        placeholder="Enter Section Code" 
                                        ref="code"
                                        autoComplete="off" 
                                        name="code"
                                        value={this.state.code}
                                        onChange={this.onChangeSectionCode}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <Form.Control 
                                        type="text" 
                                        placeholder="Enter Section Description" 
                                        ref="description"
                                        autoComplete="off" 
                                        name="description"
                                        value={this.state.description}
                                        onChange={this.onChangeSectionDescription}
                                    />
                                </Col>
                            </Form.Group>
                                
                            <ButtonToolbar>
                                <Button className="ml-auto" variant="success" onClick = { this.handleSaveClick } style={{minWidth:'60px', marginRight:"5px"}}>Save</Button>
                                <Button  href="/DepartmentSection" variant="danger" onClick={ this.handleCloseClick } style={{minWidth:'60px'}}>Back</Button>
                            </ButtonToolbar>
                        </Form>
                    </Container>
                </Modal.Body>
               
                <NoserLoading show={this.state.isloading} />
            </Modal>
           
        )
    }

}

export  default DepartmentSectionCreate