import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../noser-hris-component';

class TrainingEvaluationAndRecommendationModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            isDisableComplete : true,
            isDisableNot : true,
            isPreTestPassed : true,
            isPreTestFailed : false,
            isPostTestPassed : true,
            isPostTestFailed : false,
            postTestResult : "",
            preTestResult : "",
            recommendationRemarks : "",
            
            //trainingEvaluationRD : JSON.parse(sessionStorage.getItem("trainingEvaluationDataRow")),
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.trainingEvaluationRD = JSON.parse(sessionStorage.getItem("trainingEvaluationDataRow"))
        this.props.onRefModal(this)
        this.setState({
            //clientId : this.state.trainingEvaluationRD.clientId
        })
       
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            removeBtn   :   true,

            trainingEvaluationRD    :   e,
            clientId                :   e.clientId,
            trainingId              :   e.trainingId,
            referenceId             :   e.id,
            profileId               :   e.profileId,
            employeeName            :   e.employeeName,
            employeeNo              :   e.employeeNo,
            position                :   e.position,
            location                :   e.location,
            client                  :   e.client,
            training                :   e.training,
            dateAttended            :   e.dateAttended,
            batchNo                 :   e.batchNo,
            validity                :   e.validity,
            recommendationDDL : [
                {"id" : "2", "name" : "Re-Training"},
                {"id" : "4", "name" : "Re-Orientation"},
                {"id" : "7", "name" : "Exam Retake"},
                {"id" : "8", "name" : "For Check-back"},
                {"id" : "9", "name" : "Completed"},
            ],
        })
        console.log(e)
        
    }



    GetEmployeeEvaluations = async() => { 
        //this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.trainingEvaluationRD.clientId,
            "TrainingId"    :   this.state.trainingEvaluationRD.trainingId,
            "ProfileId"     :   this.state.trainingEvaluationRD.profileId,
            "ReferenceId"   :  this.state.trainingEvaluationRD.id,
        };
        console.log("param")
        console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetEmployeeEvaluationDetails",  param)
        .then(res => {
            const data = res.data;
            console.log("GetEmployeeEvaluationDetails for submitting")
            console.log(data)
            //this.setState({recommendationDG:data.evaluations,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    onChangeRecommendation= (e) => {
        ////////////console.log(e)
        if(e.length == 0) {
            this.state.recommendationId     =   ""
            this.state.recommendation   =   ""
            return
        }
        this.state.recommendationId     =   e[0].id
        this.state.recommendation   =   e[0].name
        this.setState({
            isshow:false,
        })

        /* if(this.state.recommendationId === "7" || this.state.recommendationId === "8") {
            this.setState({
                isDisableComplete : false,
                isDisableNot : false,
            })
        } else {
            this.setState({
                isDisableComplete : true,
                isDisableNot : true,
            })
        } */

        if(this.state.recommendationId === "8" || this.state.recommendationId === "9") {
            this.setState({
                isDisableComplete : false,
                isDisableNot : true,
            })
        }

        if(this.state.recommendationId === "2" || this.state.recommendationId === "4" || this.state.recommendationId === "7") {
            this.setState({
                isDisableNot : false,
                isDisableComplete : true,
            })
        }

        if( this.state.isPostTestFailed === true && this.state.recommendationId === "9") {
            this.setState({
                isDisableNot : true,
                isDisableComplete : true,
            })
        }

        if( this.state.isPostTestFailed === true && this.state.recommendationId === "8") {
            this.setState({
                isDisableNot : false,
                isDisableComplete : true,
            })
        }
             
        ////////////console.log(this.state.selectedClient);
       
    }

    onChangeRecommendationRemarks = (e) => {
        this.setState({
            recommendationRemarks : e.target.value
        })
    }

    onChangePreTestResult = (e) => {
        /* const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({preTestResult: e.target.value})
        } */
        this.setState({preTestResult: e.target.value})
    }

    onChangePostTestResult = (e) => {
        /* const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({postTestResult: e.target.value})
        } */
        this.setState({postTestResult: e.target.value})
    }

    handleTrainingEvaluationModalClose = () => {
        this.props.onHide();            
    }

    handleChangePreTestPassed = (e) =>{
        this.state.isPreTestPassed=e.target.checked
        if(this.state.isPreTestPassed===true){
            this.setState({
                isPreTestFailed:false
            })
        }
        
    }

    handleChangePreTestFailed = (e) =>{
        this.state.isPreTestFailed=e.target.checked
        if(this.state.isPreTestFailed===true){
            this.setState({
                isPreTestPassed:false
            })
        }
        
    }

    handleChangePostTestPassed = (e) =>{
        this.state.isPostTestPassed=e.target.checked
        if(this.state.isPostTestPassed===true){
            this.setState({
                isPostTestFailed:false,
                isDisableNot : true,
                isDisableComplete : false,
            })
        }
        
    }

    handleChangePostTestFailed = (e) =>{
        this.state.isPostTestFailed=e.target.checked
        if(this.state.isPostTestFailed===true){
            this.setState({
                isPostTestPassed:false,
            })
            //this.state.recommendationDDL.filter(x=>x.id!=="10")
        }

        if( this.state.isPostTestFailed === true && this.state.recommendationId === "9") {
            this.setState({
                isDisableNot : true,
                isDisableComplete : true,
            })
        }
        
    }

    onClickIsPassed = async(isPassed) =>{
        
        const params = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.clientId,
            "ReferenceId"       :   this.state.referenceId,
            "ProfileId"         :   this.state.profileId, 
            "TrainingId"        :   this.state.trainingId,
            "RequestTypeId"     :   this.state.recommendationId,
            "PreTestResult"     :   this.state.preTestResult,
            "PreTestPassed"     :   (this.state.isPreTestPassed) ? "1" : "0",
            "PostTestResult"    :   this.state.postTestResult,
            "PostTestPassed"    :   (this.state.isPostTestPassed) ? "1" : "0",
            "Remarks"           :   this.state.recommendationRemarks,
            "IsPassed"          :   isPassed
        };
        console.log("params")
        console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/AddTrainingEvaluation",  params)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
                this.refs.recommendationRef.clear()
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true,
                    
                    preTestResult           : "",
                    postTestResult          : "",
                    isPreTestPassed         : true,
                    isPreTestFailed         : false,
                    isPreTestPassed         : true,
                    isPostTestFailed        : false,
                    isDisableNot            : true,
                    isDisableComplete       : true,
                    recommendationRemarks   : "",
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    render() {
        
        return(
            <>
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        TRAINING >> L and D >> EMPLOYEE TRAINING EVALUATION
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                EMPLOYEE NAME
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                :
                            </Form.Label>
                            <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.employeeName}
                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            EMPLOYEE No.
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                :
                            </Form.Label>
                            <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.employeeNo}
                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            POSITION 
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                :
                            </Form.Label>
                            <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.position}
                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            CLIENT 
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                :
                            </Form.Label>
                            <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.client}
                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            BRANCH 
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                :
                            </Form.Label>
                            <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.location}
                            </Form.Label>
                        </Form.Group>
                        <hr/>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            TRAINING NAME  
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                :
                            </Form.Label>
                            <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.training}
                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            BATCH NUMBER 
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                :
                            </Form.Label>
                            <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.batchNo}
                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            DATE ATTENDED 
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                :
                            </Form.Label>
                            <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.dateAttended}
                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            VALID UNTIL 
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                :
                            </Form.Label>
                            <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.validity}
                            </Form.Label>
                        </Form.Group>
                        <hr/>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold", fontSize : "13px"}}>
                            PRE - TEST 
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                
                            </Form.Label>
                            <Col sm="4"></Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold", fontSize : "13px"}}>
                            POST - TEST 
                            </Form.Label>
                            {/* <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.location}
                            </Form.Label> */}
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label className="ml-auto" column sm="2" style={{fontWeight : "bold"}}>
                            SCORE 
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    ref="preTestResult"
                                    name="preTestResult"
                                    value={this.state.preTestResult}
                                    onChange={this.onChangePreTestResult}
                                    autoComplete="off"
                                    maxLength="3"
                                />
                            </Col>
                            <Col sm="2"></Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            SCORE 
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    ref="postTestResult"
                                    name="postTestResult"
                                    value={this.state.postTestResult}
                                    onChange={this.onChangePostTestResult}
                                    autoComplete="off"
                                    maxLength="3"
                                />
                            </Col>
                            {/* <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.location}
                            </Form.Label> */}
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Col sm="2">
                            </Col>
                            <Col sm="3">
                                <Form.Check 
                                    inline
                                    type="checkbox"  
                                    label="PASSED" 
                                    name="passed"
                                    value={this.state.isPreTestPassed}
                                    checked={this.state.isPreTestPassed}
                                    onChange={this.handleChangePreTestPassed}
                                    style={{fontWeight : "bold",marginLeft:"12px"}}
                                />
                                <Form.Check 
                                    inline
                                    type="checkbox" 
                                    label="FAILED" 
                                    name="failed"
                                    value={this.state.isPreTestFailed}
                                    checked={this.state.isPreTestFailed}
                                    onChange={this.handleChangePreTestFailed}
                                    style={{fontWeight : "bold",marginLeft:"12px"}}
                                />
                            </Col>
                            <Col sm="4">
                            </Col>
                            <Col sm="3">
                                <Form.Check 
                                    inline
                                    type="checkbox"
                                    label="PASSED" 
                                    name="passed"
                                    value={this.state.isPostTestPassed}
                                    checked={this.state.isPostTestPassed}
                                    onChange={this.handleChangePostTestPassed}
                                    style={{fontWeight : "bold",marginLeft:"12px"}}
                                />
                                <Form.Check 
                                    inline
                                    type="checkbox"
                                    label="FAILED" 
                                    name="failed"
                                    value={this.state.isPostTestFailed}
                                    checked={this.state.isPostTestFailed}
                                    onChange={this.handleChangePostTestFailed}
                                    style={{fontWeight : "bold",marginLeft:"12px"}}
                                    
                                />
                                
                            </Col>
                        </Form.Group>
                        
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                RECOMMENDATION
                            </Form.Label>
                            <Col sm="10">
                                <Typeahead
                                    ref="recommendationRef"
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.onChangeRecommendation}
                                    options={this.state.recommendationDDL}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                REMARKS
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    type="text"
                                    name="recommendationRemarks"
                                    value={this.state.recommendationRemarks}
                                    onChange={this.onChangeRecommendationRemarks} 
                                    autoComplete="off"
                                />
                            </Col>
                        </Form.Group>
                        <hr/>
                        <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                        <Col sm="2">
                            <Button href="/" variant="success" >Generate Certificate</Button>
                        </Col>
                        <Col sm="2">
                        </Col>
                        <Col sm="8">
                        <ButtonToolbar>
                            <Button disabled={this.state.isDisableComplete} className="ml-auto noser-button-mr1" variant="info" onClick={() => this.onClickIsPassed('1')}>COMPLETED</Button>&nbsp;&nbsp;&nbsp;
                            <Button disabled={this.state.isDisableNot} variant="danger noser-button-mr1" onClick={() => this.onClickIsPassed('0')}>NOT</Button>&nbsp;&nbsp;&nbsp;
                            <Button onClick={this.props.onHide} variant="danger noser-button-mr15">CLOSE</Button>
                        </ButtonToolbar>
                        </Col>
                    </Form.Group>
                    </Container>
                </Modal.Body>
                {/* <Modal.Footer>
                        <ButtonToolbar>
                            <Button variant="info" onClick = { this.handleClickCompleted }>COMPLETED</Button>&nbsp;&nbsp;&nbsp;
                            <Button variant="danger" onClick = { this.handleClickNot }>NOT</Button>&nbsp;&nbsp;&nbsp;
                            <Button  onClick={this.props.onHide} variant="danger">CLOSE</Button>
                        </ButtonToolbar>
                </Modal.Footer> */}
                <NoserLoading show={this.state.isloading} />
                
        </Modal>
        </>
        );
    }
}
export default TrainingEvaluationAndRecommendationModal