import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { Input  } from 'reactstrap';
import { DropdownButton, Dropdown} from 'react-bootstrap';
 
class WorkSchedDefaut extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            restDayData   :[],
            //payrollPeriodData:[],
            selectedperiod:"",
            selectedperiodID:"",
            selectedModeName:"",
            modalShow: false,
            dateStart:"",
            dateEnd:"",
            DayName:"",
            RestDayData : [],
            selectedRemoved:[],

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
       // this.GetResday()
        this.getWorkSchedule();
        //this.GetEmployeePayModesList()
    }

    getWorkSchedule(){
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":"0",
            "UserId":this.state.userinfo.userId,
            "Location": "0",
        }

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "TimeKeeping/GetWorkingSchedules", getParams)
            .then(res => {
                console.log("work sched")
                console.log(res)
                let newStorage = res.data.workSchedules
               /*   */

                newStorage.map(function(d){
                    d.isDeleted = "0"
                    if(d.isDefault == "0"){
                        d.defaultTag = "No"
                    }else{
                        d.defaultTag = "Yes"
                    }
                })

                this.setState({
                    restDayData :newStorage
                })

                
            })
          
    }

  /*   GetResday(){
        const getParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId" : this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
	        "DayName":"",
            "isDeleted":"0"
        }

        console.log("getParams GetResday")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "CompanyConfig/GetCompanyRestDays", getParams)
        .then(res => {
            const data = res.data
            console.log("GetResday")
            console.log(data)



            this.setState({
                restDayData: data.companyRestDays
            })
            
            
        })
    } */

    GridDataModified(oldValue, newValue, id, column) {
        ////console.log(id)
        this.state.trainingScheduleTableList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    onChangePeriod = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedperiodID     :   '',
            })
            return
        } 
        //this.state.selectedperiodID = e[0].id 
        this.state.DayName = e[0].name    
               
        this.setState({
            //selectedperiodID   : e[0].id,
            DayName   : e[0].name 

        })
       
    }

    handleModalSHOW = (e) =>{
    
        this.setState({modalShow: true})
        
    }
    
    handleModalClose = (e) =>{
        
        this.setState({modalShow: false})
        this.GetPayrollPeriods()
    }

    handleChangedateStart =date=>{
        this.setState({
            dateStart: date
        })
    }

    handleChangedateEnd =date=>{
        this.setState({
            dateEnd: date
        })
    }

    onChangeperiodName=(e)=>{
        if(e.length==0)
        {
            this.setState({
                periodName     :   '',
            })
            return
        }
        this.state.periodName = e[0].periodName     
               
        this.setState({
            periodName   : e[0].periodName

        })
    }

    handleUpdate = (e) => {

        let delWorkScheduleList = []
        this.setState({isloading:true})
        for (let i = 0; i < this.state.restDayData.length; i++) {
            if (this.state.restDayData[i]["isDefault"] == 1) {
                let obj = this.state.restDayData[i]
                delWorkScheduleList.push(obj);
            } 
        }

        console.log(delWorkScheduleList)
        
        const param = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "WorkSchedules": this.state.restDayData,
        }
        console.log("param")
        console.log(param)

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "TimeKeeping/EditWorkSchedule", param)
                .then(res => {
                const data = res.data;
                    this.setState({
                        isloading:false,
                        isshow:true,
                        color:(data.status=="1") ? "success" : "danger",
                        message:data.message,
                        fade:true
                    }); 
                  
                })
                .catch(error=>{
                this.setState(
                { 
                    isloading:false,
                    alerttype:"Error! ", 
                    show:true,
                    color:"danger",
                    message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade:true
                }) 
            })    
        
    }

    render() {

        const columnBreaktime = [
            {
                dataField: 'breaktime',
                text: 'Breaktime',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'40%'}},
                style:{textAlign:'left'}
            },
            {
                dataField: 'duration',
                text: 'Duration',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'30%'}},
                style:{textAlign:'center'}
            },
            {
                dataField: 'startTime',
                text: 'Start Time',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'30%'}},
                style:{textAlign:'center'}
            },
            {
                dataField: 'endTime',
                text: 'End Time',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'30%'}},
                style:{textAlign:'left'}
            },
        ]

        const columnWorkSched = [
            {
                dataField: 'description',
                text: 'Template Name',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%' }},
                style:{textAlign:'left'}
            },
            {
                dataField: 'startTime',
                text: 'Time-In',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'center'}
            },
            {
                dataField: 'endTime',
                text: 'Time-Out',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'72%' }},
                style:{textAlign:'left'}
            },
            {
                dataField: 'defaultTag',
                text: 'Default',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'8%' }},
                style:{textAlign:'left'}
            },
           
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
                let newData = []
                this.state.restDayData.map(function(item,i){
                    item.isDefault = "0"
                    if(item.id===row.id)
                    {
                       
                        item.isDeleted = "0"
                        //item.isModified = isSelect ? "1" : "0"
                        item.isDefault = isSelect ? "1" : "0"
                        
                    }

                   
                   /*  if(){
                        newData
                    } */

                })
            }
        };
     
        const expandRow = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "id"
                    data = { row.workScheduleDetails }
                    columns = { columnBreaktime }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    expandRow
                    wrapperClasses="table-responsive"
                    rowClasses="noser-table-row-class"
                    noDataIndication={ () => <div>No breaktime details.</div> }
                /> 
            ),
            showExpandColumn: true,
            
          };

       

    return(
            <div>
                  
                        <Card>
                            <Card.Header></Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>

                                 {/*    <Form.Group as={Row} controlId="formPlaintextEmail">
                                      
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangePeriod}
                                                options={this.state.RestDayData}
                                                placeholder='select Day'
                                            />
                                        </Col>
                                    </Form.Group> */}

                                    {/* <ButtonToolbar sm={12}>
                                        <Button variant="primary" className="ml-auto" style={{minWidth:'60px', marginBottom:"5px"}} onClick={this.searchGetResday}>
                                            Search
                                        </Button>&nbsp;&nbsp;
                                        <Button variant="success" style={{minWidth:'60px', marginBottom:"5px"}} onClick={this.handleModalSHOW}>
                                            Create
                                        </Button> 
                                       
                                    </ButtonToolbar>*/}

                                   {/*  <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.restDayData }
                                        columns = { restDayData }
                                        //selectRow = { selectRowLeaveConfig }
                                        cellEdit = { cellEditFactory({ 
                                                mode: 'dbclick', 
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => { 
                                                    //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        striped
                                        hover
                                        condensed
                                        loading={true}
                                        rowClasses="noser-table-row-class"
                                        wrapperClasses="table-responsive"
                                        pagination={ paginationFactory({sizePerPage:15,hideSizePerPage:true,hidePageListOnlyOnePage:true})}
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        selectRow = { selectRowPayrollConfig }
                                    />  */}

                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.restDayData }
                                        columns = { columnWorkSched }
                                        selectRow = { selectRow }
                                        cellEdit = { cellEditFactory({ 
                                                mode: 'dbclick', 
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => { 
                                                    this.GridDataModified(oldValue, newValue, row.rowIndex, column.dataField)
                                                }
                                            })
                                        }
                                        //rowEvents={ rowEvents }
                                        rowClasses="noser-table-row-class"
                                        striped
                                        hover
                                        condensed
                                        expandRow={ expandRow }
                                        wrapperClasses="table-responsive"
                                        rowClasses="noser-table-row-class"
                                        pagination={ paginationFactory({sizePerPage:10,hideSizePerPage:true,hidePageListOnlyOnePage:true})}
                                        noDataIndication={ () => <div>No record found.</div> }
                                    />
                                   
                                    <ButtonToolbar sm={12} >
                                       
                                       
                                        <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleUpdate}>
                                            Default work sched
                                        </Button>
                                      
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>

                   
            </div> 
            
        )
    }

}

export  default WorkSchedDefaut
