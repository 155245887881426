import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

 
class CodeOfConduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            selectedClientName                  :   [],
            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            LateAbsentTableList                 :   [],
            getClientList                       :   [],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalTrainingRegisterShow           :   false,
            clientName                          :"",
            articleList                         :[],
            sectionList                         :[],
            articleId                           :[],
            cocList                         :""
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;   
        let cocEdit = "0"
        sessionStorage.setItem("cocEdit", JSON.stringify(cocEdit))

        //this.getCOCArticle();;  
        //this.getCOCSection();; 
       
    }

    GetDepartment() {
        const departmentCodeParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "",
            "Name"          :   ""
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDepartments",  departmentCodeParams)
        .then(res => {
            const data = res.data;
            console.log("Get Departments Code");
            console.log(data);
            console.log("Get Departments Code");
            this.setState({
                departmentCodeAutocomplete  :   data.departments
            });
        })

        console.log(this.state.departmentCodeAutocomplete)
    }

    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            console.log("Client List start ");
            console.log(res.data);
            console.log("Client List end");
            const data = res.data
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }

    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.clientName = e[0].name
        this.setState({
            isloading:true,
            isshow:false,
            color:"",
            message:"",
            fade:true ,
            clientName: e[0].name
        })       

        this.getCOCSection();      
    }

    showTrainingRegisterModal = (e) => {
        this.setState({modalTrainingRegisterShow: true})
    }
    handleModalClose = (e) =>{
        //reload or refresh data here
        //alert("Data pass from child : " + e)
        this.setState({modalTrainingRegisterShow: false})
    }


    GridDataModified(oldValue, newValue, id, column) {
        ////console.log(id)
        this.state.sectionList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    getCOCSection=()=>{

        let filter_data ={ 'clientName' : this.state.clientName, "isDeleted":"0" }
        const getParams ={
            "_collection" : "COCSection",
            "filter_data" : filter_data
        }

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            
            const itemUsersDataLists = this.buildListCOCSection(data["COCSection"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            console.log("data getCOCSection")
            console.log(data)

            if(data.COCSection.length > 0){
            
                let List =[]
                let tryList =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {

                    let obj = {
                        'id'                    :   this.state.itemUserDataList[i]['id'],
                        'clientName'            :   this.state.itemUserDataList[i]['clientName'],
                        'articleId'             :   this.state.itemUserDataList[i]['articleId'],
                        "articleNo"             :   this.state.itemUserDataList[i]['articleNo'],
                        "articleName"           :   this.state.itemUserDataList[i]['articleName'],
                        'sectionNo'             :   this.state.itemUserDataList[i]['sectionNo'],
                        'infraction'            :   this.state.itemUserDataList[i]['infraction'],
                        'category'              :   this.state.itemUserDataList[i]['category'],
                        'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'            :   this.state.itemUserDataList[i]['isModified'],
                        
                    }

                    if(this.state.itemUserDataList[i]['category'] !== ""){
                        console.log("data obj")
                        console.log(obj)
                    }
                    List.push(obj)
                    tryList.push(obj)

                    //let newList = List.sort((a, b) => a.articleNo & a.sectionNo   < a.articleNo & b.sectionNo  ? 1: -1)
                    //List.sort((a, b) => a.articleNo < b.articleNo ? 1: -1)
                    

                    this.setState({
                        sectionList:List,
                        isloading:false,
                    })
                  
                  /*   this.setState({
                        sectionList:List,
                        isloading:false,
                    }) */
                
                }

               
                //let tryList = List.sort(List.sectionNo)
               /*  tryList.sort((a,b) => {
                    return a.sectionNo > b.sectionNo;
                }); */
                
                
                console.log("data tryList")
                console.log(tryList)
            }else{
               
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "Please Create Client Section",
                    fade            :   true
                })
                
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
        })

    }
    
    buildListCOCSection(data) {
        //console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let clientNameList              =[]
        let articleIdList               =[]
        let articleNoList               =[]
        let articleNameList             =[]
        let sectionNoList               =[]
        let infractionList              =[]
        let categoryList                =[]        
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
    
        for (let i = 0; i < data.length; i++) {
    
            let s1 = data[i].split("':")

            let idClean                     = s1[1].replace("ObjectId(" ,"").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let clientNameTmp               = s1[2].split("',")[0]
            let articleIdTmp                = s1[3].split("',")[0]
            let articleNoTmp                = s1[4].split("',")[0]
            let articleNameTmp              = s1[5].split("',")[0]
            let sectionNoTmp                = s1[6].split("',")[0]
            let infractionTmp               = s1[7].split("',")[0]
            let categoryTmp                 = s1[8].split("',")[0]
            let isDraftListTmp              = s1[9].split("',")[0]
            let isDeletedListTmp            = s1[10].split("',")[0]
            let createdbyListTmp            = s1[11].split("',")[0]
            let createddateListTmp          = s1[12].split("',")[0]
            let modifiedbyListTmp           = s1[13].split("',")[0]
            let modifieddateListTmp         = s1[14].split("',")[0]
            let isModifiedListTmp           = s1[15].split("',")[0]
        
            idList.push(IdClean2.replace(" '",""))
            clientNameList.push(clientNameTmp.replace(" '",""))
            articleIdList.push(articleIdTmp.replace(" '",""))
            articleNoList.push(articleNoTmp.replace(" '",""))
            articleNameList.push(articleNameTmp.replace(" '",""))
            sectionNoList.push(sectionNoTmp.replace(" '",""))
            infractionList.push(infractionTmp.replace(" '",""))
            categoryList.push(categoryTmp.replace(" '",""))
            isDraftList.push(isDraftListTmp.replace(" '",""))
            isDeletedList.push(isDeletedListTmp.replace(" '",""))
            createdbyList.push(createdbyListTmp.replace(" '",""))
            createddateList.push(createddateListTmp.replace(" '",""))
            modifiedbyList.push(modifiedbyListTmp.replace(" '",""))
            modifieddateList.push(modifieddateListTmp.replace(" '",""))
            isModifiedList.push(isModifiedListTmp.replace(" '","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'clientName'            :   clientNameList[i],
                'articleId'             :   articleIdList[i],
                'articleNo'             :   articleNoList[i],
                'articleName'           :   articleNameList[i],
                'sectionNo'             :   sectionNoList[i],
                'infraction'            :   infractionList[i],
                'category'              :   categoryList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }          
        return itemList
      
    }

    toEdit=()=>{
        let qwerty = JSON.parse(sessionStorage.getItem("itemCOC")) 
        console.log(qwerty)
        window.location.href='/CodeOfConductCreateSection'
    }

    render() {


        
        const COCColumn = [           
            {
                dataField   : 'articleNo',
                text        : 'ARTICLE NO',
                headerStyle : () => {
                    return { width  : "20%" };
                },
                sort: true,
            },
            {
                dataField   : 'articleName',
                text        : 'ARTICLE TITLE',
                headerStyle : () => {
                    return { width  : "20%" };
                },
                //sort: true,
            },
            {
                dataField   : 'sectionNo',
                text        : 'SECTION',
                headerStyle : () => {
                    return { width  : "20%" };
                },
               //sort: true,
            },
            {
                dataField   : 'infraction',
                text        : 'INFRACTION',
                headerStyle : () => {
                    return { width  : "20%" };
                },
                //sort: true,
            },
            {
                dataField   : 'category',
                text        : 'CATEGORY',
                headerStyle : () => {
                    return { width  : "20%" };
                },
                //sort: true,
            },
            /* {
                dataField: 'training',
                text: 'Training',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'position',
                text        : 'Position',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'client',
                text        : 'Client',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'register',
                text        : 'Register',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'attended',
                text        : 'Attended',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]

        const defaultSorted = [
            {
                dataField: 'articleNo',
                order: 'asc'
            },
            {
                dataField: 'sectionNo',
                order: 'asc'
            }
        ];

        const selectRow = {
            mode: 'radio',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.sectionList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        sessionStorage.setItem("itemCOC", JSON.stringify(item))
                        let cocEdit = "1"
                        sessionStorage.setItem("cocEdit", JSON.stringify(cocEdit))
                    }
                    
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>COC MAINTENANCE </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    {/* <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.getClientList}
                                                placeholder="Select Client"
                                            />
                                        </Col>
                                    </Form.Group> */}
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                    
                                    </Form.Group>
                                    
                                    <ButtonToolbar className="mt-1">
                                        <NavLink to="/CodeOfConductCreateArticle"  className="ml-auto" >
                                            <Button variant="primary" href="/CodeOfConductCreateArticle" style={{minWidth:'60px'}}>
                                                Create Article
                                            </Button>&nbsp;&nbsp;
                                        </NavLink>
                                        <NavLink to="/CodeOfConductCreateSection">
                                            <Button variant="primary" href="/CodeOfConductCreateSection" style={{minWidth:'60px'}}>
                                                Create Section
                                            </Button>
                                        </NavLink>                                       
                                    </ButtonToolbar>

                                    <Card.Header className="mt-3">COC DETAILS</Card.Header>

                                    <div className="mt-1">
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.sectionList }
                                            columns = { COCColumn }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                            selectRow = { selectRow }
                                            defaultSorted={ defaultSorted } 
                                            

                                        />
                                    </div>
                                    <ButtonToolbar sm={12}>
                                        {/* <NavLink to="/CodeOfConductCreateSection" className="ml-auto"> */}
                                            <Button  variant="success"  onClick={this.toEdit} style={{minWidth:'60px'}} >
                                                Edit
                                            </Button>&nbsp;&nbsp;
                                        {/* </NavLink>  */}
                                        <NavLink to="/DeleteCOCScreen" >
                                            <Button variant="danger"  href="/DeleteCOCScreen" style={{minWidth:'60px'}}>
                                                Delete COC Screen
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

            </div> 
        )
    }

}

export  default CodeOfConduct
