import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

class CreatePagIbigTable extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            regionId    :   '',
            name        :   '',
            disableBtn  :   true,
            regionListDDL:  [],

            
            //CompanyId:"",
            Name:"",
            RangeFrom:"",
            RangeTo:"",
            EEShare:"",
            ERShare:"",
            EEShareLabel:"",
            ERShareLabel:"",
            EffectivityYear:"",
            CreatedBy:"",
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
       
    };

    GetHDMF() {
        const hdmfParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetHDMFBrackets",  hdmfParams)
        .then(res => {
            console.log("Get HDMF Brackets")
             const data = res.data;
             console.log(res.data)
             this.setState({ hdmfList: data.brackets });
         })
    };

    componentWillUnmount() {
        this.props.onRefModal(undefined)
    };

    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            regionId    :   '',
            regionName  :   '',
            provinceId  :   '',
            name        :   '',
            disableBtn  :   true,
            regionListDDL:  [],
            provinceListDDL:[]
        })
        console.log("test w test")
    };

   

    handleSubmit = (e) =>{
        console.log("save kuno")
        this.setState({isloading:true})

        let date = new Date()
        const currentYear = new Date().getFullYear()
        let Percentage = "1%"
        const param = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "ClientName":this.state.userinfo.companyName,
            "Name":this.state.MONTHLYCOMPENSATION,
            "RangeFrom":this.state.RangeFrom,
            "RangeTo":this.state.RangeTo,
            "EEShare": this.state.EEShare,
            "ERShare": this.state.ERShare,
            "EEShareLabel":"Employee Share",
            "ERShareLabel":"Employer Share",
            "EffectivityYear": currentYear.toString(),
            "CreatedBy": this.state.userinfo.userId,
            "isDeleted": "0",
        }

        console.log("param") 
        console.log(param)
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/AddHDMFBracket",  param
            )
            .then(res => {
                const data = res.data;
                console.log(data)
                this.GetHDMF()
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true,
                    name        :   '',
                    regionId    :   '',
                    
                });
                
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true,
                    name        :   ''
                })
            })
    };

    handleModalClose = () => {
        this.props.onHide();            
    }

    handleChange = (e) =>{
        console.log(e.target.value)
        this.setState({[e.target.name]: e.target.value})
    };
    
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        CREATE PHILHEALTH CONTRIBUTION 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='MONTHLYCOMPENSATION'
                                    type="text" 
                                    placeholder="Enter MONTHLY COMPENSATION" 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.MONTHLYCOMPENSATION}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='monthlyPremium'
                                    type="text" 
                                    placeholder="Enter MONTHLY PREMIUM" 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.monthlyPremium}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='RangeFrom'
                                    type="text" 
                                    placeholder="Enter Range From " 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.RangeFrom}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='RangeTo'
                                    type="text" 
                                    placeholder="Enter Range To  " 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.RangeTo}
                                />
                            </Form.Group>
                        </Form.Row>
                        {/* <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='EEShareLabel'
                                    type="text" 
                                    placeholder="Enter EE Share Label " 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.EEShareLabel}
                                />
                            </Form.Group>
                        </Form.Row> */}
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='EEShare'
                                    type="text" 
                                    placeholder="Enter EEShare  " 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.EEShare}
                                />
                            </Form.Group>
                        </Form.Row>
                        {/* <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='ERShareLabel'
                                    type="text" 
                                    placeholder="Enter ER Share Label " 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.ERShareLabel}
                                />
                            </Form.Group>
                        </Form.Row> */}
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='ERShare'
                                    type="text" 
                                    placeholder="Enter ERShare " 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.ERShare}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='EffectivityYear'
                                    type="text" 
                                    placeholder="Enter Effectivity Year  " 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.EffectivityYear}
                                />
                            </Form.Group>
                        </Form.Row>
                       {/*  <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='employerShare'
                                    type="text" 
                                    placeholder="Enter EMPLOYER SHARE" 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.employerShare}
                                />
                            </Form.Group>
                        </Form.Row> */}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleSubmit } style={{minWidth:'60px', marginRight:"5px"}}>Save</Button>
                        <Button variant="danger" className="noser-button-mr1" onClick={this.props.onHide} style={{minWidth:'60px', }}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default CreatePagIbigTable