import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { Input  } from 'reactstrap';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import  PayrollperiodModal from "../Maintenance/PayrollperiodModal";
 
class PayrollPeriods extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            payrollperiod   :[],
            payrollPeriodData:[],
            selectedperiod:"",
            selectedperiodID:"",
            selectedModeName:"",
            modalShow: false,
            dateStart:"",
            dateEnd:"",
            periodName:"",
            payrollModeData : [
                {
                    "id":"3",
                    "name":"Monthly"

                },
                {
                    "id":"1",
                    "name":"Semi-Monthly"
                },
                {
                    "id":"2",
                    "name":"Weekly"
                },
                {
                    "id":"4",
                    "name":"Daily"
                },
            ],
            selectedRemoved:[],

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetPayrollPeriods()
        //this.GetEmployeePayModesList()
    }

    GetEmployeePayModesList(){
        const getParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId" : this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
        }
    
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollModes", getParams)
            .then(res => {
                const data = res.data
                console.log("GetEmployeePayModesList")
                console.log(data)
                this.setState({
                    payrollPeriodData: data.payrollModes
                })
            })
    
    }

    GetPayrollPeriods(){
        const getParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId" : this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
	        "PeriodName":"",
            "isDeleted":"0"
        }

        console.log("getParams GetPayrollPeriods")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollPeriods", getParams)
        .then(res => {
            const data = res.data
            console.log("GetPayrollPeriods")
            console.log(data)

            data.payrollPeriods.map(function(d){
                if(d.payrollModeId == "1"){
                    d.modeName = "Semi-Monthly"
                }
                if(d.payrollModeId == "2"){
                    d.modeName = "Weekly"
                }
                if(d.payrollModeId == "3"){
                    d.modeName = "Monthly"
                }
                if(d.payrollModeId == "4"){
                    d.modeName = "Daily"
                }
            })



            this.setState({
                payrollperiod: data.payrollPeriods
            })
            
            
        })
    }

    searchPayrollPeriods=()=>{
        /* const getParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId" : this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
	        "payModeId": this.state.selectedperiodID,
            "name": this.state.periodName,
            "PeriodStart" : this.state.periodStart,
            "PeriodEnd" : this.state.periodEnd,
            //"Name": this.state.selectedperiod,
            
        } */

        const getParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId" : this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
	        "PeriodName":this.state.periodName,
        }

        console.log("getParams GetPayrollPeriods")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollPeriods", getParams)
        .then(res => {
            const data = res.data
            console.log("GetPayrollPeriods")
            console.log(data)
            
            data.payrollPeriods.map(function(d){
                if(d.payrollModeId == "1"){
                    d.modeName = "Semi-Monthly"
                }
                if(d.payrollModeId == "2"){
                    d.modeName = "Weekly"
                }
                if(d.payrollModeId == "3"){
                    d.modeName = "Monthly"
                }
                if(d.payrollModeId == "4"){
                    d.modeName = "Daily"
                }
            })

            this.setState({
                payrollperiod: data.payrollPeriods
            })
            
            
        })
    }

    GridDataModified(oldValue, newValue, id, column) {
        ////console.log(id)
        this.state.trainingScheduleTableList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    onChangePeriod = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedperiodID     :   '',
            })
            return
        } 
        this.state.selectedperiodID = e[0].id 
        this.state.selectedModeName = e[0].name    
               
        this.setState({
            selectedperiodID   : e[0].id,
            selectedModeName   : e[0].name 

        })
       
    }

    handleModalSHOW = (e) =>{
    
        this.setState({modalShow: true})
        
    }
    
    handleModalClose = (e) =>{
        
        this.setState({modalShow: false})
        this.GetPayrollPeriods()
    }

    handleChangedateStart =date=>{
        this.setState({
            dateStart: date
        })
    }

    handleChangedateEnd =date=>{
        this.setState({
            dateEnd: date
        })
    }

    onChangeperiodName=(e)=>{
        if(e.length==0)
        {
            this.setState({
                periodName     :   '',
            })
            return
        }
        this.state.periodName = e[0].periodName     
               
        this.setState({
            periodName   : e[0].periodName

        })
    }

    handleUpdate = (e) => {
        
        console.log(this.state.selectedRemoved)
        let newUserid = this.state.userinfo.userId
        let newCompanyId = this.state.userinfo.companyId

        const Params = {

            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   newUserid,
            "CompanyId"     :   newCompanyId,
            "payrollperiods"   :   this.state.selectedRemoved
            
        }

        console.log("handleClick EditPeriodType")
        console.log(Params)

        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint  + "Maintenance/EditPayrollPeriod",  Params
            )
            .then(res => {
                const data = res.data;
                console.log("EditPayrollPeriods");
                console.log(data)
                if(data.status=="1"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true
                    });
                    this.GetPayrollPeriods()
                
                }else{
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
                
            })
       
           
       

    }

    render() {

        const payrollperiod = [
           
            {
                dataField: 'periodName',
                text: 'Period Name',
                headerStyle: () => {
                    return { width: "44%",textAlign:'left' };
                },
                style:()=>{
                    return {textAlign:'left'}
                }
            },
            {
                dataField: 'modeName',
                text: 'Payroll Mode',
                headerStyle: () => {
                    return { width: "10%", textAlign:'center' };
                },
                style:()=>{
                    return {textAlign:'left'}
                }
            },
            {
                dataField: 'startDate',
                text: 'Start date',
                headerStyle: () => {
                    return { width: "30%", textAlign:'center' };
                },
                style:()=>{
                    return {textAlign:'left'}
                }
            },
            {
                dataField: 'endDate',
                text: 'End date',
                headerStyle: () => {
                    return { width: "30%", textAlign:'center' };
                },
                style:()=>{
                    return {textAlign:'left'}
                }
            },
        ]

        const selectRowPayrollConfig = {
            mode: 'checkbox',
            clickToSelectAndEditCell: false,
            onSelect: (row, isSelect, rowIndex, e) => {
                //console.log(isSelect)
                let newData = []
                this.state.payrollperiod.map(function(item,idx){
                    if(idx==rowIndex)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        console.log(item)
                       
                    }
                    if(item.isDeleted == "1"){
                        newData.push(item)
                    }
                    
                }) 

                this.state.selectedRemoved = newData
            }
        };

    return(
            <div>
                  
                        <Card>
                            <Card.Header></Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                      
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangePeriod}
                                                options={this.state.payrollModeData}
                                                placeholder='select payroll mode'
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                       
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='periodName'
                                                id="basic-example"
                                                onChange={this.onChangeperiodName}
                                                options={this.state.payrollperiod}
                                                placeholder='select period Name'
                                            />
                                        </Col>
                                    </Form.Group>
                                    
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Start Date
                                        </Form.Label>
                                        <Col sm="2">
                                            <DatePicker
                                                ref='date'
                                                selected={this.state.dateStart}
                                                onChange={this.handleChangedateStart}
                                                value={this.state.dateStart}
                                                dateFormat={"MM/dd/yyyy"}
                                                style={{height:'40px !important'}}
                                                size="40"
                                                className="form-control"
                                            />
                                        </Col>

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            End Date
                                        </Form.Label>
                                        <Col sm="2">
                                            <DatePicker
                                                ref='date'
                                                selected={this.state.dateEnd}
                                                onChange={this.handleChangedateEnd}
                                                value={this.state.dateEnd}
                                                dateFormat={"MM/dd/yyyy"}
                                                style={{height:'40px !important'}}
                                                size="40"
                                                className="form-control"
                                            />
                                        </Col>
                                        
                                    </Form.Group>

                                    <ButtonToolbar sm={12}>
                                        <Button variant="primary" className="ml-auto" style={{minWidth:'60px', marginBottom:"5px"}} onClick={this.searchPayrollPeriods}>
                                            Search
                                        </Button>&nbsp;&nbsp;
                                        <Button variant="success" style={{minWidth:'60px', marginBottom:"5px"}} onClick={this.handleModalSHOW}>
                                            Create
                                        </Button>
                                       
                                    </ButtonToolbar>

                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.payrollperiod }
                                        columns = { payrollperiod }
                                        //selectRow = { selectRowLeaveConfig }
                                        cellEdit = { cellEditFactory({ 
                                                mode: 'dbclick', 
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => { 
                                                    //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        striped
                                        hover
                                        condensed
                                        loading={true}
                                        rowClasses="noser-table-row-class"
                                        wrapperClasses="table-responsive"
                                        pagination={ paginationFactory({sizePerPage:15,hideSizePerPage:true,hidePageListOnlyOnePage:true})}
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        selectRow = { selectRowPayrollConfig }
                                    /> 
                                   
                                    <ButtonToolbar sm={12} >
                                       
                                       
                                        <Button variant="danger" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleUpdate}>
                                            remove
                                        </Button>
                                      
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    <PayrollperiodModal
                        show={this.state.modalShow}
                        onHide={this.handleModalClose}
                    />

                   
            </div> 
            
        )
    }

}

export  default PayrollPeriods
