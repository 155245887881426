import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
  }
  from '../../noser-hris-component';
  import PayslipViewCharges from './Modal/PayslipViewCharges'
  
  import ReactToPrint from "react-to-print";
  
  import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
  const ref = React.createRef();

  //const PayslipViewCharges = React.lazy(()=>import("./Modal/PayslipViewCharges"));
  
  class ComponentToPrint extends React.Component {
  
    constructor(props) {
        super(props);
        this.state = {
            regexp          : /^[0-9\b]+$/,
  
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,   
            
            headerArray : [],
            RegOtDDL : [],
            premiumsDL : [],
            inclusionDDL : [],
            inclusionAllowDDL : [],
            mandaDDL : [],
            loansDDL : [],
            loanDDL : [],
            otherDDL : [],
            otherECDDL : [],
            otherMFDDL : [],
            otherHMODDL : [],
            colaDDL : [],
            ecolaDDL : [],
            seaDDL : [],
            lateDDL : [],
            absentDDL : [],
            undertimeDDL:[],
            totalLoansBalance : "",
            modalShow : false,
            payslipDetails : [],
            wdata:[],
            tmpEcolaRate : "",
            tmpEcolaAmpunt: "",
        }
       /*  this.state.userinfo         = JSON.parse(sessionStorage.getItem("userData"))
        this.state.payslipDetails   = JSON.parse(sessionStorage.getItem("payslipdetails_"))
        this.state.clientId         = this.state.payslipDetails.clientId
        this.state.employeeId       = this.state.payslipDetails.employeeId
        this.state.periodId         = this.state.payslipDetails.periodId
        this.state.isSpecial         = this.state.payslipDetails.isSpecial
        this.state.payPeriod         = this.state.payslipDetails.payPeriod */
    }
  
    componentDidMount(){
        this.state.userinfo         = JSON.parse(sessionStorage.getItem("userData"))
        this.state.payslipDetails   = JSON.parse(sessionStorage.getItem("payslipdetails_"))
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.GetPayrolls(); 
       // console.log(" this.state.clientId "    )
        //console.log( this.state.clientId     )
        ////////console.log("periodId")
        ////////console.log(this.state.periodId)
    }
  
    GetPayrolls = async() => {
        let payslipDetails1   = JSON.parse(sessionStorage.getItem("payslipdetails_"))
        console.log("payslipDetails")
        console.log(payslipDetails1.periodId)
        console.log("payslipDetails")
        console.log( "payslipDetails.periodId" )
        console.log(payslipDetails1.periodId)
        console.log( "payslipDetails.periodId" )
        this.setState({isloading:true})
        const params = {
            "IpAddress"     : "0.0.0.0",
            /* "UserId"        : this.state.userinfo .userId,
            "EmployeeId"    : this.state.userinfo.employeeId,
            "PayPeriodId"   : payslipDetails1.periodId, */


            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "ClientName":this.state.userinfo.companyName,
            "EmployeeId"    :   payslipDetails1.selectedEmployeeId,
          //  "Periods"       :   this.state.periodLists,
            "LocationId"    :   "",//this.state.selectedLocationId,
            "IsOffCycle": "0",
            "isProcessed" : "1",
            // "PayrollTypeId" :   this.state.payrollTypeId,
            "PayPeriodId"   : payslipDetails1.periodId
        };
        console.log("GetPayrollLedgers params")
        console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetPayrolls",  params)
        //.post("http://209.97.173.181:8081//Payroll/GetPayrolls",  params)
        .then(res => {
            var nf = new Intl.NumberFormat();
                console.log("res.data")
                console.log(res.data)
                const data = res.data.payrolls;
                console.log("GetPayrolls 123123123123123123123123123123123")
                console.log(data)
                let dataArray = []
                let dataRegOt = []
                let dataPrem = []
                let dataInclus = []
                let dataInclusAllow = []
                let dataManda = []
                let dataLoans = []
                let dataOthers = []
                let dataOthersEC = []
                let dataOthersMF = []
                let dataOthersHMO = []
                let DatatotalOtherAmount = []
                let colaData = []
                let lateData = []
                let ecolaData = []
                let seaData = []
                let absentData = []
                let undertimeData = []
                let payperiod = ""
               
                console.log(data)
                for(let x = 0; x < data.length; x++){
                    if(this.state.isSpecial === "1"){
                        payperiod = this.state.payPeriod
                    } else {
                        payperiod = data[x]["payPeriod"]
                    }
                    if(data[x]["lateAmount"] !== "0.00"){
                        const lateObj = {
                            "lateLabel" : "LATE",
                            "lateMins" : data[x]["lateMins"],
                            "lateAmount" : data[x]["lateAmount"],
                        }
                        lateData.push(lateObj)
                    }
                    if(data[x]["absentDays"] !== "0.00"){
                        const lateObj = {
                            "absentLabel" : "ABSENT",
                            "absentDays" : data[x]["absentDays"],
                            "absentAmount" : data[x]["absentAmount"],
                        }
                        absentData.push(lateObj)
                    }
                    if(data[x]["undertimeAmount"] !== "0.00"){
                        const underObj = {
                            "undertimeLabel" : "UNDERTIME / HALF DAY",
                            "undertimeMins" : data[x]["undertimeMins"],
                            "undertimeAmount" : data[x]["undertimeAmount"],
                        }
                        undertimeData.push(underObj)
                    }
                    if(data[x]["colaAmount"] !== "0.00"){
                        const colaObj = {
                            "colaName" : "COLA",
                            "colaRate" : parseFloat(data[x]["colaRate"]).toFixed(2),
                            "colaAmount" : data[x]["colaAmount"],
                        }
                        colaData.push(colaObj)
                    }
                    if(data[x]["eColaAmount"] !== "0.00"){
                        const ecolaObj = {
                            "ecolaName" : "ECOLA",
                            "eColaRate" : data[x]["eColaRate"],
                            "eColaAmount" : data[x]["eColaAmount"],
                        }
                        ecolaData.push(ecolaObj)
                    }
                    if(data[x]["seaAmount"] !== "0.00"){
                        const seaObj = {
                            "seaName" : "SEA",
                            "seaRate" : data[x]["seaRate"],
                            "seaAmount" : data[x]["seaAmount"],
                        }
                        seaData.push(seaObj)
                    }
                    const objHeader = {
                        "memberName"        : data[x]["memberName"],
                        "memberNo"          : data[x]["memberNo"],
                        "payPeriod"         : payperiod,
                        "location"          : data[x]["location"],
                        "memberNameLabel"   : "EMPLOYEE NAME",
                        "memberNoLabel"     : "EMPLOYEE NO.",
                        "locationLabel"    : "LOCATION",
                        "payPeriodLabel"     : "PAY PERIOD",
                        "colon" : ":",
                        // START EARNINGS
                        "basicPayLabel"         : "BASIC PAY",
                        "basicSalaryLabel"      : "BASIC SALARY",
                        "basicPayHrs"           : data[x]["basicPayHrs"],
                        "basicPayAmount"        : data[x]["basicPayAmount"],
                        //"lateLabel"             : "LATE",
                        //"lateMins"              : data[x]["lateMins"],
                        //"lateAmount"            : data[x]["lateAmount"],
                        "netRegularPayLabel"    : "NET REGULAR PAY",
                        "netRegularPay"         : data[x]["netRegularPay"],
                        "premiumLabel"          : "PREMIUM",
                        "totalPremiumLabel"     : "TOTAL PREMIUM",
                        "totalPremiums"         : data[x]["totalPremiums"],
                        "inclusionsLabel"       : "INCLUSION",
                        "totalInclusionsLabel"  : "TOTAL INCLUSION",
                        "totalInclusions"       : data[x]["totalInclusions"],
                        "grossLabel"            : "GROSS EARNINGS",
                        "grossPay"              : data[x]["totalGrossPay"],
                        "netSalaryLabel"        : "NET SALARY PAYABLE",
                        "salaryOffered"         : data[x]["actualNetPay"],
                        "mandatoryLabel"        : "MANDATORY",
                        "totalMandatoryLabel"   : "TOTAL MANDATORY",
                        "loansLabel"            : "LOAN",
                        "totalLoansLabel"       : "TOTAL LOAN",
                        "othersLabel"           : "OTHERS",
                        "totalOtherLabel"           : "TOTAL OTHERS",
                        "grossDeductionLabel"           : "GROSS DEDUCTIONS",
                        "totalDeductions"         : data[x]["totalDeductions"],
                        "payCardTypeLabel"           : "PAYCARD TYPE",
                        "payCardType"         : data[x]["payCardType"],
                        // END EARNINGS
                    }
                    dataArray.push(objHeader)

                for(let i = 0; i < data[x]["premiums"].length; i++){
                    //this.state.premiums = data[x]["premiums"][i]
                    // if(data[x]["premiums"][i]["rateCode"] === "REG OT"){
                    //     this.state.regOTText = "REG OT"
                    //     const objRegOt = {
                    //         "rateCode"  : data[x]["premiums"][i]["rateCode"],
                    //         "nofHrs"    : data[x]["premiums"][i]["nofHrs"],
                    //         "amount"    : data[x]["premiums"][i]["amount"]
                    //     }
                    //     dataRegOt.push(objRegOt)
                    // } else {
                    //     const objPrem = {
                    //         "rateCode"  : data[x]["premiums"][i]["rateCode"],
                    //         "nofHrs"    : data[x]["premiums"][i]["nofHrs"],
                    //         "amount"    : data[x]["premiums"][i]["amount"]
                    //     }
                    //     dataPrem.push(objPrem)
                    // }
                    const objPrem = {
                        "rateCode"  : data[x]["premiums"][i]["rateCode"],
                        "nofHrs"    : data[x]["premiums"][i]["nofHrs"],
                        "amount"    : data[x]["premiums"][i]["amount"]
                    }
                    dataPrem.push(objPrem)
                }
                
                for(let h = 0; h < data[x]["inclusions"].length; h++){
                    //this.state.premiums = data[x]["premiums"][i]
                    if(data[x]["inclusions"][h]["inclusionType"] === "ALLOWANCE"){
                        const objInclusAllow = {
                            "noOfDays"  : data[x]["inclusions"][h]["noOfDays"],
                            "inclusion" : data[x]["inclusions"][h]["inclusion"],
                            "amount"    : data[x]["inclusions"][h]["amount"]
                        }
                        dataInclusAllow.push(objInclusAllow)
                    } else {
                        const objInclus = {
                            "noOfDays"  : data[x]["inclusions"][h]["noOfDays"],
                            "inclusion" : data[x]["inclusions"][h]["inclusion"],
                            "amount"    : data[x]["inclusions"][h]["amount"]
                        }
                        dataInclus.push(objInclus)
                    }
                }

                // START MANDA LOANS

                for(let manda = 0; manda < data[x]["deductions"].length; manda++){
                    if(data[x]["deductions"][manda]["ledgerTypeId"] === "1"){
                        const mandaObj = {
                            "deductionType" : data[x]["deductions"][manda]["deductionType"],
                            "amount"        : data[x]["deductions"][manda]["amount"]
                        }
                        dataManda.push(mandaObj)
                    }
                    
                    if(data[x]["deductions"][manda]["ledgerTypeId"] === "2"){
                        const loansObj = {
                            "deductionType"     : data[x]["deductions"][manda]["deductionType"],
                            "ledgerTypeId"      : data[x]["deductions"][manda]["ledgerTypeId"],
                            "deductionTypeId"   : data[x]["deductions"][manda]["deductionTypeId"],
                            "amount"            : data[x]["deductions"][manda]["amount"],
                            "balance"           : this.state.totalLoansBalance,
                        }
                        dataLoans.push(loansObj)
                    }

                    if(data[x]["deductions"][manda]["ledgerTypeId"] === "4"){
                        if(data[x]["deductions"][manda]["deductionTypeId"] === "7"){
                            const othersECObj = {
                                "deductionType" : data[x]["deductions"][manda]["deductionType"],
                                "amount"        : data[x]["deductions"][manda]["amount"],
                                "deductionId"        : data[x]["deductions"][manda]["deductionId"],
                                "deductionType"        : data[x]["deductions"][manda]["deductionType"],
                                "clientId"        : data[x]["clientId"],
                                "employeeId"        : data[x]["employeeId"],
                                "payPeriodId"        : data[x]["payPeriodId"]
                            }
                            dataOthersEC.push(othersECObj)
                            DatatotalOtherAmount.push(othersECObj)
                        } else if(data[x]["deductions"][manda]["deductionTypeId"] === "3"){
                            const othersMFObj = {
                                "deductionType" : data[x]["deductions"][manda]["deductionType"],
                                "amount"        : data[x]["deductions"][manda]["amount"],
                                "deductionId"        : data[x]["deductions"][manda]["deductionId"],
                                "deductionType"        : data[x]["deductions"][manda]["deductionType"],
                                "clientId"        : data[x]["clientId"],
                                "employeeId"        : data[x]["employeeId"],
                                "payPeriodId"        : data[x]["payPeriodId"]
                            }
                            dataOthersMF.push(othersMFObj)
                            DatatotalOtherAmount.push(othersMFObj)
                        } else if(data[x]["deductions"][manda]["deductionTypeId"] === "5"){
                            const othersHMOObj = {
                                "deductionType" : data[x]["deductions"][manda]["deductionType"],
                                "amount"        : data[x]["deductions"][manda]["amount"],
                                "deductionId"        : data[x]["deductions"][manda]["deductionId"],
                                "deductionType"        : data[x]["deductions"][manda]["deductionType"],
                                "clientId"        : data[x]["clientId"],
                                "employeeId"        : data[x]["employeeId"],
                                "payPeriodId"        : data[x]["payPeriodId"]
                            }
                            dataOthersHMO.push(othersHMOObj)
                            DatatotalOtherAmount.push(othersHMOObj)
                        } else {
                            const othersObj = {
                                "deductionType" : data[x]["deductions"][manda]["deductionType"],
                                "amount"        : data[x]["deductions"][manda]["amount"],
                                "deductionId"        : data[x]["deductions"][manda]["deductionId"],
                                "deductionType"        : data[x]["deductions"][manda]["deductionType"],
                                "clientId"        : data[x]["clientId"],
                                "employeeId"        : data[x]["employeeId"],
                                "payPeriodId"        : data[x]["payPeriodId"]
                            }
                            dataOthers.push(othersObj)
                            DatatotalOtherAmount.push(othersObj)
                        }
                    }
                }
                this.GetPayrollLedgers(dataLoans);
                // ENDD MANDA LOANS
            }
            const RegOtDDL = dataRegOt.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.rateCode === thing.rateCode
                ))
            )
            const otherECData = dataOthersEC.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionType === thing.deductionType
                ))
            )
            const otherMFData = dataOthersMF.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionType === thing.deductionType
                ))
            )
            const otherHMOData = dataOthersHMO.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionType === thing.deductionType
                ))
            )
            //const totalNoOfHrs      = dataRegOt.reduce((total, currentValue) => total + parseInt(currentValue.nofHrs),0)
            const totalRegOtAmount  = dataRegOt.reduce((total, currentValue) => total + parseFloat(currentValue.amount),0).toFixed(2)
            const totalMandatory    = dataManda.reduce((total, currentValue) => total + parseFloat(currentValue.amount),0).toFixed(2)
            const totalLoans        = dataLoans.reduce((total, currentValue) => total + parseFloat(currentValue.amount),0).toFixed(2)
            const totalEC           = dataOthersEC.reduce((total, currentValue) => total + parseFloat(currentValue.amount),0).toFixed(2)
            const totalMF           = dataOthersMF.reduce((total, currentValue) => total + parseFloat(currentValue.amount),0).toFixed(2)
            const totalHMO          = dataOthersHMO.reduce((total, currentValue) => total + parseFloat(currentValue.amount),0).toFixed(2)
            const totalOtherAmount  = DatatotalOtherAmount.reduce((total, currentValue) => total + parseFloat(currentValue.amount),0).toFixed(2)
           // "eColaRate" : data[x]["eColaRate"],
           // "eColaAmount" : data[x]["eColaAmount"],
            let tmpEcolaRate = ""
            let tmpEcolaAmpunt= ""
            data.forEach(element => {
                tmpEcolaRate = element.eColaRate
                tmpEcolaAmpunt = element.eColaAmount
            });
            console.log("tmpEcolaRate")
            console.log(tmpEcolaRate)

            this.setState({
                wdata : res.data,
                tmpEcolaRate : tmpEcolaRate,
                tmpEcolaAmpunt : tmpEcolaAmpunt,
                colaDDL            : colaData,
                ecolaDDL            : ecolaData,
                seaDDL            : seaData,
                lateDDL            : lateData,
                absentDDL            : absentData,
                undertimeDDL            : undertimeData,
                RegOtDDL            : RegOtDDL,
                //totalNoOfHrs        : totalNoOfHrs,
                totalRegOtAmount    : totalRegOtAmount,
                premiumsDL          : dataPrem,
                headerArray         : dataArray,
                inclusionDDL        : dataInclus,
                inclusionAllowDDL        : dataInclusAllow,
                mandaDDL            : dataManda,
                totalMandatory      : totalMandatory,
                loansDDL            : dataLoans,
                totalLoans          : totalLoans,
                otherDDL            : dataOthers,
                otherECDDL          : otherECData,
                totalEC             : totalEC,
                otherMFDDL          : otherMFData,
                otherHMODDL         : otherHMOData,
                totalMF             : totalMF,
                totalHMO            : totalHMO,
                totalOtherAmount    : totalOtherAmount,
                isloading           :  false,
            })
            
        })
    }  
  
    GetPayrollLedgers = (loansDDL) => {
        //this.setState({isloading:true})

        //////console.log("loansDDL")
        //////console.log(loansDDL)
        loansDDL.filter(x => x.ledgerTypeId === "2").forEach(item => {
            const params = {
                "IpAddress"     :"0.0.0.0",
                "ClientId"      :this.state.clientId,
                "UserId"        :this.state.userinfo.userId,
                "EmployeeId"   :this.state.employeeId,
                "LedgerTypeId"   :item.ledgerTypeId,
                "ReferenceId"   :item.deductionTypeId
            };
            ////////console.log("GetPayrollLedgers params")
            ////////console.log(params)
            axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetPayrollLedgers",  params)
            //.post("http://209.97.173.181:8081/Payroll/GetPayrollLedgers",  params)
            .then(res => {
                //////console.log("res.data")
                //////console.log(res.data)
                if(res.data.ledgers!==null){
                    if(res.data.ledgers.length>0){
                        const data = res.data.ledgers.filter(x => x.payPeriodId === this.state.periodId)
                        const dataArrs = data[data.length-1];
                        let dataArr = []
                        ////////console.log("GetPayrollLedgers")
                        ////////console.log(data)
                        const obj = {
                            "deductionType"     : dataArrs.particular,
                            "amount"            : dataArrs.debit,
                            "balance"           : dataArrs.balance,
                        }
                        this.state.loanDDL.push(obj)
                        this.setState({
                            loanDDL : this.state.loanDDL,
                            //totalLoansBalance :data[data.length-1].balance,
    
                        })
                    }
                }
            })

        })
    }

    othersChargesClick = (item) => {
        this.setState({modalShow:true})
        //sessionStorage.setItem("chargesData", JSON.stringify(item))
        this.child.initialize(item)
    } 

    handleModalClose = (e) =>{
        this.setState({modalShow:false})
        //sessionStorage.clear();
    };

    testw=()=>{
        console.log("this.state.ecolaDDL")
        console.log(this.state.ecolaDDL)
    }
  
        render() {
            var nf = new Intl.NumberFormat();
            const FormatCommas = (x) =>{
                x = x.toString();
                var pattern = /(-?\d+)(\d{3})/;
                while (pattern.test(x))
                    x = x.replace(pattern, "$1,$2");
                return x;
            }
            return(
                <>
                <Container  className='mt-5' style={{backgroundColor : "#fff"}}>
                    <div style={{textAlign : "center"}}>
                        <label style={{fontWeight : "bold", fontSize : "20px"}}>{this.state.userinfo.companyName}</label><br/>
                        <label style={{fontSize : "16px"}}>UNIT 11 13 FLOOR THE ONE EXECUTIVE BUILDING #7 COL MARTINEZ ST, COR. WEST AVENUE BAGONG NAYON KANLURAN, QC</label>
                    </div>
                    <div style={{height : "15px"}}></div>
                    <div>
                        <hr style={{height : "1px", borderWidth : "0", color: "grey", backgroundColor: "gray"}}/>
                        <table style={{maxWidth : "100%"}}>
                            {
                                this.state.headerArray.map((item) => {
                                    return (
                                        <thead style={{paddingRight : "20px"}}>
                                            <th style={{fontWeight : "normal", paddingRight : "70px", fontSize : "16px"}}>{item.memberNameLabel}</th>
                                            <th style={{fontWeight : "normal", paddingRight : "40px",  fontSize : "16px"}}>{item.colon}</th>
                                            <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic"}}>{item.memberName}</th>
                                        </thead>
                                    )
                                })
                            }
                            {
                                this.state.headerArray.map((item) => {
                                    return (
                                        <thead style={{paddingRight : "20px"}}>
                                            <th style={{fontWeight : "normal", paddingRight : "70px", fontSize : "16px"}}>{item.memberNoLabel}</th>
                                            <th style={{fontWeight : "normal", paddingRight : "40px",  fontSize : "16px"}}>{item.colon}</th>
                                            <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic"}}>{item.memberNo}</th>
                                        </thead>
                                    )
                                })
                            }
                            {
                                this.state.headerArray.map((item) => {
                                    return (
                                        <thead style={{paddingRight : "20px"}}>
                                            <th style={{fontWeight : "normal", paddingRight : "70px", fontSize : "16px"}}>{item.payPeriodLabel}</th>
                                            <th style={{fontWeight : "normal", paddingRight : "40px",  fontSize : "16px"}}>{item.colon}</th>
                                            <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic"}}>{item.payPeriod}</th>
                                        </thead>
                                    )
                                })
                            }
                            {
                                this.state.headerArray.map((item) => {
                                    return (
                                        <thead style={{paddingRight : "20px"}}>
                                            <th style={{fontWeight : "normal", paddingRight : "70px", fontSize : "16px"}}>{item.locationLabel}</th>
                                            <th style={{fontWeight : "normal", paddingRight : "40px",  fontSize : "16px"}}>{item.colon}</th>
                                            <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic"}}>{item.location}</th>
                                        </thead>
                                    )
                                })
                            }
                        </table>
                        <hr style={{height : "1px", borderWidth : "0", color: "grey", backgroundColor: "gray"}}/>
                        <div style={{height : "20px"}}></div>
                        <div style={{border: "4px solid black", textAlign : "center"}}>
                            <label style={{fontWeight : "bold", fontSize : "16px"}}>EARNINGS</label>
                        </div>
                        <div style={{border: "3px solid black"}}>
                            <div style={{height : "10px"}}></div>
                            {
                                this.state.headerArray.map((item) => {
                                    return (
                                        <label style={{fontWeight : "bold", fontSize : "16px", marginLeft : "10px"}}>{item.basicPayLabel}</label>
                                    )
                                })
                            }
                            <table style={{marginLeft : "20px"}}>
                                {
                                    this.state.headerArray.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{ width: "250px",fontWeight : "normal", textAlign : "left", /* paddingRight : "10px", */ fontSize : "16px"}}>{item.basicSalaryLabel}</th>
                                                <th style={{ width: "350px",fontWeight : "bold", /* paddingRight : "200px", */ fontSize : "16px", textAlign : "center", fontStyle : "italic", }}>{item.basicPayHrs}</th>
                                                <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(item.basicPayAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                                {
                                    this.state.lateDDL.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "normal", textAlign : "left",paddingRight : "200px", fontSize : "16px"}}>{item.lateLabel}</th>
                                                <th style={{fontWeight : "bold",paddingRight : "200px", fontSize : "16px", textAlign : "center", fontStyle : "italic"}}>{item.lateMins}</th>
                                                <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(item.lateAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                                {
                                    this.state.absentDDL.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "normal", paddingRight : "300px", fontSize : "16px"}}>{item.absentLabel}</th>
                                                <th style={{fontWeight : "bold", paddingRight : "300px", fontSize : "16px", textAlign : "right", fontStyle : "italic"}}>{item.absentDays}</th>
                                                <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(item.absentAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                                {
                                    this.state.undertimeDDL.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "normal", paddingRight : "300px", fontSize : "16px"}}>{item.undertimeLabel}</th>
                                                <th style={{fontWeight : "bold", paddingRight : "300px", fontSize : "16px", textAlign : "right", fontStyle : "italic"}}>{item.undertimeMins}</th>
                                                <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(item.undertimeAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                                {
                                    this.state.colaDDL.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "normal", textAlign : "left", /* paddingRight : "250px",  */fontSize : "16px"}}>{item.colaName}</th>
                                                <th style={{fontWeight : "bold", /* paddingRight : "250px",  */fontSize : "16px", textAlign : "center", fontStyle : "italic"}}>{item.colaRate}</th>
                                                <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(item.colaAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                                {
                                    this.state.ecolaDDL.map((item) => {
                                        return (
                                        
                                                <thead  >
                                                    <th sm={3} style={{fontWeight : "normal", textAlign : "left",/* paddingRight : "250px", */ /* paddingLeft: "20px", */ fontSize : "16px"}}>{item.ecolaName}</th>
                                                    <th sm={3} style={{fontWeight : "bold", /* paddingRight : "250px", */ fontSize : "16px", textAlign : "center", fontStyle : "italic"}}> {this.state.tmpEcolaRate}</th>
                                                    <th  sm={3} style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{ parseFloat(this.state.tmpEcolaAmpunt).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                                </thead>
                                            
                                        )
                                    })
                                }
                                {
                                    this.state.seaDDL.map((item) => {
                                        return (
                                            <thead>
                                                <th  style={{fontWeight : "normal", textAlign : "left", /* paddingRight : "250px", *//*  paddingLeft: "20px", */ fontSize : "16px"}}>{item.seaName}</th>            
                                                <th  style={{fontWeight : "bold", /* paddingRight : "250px", */ fontSize : "16px", textAlign : "center", fontStyle : "italic"}}>{item.seaRate}</th>                                         
                                                <th  style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(item.seaAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead> 
                                        )
                                    })
                                }
                            </table>
                            <hr style={{height : "1px", borderWidth : "0", color: "grey", backgroundColor: "gray"}}/>
                            <table style={{marginLeft : "20px"}}>
                                {
                                    this.state.headerArray.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "bold", paddingRight : "700px", fontSize : "16px"}}>{item.netRegularPayLabel}</th>
                                                <th className='ml-auto' style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(item.netRegularPay).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                            </table>
                            <hr style={{height : "1px", borderWidth : "0", color: "grey", backgroundColor: "gray"}}/>
                            {
                                this.state.headerArray.map((item) => {
                                    return (
                                        <label style={{fontWeight : "bold", fontSize : "16px", marginLeft : "10px"}}>{item.premiumLabel}</label>
                                    )
                                })
                            }
                            <table style={{marginLeft : "20px"}}>
                                {/* {
                                    this.state.RegOtDDL.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "normal", paddingRight : "380px", fontSize : "16px"}}>{item.rateCode}</th>
                                                <th style={{fontWeight : "bold", paddingRight : "280px", fontSize : "16px", textAlign : "right", fontStyle : "italic"}}>{FormatCommas(this.state.totalNoOfHrs)}</th>
                                                <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(this.state.totalRegOtAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                } */}
                                {
                                    this.state.premiumsDL.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "normal", paddingRight : "341px", fontSize : "16px"}}>{item.rateCode}</th>
                                                <th style={{fontWeight : "bold", paddingRight : "168px", fontSize : "16px", textAlign : "right", fontStyle : "italic"}}>60.00{/* {item.nofHrs} */}</th>
                                                <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(item.amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                            </table>
                            <table style={{marginLeft : "20px"}}>
                                {
                                    this.state.headerArray.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "bold", paddingRight : "715px", fontSize : "16px"}}>{item.totalPremiumLabel}</th>
                                                <th className='ml-auto' style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}> {parseFloat(item.totalPremiums).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                            </table>
                            <hr style={{height : "1px", borderWidth : "0", color: "grey", backgroundColor: "gray"}}/>
                            {
                                this.state.headerArray.map((item) => {
                                    return (
                                        <label style={{fontWeight : "bold", fontSize : "16px", marginLeft : "10px"}}>{item.inclusionsLabel}</label>
                                    )
                                })
                            }
                            <table style={{marginLeft : "20px"}}>
                                {
                                    this.state.inclusionAllowDDL.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "normal", paddingRight : "350px", fontSize : "16px"}}>{item.inclusion}</th>
                                                <th style={{fontWeight : "bold", paddingRight : "280px", fontSize : "16px", textAlign : "right", fontStyle : "italic"}}>{parseFloat(item.noOfDays).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                                <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(item.amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                                {
                                    this.state.inclusionDDL.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "normal", paddingRight : "350px", fontSize : "16px"}}>{item.inclusion}</th>
                                                <th style={{fontWeight : "bold", paddingRight : "280px", fontSize : "16px", textAlign : "right", fontStyle : "italic"}}>{parseFloat(item.noOfDays).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                                <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(item.amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                            </table>
                            <table style={{marginLeft : "20px"}}>
                                {
                                    this.state.headerArray.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "bold", paddingRight : "700px", fontSize : "16px"}}>{item.totalInclusionsLabel}</th>
                                                <th className='ml-auto' style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(item.totalInclusions).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                            </table>
                            <hr style={{height : "1px", borderWidth : "0", color: "grey", backgroundColor: "gray"}}/>
                            <table style={{marginLeft : "20px"}}>
                                {
                                    this.state.headerArray.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "bold", paddingRight : "700px", fontSize : "16px"}}>{item.grossLabel}</th>
                                                <th className='ml-auto' style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(item.grossPay).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                            </table>
                            <hr style={{height : "1px", borderWidth : "0", color: "grey", backgroundColor: "gray"}}/>
                            <table style={{marginLeft : "20px"}}>
                                {
                                    this.state.headerArray.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "bold", paddingRight : "668px", fontSize : "16px"}}>{item.netSalaryLabel}</th>
                                                <th  className='ml-auto' style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{ parseFloat(item.salaryOffered).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                            </table>
                        </div>
                        <div style={{height : "40px"}}></div>
                        <div style={{border: "4px solid black", textAlign : "center"}}>
                            <label style={{fontWeight : "bold", fontSize : "16px"}}>DEDUCTIONS</label>
                        </div>
                        <div style={{border: "3px solid black"}}>
                            <div style={{height : "10px"}}></div>
                            {
                                this.state.headerArray.map((item) => {
                                    return (
                                        <label style={{fontWeight : "bold", fontSize : "16px", marginLeft : "10px"}}>{item.mandatoryLabel}</label>
                                    )
                                })
                            }
                            <table style={{marginLeft : "20px"}}>
                                {
                                    this.state.mandaDDL.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "normal", paddingRight : "350px", fontSize : "16px"}}>{item.deductionType}</th>
                                                <th style={{fontWeight : "bold", paddingRight : "175px", fontSize : "16px", textAlign : "right", fontStyle : "italic"}}>0.00</th>
                                                <th  style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(item.amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                            </table>
                            <table style={{marginLeft : "20px"}}>
                                {
                                    this.state.headerArray.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "bold", paddingRight : "680px", fontSize : "16px"}}>{item.totalMandatoryLabel}</th>
                                                <th  className='ml-auto' style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(this.state.totalMandatory).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                            </table>
                            <hr style={{height : "1px", borderWidth : "0", color: "grey", backgroundColor: "gray"}}/>
                            {
                                this.state.headerArray.map((item) => {
                                    return (
                                        <label style={{fontWeight : "bold", fontSize : "16px", marginLeft : "10px"}}>{item.loansLabel}</label>
                                    )
                                })
                            }
                            <table style={{marginLeft : "20px"}}>
                                {
                                    this.state.loanDDL.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "normal", paddingRight : "380px", fontSize : "16px"}}>{item.deductionType}</th>
                                                <th style={{fontWeight : "bold", paddingRight : "280px", fontSize : "16px", textAlign : "right", fontStyle : "italic"}}>{item.balance}</th>
                                                <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{item.amount}</th>
                                            </thead>
                                        )
                                    })
                                }
                            </table>
                            <table style={{marginLeft : "20px"}}>
                                {
                                    this.state.headerArray.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "bold", paddingRight : "740px", fontSize : "16px"}}>{item.totalLoansLabel}</th>
                                                <th className='ml-auto' style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(this.state.totalLoans).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                            </table>
                            <hr style={{height : "1px", borderWidth : "0", color: "grey", backgroundColor: "gray"}}/>
                            {
                                this.state.headerArray.map((item) => {
                                    return (
                                        <label style={{fontWeight : "bold", fontSize : "16px", marginLeft : "10px"}}>{item.othersLabel}</label>
                                    )
                                })
                            }
                            <table style={{marginLeft : "20px"}}>
                                {
                                    this.state.otherDDL.map((item) => {
                                        return (
                                            <thead>
                                                <th  style={{fontWeight : "normal", paddingRight : "380px", fontSize : "16px"}}>{item.deductionType}</th>
                                                <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(item.amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                                {
                                    this.state.otherECDDL.map((item) => {
                                        return (
                                            <thead>
                                                <th onClick={ () => this.othersChargesClick(item)} style={{cursor : "pointer", fontWeight : "normal", paddingRight : "380px", fontSize : "16px"}}>{item.deductionType}</th>
                                                <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(this.state.totalEC).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                                {
                                    this.state.otherMFDDL.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "normal", paddingRight : "380px", fontSize : "16px"}}>{item.deductionType}</th>
                                                <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(this.state.totalMF).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                                {
                                    this.state.otherHMODDL.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "normal", paddingRight : "380px", fontSize : "16px"}}>{item.deductionType}</th>
                                                <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(this.state.totalHMO).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                            </table>
                            <table style={{marginLeft : "20px"}}>
                                {
                                    this.state.headerArray.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "bold", paddingRight : "720px", fontSize : "16px"}}>{item.totalOtherLabel}</th>
                                                <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(this.state.totalOtherAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                            </table>
                            <hr style={{height : "1px", borderWidth : "0", color: "grey", backgroundColor: "gray"}}/>
                            <table style={{marginLeft : "20px"}}>
                                {
                                    this.state.headerArray.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "bold", paddingRight : "680px", fontSize : "16px"}}>{item.grossDeductionLabel}</th>
                                                <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{parseFloat(item.totalDeductions).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                            </thead>
                                        )
                                    })
                                }
                            </table>
                            <hr style={{height : "1px", borderWidth : "0", color: "grey", backgroundColor: "gray"}}/>
                            <table style={{marginLeft : "20px"}}>
                                {
                                    this.state.headerArray.map((item) => {
                                        return (
                                            <thead>
                                                <th style={{fontWeight : "bold", paddingRight : "720px", fontSize : "16px"}}>{item.payCardTypeLabel}</th>
                                                <th style={{fontWeight : "bold", fontSize : "16px", fontStyle : "italic", textAlign : "right"}}>{item.payCardType}</th>
                                            </thead>
                                        )
                                    })
                                }
                            </table>
                        </div>
                        <div style={{height : "20px"}}></div>
                        <table style={{marginLeft : "10"}}>
                            <thead>
                                <th style={{fontWeight : "bold", fontSize : "16px"}}>I hereby acknowledge to have received the sum as fullpayment of my service rendered.</th>
                            </thead>
                        </table>
                        <div style={{height : "60px"}}></div>
                        <table style={{marginLeft : "10"}}>
                            <thead>
                                <th><label style={{fontSize : "16px"}}>Signature :</label>&nbsp;<input type="text" style={{width: "400px", border : "0", borderBottom : "2px solid gray", marginLeft: "10px"}}/></th>
                            </thead>
                        </table>
                        {/* <div style={{height : "20px"}}></div>
                        <table style={{marginLeft : "20px"}}>
                            <thead>
                                <th style={{fontWeight : "bold", paddingRight : "500px", fontSize : "16px"}}>sdfsdfsdf</th>
                                <th><input type="text" style={{border : "0", borderBottom : "2px solid gray"}}/></th>
                            </thead>
                        </table> */}
                    </div>
                </Container>
                <NoserLoading show={this.state.isloading} />
                <PayslipViewCharges 
                    show={this.state.modalShow}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
                </>
            )
        }
    }
  
  
  class DownloadPayslip extends React.Component {
    render() {
      return (
        <div>
            <ComponentToPrint ref={(el) => (this.componentRef = el)} />
            <div style={{height : "20px"}}></div>
            <div style={{textAlign : "center", fontSize : "16px"}}>
                <ReactToPrint 
                    trigger={() => <button>Download Payslip</button>}
                    content={() => this.componentRef}
                />
               
            </div>
            <div style={{height : "40px"}}></div>
  
        </div>
      );
    }
  }
  
  export default DownloadPayslip
