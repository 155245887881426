import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../../noser-hris-component';
import CreateRole from './CreateRole'
class Roles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            roleDDL         :   [],
            roleList        :   [],
            roleName        :   '',
            departmentDDL   :   [],
            disableSaveBtn  :   true     
        }
    } 
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetDepartments()
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.GetDepartments()
    }
    GetDepartments = async() =>{
        this.setState({isloading:true,isDisable:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            //"Name" : this.state.roleName
        }
        console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetUserDepartments", params)
        .then(res => {
            const data = res.data
            if(data.status=="1"){
                
                let _department = []
                data.userDepartments.map(function(itm){
                    _department.push({
                        "value":itm.id,
                        "label":itm.name
                    })
                })
                this.setState({
                    isloading   :   false,
                    departmentDDL:   _department
                });
                console.log(this.state.departmentDDL)
                this.GetRoles()
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    departmentDDL     :   data.userDepartments
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    GetRoles = () =>{
        this.setState({isloading:true,isDisable:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            //"Name" : this.state.roleName
        }
        console.log(params)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetRoles", params)
        .then(res => {
            const data = res.data
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    roleDDL     :   data.roles,
                    roleList    :   data.roles,
                });
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    roleDDL     :   data.roles,
                    roleList    :   data.roles,
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    handleSearch = (e) => {
        console.log(this.state.roleName)
        let _rolelst = []
        let _roleDDL = []
        if(this.state.roleName=="")
            _roleDDL = this.state.roleDDL
        else
            _roleDDL = this.state.roleDDL.filter(x=>x.name==this.state.roleName)
        _roleDDL.map(function(itm){
        _rolelst.push({
                        "id" : itm.id,
                        "name": itm.name, 
                        "description" : itm.description,
                        "isDeleted" : itm.isDeleted,
                        "isModified" : itm.isModified
                    })
        })
        this.setState({roleList : _rolelst})
    }
    handleSubmit = (e) => {
        this.setState({isloading:true,disableSaveBtn:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "roles":this.state.roleList.filter(x=>x.isModified=="1"),
        }
        
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/EditRole", params)
        .then(res => {
            const data = res.data
            if(data.status=="1"){
                this.GetRoles()
            }
            this.setState({
                isloading   :   false,
                alerttype   :   data.status=="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                disableSaveBtn:true
            });

        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    handleChangeRole = (e) =>{
        this.setState({
            alerttype   :   "",
            isshow      :   false,
            color       :   "",
            message     :   "",
            fade        :   false
        });
        if(e.length==0)
        {
            this.state.roleName= ''
            return
        }
        this.state.roleName= e[0].name
    }
    GridDataModified(oldValue, newValue, rowid, column) {
        let isDisable = true
        this.state.roleList.map(function(item,i){
            if(item.id==rowid)
                item.isModified = "1"
            if(item.isModified=="1")
                isDisable = false
            console.log(isDisable)
        })
        this.setState({disableSaveBtn:isDisable})
    }
    render(){
        const usersCol = [
            {
                dataField: 'name',
                text: 'NAME',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%' }},
                style:{textAlign:'left',width:'20%'},
                formatter: (cell, row) => {
                    return cell.toUpperCase();
                },
            },
            {
                dataField: 'description',
                text: 'DESCRIPTION',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%' }},
                style:{textAlign:'left',width:'20%'},
                formatter: (cell, row) => {
                    return cell.toUpperCase();
                },
            },
            {
                dataField: 'departmentId',
                text: 'Department',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'60%' }},
                style:{textAlign:'left',width:'60%'},
                //sort:true,
                editable:true,
                formatter: (cell, row) => {
                    if(row.departmentId!=='' && row.departmentId!==null){
                        if(this.state.departmentDDL.filter(x => x.value === cell).length==0)
                        return ""
                    else
                        return this.state.departmentDDL.find(x => x.value === cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.departmentDDL
                }
            },
        ]
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.roleList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableSaveBtn:isDisable})
            }
        };
        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header> ADMINISTRATOR >> ROLES</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Row>
                                    <Form.Group as={Col} sm={12} controlId="formHorizontalEmail">
                                        <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeRole}
                                                options={this.state.roleDDL}
                                                placeholder="Select Role"
                                            />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm={12} controlId="formHorizontalEmail">
                                        <div className="mt-1">
                                            <ButtonToolbar>
                                                <Button style={{minWidth:'60px',marginRight:'1pt'}} className="ml-auto" variant="success" variant="primary" onClick={this.handleSearch}>Search</Button>
                                            </ButtonToolbar>
                                        </div>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                        <Card.Header>LIST OF ROLES</Card.Header>
                                        <div className="mt-1">
                                            <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.roleList }
                                                columns = { usersCol }
                                                selectRow = { selectRow }
                                                cellEdit = { cellEditFactory({ 
                                                        mode: 'dbclick', 
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => { 
                                                            this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                loading={true}
                                                wrapperClasses="table-responsive"
                                                rowClasses="noser-table-row-class"
                                                pagination={ paginationFactory({sizePerPageRenderer})}
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            />
                                        </div>
                                        
                                    </Form.Group>
                                </Form.Row>
                                <div className="mt-1">
                                    <ButtonToolbar>
                                        <Button style={{minWidth:'60px',marginRight:'1pt'}} className="ml-auto" variant="success" variant="success" onClick={this.handleSubmit} disabled={this.state.disableSaveBtn}>Save</Button>
                                        <Button style={{minWidth:'60px'}} variant="primary" variant="primary" onClick={this.handleModalShow}>Create</Button>
                                    </ButtonToolbar>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
                <CreateRole 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div>
        )
    }
}

export default Roles;