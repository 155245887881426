import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker, LeaveModal, Tabs, Tab,Modal,
    sizePerPageRenderer
} 
from '../../noser-hris-component';

class DiagnoseddiseaseCreate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo  :   [],
            isloading :   false,
            isshow    :   false,
            alerttype :   "",
            message   :   "",
            color     :   "",
            fade      :   true,
            name:""
            
        }
         
    }

    componentDidMount() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        sleep(1000).then(() => {
        this.setState({isLoading:false})}) 
       
    }

    onChangeDiagnosedName(e){
        console.log(e)
        this.setState({name:e.target.value})
    }

    handleSaveClick = event => {
        const diseaseParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "CompanyId":this.state.userinfo.companyId,
            "Name":this.state.name,
        }
        console.log(diseaseParams)
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/AddDisease",  diseaseParams
            )
            .then(res => {
                const data = res.data;
                console.log("Get data");
                console.log(data)
                if(data.status=="1")
                {
                    this.setState(
                        { 
                            isshow:true,
                            color:"success",
                            message:data.message ,
                            fade:true
                        });
                    
                    }
                else
                {
                    this.setState(
                        { 
                            isshow:true,
                            color:"danger",
                            message:data.message,
                            fade:true
                        });
                }
            })
        
    }

    render() {
        

        return(

            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className="modal-90w"
                >
                <Modal.Header closeButton className="card-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Diagnosed Disease
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                    <Form>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                        <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Col sm={6}>
                            <Form.Control 
                                    type="text"
                                    name="name"
                                    value={this.state.name}
                                    placeholder="NAME"
                                    onChange={this.onChangeDiagnosedName.bind(this)} 
                                    autoComplete="off"
                                />
                            </Col>

                        </Form.Row>
                        <ButtonToolbar sm={12} className="mt-3">
                            <Button variant="primary"  onClick={this.handleSaveClick}  style={{minWidth:'60px', marginRight:"5px"}}>
                                Submit
                            </Button>
                            <Button variant="danger" href="/Diagnoseddisease"  style={{minWidth:'60px',}}>
                                Back
                            </Button>
                        </ButtonToolbar>
                        </Form>
                    </Container>
                </Modal.Body>
               
                <NoserLoading show={this.state.isloading} />
            </Modal>            
            
        )
    }
}

export default DiagnoseddiseaseCreate;