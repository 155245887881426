import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Tabs, Tab,
    Redirect
} 
from '../../noser-hris-component';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
class ManageEmployeeApprovals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            disablebutton   :   true,
            navigateApproval:   false,
            navigateView    :   false,
            
            clientId        :   "",
            clientName      :   "",
            locationId      :   "",
            employeeId      :   "",
            statusId        :   "-1",
            statusName      :   "",
            clientDDL       :   [],
            locationDDL     :   [],
            employeeDDL     :   [],
            statusDDL       :   [
                {"id":"","name":"ALL"},
                //{"id":"1","name":"PENDING FOR APPROVAL (AM)"},
                {"id":"2","name":"FOR APPROVAL"},
                //{"id":"3","name":"REJECTED BY AM"},
                {"id":"4","name":"APPROVED"},
                {"id":"5","name":"REJECTED"}
            ],
            employeeGRID    :   [],

            clientLst       :   [],
            branchLst       :   [],
            yesNoLst        :   [
                {"value":"0","label" : "NO"},
                {"value":"1","label" : "YES"}
            ],
            nationalityLst  :   [],
            religionLst     :   [],
            civilStatusLst  :   [
                {"label":"SINGLE","value":"1"},
                {"label":"MARRIED","value":"2"},
                {"label":"DIVORCED","value":"3"},
                {"label":"SEPERATED","value":"4"}

            ],
            arrangementLst  :   [],
            bloodTypeLst    :   [],
            positionLst     :   [],
            payrollTypeLst  :   [],
            payrollModeLst  :   [],
            employeeStatusLst:  [],
            paycardTypeLst  :   []
        }
    } 
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        let filterObj = JSON.parse(sessionStorage.getItem("employeeapprovalFilter_"  + this.state.userinfo.userId + this.state.userinfo.employeeId))
        console.log(filterObj)
        this.GetEmployees()
        // this.state.statusId = this.state.userinfo.roleId === "1" ||  
        //                         this.state.userinfo.roleId === "8" ? "4" : "2"
        //this.GetSubmittedEmployees()
        //this.state.disablebutton = this.state.userinfo.roleId!=="1" || this.state.userinfo.roleId!=="8" ? true : false
        if(filterObj!==null){
            this.state.clientId = filterObj.clientId
            this.state.clientName = filterObj.clientName
            this.state.statusId = filterObj.statusId
            this.state.statusName = filterObj.statusName
            this.GetSubmittedEmployees()
        }



        // var array = [
        //     { Id: "001", qty: 1 }, 
        //     { Id: "002", qty: 2 }, 
        //     { Id: "001", qty: 2 }, 
        //     { Id: "003", qty: 4 }
        //   ];

        // var result = [];
        // array.reduce(function(res, value) {
        // if (!res[value.Id]) {
        //     res[value.Id] = { Id: value.Id, qty: 0 };
        //     result.push(res[value.Id])
        // }
        // res[value.Id].qty += value.qty;
        // return res;
        // }, {});
        
        // console.log(result)
        //alert('load for approval')
    }
    componentWillUnmount(){
        //alert('unload for approval')
        //alert(window.location.href)
        //console.log(document.url)
        //console.log(document.referrer)
        //alert('1')
        // let uri = window.location.href.toString().split('/')
        // console.log(uri[3])
        // if(uri[3]!=="employeeapproval" || uri[3]!=="manageemployeeapprovals"){
        //     alert('2')
        //     sessionStorage.setItem("employeeapprovalFilter_"  + this.state.userinfo.userId + this.state.userinfo.employeeId, null)
        // }
    }
    // GetClients = async() =>{
    //     this.setState({isloading:true})
    //     const params = {
    //         "IpAddress":"0.0.0.0",
    //         "CompanyId":this.state.userinfo.clientId,
    //         "UserId":this.state.userinfo.userId,
    //     }
    //     await axios
    //     .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
    //     .then(res => {
    //         const data = res.data;
    //         this.setState({clientDDL : data.clients ? data.clients : []}) 
    //     })
    //     .catch(error=>{
    //         this.setState({
    //             isloading   :   false,
    //             alerttype   :   "Error!",
    //             isshow      :   true,
    //             color       :   "danger",
    //             message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
    //             fade        :   true
    //         })
    //     })
    //     this.GetSubmittedEmployees()
    // }
    GetEmployees = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeList", params)
        .then(res => {
            const data = res.data;
            this.setState({employeeDDL : data.employees,isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        
    }
    
    handleChangeClient = (e) =>{
        //this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0)
        {
            this.state.clientId= ''
            this.state.clientName=''
            return
        }
        this.state.clientId=e[0].id
        this.state.clientName=e[0].name
        this.GetEmployees()
    }
    handleChangeStatus = (e) =>{
        //this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0)
        {
            this.state.statusId=""
            this.state.statusName=""
            return
        }
        this.state.statusId=e[0].id
        this.state.statusName=e[0].name
    }
    handleChangeEmployee = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.employeeId= ''
            return
        }
        this.state.employeeId= e[0].id
    }
    
    GetSubmittedEmployees = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "SubmittedBy":this.state.userinfo.employeeId,
            "CompanyId":this.state.userinfo.companyId,
            "LocationId":this.state.locationId,
            "EmployeeId":this.state.employeeId,
            "StatusId":this.state.statusId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/GetManningSubmittedEmployeeForApprovals", params)
        .then(res => {
            const data = res.data;
            this.setState({employeeGRID : data.manningEmployees ? data.manningEmployees : [], isloading:false})
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }  
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleSearchClick = () =>{
        this.GetSubmittedEmployees()
    }
    handleApprovalClick = (row) =>{
        var filterObj = {
            "CompanyId":this.state.userinfo.companyId,
            "clientName":this.state.clientName,
            "statusId":this.state.statusId,
            "statusName":this.state.statusName
        }
        sessionStorage.setItem("employeeapproval_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
        sessionStorage.setItem("employeeapprovalFilter_"  + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(filterObj))
        
        if(row.statusId==="2"){
            this.setState({approvalURL: true})
        }
        else{
            this.setState({viewURL: true})
        }
    }
    render(){
        const { approvalURL,viewURL } = this.state;
        if(approvalURL){
            return <Redirect to="/employeeapproval" push={true}/>
        }
        if(viewURL){
            return <Redirect to="/employeeinformation" push={true}/>
        }
        let roleId = this.state.userinfo.roleId
        const employeeCol = [
            {
                dataField: 'status',
                text: 'STATUS',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'12%'}},
                style:{textAlign:'center',width:'12%'},
            },
            {
                dataField: 'department',
                text: 'DEPARTMENT',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'12%'}},
                style:{textAlign:'left',width:'12%'}
            },
            {
                dataField: 'submittedByName',
                text: 'SUBMITTED BY',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%'}},
                style:{textAlign:'left',width:'15%'}
            },
            {
                dataField: 'submittedDate',
                text: 'DATE SUBMITTED',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'}
            },
            {
                dataField: 'approvedByName',
                text: 'APPROVED / REJECTED BY',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%'}},
                style:{textAlign:'left',width:'15%'}
            },
            {
                dataField: 'approvedDate',
                text: 'APPR / REJ DATE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'}
            },
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE NAME',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'25%'}},
                style:{textAlign:'left',width:'25%'}
            },
            /* {
                dataField: 'clientName',
                text: 'CLIENT',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'31%'}},
                style:{textAlign:'left',width:'31%'}
            }, */
            {
                dataField: 'action',
                text: 'ACTION',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'7%'}
                },
                style:{textAlign:'center',width:'7%'},
                formatter   :   (cell, row, isSelect) => {
                    if(row.statusId==="2")
                    return (
                        <Button 
                            onClick={e => this.handleApprovalClick(row)}
                            style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link"
                    >APPROVE</Button>
                    );
                    else{
                        return (
                            <Button 
                                onClick={e => this.handleApprovalClick(row)}
                                style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                                variant="link"
                        >VIEW</Button>);
                    }
                },
                
            }
        ]
        
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable=true
                this.state.employeeGRID.map(function(item,i){
                    if(item.id===row.id)
                    {
                        if(row.statusId==="2"){
                            item.isModified = isSelect ? "1" : "0"
                        }
                    }
                    if (item.isModified=="1")
                    disable =  false
                })
                //disable = this.state.userinfo.roleId!=="1" && this.state.userinfo.roleId!=="8" ? true : false
                //this.setState({disablebutton:disable})
            }
        };
        const { ExportCSVButton } = CSVExport;
        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>Manning >> Manage Employees Approvals</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    CLIENT : 
                                    </Form.Label>
                                    <Col sm={11}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientDDL}
                                            //placeholder="Select Client"
                                            selected={[this.state.clientName]}
                                        />
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    EMPLOYEE : 
                                    </Form.Label>
                                    <Col sm={11}>
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.handleChangeEmployee}
                                            options={this.state.employeeDDL}
                                            //placeholder="Select Employee"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    STATUS : 
                                    </Form.Label>
                                    <Col sm={11}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeStatus}
                                            options={this.state.statusDDL}
                                            //placeholder="Select Status"
                                            selected={[this.state.statusName]}
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar className="mt-1">
                                    <Button variant="primary" className="ml-auto noser-button-mr1" onClick={ this.handleSearchClick }>Search</Button>
                                    <Button variant="success" className="noser-button" href="/manageemployee">Create</Button>
                                </ButtonToolbar>
                                <div className="mt-2">
                                <ToolkitProvider
                                        keyField="id"   
                                        data={ this.state.employeeGRID }
                                        columns = { employeeCol }
                                        exportCSV={ {
                                            fileName: "Manage Employee Approvals.csv",
                                            noAutoBOM: false,
                                            separator: ',',
                                            blobType: "text/plain;charset=utf-8,%EF%BB%BF"
                                        } }
                                        >
                                        {
                                            props => (
                                            <div>
                                                
                                                <hr />
                                                <ExportCSVButton disabled={this.state.disableExport} className="btn btn-success" { ...props.csvProps }>Export CSV!!</ExportCSVButton>
                                                <Card.Header>EMPLOYEE APPROVAL LIST</Card.Header>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.employeeGRID }
                                                    columns = { employeeCol }
                                                    selectRow = { selectRow }
                                                    cellEdit = { cellEditFactory({ 
                                                            mode: 'dbclick', 
                                                            blurToSave: true,
                                                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                                                
                                                            }
                                                        })
                                                    }
                                                    rowClasses="noser-table-row-class no-checkbox"
                                                    striped
                                                    hover
                                                    condensed
                                                    loading={true}
                                                    wrapperClasses="table-responsive"
                                                    pagination={ paginationFactory({sizePerPageRenderer})}
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    
                                                />
                                            </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div>
        )
    }
}

export default ManageEmployeeApprovals;