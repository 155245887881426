import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker, LeaveModal, Tabs, Tab,
    sizePerPageRenderer, type,Type
} 
from '../../noser-hris-component';

class View extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo  :   [],
            isloading :   false,
            isshow    :   false,
            alerttype :   "",
            message   :   "",
            color     :   "",
            fade      :   true,
            selected: [],

            firstName:"",
            nickName:"",
            middleName:"",
            homePhoneNumber:"",
            lastName:"",
            mobileNumber:"",
            emailAddress:"",
            emergencyContactName:"",
            emergencyContactRelationship:"",
            emergencyContactNumber:"",
            changes:"",
            employeeList:[],
            selectedEmployeeId : "",
            selectedProfileId :"",
            employeeName:"",
            employeeId:"",
            civilStatus:"",
            religion:"",
            religionId:"",
            employeeAddresses:[],
            employeeEducations:[],
            employeeFamilyBackgrounds:[],
        }
         
    }

    componentDidMount() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        
        sleep(1000).then(() => {
            this.setState({isLoading:false})}) 

        this.state.client = this.props.location.params.data.client
        this.state.employeeName = this.props.location.params.data.employeeName
        this.state.firstName = this.props.location.params.data.firstName
        this.state.middleName = this.props.location.params.data.middleName
        this.state.nickName = this.props.location.params.data.nickName
        this.state.lastName = this.props.location.params.data.lastName
        this.state.mobileNumber = this.props.location.params.data.mobileNumber
        this.state.homePhoneNumber = this.props.location.params.data.homePhoneNumber
        this.state.emergencyContactName = this.props.location.params.data.emergencyContactName
        this.state.civilStatus = this.props.location.params.data.civilStatus
        this.state.religion = this.props.location.params.data.religion
        this.state.emergencyContactNumber = this.props.location.params.data.emergencyContactNumber
        this.state.emergencyContactRelationship = this.props.location.params.data.emergencyContactRelationship
        this.state.selectedClient = this.props.location.params.data.client
        this.state.emailAddress = this.props.location.params.data.emailAddress
        this.state.employeeAddresses = this.props.location.params.data.employeeAddresses
        this.state.employeeEducations = this.props.location.params.data.employeeEducations
        this.state.employeeFamilyBackgrounds = this.props.location.params.data.employeeFamilyBackgrounds
         //console.log("test")
        //console.log(this.props.location.params.data.employeeAddresses)
    }

    onChangeFirstName(e){
        //console.log(e)
        this.setState({firstName:e.target.value})
    }
   
    onChangeClient(e){
        this.setState({client:e.target.value})
    }

    onChangeEmployeeName(e){
        this.setState({employeeName:e.target.value})
    }
 
    
    handleChangeEmployee = (e) => {
        //console.log(e)
            if (e.length == 0) {
                this.setState({employeeName:"", firstName: "", nickName:"", middleName:"",
                lastName:"", homePhoneNumber:"", mobileNumber:"", civilStatus:"",
                emailAddress:"", emergencyContactName:"", emergencyContactRelationship:"",
                emergencyContactNumber:"", changes:"" ,religion:"", employeeAddresses:""
            })
           
        } else {
            this.state.employeeName = this.props.location.params.data.employeeName
            this.state.firstName = this.props.location.params.data.firstName
            this.state.middleName = this.props.location.params.data.middleName
            this.state.nickName = this.props.location.params.data.nickName
            this.state.lastName = this.props.location.params.data.lastName
            this.state.mobileNumber = this.props.location.params.data.mobileNumber
            this.state.homePhoneNumber = this.props.location.params.data.homePhoneNumber
            this.state.emergencyContactName = this.props.location.params.data.emergencyContactName
            this.state.selectedCivilStatus = this.props.location.params.data.civilStatus
            this.state.emergencyContactNumber = this.props.location.params.data.emergencyContactNumber
            this.state.emergencyContactRelationship = this.props.location.params.data.emergencyContactRelationship
            this.state.selectedClient = this.props.location.params.data.client
            this.state.emailAddress = this.props.location.params.data.emailAddress
            this.state.religion = this.props.location.params.data.religion
            // this.state.employeeAddresses = this.props.location.params.data.employeeAddresses
           

           
        }
    } 

    onChangeNickName(e){
        //console.log(e)
        this.setState({nickName:e.target.value})
    }

    onChangeMiddleName(e){
        //console.log(e)
        this.setState({middleName:e.target.value})
    }

    onChangeLastName(e){
        //console.log(e)
        this.setState({lastName:e.target.value})
    }

    onChangeHomePhoneNumber(e){
        //console.log(e)
        this.setState({homePhoneNumber:e.target.value})
    }

    onChangeMobileNumber(e){
        //console.log(e)
        this.setState({mobileNumber:e.target.value})
    }

    onChangeReligion(e){
        //console.log(e)
        this.setState({religion:e.target.value})
    }

    onChangeEmailAddress(e){
        //console.log(e)
        this.setState({emailAddress:e.target.value})
    }

    onChangeCivilStatus(e){
        //console.log(e)
        this.setState({civilStatus:e.target.value})
    }

    onChangeEmergencyContactName(e){
        //console.log(e)
        this.setState({emergencyContactName:e.target.value})
    }

    onChangeEmergencyContactRelationship(e){
        //console.log(e)
        this.setState({emergencyContactRelationship:e.target.value})
    }

    onChangeEmergencyContactNumber(e){
        //console.log(e)
        this.setState({emergencyContactNumber:e.target.value})
    }

    onChangeChanges(e){
        //console.log(e)
        this.setState({changes:e.target.value})
    }


    render() {
        const addresses = [
            {
                dataField: 'typeId',
                text: 'TYPE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '15%'};
                }
            },
            {
                dataField: 'houseNumber',
                text: 'HOUSE #',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '10%'};
                }
            },
            {
                dataField: 'streetName',
                text: 'STREET NAME',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '15%'};
                }
            },
            {
                dataField: 'barangay',
                text: 'BARANGAY',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '15%'};
                }
            },
            {
                dataField: 'city',
                text: 'CITY',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '20%'};
                }
            },
            {
                dataField: 'region',
                text: 'REGION',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '15%'};
                }
            },
            {
                dataField: 'province',
                text: 'PROVINCE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '15%'};
                }
            },
        {
            dataField: 'postalCode',
            text: 'POSTAL CODE',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: '15%'};
            }
        }]

        const selectRowEmployeeAddresses = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let restLst = this.state.employeeAddresses
                this.state.employeeAddresses.map(function(item,idx){
                    
                    if(idx==rowIndex)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        }; 

        const FamilyBackground = [
            {
                dataField: 'roleId',
                text: 'ROLE ID',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '15%'};
                }
            },
            {
                dataField: 'name',
                text: 'NAME',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '40%'};
                }
            },
            {
                dataField: 'age',
                text: 'AGE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '10%'};
                }
            },
            {
                dataField: 'occupation',
                text: 'OCCUPATION',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '40%'};
                }
            },
            {
            dataField: 'company',
            text: 'COMPANY',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: '40%'};
            }
        }] 
        
        // const selectRowFamilyBackground = {
        //     mode: 'checkbox',
        //     clickToSelectAndEditCell: true,
        //     onSelect: (row, isSelect, rowIndex, e) => {

        //         let BackgroundLst = this.state.familyList
        //         this.state.familyList.map(function(item,idx){
                    
        //             if(idx==rowIndex)
        //             {
        //                 item.isDeleted = isSelect ? "1" : "0"
        //                 item.isModified = isSelect ? "1" : "0"
        //             }
        //         })
        //       }
        // };

        const education = [
            {
                dataField: 'levelId',
                text: 'LEVEL ID',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '15%'};
                }
            },
            {
                dataField: 'schoolName',
                text: 'SCHOOL NAME',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '40%'};
                }
            },
            {
                dataField: 'course',
                text: 'COURSE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '35%'};
                }
            },
            {
                dataField: 'startYear',
                text: 'START YEAR',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '35%'};
                }
            },
            {
                dataField: 'endYear',
                text: 'END YEAR',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '35%'};
                }
            },
            {
                dataField: 'honorRecieved',
                text: 'HONOR RECIEVED',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '35%'};
                }
            }] 
        
        // const selectRow = {
        //     mode: 'checkbox',
        //     clickToSelectAndEditCell: true,
        //     onSelect: (row, isSelect, rowIndex, e) => {

        //         let EducationLst = this.state.educationList
        //         this.state.educationList.map(function(item,idx){
                    
        //             if(idx==rowIndex)
        //             {
        //                 item.isDeleted = isSelect ? "1" : "0"
        //                 item.isModified = isSelect ? "1" : "0"
        //             }
        //         })
        //       }
        // };
        
        return(
            <div>
                <Banner />
                    <Container className="mt-5">
                        <Card>
                            <Card.Body>
                                <Form>
                                <Card.Header>I. GENERAL INFORMATION</Card.Header>
                                    <Form.Row>
                                        <Col sm={12} className="mt-2">
                                            <label style={{fontSize:"10px"}}>   
                                                EMPLOYEE
                                            </label>
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.employeeName}
                                                //placeholder="FIRST NAME"
                                                onChange={this.onChangeEmployeeName.bind(this)} 
                                                input disabled={true}
                                                autoComplete="off"
                                            /> 
                                        </Col> 
                                    </Form.Row>
                                   <Form.Row>
                                        <Col sm={6} className="mt-2">
                                            <label style={{fontSize:"10px"}}>
                                                FIRST NAME
                                            </label>
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.firstName}
                                                //placeholder="FIRST NAME"
                                                onChange={this.onChangeFirstName.bind(this)} 
                                                input disabled={true}
                                                autoComplete="off"
                                            /> 
                                        </Col>
                                        <Col sm={6} className="mt-2">
                                            <label style={{fontSize:"10px"}}>
                                                MIDDLE NAME
                                            </label>
                                            <Form.Control 
                                                type="text"
                                                name="middleName"
                                                value={this.state.middleName}
                                                input disabled={true}
                                                onChange={this.onChangeMiddleName.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-2">
                                            <label style={{fontSize:"10px"}}>
                                                LAST NAME
                                            </label>
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.lastName}
                                                input disabled={true}
                                                onChange={this.onChangeLastName.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Row> 
                                    <Form.Row>
                                        <Col sm={6} className="mt-2">
                                            <label style={{fontSize:"10px"}}>
                                                HOME PHONE NUMBER
                                            </label>
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.homePhoneNumber}
                                                input disabled={true}
                                                onChange={this.onChangeHomePhoneNumber.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-2">
                                            <label style={{fontSize:"10px"}}>
                                                MOBILE NUMBER
                                            </label>
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.mobileNumber}
                                                input disabled={true}
                                                onChange={this.onChangeMobileNumber.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-2">
                                            <label style={{fontSize:"10px"}}>
                                                RELIGION
                                            </label>
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.religion}
                                                input disabled={true}
                                                onChange={this.onChangeReligion.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-2">
                                            <label style={{fontSize:"10px"}}>
                                                EMAIL ADDRESS
                                            </label>
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.emailAddress}
                                                input disabled={true}
                                                onChange={this.onChangeEmailAddress.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-2">
                                            <label style={{fontSize:"10px"}}>
                                                CIVIL STATUS
                                            </label>
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.civilStatus}
                                                input disabled={true}
                                                onChange={this.onChangeCivilStatus.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col sm={6} className="mt-2">
                                            <label style={{fontSize:"10px"}}>
                                                EMERGENCY CONTACT NAME
                                            </label>
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.emergencyContactName}
                                                input disabled={true}
                                                onChange={this.onChangeEmergencyContactName.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-2">
                                            <label style={{fontSize:"10px"}}>
                                                EMERGENCY CONTACT RELATIONSHIP
                                            </label>
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.emergencyContactRelationship}
                                                input disabled={true}
                                                onChange={this.onChangeEmergencyContactRelationship.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-2">
                                            <label style={{fontSize:"10px"}}>
                                                EMERGENCY CONTACT NUMBER
                                            </label>
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.emergencyContactNumber}
                                                input disabled={true}
                                                onChange={this.onChangeEmergencyContactNumber.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Row> &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Card.Header >ADDRESS</Card.Header>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.employeeAddresses}
                                                columns = { addresses}
                                                cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                            />
                                            
                                        </Col>
                                    </Form.Group>
                                    <Card.Header>II. FAMILY BACKGROUND</Card.Header>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <BootstrapTable
                                                caption={Noser.TableHeader({title:"FAMILY BACKGROUND"})}
                                                keyField = "id"
                                                data = { this.state.employeeFamilyBackgrounds}
                                                columns = { FamilyBackground}
                                                //selectRow = { selectRowFamilyBackground }
                                                cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                                rowClasses="noser-table-row-class"                          
                                                striped
                                                hover
                                                condensed
                                            />
                                            
                                        </Col>
                                    </Form.Group>
                                    <Card.Header>III. EDUCATION</Card.Header>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.employeeEducations}
                                                columns = { education}
                                                //selectRow = { selectRow }
                                                cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                            />
                                            
                                        </Col>
                                    </Form.Group>
                                    <ButtonToolbar sm={12} className="mt-3">
                                        {/* <Button style={{minWidth:"60px",marginRight:"1pt"}} variant="success" className="ml-auto" onClick={this.handleSaveClick}>
                                            Submit
                                        </Button>&nbsp;&nbsp; */}
                                        <Button style={{minWidth:"60px",marginRight:"1pt"}} className="ml-auto" variant="danger" href="/ManningBatchApprove">
                                            Back
                                        </Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                    <NoserLoading show={this.state.isLoading} />
            </div> 
            
        )
    }
}

export default View;