import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Redirect
} 
from '../../noser-hris-component';
import SourcingModal from './Modal/SourcingModal';


class ApplicationFormSourcing extends Component {
    state = {
        userinfo        :   [],
        isloading       :   false,
        isshow          :   false,
        alerttype       :   "",
        message         :   "",
        color           :   "",
        fade            :   true,
        disableBtn      :   true,
        openModal       :   false,
        applicationGrid :   [],
        applicationDDL  :   [],
        clientDDL       :   [],
        employeeDDL     :   [],
        employeeList    :   [],

        clientId        :   "",
        referenceNo     :   "",
        statusId        :   "",
        tinNumber       :   "",
        applicationFormId:  "",
    }

    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        this.child.initialize(e)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.GetApplications()
    }
    GetClients = async()=> {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  clientParams)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL:data.clients});
            this.GetApplicationFormFilter()
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    GetApplicationFormFilter = async()=> {
        //this.setState({isloading:true})
        const params = {
            "IpAddress" :   "0.0.0.0",
            "ClientId"  :   this.state.clientId,
            "UserId"    :   this.state.userinfo.userId,
            "TypeId"    :   "8",
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationFormFilter",  params)
        .then(res => {
            const data = res.data;
            console.log("data")
            console.log(data)
            this.setState({employeeList:data.applicationForms,employeeDDL:data.applicationForms,isloading:false})
            this.GetApplications();
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }
    GetApplications = async() => {
        //this.setState({isloading:true,disableBtn:true});
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ReferenceNo":this.state.referenceNo,
            "PositionId":"",
            "StatusId":this.state.statusId,
            "TINNumber":this.state.tinNumber,
            "ApplicationFormId" : this.state.applicationFormId,
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Pooling/GetSourcings",  params)
        .then(res => {
             const data = res.data
             console.log(data)
            if(data.status=="1"){
                this.setState({isloading:false,applicationGrid:data.sourcings});
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                });
            }
         })
         .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    handleChangeClient = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length == 0) {
            this.state.clientId     =   ""
            this.state.clientName   =   ""
            this.state.employeeDDL  =   this.state.employeeList
            return
        }
        this.state.clientId     =   e[0].id
        this.state.clientName   =   e[0].name
        this.state.employeeDDL  =   this.state.employeeList.filter(x=>x.clientId===e[0].id);
    }
    handleChangeEmployee = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length == 0) {
            this.state.applicationFormId    =   ""
            this.state.profileId            =   ""
            this.state.referenceNo          =   ""
            this.state.tinNumber            =   ""
            this.state.statusId             =   ""
            return
        }
        this.state.applicationFormId    =   e[0].applicationFormId
        this.state.profileId            =   e[0].profileId
        this.state.referenceNo          =   e[0].referenceNo
        this.state.tinNumber            =   e[0].tinNumber
        this.state.statusId             =   e[0].statusId
    }
    handleSearchClick = event => {
        this.GetApplications()
    }

    GridDataModified(oldValue, newValue, regionid, column) {
        // let isDisable = true
        // this.state.regionListGrid.map(function(item,i) {
        //     if (item.id===regionid)
        //         item.isModified = newValue!=oldValue ? "1" : "0"
        //     if(item.isModified=="1")
        //         isDisable = false
        // })
        // this.setState({disableBtn:isDisable})
    }
    handleClickedEdit = (row) =>{
        row.isPooling = "0"
        sessionStorage.setItem("viewtoto_" + this.state.userinfo.userId + this.state.userinfo.employeeId, null)
        sessionStorage.setItem("viewapplicationform_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
        this.setState({navigate:true})
    }
    render() {
        const {navigate} = this.state;
        if (navigate) {
            return <Redirect to="/applicationformview" push={true} />;
        } 
        const cols = [
            {
                dataField: 'applicationFormNo',
                text: 'REFERENCE NO',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'center',width:'8%'},
                sort:true
            },
            {
                dataField: 'employeeName',
                text: 'FULL NAME',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'18%' }},
                style:{textAlign:'left',width:'18%'},
                sort:true,
                formatter   :   (cell, row, isSelect) => {
                    if(row.statusId!=="19"){
                        return (
                            <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                                variant="link" onClick={e => this.handleClickedEdit(row)}
                        >{cell}</Button>
                        );
                    }
                    else{
                        return (
                            cell
                        );
                    }
                }
            },
            {
                dataField: 'position',
                text: 'POSITION',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'18%' }},
                style:{textAlign:'left',width:'18%'},
                sort:true
            },
            {
                dataField: 'client',
                text: 'CLIENT',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'30%' }},
                style:{textAlign:'left',width:'30%'},
                sort:true
            },
            {
                dataField: 'dateApplied',
                text: 'DATE APPLIED',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'center',width:'8%'},
                sort:true
            },
            {
                dataField: 'age',
                text: 'AGE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'6%' }},
                style:{textAlign:'center',width:'6%'},
                sort:true
            },
            {
                dataField: 'status',
                text: 'STATUS',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'6%' }},
                style:{textAlign:'center',width:'6%'},
                sort:true
            },
            {
                dataField: '',
                text: 'ACTION',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'6%' }},
                style:{textAlign:'center',width:'6%'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link" onClick={e => this.handleModalShow(row)}
                        >Process</Button>
                        );
                },
            },
        ]
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            hideSelectAll: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                // let isDisable = true
                // this.state.regionListGrid.map(function(item,i){
                //     if(item.id===row.id)
                //     {
                //         item.isDeleted = isSelect ? "1" : "0"
                //         item.isModified = isSelect ? "1" : "0"
                //     }
                //     if(item.isDeleted=="1")
                //         isDisable=false
                // })
                // this.setState({disableBtn:isDisable})
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
        }
    };

    return(
            <div>
                <Banner />
                    <Container className="themed-container" fluid={true}>
                        <Card className="mt-5">
                            <Card.Header>RECRUITMENT >> SOURCING</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            {/* <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label> */}
                                            <Typeahead 
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeClient}
                                                options={this.state.clientDDL}
                                                placeholder="Select Client" 
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            {/* <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label> */}
                                            <Typeahead 
                                                labelKey='memberName'
                                                id="basic-example"
                                                onChange={this.handleChangeEmployee}
                                                options={this.state.employeeDDL}
                                                placeholder="Select Employee" 
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <ButtonToolbar className="mt-1">
                                        <Button variant="primary" className="ml-auto noser-button" onClick={ this.handleSearchClick }>
                                            Search
                                        </Button>
                                    </ButtonToolbar>
                                    <div className="mt-2">
                                    <Card.Header>LIST OF APPLICANTS</Card.Header>
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.applicationGrid }
                                            columns = { cols }
                                            selectRow = { selectRow }
                                            cellEdit = { cellEditFactory({ 
                                                    mode: 'dbclick', 
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => { 
                                                        this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                            striped
                                            hover
                                            condensed
                                            loading={true}
                                            rowClasses="noser-table-row-class no-checkbox"
                                            pagination={ paginationFactory({sizePerPageRenderer})}
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        />
                                        {/* <ButtonToolbar sm={12}>
                                            <Button variant="success" className="ml-auto noser-button-mr1" disabled={this.state.disableBtn} onClick={this.handleSaveClick}>Save</Button>
                                            <Button variant="primary" className="noser-button" onClick={this.handleModalShow}>CREATE</Button>
                                        </ButtonToolbar> */}
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                <NoserLoading show={this.state.isloading} />
                <SourcingModal 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div> 
        )
    }

}

export  default ApplicationFormSourcing