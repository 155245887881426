import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Modal, DatePicker, NumberFormat, Image
} 
from '../../../noser-hris-component';

import ReactToPrint from "react-to-print";
import paramountHeader from '../../../icon/contract-header.PNG'
import paramountFooter from '../../../icon/contract-footer.PNG'

class ComponentToPrint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,      
            disable         :   true,

            issueDate       :   "",
            employeeName    :   "",
            address         :   "",
            positionName    :   "",
            branchName      :   "",
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        document.title = "RegularContract";

        let data = JSON.parse(sessionStorage.getItem("contract_" + this.state.userinfo.userId + this.state.userinfo.employeeId))
        this.setState({
            employeeName : data.employeeName,
            positionName : data.positionName,
            address : data.address,
            issueDate : moment(new Date(data.issueDate)).format('MMMM DD, YYYY'),
            branchName : data.locationName
        })
    }
    render() {
        return( <>
            <Container className="mt-0 noser-a4-body-page" fluid>
                <div className='noser-a4-page'  style={{wordBreak:'break-word',whiteSpace:'-moz-pre-wrap'}}>
                <Form>
                
               {/*  <Col style={{marginLeft:'-30px', marginTop:"50px"}} >
                    <Image src={paramountHeader} style={{width:"900px", height:"200px"}} />
                </Col> */}
                
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontWeight : "bold", fontSize: 14, marginTop:"30px" }}>
                        DATE: &nbsp;{this.state.issueDate}
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontFamily:'Century Gothic',fontWeight : "bold", fontSize: 14}}>
                        MR./MS. &nbsp;{this.state.employeeName}
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Col>
                        {/* <Form.Label row style={{fontFamily:'Century Gothic',fontWeight : "bold", fontSize: 14, marginTop:"-10px",whiteSpace: "pre-wrap" }}>
                        ADDRESS: &nbsp;
                        <span style={{textDecoration:'underline',fontWeight : "bold", fontSize: 14, marginTop:"-10px" }}>
                            {this.state.address}
                        </span>
                        </Form.Label>   */}
                        <div>
                            <div style={{width:"80px",float:"left",marginRight:"2px"}}>
                            <span style={{fontFamily:'Century Gothic',fontWeight : "bold", fontSize: 14, marginTop:"-10px",whiteSpace: "pre-wrap" }}>
                            ADDRESS : 
                            </span>
                            </div>
                            <div style={{width:"700px",float:"left",height:"70px",whiteSpace: "pre-wrap"}}>
                                <span style={{textDecoration:'underline',fontWeight : "bold", fontSize: 14, marginTop:"-10px",whiteSpace: "pre-wrap" }}>
                                {this.state.address}
                            </span> 
                            </div>
                        </div>
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-5">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontFamily:'Century Gothic',fontWeight : "bold", fontSize: 14, marginTop:"30px" }}>
                        RE: Regular Employment
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontFamily:'Century Gothic',fontSize: 14, marginTop:"30px" }}>
                        Dear Member,
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{ fontFamily:'Century Gothic',textAlign:'justify',fontSize: 14,whiteSpace: "pre-wrap" }}>
                        This confirms that you, a member of  Human Resource Multi-Purpose Cooperative (the “Cooperative”), 
                        are under regular employment of the Cooperative under the following terms and conditions.
                        </p>
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <ol type="1">
                            <p>
                            <li className="no-text-transform-li" style={{ fontFamily:'Century Gothic',textAlign:'justify', marginLeft:"60px",fontSize: 14,whiteSpace: "pre-wrap"}}>
                            <span className="no-text-transform" style={{fontFamily:'Century Gothic',fontWeight:'bold',textAlign:'justify',fontSize: 14,whiteSpace: "pre-wrap"}}>
                            Commencement Date: 
                            </span>&nbsp;
                            Your regular employment had commenced or shall commence on &nbsp;
                            <span className="no-text-transform" style={{fontFamily:'Century Gothic',fontWeight:'bold',textAlign:'justify',fontSize: 14,whiteSpace: "pre-wrap"}}>
                            {this.state.issueDate}.
                            </span>&nbsp;
                            </li>
                            </p>
                            
                            <p>
                            <li className="no-text-transform-li" style={{ fontFamily:'Century Gothic',textAlign:'justify', marginLeft:"60px",fontSize: 14,whiteSpace: "pre-wrap"}}>
                            <span className="no-text-transform" style={{fontFamily:'Century Gothic',fontWeight:'bold',textAlign:'justify',fontSize: 14,whiteSpace: "pre-wrap"}}>
                            Position: 
                            </span>&nbsp;
                            You are employed as &nbsp;
                            <span className="no-text-transform" style={{fontFamily:'Century Gothic',textDecoration:'underline',fontWeight:'bold',textAlign:'justify',fontSize: 14,whiteSpace: "pre-wrap"}}>
                            {this.state.positionName}. 
                            </span>&nbsp;
                            As such you will perform the duties assigned to your position and other duties that the Cooperative may assign to you from time to time.  It is expected that you shall perform your assigned duties and responsibilities to the best of your ability, along with your whole attention and energy.
                            </li>
                            </p>

                            <p>
                            <li className="no-text-transform-li" style={{ fontFamily:'Century Gothic',textAlign:'justify', marginLeft:"60px",fontSize: 14,whiteSpace: "pre-wrap"}}>
                            <span className="no-text-transform" style={{fontFamily:'Century Gothic',fontWeight:'bold',textAlign:'justify',fontSize: 14,whiteSpace: "pre-wrap"}}>
                            Place of work: 
                            </span>&nbsp;
                            You shall render services as the Cooperative’s employee at &nbsp;
                            <span className="no-text-transform" style={{fontFamily:'Century Gothic',fontWeight:'bold',textAlign:'justify',fontSize: 14,whiteSpace: "pre-wrap"}}>
                            {this.state.branchName}
                            </span>
                            </li>
                            </p>

                            <p>
                            <li className="no-text-transform-li" style={{ fontFamily:'Century Gothic',textAlign:'justify', marginLeft:"60px",fontSize: 14,whiteSpace: "pre-wrap"}}>
                            <span className="no-text-transform" style={{fontFamily:'Century Gothic',fontWeight:'bold',textAlign:'justify',fontSize: 14,whiteSpace: "pre-wrap"}}>
                            Compensation: 
                            </span>&nbsp;
                            You shall receive a monthly rate of &nbsp;
                            <span className="no-text-transform" style={{fontFamily:'Century Gothic',fontWeight:'bold',textAlign:'justify',fontSize: 14,whiteSpace: "pre-wrap"}}>
                            Confidential per month 
                            </span>&nbsp;
                            as your compensation for your services, payable on a bi-monthly basis, which in no case shall be less than the applicable minimum wage for the region where you will be assigned. 
                            Should there be any further increases in the applicable minimum wage rates, where your compensation falls below the minimum wage; 
                            the same shall be deemed to be automatically 
                            </li>
                            </p>
                            <p style={{ fontFamily:'Century Gothic',textAlign:'justify', marginLeft:"60px",fontSize: 14,whiteSpace: "pre-wrap"}}>
                            adjusted upwards to comply with the applicable minimum wage rate.
                            Your compensation shall be subject to withholding tax, if applicable, as well as SSS, HDMF, Phil health and other deductions as prescribed by law.
                            </p>
                            <p>
                            <li className="no-text-transform-li mt-5" style={{ fontFamily:'Century Gothic',textAlign:'justify', marginLeft:"60px",fontSize: 14,whiteSpace: "pre-wrap"}}>
                            <span className="no-text-transform" style={{fontFamily:'Century Gothic',fontWeight:'bold',textAlign:'justify',fontSize: 14,whiteSpace: "pre-wrap"}}>
                            Work Schedule. 
                            </span>&nbsp;
                            You are to render your services in accordance with the work schedule as the cooperative may assign to you, but in no case shall it be more than eight (8) hours per day, or forty eight (48) hours for six (6) days per week, with one (1) rest day as the cooperative may assign to you. You may be required by the cooperative to render overtime services, and for this purpose, a written authorization will be issued to you. The additional premium for the said overtime work shall be paid in accordance with law. 
                            </li>    
                            </p>
                            
                            <p>
                            <li className="no-text-transform-li" style={{ fontFamily:'Century Gothic',textAlign:'justify', marginLeft:"60px",fontSize: 14,whiteSpace: "pre-wrap"}}>
                            <span className="no-text-transform" style={{fontFamily:'Century Gothic',fontWeight:'bold',textAlign:'justify',fontSize: 14,whiteSpace: "pre-wrap"}}>
                            Transfer and Reassignments:  
                            </span>
                            It is understood that you are available for re-assignment or transfer in any work station, line of business, or service contract as may be determined as reasonable and necessary by the Cooperative.
                            </li>
                            </p>

                            <p>
                            <li className="no-text-transform-li" style={{ fontFamily:'Century Gothic',textAlign:'justify', marginLeft:"60px",fontSize: 14,whiteSpace: "pre-wrap"}}>
                            <span className="no-text-transform" style={{fontFamily:'Century Gothic',fontWeight:'bold',textAlign:'justify',fontSize: 14,whiteSpace: "pre-wrap"}}>
                            Reporting. &nbsp;
                            </span>
                            You shall report to a team leader or supervisor as may be assigned to you.
                            </li>
                            </p>

                            <p>
                            <li className="no-text-transform-li" style={{ fontFamily:'Century Gothic',textAlign:'justify', marginLeft:"60px",fontSize: 14,whiteSpace: "pre-wrap"}}>
                            <span className="no-text-transform" style={{fontFamily:'Century Gothic',fontWeight:'bold',textAlign:'justify',fontSize: 14,whiteSpace: "pre-wrap"}}>
                            Benefits. 
                            </span>&nbsp;
                            You shall be provided with all benefits as required under the Philippine Labor Code and other social legislation.
                            </li>
                            </p>
                                {/* <Col>
                                    <Image src={paramountFooter} 
                                        style={{width:"400px", 
                                                height:"100px", 
                                                marginLeft:'170px',
                                                marginTop:"140px"}}/>
                                </Col> */}
                                {/* <Col>
                                    <Image src={paramountHeader} 
                                        style={{width:"900px",
                                                height:"200px", 
                                                textAlign:"center",
                                                justifyContent:"center",
                                                marginLeft:'-75px', 
                                                marginTop:"50px"}} />
                                </Col> */}
                            <p>
                            <li className="no-text-transform-li" style={{ fontFamily:'Century Gothic',textAlign:'justify', marginLeft:"60px",fontSize: 14,whiteSpace: "pre-wrap"}}>
                            <span className="no-text-transform" style={{fontFamily:'Century Gothic',fontWeight:'bold',textAlign:'justify',fontSize: 14,whiteSpace: "pre-wrap"}}>
                            Other terms and conditions:
                            </span>
                                <ol type="a">
                                    <p>
                                    <li className="no-text-transform-li" style={{ fontFamily:'Century Gothic',textAlign:'justify', marginLeft:"60px",fontSize: 14,whiteSpace: "pre-wrap"}}>
                                    For purposes of maintaining safety, order, and security in the work premises, you shall abide by the applicable rules and regulations on workplace behavior depending on the premises where you will be working;
                                    </li>
                                    </p>

                                    <p>
                                    <li className="no-text-transform-li" style={{ fontFamily:'Century Gothic',textAlign:'justify', marginLeft:"60px",fontSize: 14,whiteSpace: "pre-wrap"}}>
                                    You shall be subject to the “conflict of interest rule,” hence, you shall not accept any benefit or payment in return for favors, discounts, and other considerations granted by suppliers, customers, and other third parties.
                                    </li>
                                    </p>
                                    
                                    <p style={{ marginLeft:'60px', fontFamily:'Century Gothic',textAlign:'justify', fontSize: 14,whiteSpace: "pre-wrap"}}>
                                    You are prohibited from accepting other employments or consultancy work from other employers who are competitors of the cooperative or its clients, 
                                    or those whose interest are otherwise in conflict with the same, during the period of your assignment and for a period of one (1) year thereafter.
                                    </p>
                                    <p>
                                    
                                    <li className="no-text-transform-li mt-5" style={{ fontFamily:'Century Gothic',textAlign:'justify', marginLeft:"60px",fontSize: 14,whiteSpace: "pre-wrap"}}>
                                    You shall at all times comply with the rules of CONFIDENTIALITY.  Thus, you shall not divulge any information you have become privy to as a result your employment.
                                    </li>
                                    </p>
                                    <p>
                                    <li className="no-text-transform-li" style={{ fontFamily:'Century Gothic',textAlign:'justify', marginLeft:"60px",fontSize: 14,whiteSpace: "pre-wrap"}}>
                                    Your membership with the cooperative means that you are a part-owner thereof but you are also an employee of the cooperative as per the ruling in Republic of the Philippines vs. Asiapro Cooperative, G.R. No. 172101, 23 November 2007. Therefore, you are not qualified to form or join the labor union or workers’ organization pertaining to any other company’s employees for the purpose of collective bargaining with such company. Any violation of this rule may result in the termination of your employment with the cooperative.
                                    </li>
                                    </p>
                                    <p>
                                    <li className="no-text-transform-li" style={{ fontFamily:'Century Gothic',textAlign:'justify', marginLeft:"60px",fontSize: 14,whiteSpace: "pre-wrap"}}>
                                    Your employment may be terminated by the cooperative for any just or authorized cause, with observance of due process in either case. In case you decide to resign, you will shall conform to the 30 day prior notice rule for the effectivity of your resignation, otherwise you shall be liable for damages.
                                    </li>
                                    </p>
                                </ol>
                            </li>
                            </p>
                        </ol>
                    </Col>                                    
                </Form.Group>
                
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{ fontFamily:'Century Gothic',textAlign:'justify',fontSize: 14,whiteSpace: "pre-wrap" }}>
                        Any violation of the foregoing terms and conditions aforementioned shall constitute as a ground for the termination of your employment.
                        </p>
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{ fontFamily:'Century Gothic',textAlign:'justify',fontSize: 14,whiteSpace: "pre-wrap" }}>
                        If you are amenable to the above terms and conditions of your Service contract, please indicate your conformity therewith by affixing your signatures at the space provide below.
                        </p>
                    </Col>                                    
                </Form.Group>
                
                <Form.Group as={Row} controlId="formPlaintextEmail" className='mt-5'>
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontFamily:'Century Gothic',fontSize: 14, whiteSpace: "pre-wrap" }}>
                        Yours truly,
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <h1  style={{backgroundColor:'#000000', width:"30%", height:"5px",marginTop:"40px" }} > </h1>
                <Form.Group as={Row} controlId="formPlaintextEmail" >
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontFamily:'Century Gothic',marginLeft:"70px",fontSize: 14, whiteSpace: "pre-wrap" }}>
                        HR Admin OIC
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{ fontFamily:'Century Gothic',fontSize: 14, whiteSpace: "pre-wrap",marginTop:"40px" }}>
                        WITH MY CONFORMITY:
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <h1  style={{backgroundColor:'#000000', width:"30%", height:"5px",marginTop:"40px" }} > </h1>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{ fontFamily:'Century Gothic',fontSize: 14 ,whiteSpace: "pre-wrap" }}>
                        Signature Over Printed Name
                        </Form.Label>
                    </Col>                         
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{ fontFamily:'Century Gothic',fontSize: 14 ,whiteSpace: "pre-wrap" }}>
                        With Receiving Copy:
                        </Form.Label>
                    </Col>                         
                </Form.Group>
                {/* <Col sm="12">
                    <Image src={paramountFooter} 
                        style={{width:"400px", 
                                height:"100px", 
                                marginLeft:'210px',
                                marginTop:"150px"}}/>
                </Col> */}
            </Form>
                </div>
            </Container>
            <NoserLoading show={this.state.isloading} />
            </>
        )
    }
}
class RegularContract extends React.Component {
    render() {
      return (
        <div style={{backgroundColor:"#FFF",height:"100%"}}>
            <div style={{width:"100%"}}>
                <Col sm="3" className="noser-button" as={Row} style={{width:"150px",
                                                height:"25px",
                                                marginLeft:"auto",
                                                marginRight:"auto"}}>
                    <ReactToPrint
                        trigger={() => <button style={{width:"150px",
                                                height:"25px",
                                                marginLeft:"auto",
                                                marginRight:"auto",
                                                color:"#ffffff",
                                                fontWeight:"bold",
                                                backgroundColor:"rgb(8, 139, 30)",
                                                border:".5pt solid rgba(17, 99, 31, 0.644)"}}>Print Preview</button>}
                        content={() => this.componentRef}
                    />
                </Col>
            </div>
            <ComponentToPrint ref={(el) => (this.componentRef = el)} />  
        </div>
      );
    }
  }
export  default RegularContract