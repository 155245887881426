import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

class EEShareReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            clientDDL : [],
            employeeDDL : [],
            positionDDL : [],
            branchDDL : [],
            dateFrom : "",
            dateTo : "",
            eeShareReportGrid : [],


        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients();
        this.GetPosition();
    }

    GetClients = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  param)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL:data.clients,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    onChangeClient = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name

        this.getEmployees();
        this.getClientLocation();
    }

    getEmployees = async() => {
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":this.state.selectedClientName
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List ");
            console.log(res.data);
            const data = res.data
            this.setState({employeeDDL : data.employees, isloading:false,}) 
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
        
    }

    onChangeBranch = (e) => {
        if(e.length == 0) {
            this.state.selectedBranchId=""
            return
        } 
        this.state.selectedBranchId = e[0].id
    }

    GetPosition = async() => {
        this.setState({isloading:true})
        const positionParams = {
            "IpAddress":"0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId":"",
            "DepartmentId":"",
            "Name":""
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  positionParams)
        .then(res => {
            const data = res.data;
            //////console.log("Get Position Name");
            //////console.log(data);
            this.setState({ positionDDL  : data.positions, isloading:false });
        })
        
    }

    getClientLocation(){
        this.setState({isloading:true})

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.selectedClientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", getParams)
        .then(res => {
            const data = res.data;
            ////////////console.log("Client Location");
            ////////////console.log(data);
            this.setState({
                branchDDL	:   res.data.locations ? res.data.locations : [],
                isloading	            :   false
            })
            /* if(data.locations.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            } */
        })
    }

    onChangeEmployees = (e) => {
        if(e.length == 0) {
            this.state.selectedEmployeeId=""
            return
        } 
        this.state.selectedEmployeeId = e[0].id
    }

    onChangePosition = (e) => {
        if(e.length == 0) {
            this.state.selectedPositionId=""
            return
        } 
        this.state.selectedPositionId = e[0].id
    }
    
    handleChangeDateFrom = date => {
        //////console.log(date)
        this.setState({
            dateFrom: date,
            isshow:false,
        })
    }

    handleChangeDateTo = date => {
        //////console.log(date)
        this.setState({
            dateTo: date,
            isshow:false,
        })
    }

    render() {
        const { ExportCSVButton } = CSVExport;

        const eeShareReportColumn = [
            {
                text	    : 'EMPLOYEE_NUMBER',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'EMPLOYEE_NAME',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'SSS_1st_CUT_OFF',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'SSS_2nd_CUT_OFF',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'SSS_3rd_CUT_OFF',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'SSS_4th_CUT_OFF',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'SSS_5th_CUT_OFF',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'TOTAL_SSS',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'PHIC_1st_CUT_OFF',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'PHIC_2nd_CUT_OFF',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'PHIC_3rd_CUT_OFF',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'PHIC_4th_CUT_OFF',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'PHIC_5th_CUT_OFF',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'TOTAL_PHIC',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'HDMF_1st_CUT_OFF',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'HDMF_2nd_CUT_OFF',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'HDMF_3rd_CUT_OFF',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'HDMF_4th_CUT_OFF',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'HDMF_5th_CUT_OFF',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'TOTAL_HDMF',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
        ] 

            const eeShareReportSelectRow = {
                mode: 'checkbox',
                clickToSelectAndEditCell: true,
                onSelect: (row, isSelect, rowIndex, e) => {
                    this.state.eeShareReportGrid.map(function(item,i){
                        if(item.id===row.id)
                        {
                            item.isDeleted = isSelect ? "1" : "0"
                            item.isModified = isSelect ? "1" : "0"
                        }
                    })
                }
            };
    
            const eeShareReportRowEvents = {
                onClick: (e, row, rowIndex) => {
                }
            };

        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>REPORT >> PAYROLL - EE SHARE REPORT</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                {/* <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClient}
                                            options={this.state.clientDDL}
                                        />
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        BRANCH
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeBranch}
                                            options={this.state.branchDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.onChangeEmployees}
                                            options={this.state.employeeDDL}
                                    />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        POSITION
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangePosition}
                                            options={this.state.positionDDL}
                                    />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        DATE FROM
                                    </Form.Label>
                                    <Col sm="2">
                                        <DatePicker
                                            ref='dateFrom'
                                            selected={this.state.dateFrom}
                                            onChange={this.handleChangeDateFrom}
                                            minDate={this.minDate}
                                            value={this.props.dateFrom}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />  
                                    </Col>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        DATE TO
                                    </Form.Label>
                                    <Col sm="2">
                                        <DatePicker
                                            ref='dateTo'
                                            selected={this.state.dateTo}
                                            onChange={this.handleChangeDateTo}
                                            minDate={this.minDate}
                                            value={this.props.dateTo}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />
                                    </Col>
                                </Form.Group>

                                <ButtonToolbar sm={12}>
                                    <Button variant="success" className="ml-auto" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>
                                <div className="mt-5">   
                                    <ToolkitProvider
                                        keyField="id"   
                                        data={ this.state.eeShareReportGrid }
                                        columns={ eeShareReportColumn }
                                        exportCSV={ {
                                            fileName: "Payroll EE Share Reports.csv",
                                        } }
                                        >
                                        {
                                            props => (
                                            <div>
                                            <Card.Header>Payroll EE Share Record</Card.Header>
                                            <BootstrapTable
                                                /* caption={Noser.TableHeader({title:"RECORD"})} */
                                                keyField = "id"
                                                data = { this.state.eeShareReportGrid }
                                                columns = { eeShareReportColumn }
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                rowClasses="noser-table-row-class no-checkbox"
                                                striped
                                                hover
                                                condensed
                                                cellEdit = { cellEditFactory({
                                                    mode: 'dbclick',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                        //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                selectRow = { eeShareReportSelectRow }
                                                rowEvents={ eeShareReportRowEvents }
                        
                                            />
                                            <ButtonToolbar>
                                                <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export</ExportCSVButton>
                                            </ButtonToolbar>
                                            </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div> 
            
        )
    }
}

export default EEShareReport;
