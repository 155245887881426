import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

class CreatePhilHealthTable extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            regionId    :   '',
            name        :   '',
            disableBtn  :   true,
            regionListDDL:  [],

           /*  basicSalary : "",
            monthlyPremium    : "",
            perosonalShare    : "",
            employerShare    : "", */

            MonthlyBasicSalary : "",
            MonthlyPremium:"",
            MBRangeFrom: "",
            MBRangeTo: "",
            PersonalShare: "",
            EmployeerShare : "",
            Percentage: "",
            EffectivityYear : "",
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
       
    };

    GetPHIC() {
        const phicParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "isDeleted" : "0"
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPHICBrackets",  phicParams)
        .then(res => {
             const data = res.data;
             console.log("data")
             console.log(data)
             this.setState({ phicList: data.brackets });
         })
    };

    componentWillUnmount() {
        this.props.onRefModal(undefined)
    };

    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            regionId    :   '',
            regionName  :   '',
            provinceId  :   '',
            name        :   '',
            disableBtn  :   true,
            regionListDDL:  [],
            provinceListDDL:[]
        })
        console.log("test w test")
    };

   

    handleSubmit = (e) =>{
        console.log("save kuno")
        this.setState({isloading:true})

        let date = new Date()
        const currentYear = new Date().getFullYear()
        let Percentage = "1%"
        const param = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "ClientName":this.state.userinfo.companyName,
            "MonthlyBasicSalary" : this.state.MonthlyBasicSalary,
            "MonthlyPremium":this.state.MonthlyPremium,
            "monthlyPremiu":this.state.MonthlyPremium,
            "MBRangeFrom": this.state.MBRangeFrom,
            "MBRangeTo": this.state.MBRangeTo,
            "PersonalShare": this.state.PersonalShare,
            "EmployeerShare" : this.state.EmployeerShare,
            "Percentage": this.state.Percentage,
            "EffectivityYear": currentYear.toString(),
            "CreatedBy": this.state.userinfo.userId,
            "isDeleted": "0"
        };

        console.log("param") 
        console.log(param)
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/AddPHICBracket",  param
            )
            .then(res => {
                const data = res.data;
                console.log(data)
                this.GetPHIC()
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true,
                    name        :   '',
                    regionId    :   '',
                    
                });
                
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true,
                    name        :   ''
                })
            })
    };

    handleModalClose = () => {
        this.props.onHide();            
    }

    handleChange = (e) =>{
        console.log(e.target.value)
        this.setState({[e.target.name]: e.target.value})
    };
    
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        CREATE PHILHEALTH CONTRIBUTION 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='MonthlyBasicSalary'
                                    type="text" 
                                    placeholder="Enter monthly basic salary" 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.MonthlyBasicSalary}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='MonthlyPremium'
                                    type="text" 
                                    placeholder="Enter MONTHLY PREMIUM" 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.MonthlyPremium}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='MBRangeFrom'
                                    type="text" 
                                    placeholder="Enter MB Range From" 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.MBRangeFrom}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='MBRangeTo'
                                    type="text" 
                                    placeholder="Enter MB Range To" 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.MBRangeTo}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='PersonalShare'
                                    type="text" 
                                    placeholder="Enter PersonalShare" 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.PersonalShare}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='EmployeerShare'
                                    type="text" 
                                    placeholder="Enter Employeer Share" 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.EmployeerShare}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='Percentage'
                                    type="text" 
                                    placeholder="Enter Percentage" 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.Percentage}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='EffectivityYear'
                                    type="text" 
                                    placeholder="Enter Effectivity Year" 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.EffectivityYear}
                                />
                            </Form.Group>
                        </Form.Row>
                       {/*  <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='employerShare'
                                    type="text" 
                                    placeholder="Enter EMPLOYER SHARE" 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.employerShare}
                                />
                            </Form.Group>
                        </Form.Row> */}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleSubmit } style={{minWidth:'60px', marginRight:"5px"}}>Save</Button>
                        <Button variant="danger" className="noser-button-mr1" onClick={this.props.onHide} style={{minWidth:'60px', }}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default CreatePhilHealthTable