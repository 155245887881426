import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';

import { Input  } from 'reactstrap';
import { DropdownButton, Dropdown} from 'react-bootstrap';

 
class CodeOfConductCreateArticle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            trainingRegisterTableList           :   [],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalTrainingRegisterShow           :   false,

            selectedClientName                  :   "",
          
            articleNo                           :   "",
            articleName                         :   "",
            getClientList                       :   [],
            clientName                          :   "",           

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;
    }
    GetDepartment() {
        const departmentCodeParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "",
            "Name"          :   ""
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDepartments",  departmentCodeParams)
        .then(res => {
            const data = res.data;
            console.log("Get Departments Code");
            console.log(data);
            console.log("Get Departments Code");
            this.setState({
                departmentCodeAutocomplete  :   data.departments
            });
        })

        console.log(this.state.departmentCodeAutocomplete)
    }

    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            console.log("Client List start ");
            console.log(res.data);
            console.log("Client List end");
            const data = res.data
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }
    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.client = e[0].name
        this.setState({
            isloading:false,
            isshow:false,
            color:"",
            message:"",
            fade:true ,
            clientName: e[0].name
        })
       
       
    }
    
    onChangeArticleNo=(e)=>{
        this.setState({
            articleNo:e.target.value
        })
        //console.log(this.state.articleNo)
    }
    onChangeArticleName=(e)=>{
        this.setState({
            articleName:e.target.value
        })
        //console.log(this.state.articleName)
    }
    
    
    handleSave=(e)=>{
        
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');

        let data = {
          "clientName"      :   this.state.clientName,
          "articleNo"       :   this.state.articleNo,
          "articleName"     :   this.state.articleName,
          "isDraft"         :   "0",
          "isDeleted"       :   "0",
          "createdby"       :   "user007",
          "createddate"     :   createddate,
          "modifiedby"      :   'user007',
          "modifieddate"    :   '0',
          "isModified"      :   '0',    
        }
        console.log(data)
        
        const addParams = {
          "_collection" : "COCArticle",
          "doc_data"    : data
        }
          
        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
        //.post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
          const data = res.data
          console.log("success")
          console.log(data)
          this.setState({
            clientName  :"",
            articleNo   :"",
            articleName :"",
            isshow      : true,
            alerttype       :   "Successfully Save!",
            color           :   "success",
            message         :   "Successfully Save!",
           
          })
        })
        .catch(error =>{
          console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })          
        })
    }

    render() {     
     
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>Maintenance Administrative Cases (COC)</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.getClientList}
                                                placeholder="Select Client"
                                            />
                                            
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Article
                                        </Form.Label>
                                        <Col sm="2">
                                            <Input
                                            
                                                onChange={this.onChangeArticleNo}
                                                value={this.state.articleNo}
                                               className="no-text-transform-li"
                                                placeholder="(Enter Aticle No.)"
                                            />
                                        </Col>
                                        
                                        <Col sm="4">
                                            <Input
                                                onChange={this.onChangeArticleName}
                                                value={this.state.articleName}
                                               className="no-text-transform-li"
                                                placeholder="(Enter Article Title)"
                                            />
                                        </Col>
                                    </Form.Group>
                                    
                                    <ButtonToolbar sm={12}>
                                        <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleSave}>
                                           Save
                                        </Button>&nbsp;&nbsp;
                                        <NavLink to="/codeofconduct">
                                            <Button variant="danger" href="/codeofconduct"  style={{minWidth:'60px'}}>
                                                Close
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                    
            </div> 
        )
    }

}

export  default CodeOfConductCreateArticle
