import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../../noser-hris-component';
import { Input  } from 'reactstrap';
import { DropdownButton, Dropdown} from 'react-bootstrap';
 
class PreFinalRecomendationCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            trainingRegisterTableList          :   [ { "typeOfCases" : "DOLE" } ],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalTrainingRegisterShow           :   false,

            category    :"",
            caseName    :"",
            caseType    :"",

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    showTrainingRegisterModal = (e) => {
        this.setState({modalTrainingRegisterShow: true})
    }
    handleModalClose = (e) =>{
        //reload or refresh data here
        //alert("Data pass from child : " + e)
        this.setState({modalTrainingRegisterShow: false})
    }


    GridDataModified(oldValue, newValue, id, column) {
        ////console.log(id)
        this.state.trainingScheduleTableList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }
    
    onChangeCategory=(e)=>{
        this.setState({
            category:e.target.value
        })
        console.log(this.state.category)
    }
    onChangeCaseName=(e)=>{
        this.setState({
            caseName:e.target.value
        })
        console.log(this.state.caseName)
    }
    onChangeCaseType=(e)=>{
        this.setState({
            caseType:e.target.value
        })
        console.log(this.state.caseType)
    }

    handleSave=(e)=>{

        /*   this.setState({
            isloading       :   false,
            alerttype       :   "Success!",
            isshow          :   true,
            color           :   "warning",
            message         :   "under development" ,
            fade            :   true
        }) */

        

        const Params = {
            "IpAddress" :   "0.0.0.0",
            "UserId"    :   this.state.userinfo.userId,
            "CompanyId" :   this.state.userinfo.companyId,
            "Name"      :   this.state.caseName
        }
        
        console.log("handleSaveClick prefinalrecomendation")
        console.log(Params)

        axios
        .post(
            AppConfiguration.Setting().noserapiendpoint + "Maintenance/AddRecommendation",  Params
        )
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
                this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true,
                    });
                
                }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
            
        })
    }

    render() {
        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>Maintenance >> PreFinal Recomendation (Create) </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                       {/*  <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Code
                                        </Form.Label>
                                        <Col sm="11">
                                            <Input
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeCaseType}
                                                type="text" 
                                                placeholder=""
                                                value={this.state.caseType}
                                                autocomplete= "false"
                                                
                                            />
                                        </Col> */}
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Name
                                        </Form.Label>
                                        <Col sm="4">
                                            <Input
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeCaseName}
                                                type="text" 
                                                placeholder=""
                                                value={this.state.caseName}
                                                autoComplete="false"
                                            />
                                        </Col>
                                       
                                        
                                    </Form.Group>
                                    
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                    
                                    </Form.Group>
                                   
                                    <ButtonToolbar sm={12}>
                                        <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleSave}>
                                            Save
                                        </Button>&nbsp;&nbsp;
                                        <NavLink to="/prefinalrecomendation">
                                            <Button variant="danger" href="/prefinalrecomendation" style={{minWidth:'60px'}}>
                                                Close
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                   
            </div> 
        )
    }

}

export  default PreFinalRecomendationCreate
