import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker, LeaveModal, Tabs, Tab,
    sizePerPageRenderer,Modal,
} 
from '../../noser-hris-component';

class ExamCreate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo  :   [],
            isloading :   false,
            isshow    :   false,
            alerttype :   "",
            message   :   "",
            color     :   "",
            fade      :   true,
            name:"",
            examDescription:"",
            examInstruction:"",
            
        }
         
    }

    componentDidMount() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        sleep(1000).then(() => {
            this.setState({isLoading:false})}) 
       
    }

    onChangeExamName(e){
        console.log(e)
        this.setState({name:e.target.value})
    }

    onChangeExamDescription(e){
        console.log(e)
        this.setState({examDescription:e.target.value})
    }

    onChangeExamInstruction(e){
        console.log(e)
        this.setState({examInstruction:e.target.value})
    }

    handleSaveClick = event => {
        const examParams = {
            "IpAddress":"0.0.0.0",
            "UserId":"1",
	        "CompanyId":this.state.userinfo.companyId,
            "Name":this.state.name,
            "Description":this.state.examDescription,
            "Instructions":this.state.examInstruction
        }
        console.log(examParams)
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/AddExam",  examParams
            )
            .then(res => {
                const data = res.data;
                console.log("Get data");
                console.log(data)
                if(data.status=="1")
                {
                    this.setState(
                        { 
                            show:true,
                            Color:"success",
                            Message:data.message ,
                            Fade:true
                        });
                    
                    }
                else
                {
                    this.setState(
                        { 
                            show:true,
                            Color:"danger",
                            Message:data.message,
                            Fade:true
                        });
                }
            })
        
    }


    render() {
        

        return(
            <Modal
            {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className="modal-90w"
            >
                <Modal.Header closeButton className="card-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create Exam
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                    <Form>
                        <Alert color="success" isOpen={this.state.show} color={this.state.Color} fade={this.state.Fade}>
                                {this.state.Message}
                            </Alert>
                            <Form.Row>
                                <Col sm={6} className="mt-3">
                                    <Form.Control 
                                        type="text"
                                        name="name"
                                        value={this.state.name}
                                        placeholder="EXAM NAME"
                                        onChange={this.onChangeExamName.bind(this)} 
                                        autoComplete="off"
                                    />
                                </Col>
                                <Col sm={6} className="mt-3">
                                    <Form.Control 
                                        type="text"
                                        name="examDescrition"
                                        value={this.state.examDescription}
                                        placeholder="EXAM DESCRIPTION"
                                        onChange={this.onChangeExamDescription.bind(this)} 
                                        autoComplete="off"
                                    />
                                </Col>
                                <Col sm={6} className="mt-3">                
                                    <Form.Control 
                                        type="text"
                                        name="examInstruction"
                                        value={this.state.examInstruction}
                                        placeholder="EXAM INSTRUCTIONS"
                                        onChange={this.onChangeExamInstruction.bind(this)} 
                                        autoComplete="off"
                                    />
                                </Col>


                            </Form.Row>
                            <ButtonToolbar sm={12} className="mt-3">
                                <Button variant="primary"  onClick={this.handleSaveClick}  style={{minWidth:'60px', marginRight:"5px"}}>
                                    Save
                                </Button>
                                <Button variant="danger" href="/Exam"  style={{minWidth:'60px'}}>
                                    Back
                                </Button>
                            </ButtonToolbar>
                        </Form>
                    </Container>
                </Modal.Body>

                <NoserLoading show={this.state.isloading} />
            </Modal>
            
        )
    }
}

export default ExamCreate;