import React, { Component } from "react"
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {
    
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer,DatePicker
} 
from '../../noser-hris-component';
import ModalSSSTable from './ModalSSSTable';


class SSSTable extends Component {

    constructor() {
        super()
        this.state = {
            userinfo: [],
            isLoading: true,
            dateStart: "",
            dateEnd:"",
            openModal:"",
            typeId:"",
            clientId:"",
            clientName:"",
            periodId:"",
            periodName:"",
        }
    }
    componentDidMount() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
       this.GetSSSBrackets()
       sleep(1000).then(() => {
        this.setState({isLoading:false})})
    }

    GetSSSBrackets() {
        const sssParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "Year":"",
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetSSSBrackets",  sssParams)
        .then(res => {
             const data = res.data;
             console.log(res.data)
             this.setState({ sssBracketList: data.brackets });
         })
    }

    handleChangedateStart =/* date */(e)=>{
        this.setState({
            dateStart: e.target.value
        })
    }

    handleChangedateEnd =date=>{
        this.setState({
            dateEnd: date
        })
    }

    handleSearch=()=>{
        this.setState({ isLoading:true });
        let dateStart = moment(this.state.dateStart).format('YYYY');
        let dateEnd = moment(this.state.dateEnd).format('YYYY');

        const sssParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "Year":dateStart,
           
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetSSSBrackets",  sssParams)
        .then(res => {
            const data = res.data;
            console.log(res.data)
            this.setState({ 
                sssBracketList: data.brackets,
                isLoading:false
            });
        })

    }

    handleModalShow = (e) =>{

      

        this.setState({openModal:true})
        let obj = {
            typeId:e,
            clientId:this.state.userinfo.companyId,
            clientName:this.state.userinfo.companyName,
            periodId:this.state.periodId,
            periodName:this.state.periodName,
        }
        this.child.initialize(obj)
    }

    handleModalClose = (e) =>{
        this.setState({openModal:false})
        //this.handleSearchClick()
    }



    render() {
        const cellEdit = {
        mode: 'click',
        blurToSave: true
        };  
        
        

        return (
            <div>
             <Banner />
             <Container className="mt-5">
                    <Card>
                        <Card.Header>SSS CONTRIBUTION TABLE</Card.Header>
                        <Card.Body>

                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    year
                                </Form.Label>
                                <Col sm="2">
                                <Form.Control
                                                                type="text"
                                                                name="dateStart"
                                                                value={this.state.dateStart}
                                                                onChange={this.handleChangedateStart}
                                                                autoComplete="off"
                                                                placeholder="Enter Year"
                                                            />
                                    {/* <DatePicker
                                        ref='date'
                                        selected={this.state.dateStart}
                                        onChange={this.handleChangedateStart}
                                        value={this.state.dateStart}
                                        dateFormat={"yyyy"}
                                        style={{height:'40px !important'}}
                                        size="40"
                                        className="form-control"
                                    /> */}
                                </Col>

                                {/* <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    End Date
                                </Form.Label>
                                <Col sm="2">
                                    <DatePicker
                                        ref='year'
                                        selected={this.state.dateEnd}
                                        onChange={this.handleChangedateEnd}
                                        value={this.state.dateEnd}
                                        dateFormat={"yyyy"}
                                        style={{height:'40px !important'}}
                                        size="40"
                                        className="form-control"
                                    />
                                </Col> */}
                                
                            </Form.Group>
                
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <ButtonToolbar>
                                    {<Button variant="warning" className="noser-button-mr1" onClick={() => this.handleModalShow("1") }>Download</Button>}
                                    <Button variant="info" className="noser-button" onClick={() => this.handleModalShow("0") }>Upload</Button>
                                    <Button variant="primary" className="ml-auto noser-button" onClick={ this.handleSearch }>Search</Button>
                                    </ButtonToolbar>
                                </Col>
                            </Form.Group>

                            <Form.Row>
                                <BootstrapTable data={ this.state.sssBracketList } striped={true} hover={true}
                                cellEdit={ cellEdit }
                                >
                                    <TableHeaderColumn  row='0' rowSpan='2' dataField='id' dataAlign="center"  isKey={true} width='175' hidden>RANGE OF COMPENSATION</TableHeaderColumn>
                                    <TableHeaderColumn row='0' rowSpan='2' dataField='name' dataAlign="center"   width='175'>RANGE OF COMPENSATION</TableHeaderColumn>

                                    <TableHeaderColumn row='0' rowSpan='2' dataField='monthlySalary' width='175' dataAlign="center">MONTHLY SALARY CREDIT</TableHeaderColumn>

                                    <TableHeaderColumn row='0' colSpan='3'  headerAlign='center' width='210' >EMPLOYERS SS CONTRIBUTION</TableHeaderColumn>
                                    <TableHeaderColumn row='1' dataField='scerShare' width='75' dataAlign='center'>ER</TableHeaderColumn>
                                    <TableHeaderColumn row='1' dataField='sceeShare' width='75' dataAlign="center">EE</TableHeaderColumn>
                                    <TableHeaderColumn row='1' dataField='scTotalShare' width='75'>TOTAL</TableHeaderColumn>

                                    <TableHeaderColumn row='0' colSpan='1'  width='170' headerAlign='center' >EC CONTRIBUTION</TableHeaderColumn>
                                    <TableHeaderColumn row='1' dataField='ecShare' width='170' dataAlign='center'>ER</TableHeaderColumn>

                                    <TableHeaderColumn row='0' colSpan='3'   headerAlign='center' width='210'>TOTAL CONTRIBUTION</TableHeaderColumn>
                                    <TableHeaderColumn row='1' dataField='tcerShare' width='70' dataAlign='center'>ER</TableHeaderColumn>
                                    <TableHeaderColumn row='1' dataField='tceeShare' width='70' dataAlign="center">EE</TableHeaderColumn>
                                    <TableHeaderColumn row='1' dataField='tcTotalShare' width='70'>TOTAL</TableHeaderColumn>
                                </BootstrapTable>&nbsp;&nbsp;
                                
                            </Form.Row>
                            <Form.Group>
                                <ButtonToolbar sm={12}>
                                    <Button  variant="danger"  href="/home">
                                        Close
                                    </Button>
                                </ButtonToolbar>
                            </Form.Group>
                            
                        </Card.Body>
                    </Card>
                    <NoserLoading show={this.state.isLoading} />
                    <ModalSSSTable 
                        show={this.state.openModal}
                        onHide={this.handleModalClose}
                        onRefModal={ref => (this.child = ref)}
                    />
                </Container>
            </div>
        );
    }

}
 
export  default SSSTable