
import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

import UploadDTRAdjustmentModal from './UploadDTRAdjustmentModal'

class UploadDTRAdjustment extends Component {

    constructor() {
        super()
        this.state = {
            clientId : '',
            clientName : '',
            locationId : '',
            locationName:'',
            periodId:'',
            periodName:'',
            openModal:false,
            closeModal:false,
            disable:true,

            clientList : [],
            locationList : [],
            periodList:[],
            billableHours:[],
            
        }

        
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()
        sleep(1000).then(() => {
            this.setState({isloading:false})
          })
        
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.handleSearch(e)
    }
    handleDelete = (e) => {
        this.setState({isloading:true})

        let newBillableHrs = []
        for (let i = 0; i < this.state.billableHours.length; i++) {
            if (this.state.billableHours[i]["isDeleted"] == 1) {
                let obj = this.state.billableHours[i]
                newBillableHrs.push(obj);
            } 
        }

        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId": this.state.userinfo.clientId,
            "billableHours": newBillableHrs
        }
        console.log(params)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/EditAdjustmentBillableHours", params)
        .then(res => {
            const data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   (data.status=="0") ? "Error!" : "Success!",
                isshow      :   true,
                color       :   (data.status=="0") ? "danger" : "success",
                message     :   data.message,
                fade        :   true
            });
            if(data.status=="1"){
                this.handleSearch(e)
            }
        })
        
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    handleSearch =(e) => {
        this.setState({isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientId": this.state.clientId,
            "LocationId": this.state.locationId,
            "PeriodId": this.state.periodId
    
        }
        console.log(params)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetAdjustmentBillableHours", params)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({billableHours : res.data.billableHours ? res.data.billableHours : [], isloading:false})
            if(data.billableHours.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    handleDownload =(e) => {
        this.setState({isloading:true})

        if(this.state.clientId=="")
        {
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select client",
                fade            :   true
            })
            return
        }

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientId": this.state.clientId,
            "LocationId": this.state.locationId
    
        }
        console.log(params)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/DownloadBillableTemplate", params,{'responseType': 'blob'})
        .then(res => {
            const data = res.data;
            let filename = res.headers["content-disposition"].split(';')[1].trim().split('=')[1].replace(/"/g, '')
            if(filename=='' || filename==null)
            {
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while downloading template.",
                    fade            :   true
                })
            }
            else
            {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                this.setState({isloading:false})
            }
        })
        
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    handleChangeClient = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length==0)
        {
            this.setState({clientId:"",clientName:""})
            return
        }
        this.state.clientId= e[0].id
        this.state.clientName= e[0].name
        this.GetClientLocation()
        this.GetPeriodList()
    }
    handleChangePeriod = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length == 0) {
            this.setState({periodId:"",periodName:""})
            return
        }
        this.state.periodId = e[0].periodId
        this.state.periodName =e[0].payPeriod
    }
    handleChangeLocation = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length==0)
        {
            this.setState({locationId:"",locationName:""})
            return
        }
        this.state.locationId = e[0].id
        this.state.locationName = e[0].name
    }
    GetClients(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            this.setState(
                {
                    isloading:false,
                    clientList : res.data.clients ? res.data.clients : []
                });
                
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetClientLocation(){
        this.setState({isloading:true})

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.clientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        console.log(getParams)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", getParams)
        .then(res => {
            const data = res.data;
            this.setState({locationList : res.data.locations ? res.data.locations : [], isloading:false})
            if(data.locations.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    GetPeriodList() {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   "0",
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   "",
            "IsProcessed"   :   "0"
         };
         axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods",  param
             )
             .then(res => {
                const data = res.data;
                console.log(data)
                this.setState({
                    periodList   :   data.payrollPeriods,
                    isloading       :   false
                })
             })
    }
    render() {
        const columns = [
            /* {
                dataField   :   'clientName',
                text        :   'CLIENT',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'left',width  : "25%",fontSize:'10px' };
                  },
                style:{textAlign:'left',fontSize:'10px'}
            }, */
            {
                dataField   :   'locationName',
                text        :   'BRANCH',
                editable    :    false,
                headerStyle : () => {
                    return { width  : "25%",fontSize:'10px' };
                  },
                  style:{fontSize:'10px'}
            },
            {
                dataField   :   'periodName',
                text        :   'PERIOD',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "15%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'11px'}
            },
            {
                dataField   :   'totalTxn',
                text        :   'TOTAL COUNT',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "15%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'txnDate',
                text        :   'DATE UPLOAD',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "15%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'fileName',
                text        :   'FILE NAME',
                editable    :    false,
                headerStyle : () => {
                    return { width  : "30%",fontSize:'10px' };
                  },
                  style:{fontSize:'10px'}
            }
        ] 
        const columnDetails = [
            {
                dataField   :   'employeeName',
                text        :   'EMPLOYEE NAME',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'left',width  : "10%",fontSize:'10px' };
                  },
                style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'}
            },
            {
                dataField   :   'totalWorkingDays',
                text        :   'TOTAL WORKING DAYS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'totalWorkingHrs',
                text        :   'TOTAL WORKING HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'absentDays',
                text        :   'ABSENT DAYS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'absentHrs',
                text        :   'ABSENT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'lateMins',
                text        :   'LATE MINS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'lateHrs',
                text        :   'LATE HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'overbreakMins',
                text        :   'OB MINS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'overbreakHrs',
                text        :   'OB HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'undertimeMins',
                text        :   'UT MINS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'undertimeHrs',
                text        :   'UT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'vacationLeave',
                text        :   'VL',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'sickLeave',
                text        :   'SL',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'maternityLeave',
                text        :   'ML',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'paternityLeave',
                text        :   'PL',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'ndHrs',
                text        :   'ND HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'ndotHrs',
                text        :   'NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'otHrs',
                text        :   'OT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'dodHrs',
                text        :   'DOD HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'dodotHrs',
                text        :   'DOD OT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'dodndHrs',
                text        :   'DOD ND HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'dodndotHrs',
                text        :   'DOD NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'sholHrs',
                text        :   'SHOL HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'sholotHrs',
                text        :   'SHOL OT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'sholndHrs',
                text        :   'SHOL ND HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'sholndotHrs',
                text        :   'SHOL NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'shdodHrs',
                text        :   'SHOL DOD HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'shdodotHrs',
                text        :   'SHOL DOD OT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'shdodndHrs',
                text        :   'SHOL DOD ND HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'shdodndotHrs',
                text        :   'SHOL DOD NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'lholHrs',
                text        :   'LHOL HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'lholotHrs',
                text        :   'LHOL OT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'lholndHrs',
                text        :   'LHOL ND HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'lholndotHrs',
                text        :   'LHOL NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },

            {
                dataField   :   'lholdodHrs',
                text        :   'LHOL DOD NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'lholdodotHrs',
                text        :   'LHOL DOD NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'lholdodndHrs',
                text        :   'LHOL DOD NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'lholdodndotHrs',
                text        :   'LHOL DOD NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },

            {
                dataField   :   'doublesholHrs',
                text        :   'DOUBLE HOL HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublesholotHrs',
                text        :   'DOUBLE HOL OT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublesholndHrs',
                text        :   'DOUBLE HOL ND HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublesholndotHrs',
                text        :   'DOUBLE HOL NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },

            {
                dataField   :   'doublesholdodHrs',
                text        :   'DOUBLE HOL DOD HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublesholotHrs',
                text        :   'DOUBLE HOL DOD OT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublesholndHrs',
                text        :   'DOUBLE HOL DOD ND HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublesholndotHrs',
                text        :   'DOUBLE HOL DOD NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },

            {
                dataField   :   'doublelholHrs',
                text        :   'DOUBLE LHOL HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublelholotHrs',
                text        :   'DOUBLE LHOL OT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublelholndHrs',
                text        :   'DOUBLE LHOL ND HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublelholndotHrs',
                text        :   'DOUBLE LHOL NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },

            {
                dataField   :   'doublelholdodHrs',
                text        :   'DOUBLE LHOL DOD HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublelholdodotHrs',
                text        :   'DOUBLE LHOL DOD OT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublelholdodndHrs',
                text        :   'DOUBLE LHOL DOD ND HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublelholdodndotHrs',
                text        :   'DOUBLE LHOL DOD NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },

            {
                dataField   :   'remarks',
                text        :   'Remarks',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'left',width  : "20%",fontSize:'10px' };
                  },
                  style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'}
            },
            
        ]
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable=true
                this.state.billableHours.map(function(item,i){
                    if(item.id==row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if (item.isDeleted=="1")
                    isDisable =  false
                    //console.log(item.isDeleted)
                })
                this.setState({disable:isDisable})
            }
        };
        const expandRow = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "id"
                    data = { row.billableDetails }
                    columns = { columnDetails }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    expandRow
                    wrapperClasses="table-responsive"
                    rowClasses="noser-table-row-class"
                    noDataIndication={ () => <div>No details.</div> }
                /> 
                
            ),
            showExpandColumn: true,
          };
            return(
                <div>
                    <Banner />
                    <Container className="themed-container" fluid={true}>
                        <Card className="mt-5">
                            <Card.Header>Timekeeping >> Upload DTR Adjustment </Card.Header>
                            <Card.Body>
                                <Form >
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeClient}
                                                options={this.state.clientList}
                                                placeholder="Select Client"
                                                //defaultSelected={[this.state.clientName]}
                                            />
                                        </Col>
                                    </Form.Group> */}
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeLocation}
                                                options={this.state.locationList}
                                                placeholder="Select Location"

                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <Typeahead
                                                labelKey='payPeriod'
                                                id="basic-example"
                                                onChange={this.handleChangePeriod}
                                                options={this.state.periodList}
                                                placeholder="Select Payroll Period"

                                            />
                                        </Col>
                                    </Form.Group>
                                    <div className="mt-1">
                                        <ButtonToolbar>
                                            <Button style={{minWidth:'60px'}} className="ml-auto" variant="primary" variant="success" onClick={this.handleSearch}>Search</Button>
                                        </ButtonToolbar>
                                    </div>

                                    <div className="mt-2">
                                        <hr />
                                        <ButtonToolbar>
                                            <Button style={{minWidth:'60px',marginBottom:'2px'}} variant="primary" variant="success" onClick={this.handleDownload}>Download Template</Button>
                                        </ButtonToolbar>
                                        <Card.Header>BILLABLE HOURS</Card.Header>
                                        <BootstrapTable
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            pagination={ paginationFactory({sizePerPage:20,hideSizePerPage:true,hidePageListOnlyOnePage:true})}
                                            keyField = "id"
                                            data = { this.state.billableHours }
                                            columns = { columns}
                                            selectRow = { selectRow }
                                            cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                            expandRow={ expandRow }
                                            noDataIndication={ () => <div>No record found.</div> }
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <ButtonToolbar>
                                            <Button style={{minWidth:'60px',marginRight:'1pt'}} className="ml-auto" variant="primary" variant="success" onClick={this.handleModalShow}>Upload</Button>
                                            <Button style={{minWidth:'60px'}} variant="primary" variant="danger" onClick={this.handleDelete} disabled={this.state.disable}>Delete</Button>
                                        </ButtonToolbar>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                    <NoserLoading show={this.state.isloading} />
                    <UploadDTRAdjustmentModal 
                        show={this.state.openModal}
                        onHide={this.handleModalClose}
                    />
                </div>
        )
    }
}

export  default UploadDTRAdjustment