import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import CreatePayrollArea from './Modal/CreatePayrollAreaMinimumRates';

class PayrollAreaMinimumRates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true,
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            dataGrid    : [],
            clientDDL   : [],
            clientId    :   "",
            branchDDL   : [],
            branchId    : "",
            areaDDL     : [],
            areaName    : "",
            minRateDDL  : [],
            minRate     : "",
            arrList     : [],
            
            disableSaveBtn : true,
        }
    }
    
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        //this.GetClients();
        this.GetData();
        this.GetClientLocations()
        this.GetArea();
        this.GetMinimumRate();
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.GetData()
    }
    GetData = async() => {
        this.setState({isloading:true,alerttype:'',isshow:false,color:'',message:'',fade:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "LocationId"    :   "",
            "Name"          :   "",
            "Rates"         :   "",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollMinimumRates",  param)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({dataGrid:data.payrollArea,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    GetClients = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  param)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL:data.clients,isloading:false});
            if(data.status===0){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }  
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    GetClientLocations = async() =>{
        this.setState({isloading:true,})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.userinfo.clientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", params)
        .then(res => {
            const data = res.data;
            this.setState({branchDDL : data.locations ? data.locations : [],isloading:false,})
            if(data.status===0){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }  
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleChangedClient = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.clientId=""
            this.state.clientName=""
            return
        } 
        this.state.clientId = e[0].id
        this.state.clientName= e[0].name
        this.GetClientLocations()
        this.GetArea();
        this.GetMinimumRate();
        
    }
    handleChangedBranch = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.branchId=""
            return
        } 
        this.state.branchId = e[0].id
    }
    GetArea = async() => {
        this.setState({isloading:true,alerttype:'',isshow:false,color:'',message:'',fade:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "LocationId"    :   this.state.branchId,
            "Name"      :   "",
            "Rates"       :   "",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollMinimumRates",  param)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({areaDDL:data.payrollArea,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangedArea = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.areaName=""
            return
        } 
        this.state.areaName = e[0].name
        this.GetMinimumRate();
    }
    GetMinimumRate = async() => {
        this.setState({isloading:true,alerttype:'',isshow:false,color:'',message:'',fade:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "LocationId"    :   this.state.branchId,
            "Name"          :   this.state.areaName,
            "Rates"         :   "",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollMinimumRates",  param)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({minRateDDL:data.payrollArea,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangedMinRate = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.minRate=""
            return
        } 
        this.state.minRate = e[0].rates
    }
    handleSearchClick = async() =>{
        this.setState({isloading:true,alerttype:'',isshow:false,color:'',message:'',fade:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.clientId,
            "LocationId"    :   this.state.branchId,
            "Name"          :   this.state.areaName,
            "Rates"         :   this.state.minRate,
        };
        console.log(param)
        console.log("param")
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollMinimumRates",  param)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({dataGrid:data.payrollArea,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleSaveClick = event => {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.setState({
            arrList     : [],
            isloading   : true,
            disableSaveBtn : true
        })

        for (let i = 0; i < this.state.dataGrid.length; i++) {
            if (this.state.dataGrid[i]["isModified"] == 1) {
                const obj = {
                    "Id"        :   this.state.dataGrid[i]["id"],
                    "ClientId"  :   this.state.dataGrid[i]["clientId"],
                    "LocationId":   this.state.dataGrid[i]["locationId"],
                    "Name"      :   this.state.dataGrid[i]["name"],
                    "Rates"     :   this.state.dataGrid[i]["rates"],
                    "ModifiedBy":   this.state.userinfo.userId,
                    "IsDeleted" :   this.state.dataGrid[i]["isDeleted"].toString()
                };

                this.state.arrList.push(obj);
            }
        }
        console.log(this.state.arrList)
        const editPositionParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "payrollArea":   this.state.arrList
        };

        console.log("Submit Edit Params")
        console.log(editPositionParams)

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditPayrollMinimumRates", editPositionParams)
            .then(res => {
                const data = res.data;
                sleep(5000).then(() => {
                    this.setState({
                        isloading   :   false
                    })
                })
                this.GetData();
                if(data.status=="1"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true
                    });
                }
                else{
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    })
                }
             })
             .catch(error=>{
                this.setState(  {
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }

    GridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.dataGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
        this.setState({disableSaveBtn:disable})
    }
    render(){
        const columns = [
            {
                dataField: 'clientName',
                text: 'Company',
                editable    : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap',width:"25%"}},
                style:{textAlign:'left',whiteSpace:'nowrap',width:"25%"}
            },
            {
                dataField: 'locationName',
                text: 'Branch/Location',
                editable    : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',width:"25%"}},
                style:{textAlign:'left',whiteSpace:'nowrap',width:"25%"}
            },
            {
                dataField: 'name',
                text: 'Area',
                editable    : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',width:"25%"}},
                style:{textAlign:'left',whiteSpace:'nowrap',width:"25%"},
                validator: (newValue, row, column) => {
                  if (newValue === "") {
                    return {
                      valid: false,
                      message: 'Area should not empty'
                    };
                  }
                  return true;
                }
            },
            {
                dataField: 'rates',
                text: 'Min Rates',
                editable    : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',width:"25%"}},
                style:{textAlign:'left',whiteSpace:'nowrap',width:"25%"},
                validator: (newValue, row, column) => {
                  if (isNaN(newValue)) {
                    return {
                      valid: false,
                      message: 'Price should be numeric'
                    };
                  }
                  return true;
                }
            },
        ]
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.dataGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableSaveBtn:isDisable})
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>MAINTENANCE >> PAYROLL >> AREA MINIMUM RATES</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                   {/*  <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Company
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedClient}
                                            options={this.state.clientDDL}
                                        />
                                    </Col> */}
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        BRANCH
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedBranch}
                                            options={this.state.branchDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        AREA
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedArea}
                                            options={this.state.areaDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        RATE
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='rates'
                                            id="basic-example"
                                            onChange={this.handleChangedMinRate}
                                            options={this.state.minRateDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar sm={12}>
                                    <Button variant="success" className="ml-auto noser-button" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>
                                <div className="mt-3">
                                    <Card.Header>LISTS</Card.Header>
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.dataGrid }
                                        columns = { columns }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div>No record found.</div> }
                                        rowClasses="noser-table-row-class"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        rowEvents={ rowEvents }
                                        selectRow = { selectRow }
                                    />
                                </div>
                                <div className="mt-1">
                                    <ButtonToolbar>
                                        <Button className="ml-auto noser-button-mr1" variant="success" onClick={this.handleSaveClick} disabled={this.state.disableSaveBtn} style={{marginRight:"5px"}}>Save</Button>
                                        <Button className="noser-button" variant="primary" onClick={this.handleModalShow}>Create</Button>
                                    </ButtonToolbar>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>  
                <NoserLoading show={this.state.isloading} />
                <CreatePayrollArea 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div>
        )
    }

}

export  default PayrollAreaMinimumRates