import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
}
from '../../noser-hris-component';


class EventScheduleList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            disableBtn      :   true,
            disableresetBtn :   true,
            employeeDDL     :   [],
            employeeList    :   [],
            eventEmployeeDDL :  [],
            clientList      :   [],
            AreaDDL         :   [],
            payrolPositionDDL   :   [],
            selectedClientId:   "",
            toDate          :   "",
            fromDate        :   "",
            employeeId      :   "",
            employeeName    :   "",
            positionid      :   "",
            positionName    :   "",
            Areaid          :   "",
            nameOfEvent     :   "",
            venueOfEvent    :   "",
            employeeNo      :   "",
            PositionName    :   "",
            eventEmployeeDDL :  [],
            areaDropdownDDL :   [],
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    componentDidMount(){
        this.getClientList()
        this.getPayrollPositionRates()
        this.getAreaList();
    }


    GridDataModified(oldValue, newValue, id, column) {
        this.state.eventEmployeeDDL.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
                console.log(item)
        })
    }



    getClientList(){
        this.setState({isloading:true})
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            sleep(1000).then(() => {
                this.setState({
                    clientList  :   res.data.clients ? res.data.clients : [],
                    isloading   :   false
                })
            })
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        // this.GetEventSchedule()
    }
    getPayrollPositionRates = async(clientId) =>{
        this.setState({isloading:true})

        const params = {
            "IpAddress": "0,0,0,0",
            "UserId": "1",
            "ClientId" : this.state.selectedClientId,
            "LocationId" : "",
            "Name" : "",
            "Rates" : ""
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollPositionRates", params)
        .then(res => {
            const data = res.data;
            // console.log(data)
            this.setState({payrolPositionDDL : data.payrollPosition, isloading : false})
            // this.GetNationalities()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    getAreaList = async(clientid) =>{
        this.setState({isloading:true})

        const params = {
            "IpAddress": "0,0,0,0",
            "UserId": "1",
            "ClientId" : this.state.selectedClientId,
            "LocationId" : "",
            "Name" : "",
            "Rates" : ""
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollMinimumRates", params)
        .then(res => {
            const data = res.data;
            // console.log(data)
            this.setState({AreaDDL : data.payrollArea, isloading : false})
            // this.GetNationalities()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    getAreaDropdownDDL = async(_gridList) =>{

        let _gridData = []
        for( let i = 0; i < _gridList.length; i++){
            console.log("_gridList[i]")
            console.log(_gridList[i])
            let noEmp = _gridList[i]["payrollEventScheduleDetails"].length
            _gridData.push({
                'areaId': _gridList[i]["areaId"],
                'areaName' : _gridList[i]["areaName"],
                'clientId': _gridList[i]["clientId"],
                'clientName': _gridList[i]["clientName"],
                'createdBy': _gridList[i]["createdBy"],
                'createdDate': _gridList[i]["createdDate"],
                'dateEnd': _gridList[i]["dateEnd"],
                'dateStart': _gridList[i]["dateStart"],
                'id': _gridList[i]["id"],
                'isDeleted': _gridList[i]["isDeleted"],
                'isModified': _gridList[i]["isModified"],
                'modifiedBy': _gridList[i]["modifiedBy"],
                'modifiedDate': _gridList[i]["modifiedDate"],
                'nameOfEvent': _gridList[i]["nameOfEvent"],
                'venueOfEvent': _gridList[i]["venueOfEvent"],
                'payrollEventScheduleDetails' : _gridList[i]["payrollEventScheduleDetails"],
                'noOfEmployee' : noEmp,
            })
            this.setState({ eventEmployeeDDL : _gridData })
        }

        this.setState({isloading:true})

        const params = {
            "IpAddress": "0,0,0,0",
            "UserId": "1",
            "ClientId" : this.state.selectedClientId,
            "LocationId" : "",
            "Name" : "",
            "Rates" : ""
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollMinimumRates", params)
        .then(res => {
            const data = res.data;
            // console.log("data")
            // console.log(data)
            this.modififyData(data.payrollArea)
            // this.setState({areaDropdownDDL : data.payrollArea, isloading : false})
            // this.GetNationalities()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    modififyData = async(_itemList) =>{

        let _pushData = []
        for (let i = 0; i < _itemList.length; i++){
            // console.log("_itemList")
            // console.log(_itemList[i])
            _pushData.push({
                "label" : _itemList[i]["name"],
                "value" : _itemList[i]["name"],
                "name" : _itemList[i]["name"],
            })
        }
        // console.log("_pushData")
        // console.log(_pushData)
        this.setState({ areaDropdownDDL : _pushData, isloading : false })
    }





    handleGetEventSchedule = () =>{

        const payrollParams = {

            "IpAddress":"0.0.0.0",
            "ClientId": this.state.selectedClientId,
            "UserId": this.state.userinfo.userId,
            "NameOfEvent": "", //this.state.nameOfEvent,
            "VenueOfEvent": "", //this.state.venueOfEvent,
            "AreaId": "", //this.state.Areaid,
            "DateStart": "", //moment(this.state.fromDate).format("MM/DD/YYYY"),
            // "DateEnd" : moment(this.state.toDate).format("MM/DD/YYYY"),
        };
        console.log("payrollParams")
        console.log(payrollParams)
        axios
            .post( AppConfiguration.Setting().noserapiendpoint + "Payroll/GetPayrollEventSchedule", payrollParams )
            .then(res => {
                const data = res.data;
                console.log("data")
                console.log(data)
                this.getAreaDropdownDDL(data.payrollEventSchedule);
                this.setState({isLoading:false, /* eventEmployeeDDL: data.payrollEventSchedule */})
                    var alertType = (data.status=="1") ? "success" : "danger"
                this.setState(
                    {
                        isLoading:false,
                        AlertType:"Success!",
                        show:true,
                        Color:alertType,
                        Message:data.message ,
                        Fade:true
                    }
                );
            })
            .catch(error=>{
                this.setState(
                {
                    isLoading:false,
                    AlertType:"Error! ",
                    Show:true,
                    Color:"danger",
                    Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    Fade:true
                })
        })
    }
    handleSaveEvent = () =>{

        this.setState({isloading:true,isDisable:true})
        let arrList = []
        let subList = []
        const userid = this.state.userinfo.userId
        const data = this.state.eventEmployeeDDL
        data.map(function(itm,a){
            if(itm.isModified=="1"){
                arrList.push({
                    "Id":itm.id,
                    "ClientId":itm.clientId,
                    "NameOfEvent": itm.nameOfEvent,
                    "VenueOfEvent":itm.venueOfEvent,
                    "AreaId":itm.areaId,
                    "DateStart":itm.dateStart,
                    "DateEnd":itm.dateEnd,
                    "IsDeleted" : itm.isDeleted,
                    "eventdetails" : subList
                })
                itm.payrollEventScheduleDetails.map(function(subitm,c){
                    subList.push({
                        "Id"     :   subitm.id,
                        "HeaderId": subitm.headerId,
                        "EmployeeId": subitm.employeeId,
                        "EmployeeNo": subitm.employeeNo,
                        "PositionId": subitm.positionId,
                        "IsDeleted": subitm.isDeleted,
                        "ModifiedBy": userid
                    })
                })
            }
        })
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "payrolleventschedule":arrList,
        }

         console.log('params')
         console.log(params)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/EditPayrollEventSchedule", params)
        .then(res => {
            const data = res.data
            this.setState({
                isloading   :   false,
                alerttype   :   data.status=="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    handleDeleteItem = () =>{
        

        this.setState({isloading:true,isDisable:true})
        let arrList = []
        let subList = []
        const userid = this.state.userinfo.userId
        const data = this.state.eventEmployeeDDL
        data.map(function(itm,a){
            // console.log("itm")
            // console.log(itm)
            // if(itm.isModified==="0"){
                arrList.push({
                    "Id":itm.id,
                    "ClientId":itm.clientId,
                    "NameOfEvent": itm.nameOfEvent,
                    "VenueOfEvent":itm.venueOfEvent,
                    "AreaId":itm.areaId,
                    "DateStart":itm.dateStart,
                    "DateEnd":itm.dateEnd,
                    "IsDeleted" : itm.isDeleted,
                    "eventdetails" : subList
                })
                let _filterIsDeleted = itm.payrollEventScheduleDetails.filter( x => x.isDeleted === "1")
                // console.log("_filterIsDeleted")
                // console.log(_filterIsDeleted)
                _filterIsDeleted.map(function(subitm,c){
                    if (itm.isDeleted!== "1") {
                        // console.log("subitm")
                        // console.log(subitm)
                        subList.push({
                            "Id"     :   subitm.id,
                            "HeaderId": subitm.headerId,
                            "EmployeeId": subitm.employeeId,
                            "EmployeeNo": subitm.employeeNo,
                            "PositionId": subitm.positionId,
                            "IsDeleted": subitm.isDeleted,
                            "ModifiedBy": userid
                        })
                    }
                })
            // }
        })
        // console.log("arrList")
        // console.log(arrList)
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "payrolleventschedule":arrList,
        }

         console.log('params')
         console.log(params)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/EditPayrollEventSchedule", params)
        .then(res => {
            const data = res.data
            this.setState({
                isloading   :   false,
                alerttype   :   data.status=="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }





    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        }
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        this.setState({isLoading:true,loadingText:"Retrieving employee list..."})
        // console.log(e[0])
        // this.getEmployeeList(e[0].id);
    }
    onChangeArea = (e) => {
        if(e.length == 0) {
            this.state.Area=""
            return
        }
        // this.state.selectedClientId = e[0].id
        this.state.Areaid = e[0].id
        this.state.Area = e[0].name
        // this.setState({isLoading:true,loadingText:"Retrieving employee list..."})
        // this.setState({ Area : e.target.value})
    }
    onChangeFromDate = date => {
        this.setState({
            fromDate: date,
        });
    }
    // onChangeToDate = date => {
    //     this.setState({
    //         toDate: date,
    //     });
    // }



    IsDecimal(val)
    {
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        return !regex.test(val)
    }
    render() {
        const EmployeeEventColumn = [
            /* {
                dataField   : 'clientName',
                text        : 'Client',
                editable    : false,
                // formatter: (cell, row) => {
                //     if(row.clientName!=='' && row.clientName!==null){
                //         if(this.state.clientList.filter(x => x.value === cell).length==0)
                //         return ""
                //         else
                //         return this.state.clientList.find(x => x.value === cell).clientName;
                //     }
                // },
                // editor: {
                //     type: Type.SELECT,
                //     options: this.state.clientList.sort((a, b) => (a.label > b.label) ? 1 : -1)
                // },
                headerStyle : () => {
                    return { width  : "20%" };
                }
            }, */
            {
                dataField   : 'nameOfEvent',
                text        : 'Event Name',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'venueOfEvent',
                text        : 'Event Venue',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'areaName',
                text        : 'Area',
                editable    : true,
                // formatter: (cell, row) => {
                //     if(row.areaName!=='' /* && row.areaName!==null */ || typeof row.areaName === 'undefined'){
                //         if(this.state.areaDropdownDDL.filter(x => x.value === cell).length==0)
                //         return ""
                //         else
                //         return this.state.areaDropdownDDL.find(x => x.value === cell).value;
                //     }
                // },
                // editor: {
                //     type: Type.SELECT,
                //     options: this.state.areaDropdownDDL
                // },
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'dateStart',
                text        : 'Date',
                editable    : true,
                formatter: (cell) => {
                    let dateObj = cell;
                    if (typeof cell !== 'object') dateObj = new Date(cell);
                    if (cell != null){
                        let m = moment(dateObj);
                        return (m.isValid() ? (dateObj.getMonth()+1 + "/" + dateObj.getDate() + "/" + dateObj.getFullYear()) : "")
                    }
                },
                editor: {
                    type: Type.DATE,
                    defaultValue: Date.now(),
                    minDateValue: Date.now(),
                },
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'noOfEmployee',
                text        : 'No. of Emp',
                editable    : false,
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
        ]

        const colClient = [
            {
                dataField: 'employeeName',
                text: 'Employee Name',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'50%' }},
                style:{textAlign:'left',width:'50%'}
            },
            // {
            //     dataField: 'employeeNo',
            //     text: 'Employee No.',
            //     editable: false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'left',width:'50%' }},
            //     style:{textAlign:'left',width:'50%'}
            // },
            {
                dataField: 'positionName',
                text: 'Position Name',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'50%' }},
                style:{textAlign:'left',width:'50%'}
            },
        ]

        const selectRowClient = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                let arr = this.state.eventEmployeeDDL.filter(x=>x.employeeId===row.employeeId)
                this.state.arr/* .filter(x=>x.employeeId===row.employeeId)[0].clients */.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                // if(isDisable===false){
                //     this.state.userList.map(function(itm){
                //         if(itm.id===arr[0].id){
                //             itm.isModified="1"
                //         }
                //     })
                // }
                // this.setState({disableSaveBtn:isDisable})
            }
        };
        const expandRow = {
            onlyOneExpanding: true,
            renderer: row =>
            (
                <BootstrapTable
                    keyField = "id"
                    data = { row.payrollEventScheduleDetails }
                    columns = { colClient }
                    rowClasses="noser-table-row-class"
                    striped
                    expandRow
                    selectRow = { selectRowDetails }
                    wrapperClasses="table-responsive"
                    noDataIndication={ () => <div>No record found.</div> }
                />
            ),
            showExpandColumn: true,
          };

          const selectRowDetails = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.eventEmployeeDDL.map(function(itm,i){
                itm.payrollEventScheduleDetails.map(function(subitm,j){
                        // console.log("subitm")
                        // console.log(subitm)
                        // console.log("subitm")
                        // console.log(row)
                        if(subitm.id===row.id)
                        {
                            if(isSelect)
                            {
                                // subitm.isModified= isSelect ? "1" : "0"
                                subitm.isDeleted = isSelect ? "1" : "0"
                            }
                        }
                    })
                })
                // console.log(this.state.eventEmployeeDDL)
              }
          };
    return(
            <div>
                <Banner />
                    <Container className="themed-container" fluid={true}>
                        <Card className="mt-5">
                            <Card.Header>EVENT SCHEDULE LIST</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div>
                                        {this.state.message}
                                    </Alert>
                                    <Card>
                                        <Card.Header>EVENT DETAILS</Card.Header>
                                        <Card.Body>
                                            {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    Client
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Typeahead
                                                        labelKey='name'
                                                        id="basic-example"
                                                        onChange={this.onChangeClientList}
                                                        options={this.state.clientList}
                                                        placeholder="Select Client"
                                                    />
                                                </Col>
                                            </Form.Group> */}
                                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    Name of Event
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control
                                                        // name="amount"
                                                        placeholder="Enter Event Name"
                                                        value={this.state.eventName}
                                                        onChange={this.onChangeNameOfEvent}
                                                        // readOnly
                                                        autoComplete="off"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    Venue of Event
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control
                                                        // name="amount"
                                                        placeholder="Enter Venue Name"
                                                        value={this.state.eventName}
                                                        onChange={this.onChangeVenueOfEvent}
                                                        // readOnly
                                                        autoComplete="off"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    Area
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Typeahead
                                                        labelKey='name'
                                                        id="basic-example"
                                                        onChange={this.onChangeArea}
                                                        options={this.state.AreaDDL}
                                                        placeholder="SELECT AREA"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    Date
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <DatePicker
                                                        ref='FromDate'
                                                        selected={this.state.fromDate}
                                                        onChange={this.onChangeFromDate}
                                                        minDate={this.minDate}
                                                        value={this.state.fromDate}
                                                        dateFormat={"MM/dd/yyyy"}
                                                        className="form-control"
                                                        placeholderText="ENTER DATE"
                                                    />
                                                </Col>
                                                {/* <Col sm={5}>
                                                    <DatePicker
                                                        ref='FromDate'
                                                        selected={this.state.toDate}
                                                        onChange={this.onChangeToDate}
                                                        minDate={this.minDate}
                                                        value={this.state.toDate}
                                                        dateFormat={"MM/dd/yyyy"}
                                                        className="form-control"
                                                        placeholderText="Date To"
                                                    />
                                                </Col> */}
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                                <Col sm={12}>
                                                    <ButtonToolbar>
                                                        <Button variant="primary" className="ml-auto noser-button" onClick={ this.handleGetEventSchedule }>Search</Button>
                                                        &nbsp;&nbsp;
                                                        <Button href="/payrolleventschedulescreate" variant="success" /* className="ml-auto noser-button" */ onClick={ this.handleCreateEvent }>CREATE</Button>
                                                    </ButtonToolbar>
                                                </Col>
                                            </Form.Group>

                                            <div className="mt-1">
                                                <Card.Header>EVENT LIST</Card.Header>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.eventEmployeeDDL }
                                                    columns = { EmployeeEventColumn }
                                                    // selectRow = { selectRow }
                                                    cellEdit = { cellEditFactory({
                                                            mode: 'dbclick',
                                                            blurToSave: true,
                                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                                this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    // rowEvents={ rowEvents }
                                                    expandRow={ expandRow }
                                                    striped
                                                    hover
                                                    condensed
                                                    loading={true}
                                                    wrapperClasses="table-responsive"
                                                    rowClasses="noser-table-row-class no-checkbox"
                                                    pagination={ paginationFactory({sizePerPageRenderer})}
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                />
                                                <ButtonToolbar sm={12} className='mt-2'>
                                                    <Button variant="success" style={{minWidth:'60px'}} className="ml-auto noser-button-mr1" /* disabled={this.state.disableresetBtn} */ onClick={this.handleSaveEvent}>SAVE</Button>&nbsp;&nbsp;
                                                    <Button variant="danger" style={{minWidth:'60px'}} className="noser-button-mr1" /* disabled={this.state.disableresetBtn} */ onClick={this.handleDeleteItem}>Delete</Button>&nbsp;&nbsp;
                                                    <Button style={{minWidth:'60px'}} variant="danger" href="/Home">CLOSE</Button>
                                                </ButtonToolbar>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                <NoserLoading show={this.state.isloading} />

            </div>
        )
    }

}

export  default EventScheduleList