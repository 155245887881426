import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import DeductionTypeCreate from './DeductionTypeCreate'
class DeductionType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            roleDDL         :   [],
            roleList        :   [],
            roleName        :   '',
            departmentDDL   :   [],
            disableSaveBtn  :   true     ,

            selected: [],
            newDeductionTypeList: [],
            deductionTypeList: [],
            deductionTypeListGrid: [],
            deductionType: "",
            deductionTypeId: "",
            userinfo: [],
          
          
            Show: false,
          
          
            isGridDataChanged: false
        }
    } 
    componentDidMount(){
       

        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetDeductionType()
        sleep(1000).then(() => {
        this.setState({isLoading:false})})
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.handleSearchClick();
        sleep(1000).then(() => {
        this.setState({isLoading:false,
            alerttype   :   "",
            isshow      :   false,
            color       :   "success",
            message     :   "",
            fade        :   false
        })})
        
       
    }

    GetDeductionType(){
        const typeParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "Name":this.state.deductionType
        };
        axios
        .post(
            AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDeductionTypes", typeParams
        )
        .then(res => {
            const data = res.data;
            console.log("deductiontypeList");
            console.log(data);
            this.setState({deductionTypeList: data.deductionTypes});
        })
    };

    
    handleCoverChangeDeductionType = (e) => {
        console.log(e)
        if (e.length > 0) {
            this.state.deductionType= e[0].name
        }
        else {
            this.state.deductionType = ""
        }
        console.log(this.state.deductionTypeList);
        this.GetDeductionType()
    }

     handleSearchClick = event => {
        this.setState({
            deductionTypeListGrid: [],
            newDeductionTypeList: []
        });
        const typeParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "Name":this.state.deductionType
        };
        axios
        .post(
            AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDeductionTypes", typeParams)
        .then(res => {
            const data = res.data;
            console.log("test");
            console.log(data);
            this.setState({deductionTypeListGrid: data.deductionTypes})
        var alertType = (data.status=="1") ? "success" : "danger"
            this.setState(
                {
                    isLoading:false,
                    AlertType:"Success!",
                    show:true,
                    Color:alertType,
                    Message:data.message ,
                    Fade:true
                });
                //this.handleSaveClick();
         })
         .catch(error=>{
            this.setState(
            {
                isLoading:false,
                AlertType:"Error! ",
                Show:true,
                Color:"danger",
                Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                Fade:true
            })
        })

    }

    handleSaveClick = event => {
        this.setState({newDeductionTypeList: [],isLoading:true})
        this.setState({newDeductionTypeList: []})
        for (let i = 0; i < this.state.deductionTypeListGrid.length; i++) {
            if (this.state.deductionTypeListGrid[i]["isModified"] == 1) {
                const obj = {
                    Id: this.state.deductionTypeListGrid[i]["id"],
                    Name: this.state.deductionTypeListGrid[i]["name"],
                    IsDeleted: this.state.deductionTypeListGrid[i]["isDeleted"].toString()
                };

                this.state.newDeductionTypeList.push(obj);
            }
        }

        const typeParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "DeductionTypes":this.state.newDeductionTypeList
        };

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditDeductionType", typeParams)
            .then(res => {
                const data = res.data;
                console.log(res.data);
                this.setState({isLoading:false})
                var alertType = (data.status=="1") ? "success" : "danger"
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
                this.GetDeductionType()
                this.handleSearchClick()
            })
             .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
        })
    }

    LeavePageWithourSavingChanges() {
        const isChanged=false
        for (let i = 0; i < this.state.deductionTypeListGrid.length; i++) {
            if (this.state.deductionTypeListGrid[i]["isModified"] == 1) {
                this.setState({isGridDataChanged: true})
                isChanged=true
                break;
            }
        }
        return isChanged
    }

    GridDataModified(oldValue, newValue, deductiontypeid, column) {
        console.log(deductiontypeid)
        this.state.deductionTypeListGrid.map(function(item,i) {
            if (item.id===deductiontypeid)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    };

    render(){
        const columndeduction = [
            {
                dataField: 'name',
                text: 'Deduction Type',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'100%' }},
                style:{textAlign:'left',width:'100%'},
            },
        ]
       const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.deductionTypeListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header> DEDUCTION TYPE</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                 <Form.Row>
                                    <Col sm={12} >
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleCoverChangeDeductionType}
                                            options={this.state.deductionTypeList}
                                            placeholder="Select Deduction Type"
                                        />
                                    </Col>
                                </Form.Row>&nbsp;&nbsp;
                                <Form.Row>
                                    <Form.Group as={Col} sm={12} controlId="formHorizontalEmail">
                                        <div className="mt-1">
                                            
                                            <ButtonToolbar>
                                                <Button style={{minWidth:'60px'}} className='ml-auto' variant="primary"  onClick={ this.handleSearchClick } >
                                                    search
                                                </Button>&nbsp;&nbsp;
                                                <Button style={{minWidth:'60px'}} variant="success" onClick={this.handleModalShow}>
                                                    Create
                                                </Button>&nbsp;&nbsp;
                                               
                                            </ButtonToolbar>
                                        </div>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                        <Card.Header>Record</Card.Header>
                                        <div className="mt-1">
                                    <BootstrapTable
                                        rowClasses="noser-table-row-class"
                                        striped
                                        hover
                                        condensed
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        keyField = "id"
                                        data = { this.state.deductionTypeListGrid}
                                        columns = { columndeduction}
                                        selectRow = { selectRow }
                                        cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                                    }
                                                rowEvents={ rowEvents }

                                            />
                                    <ButtonToolbar sm={12}>
                                        <Button variant="success" className="ml-auto" onClick={this.handleSaveClick} style={{minWidth:'60px'}}>
                                            Save
                                        </Button>&nbsp;&nbsp;
                                        <NavLink to="/home">
                                            <Button variant="danger" href="/banner" style={{minWidth:'60px'}}>
                                                Close
                                            </Button>&nbsp;&nbsp;
                                        </NavLink>
                                    </ButtonToolbar>
                                </div>
                                        
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
                <DeductionTypeCreate 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div>
        )
    }
}

export default DeductionType;