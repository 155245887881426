import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

import  TrainingRegisterModal from '../Training/TrainingModal/TrainingRegisterModal'
import { Input } from 'reactstrap';

const Tonow = moment().hour(0).minute(0);
const showSecond = false;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';
 
class IncidentReportForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            regexp: /^[0-9\b]+$/,

            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete           :   [],
            clientAutocomplete          :   [],
            positionAutocomplete        :   [],
            trainingAutocomplete        :   [],
            scheduleAutocomplete        :   [],
            trainingRegisterTableList   :   [],
            batchNoAutocomplete         :   [],
            dateFrom                    :   new Date(),
            dateTo                      :   new Date(),
            modalTrainingRegisterShow   :   false,

            isLoading               :"",

            selectedEmployeeName    :[],
            selectedEmployeeId      :[],
            selectedEmployeeNo      :[],
            selectedEmployeeBranch  :[],
            

            getClientList           :[],
            getEmployeeList         :[],
            getEmployeeNoList       :[],
            getReportedEmployeeList :[],
            checkReported:"",

            client                  :"",
            position                :"",
            employeeName            :"",
            employeeNo              :"",

            nonEmpName              :"",
            nonEmpCompany           :"",
            nonEmpPosition          :"",
           // timeOfIncident:"",

            dateReported            :"",
            dateOfIncident          :"",
            venueOfIncident             :"",
            timeOfIncident              :moment(),
            irReferenceNo               :"",
            question1                   :"",
            question2                   :"",
            question3                   :"",
            question4                   :"",

            reportedByClient            :"",
            reportedByClientId          :"",
            reportedByEmployee          :"",
            reportedByPosition          :"",
            reportedByDept              :"",
            
            departmentCodeAutocomplete  :"",
            disabledSave                :false,
            selectedClientId            :"",
            IRobjId                     :"",

        }
        this.onChangeTo=this.onChangeTo.bind(this);
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})

        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;
        let dataCheck = JSON.parse(sessionStorage.getItem("nextProcessCheck"))
       // let updateIR = "2" //should be 2 when update because 1 is for next process
        if(dataCheck =="2"){
            let IRData = JSON.parse(sessionStorage.getItem("IRData"))
            if(IRData.status == "NTE" ){
                this.setState({
                    disabledSave:true
                })
            }
            if(IRData.status == "NOH" ){
                this.setState({
                    disabledSave:true
                })
            }
            if(IRData.status == "NOD" ){
                this.setState({
                    disabledSave:true
                })
            }
            if(IRData.status == "RecordNODPenalty" ){
                this.setState({
                    disabledSave:true
                })
            }
            //let IRData = JSON.parse(sessionStorage.getItem("IRData"))




            // this.setState({

            //     irReferenceNo               : IRData.irReferenceNo,
            //     client          : IRData.client,
            //     employeeName        : IRData.employeeName,
            //     employeeNo          : IRData.employeeNo,
            //     position                    : IRData.position,
            //     selectedEmployeeBranch      : IRData.location,
            //     disableEmployee             : true,
            //     employeePlaceHolder         : IRData.employeeName,
            //     selectedClientId            : IRData.ClientId,
                
            //     dateReported                :new Date(IRData.dateOfIncident) ,
            //     selectedClientId            : IRData.ClientId,
            //     selectedClientId            : IRData.ClientId,
            //     selectedClientId            : IRData.ClientId,

            // })

            this.getIRbyRefNo()

        }

       // this.trygetED()
        
        
    }

    

    getIRbyRefNo=()=>{
        this.setState({
            isloading:true
        })
        console.log("getIRbyRefNo start")

        let IRData = JSON.parse(sessionStorage.getItem("IRData"))

        console.log(IRData)

        let filter_data ={"irReferenceNo" : IRData.irReferenceNo}
        console.log("filter_data")
        console.log(filter_data)
        const getParams ={
            "_collection" : "IncidentReport",
            "filter_data" : filter_data
        }
        axios
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        .then(res =>{
            const data = res.data
            const itemUsersDataLists = this.buildListIR(data["IncidentReport"])
            this.state.itemUserDataList  =  itemUsersDataLists
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                let obj = {

                    'id'                        :   this.state.itemUserDataList[i]['id'],
                }
                this.setState({
                    IRobjId: obj.id,
                })
                
            }
            console.log("Raw Json getIRbyRefNo")
            console.log(data)
            console.log("End Raw Json getIRbyRefNo")
            var obj = (data);
            var res = [];
            
            data.IncidentReport.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                res.push(json)
            })

            console.log("Parsed Json below")
            console.log(res)
            //let refNo = JSON.stringify(res.length)

            // this.setState({
            //     nodaRefNo:refNo,
            // })

            let newtime = moment("1/1/2022"+" "+res[0].timeOfIncident)
            let getTime = moment(newtime).format("HH:mm")
            console.log("newtime")
            console.log(newtime)
            console.log("getTime")
            console.log(getTime)

            this.setState({
                client                :   res[0].client,
                selectedEmployeeBranch:   res[0].location,
                employeeName          :   res[0].employeeName,
                position              :   res[0].position,
                employeeNo            :   res[0].employeeNo,
                venueOfIncident       :   res[0].venueOfIncident,
                timeOfIncident        :   getTime,
                question1             :   res[0].question1,
                question2             :   res[0].question2,
                question3             :   res[0].question3,
                question4             :   res[0].question4,
                reportedByClient      :   res[0].reportedByClient,
                reportedByEmployee    :   res[0].reportedByEmployee,
                reportedByPosition    :   res[0].reportedByPosition,
                reportedByDept        :   res[0].reportedByDept,
                ClientId              :   res[0].selectedClientId,
                reportedByClientId    :   res[0].reportedByClientId,
                dateOfIncident        :   new Date(res[0].dateOfIncident),
                dateReported            :   new Date(res[0].dateReported),
                irReferenceNo          : res[0].irReferenceNo
            })
           this.tryconsole()
        })
        .catch(error=>{
            console.log("error: " + error)
        })

    }

    tryconsole=(e)=>{
        console.log("this.state.timeOfIncident")
        console.log(this.state.timeOfIncident)
    }

    buildListIR(data) {
        let itemList =[]
    
        let idList                      =[]

        for (let i = 0; i < data.length; i++) {            
    
            let s1 = data[i].split("', '")
          
            let IdClean0                            = s1[0].split("': ")[1]           
            let idClean1                            = IdClean0.replace("ObjectId(","").replace(")","").replace("{","").replace(", 'action","")            
            let idClean2                            = idClean1.split(",")[0]

            idList.push(idClean2.replace("'","").replace("'","").replace(" ",""))
           
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                         :   idList[i],
            
            }
            itemList.push(obj)
            
        }
        return itemList
    }

    /* tryconsole=()=>{
        console.log(this.state.timeOfIncident)
    } */

    onChangeTo(timeOfIncident) {

       /*  if(this.state.timeOfIncident !== ""){
            this.state.timeOfIncident = ""
        } */
       
        this.state.timeOfIncident = timeOfIncident && timeOfIncident.format(str)
        console.log("format");
        console.log( this.state.timeOfIncident);
    }

    GetDepartment() {
        const departmentCodeParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "",
            "Name"          :   ""
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDepartments",  departmentCodeParams)
        .then(res => {
            const data = res.data;
            console.log("Get Departments Code");
            console.log(data);
            console.log("Get Departments Code");
            this.setState({
                departmentCodeAutocomplete  :   data.departments
            });
        })

        console.log(this.state.departmentCodeAutocomplete)
    }

    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            console.log("Client List start ");
            console.log(res.data);
            console.log("Client List end");
            const data = res.data
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }

    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 

        this.state.selectedClientId = e[0].id
        this.state.client = e[0].name.replace("'","")

        this.setState({
            isloading       :true,
            isshow          :false,
            color           :"",
            message         :"",
            fade            :true ,
            checkReported   :"1"
        })
       

        this.state.checkReported = "1"
        this.getEmployees();

    }

    getEmployees(){

        let getParams = {}

        this.setState({
            isloading:true,
        })
       
        if(this.state.checkReported =="1" || this.state.checkReported === "1"){
            
            getParams = {
                "IpAddress":"0.0.0.0",
                "ClientId":this.state.selectedClientId,
                "UserId":this.state.userinfo.userId,
                "EmployeeName":"",
                "EmployeeNo":"",
                "ClientName":""
            }

        }else{

            getParams = {
                "IpAddress":"0.0.0.0",
                "ClientId":this.state.reportedByClientId,
                "UserId":this.state.userinfo.userId,
                "EmployeeName":"",
                "EmployeeNo":"",
                "ClientName":""
            }

        }

        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
          
            const data = res.data
            if(data.status=="1"){

                if(this.state.checkReported =="1" || this.state.checkReported === "1"){
                    this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
                }else{
                    this.setState({getReportedEmployeeList : data.employees, isloading:false,}) 
                }
               
            }
            else{
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
            }
                
        })
        .catch(error=>{
            this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
        })
        
    }

    onChangeEmployeesList = (e) => {
        if(e.length==0)
        {
            //this.setState({employeeName: "", })
            this.state.employeeName = ""
          
            return
        }
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeBranch = e[0].locationName.replace("'","")
      
        this.state.employeeName = e[0].employeeName
        this.state.employeeNo = e[0].employeeNo
        this.state.position = e[0].position
       
        this.setState({isloading:false})
    }

    onChangeEmployeesNo=(e)=>{
        this.setState({selectedEmployeeNo : e.target.value})
        //console.log(e.target.value)
    }

    onChangePosition=(e)=>{
        this.setState({position : e.target.value})
        //console.log(e.target.value)
    }

    onChangeNonEmpName=(e)=>{
        this.setState({nonEmpName : e.target.value})
        console.log(e.target.value)
    }

    onChangeNonEmpCompany=(e)=>{
        this.setState({nonEmpCompany : e.target.value})
        console.log(e.target.value)
    }

    onChangeNonEmpPosition=(e)=>{
        this.setState({nonEmpPosition : e.target.value})
        console.log(e.target.value)
    }

    onChangeDateReported = date => {
        
        this.setState({
            dateReported: date,
            isshow:false,
        })

    }

    onChangeDateOfIncident = date => {
        
        this.setState({
            dateOfIncident: date,
            isshow:false,
        })

    }

    onChangeVenueOfIncident=(e)=>{
        this.setState({venueOfIncident : e.target.value})
        //console.log(e.target.value)
    }

   /*  onChangetimeOfIncident=(e)=>{
        this.setState({timeOfIncident : e.target.value})
        //console.log(e.target.value)
    } */

    onChangeIrReferenceNo=(e)=>{
        this.setState({irReferenceNo : e.target.value})
        //console.log(e.target.value)
    }

    onChangeQuestion1=(e)=>{
        this.setState({question1 : e.target.value})
        //console.log(e.target.value)
    }

    onChangeQuestion2=(e)=>{
        this.setState({question2 : e.target.value})
        //console.log(e.target.value)
    }

    onChangeQuestion3=(e)=>{
        this.setState({question3 : e.target.value})
        //console.log(e.target.value)
    }

    onChangeQuestion4=(e)=>{
        this.setState({question4 : e.target.value})
        //console.log(e.target.value)
    }

    onChangeReportedByClient=(e)=>{

        if(e.length == 0) {
            this.state.reportedByClient=""
            this.state.reportedByClientId=""
            return
        } 

        this.state.reportedByClient = e[0].name.replace("'","")
        this.state.reportedByClientId = e[0].id

        this.setState({
            isloading:true,
            isshow:false,
            color:"",
            message:"",
            fade:true ,
            checkReported:"0",
        })
        this.state.checkReported ="0"
        
        this.getEmployees();

    }

    onChangeReportedEmployee=(e)=>{

        if(e.length==0)
        {
            this.state.reportedByEmployee = ""
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.reportedByEmployee = e[0].employeeName
        this.state.reportedByPosition = e[0].position

        this.setState({isloading:false})
    }

    onChangeReportedPosition=(e)=>{
        this.setState({reportedByPosition : e.target.value})
        //console.log(e.target.value)
    }

    onChangeReportedDept=(e)=>{
        this.setState({reportedByDept : e.target.value})
        //console.log(e.target.value)
    }
   
    handleGenerateIR=(e)=>{
        
        console.log("this.state.timeOfIncident")
        console.log(this.state.timeOfIncident)
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');
        let _dateReported = moment(this.state.dateReported).format('MM/DD/YYYY');
        let _dateOfIncident = moment(this.state.dateOfIncident).format('MM/DD/YYYY');

        let data = {
            "client"                :   this.state.client,
            "location"              :   this.state.selectedEmployeeBranch,
            "employeeName"          :   this.state.employeeName,
            "position"              :   this.state.position,
            "employeeNo"            :   this.state.employeeNo,
            "nonEmpName"            :   this.state.nonEmpName,
            "nonEmpCompany"         :   this.state.nonEmpCompany,
            "nonEmpPosition"        :   this.state.nonEmpPosition,
            "dateReported"          :   _dateReported,
            "dateOfIncident"        :   _dateOfIncident,
            "venueOfIncident"       :   this.state.venueOfIncident,
            "timeOfIncident"        :   this.state.timeOfIncident,
            "irReferenceNo"         :   this.state.irReferenceNo,
            "question1"             :   this.state.question1,
            "question2"             :   this.state.question2,
            "question3"             :   this.state.question3,
            "question4"             :   this.state.question4,
            "reportedByClient"      :   this.state.reportedByClient,
            "reportedByEmployee"    :   this.state.reportedByEmployee,
            "reportedByPosition"    :   this.state.reportedByPosition,
            "reportedByDept"        :   this.state.reportedByDept,
            "isDraft"               :   "0",
            "isDeleted"             :   "0",
            "createdby"             :   "user007",
            "createddate"           :   createddate,
            "modifiedby"            :   'user007',
            "modifieddate"          :   '0',
            "isModified"            :   '0',    
        }
        console.log(data)

        sessionStorage.setItem("data", JSON.stringify(data))
        let viewIRTmp = "1"
        sessionStorage.setItem("viewDraftMemoTmp", JSON.stringify(viewIRTmp))
        
     
    }

    getIRrefNo=(e)=>{
       

        /* let filter_data ={}
        const getParams ={
            "_collection" : "IncidentReport",
            "filter_data" : filter_data
        }
        console.log("axios start")
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data
            console.log("Raw Json111")
            console.log(data)
            console.log("End Raw Json111")
            var obj = (data);
            var res = [];
            
            data.IncidentReport.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                res.push(json)
            })
            
            let refNotmp = ""
            refNotmp = res.length
            console.log("Parsed Json below")
            console.log(refNotmp)


            this.setState({
                irReferenceNo: refNotmp,
                isloading:false
            })
                            
            
            
        })
        .catch(error=>{
            console.log("error: " + error)
        }) */
       
        this.setState({
            //irReferenceNo: newIRRef,
            isloading:false
        })
        this.handleSave();
    
        
    }
 
    //save IR
    handleSave=(e)=>{
        let reIRDate        = moment(this.state.createddate).format('MMDDYYYY');
        let newIRRef        = this.state.employeeNo+reIRDate
        let dataCheck = JSON.parse(sessionStorage.getItem("nextProcessCheck"))
        // let updateIR = "2" //should be 2 when update because 1 is for next process
        if(dataCheck =="2"){
           /*  this.setState({
                isloading       :   false,
                alerttype       :   "warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Under Development",
                fade            :   true
            })
            return */
            this.updateIR()

        }else{

            let createddate     = moment(this.state.createddate).format('MM/DD/YYYY');
            let _dateReported   = moment(this.state.dateReported).format('MM/DD/YYYY');
            let _dateOfIncident = moment(this.state.dateOfIncident).format('MM/DD/YYYY');
            let reIRDate        = moment(this.state.createddate).format('MMDDYYYY');
            //let newIRRef        = this.state.employeeNo+reIRDate
    
            if(this.state.dateReported === "" || this.state.dateReported == ""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "Please Set Reported Date",
                    fade            :   true
                })
                return
            }
    
            if(this.state.employeeName === "" || this.state.employeeName == ""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "Please Set Employee Name",
                    fade            :   true
                })
                return
            }
            if(this.state.dateOfIncident ==="" || this.state.dateOfIncident == ""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "Please Set Incident Date",
                    fade            :   true
                })
                return
            }
            if(this.state.employeeNo ==="" || this.state.employeeNo == ""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "No Employee No",
                    fade            :   true
                })
                return
            }
    
            let data = {
    
                "client"                :   this.state.client,
                "location"              :   this.state.selectedEmployeeBranch,
                "employeeName"          :   this.state.employeeName,
                "position"              :   this.state.position,
                "employeeNo"            :   this.state.employeeNo,
                
                "dateReported"          :   _dateReported,
                "dateOfIncident"        :   _dateOfIncident,
                "venueOfIncident"       :   this.state.venueOfIncident,
                "timeOfIncident"        :   this.state.timeOfIncident,
                "irReferenceNo"         :   newIRRef,
                "question1"             :   this.state.question1.replace(/'/g, '').replace(/"/g, ''),
                "question2"             :   this.state.question2.replace(/'/g, '').replace(/"/g, ''),
                "question3"             :   this.state.question3.replace(/'/g, '').replace(/"/g, ''),
                "question4"             :   this.state.question4.replace(/'/g, '').replace(/"/g, ''),
                "reportedByClient"      :   this.state.reportedByClient,
                "reportedByEmployee"    :   this.state.reportedByEmployee,
                "reportedByPosition"    :   this.state.reportedByPosition,
                "reportedByDept"        :   this.state.reportedByDept,
                "processStatus"         :   "NTE",
                "isDraft"               :   "0",
                "isDeleted"             :   "0",
                "createdby"             :   "user007",
                "createddate"           :   createddate,
                "modifiedby"            :   'user007',
                "modifieddate"          :   '0',
                "isModified"            :   '0',  
                "ClientId"              :   this.state.selectedClientId,
                "reportedByClientId"    :   this.state.reportedByClientId,
    
            }
            
            const addParams = {
              "_collection" : "IncidentReport",
              "doc_data"    : data
            }
            console.log(addParams)
              
            axios
            .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
            //.post("http://134.209.99.190:8088/action/add", addParams)
            .then(res =>{
                const data = res.data
                console.log("success")
                console.log(data)
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   "Data successfully Saved" ,
                    fade            :   true,
                    disabledSave    :   true,
                    
    
                })
                this.saveToEDMonitoring()
    
            })
            .catch(error =>{
                console.log("error: " + error)
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })
        }
        
        
    }

    updateIR=(e)=>{

        let dataCheck = JSON.parse(sessionStorage.getItem("nextProcessCheck"))
        // let updateIR = "2" //should be 2 when update because 1 is for next process

        let createddate     = moment(this.state.createddate).format('MM/DD/YYYY');
        let _dateReported   = moment(this.state.dateReported).format('MM/DD/YYYY');
        let _dateOfIncident = moment(this.state.dateOfIncident).format('MM/DD/YYYY');
        let reIRDate        = moment(this.state.createddate).format('MMDDYYYY');
        let newIRRef        = this.state.employeeNo+reIRDate

        let updateTime        = moment(this.state.timeOfIncident).format('HH:mm');

        if(this.state.dateReported === "" || this.state.dateReported == ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please Set Reported Date",
                fade            :   true
            })
            return
        }

        if(this.state.employeeName === "" || this.state.employeeName == ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please Set Employee Name",
                fade            :   true
            })
            return
        }
        if(this.state.dateOfIncident ==="" || this.state.dateOfIncident == ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please Set Incident Date",
                fade            :   true
            })
            return
        }
        if(this.state.employeeNo ==="" || this.state.employeeNo == ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "No Employee No",
                fade            :   true
            })
            return
        }

        let data = {

            "client"                :   this.state.client,
            "location"              :   this.state.selectedEmployeeBranch,
            "employeeName"          :   this.state.employeeName,
            "position"              :   this.state.position,
            "employeeNo"            :   this.state.employeeNo,
            
            "dateReported"          :   _dateReported,
            "dateOfIncident"        :   _dateOfIncident,
            "venueOfIncident"       :   this.state.venueOfIncident,
            "timeOfIncident"        :   updateTime,
            "irReferenceNo"         :   newIRRef,
            "question1"             :   this.state.question1.replace(/'/g, '').replace(/"/g, ''),
            "question2"             :   this.state.question2.replace(/'/g, '').replace(/"/g, ''),
            "question3"             :   this.state.question3.replace(/'/g, '').replace(/"/g, ''),
            "question4"             :   this.state.question4.replace(/'/g, '').replace(/"/g, ''),
            "reportedByClient"      :   this.state.reportedByClient,
            "reportedByEmployee"    :   this.state.reportedByEmployee,
            "reportedByPosition"    :   this.state.reportedByPosition,
            "reportedByDept"        :   this.state.reportedByDept,
            "processStatus"         :   "NTE",
            "isDraft"               :   "0",
            "isDeleted"             :   "0",
            "createdby"             :   "user007",
            "createddate"           :   createddate,
            "modifiedby"            :   'user007',
            "modifieddate"          :   '0',
            "isModified"            :   '0',  
            "ClientId"              :   this.state.selectedClientId,
            "reportedByClientId"    :   this.state.reportedByClientId,

        }
        
        const updateParams = {

            "_collection" : "IncidentReport",
            "doc_data"    : data,
            "_id"         : this.state.IRobjId,

        }
        console.log("updateIR")
        console.log(updateParams)
        this.getIRobjID()

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/update_one", updateParams)
        //.post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(data)
            this.setState({
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Data successfully Saved" ,
                fade            :   true,
                disabledSave    :   true,
                

            })
            //this.saveToEDMonitoring()

        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    buildListEDMonitoring(data) {
        // console.log("data start")
        // console.log(data )
        console.log("data start")
        console.log(data )
        console.log("data" )
        let itemList =[]
    
        let idList                      =[]
        let quotedStatementList         =[]
        let dateOfNTEList               =[]
        let clientList                  =[]
        let createdbyList               =[]
        let createddateList             =[]
        let cycleOfDaysList             =[]
        let dateOfIncidentList          =[]
        let dateOfNODList               =[]
        let dateOfNOHList               =[]
        let dateRecordNodaPenaltyList   =[]
        let employeeNameList            =[]
        let employeeNoList              =[]
        let irReferenceNoList           =[]
        let isDeletedList               =[]
        let isDraftList                 =[]
        let isModifiedList              =[]
        let locationList                =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let positionList                =[]
        let retailgroupList             =[]
        let statusList                  =[]
        let articleList                 =[]
        let categoryList                =[]
        let sectionList                 =[]
        let NTEReferenceNoList                 =[]
        let NODReferenceNoList                 =[]
        let NOHReferenceNoList                 =[]
        let ClientIdList                 =[]

        let NTHoffenseList                 =[]
        let penaltyList                 =[]
        let monetaryChargeList                 =[]
        let statusOfIssueList                 =[]

        for (let i = 0; i < data.length; i++) {            
    
            let s1 = data[i].split("', '")
            console.log("s1 start")
            console.log(s1 )
            console.log("s1" )
          
            let IdClean0                            = s1[0].split("': ")[1]           
            let idClean1                            = IdClean0.replace("ObjectId(","").replace(")","").replace("{","").replace(", 'action","")  
            let quotedStatementTmp                  = s1[1].split("': ")[1]    
            let dateOfNTETmp                        = s1[2].split("': ")[1]           
            let clientTmp                        = s1[3].split("': ")[1]        
            let createdbyTmp                     = s1[4].split("': ")[1]          
            let createddateTmp                   = s1[5].split("': ")[1]
            let cycleOfDaysTmp                   = s1[6].split("': ")[1]
            let dateOfIncidentTmp                = s1[7].split("': ")[1]
            let dateOfNODTmp                     = s1[8].split("': ")[1]
            let dateOfNOHTmp                     = s1[9].split("': ")[1]
            let dateRecordNodaPenaltyTmp         = s1[10].split("': ")[1]
            let employeeNameTmp                  = s1[11].split("': ")[1]
            let employeeNoTmp                    = s1[12].split("': ")[1]
            let irReferenceNoTmp                 = s1[13].split("': ")[1]
            let isDeletedTmp                     = s1[14].split("': ")[1]
            let isDraftTmp                       = s1[15].split("': ")[1]
            let isModifiedListTmp                = s1[16].split("': ")[1]            
            let locationTmp                      = s1[17].split("': ")[1]
            let modifiedbyListTmp                = s1[18].split("': ")[1]
            let modifieddateListTmp              = s1[19].split("': ")[1]
            let positionTmp                      = s1[20].split("': ")[1]
            let retailgroupTmp                   = s1[21].split("': ")[1]
            let statusTmp                        = s1[22].split("': ")[1]
            let articleTmp                       = s1[23].split("': ")[1]
            let categoryTmp                      = s1[24].split("': ")[1]
            let sectionTmp                       = s1[25].split("': ")[1]
            let NTEReferenceNoTmp                = s1[26].split("': ")[1]
            let NODReferenceNoTmp                = s1[27].split("': ")[1]
            let NOHReferenceNoTmp                = s1[28].split("': ")[1]
            let ClientIdTmp                      = s1[29].split("': ")[1]
            let NTHoffenseTmp                    = s1[30].split("': ")[1]
            let penaltyTmp                       = s1[31].split("': ")[1]
            let monetaryChargeTmp                = s1[32].split("': ")[1]
            let statusOfIssueTmp                 = s1[33].split("': ")[1]

          

            idList.push(idClean1.replace("'","").replace("'","").replace(" ",""))
            quotedStatementList.push(quotedStatementTmp.replace("'",""))
            dateOfNTEList.push(dateOfNTETmp.replace("'","").replace(" ",""))           
            clientList.push(clientTmp.replace("'",""))
            createdbyList.push(createdbyTmp.replace("'",""))
            createddateList.push(createddateTmp.replace("'",""))
            cycleOfDaysList.push(cycleOfDaysTmp.replace("'",""))
            dateOfIncidentList.push(dateOfIncidentTmp.replace("'",""))
            dateOfNODList.push(dateOfNODTmp.replace("'",""))
            dateOfNOHList.push(dateOfNOHTmp.replace("'",""))
            dateRecordNodaPenaltyList.push(dateRecordNodaPenaltyTmp.replace("'",""))
            employeeNameList.push(employeeNameTmp.replace("'",""))
            employeeNoList.push(employeeNoTmp.replace("'",""))
            irReferenceNoList.push(irReferenceNoTmp.replace("'",""))
            isDeletedList.push(isDeletedTmp.replace("'",""))
            isDraftList.push(isDraftTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'",""))
            locationList.push(locationTmp.replace("'",""))
            positionList.push(positionTmp.replace("'",""))
            retailgroupList.push(retailgroupTmp.replace("'",""))
            statusList.push(statusTmp.replace("'",""))
            articleList.push(articleTmp.replace("'",""))
            categoryList.push(categoryTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            sectionList.push(sectionTmp.replace("'",""))
            NTEReferenceNoList.push(NTEReferenceNoTmp.replace("'",""))
            NODReferenceNoList.push(NODReferenceNoTmp.replace("'",""))
            NOHReferenceNoList.push(NOHReferenceNoTmp.replace("'",""))
            ClientIdList.push(ClientIdTmp.replace("'",""))

            NTHoffenseList.push(NTHoffenseTmp.replace("'",""))
            penaltyList.push(penaltyTmp.replace("'",""))
            monetaryChargeList.push(monetaryChargeTmp.replace("'",""))
            statusOfIssueList.push(statusOfIssueTmp.replace("'","").replace("'}",""))

          
            
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                         :   idList[i],
                'quotedStatement'            :   quotedStatementList[i],
                'dateOfNTE'                  :   dateOfNTEList[i],
                'client'                     :   clientList[i],              
                'cycleOfDays'                :   cycleOfDaysList[i],
                'dateOfIncident'             :   dateOfIncidentList[i],
                'dateOfNOD'                  :   dateOfNODList[i],
                'dateOfNOH'                  :   dateOfNOHList[i],
                'dateRecordNodaPenalty'      :   dateRecordNodaPenaltyList[i],
                'employeeName'               :   employeeNameList[i],
                'employeeNo'                 :   employeeNoList[i],
                'irReferenceNo'              :   irReferenceNoList[i],              
                'location'                   :   locationList[i],
                'position'                   :   positionList[i],
                'retailgroup'                :   retailgroupList[i],
                'status'                     :   statusList[i],
                'article'                    :   articleList[i],
                'category'                   :   categoryList[i],
                'section'                    :   sectionList[i],
                'isDraft'                    :   isDraftList[i],
                'isDeleted'                  :   isDeletedList[i],
                'createdby'                  :   createdbyList[i],
                'createddate'                :   createddateList[i],
                'modifiedby'                 :   modifiedbyList[i],
                'modifieddate'               :   modifieddateList[i],
                'isModified'                 :   isModifiedList[i],
                'NTEReferenceNo'             :   NTEReferenceNoList[i],
                'NODReferenceNo'             :   NODReferenceNoList[i],
                'NOHReferenceNo'             :   NOHReferenceNoList[i],
                'ClientId'                   :   ClientIdList[i],                
                "NTHoffense"                 :   NTHoffenseList[i],
                "penalty"                    :   penaltyList[i],
                "monetaryCharge"             :   monetaryChargeList[i],
                "statusOfIssue"              :   statusOfIssueList[i],
            
            }
            itemList.push(obj)
            console.log("itemList start")
            console.log(itemList )
            console.log("itemList" )
            
        }
        return itemList
    }

    getIRobjID=()=>{
       
        let filter_data ={'irReferenceNo':this.state.irReferenceNo}

        const getParams ={
            "_collection" : "EDMonitoring",
            "filter_data" : filter_data
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data

            if(data.EDMonitoring.length > 0){

                const itemUsersDataLists = this.buildListEDMonitoring(data["EDMonitoring"])
            
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List1 =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                    let obj = {

                        'id'                        :   this.state.itemUserDataList[i]['id'],
                        'quotedStatement'           :   this.state.itemUserDataList[i]['quotedStatement'],
                        'dateOfNTE'                 :   this.state.itemUserDataList[i]['dateOfNTE'],
                        'client'                    :   this.state.itemUserDataList[i]['client'],              
                        'cycleOfDays'               :   this.state.itemUserDataList[i]['cycleOfDays'],
                        'dateOfIncident'            :   this.state.itemUserDataList[i]['dateOfIncident'],
                        'dateOfNOD'                 :   this.state.itemUserDataList[i]['dateOfNOD'],
                        'dateOfNOH'                 :   this.state.itemUserDataList[i]['dateOfNOH'],
                        'dateRecordNodaPenalty'     :   this.state.itemUserDataList[i]['dateRecordNodaPenalty'],
                        'employeeName'              :   this.state.itemUserDataList[i]['employeeName'],
                        'employeeNo'                :   this.state.itemUserDataList[i]['employeeNo'],
                        'irReferenceNo'             :   this.state.itemUserDataList[i]['irReferenceNo'],              
                        'location'                  :   this.state.itemUserDataList[i]['location'],
                        'position'                  :   this.state.itemUserDataList[i]['position'],
                        'retailgroup'               :   this.state.itemUserDataList[i]['retailgroup'],
                        'status'                    :   this.state.itemUserDataList[i]['status'],
                        'article'                   :   this.state.itemUserDataList[i]['article'],
                        'category'                  :   this.state.itemUserDataList[i]['category'],
                        'section'                   :   this.state.itemUserDataList[i]['section'],
                        'isDraft'                   :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'                 :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'                 :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'               :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'                :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'              :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'                :   this.state.itemUserDataList[i]['isModified'],
                        'NTEReferenceNo'            :   this.state.itemUserDataList[i]['NTEReferenceNo'],
                        'NODReferenceNo'            :   this.state.itemUserDataList[i]['NODReferenceNo'],
                        'NOHReferenceNo'            :   this.state.itemUserDataList[i]['NOHReferenceNo'],
                        'ClientId'                    :   this.state.itemUserDataList[i]['ClientId'],
                        "NTHoffense"                :   this.state.itemUserDataList[i]['NTHoffense'],  
                        "penalty"                   :   this.state.itemUserDataList[i]['penalty'],  
                        "monetaryCharge"            :   this.state.itemUserDataList[i]['monetaryCharge'],  
                        "statusOfIssue"             :   this.state.itemUserDataList[i]['statusOfIssue'],  
                        
                        'action'                    :   "1",

                    }
                    List1.push(obj)
                }

                this.setState({
                    //IRobjId: List1[0].id,
                    isloading:false
                })
    
                this.UpdateEDMonitoring(List1)     
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    }

    UpdateEDMonitoring=(List1)=>{


        console.log("List start UpdateEDMonitoring")
        console.log(List1)
        let IRData = JSON.parse(sessionStorage.getItem("IRData"))

        let datetmp = moment(this.state.dateApplied).format('MM/DD/YYYY');
        let _dateOfIncident = moment(this.state.dateOfIncident).format('MM/DD/YYYY');
        
        let data = {
        
            "action"                : "1",
            "quotedStatement"       : "",
            "dateOfNTE"             : "",
            "client"                : this.state.client,
            "createdby"             : List1[0].createdby,
            "createddate"           : List1[0].createddate,
            "cycleOfDays"           : List1[0].cycleOfDays,
            "dateOfIncident"        : _dateOfIncident,
            "dateOfNOD"             : List1[0].dateOfNOD,
            "dateOfNOH"             : List1[0].dateOfNOH,            
            "dateRecordNodaPenalty" : List1[0].dateRecordNodaPenalty,
            "employeeName"          : this.state.employeeName,
            "employeeNo"            : this.state.employeeNo,
            "irReferenceNo"         : this.state.irReferenceNo,
            "isDeleted"             : List1[0].isDeleted,
            "isDraft"               : List1[0].isDraft,
            "isModified"            : List1[0].isModified,
            "location"              : this.state.selectedEmployeeBranch,
            "modifiedby"            : List1[0].modifiedby,
            "modifieddate"          : List1[0].modifieddate,
            "position"              : this.state.position,            
            "retailgroup"           : List1[0].retailgroup,
            "status"                : "IR",
            "article"               : "",
            "category"              : "",
            "section"               : "",
            "NTEReferenceNo"        :List1[0].NTEReferenceNo ,
            "NODReferenceNo"        : List1[0].NODReferenceNo,
            "NOHReferenceNo"        : List1[0].NOHReferenceNo,
            'ClientId'              : this.state.selectedClientId,
            "NTHoffense"            :List1[0].NTHoffense,
            "penalty"               :List1[0].penalty,
            "monetaryCharge"        :List1[0].monetaryCharge,
            "statusOfIssue"         :"Pending",


        }

        const updateParams = {

            "_collection" : "EDMonitoring",
            "doc_data"    : data,
            "_id"         : List1[0].id,

        }

        console.log("updateIRstatus")
        console.log(updateParams)       
        console.log("updateIRstatus")
        
        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/update_one", updateParams)
        //.post("http://134.209.99.190:8088/action/update_one", updateParams)
        .then(res =>{

            this.setState({
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Data successfully Updated" ,
                fade            :   true,
                disabledSave    :   true,
            
            })
           // let nextProcessCheck = "0"
           // sessionStorage.setItem("nextProcessCheck", JSON.stringify(nextProcessCheck)) 
        
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })

        })
    }

    saveToEDMonitoring=()=>{

        let createddate     = moment(this.state.createddate).format('MM/DD/YYYY');
        let _dateReported   = moment(this.state.dateReported).format('MM/DD/YYYY');
        let _dateOfIncident = moment(this.state.dateOfIncident).format('MM/DD/YYYY');
        let reIRDate        = moment(this.state.createddate).format('MMDDYYYY');
        let newIRRef        = this.state.employeeNo+reIRDate

        let data = {
            "action"                :   "1",
            "quotedStatement"       :   "",
            "dateOfNTE"             :   "",
            "client"                :   this.state.client,
            "createdby"             :   "user007",
            "createddate"           :   createddate,
            "cycleOfDays"           :   "",
            "dateOfIncident"        :   _dateOfIncident,
            "dateOfNOD"             :   "",
            "dateOfNOH"             :   "",
            "dateRecordNodaPenalty" :   "",            
            "employeeName"          :   this.state.employeeName,
            "employeeNo"            :   this.state.employeeNo,
            "irReferenceNo"         :   newIRRef,
            "isDeleted"             :   "0",
            "isDraft"               :   "0",
            'isModified'            :   "",
            "location"              :   this.state.selectedEmployeeBranch,
            'modifiedby'            :   "",
            'modifieddate'          :   "",
            "position"              :   this.state.position,
            "retailgroup"           :   "",
            "status"                :   "IR",
            "article"               :   "",
            "category"              :   "",
            "section"               :   "",
            "NTEReferenceNo"            :   '0',
            "NODReferenceNo"            :   '0', 
            "NOHReferenceNo"            :   '0', 
            "ClientId"              :   this.state.selectedClientId,
            "NTHoffense"            :"",
            "penalty"               :"",
            "monetaryCharge"        :"",
            "statusOfIssue"         :"Pending",
           
        }       
        
        const addParams = {
          "_collection" : "EDMonitoring",
          "doc_data"    : data
        }
        console.log(addParams)
          
        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
        //.post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(data)

        })
        .catch(error =>{
            console.log("error: " + error)
        })
    }

    setBack=()=>{
        let nextProcessCheck = "0"
        sessionStorage.setItem("nextProcessCheck", JSON.stringify(nextProcessCheck))
    }

    render() {

    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>INCIDENT REPORT FORM</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>

                                    <Card.Header className="mt-5">DATE / TIME / VENUE</Card.Header>

                                     <Form.Group as={Row} className="mt-4" /* controlId="formPlaintextEmail" */>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Date Reported
                                        </Form.Label>
                                        <Col sm="3">
                                            <DatePicker
                                                ref='dateReported'
                                                selected={this.state.dateReported}
                                                onChange={this.onChangeDateReported}
                                                minDate={this.minDate}
                                                value={this.props.dateReported}
                                                dateFormat={"MM/dd/yyyy"}
                                                /* placeholderText="DATE APPLIED" */
                                                className="form-control"
                                            />
                                          
                                        </Col>
                                        <Col sm="2">
                                        </Col>

                                       {/*  <Form.Label column sm="2" >
                                            Time Of Incident
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.timeOfIncident}
                                                onChange={this.onChangetimeOfIncident} 
                                                autoComplete="off"
                                            />
                                        </Col> */}
                                      
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Time Of Incident
                                        </Form.Label>
                                        <Col sm="3">
                                            <TimePicker 
                                                showSecond={showSecond}
                                                //value={this.state.timeOfIncident}
                                                defaultValue={this.state.timeOfIncident}
                                                className="xxx"
                                                onChange={this.onChangeTo}
                                            />
                                        </Col>

                                    </Form.Group>

                                    <Form.Group as={Row} /* controlId="formPlaintextEmail" */>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Date Of Incident
                                        </Form.Label>
                                        <Col sm="3">
                                             <DatePicker
                                                ref='dateOfIncident'
                                                selected={this.state.dateOfIncident}
                                                onChange={this.onChangeDateOfIncident}
                                                minDate={this.minDate}
                                                value={this.props.dateOfIncident}
                                                dateFormat={"MM/dd/yyyy"}
                                                /* placeholderText="DATE APPLIED" */
                                                className="form-control"
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            IR Reference No.
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.irReferenceNo}
                                                onChange={this.onChangeIrReferenceNo} 
                                                autoComplete="off"
                                                placeholder="System Generated"
                                                disabled={true}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} /* controlId="formPlaintextEmail" */>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Venue Of Incident
                                        </Form.Label>
                                        <Col sm="10">
                                            <Form.Control 
                                                controlId="formBasicEmail"
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.venueOfIncident}
                                                onChange={this.onChangeVenueOfIncident} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Card.Header className="mt-5">PERSON/S TO BE REPORTED</Card.Header>

                                    <Form.Group as={Row} className="mt-3" /* controlId="formPlaintextEmail" */>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                             Employee
                                        </Form.Label>
                                        <Col sm="3">
                                        </Col>
                                        <Col sm="3">`
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} /* controlId="formPlaintextEmail" */>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Location
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeLocation}
                                                options={this.state.selectedEmployeeBranch}
                                                value={this.state.selectedEmployeeBranch}
                                                //placeholder="Select Client"
                                                autocomplete="off"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} /* controlId="formPlaintextEmail" */>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.onChangeEmployeesList}
                                                options={this.state.getEmployeeList}
                                                selected={[this.state.employeeName]}
                                                placeholder="Select Employee"
                                                //autocomplete="false"
                                            />
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Emp No.
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                type="text"
                                                name="employeeNo"
                                                value={this.state.employeeNo}
                                                onChange={this.onChangePosition} 
                                                autoComplete="off"
                                                placeholder="Auto Fill"
                                            />
                                        </Col>                                       
                                    </Form.Group>

                                    <Form.Group as={Row} /* controlId="formPlaintextEmail" */>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.position}
                                                onChange={this.onChangePosition} 
                                                autoComplete="off"
                                                placeholder="Autofill"
                                            />
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        </Form.Label>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="4">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} /* controlId="formPlaintextEmail" */>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                           
                                        </Form.Label>
                                       {/*  <Col sm="3">
                                        <Button variant="success" style={{minWidth:'60px'}} >Add</Button>
                                        </Col> */}
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        </Form.Label>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="4">
                                        </Col>
                                    </Form.Group>

                                    {/* <Form.Group as={Row} className="mt-3">
                                        <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                            NON-Employee
                                        </Form.Label>                                       
                                    </Form.Group>
                                    <Form.Group as={Row} className="mt-3">
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="nonEmpName"
                                                //value={this.state.selectedEmployeeName}
                                                onChange={this.onChangeNonEmpName} 
                                                autoComplete="off"
                                                placeholder="Enter Name"
                                            />
                                        </Col>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="nonEmpCompany"
                                                //value={this.state.selectedEmployeeName}
                                                onChange={this.onChangeNonEmpCompany} 
                                                autoComplete="off"
                                                placeholder="Enter Company"
                                            />
                                        </Col>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="nonEmpPosition"
                                                //value={this.state.selectedEmployeeName}
                                                onChange={this.onChangeNonEmpPosition} 
                                                autoComplete="off"
                                                placeholder="Enter Position"
                                            />
                                        </Col>
                                    </Form.Group> */}
                                    <Form.Group as={Row} className="mt-3" /* controlId="formPlaintextEmail" */>
                                    {/* <Col sm="1">
                                        <Button variant="success" style={{minWidth:'60px', }}>Add</Button>
                                    </Col> */}
                                    </Form.Group>

                                    <Card.Header className="mt-5">BRIEF DESCRIPTION OF INCIDENT</Card.Header>


                                    <Form.Label style={{fontWeight : "bold"}} className="mt-4">
                                        1. What happened?
                                    </Form.Label>
                                    <Form.Control 
                                        className="no-text-transform-li"
                                        type="text"
                                        name="selectedEmployeeName"
                                        value={this.state.question1}
                                        onChange={this.onChangeQuestion1} 
                                        autoComplete="off"
                                    />

                                    <Form.Label className="mt-3" style={{fontWeight : "bold"}}>
                                        2. What physical evidence or document exist to support your report?
                                    </Form.Label>
                                    <Form.Control 
                                        className="no-text-transform-li"
                                        type="text"
                                        name="selectedEmployeeName"
                                        value={this.state.question2}
                                        onChange={this.onChangeQuestion2} 
                                        autoComplete="off"
                                    />

                                    <Form.Label className="mt-3" style={{fontWeight : "bold"}}>
                                        3. How did you know the incident and who are the possible witness/es?
                                    </Form.Label>
                                    <Form.Control 
                                        className="no-text-transform-li"
                                        type="text"
                                        name="selectedEmployeeName"
                                        value={this.state.question3}
                                        onChange={this.onChangeQuestion3} 
                                        autoComplete="off"
                                    />

                                    <Form.Label className="mt-3" style={{fontWeight : "bold"}}>
                                        4. Is there money involved ? If yes please specify the estimated amount?
                                    </Form.Label>
                                    <Form.Control 
                                        className="no-text-transform-li"
                                        type="text"
                                        name="selectedEmployeeName"
                                        value={this.state.question4}
                                        onChange={this.onChangeQuestion4} 
                                        autoComplete="off"
                                    />

                                    <Card.Header className="mt-5">REPORTED BY {/* (option to autofill based on log in details) */}</Card.Header>

                                    <Form.Group as={Row} className="mt-5" /* controlId="formPlaintextEmail" */>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Company
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeReportedByClient}
                                                options={this.state.getClientList}
                                                selected={[this.state.reportedByClient]}
                                                placeholder="Select Company"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mt-3" /* controlId="formPlaintextEmail" */>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.onChangeReportedEmployee}
                                                options={this.state.getReportedEmployeeList}
                                                selected={[this.state.reportedByEmployee]}
                                                placeholder="Select Employee"
                                                autocomplete="false"
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position 
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                type="text"
                                                name="reportedByPosition"
                                                value={this.state.reportedByPosition}
                                                onChange={this.onChangeReportedPosition} 
                                                autoComplete="off"
                                                placeholder="Auto Fill"
                                            />
                                        </Col>
                                      
                                    </Form.Group>
                                   
                                    <Form.Group as={Row} /* controlId="formPlaintextEmail" */ className="mt-4">
                                        <ButtonToolbar sm={1}>
                                            <Col >
                                                <ButtonToolbar>
                                                    <Button disabled={this.state.disabledSave} className="ml-auto" variant="success" onClick = {this.getIRrefNo} style={{minWidth:'60px'}}>Save</Button>&nbsp;&nbsp;&nbsp;
                                                    <Button  onClick={this.setBack} href="/EmployeeDiciplineMonitoring" variant="danger" style={{minWidth:'60px'}}>Back</Button>
                                                </ButtonToolbar>                                          
                                            </Col>                                                                      
                                        </ButtonToolbar>
                                        <ButtonToolbar sm={2}>
                                            <Col>
                                                <ButtonToolbar  className="ml-auto">
                                                    <NavLink to="/ExportIncidentReport">
                                                        <Button className="mr-auto" variant="success" onClick={this.handleGenerateIR} href="/ExportIncidentReport" style={{minHeight:"18px",minWidth:'60px', marginLeft:"1200px", marginTop:"-1px"}}>Generate IR</Button>&nbsp;&nbsp;
                                                    </NavLink>
                                                </ButtonToolbar>                                          
                                            </Col>                                                                      
                                        </ButtonToolbar>                                        
                                    </Form.Group>
                                  

                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                    <TrainingRegisterModal 
                        show={this.state.modalTrainingRegisterShow}
                        onHide={this.handleModalClose}
                        onRefModal={ref => (this.child = ref)}
                    />
            </div> 
        )
    }

}

export  default IncidentReportForm
