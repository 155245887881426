import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

class UploadModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clientId : '',
            clientName : '',
            locationId : '',
            locationName:'',
            periodId:'',
            periodName:'',
            periodStart:'',
            periodEnd:'',
            disable : true,
            uploadfile : [],
            clientDDL:[],
            periodDDL : [],
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL : data.clients ? data.clients : [], isloading:false})
            if(data.clients.length===0){
                this.setState({
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }    
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetPayrollPeriods = async()=>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "SpecialClientConfiguration/GetClientPayrollPeriods", params)
        .then(res => {
            const data = res.data;
            this.setState({periodDDL : data.payrollPeriods,isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleChangeClient = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.clientId= ''
            this.state.clientName=''
            return
        }
        this.state.clientId= e[0].id
        this.state.clientName=e[0].name
        this.GetPayrollPeriods()
    }
    handleChangePeriod = async(e)=>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.periodId= ''
            this.state.periodName=''
            return
        }
        this.state.periodId= e[0].id
        this.state.periodName=e[0].payPeriod
        this.state.periodStart=e[0].startDate
        this.state.periodEnd=e[0].endDate
    }
    setFile = (e) => {
        if(e.target.value.length>0){
            
            for(let i=0;i<e.target.files.length;i++)
            {
                this.state.uploadfile.push(e.target.files[i])
            }
            this.setState({ disable:false});
            console.log(this.state.uploadfile)
        }
        else
            this.setState({ uploadfile: [],disable:true });
    }
    handleSubmit = async()=>{
        this.setState({isloading:true})

        if(this.state.clientId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   true
            })
            return 
        }

        if(this.state.periodId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select Payroll Period.",
                fade        :   true
            })
            return 
        }
        const formData = new FormData();
        formData.append('IpAddress', "0.0.0.0");
        formData.append('ClientId', this.state.clientId);
        formData.append('ClientName', this.state.clientName);
        formData.append('LocationId', this.state.locationId);
        formData.append('LocationName', this.state.locationName);
        formData.append('PeriodId', this.state.periodId);
        formData.append('PeriodName', this.state.periodName);
        formData.append('PeriodStart', this.state.periodStart);
        formData.append('PeriodEnd', this.state.periodEnd);
        formData.append('UserId', this.state.userinfo.userId);
        for(let i=0;i<this.state.uploadfile.length;i++)
        {
            formData.append('File', this.state.uploadfile[i]);
            //this.state.uploadfile.push(this.state.uploadfile[i])
        }
        

        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Timekeeping/UploadSpecialClientBillableHours",  formData, {headers: {'Content-Type': 'multipart/form-data'}}
            )
            .then(res => {
                const data = res.data;
                if(data.status==="1")
                {
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true
                    });
                }
                else{
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }
    render() {

    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Upload Billable Hours
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} controlId="formGridPassword">
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeClient}
                                    options={this.state.clientDDL}
                                    placeholder="Select Client"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} controlId="formGridPassword">
                                <Typeahead
                                        labelKey='payPeriod'
                                        id="basic-example"
                                        onChange={this.handleChangePeriod}
                                        options={this.state.periodDDL}
                                        placeholder="Select Payroll Period"

                                    />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={6} controlId="formGridPassword">
                                <Form.Control id="FirstFile" type="file" accept='.xlsx' onChange={this.setFile} multiple/>
                                {/* <Form.Control id="SecondFile" type="file" accept='.xlsx' onChange={this.setFile} /> */}
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleSubmit } disabled={this.state.disable}>Upload</Button>
                        <Button variant="danger" className="noser-button" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default UploadModal