import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../noser-hris-component';

class DeductionTypeCreate extends Component {
    constructor(props) {
        super(props)
        this.module = React.createRef();
        this.submodule = React.createRef();

        this.state = {
            selected:[],
            deductionTypeList: [],
        selectedDeductionType: "",
        selectedDeductionTypeId: "",
        isloading       :   false,
        isshow          :   false,
        alerttype       :   "",
        message         :   "",
        color           :   "",
        fade            :   true,
        }
        this.textInput = React.createRef();
      
    }
    handleCoverChangeDeductiontype = this.handleCoverChangeDeductiontype.bind(this)
    
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
        this.GetDeductiontype();
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            name        :   '',
            description :   ''
        })
    };

    handleChange(){
        this.state.selectedDeductionType = this.textInput.current.value;
    };

    handleCoverChangeDeductiontype(e){
        if(e.length > 0) {
            this.state.selectedDeductionType = e[0].name
            this.state.selectedDeductionTypeId = this.GetDeductiontypeId(e[0].name)
         }
         else
                 {
                this.state.selectedDeductionType = ""
                this.state.selectedDeductionTypeId = ""
                }
    };

    GetDeductiontype() {
        const typeParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "Name":""
        };
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDeductionTypes", typeParams
            )
            .then(res =>{
                const data = res.data;
                console.log(data)
                this.setState({deductionTypeList: data.deductionTypes});
            })
    };

    handleSaveClick = event => {
        event.preventDefault();
        console.log(this.state.selectedDeductionType);
        console.log(this.state.textInput);
       const typeParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "Name":this.state.selectedDeductionType
        };
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/AddDeductionType", typeParams
            )
            .then(res => {
                const data = res.data;
                console.log(res.data)
                if(data.status=="1")
                {
                    this.setState(
                        { 
                            isshow:true,
                            color:"success",
                            message:data.message ,
                            fade:true
                        });
                    
                    }
                else
                {
                    this.setState(
                        { 
                            show:true,
                            color:"danger",
                            message:data.message,
                            fade:true
                        });
                }
            }) 
        
    };

    handleModalClose = () => {
        this.props.onHide();            
    };
   
    handleKeyUp = (e) =>{

    };

    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Deduction Type - Create
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Form onSubmit={ this.handleSaveClick } ref={form => this.form = form}>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                <Form.Control type="text" placeholder="Enter New Deduction Type" ref={this.textInput} onChange={() => this.handleChange()} autoComplete="off" />
                                </Col>
                            </Form.Group>
                        </Form>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                   
                    <ButtonToolbar sm={12}>
                        <Button variant="success" className="ml-auto" onClick={this.handleSaveClick} style={{minWidth:'60px'}}>
                            Save
                        </Button>&nbsp;&nbsp;
                        <Button variant="danger" onClick={this.props.onHide} style={{minWidth:'60px'}}>
                            Close
                        </Button>
                    </ButtonToolbar>
                   
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default DeductionTypeCreate