import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer
}
from '../../noser-hris-component';
import CreatePhilHealthTable from './Modal/CreatePhilHealthTable';

class PhilHealthTable extends Component {
    constructor() {
        super()
        this.state = {
            userinfo: [],
            isLoading: true,
            AlertType: "",
            Show: false,
            Message: "",
            Color: "",
            Fade: true,
            phicList:[],

            alerttype   :   "",
            isshow      :   false,
            color       :   "",
            message     :   "",
            fade        :   false,

            newphicList : [],

            isGridDataChanged: false,
        }
    }
    componentDidMount() {
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        console.log("this.state.userinfo")
        console.log(this.state.userinfo)
       this.GetPHIC()
    }

    GetPHIC() {
        const phicParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "isDeleted" : "0"
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPHICBrackets",  phicParams)
        .then(res => {
             const data = res.data;
             console.log(" hugot ng data")
             console.log(data)
             this.setState({ phicList: data.brackets });
         })
    };

    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    };

    handleModalClose = (e) =>{
        this.setState({openModal:false})
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        //this.handleSearchClick();
        this.GetPHIC()
        sleep(1000).then(() => {
        this.setState({isLoading:false,
            alerttype   :   "",
            isshow      :   false,
            color       :   "success",
            message     :   "",
            fade        :   false
        })})
    };

    handleSaveClick = event => {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        let a = []
        
        this.setState({newphicList: [],isLoading:true})
        this.state.phicList.filter(x=>x.isModified==="1") 
        
        const inclusionParams = {
            "IpAddress":"0.0.0.0",
           // "CompanyId":this.state.userinfo.companyId,
             "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "PHICBrackets":  this.state.phicList.filter(x=>x.isModified==="1") 
        };
        console.log("Params")
        console.log(inclusionParams)
        axios
        .post(
            AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditPHICBracket", inclusionParams
        )
        .then(res => {
            //console.log(inclusionParams)
            const data = res.data;
            console.log("res.data")
            console.log(data)
            sleep(1000).then(() => {
                this.setState({isLoading:false})})
            this.GetPHIC();
                var alertType = (data.status=="1") ? "success" : "danger"
                this.setState(
                    {
                        isloading:false,
                        alerttype:"Success!",
                        isshow:true,
                        color:alertType,
                        message:data.message ,
                        fade:true
                    });
            })
            .catch(error=>{
                this.setState(
                {
                    isloading:false,
                    alerttype:"Error! ",
                    isshow:true,
                    Color:"danger",
                    message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade:true
                })
            })
        };

        LeavePageWithourSavingChanges() {
            const isChanged=false
            for (let i = 0; i < this.state.phicList.length; i++) {
                if (this.state.phicList[i]["isModified"] == 1) {
                    this.setState({isGridDataChanged: true})
                    isChanged=true
                    break;
                }
            }
            return isChanged
        }
    
        GridDataModified(oldValue, newValue, id, column) {
            console.log(id)
            this.state.phicList.map(function(item,i) {
                if (item.id===id)
                    item.isModified = newValue!=oldValue ? "1" : "0"
                })
        }


    render() {
       
        const columns3 = [
            {
                dataField: 'monthlyBasicSalary',
                text: 'MONTHLY BASIC SALARY ',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'30%'}},
                style:{textAlign:'center'}
            },
            {
                dataField: 'monthlyPremium',
                text: 'MONTHLY PREMIUM',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'30%'}},
                style:{textAlign:'center'}
            },
            {
                dataField: 'personalShare',
                text: 'PERSONAL SHARE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'30%'}},
                style:{textAlign:'center'}
            },
            {
                dataField: 'employeerShare',
                text: 'EMPLOYER SHARE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'30%'}},
                style:{textAlign:'center'}
            }
        ] 

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                console.log("row")
                console.log(row)
                this.state.phicList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {}
        };
        // const selectRow = {
        //     mode: 'checkbox',
        //     clickToSelectAndEditCell: true,
        //     onSelect: (row, isSelect, rowIndex, e) => {
        //         this.state.phicList.map(function(item,i){
        //             if(item.id===row.id)
        //             {
        //                 item.isDeleted = isSelect ? "1" : "0"
        //                 item.isModified = isSelect ? "1" : "0"
        //             }
        //         })
        //      }
        // };

        // const rowEvents = {
        //     onClick: (e, row, rowIndex) => {
        //       //alert(e.row);
        //     }
        // };


         
       
        /* const dtr2 = [
            {"monthlybasic" : "10,000.00 and below", "monthly premium" : "275.00", "personal share" : "137.50", "employer share" : "137.50"},
            {"monthlybasic" : "10,000.01 to 39,999.99", "monthly premium" : "275.02 to 1,099.99", "personal share" : "137.51 to 549.99", "employer share" : "137.51 to 549.99"},
            {"monthlybasic" : "40,000.00 and above", "monthly premium" : "1,100.00", "personal share" : "550.00", "employer share" : "550.00"},
        ]  */
        return(
            <div >
                <Banner />
                <Container className="mt-5" >
                    <Card>
                        <Card.Header>PHILHEALTH CONTRIBUTION TABLE</Card.Header>
                        <Card.Body>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                           {/*  <Form.Group as={Row} controlId="formHorizontalEmail" style={{marginLeft:'0px',}}>
                                <BootstrapTable
                                keyField = "month"
                                data = { this.state.phicList }
                                columns = { columns3 }
                                //selectRow = { selectRow }
                                cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                />
                            </Form.Group>&nbsp;&nbsp; */}
                            <Form.Group as={Row}  className="mt-2" controlId="formHorizontalEmail">
                                <Col sm={12} >
                                     <BootstrapTable
                                      rowClasses="noser-table-row-class"
                                      striped
                                      hover
                                      condensed
                                      pagination={ paginationFactory({sizePerPageRenderer}) }
                                     keyField = "id"
                                        data = { this.state.phicList}
                                        
                                        columns = { columns3 }
                                        selectRow = { selectRow }
                                    
                                        //selectRow = { selectRow }
                                        //cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                       

                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                                }
                                            rowEvents={ rowEvents }
                                     /> 
                                </Col> 
                            </Form.Group>&nbsp;&nbsp;
                            <ButtonToolbar sm={12}>
                                <Button variant="success" className="ml-auto" onClick={this.handleSaveClick} style={{minWidth:'60px'}}>
                                    Save
                                </Button>&nbsp;&nbsp;
                               
                                <Button style={{minWidth:'60px'}} className='' variant="primary"  onClick={this.handleModalShow} >
                                    create
                                </Button>&nbsp;&nbsp;
                                <Button variant="danger"  href="/home" style={{minWidth:'60px'}}>
                                    Close
                                </Button>

                            </ButtonToolbar>
                        </Card.Body>
                    </Card>
                </Container>
                <CreatePhilHealthTable 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div>
        )
    }

}



export  default PhilHealthTable