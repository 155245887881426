import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

class CreatePayrollProduct extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            dataGrid : [],
            name : ""
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }
    componentDidMount(){
        this.DataOnLoad()
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            dataGrid : [],
            name : "",
        })
        this.DataOnLoad()
    }
    DataOnLoad = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress" :   "0.0.0.0",
            "UserId"    :   this.state.userinfo.userId,
            "CompanyId":this.state.userinfo.companyId,
            "Id"        :   ""
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollProduct",  param)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({dataGrid:data.payrollProduct,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangeName = (e) => {
        this.setState({isshow:false})
        this.setState({ name: e.target.value.toUpperCase() });
    };
    handleSubmit = (e) =>{
        this.setState({isloading:true})
                
        if(this.state.dataGrid.some(item =>item.name === this.state.name ) === true ){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Location name already exist!",
                fade            :   true
            })
            return  
        }

        if(this.state.name==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter name",
                fade        :   true
            })
            return 
        }

        const param = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "Name":this.state.name,
        }
        console.log(param)
        
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/AddPayrollProduct",  param)
            .then(res => {
                const data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true
                });
                this.DataOnLoad();
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }

    
    handleModalClose = () => {
        this.props.onHide();            
    }
    handleKeyUp = (e) =>{

    }
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        CREATE PAYROLL PER TRIP - PRODUCT
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                Name
                            </Form.Label>
                            <Col>
                                <Form.Control 
                                    name='name'
                                    type="text" 
                                    onChange={this.handleChangeName} 
                                    autoComplete="off" 
                                    value={this.state.name}
                                />
                            </Col>
                        </Form.Group>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleSubmit }  style={{marginRight:"5px"}}>Save</Button>
                        <Button variant="danger" className="noser-button-mr1" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default CreatePayrollProduct