import {
    React,Component,
    paginationFactory,Button, ButtonToolbar, Card, BootstrapTable,
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, useState, DatePicker
} 
from '../../noser-hris-component';

// import  StandardBillingModalCreate  from './BillingModal/StandardBillingModalCreate';
// import  StandardBillingModalEdit  from './BillingModal/StandardBillingModalEdit';

// import  BillingRateOneCreate  from './BillingModal/BillingRateOneCreate';
// import  BillingRateOneEdit  from './BillingModal/BillingRateOneEdit';

// import  BillingRateTwoCreate  from './BillingModal/BillingRateTwoCreate';
// import  BillingRateTwoEdit  from './BillingModal/BillingRateTwoEdit';

// import  ClientBillingModalCreate  from './BillingModal/ClientBillingModalCreate';
// import  ClientBillingModalEdit  from './BillingModal/ClientBillingModalEdit';

class EventScheduleCreate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            isDisable       :   true,
            employeeDDL     :   [],
            employeeList    :   [],
            eventEmployeeDDL :  [],
            clientList      :   [],
            AreaDDL         :   [],
            payrolPositionDDL   :   [],
            selectedClientId:   "",
            toDate          :   "",
            fromDate        :   "",
            employeeId      :   "",
            employeeName    :   "",
            positionid      :   "",
            positionName    :   "",
            Areaid          :   "",
            nameOfEvent     :   "",
            venueOfEvent    :   "",
            employeeNo      :   "",
            PositionName    :   "",
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    componentDidMount() {
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();
    }
    
    getClientList(){
        this.setState({isloading:true})
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            sleep(1000).then(() => {
                this.setState({
                    clientList  :   res.data.clients ? res.data.clients : [],
                    isloading   :   false
                })
            })
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    getEmployeeList = async(clientId) =>{
        this.setState({isloading:true})
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":clientId,
            "UserId":this.state.userinfo.userId,
	        "EmployeeId": this.state.userinfo.roleId==="1" || this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", params)
        .then(res => {
            const data = res.data;
            console.log(data.profiles)
            this.setState({employeeDDL : data.profiles,employeeList : data.profiles, isloading : false})
            // this.GetNationalities()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    getPayrollPositionRates = async(clientId) =>{
        this.setState({isloading:true})
        
        const params = {
            "IpAddress": "0,0,0,0",
            "UserId": "1",
            "ClientId" : clientId,
            "LocationId" : "",
            "Name" : "",
            "Rates" : ""
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollPositionRates", params)
        .then(res => {
            const data = res.data;
            // console.log(data)
            this.setState({payrolPositionDDL : data.payrollPosition, isloading : false})
            // this.GetNationalities()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    getAreaList = async(clientid) =>{
        this.setState({isloading:true})
        
        const params = {
            "IpAddress": "0,0,0,0",
            "UserId": "1",
            "ClientId" : clientid,
            "LocationId" : "",
            "Name" : "",
            "Rates" : ""
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollMinimumRates", params)
        .then(res => {
            const data = res.data;
            // console.log(data)
            this.setState({AreaDDL : data.payrollArea, isloading : false})
            // this.GetNationalities()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }



    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        this.setState({isLoading:true,loadingText:"Retrieving employee list..."})
        // console.log(e[0])
        this.getEmployeeList(e[0].id);
        this.getPayrollPositionRates(e[0].id)
        this.getAreaList(e[0].id);
    }
    onChangeArea = (e) => {
        if(e.length == 0) {
            this.state.Area=""
            return
        } 
        // this.state.selectedClientId = e[0].id
        this.state.Areaid = e[0].id
        this.state.Area = e[0].name
        // this.setState({isLoading:true,loadingText:"Retrieving employee list..."})
        // this.setState({ Area : e.target.value})
    }
    onChangeFromDate = date => {
        this.setState({
            fromDate: date,
        });
    }
    onChangeToDate = date => {
        this.setState({
            toDate: date,
        });
    }
    onChangeEmployeeList = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        
        if(e.length===0){
            this.state.employeeId= ''
            this.state.employeeName=''
            // this.state.profileId= ''
            // this.state.employeeName=''
            // this.state.disablebutton = true
            return
        }
        this.state.employeeId = e[0].id
        this.state.employeeNo = e[0].profileId
        this.state.employeeName = e[0].employeeName
    }
    onChangePositionList = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        
        if(e.length===0){
            this.state.positionid= ''
            this.state.positionName=''
            // this.state.profileId= ''
            // this.state.employeeName=''
            // this.state.disablebutton = true
            return
        }
        console.log(e[0])
        this.state.positionid = e[0].id
        this.state.positionName = e[0].name
    }
    onChangeNameOfEvent = (e) =>{
        this.setState({ nameOfEvent : e.target.value })
    }
    onChangeVenueOfEvent = (e) =>{
        this.setState({ venueOfEvent : e.target.value })
    }



    handleAddEventSchedule = (e) =>{
        // console.log("Start")
        // console.log(this.state.employeeId)
        // console.log(this.state.employeeName)
        // console.log(this.state.positionid)
        // console.log("End") 
        // console.log("this.state.eventEmployeeDDL")
        // console.log(this.state.eventEmployeeDDL)
        let idtmp = this.state.eventEmployeeDDL.length + 1
        let obj = {
            "seq" : String(idtmp),
            "EmployeeId" : this.state.employeeId,
            "EmployeeNo" : this.state.employeeNo,
            "EmployeeName" : this.state.employeeName,
            "PositionId" : this.state.positionid,
            "PositionName" : this.state.positionName
        }
        this.state.eventEmployeeDDL.push(obj)
        this.setState({ eventEmployeeDDL : this.state.eventEmployeeDDL })
        console.log("this.state.eventEmployeeDDL")
        console.log(this.state.eventEmployeeDDL)

    }
    handleSaveEventSchedue = () =>{
        // console.log("Start")
        // console.log(this.state.venueOfEvent)
        // console.log(this.state.toDate)
        // console.log(this.state.fromDate)
        // console.log(this.state.selectedClientId)
        // console.log(this.state.Areaid)
        // console.log(this.state.nameOfEvent)
        // console.log("End")
        // let dataDDL = this.state.eventEmployeeDDL
        let _NewArryList = []
        for( let i = 0; i < this.state.eventEmployeeDDL.length; i++){
            let obj = {
                // "seq" : String(idtmp),
                "EmployeeId" : this.state.eventEmployeeDDL[i]["EmployeeId"],
                "EmployeeNo" : this.state.eventEmployeeDDL[i]["EmployeeNo"],
                // "EmployeeName" : this.state.employeeName,
                "PositionId" : this.state.eventEmployeeDDL[i]["PositionId"],
                // "PositionName" : this.state.positionName
            }
            _NewArryList.push(obj)
        }
        // console.log("_NewArryList")
        // console.log(_NewArryList)
        const payrollParams = {

            "IpAddress":"0.0.0.0",
            "ClientId": this.state.selectedClientId,
            "UserId": this.state.userinfo.userId,
            "NameOfEvent": this.state.nameOfEvent,
            "VenueOfEvent": this.state.venueOfEvent,
            "AreaId": this.state.Areaid,
            "DateStart": moment(this.state.fromDate).format("MM/DD/YYYY"),
            "DateEnd" : moment(this.state.toDate).format("MM/DD/YYYY"),
            "payrolleventdetails" : _NewArryList
        };
        console.log("payrollParams")
        console.log(payrollParams)
        axios
            .post( AppConfiguration.Setting().noserapiendpoint + "Payroll/AddPayrollEventSchedule", payrollParams )
            .then(res => {
                const data = res.data;
                console.log(data);
                this.setState({isLoading:false})
                    var alertType = (data.status=="1") ? "success" : "danger"
                this.setState(
                    {
                        isLoading:false,
                        AlertType:"Success!",
                        show:true,
                        Color:alertType,
                        Message:data.message ,
                        Fade:true
                    }
                );
            })
            .catch(error=>{
                this.setState(
                {
                    isLoading:false,
                    AlertType:"Error! ",
                    Show:true,
                    Color:"danger",
                    Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    Fade:true
                })
        })
    }
    render() {
        const minDate = new Date();
        const EmployeeEventColumn = [
            {
                dataField   : 'seq',
                text        : 'No.',
                headerStyle : () => {
                    return { width  : "2%" };
                }
            },
            {
                dataField   : 'EmployeeName',
                text        : 'Employee Name',
                headerStyle : () => {
                    return { width  : "48%" };
                }
            },
            {
                dataField   : 'EmployeeNo',
                text        : 'Employee No.',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'PositionName',
                text        : 'Position',
                headerStyle : () => {
                    return { width  : "30%" };
                }
            },
        ]
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>Event Schedule Create</Card.Header>
                        <Card.Body>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>

                            <Card>
                                <Card.Header>Event Details</Card.Header>
                                <Card.Body>
                                    {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.clientList}
                                                placeholder="Select Client"
                                            />
                                        </Col>
                                    </Form.Group> */}
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Name of Event
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control
                                                // name="amount"
                                                placeholder="Enter Event Name"
                                                value={this.state.eventName}
                                                onChange={this.onChangeNameOfEvent} 
                                                // readOnly
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Venue of Event
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control
                                                // name="amount"
                                                placeholder="Enter Venue Name"
                                                value={this.state.eventName}
                                                onChange={this.onChangeVenueOfEvent} 
                                                // readOnly
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Area
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeArea}
                                                options={this.state.AreaDDL}
                                                placeholder="Select Area"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Date
                                        </Form.Label>
                                        <Col sm={5}>
                                            <DatePicker
                                                ref='FromDate'
                                                selected={this.state.fromDate}
                                                onChange={this.onChangeFromDate}
                                                minDate={this.minDate}
                                                value={this.state.fromDate}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                placeholderText="Date From"
                                            />
                                        </Col>
                                        <Col sm={5}>
                                            <DatePicker
                                                ref='FromDate'
                                                selected={this.state.toDate}
                                                onChange={this.onChangeToDate}
                                                minDate={this.minDate}
                                                value={this.state.toDate}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                placeholderText="Date To"
                                            />
                                        </Col>
                                    </Form.Group>
                                </Card.Body>
                            </Card>

                            <Card>
                                <Card.Header>Event Employee</Card.Header>
                                <Card.Body>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Employee
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.onChangeEmployeeList}
                                                options={this.state.employeeDDL}
                                                placeholder="Select Employee"
                                            />
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Employee No.
                                        </Form.Label>
                                        <Col sm={2}>
                                            <Form.Control
                                                // name="amount"
                                                // placeholder="Enter Amount"
                                                value={this.state.employeeId}
                                                style={{textAlign : 'right'}}
                                                // onChange={this.handleChange} 
                                                readOnly
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangePositionList}
                                                options={this.state.payrolPositionDDL}
                                                placeholder="Select Position"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Button style={{minWidth:'60px'}} variant="success" className="float-right" onClick={this.handleAddEventSchedule}>
                                        Add
                                    </Button>
                                </Card.Body>
                            </Card>

                            <Card.Header>List of Event Employee</Card.Header>
                            <div className="mt-2">
                                <BootstrapTable
                                    /* caption={Noser.TableHeader({title:"RECORD"})} */
                                    keyField = "id"
                                    data = { this.state.eventEmployeeDDL }
                                    columns = { EmployeeEventColumn }
                                    pagination={ paginationFactory({sizePerPageRenderer}) }
                                    rowClasses="noser-table-row-class no checkbox"
                                    //bordered={ false }
                                    striped
                                    hover
                                    condensed
                                    // cellEdit = { cellEditFactory({
                                    //     mode: 'dbclick',
                                    //     blurToSave: true,
                                    //     afterSaveCell: (oldValue, newValue, row, column) => {
                                    //         this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                    //         }
                                    //     })
                                    // }
                                    // rowEvents={ rowEvents }
                                    // selectRow = { selectRow }
                                />
                            </div>

                            <ButtonToolbar className="mt-3 mb-2">
                                <Button style={{minWidth:'60px'}} variant="success" className="ml-auto" onClick={this.handleSaveEventSchedue}>
                                    Save
                                </Button>&nbsp;&nbsp;&nbsp;
                                <Button style={{minWidth:'60px'}} variant="danger" href="/Home">Close</Button>
                            </ButtonToolbar>
                        </Card.Body>
                    </Card>
                </Container>

                <NoserLoading show={this.state.isloading} />

            </div>
        )

    }

}

export  default EventScheduleCreate