import {
    React,Component, img_401
  } 
  from './noser-hris-component';
class Unathorized extends Component {

    render(){
        return (
            <div className="noser-unathorized"></div>
        );
    }
}
export default Unathorized;