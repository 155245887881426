import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import CasesCreate from '../Maintenance/CasesCreate';

 
class PenaltyTypeSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            penaltyTypeList                     :   [],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalTrainingRegisterShow           :   false,
            selectedpenaltyType                 :   "",
            selectedpenaltyTypeid               :   "",

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getPenaltyType()
    }

   /*  showTrainingRegisterModal = (e) => {
        this.setState({modalTrainingRegisterShow: true})
    } */
    handleModalClose = (e) =>{
        //reload or refresh data here
        //alert("Data pass from child : " + e)
        this.setState({modalTrainingRegisterShow: false})
    }


    GridDataModified(oldValue, newValue, id, column) {
        //////console.log(id)
        this.state.trainingScheduleTableList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    getPenaltyType =()=>{
      
        let filter_data = ""

        let getParams = ""

        if(this.state.selectedpenaltyType =="" || this.state.selectedpenaltyType ===""){
           
            filter_data = {}

            getParams ={
                "_collection" : "PenaltyType",
                "filter_data" : filter_data
            }

        }else{
           
            filter_data = {"penaltyType": this.state.selectedpenaltyType}

            getParams ={
                "_collection" : "PenaltyType",
                "filter_data" : filter_data
            }

        }

        axios
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        .then(res =>{
            let data = res.data
            const itemUsersDataLists = this.buildList(data["PenaltyType"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                let obj = {
                    'id'                    :   this.state.itemUserDataList[i]['id'],
                    'penaltyType'           :   this.state.itemUserDataList[i]['penaltyType'],
                    'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'            :   this.state.itemUserDataList[i]['isModified'],
                    
                }
                List.push(obj)
                //console.log(List)
                
                this.setState({
                    penaltyTypeList         :List,
                    trainingAutocomplete    :List,
                    isloading               :false,

                })
                
            }

        })
        .catch(error=>{
            //console.log("error: " + error)
        })
    } 
        
    buildList(data) {
        ////console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let penaltyTypeList             =[]
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
    
            let s1 = data[i].split("':")
            
            let idClean                     = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let penaltyTypeTmp              = s1[2].split("',")[0]
            let isDraftListTmp              = s1[3].split("',")[0]
            let isDeletedListTmp            = s1[4].split("',")[0]
            let createdbyListTmp            = s1[5].split("',")[0]
            let createddateListTmp          = s1[6].split("',")[0]
            let modifiedbyListTmp           = s1[7].split("',")[0]
            let modifieddateListTmp         = s1[8].split("',")[0]
            let isModifiedListTmp           = s1[9].split("',")[0]

        
            idList.push(IdClean2.replace(" '",""))
            penaltyTypeList.push(penaltyTypeTmp.replace(" '",""))
           
            isDraftList.push(isDraftListTmp.replace(" '",""))
            isDeletedList.push(isDeletedListTmp.replace(" '",""))
            createdbyList.push(createdbyListTmp.replace(" '",""))
            createddateList.push(createddateListTmp.replace(" '",""))
            modifiedbyList.push(modifiedbyListTmp.replace(" '",""))
            modifieddateList.push(modifieddateListTmp.replace(" '",""))
            isModifiedList.push(isModifiedListTmp.replace(" '","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'penaltyType'           :   penaltyTypeList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
        
       /*  //console.log("itemList start")
        //console.log(itemList)
        //console.log("itemList End") */
        return itemList
    }
    
    onChangePenaltyType = (e) => { 

        if(e.length == 0) {
            this.state.selectedpenaltyType=""
            this.state.selectedpenaltyTypeid=""
            this.getPenaltyType()
            return
        } 

        this.state.selectedpenaltyTypeid = e[0].id
        this.state.selectedpenaltyType = e[0].penaltyType
        
        this.setState({
            isloading: true
        })
        this.getPenaltyType()
    }



    render() {
        
        const penaltyTypeColumn = [
            {
                dataField   : 'penaltyType',
               
                headerStyle : () => {
                    return { width  : "100%" };
                }
            },
            /* {
                dataField: 'training',
                text: 'Training',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'position',
                text        : 'Position',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'client',
                text        : 'Client',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'register',
                text        : 'Register',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'attended',
                text        : 'Attended',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.penaltyTypeList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>Maintenance >> Cases Maintenance >> Penalty Types >> Search</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Penalty Types
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='penaltyType'
                                                id="basic-example"
                                                onChange={this.onChangePenaltyType}
                                                options={this.state.trainingAutocomplete}
                                                placeholder="select penalty type"
                                            />
                                        </Col>
                                    </Form.Group>
                                    
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                    
                                    </Form.Group>
                                   
                                    <ButtonToolbar className="mt-1">
                                       {/*  <Button variant="success" className="ml-auto" onClick={ this.handleSearchClick }>
                                            Search
                                        </Button>&nbsp;&nbsp; */}
                                        <NavLink to="/PenaltyTypeCreate" className="ml-auto" className="ml-auto" >
                                            <Button variant="success" href="/PenaltyTypeCreate" style={{minWidth:'60px'}}>
                                                Create
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>

                                    <Card.Header className="mt-3">Penalty List Types</Card.Header>
                                    <div className="mt-1">
                                        <BootstrapTable
                                            /* caption={Noser.TableHeader({title:"RECORD"})} */
                                            keyField = "id"
                                            data = { this.state.penaltyTypeList }
                                            columns = { penaltyTypeColumn }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                            selectRow = { selectRow }

                                        />
                                    </div>
                                    <ButtonToolbar sm={12}>
                                        {/* <Button variant="success"  style={{minWidth:'60px'}} onClick={this.handleUpdateClick}>
                                            Save
                                        </Button>&nbsp;&nbsp; */}
                                        <NavLink to="/home" className="ml-auto">
                                            <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                Close
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                    {/* <TrainingRegisterModal 
                        show={this.state.modalTrainingRegisterShow}
                        onHide={this.handleModalClose}
                    /> */}
            </div> 
        )
    }

}

export  default PenaltyTypeSearch
