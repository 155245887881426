import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker, LeaveModal, Tabs, Tab,
    sizePerPageRenderer
} 
from '../../noser-hris-component';

class ViewRecord extends Component {
    constructor(props) {
        super(props)
        this.minDate = new moment(props.Date)
        this.state = {
            selected: [],
            userinfo  :   [],
            isloading :   false,
            isshow    :   false,
            alerttype :   "",
            message   :   "",
            color     :   "",
            fade      :   true,


            client:"",
            IsDefaultLocation:"false",
            branch:"",
            payCardType:"",
            payCardNumber:"",
            TMNProfiledId:"",
            dateOfDeactivation:"",
            contractDateStart:"",
            contractDateEnd:"",
            dateHired:"",
            position:"",
            rate:"",
            seaValue:"",
            ecolaValue:"",
            colaValue:"",
            remarks:"",
            employee:""

        }
         
    }

    componentDidMount() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        sleep(1000).then(() => {
            this.setState({isLoading:false})}) 

            this.state.client = this.props.location.params.data.clientName
            this.state.colaValue = this.props.location.params.data.colaValue
            this.state.contractDateEnd = this.props.location.params.data.contractDateEnd
            this.state.contractDateStart = this.props.location.params.data.contractDateStart
            this.state.dateOfDeactivation = this.props.location.params.data.dateOfDeactivation
            this.state.ecolaValue = this.props.location.params.data.ecolaValue
            this.state.employee = this.props.location.params.data.employeeName
            this.state.employeeStatus = this.props.location.params.data.employeeStatusType
            this.state.branch = this.props.location.params.data.locationName
            this.state.payCardNumber = this.props.location.params.data.payCardNumber
            this.state.payCardType = this.props.location.params.data.payCardType
            this.state.position = this.props.location.params.data.position
            this.state.rate = this.props.location.params.data.salaryOffered
            this.state.seaValue = this.props.location.params.data.seaValue
           
            
    }

    onChangeClient(e){
        this.setState({client : e.target.value})
    }

    onChangeEmployee(e){
        this.setState({
            employee: e.target.value
        })
    }

    onChangeBranch(e){
        this.setState({
            branch: e.target.value
        })
    }

    onChangePosition(e){
        this.setState({
            position:e.target.value
        })
    }

    onChangeEmployeeStatus(e){
        this.setState({
            employeeStatus:e.target.value
        })
    }

    onChangePayCardNumber(e){
        //console.log(e.target.value)
        this.setState({payCardNumber : e.target.value})
       
    }

    onChangePayCardType(e){
        this.setState({payCardType: e.target.value

        })
    }

    onChangeTMNProfiledId(e){
        //console.log(e.target.value)
        this.setState({TMNProfiledId :e.target.value})
    }

    onChangeRate(e){
        //console.log(e.target.value)
        this.setState({rate:e.target.value})
    }

    onChangeSeaValue(e){
        //console.log(e.target.value)
        this.setState({seaValue:e.target.value})
    }

    onChangeECOLAValue(e){
        //console.log(e.target.value)
        this.setState({ecolaValue:e.target.value})
    }

    onChangeCOLAValue(e){
        //console.log(e.target.value)
        this.setState({colaValue:e.target.value})
    }

    onChangeRemarks(e){
        //console.log(e.target.value)
        this.setState({remarks:e.target.value})
    }

    onChangeDeactivation(e){
        //console.log(e.target.value)
        this.setState({dateOfDeactivation:e.target.value})
    }

    onChangeContractDateStart (e) {
        //console.log(date)
        this.setState({contractDateStart:e.target.value});
    }

    onChangeContractDateEnd (e) {
        this.setState({contractDateEnd: e.target.value});
    }

    onChangeDateHired(e){
        this.setState({
            dateHired: e.target.value
        });
    }


    render() {
        return(
            <div>
                <Banner />
                    <Container className="mt-5">
                        <Card>
                            <Card.Header>VIEW EMPLOYEE RECORDS </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Col sm={6} className="mt-3">
                                            <label style={{fontSize:"10px"}}>
                                                CLIENT
                                            </label>
                                            <Form.Control 
                                                    type="text" 
                                                    autoComplete="off" 
                                                    name="client"
                                                    value={this.state.client}
                                                    onChange={this.onChangeClient.bind(this)}
                                                    input disabled={true}
                                                    // disabled = { this.state.client}
                                                />  
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <label style={{fontSize:"10px"}}>
                                                EMPLOYEE
                                            </label>
                                            <Form.Control 
                                                    type="text" 
                                                    autoComplete="off" 
                                                    name="employee"
                                                    value={this.state.employee}
                                                    onChange={this.onChangeEmployee.bind(this)}
                                                    input disabled={true}
                                                /> 
                                        </Col>
                                            <Col sm={6} className="mt-3">
                                                <label style={{fontSize:"10px"}}>
                                                    BRANCH
                                                </label>
                                                <Form.Control 
                                                    type="text" 
                                                    autoComplete="off" 
                                                    name="branch"
                                                    value={this.state.branch}
                                                    onChange={this.onChangeBranch.bind(this)}
                                                    input disabled={true}
                                                />  
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col sm={6} className="mt-3">
                                                <Form.Check type="checkbox" 
                                                    label="IS DEFAULT BRANCH" 
                                                    name="IsDefaultLocation"
                                                    onChange={this.handleChangeBranch}
                                                />
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            {/* <Col sm={6} className="mt-3">
                                                <label style={{fontSize:"10px"}}>
                                                    DATE HIRED
                                                </label>
                                                <Form.Control 
                                                    type="text" 
                                                    autoComplete="off" 
                                                    name="dateHired"
                                                    value={this.state.dateHired}
                                                    onChange={this.onChangeDateHired.bind(this)}
                                                    disabled = { this.state.dateHired}
                                                />  
                                            </Col> */}
                                            <Col sm={6} className="mt-3">
                                                <label style={{fontSize:"10px"}}>
                                                    JOB POSITION
                                                </label>
                                                <Form.Control 
                                                    type="text" 
                                                    autoComplete="off" 
                                                    name="position"
                                                    value={this.state.position}
                                                    onChange={this.onChangePosition.bind(this)}
                                                    input disabled={true}
                                                />  
                                            </Col>
                                            <Col sm={6} className="mt-3">
                                                <label style={{fontSize:"10px"}}>
                                                    EMPLOYEE STATUS
                                                </label>
                                                <Form.Control 
                                                    type="text" 
                                                    autoComplete="off" 
                                                    name="employeeStatus"
                                                    value={this.state.employeeStatus}
                                                    onChange={this.onChangeEmployeeStatus.bind(this)}
                                                    input disabled={true}
                                                />  
                                            </Col>
                                            <Col sm={6} className="mt-3">
                                                <label style={{fontSize:"10px"}}>
                                                    DATE OF DEACTIVATION
                                                </label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={this.state.dateOfDeactivation}
                                                    onChange={this.onChangeDeactivation.bind(this)}
                                                    autoComplete="off" 
                                                    name="DateOfDeactivation"
                                                    input disabled={true} 
                                                    // disabled={ this.state.dateOfDeactivation}
                                                   
                                                />
                                            </Col>
                                            <Col sm={6} className="mt-3">
                                                <label style={{fontSize:"10px"}}>
                                                    PAY CARD TYPE
                                                </label>
                                                <Form.Control 
                                                    type="text" 
                                                    name="payCardType"
                                                    value={this.state.payCardType}
                                                    onChange={this.onChangePayCardType.bind(this)}
                                                    input disabled={true}
                                                />  
                                            </Col>
                                            <Col sm={6} className="mt-3">
                                                <label style={{fontSize:"10px"}}>
                                                    PAY CARD NUMBER
                                                </label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={this.state.payCardNumber}
                                                    onChange={this.onChangePayCardNumber.bind(this)}
                                                    autoComplete="off" 
                                                    name="PayCardNumber"
                                                    input disabled={true}
                                                />
                                            </Col>
                                            <Col sm={6} className="mt-3">
                                                <label style={{fontSize:"10px"}}>
                                                    TMN PROFILEID
                                                </label>
                                                <Form.Control 
                                                    type="text" 
                                                    autoComplete="off" 
                                                    name="TMNProfiledId"
                                                    value={this.state.TMNProfiledId}
                                                    onChange={this.onChangeTMNProfiledId.bind(this)}
                                                    input disabled={true}
                                                    // disabled = { this.state.TMNProfiledId}
                                                />
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col sm={6} className="mt-3">
                                                <label style={{fontSize:"10px"}}>
                                                    CONTRACT DATE START
                                                </label>
                                                <Form.Control 
                                                    type="text" 
                                                    autoComplete="off" 
                                                    name="contractDateStart"
                                                    value={this.state.contractDateStart}
                                                    onChange={this.onChangeContractDateStart.bind(this)}
                                                    input disabled={true}
                                                />                                         
                                            </Col>
                                            <Col sm={6} className="mt-3">
                                                <label style={{fontSize:"10px"}}>
                                                    CONTRACT DATE END
                                                </label>
                                                <Form.Control 
                                                    type="text" 
                                                    autoComplete="off" 
                                                    name="contractDateEnd"
                                                    value={this.state.contractDateEnd}
                                                    onChange={this.onChangeContractDateEnd.bind(this)}
                                                    input disabled={true}
                                                />
                                            </Col>
                                            <Col sm={6} className="mt-3">
                                                <label style={{fontSize:"10px"}}>
                                                    RATE
                                                </label>
                                                <Form.Control 
                                                    type="text"   
                                                    autoComplete="off" 
                                                    name="Rate"
                                                    value={this.state.rate}
                                                    onChange={this.onChangeRate.bind(this)}
                                                    input disabled={true}
                                                />
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                        <Col sm={6} className="mt-3">
                                            <label style={{fontSize:"10px"}}>
                                                SEA VALUE
                                            </label>
                                            <Form.Control 
                                                type="text" 
                                                autoComplete="off" 
                                                name="seaValue"
                                                value={this.state.seaValue}
                                                onChange={this.onChangeSeaValue.bind(this)}
                                                input disabled={true}
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <label style={{fontSize:"10px"}}>
                                                ECOLA VALUE
                                            </label>
                                                <Form.Control 
                                                    type="text" 
                                                    autoComplete="off" 
                                                    name="ecolaValue"
                                                    value={this.state.ecolaValue}
                                                    onChange={this.onChangeECOLAValue.bind(this)}
                                                    input disabled={true}
                                                />
                                            </Col>
                                            <Col sm={6} className="mt-3">
                                            <label style={{fontSize:"10px"}}>
                                                COLA VALUE
                                            </label>
                                                <Form.Control 
                                                    type="text" 
                                                    autoComplete="off" 
                                                    name="colaValue"
                                                    value={this.state.colaValue}
                                                    onChange={this.onChangeCOLAValue.bind(this)}
                                                    input disabled={true}
                                                />
                                            </Col>
                                            <Col sm={12} className="mt-3">
                                                <label style={{fontSize:"10px"}}>
                                                    REMARKS
                                                </label>
                                                <Form.Control 
                                                    type="text" 
                                                    autoComplete="off" 
                                                    name="remarks"
                                                    value={this.state.remarks}
                                                    onChange={this.onChangeRemarks.bind(this)}
                                                    input disabled={true}
                                                />
                                            </Col>
                                        </Form.Row>
                                        <ButtonToolbar sm={12} className="mt-3">
                                        {/* <Button style={{minWidth:"60px",marginRight:"1pt"}} variant="success" className="ml-auto" onClick={this.handleSaveClick}>
                                            Submit
                                        </Button>&nbsp;&nbsp; */}
                                        <Button className="ml-auto noser-button-mr1" style={{minWidth:"60px",marginRight:"1pt"}} variant="danger" href="/ManningRecordApprove">
                                            Back
                                        </Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                        
                    </Container>
                    <NoserLoading show={this.state.isLoading} /> 
            </div> 
            
        )
    }
}


export default ViewRecord;