import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

class DocumentsClient extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            removeBtn   :   true,
            documentsId  :   "",
            clientId    :   "",
            clientName  :   "",

            clientDDL  :   [],
            clientGrid :   []
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            removeBtn   :   true,
            documentsId  :   e.id,
            clientGrid :   e.documentClients
        })
        //console.log(e)
        this.GetClients()
    }
    GetClients = async() => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };

        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  params)
        .then(res => {
            const data = res.data;
            this.setState({
                clientDDL  :   data.clients,
                isloading   :   false
            });
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleClientModalClose = () => {
        this.props.onHide();            
    }
    handleChangeClient = (e) =>{
        this.setState({
            alerttype   :   "",
            isshow      :   false,
            color       :   "",
            message     :   "",
            fade        :   false
        });
        if(e.length==0)
        {
            this.state.clientId=''
            this.state.clientName=''
            return
        }
        this.state.clientId= e[0].id
        this.state.clientName=e[0].name
    }
    handleAddClient = (e) =>{
        let clientGrid = this.state.clientGrid

        if(this.state.clientId===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   false
            });
            return
        }
        var exist = clientGrid.filter(x=>x.clientId==this.state.clientId)
        if(exist.length>0){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Selected client already exist.",
                fade        :   false
            });
            return
        }

        let today = new Date()
        let newId = moment(today).format('0HHMMSS');
        const obj = {
            "id":newId.toString(),
            "documentsId" : this.state.documentsId,
            "clientId" : this.state.clientId,
            "client" : this.state.clientName,
            "isDeleted" : "0",
        }
        clientGrid.push(obj)        
        this.refs.clientRef.clear()
        this.setState({clientGrid:clientGrid,clientId:'',clientName:''})
    }
    handleRemoveClient = (e) =>{
        let clientGrid = this.state.clientGrid.filter(x=>x.isDeleted=="0")
        this.state.clientGrid = []
        this.setState({clientGrid:clientGrid,removeBtn: clientGrid.length===0 ? true : false})
    }
    handleSaveClick = async() =>{
        this.setState({isloading:true})

        const param = {
            "IpAddress" : "0.0.0.0",
            "UserId" : this.state.userinfo.userId,
            "ClientId" : this.state.userinfo.clientId,
            "DocumentId" : this.state.documentsId,
            "clients" : this.state.clientGrid
        };
        console.log(param)
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditDocumentClient",  param)
            .then(res => {
                const data = res.data;
                this.setState({isloading:false,isshow:false })
                if(data.status=="1"){
                this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true,
                        disablebtn  :   true
                    });
                
                }
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    render() {
        const colClients = [
            {
                dataField: 'client',
                text: 'CLIENT',
                headerStyle : () => {
                    return { width  : "100%" };
                }
            },
        ]
        const clientSelectRow = {
            mode: 'checkbox',
            hideSelectAll: false,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.clientGrid.map(function(item,i){
                    if(item.id===row.id)
                        item.isDeleted = isSelect ? "1" : "0"
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({removeBtn:isDisable})
            },
            onSelectAll: (isSelect, rows) => {
                let isDisable = true
                this.state.clientGrid.map(function(itm){
                    itm.isDeleted=isSelect ? "1" : "0"
                    if(itm.isDeleted=="1")
                        isDisable=false
                })
                this.setState({removeBtn:isDisable})
            }
        };
        return(
            <>
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        DOCUMENTS CLIENTS
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Col>
                                <Typeahead
                                    ref="clientRef"
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeClient}
                                    options={this.state.clientDDL}
                                    placeholder="Select Client"
                                />
                            </Col>
                            <Col sm="2.8">
                                <Button className="ml-auto noser-button-mr1" variant="success" onClick = { this.handleAddClient }>ADD</Button>
                                <Button disabled={this.state.removeBtn} className="noser-button" variant="danger" onClick = { this.handleRemoveClient }>REMOVE</Button>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm="12">
                            <div className="mt-2">
                            <Card.Header>INCLUDED CLIENT(S)</Card.Header>
                                <BootstrapTable
                                    keyField = "id"
                                    data = { this.state.clientGrid }
                                    columns = { colClients }
                                    rowClasses="noser-table-row-class"
                                    striped
                                    hover
                                    condensed
                                    cellEdit = { cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                            this.ClientGridDataModified(oldValue, newValue, row.id, column.dataField)
                                            }
                                        })
                                    }
                                    selectRow = { clientSelectRow }
                                    pagination={ paginationFactory({sizePerPageRenderer})}
                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                />
                            </div>
                            </Col>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar>
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleSaveClick }>Save</Button>
                        <Button variant="danger" className="noser-button-mr15" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
                
        </Modal>
        </>
        );
    }
}
export default DocumentsClient