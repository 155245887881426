import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, ReactDOM,
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Tabs, Tab, Accordion,Redirect
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import NumberFormat from 'react-number-format';


class ViewApplication extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: new Date(),
            // selected: [],
            userinfo  :   [],
            isloading :   false,
            isshow    :   false,
            alerttype :   "",
            message   :   "",
            color     :   "",
            fade      :   true, 
            navigate : false,
            
            disableRemoveEducation:true,

            clientDDL       : [],
            clientId        : "",
            clientName      : "",
            positionDDL     : [],
            positionId      : "",
            positionName    : "",
            dateApplied     : "",
            titleDDL: [
                {name: 'Select Name Title', value: "",},
                {name: 'MR.',               value: 'MR.',},
                {name: 'MS.',               value: 'MS.',},
                {name: 'MRS.',              value: 'MRS.',},
            ],
            titleValue  : "",
            fName       : "",
            nName       : "",
            mName       : "",
            lName       : "",
            typeDDL: [
                {name: 'Select Address Type',   value: "",},
                {name: 'CURRENT ADDRESS',       value: '0',},
                {name: 'REGISTERED ADDRESS',    value: '1',},
                {name: 'PROVINCIAL ADDRESS',    value: "2",},
                {name: 'CORRESPONDENCE ADDRESS',value: '3',},
                {name: 'FOREIGN ADDRESS',       value: '4',},
            ],
            typeId      : "",
            regionDDL   : [],
            regionId    : "",
            region  : "",
            regionList  : "",
            provinceDDL : [],
            provinceonloadDDL : [],
            provinceId  : "",
            provinceName: "",
            cityDDL     : [],
            cityonloadDDL : [],
            cityId      : "",
            cityName    : "",
            houseNumber : "",
            street      : "",
            barangay    : "",
            postalCode  : "",
            hpNumber    : "",
            opNumber    : "",
            mobileNumber: "",
            emailAddress: "",
            facebook    : "",
            twitter     : "",
            nationalityDDL: [
                {name: 'Select Citizenship',value: "",},
                {name: 'FILIPINO',          value: '1',},
                {name: 'OTHERS',            value: '2',},
            ],
            nationalityId : "",
            religionDDL: [
                {name: 'Select Religion',       value: "",},
                {name:"ADVENTIST",              value:"1"},
                {name:"AGLIPAY",                value:"2"},
                {name:"ALLIANCE",               value:"3"},
                {name:"APOSTLIC HOLINESS",      value:"4"},
                {name:"ASSEMBLY OF GOD",        value:"5"},
                {name:"BAPTIST",                value:"6"},
                {name:"BIBLE BAPTIST",          value:"7"},
                {name:"BORN AGAIN",             value:"8"},
                {name:"BORN AGAIN CHRISTIAN",   value:"9"},
                {name:"CATHOLIC",               value:"10"},
                {name:"CHRISTIAN",              value:"11"},
                {name:"CHRISTIAN(BORN AGAIN)",  value:"12"},
                {name:"CHURCH OF GOD",          value:"13"},
                {name:"EVANGILICAL",            value:"14"},
                {name:"IEMELIF",                value:"15"},
                {name:"IGLESIA NI CHRISTO",     value:"16"},
                {name:"ISLAM",                  value:"17"},
                {name:"JEHOVA WITNESS",         value:"18"},
                {name:"JEHOVAH'S WITNESS",      value:"19"},
                {name:"JEHOVA'S WITNESS",       value:"20"},
                {name:"MCGI",                   value:"21"},
                {name:"METHODIST",              value:"22"},
                {name:"MORMON",                 value:"23"},
                {name:"MORMONS",                value:"24"},
                {name:"N/A",                    value:"25"},
                {name:"OTHERS",                 value:"26"},
                {name:"PENTECOSTAL",            value:"27"},
                {name:"PIC",                    value:"28"},
                {name:"PINICOCS",               value:"29"},
                {name:"PROTESTANT",             value:"30"},
                {name:"ROMAN CATHOLIC",         value:"31"},
                {name:"SDA",                    value:"32"},
                {name:"SEVENTH DAYA ADVENTIST", value:"33"},
                {name:"THE CHURCH OF JESUS CHRIST", value:"34"},
                {name:"UCCP",                       value:"35"},
                {name:"UNION ESPIRITISTA" ,         value:"36"},
                {name:"UPC",                        value:"37"},
                {name:"V.C.C.P",                    value:"38"}
            ],
            religionId : "",
            genderDDL: [
                {name: 'Select Gender', value: "",},
                {name: 'MALE',          value: 'MALE',},
                {name: 'FEMALE',        value: 'FEMALE',},
            ],
            genderValue : "",
            civilStatusDDL: [
                {name: 'Select Civil Status', value: "",},
                {name:"SINGLE",         value:"SINGLE"},
                {name:"MARRIED",        value:"MARRIED"},
                {name:"DIVORCED",       value:"DIVORCED"},
                {name:"SEPARATED",      value:"SEPARATED"}
            ],
            civilStatusValue : "",
            ageYear  :   "",//,new Date().getFullYear(),
            ageMonth :   "",//,new Date().getMonth()+1,
            dateOfBirth : "",
            totalAge : "",
            placeOfBirth : "",
            height : "",
            weight : "",
            sSkills : "",
            lSpoken : "",
            ecName : "",
            relationship : "",
            ecNumber : "",
            sss : "",
            tin : "",
            phic : "",
            pagibig : "",


            levelDDL  :    [
                {"name": 'Select Level', "value": "",},
                {"name":"COLLEGE",       "value":"0"},
                {"name":"HIGH SCHOOL",   "value":"1"},
                {"name":"ELEMENTARY",    "value":"2"},
                {"name":"OTHERS",        "value":"3"}
            ],
            levelId : "",
            tblLevelArrLst  :   [],
            schoolName:"",
            course:"",
            startYear:"",
            endYear:"",
            honorsAward:"",
            educationGrid : [],
            educationGRIDDel : [],


            checkIsFreshGraduate : false,
            isEmploymentDisabled : false,
            erCompanyName : "",
            erCompanyAddress : "",
            erPosition : "",
            erPeriodCover : "",
            erSalary : "",
            erSupervisor : "",
            erCNumber : "",
            erRFLeaving : "",
            erTCIncome : "",
            erWHTax : "",
            erYOCompensation : "",
            erMPAOB : "",
            erDBenefits : "",
            erMDeduction : "",
            erNTSOFOC: "",
            erBasicSalary : "",
            erTMPOB : "",
            erTSPFOC : "",
            erBPFMWE : "",
            erHPFHWE : "",
            erOPFHWE : "",
            erNDPFHWE : "",
            erRemarks : "",
            employmentRecordGrid : [],
            employmentRecordGRIDDel : [],
            disableRemoveEmploymentRecord : true,

            roleDDL  :    [
                {"name": 'Select Role', "value": "",},
                {"name":"FATHER",       "value":"0"},
                {"name":"MOTHER",       "value":"1"},
                {"name":"SPOUSE",       "value":"2"},
                {"name":"BROTHER",      "value":"3"},
                {"name":"SISTER",       "value":"4"},
                {"name":"CHILD",        "value":"5"},
            ],
            tblRoleArrLst : [],
            roleId : "",
            fbName : "",
            fbAge : "",
            fbOccupation : "",
            fbCOSchool : "",
            fbCNumber : "",
            disableBackground : true,
            familyBackgroundGrid : [],
            backgroundGRIDDel : [],
            tblJobOpeningArrLst : [],
            jobOpeningDDL  :    [
                {"name": 'Select Job Opening',  "value": "",},
                {"name":"BESTJOBS",             "value":"1"},
                {"name":"CLASSIFIED ADD",       "value":"2"},
                {"name":"FIELD COLLECTORS",     "value":"3"},
                {"name":"JOBSTREET",            "value":"4"},
                {"name":"OTHERS",               "value":"5"},
                {"name":"PESO",                 "value":"6"},
                {"name":"REFERRAL",             "value":"7"},
            ],
            jobSourceId : "",
            jobSource : "",
            oReferredBy : "",
            checkORelativeYes : false,
            checkORelativeNo : true,
            disabledRelative : false,
            oRelativeName : "",
            oCNumber : "",
            oRelationship : "",
            oJobTitle : "",
            disableOthers : true,

            checkConvictedYes : false,
            checkConvictedNo : true,
            oCrime : "",
            disabledConvicted : false,

            checkLaborUnionYes : false,
            checkLaborUnionNo : true,
            disabledLaborUnion : true,
            luDetails : "",
            checkChronicDiseaseYes : false,
            checkChronicDiseaseNo : true,
            disabledDisease : true,

            tblDiseaseArrLst : [],
            diseaseDDL  :    [
                {"name": 'Select Chronic Disease',  "value": "",},
                {"name":"ALLERGIES",            "value":"1"},
                {"name":"ASTHMA",               "value":"2"},
                {"name":"DIABETES",             "value":"3"},
                {"name":"HEPA B",               "value":"4"},
                {"name":"HYPERTENSION",         "value":"5"},
                {"name":"N/A",                  "value":"6"},
                {"name":"OTHERS",               "value":"7"}
            ],
            diagnoseDeseaseId : "",
            othersGrid : [],
            othersGRIDDel : [],
            referenceName : "",
            referenceOccupation : "",
            referenceCOSchool : "",
            referenceCNumber : "",
            referenceGrid : [],
            disableReference : true,
            referenceGRIDDel : [],
            checkDisclaimer : false,
            disablebutton : true,
            disablebuttonsubmit : true,
        }
        
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.data = JSON.parse(sessionStorage.getItem("viewapplicationform_" + this.state.userinfo.userId + this.state.userinfo.employeeId))
        console.log("1")
        console.log(this.state.data)
        this.state.applicationId   =   this.state.data.applicationFormId
        this.state.clientName      =   this.state.data.client
        this.state.clientId        =   this.state.data.clientId
        this.state.position        =   this.state.data.position
        this.state.positionId      =   this.state.data.positionId
        //this.state.regionList =  this.state.region
        this.GetApplicationInformation(this.state.applicationId);
        this.GetApplicationFormDetails(this.state.applicationId);
    }


    componentDidMount(){
        
        //this.state.regionList = "VI - WESTERN VISAYAS"
        this.GetClient(); 
        this.GetPosition();
        this.GetRegion();
        this.GetProvince();
        this.GetCity();
        this.GetLevel();
        this.GetRole();
        this.GetDisease();
        this.GetJobOpening();
        if(this.state.checkORelativeNo === true) {
            this.setState({
                disabledRelative : true,
                oRelativeName : "",
                oCNumber : "",
                oRelationship : "",
                oJobTitle : "",
            })
        }
        if(this.state.checkConvictedNo === true) {
            this.setState({
                disabledConvicted : true,
                oCrime : "",
            })
        }
        if(this.state.checkLaborUnionNo === true) {
            this.setState({
                disabledLaborUnion : true,
                luDetails : "",
            })
        }
        if(this.state.checkChronicDiseaseNo === true) {
            this.setState({
                disabledDisease : true,
                diagnoseDeseaseId : "",
            })
        }

    }

    GetApplicationInformation = async(id)=> {

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ApplicationId" : id,
        };
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationInformation",  params)
            .then(res => {
            const data = res.data;
            //console.log("GetApplicationInformation")
            //console.log(data)
            var today = new Date();
            var birthDate = new Date(data.dateOfBirth);
            var age_now = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
            {
                age_now--;
            }
            this.state.totalAge = age_now
            this.state.ageMonth = new Date(data.dateOfBirth).getMonth()+1
            this.state.ageYear = birthDate.getFullYear()
            // start general information
            this.state.profileId = data.profileId
            this.state.applicationFormInfoId= data.id
            this.state.dateApplied  = new Date(data.dateApplied)
            this.state.fName        = data.firstName
            this.state.nName        = data.nickName
            this.state.mName        = data.middleName
            this.state.lName        = data.lastName
            this.state.titleValue   = data.title
            this.state.hpNumber     = data.homePhoneNumber
            this.state.mobileNumber = data.mobileNumber
            this.state.emailAddress = data.emailAddress
            
            this.state.facebook         = data.facebook
            this.state.twitter          = data.twitter
            this.state.nationalityId    = data.nationalityId
            this.state.religionId       = data.religionId
            this.state.genderValue      = data.gender
            this.state.civilStatusValue = data.civilStatus
            this.state.dateOfBirth      = new Date(data.dateOfBirth)
            this.state.totalAge         = data.age
            
            this.state.placeOfBirth = data.placeOfBirth
            this.state.height       = data.height
            this.state.weight       = data.weight
            this.state.sSkills      = data.specialSkills
            this.state.lSpoken      = data.languageSpoken
            this.state.ecName       = data.emergencyContactName
            this.state.relationship = data.emergencyContactRelation
            this.state.ecNumber     = data.emergencyContactNumber
            
            this.state.sss     = data.sssNumber
            this.state.tin     = data.tinNumber
            this.state.phic    = data.phicNumber
            this.state.pagibig = data.hdmfNumber
            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "1 An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    
    GetApplicationFormDetails = async(id)=> {
        //this.setState({isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ApplicationId" : id,
        };
        console.log("GetApplicationFormDetails params")
        console.log(params)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationFormDetails",  params)
            .then(res => {
            const data = res.data;
            console.log("GetApplicationFormDetails")
            console.log(data)
            /* if(data.applicantAddresses.length>0){
                this.state.region = data.applicantAddresses[0]["region"]
            } */
            if(data.status==="1"){
                if(data.applicantAddresses.length>0){
                    this.setState({
                        addressApplicationFormId      : data.applicantAddresses[0]["applicationFormId"],
                        addressId      : data.applicantAddresses[0]["id"],
                        typeId      : data.applicantAddresses[0]["typeId"],
                        regionId    : data.applicantAddresses[0]["regionId"],
                        region    : data.applicantAddresses[0]["region"],
                        provinceId  : data.applicantAddresses[0]["provinceId"],
                        provinceName  : data.applicantAddresses[0]["province"],
                        cityId      : data.applicantAddresses[0]["cityId"],
                        cityName      : data.applicantAddresses[0]["city"],
                        houseNumber : data.applicantAddresses[0]["houseNumber"],
                        street      : data.applicantAddresses[0]["streetName"],
                        barangay    : data.applicantAddresses[0]["barangay"],
                        postalCode  : data.applicantAddresses[0]["postalCode"],
                        educationGrid : data.educationAttainments,
                        employmentRecordGrid : data.employmentHistories,
                        familyBackgroundGrid : data.familyBackgrounds,
                        othersGrid : data.others,
                        referenceGrid : data.characterReferences,
                    })
                }
                /* this.setState({
                    region : regionString
                }) */
                

                // START educationAttainments APPLICATIONID
                for(let x = 0; x < data.educationAttainments.length; x++){
                    this.state.educationApplicationFormId      = data.educationAttainments[x].applicationFormId
                    this.state.educationId      = data.educationAttainments[x].id
                }
                // START employmentHistories APPLICATIONID
                for(let x = 0; x < data.employmentHistories.length; x++){
                    this.state.employmentApplicationFormId      = data.employmentHistories[x].applicationFormId
                    this.state.employmentId      = data.employmentHistories[x].id
                }
                // START familyBackgrounds APPLICATIONID
                for(let x = 0; x < data.familyBackgrounds.length; x++){
                    this.state.familyApplicationFormId      = data.familyBackgrounds[x].applicationFormId
                    this.state.familyId      = data.familyBackgrounds[x].id
                }
                // START others APPLICATIONID
                for(let x = 0; x < data.others.length; x++){
                    this.state.othersApplicationFormId      = data.others[x].applicationFormId
                    this.state.othersId      = data.others[x].id
                }
                // START characterReferences APPLICATIONID
                for(let x = 0; x < data.characterReferences.length; x++){
                    this.setState({
                        characterApplicationFormId : data.characterReferences[x].applicationFormId,
                        characterId : data.characterReferences[x].id,
                    })
                    //this.state.characterApplicationFormId      = data.characterReferences[x].applicationFormId
                    //this.state.characterId      = data.characterReferences[x].id
                }
            }
            this.setState({
                isloading       :   false
            });
           
            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "2 An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    GetClient = async()=> {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  clientParams)
        .then(res => {
            const data = res.data;
            this.setState({
                clientDDL   :   data.clients,
                isloading   :   false
            });
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleChangeClient = (e) => {
        if(e.length == 0) {
            this.state.clientId     =   ""
            this.state.clientName   =   ""
            return
        }
        this.state.clientId     =   e[0].id
        this.state.clientName   =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    GetPosition = async()=> {
        this.setState({isloading:true})
        const positionParams = {
            "IpAddress"     : "0.0.0.0",
            "ClientId"      : this.state.userinfo.clientId,
            "UserId"        : this.state.userinfo.userId,
            "SectionId"     : "",
            "DepartmentId"  : "",
            "Name"          : ""
        };
        await
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  positionParams)
        .then(res => {
            const data = res.data;
            this.setState({
                positionDDL : data.positions,
                isloading   : false
            });
        })
        
    }
    
    handleChangePosition = (e) => {
        if(e.length == 0) {
            this.state.positionId     =   ""
            this.state.positionName   =   ""
            return
        }
        this.state.positionId     =   e[0].id
        this.state.positionName   =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    handleChangeDateApplied = date => {
        this.setState({
            dateApplied: date,
            isshow:false,
        })
    }
    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }


    handleChangeTitle = (e) => {
        this.setState({ titleValue: e.target.value,isshow:false, });
    };

    onChangeFirstName = (e) => {
        this.setState({ fName: e.target.value,isshow:false, });
    }

    onChangeNickName = (e) => {
        this.setState({ nName: e.target.value,isshow:false });
    }

    onChangeMiddleName = (e) => {
        this.setState({ mName: e.target.value,isshow:false });
    }

    onChangeLastName = (e) => {
        this.setState({ lName: e.target.value,isshow:false });
    }

    handleChangeType = (e) => {
        this.setState({ typeId: e.target.value,isshow:false });
    };

    GetRegion = async()=> {
        this.setState({isloading:true});
        const params = {
            "IpAddress" : "0.0.0.0",
            "ClientId"  : this.state.userinfo.clientId,
            "UserId"    : this.state.userinfo.userId,
            "Name"      : ""
        };
        await
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRegions",  params)
        .then(res => {
            const data = res.data
            this.setState({
                regionDDL   :   data.regions,
                isloading   :   false
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }

    handleChangeRegion = (e) => {
        
        this.state.isshow   =   false
        if(e.length == 0) { 
            this.state.regionId     =   ""
            this.state.region   =   ""
            return
        }
        this.state.regionId     =   e[0].id
        this.state.region   =   e[0].name
        this.setState({
            isshow:false,
            provinceDDL : this.state.provinceonloadDDL.filter(x=>x.region==this.state.region)
        })
        
        //this.GetProvince();
    }

    GetProvince = async()=> {
        this.setState({isloading:true});
        const provinceParams = {
            "IpAddress" : "0.0.0.0",
            "ClientId"  : this.state.userinfo.clientId,
            "UserId"    : this.state.userinfo.userId,
            "Region"    : this.state.region
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetProvinces",  provinceParams)
        .then(res => {
            const data = res.data;
            this.setState({
                provinceonloadDDL : data.provinces,
                provinceDDL : data.provinces,
                isloading   :   false
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })

    }

    handleChangeProvince = (e) => {
        if(e.length == 0) {
            this.state.provinceId     =   ""
            this.state.provinceName   =   ""
            return
        }
        this.state.provinceId     =   e[0].id
        this.state.provinceName   =   e[0].name
        this.setState({
            isshow:false,
            cityDDL : this.state.cityonloadDDL.filter(x=>x.provinceId==this.state.provinceId)
        })
        //this.GetCity();
    }

    GetCity = async()=> {
        this.setState({isloading:true})
        const params = {
            "IpAddress" : "0.0.0.0",
            "ClientId"  : this.state.userinfo.clientId,
            "UserId"    : this.state.userinfo.userId,
            "RegionId"  : this.state.regionId,
            "ProvinceId": this.state.provinceId,
            "Name"      : ""
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetCities",  params)
        .then(res => {
            const data = res.data;
            this.setState({
                cityonloadDDL   : data.cities,
                cityDDL   : data.cities,
                isloading : false
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })

    }

    handleChangeCity = (e) => {
        if(e.length == 0) {
            this.state.cityId     =   ""
            this.state.cityName   =   ""
            return
        }
        this.state.cityId     =   e[0].id
        this.state.cityName   =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    onChangeHouseNumber = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ houseNumber: e.target.value,isshow:false, })
        }
        //this.setState({ houseNumber: e.target.value,isshow:false, });
    };

    onChangeStreet = (e) => {
        this.setState({ street: e.target.value,isshow:false, });
    };

    onChangeBarangay = (e) => {
        this.setState({ barangay: e.target.value,isshow:false, });
    };

    onChangePostalCode = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ postalCode: e.target.value,isshow:false, })
        }
        //this.setState({ postalCode: e.target.value,isshow:false, });
    };

    onChangeHPNumber = (e) => {
        //this.setState({ hpNumber: e.target.value,isshow:false, });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ hpNumber: e.target.value,isshow:false, })
        }
    };

    onChangeOPNumber = (e) => {
        //this.setState({ opNumber: e.target.value,isshow:false, });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ opNumber: e.target.value,isshow:false, })
        }
    };

    onChangeMobileNumber = (e) => {
        //this.setState({ mobileNumber: e.target.value,isshow:false, });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ mobileNumber: e.target.value,isshow:false, })
        }
    };

    onChangeEmailAddress = (e) => {
        this.setState({ emailAddress: e.target.value,isshow:false, });
    };

    onChangeFacebook = (e) => {
        this.setState({ facebook: e.target.value,isshow:false, });
    };

    onChangeFacebook = (e) => {
        this.setState({ facebook: e.target.value,isshow:false, });
    };

    onChangeTwitter = (e) => {
        this.setState({ twitter: e.target.value,isshow:false, });
    };

    handleChangeCitizenship = (e) => {
        this.setState({ nationalityId: e.target.value,isshow:false, });
    };

    handleChangeReligion = (e) => {
        this.setState({ religionId: e.target.value,isshow:false, });
    };

    handleChangeGender = (e) => {
        this.setState({ genderValue: e.target.value,isshow:false });
    };

    handleChangeCivilStatus = (e) => {
        this.setState({ civilStatusValue: e.target.value,isshow:false });
    };

    calculate_age = (date) => {
        var today = new Date();
        var birthDate = new Date(date); 
        ////////console.log("get bod-->",birthDate) // create a date object directly from `dob1` argument
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }
        ////////console.log('my age', age_now);
        this.setState({
            totalAge : age_now
        });
        return age_now;
    }

    handleChangeDateOfBirth = date => {
        
        this.setState({
            dateOfBirth: date,
            isshow:false,
        });
        var today = new Date();
        var birthDate = new Date(date);
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
        {
            age_now--;
        }
        this.state.totalAge = age_now
        this.state.ageMonth = date.getMonth()+1
        this.state.ageYear = birthDate.getFullYear()    
        /* if(date > null) {
            this.state.ageMonth = date.getMonth()+1
            this.state.ageYear = date.getFullYear()
        } */

    }

    onChangeAgeMonth(e){
        this.setState({totalAge:e.target.value});
    }

    onChangePlaceOfBirth = (e) => {
        this.setState({ placeOfBirth: e.target.value,isshow:false });
    };

    onChangeHeight = (e) => {
        this.setState({ height: e.target.value,isshow:false });
    };

    onChangeWeight = (e) => {
        this.setState({ weight: e.target.value,isshow:false });
    };

    onChangeSkills = (e) => {
        this.setState({ sSkills: e.target.value,isshow:false });
    };

    onChangeLSpoken = (e) => {
        this.setState({ lSpoken: e.target.value,isshow:false });
    };

    onChangeECName = (e) => {
        this.setState({ ecName: e.target.value,isshow:false });
    };

    onChangeRelationship = (e) => {
        this.setState({ relationship: e.target.value,isshow:false });
    };

    onChangeECNumber = (e) => {
        //this.setState({ ecNumber: e.target.value,isshow:false });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ ecNumber: e.target.value,isshow:false, })
        }
          
    };

    onChangeSSS = (e) => {
        this.setState({sss: e.target.value,isshow:false})
    }

    onChangeTin = (e) => {
        this.setState({tin: e.target.value,isshow:false})
    }

    onChangePhilHealth = (e) => {
        this.setState({phic: e.target.value,isshow:false})
    }

    onChangePagibig = (e) => {
        this.setState({pagibig: e.target.value,isshow:false})
    }

    handleChangeLevel = (e) => {
        this.setState({ levelId: e.target.value,isshow:false });
    };

    onChangeSchoolName = (e) => {
        this.setState({ schoolName: e.target.value,isshow:false });
    };

    onChangeCourse = (e) => {
        this.setState({ course: e.target.value,isshow:false });
    };

    onChangeHonorsAward = (e) => {
        this.setState({ honorsAward: e.target.value,isshow:false });
    };

    onChangeStartYear = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ startYear: e.target.value,isshow:false, })
        }
        //this.setState({ startYear: e.target.value,isshow:false });
    };

    onChangeEndYear = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ endYear: e.target.value,isshow:false, })
        }
        //this.setState({ endYear: e.target.value,isshow:false });
    };

    handleAddEducation = (e) =>{
        let educationGrid = this.state.educationGrid

        if(this.state.levelId===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select level.",
                fade        :   false
            });
            return
        }

        if(this.state.schoolName===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter school name.",
                fade        :   false
            });
            return
        }

        if(this.state.course===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter course.",
                fade        :   false
            });
            return
        }

        if(this.state.honorsAward===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter honors Award.",
                fade        :   false
            });
            return
        }

        if(this.state.startYear===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter start year.",
                fade        :   false
            });
            return
        }

        if(this.state.endYear===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter end year.",
                fade        :   false
            });
            return
        }

        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        const obj = {
            "id":newId.toString(),
            "id"      : this.state.educationId,
            "applicationFormId"      : this.state.addressApplicationFormId,
            /* "educationId"      : this.state.educationId,
            "addressApplicationFormId"      : this.state.addressApplicationFormId, */
            "levelId"       :   this.state.levelId,
            "schoolName"    :   this.state.schoolName,
            "course"        :   this.state.course,
            "startYear"     :   this.state.startYear,
            "endYear"       :   this.state.endYear,
            "honorRecieved" :   this.state.honorsAward,
            "contactPerson" :   "",
            "contactNumber" :   "", 
            "isDeleted"    :   "0",   

            //"level":this.state.level,
        }
        educationGrid.push(obj)      
        this.setState({
            isshow:false,
            educationGrid:educationGrid,
            levelId:"",
            schoolName:"",
            course:"",
            startYear:"",
            endYear:"",
            honorsAward:"",
        })

        ////////console.log(educationGrid)
    }
    handleRemoveEducation = (id) =>{
        let educationGrid = this.state.educationGrid
        let educationGRIDDel = this.state.educationGRIDDel
        educationGrid.map(function(itm){
            if(itm.id===id){
                itm.isDeleted="1"
                educationGRIDDel.push(itm)
            }
        })
        this.setState({educationGrid:educationGrid.filter(x=>x.isDeleted==="0")})
        /* let educationGrid = this.state.educationGrid.filter(x=>x.isDeleted=="0")
        this.state.educationGrid = []
        this.setState({educationGrid:educationGrid,disableRemoveEducation: educationGrid.length===0 ? true : false}) */
    }

    EducationGridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.educationGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
    }

    GetLevel() {
        for (let i = 0; i < this.state.levelDDL.length; i++) {
            const obj = {
                value : this.state.levelDDL[i]["value"],
                label : this.state.levelDDL[i]["name"],
            };
            this.state.tblLevelArrLst.push(obj);
        }
        
    }

    onChangeERCompanyName = (e) => {
        this.setState({ erCompanyName: e.target.value,isshow:false });
    };

    onChangeERCompanyAddress = (e) => {
        this.setState({ erCompanyAddress: e.target.value,isshow:false });
    };

    onChangeERPosition = (e) => {
        this.setState({ erPosition: e.target.value,isshow:false });
    };

    onChangeERCPeriodCover = (e) => {
        
        this.setState({ erPeriodCover: e.target.value,isshow:false });
    };

    onChangeERSalary = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ erSalary: e.target.value,isshow:false, })
        }
        //this.setState({ erSalary: e.target.value,isshow:false });
    };

    onChangeERSupervisor = (e) => {
        this.setState({ erSupervisor: e.target.value,isshow:false });
    };

    onChangeERCNumber = (e) => {
        //this.setState({ erCNumber: e.target.value,isshow:false });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ erCNumber: e.target.value,isshow:false, })
        }
    };

    onChangeERRFLeaving = (e) => {
        this.setState({ erRFLeaving: e.target.value,isshow:false });
    };

    onChangeERCIncome = (e) => {
        this.setState({ erTCIncome: e.target.value,isshow:false });
    };

    onChangeERWHTax = (e) => {
        this.setState({ erWHTax: e.target.value,isshow:false });
    };

    onChangeERYOCompensation = (e) => {
        this.setState({ erYOCompensation: e.target.value,isshow:false });
    };

    onChangeERMPAOB = (e) => {
        this.setState({ erMPAOB: e.target.value,isshow:false });
    };

    onChangeERDBenefits = (e) => {
        this.setState({ erDBenefits: e.target.value,isshow:false });
    };

    onChangeERMDeduction = (e) => {
        this.setState({ erMDeduction: e.target.value,isshow:false });
    };

    onChangeERNTSOFOC = (e) => {
        this.setState({ erNTSOFOC: e.target.value,isshow:false });
    };

    onChangeERBasicSalary = (e) => {
        this.setState({ erBasicSalary: e.target.value,isshow:false });
    };

    onChangeERTMPOB = (e) => {
        this.setState({ erTMPOB: e.target.value,isshow:false });
    };

    onChangeERTSPFOC = (e) => {
        this.setState({ erTSPFOC: e.target.value,isshow:false });
    };

    onChangeERBPFMWE = (e) => {
        this.setState({ erBPFMWE: e.target.value,isshow:false });
    };

    onChangeERHPFHWE = (e) => {
        this.setState({ erHPFHWE: e.target.value,isshow:false });
    };

    onChangeEROPFHWE = (e) => {
        this.setState({ erOPFHWE: e.target.value,isshow:false });
    };

    onChangeERNDPFHWE = (e) => {
        this.setState({ erNDPFHWE: e.target.value,isshow:false });
    };

    onChangeERRemarks = (e) => {
        this.setState({ erRemarks: e.target.value,isshow:false, });
    };

    onChangesFreshGraduate  = (e) => {
        let employmentRecordGrid = this.state.employmentRecordGrid
        this.state.checkIsFreshGraduate = e.target.checked
        if(this.state.checkIsFreshGraduate == true) {
            
            let today = new Date()
            let newId = moment(today).format('HHMMSS');
            const obj = {
                "id":newId.toString(),
                "companyName"               :   "N/A",
                "companyAddress"            :   "N/A",
                "position"                  :   "N/A",
                "salary"                    :   "0",
                "periodCovered"             :   "N/A",
                "isModified"                :   "0",
                "supervisor"                :   this.state.erSupervisor,
                "contactNumber"             :   this.state.erCNumber,
                "reasonForLeaving"          :   this.state.erRFLeaving,
                "taxableCompensationIncome" :   this.state.erTCIncome,
                "withholdingTax"            :   this.state.erWHTax,
                "yearOfCompensation"        :   this.state.erYOCompensation,
                "nTThirteenMonthPay"        :   this.state.erMPAOB,
                "deminimis"                 :   this.state.erDBenefits,
                "nTMandatoryDeduction"      :   this.state.erMDeduction,
                "nTSalaries"                :   this.state.erNTSOFOC,
                "basicSalary"               :   this.state.erBasicSalary,
                "taxableThirteenMonthPay"   :   this.state.erTMPOB,
                "taxableSalaries"           :   this.state.erTSPFOC,
                "basicPayMWE"               :   this.state.erBPFMWE,
                "holidayPayMWE"             :   this.state.erHPFHWE,
                "overtimePayMWE"            :   this.state.erOPFHWE,
                "nightDiffPayMWE"           :   this.state.erNDPFHWE,
                "remarks"                   :   this.state.erRemarks,
                "isDeleted"    :   "0",
            }
            employmentRecordGrid.push(obj)      
            this.setState({
                employmentRecordGrid:employmentRecordGrid,
                isshow:false,
                isEmploymentDisabled : true,
            })
            ////console.log(employmentRecordGrid)
        } else {     
            this.setState({
                employmentRecordGrid: [],
                isshow:false,
                isEmploymentDisabled : false,
            })
        }
    }


    handleAddEmploymentRecord = (e) =>{
        let employmentRecordGrid = this.state.employmentRecordGrid


        if(this.state.erCompanyName===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter company name.",
                fade        :   false
            });
            return
        }

        if(this.state.erCompanyAddress===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter company address.",
                fade        :   false
            });
            return
        }

        if(this.state.erPosition===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter position.",
                fade        :   false
            });
            return
        }

        if(this.state.erSalary===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please salary.",
                fade        :   false
            });
            return
        }

        if(this.state.erPeriodCover===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please period covered.",
                fade        :   false
            });
            return
        }

        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        const obj = {
            "id":newId.toString(),
            "id"      : this.state.employmentId,
            "applicationFormId"      : this.state.employmentApplicationFormId,
            "companyName"               :   this.state.erCompanyName,
            "companyAddress"            :   this.state.erCompanyAddress,
            "position"                  :   this.state.erPosition,
            "salary"                    :   this.state.erSalary,
            "supervisor"                :   this.state.erSupervisor,
            "contactNumber"             :   this.state.erCNumber,
            "reasonForLeaving"          :   this.state.erRFLeaving,
            "periodCovered"             :   this.state.erPeriodCover,
            "taxableCompensationIncome" :   this.state.erTCIncome,
            "withholdingTax"            :   this.state.erWHTax,
            "yearOfCompensation"        :   this.state.erYOCompensation,
            "nTThirteenMonthPay"        :   this.state.erMPAOB,
            "deminimis"                 :   this.state.erDBenefits,
            "nTMandatoryDeduction"      :   this.state.erMDeduction,
            "nTSalaries"                :   this.state.erNTSOFOC,
            "basicSalary"               :   this.state.erBasicSalary,
            "taxableThirteenMonthPay"   :   this.state.erTMPOB,
            "taxableSalaries"           :   this.state.erTSPFOC,
            "basicPayMWE"               :   this.state.erBPFMWE,
            "holidayPayMWE"             :   this.state.erHPFHWE,
            "overtimePayMWE"            :   this.state.erOPFHWE,
            "nightDiffPayMWE"           :   this.state.erNDPFHWE,
            "remarks"                   :   this.state.erRemarks,
            "isModified"                :   "0",
            "isDeleted"    :   "0",  
        }
        employmentRecordGrid.push(obj)      
        this.setState({
            employmentRecordGrid:employmentRecordGrid,
            isshow:false,
            
            erCompanyName : "",
            erCompanyAddress : "",
            erPosition : "",
            erPeriodCover : "",
            erSalary : "",
            erSupervisor : "",
            erCNumber : "",
            erRFLeaving : "",
            erTCIncome : "",
            erWHTax : "",
            erYOCompensation : "",
            erMPAOB : "",
            erDBenefits : "",
            erMDeduction : "",
            erNTSOFOC: "",
            erBasicSalary : "",
            erTMPOB : "",
            erTSPFOC : "",
            erBPFMWE : "",
            erHPFHWE : "",
            erOPFHWE : "",
            erNDPFHWE : "",
            erRemarks : "",
        })
        ////////console.log(employmentRecordGrid)
    }
    handleRemoveEmploymentRecord = (id) =>{
        let employmentRecordGrid = this.state.employmentRecordGrid
        let employmentRecordGRIDDel = this.state.employmentRecordGRIDDel
        employmentRecordGrid.map(function(itm){
            if(itm.id===id){
                itm.isDeleted="1"
                employmentRecordGRIDDel.push(itm)
            }
        })
        this.setState({employmentRecordGrid:employmentRecordGrid.filter(x=>x.isDeleted==="0")})
        /* let employmentRecordGrid = this.state.employmentRecordGrid.filter(x=>x.isDeleted=="0")
        this.state.employmentRecordGrid = []
        this.setState({employmentRecordGrid:employmentRecordGrid,disableRemoveEmploymentRecord: employmentRecordGrid.length===0 ? true : false}) */
    }

    EmploymentGridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.employmentRecordGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
       // this.setState({disableRemoveEducation:disable})
    }



    handleChangeRole = (e) => {
        this.setState({ roleId: e.target.value,role: e.target.name,isshow:false });
    };

    onChangeFBName = (e) => {
        this.setState({ fbName: e.target.value,isshow:false });
    };

    onChangeFBAge = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({fbAge: e.target.value,isshow:false})
        }
    };

    onChangeFBOccupation = (e) => {
        this.setState({ fbOccupation: e.target.value,isshow:false });
    };

    onChangeFBCOSchool = (e) => {
        this.setState({ fbCOSchool: e.target.value,isshow:false });
    };

    onChangeFBCNumber = (e) => {
        //this.setState({ fbCNumber: e.target.value,isshow:false });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ fbCNumber: e.target.value,isshow:false, })
        }
    };

    GetRole() {
        for (let i = 0; i < this.state.roleDDL.length; i++) {
            const obj = {
                value : this.state.roleDDL[i]["value"],
                label : this.state.roleDDL[i]["name"],
            };
            this.state.tblRoleArrLst.push(obj);
        }
        
    }

    handleAddFamilybackground = (e) =>{
        let familyBackgroundGrid = this.state.familyBackgroundGrid


        if(this.state.roleId===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select Role.",
                fade        :   false
            });
            return
        }

        if(this.state.fbName===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter Name.",
                fade        :   false
            });
            return
        }

        if(this.state.fbAge===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter Age.",
                fade        :   false
            });
            return
        }

        if(this.state.fbOccupation===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please Occupation.",
                fade        :   false
            });
            return
        }

        if(this.state.fbCOSchool===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please Company Or School.",
                fade        :   false
            });
            return
        }

        if(this.state.fbCNumber===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please Contact Number.",
                fade        :   false
            });
            return
        }

        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        const obj = {
            "id":newId.toString(),
            "id"      : this.state.familyId,
            "applicationFormId"      : this.state.familyApplicationFormId,
            "roleId"        :   this.state.roleId,
            "name"          :   this.state.fbName,
            "contactNumber" :   this.state.fbCNumber,
            "age"           :   this.state.fbAge,
            "occupation"    :   this.state.fbOccupation,
            "company"       :   this.state.fbCOSchool,
            "userId"        :   this.state.userinfo.userId,
            "isDeleted"     :   "0",  
        }
        familyBackgroundGrid.push(obj)      
        this.setState({
            familyBackgroundGrid:familyBackgroundGrid,
            isshow:false,
            roleId : "",
            fbName : "",
            fbAge : "",
            fbOccupation : "",
            fbCOSchool : "",
            fbCNumber : "",
        })
        ////////console.log(familyBackgroundGrid)
    }
    handleRemoveBackground = (id) =>{
        let familyBackgroundGrid = this.state.familyBackgroundGrid
        let backgroundGRIDDel = this.state.backgroundGRIDDel
        familyBackgroundGrid.map(function(itm){
            if(itm.id===id){
                itm.isDeleted="1"
                backgroundGRIDDel.push(itm)
            }
        })
        this.setState({familyBackgroundGrid:familyBackgroundGrid.filter(x=>x.isDeleted==="0")})
        /* let familyBackgroundGrid = this.state.familyBackgroundGrid.filter(x=>x.isDeleted=="0")
        this.state.familyBackgroundGrid = []
        this.setState({familyBackgroundGrid:familyBackgroundGrid,disableBackground: familyBackgroundGrid.length===0 ? true : false}) */
    }

    FamilyBackgroundGridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.familyBackgroundGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
       // this.setState({disableRemoveEducation:disable})
    }



    handleChangeJobOpening = (e) => {
        this.setState({ jobSourceId: e.target.value,isshow:false });
    };

    onChangeOthersRefferedBy = (e) => {
        this.setState({ oReferredBy: e.target.value,isshow:false });
    };
    
    onChangeORelativeYes  = (e) => {
        this.state.checkORelativeYes = e.target.checked
        if(this.state.checkORelativeYes == true) {
            this.setState({
                checkORelativeNo    :   false,
                disabledRelative    : false,
            })
        }
    }
    
    onChangeORelativeNo  = (e) => {
        this.state.checkORelativeNo = e.target.checked
        if(this.state.checkORelativeNo == true) {
            this.setState({
                checkORelativeYes   :   false,
                disabledRelative    : true,
                oRelativeName : "",
                oCNumber : "",
                oRelationship : "",
                oJobTitle : "",
            })
        }
    }

    onChangeORelativeName = (e) => {
        this.setState({ oRelativeName: e.target.value,isshow:false });
    };

    onChangeOCNumber = (e) => {
        //this.setState({ oCNumber: e.target.value,isshow:false });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ oCNumber: e.target.value,isshow:false, })
        }
    };

    onChangeORelationship = (e) => {
        this.setState({ oRelationship: e.target.value,isshow:false });
    };

    onChangeOJobTitle = (e) => {
        this.setState({ oJobTitle: e.target.value,isshow:false });
    };
    
    onChangeOthersConvictedYes  = (e) =>{
        this.state.checkConvictedYes = e.target.checked
        if(this.state.checkConvictedYes == true) {
            this.setState({
                checkConvictedNo  :   false,
                disabledConvicted  		  :   false,
            })
        }
    }
    
    onChangeOthersConvictedNo  = (e) =>{
        this.state.checkConvictedNo = e.target.checked
        if(this.state.checkConvictedNo == true) {
            this.setState({
                disabledConvicted  :   true,
                checkConvictedYes  		  :   false,
                crime : "",
            })
        }
    }
    
    onChangeOthersLaborUnionYes  = (e) =>{
        this.state.checkLaborUnionYes = e.target.checked
        if(this.state.checkLaborUnionYes == true) {
            this.setState({
                checkLaborUnionNo  :   false,
                disabledLaborUnion  :   false,
            })
        }
    }

    onChangeOCrime = (e) => {
        this.setState({ oCrime: e.target.value,isshow:false });
    };
    
    onChangeOthersLaborUnionNo  = (e) =>{
        this.state.checkLaborUnionNo = e.target.checked
        if(this.state.checkLaborUnionNo == true) {
            this.setState({
                checkLaborUnionYes  :   false,
                disabledLaborUnion  :   true,
            })
        }
    }

    onChangeLUDetails = (e) => {
        this.setState({ luDetails: e.target.value,isshow:false });
    };
    
    onChangeOthersChronicDiseaseYes  = (e) =>{
        this.state.checkChronicDiseaseYes = e.target.checked
        if(this.state.checkChronicDiseaseYes == true) {
            this.setState({
                checkChronicDiseaseNo  :   false,
                disabledDisease  :   false,
            })
        }
    }
    
    onChangeOthersChronicDiseaseNo  = (e) =>{
        this.state.checkChronicDiseaseNo = e.target.checked
        if(this.state.checkChronicDiseaseNo == true) {
            this.setState({
                checkChronicDiseaseYes  :   false,
                disabledDisease :   true,
                diagnoseDeseaseId : "",
            })
        }
    }

    handleChangeDisease = (e) => {
        this.setState({ diagnoseDeseaseId: e.target.value,chronicDisease: e.target.name,isshow:false });
    };

    GetJobOpening() {
        for (let i = 0; i < this.state.jobOpeningDDL.length; i++) {
            const obj = {
                value : this.state.jobOpeningDDL[i]["value"],
                label : this.state.jobOpeningDDL[i]["name"],
            };
            this.state.tblJobOpeningArrLst.push(obj);
        }
        
    }

    GetDisease() {
        for (let i = 0; i < this.state.diseaseDDL.length; i++) {
            const obj = {
                value : this.state.diseaseDDL[i]["value"],
                label : this.state.diseaseDDL[i]["name"],
            };
            this.state.tblDiseaseArrLst.push(obj);
        }
        
    }
    
    handleAddOthers = (e) =>{
        if(this.state.jobSourceId === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select job opening",
                fade:true
            });
            return
        }
        if(this.state.oReferredBy === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter referred by",
                fade:true
            });
            return
        }
        if(this.state.checkORelativeYes === true) {
            if(this.state.oRelativeName === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter name",
                    fade:true
                });
                return
            }
            if(this.state.oCNumber === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter contact number",
                    fade:true
                });
                return
            }
            if(this.state.oRelationship === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter relationship",
                    fade:true
                });
                return
            }
            if(this.state.oJobTitle === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter job title",
                    fade:true
                });
                return
            }
        }
        
        if(this.state.checkConvictedYes === true) {
            if(this.state.oCrime === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter details",
                    fade:true
                });
                return
            }
        }
        
        if(this.state.checkLaborUnionYes === true) {
            if(this.state.luDetails === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter details",
                    fade:true
                });
                return
            }
        }
        
        if(this.state.checkChronicDiseaseYes === true) {
            if(this.state.diagnoseDeseaseId === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please select details",
                    fade:true
                });
                return
            }
        }

        let othersGrid = this.state.othersGrid

        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        const obj = {
            "id":newId.toString(),
            "id"      : this.state.othersId,
            "applicationFormId"      : this.state.othersApplicationFormId,
            "jobSourceId":this.state.jobSourceId,
            "refferedBy":this.state.oReferredBy,
            "hasCompanyRelative":(this.state.checkORelativeYes)? "1" : "0",
            "relativeName":this.state.oRelativeName,
            "relativeRelation":this.state.oRelationship,
            "contactNumber":this.state.oCNumber,
            "relativePosition":this.state.oJobTitle,
            "isCrimeConvicted":(this.state.checkConvictedYes)? "1" : "0",
            "crimeDescription":this.state.oCrime,
            "isLaborUnion":(this.state.checkLaborUnionYes)? "1" : "0",
            "laborUnionDescription":this.state.luDetails,
            "diagnoseDeseaseId":this.state.diagnoseDeseaseId,
            "userId":this.state.userinfo.userId,
            "isDeleted"    :   "0",  
        }
        othersGrid.push(obj)      
        this.setState({
            othersGrid:othersGrid,
            isshow:false,
            jobSourceId : "",
            checkConvictedYes : false,
            checkConvictedNo : true,
            oCrime : "",
            disabledConvicted : true,
            oReferredBy : "",
            checkLaborUnionYes : false,
            checkLaborUnionNo : true,
            disabledLaborUnion : true,
            luDetails : "",
            checkChronicDiseaseYes : false,
            checkChronicDiseaseNo : true,
            disabledDisease : true,
            diagnoseDeseaseId : "",
        })
        ////////console.log(othersGrid)
    }
    handleRemoveOthers = (id) =>{
        let othersGrid = this.state.othersGrid
        let othersGRIDDel = this.state.othersGRIDDel
        othersGrid.map(function(itm){
            if(itm.id===id){
                itm.isDeleted="1"
                othersGRIDDel.push(itm)
            }
        })
        this.setState({othersGrid:othersGrid.filter(x=>x.isDeleted==="0")})
        /* let othersGrid = this.state.othersGrid.filter(x=>x.isDeleted=="0")
        this.state.othersGrid = []
        this.setState({othersGrid:othersGrid,disableOthers: othersGrid.length===0 ? true : false}) */
    }

    OtherGridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.othersGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
       // this.setState({disableRemoveEducation:disable})
    }


    onChangeReferenceName = (e) => {
        this.setState({ referenceName: e.target.value,isshow:false });
    };

    onChangeReferencOccupation = (e) => {
        this.setState({ referenceOccupation: e.target.value,isshow:false });
    };

    onChangeReferenceCOSchool = (e) => {
        this.setState({ referenceCOSchool: e.target.value,isshow:false });
    };

    onChangeReferenceCNumber = (e) => {
        //this.setState({ referenceCNumber: e.target.value,isshow:false });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ referenceCNumber: e.target.value,isshow:false, })
        }
         
    };

    handleAddReference = (e) =>{
		if(this.state.referenceName === "") {
		    this.setState({
		        isloading:false,
		        alerttype:"Error!",
		        isshow:true,
		        color:"danger",
		        message: "please enter name",
		        fade:true
		    });
		    return
        }
		if(this.state.referenceOccupation === "") {
		    this.setState({
		        isloading:false,
		        alerttype:"Error!",
		        isshow:true,
		        color:"danger",
		        message: "please enter occupation",
		        fade:true
		    });
		    return
        }
		if(this.state.referenceCOSchool === "") {
		    this.setState({
		        isloading:false,
		        alerttype:"Error!",
		        isshow:true,
		        color:"danger",
		        message: "please enter company or school",
		        fade:true
		    });
		    return
        }
		if(this.state.referenceCNumber === "") {
		    this.setState({
		        isloading:false,
		        alerttype:"Error!",
		        isshow:true,
		        color:"danger",
		        message: "please enter contact number",
		        fade:true
		    });
		    return
        }
        
        let referenceGrid = this.state.referenceGrid

        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        const obj = {
            "id":newId.toString(),
            "id"      : this.state.characterId,
            "applicationFormId"      : this.state.characterApplicationFormId,
            "name"          :  this.state.referenceName,
            "contactNumber" :   this.state.referenceCNumber,
            "occupation"    :   this.state.referenceOccupation,
            "company"       :   this.state.referenceCOSchool,
            "userId"        :this.state.userinfo.userId,
            "isDeleted"    :   "0",  
        }
        referenceGrid.push(obj)      
        this.setState({
            referenceGrid:referenceGrid,
            isshow:false,
            referenceName : "",
            referenceOccupation : "",
            referenceCOSchool : "",
            referenceCNumber : "",
        })
        ////////console.log(referenceGrid)
    }
    handleRemoveReference = (id) =>{
        let referenceGrid = this.state.referenceGrid
        let referenceGRIDDel = this.state.referenceGRIDDel
        referenceGrid.map(function(itm){
            if(itm.id===id){
                itm.isDeleted="1"
                referenceGRIDDel.push(itm)
            }
        })
        this.setState({referenceGrid:referenceGrid.filter(x=>x.isDeleted==="0")})
        /* let referenceGrid = this.state.referenceGrid.filter(x=>x.isDeleted=="0")
        this.state.referenceGrid = []
        this.setState({referenceGrid:referenceGrid,disableReference: referenceGrid.length===0 ? true : false}) */
    }

    ReferenceGridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.referenceGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
       // this.setState({disableRemoveEducation:disable})
    }
    
    onChangeDisclaimer  = (e) =>{
        this.state.checkDisclaimer = e.target.checked
        if(this.state.checkDisclaimer == true) {
            if(this.state.data.statusId === "1") {
                this.state.disablebuttonsubmit = false
            }
            this.setState({
                disablebutton :   false,
            })
        } else {
            this.setState({
                disablebutton :   true,
                disablebuttonsubmit : true,
            })
        }
    }
    
    handleClickStatus = (statusId) => {
        
        if(this.state.clientId === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select client",
                fade:true
            });
            return
        }
        if(this.state.positionId === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select position",
                fade:true
            });
            return
        }
        if(this.state.dateApplied === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select date applied",
                fade:true
            });
            return
        }
        if(this.state.titleValue === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select name title",
                fade:true
            });
            return
        }
        if(this.state.fName === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter first name",
                fade:true
            });
            return
        }
        if(this.state.nName === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter nick name",
                fade:true
            });
            return
        }
        if(this.state.lName === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter last name",
                fade:true
            });
            return
        }
        if(this.state.typeId === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select address type",
                fade:true
            });
            return
        }
        if(this.state.regionId === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select region",
                fade:true
            });
            return
        }
        if(this.state.provinceId === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select province",
                fade:true
            });
            return
        }
        if(this.state.cityId === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select city",
                fade:true
            });
            return
        }
        if(this.state.houseNumber === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter houseNumber",
                fade:true
            });
            return
        }
        if(this.state.street === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter street",
                fade:true
            });
            return
        }
        if(this.state.barangay === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter barangay",
                fade:true
            });
            return
        }
        if(this.state.postalCode === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter postalCode",
                fade:true
            });
            return
        }
        if(this.state.emailAddress === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter email address",
                fade:true
            });
            return
        }
        if(this.state.mobileNumber === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter mobile number",
                fade:true
            });
            return
        }
        if(this.state.hpNumber === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter home phone number",
                fade:true
            });
            return
        }
        if(this.state.facebook === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter facebook",
                fade:true
            });
            return
        }
        if(this.state.twitter === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter twitter",
                fade:true
            });
            return
        }
        if(this.state.nationalityId === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select nationality",
                fade:true
            });
            return
        }
        if(this.state.religionId === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select religion",
                fade:true
            });
            return
        }
        if(this.state.genderValue === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select gender",
                fade:true
            });
            return
        }
        if(this.state.civilStatusValue === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select civil status",
                fade:true
            });
            return
        }
        if(this.state.dateOfBirth === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select date of birth",
                fade:true
            });
            return
        }
        if(this.state.placeOfBirth === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter place of birth",
                fade:true
            });
            return
        }
        if(this.state.height === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter height",
                fade:true
            });
            return
        }
        if(this.state.weight === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter weight",
                fade:true
            });
            return
        }
        if(this.state.sSkills === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter special skills",
                fade:true
            });
            return
        }
        if(this.state.lSpoken === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter language spoken",
                fade:true
            });
            return
        }
        if(this.state.ecName === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter emergency contact name",
                fade:true
            });
            return
        }
        if(this.state.relationship === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter emergency contact relation",
                fade:true
            });
            return
        }
        if(this.state.ecNumber === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter emergency contact number",
                fade:true
            });
            return
        }
        if(this.state.tin === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter tin number",
                fade:true
            });
            return
        }
        if(this.state.sss === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter sss number",
                fade:true
            });
            return
        }
        if(this.state.phic === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter philhealth number",
                fade:true
            });
            return
        }
        if(this.state.pagibig === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter pagibig number",
                fade:true
            });
            return
        }

        if(18 > this.state.totalAge){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "Age below 18 is not valid",
                fade:true
            });
            return
        }

        if(!this.state.educationGrid.length) {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please input all fields under education",
                fade:true
            });
            return
        }

        if(!this.state.employmentRecordGrid.length) {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please input some fields under employment record",
                fade:true
            });
            return
        }

        if(!this.state.familyBackgroundGrid.length) {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please input all fields under family background",
                fade:true
            });
            return
        }

        if(!this.state.othersGrid.length) {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please input all fields under others",
                fade:true
            });
            return
        }

        if(!this.state.referenceGrid.length) {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please input all fields under reference",
                fade:true
            });
            return
        }

        /* let neweducationGrid = []
        this.state.educationGrid.map(function(itm,i) {
            neweducationGrid.push({
                "Id"      : itm.educationId,
                "ApplicationFormId"      : itm.addressApplicationFormId,
                "LevelId"       :   itm.levelId,
                "ContactPerson" :   itm.contactPerson,
                "ContactNumber" :   itm.contactNumber,
                "SchoolName"    :   itm.schoolName,
                "Course"        :   itm.course,
                "StartYear"     :   itm.startYear,
                "EndYear"       :   itm.endYear,
                "HonorRecieved" :   itm.honorRecieved,
                "isDeleted"    :   "0",  
            })
        })    */       

        /* this.state.employmentRecordGrid.map(function(itm,i) {
            newemploymentRecordGrid.push({
                "Id"      : itm.employmentId,
                "ApplicationFormId"      : itm.employmentApplicationFormId,
                "CompanyName"               :   itm.companyName,
                "CompanyAddress"            :   itm.companyAddress,
                "Position"                  :   itm.position,
                "Salary"                    :   itm.salary,
                "Supervisor"                :   itm.supervisor,
                "ContactNumber"             :   itm.contactNumber,
                "ReasonForLeaving"          :   itm.reasonForLeaving,
                "PeriodCovered"             :   itm.periodCovered,
                "TaxableCompensationIncome" :   itm.taxableCompensationIncome,
                "WithholdingTax"            :   itm.withholdingTax,
                "YearOfCompensation"        :   itm.yearOfCompensation,
                "NTThirteenMonthPay"        :   itm.nTThirteenMonthPay,
                "Deminimis"                 :   itm.deminimis,
                "NTMandatoryDeduction"      :   itm.nTMandatoryDeduction,
                "NTSalaries"                :   itm.nTSalaries,
                "BasicSalary"               :   itm.basicSalary,
                "TaxableThirteenMonthPay"   :   itm.taxableThirteenMonthPay,
                "TaxableSalaries"           :   itm.taxableSalaries,
                "BasicPayMWE"               :   itm.basicPayMWE,
                "HolidayPayMWE"             :   itm.holidayPayMWE,
                "OvertimePayMWE"            :   itm.overtimePayMWE,
                "NightDiffPayMWE"           :   itm.nightDiffPayMWE,
                "Remarks"                   :   itm.remarks,
                "isDeleted"    :   "0",  
            })
        })                    

        this.state.familyBackgroundGrid.map(function(itm,i) {
            newfamilyBackgroundGrid.push({
                "Id"      : itm.familyId,
                "ApplicationFormId"      : itm.familyApplicationFormId,
                "RoleId"        :   itm.roleId,
                "Name"          :   itm.name,
                "ContactNumber" :   itm.contactNumber,
                "Age"           :   itm.age,
                "Occupation"    :   itm.occupation,
                "Company"       :   itm.company,
                "UserId":itm.userId,
                "isDeleted"    :   "0",  
            })
        })             

        this.state.othersGrid.map(function(itm,i) {
            newothersGrid.push({
                "Id"      : itm.othersId,
                "ApplicationFormId"      : itm.othersApplicationFormId,
                "JobSourceId":itm.jobSourceId,
                "RefferedBy":itm.refferedBy,
                "HasCompanyRelative":itm.hasCompanyRelative,
                "RelativeName":itm.relativeName,
                "RelativeRelation":itm.relativeRelation,
                "RelativePosition":itm.relativePosition,
                "IsCrimeConvicted":itm.isCrimeConvicted,
                "CrimeDescription":itm.crimeDescription,
                "IsLaborUnion":itm.isLaborUnion,
                "LaborUnionDescription":itm.laborUnionDescription,
                "DiagnoseDeseaseId":itm.diagnoseDeseaseId,
                "UserId":itm.userId,
                "isDeleted"    :   "0",  
            })
        })           

        this.state.referenceGrid.map(function(itm,i) {
            newreferenceGrid.push({
                "Id"      : itm.characterId,
                "ApplicationFormId"      : itm.characterApplicationFormId,
                "Name"          :  itm.name,
                "ContactNumber" :   itm.contactNumber,
                "Occupation"    :   itm.occupation,
                "Company"       :   itm.company,
                "UserId"        :itm.userId,
                "isDeleted"    :   "0",  
            })
        }) */

        this.setState({isloading:true})

        let education = this.state.educationGrid.concat(this.state.educationGRIDDel)
        let employmentRecord = this.state.employmentRecordGrid.concat(this.state.employmentRecordGRIDDel)
        let familyBackground = this.state.familyBackgroundGrid.concat(this.state.backgroundGRIDDel)
        let others = this.state.othersGrid.concat(this.state.othersGRIDDel)
        let reference = this.state.referenceGrid.concat(this.state.referenceGRIDDel)
        

        const params = {
            "IpAddress"                 : "0.0.0.0",
            "ApplicationFormId"      : this.state.applicationFormInfoId,
            "ClientId"                  : this.state.clientId,
            "UserId"                    : this.state.userinfo.userId,
            "StatusId"                  : statusId,
            "PositionId"                : this.state.positionId,
            "DateApplied"               : this.FormatDate(this.state.dateApplied),
            "Title"                     : this.state.titleValue,
            "FirstName"                 : this.state.fName,
            "NickName"                  : this.state.nName,
            "MiddleName"                : this.state.mName,
            "LastName"                  : this.state.lName,
            "EmailAddress"              : this.state.emailAddress,
            "MobileNumber"              : this.state.mobileNumber,
            "HomePhoneNumber"           : this.state.hpNumber,
            "Facebook"                  : this.state.facebook,
            "Twitter"                   : this.state.twitter,
            "NationalityId"             : this.state.nationalityId,
            "ReligionId"                : this.state.religionId,
            "Gender"                    : this.state.genderValue,
            "CivilStatus"               : this.state.civilStatusValue,
            "DateOfBirth"               : this.FormatDate(this.state.dateOfBirth),
            "AgeYear"                   : this.state.ageYear,
            "AgeMonth"                  : this.state.ageMonth,
            "PlaceOfBirth"              : this.state.placeOfBirth,
            "Height"                    : this.state.height,
            "Weight"                    : this.state.weight,
            "SpecialSkills"             : this.state.sSkills,
            "LanguageSpoken"            : this.state.lSpoken,
            "EmergencyContactName"      : this.state.ecName,
            "EmergencyContactRelation"  : this.state.relationship,
            "EmergencyContactNumber"    : this.state.ecNumber,
            "Hobbies"                   : "",
            "TINNumber"                 : this.state.tin,
            "SSSNumber"                 : this.state.sss,
            "PHICNumber"                : this.state.phic,
            "HDMFNumber"                : this.state.pagibig,
            "LivingArrangementId"       : "1",
            "BloodTypeId"               : "1",

            "applicantAddresses" :[{
                "Id"      : this.state.addressId,
                "ApplicationFormId"      : this.state.addressApplicationFormId,
                "TypeId"      : this.state.typeId,
                "RegionId"    : this.state.regionId,
                "Region"      : this.state.region,
                "ProvinceId"  : this.state.provinceId,
                "Province"    : this.state.provinceName,
                "CityId"      : this.state.cityId,
                "City"        : this.state.cityName,
                "HouseNumber" : this.state.houseNumber,
                "StreetName"  : this.state.street,
                "Barangay"    : this.state.barangay,
                "PostalCode"  : this.state.postalCode,
                "isDeleted"     :   "0", 
            }],
            "educationAttainments"  : education,
            "employmentHistories"   : employmentRecord,
            "familyBackgrounds"     : familyBackground,
            "others"                : others,
            "characterReferences"   : reference,
        }
        ////console.log(params) 
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/AddApplicationForm",  params )
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
                this.GetApplicationInformation();
                this.GetApplicationFormDetails();

                this.state.applicationId   =   this.state.data.id
                this.state.clientName      =   this.state.data.client
                this.state.clientId        =   this.state.data.clientId
                this.state.position        =   this.state.data.position
                this.state.positionId      =   this.state.data.positionId
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true,
                    checkDisclaimer : false,
                    disablebutton : true,
                    disablebuttonsubmit : true,
                    navigate    :   true,
                });
            }
            else {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: data.message,
                    fade:true
                });
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    } 
    ValidNumeric(val)
    {
        var regex = /^\d+(.\d+)?$/;
        return !regex.test(val)
    }
    IsNumeric(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
        
    }
    IsDecimal(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    render() {
        const { navigate } = this.state;
        if (navigate) {
            return <Redirect to="/applicationform" push={true} />;
        } 

        const educationColumn = [
            {
                dataField: 'levelId',
                text: 'Level',
                headerStyle: () => {
                    return { width: "15%" };
                },
                formatter: (cell, row) => {
                    if(row.levelId!='' && row.levelId!=null){
                        if(this.state.tblLevelArrLst.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.tblLevelArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblLevelArrLst
                },
            },
            {
                dataField: 'schoolName',
                text: 'School Name',
                headerStyle: () => {
                    return { width: "30%" };
                  },
            },
            {
                dataField: 'course',
                text: 'Course',
                headerStyle: () => {
                    return { width: "20%" };
                  },
                  validator: (newValue, row, column) => {
                      if(row.schoolName==="")
                      return {
                          valid: false,
                          message:"Please enter school name."
                        };
                      return true;   
                  }
            },
            {
                dataField: 'startYear',
                text: 'Start Year',
                headerStyle: () => {
                    return { width: "10%" };
                },
                validator: (newValue, row, column) => {
                    this.setState({
                        totalValue : newValue
                    })
                    if(row.course==="")
                        return {
                            valid: false,
                            message:"Please enter course."
                    };
                    if (this.ValidNumeric(newValue)) {
                    return {
                        valid: false,
                        message:"Value must be numeric."
                    };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'endYear',
                text: 'End Year',
                headerStyle: () => {
                    return { width: "10%" };
                  },
                  validator: (newValue, row, column) => {
                      if(row.startYear==="")
                          return {
                          valid: false,
                          message:"Please enter start year."
                      };
                      if (this.ValidNumeric(newValue)) {
                        return {
                          valid: false,
                          message:"Value must be numeric."
                        };
                      } 
                      return true;   
                  }
            },
            {
                dataField: 'honorRecieved',
                text: 'Honor Recieved',
                headerStyle: () => {
                    return { width: "15%" };
                },
                validator: (newValue, row, column) => {
                    if(row.endYear==="")
                        return {
                        valid: false,
                        message:"Please enter start year."
                    };
                    return true;   
                }
            },
        ]

        const educationSelectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
            /* mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.educationGrid.map(function(item,i){
                    if(item.id===row.id)
                        item.isDeleted = isSelect ? "1" : "0"
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableRemoveEducation:isDisable})
            },
            onSelectAll: (isSelect, rows) => {
                let isDisable = true
                this.state.educationGrid.map(function(itm){
                    itm.isDeleted=isSelect ? "1" : "0"
                    if(itm.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableRemoveEducation:isDisable})
            } */
        };

        const employmentRecordColumn = [
            {
                dataField: 'companyName',
                text: 'Company_Name',
                headerStyle: () => {
                    return { width: "20%" };
                },
                validator: (newValue, row, column) => {
                    if(row.endYear==="")
                        return {
                        valid: false,
                        message:"Please enter company name."
                    };
                    return true;   
                }
            },
            {
                dataField: 'companyAddress',
                text: 'Company_Address',
                headerStyle: () => {
                    return { width: "20%" };
                },
                validator: (newValue, row, column) => {
                    if(row.endYear==="")
                        return {
                        valid: false,
                        message:"Please enter company address."
                    };
                    return true;   
                }
            },
            {
                dataField: 'position',
                text: 'Position',
                headerStyle: () => {
                    return { width: "20%" };
                },
                validator: (newValue, row, column) => {
                    if(row.endYear==="")
                        return {
                        valid: false,
                        message:"Please enter position."
                    };
                    return true;   
                }
            },
            {
                dataField: 'periodCovered',
                text: 'Period_Covered',
                headerStyle: () => {
                    return { width: "20%" };
                },
                validator: (newValue, row, column) => {
                    if(row.course==="")
                        return {
                            valid: false,
                            message:"Please enter period covered."
                    };
                    if (this.ValidNumeric(newValue)) {
                    return {
                        valid: false,
                        message:"Value must be numeric."
                    };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'salary',
                text: 'Salary',
                headerStyle: () => {
                    return { width: "20%" };
                },
                validator: (newValue, row, column) => {
                    if(row.course==="")
                        return {
                            valid: false,
                            message:"Please enter salary."
                    };
                    if (this.ValidNumeric(newValue)) {
                    return {
                        valid: false,
                        message:"Value must be numeric."
                    };
                    } 
                    return true;   
                },
                style:{textAlign:'right',whiteSpace:'nowrap'}
            },
        ]

        const employmentRecordSelectRow = {
            
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };

        

        const familyBackgroundColumn = [
            {
                dataField: 'roleId',
                text: 'Role',
                headerStyle: () => {
                    return { width: "15%" };
                },
                formatter: (cell, row) => {
                    if(row.roleId!='' && row.roleId!=null){
                        if(this.state.tblRoleArrLst.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.tblRoleArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblRoleArrLst
                },
                validator: (newValue, row, column) => {
                    if(this.state.familyBackgroundGrid.filter(x=>x.roleId===newValue).length>0 && newValue==="0")
                    return {
                        valid: false,
                        message:"Cannot set multiple for role father."
                      };
                    if(this.state.familyBackgroundGrid.filter(x=>x.roleId===newValue).length>0 && newValue==="1")
                    return {
                        valid: false,
                        message:"Cannot set multiple for role mother."
                    };
                    if(this.state.familyBackgroundGrid.filter(x=>x.roleId===newValue).length>0 && newValue==="2")
                    return {
                        valid: false,
                        message:"Cannot set multiple for role spouse."
                    };
                    return true;   
                }
            },
            {
                dataField: 'name',
                text: 'Name',
                headerStyle: () => {
                    return { width: "20%" };
                },
                validator: (newValue, row, column) => {
                    if (row.roleId==="") {
                      return {
                        valid: false,
                        message:"Please enter role."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'contactNumber',
                text: 'Contact Number',
                headerStyle: () => {
                    return { width: "15%" };
                },
                validator: (newValue, row, column) => {
                    if (row.name==="") {
                        return {
                            valid: false,
                            message:"Please enter contact number."
                        };
                    } 
                    if (this.ValidNumeric(newValue)) {
                        return {
                        valid: false,
                        message:"Value must be numeric."
                        };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'age',
                text: 'Age',
                headerStyle: () => {
                    return { width: "10%" };
                },
                validator: (newValue, row, column) => {
                    if (row.name==="") {
                        return {
                            valid: false,
                            message:"Please enter name."
                        };
                    } 
                    if (this.ValidNumeric(newValue)) {
                        return {
                        valid: false,
                        message:"Value must be numeric."
                        };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'occupation',
                text: 'Occupation',
                headerStyle: () => {
                    return { width: "20%" };
                },
                validator: (newValue, row, column) => {
                    if (row.age==="") {
                        return {
                          valid: false,
                          message:"Please enter occupation."
                        };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'company',
                text: 'Company',
                headerStyle: () => {
                    return { width: "20%" };
                },
                validator: (newValue, row, column) => {
                    if (row.occupation==="") {
                        return {
                          valid: false,
                          message:"Please enter company."
                        };
                    } 
                    return true;   
                }
            },
        ]

        const familyBackgroundSelectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
            /* mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.familyBackgroundGrid.map(function(item,i){
                    if(item.id===row.id)
                        item.isDeleted = isSelect ? "1" : "0"
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableBackground:isDisable})
            },
            onSelectAll: (isSelect, rows) => {
                let isDisable = true
                this.state.familyBackgroundGrid.map(function(itm){
                    itm.isDeleted=isSelect ? "1" : "0"
                    if(itm.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableBackground:isDisable})
            } */
        };

        const othersColumn = [
            {
                dataField: 'jobSourceId',
                text: 'Job Opening',
                headerStyle: () => {
                    return { width: "10%" };
                },
                formatter: (cell, row) => {
                    if(row.jobSourceId!='' && row.jobSourceId!=null){
                        return this.state.tblJobOpeningArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblJobOpeningArrLst
                }
                /* headerStyle: () => {
                    return { width: "10%" };
                },
                formatter: (cell, row) => {
                    if(row.roleId!='' && row.roleId!=null){
                        return this.state.tblRoleArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblRoleArrLst
                } */
            },
            {
                dataField: 'refferedBy',
                text: 'Preffered By',
                headerStyle: () => {
                    return { width: "15%" };
                    }
            },
            /* {
                dataField: 'presentlyConnected',
                text: 'Presently Connected',
                headerStyle: () => {
                    return { width: "5%" };
                    }
            }, */
            {
                dataField: 'relativeName',
                text: 'Name',
                headerStyle: () => {
                    return { width: "15%" };
                    }
            },
            {
                dataField: 'relativeRelation',
                text: 'Relationship',
                headerStyle: () => {
                    return { width: "10%" };
                    }
            },
            {
                dataField: 'relativePosition',
                text: 'Job Title',
                headerStyle: () => {
                    return { width: "10%" };
                    }
            },
            {
                dataField: 'contactNumber',
                text: 'Contact Number',
                headerStyle: () => {
                    return { width: "10%" };
                    }
            },
            /* {
                dataField: 'convictedWithACrime',
                text: 'Convicted With A Crime',
                headerStyle: () => {
                    return { width: "5%" };
                    }
            }, */
            {
                dataField: 'crimeDescription',
                text: 'Give Details Crime',
                headerStyle: () => {
                    return { width: "10%" };
                    }
            },
            /* {
                dataField: 'labourUnion',
                text: 'Member Of A Labour Union',
                headerStyle: () => {
                    return { width: "5%" };
                    }
            }, */
            {
                dataField: 'laborUnionDescription',
                text: 'Give Details Union',
                headerStyle: () => {
                    return { width: "10%" };
                    }
            },
            {
                dataField: 'diagnoseDeseaseId',
                text: 'Suffered or Diagnosed',
                headerStyle: () => {
                    return { width: "10%" };
                },
                formatter: (cell, row) => {
                    if(row.diagnoseDeseaseId!='' && row.diagnoseDeseaseId!=null){
                        return this.state.tblDiseaseArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblDiseaseArrLst
                }
            },
        ]

        const othersSelectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };

        const referenceColumn = [
            {
                dataField: 'name',
                text: 'Name',
                headerStyle: () => {
                    return { width: "25%" };
                },
                validator: (newValue, row, column) => {
                    if(row.endYear==="")
                        return {
                        valid: false,
                        message:"Please enter occupation."
                    };
                    return true;   
                }
            },
            {
                dataField: 'contactNumber',
                text: 'Contact Number',
                headerStyle: () => {
                    return { width: "20%" };
                },
                validator: (newValue, row, column) => {
                    if(row.startYear==="")
                        return {
                        valid: false,
                        message:"Please enter contact number."
                    };
                    if (this.ValidNumeric(newValue)) {
                      return {
                        valid: false,
                        message:"Value must be numeric."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'occupation',
                text: 'Occupation',
                headerStyle: () => {
                    return { width: "25%" };
                },
                validator: (newValue, row, column) => {
                    if(row.endYear==="")
                        return {
                        valid: false,
                        message:"Please enter occupation."
                    };
                    return true;   
                }
            },
            {
                dataField: 'company',
                text: 'Company',
                headerStyle: () => {
                    return { width: "30%" };
                },
                validator: (newValue, row, column) => {
                    if(row.endYear==="")
                        return {
                        valid: false,
                        message:"Please enter company."
                    };
                    return true;   
                }
            },
        ]

        const referenceSelectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };



        return(
            <div>
                <Banner />
                    <Container  className="mt-3" fluid>
                        <Card>
                            <Card.Header>RECRUITMENT >> APPLICATION FORM (CREATE) </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Row>
                                        {/* <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                            <Typeahead 
                                                ref='clientref'
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeClient}
                                                options={this.state.clientDDL}
                                                placeholder="Select Client" 
                                                defaultSelected={[this.state.clientName]}
                                                disabled
                                            />
                                        </Form.Group> */}
                                    </Form.Row>
                                    <Form.Row className="mt-2">
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <Form.Label style={{fontWeight : "bold"}}>POSITION APPLIED</Form.Label>
                                            <Typeahead
                                                ref='positionref'
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangePosition}
                                                options={this.state.positionDDL}
                                                placeholder="Select Position" 
                                                defaultSelected={[this.state.position]}
                                                disabled
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label style={{fontWeight : "bold", marginLeft : 15}}>DATE APPLIED</Form.Label>
                                            <Form.Group as={Col} controlId="formGridCity">
                                            <DatePicker
                                                ref='dateApplied'
                                                selected={this.state.dateApplied}
                                                onChange={this.handleChangeDateApplied}
                                                minDate={this.minDate}
                                                value={this.props.dateApplied}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                wrapperClassName="datepicker"
                                                placeholderText="Select Date Applied" 
                                                disabled
                                            />
                                            </Form.Group>
                                        </Form.Group>
                                    </Form.Row>
                                    <Tabs className="mt-2" defaultActiveKey="default" transition={false} id="noanim-tab-example">
                                        <Tab eventKey="default" title="I. GENERAL INFORMATION">
                                            <Card className="card-tab-no-border">
                                                <Card.Body>
                                                    <Form.Row>
                                                        <Form.Group as={Col} sm="2" controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>TITLE</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.handleChangeTitle}
                                                                value={this.state.titleValue}
                                                                disabled
                                                            >
                                                            {this.state.titleDDL.map(item => (
                                                                <option key={item.value} value={item.value}>
                                                                {item.name}
                                                                </option>
                                                            ))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>FIRST NAME</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="fName"
                                                                value={this.state.fName}
                                                                onChange={this.onChangeFirstName} 
                                                                autoComplete="off"
                                                                placeholder="Enter First name" 
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>NICK NAME</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="nName"
                                                                value={this.state.nName}
                                                                onChange={this.onChangeNickName} 
                                                                autoComplete="off"
                                                                placeholder="Enter Nick name" 
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>MIDDLE NAME</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="mName"
                                                                value={this.state.mName}
                                                                onChange={this.onChangeMiddleName} 
                                                                autoComplete="off"
                                                                placeholder="Enter Middle name" 
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>LAST NAME</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="lName"
                                                                value={this.state.lName}
                                                                onChange={this.onChangeLastName} 
                                                                autoComplete="off"
                                                                placeholder="Enter Last name" 
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>TYPE</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.handleChangeType}
                                                                value={this.state.typeId}
                                                                disabled
                                                            >
                                                            {this.state.typeDDL.map(item => (
                                                                <option key={item.value} value={item.value}>
                                                                {item.name}
                                                                </option>
                                                            ))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>REGION</Form.Label>
                                                                <Typeahead
                                                                    labelKey='name'
                                                                    name="regionId"
                                                                    id="regionId"
                                                                    onChange={this.handleChangeRegion}
                                                                    options={this.state.regionDDL}
                                                                    //defaultSelected={[this.state.region]}
                                                                    selected={[this.state.region]}
                                                                    placeholder="Select Region"
                                                                    disabled
                                                                />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>PROVINCE</Form.Label>
                                                                <Typeahead
                                                                    //ref='provinceref'
                                                                    labelKey='name'
                                                                    id="basic-example"
                                                                    onChange={this.handleChangeProvince}
                                                                    options={this.state.provinceDDL}
                                                                    placeholder="Select Province"
                                                                    //defaultSelected={[this.state.provinceName]}
                                                                    selected={[this.state.provinceName]}
                                                                    disabled
                                                                /> 
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>CITY/MUNICIPALITY</Form.Label>
                                                                <Typeahead    
                                                                    //ref='cityref'                              
                                                                    labelKey='name'
                                                                    id="basic-examplex"
                                                                    onChange={this.handleChangeCity}
                                                                    options={this.state.cityDDL}
                                                                    placeholder="Select City"
                                                                    //defaultSelected={[this.state.cityName]}
                                                                    selected={[this.state.cityName]}
                                                                    disabled
                                                                />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>HOUSE NUMBER</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="houseNumber"
                                                                value={this.state.houseNumber}
                                                                placeholder="Enter House Number"
                                                                onChange={this.onChangeHouseNumber} 
                                                                autoComplete="off"  
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>STREET</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="street"
                                                                value={this.state.street}
                                                                placeholder="Enter Street"
                                                                onChange={this.onChangeStreet} 
                                                                autoComplete="off"  
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>BARANGAY</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="barangay"
                                                                value={this.state.barangay}
                                                                placeholder="Enter barangay"
                                                                onChange={this.onChangeBarangay} 
                                                                autoComplete="off"  
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>POSTAL CODE</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="postalCode"
                                                                value={this.state.postalCode}
                                                                placeholder="Enter Postal Code"
                                                                onChange={this.onChangePostalCode} 
                                                                autoComplete="off"  
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>HOME PHONE NUMBER</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="hpNumber"
                                                                value={this.state.hpNumber}
                                                                placeholder="Enter Home Phone Number"
                                                                onChange={this.onChangeHPNumber} 
                                                                autoComplete="off"        
                                                                maxLength="8"  
                                                                disabled
                                                            />
                                                            {/* <NumberFormat
                                                                name="hpNumber"
                                                                value={this.state.hpNumber}
                                                                onChange={this.onChangeHPNumber} 
                                                                autoComplete="off"
                                                                format="####-####"
                                                                className="form-control"
                                                                placeholder="Enter Home Phone Number"
                                                            /> */}
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>OFFICE PHONE NUMBER</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="opNumber"
                                                                value={this.state.opNumber}
                                                                placeholder="Enter Office Phone Number"
                                                                onChange={this.onChangeOPNumber} 
                                                                autoComplete="off"        
                                                                maxLength="8"   
                                                                disabled
                                                            />
                                                            {/* <NumberFormat
                                                                name="opNumber"
                                                                value={this.state.opNumber}
                                                                onChange={this.onChangeOPNumber} 
                                                                autoComplete="off"
                                                                format="####-####"
                                                                className="form-control"
                                                                placeholder="Enter Office Phone Number"
                                                            /> */}
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>MOBILE NUMBER</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="mobileNumber"
                                                                value={this.state.mobileNumber}
                                                                placeholder="Enter Mobile Number"
                                                                onChange={this.onChangeMobileNumber} 
                                                                autoComplete="off"          
                                                                maxLength="11"
                                                                disabled
                                                            />
                                                            {/* <NumberFormat
                                                                name="mobileNumber"
                                                                value={this.state.mobileNumber}
                                                                onChange={this.onChangeMobileNumber} 
                                                                autoComplete="off"
                                                                format="####-####-###"
                                                                className="form-control"
                                                                placeholder="Enter Mobile Number"
                                                            /> */}
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formBasicEmail">
                                                            <Form.Label style={{fontWeight : "bold"}}>EMAIL ADDRESS</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="emailAddress"
                                                                value={this.state.emailAddress}
                                                                placeholder="Enter Email Address"
                                                                onChange={this.onChangeEmailAddress} 
                                                                autoComplete="off"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>FACEBOOK</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="facebook"
                                                                value={this.state.facebook}
                                                                placeholder="Enter Facebook"
                                                                onChange={this.onChangeFacebook} 
                                                                autoComplete="off"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>TWITTER</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="twitter"
                                                                value={this.state.twitter}
                                                                placeholder="Enter Twitter"
                                                                onChange={this.onChangeTwitter} 
                                                                autoComplete="off"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>CITIZENSHIP</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.handleChangeCitizenship}
                                                                value={this.state.nationalityId}
                                                                disabled
                                                            >
                                                            {this.state.nationalityDDL.map(item => (
                                                                <option key={item.value} value={item.value}>
                                                                {item.name}
                                                                </option>
                                                            ))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>RELIGION</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.handleChangeReligion}
                                                                value={this.state.religionId}
                                                                disabled
                                                            >
                                                            {this.state.religionDDL.map(item => (
                                                                <option key={item.value} value={item.value}>
                                                                {item.name}
                                                                </option>
                                                            ))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>GENDER</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.handleChangeGender}
                                                                value={this.state.genderValue}
                                                                disabled
                                                            >
                                                            {this.state.genderDDL.map(item => (
                                                                <option key={item.value} value={item.value}>
                                                                {item.name}
                                                                </option>
                                                            ))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>CIVIL STATUS</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.handleChangeCivilStatus}
                                                                value={this.state.civilStatusValue}
                                                                disabled
                                                            >
                                                            {this.state.civilStatusDDL.map(item => (
                                                                <option key={item.value} value={item.value}>
                                                                {item.name}
                                                                </option>
                                                            ))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold", marginLeft : 15}}>DATE OF BIRTH</Form.Label>
                                                            <Form.Group as={Col} controlId="formGridCity">
                                                                <DatePicker
                                                                    ref='dateOfBirth'
                                                                    selected={this.state.dateOfBirth}
                                                                    onChange={this.handleChangeDateOfBirth}
                                                                    minDate={this.minDate}
                                                                    value={this.props.dateOfBirth}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    placeholderText="Select Date Of Birth "
                                                                    className="form-control"
                                                                    wrapperClassName="datepicker"
                                                                    disabled
                                                                    //showMonthDropdown
                                                                    //showYearDropdown
                                                                    //dropdownMode= "scroll"
                                                                    
                                                                />
                                                            </Form.Group>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>AGE</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="totalAge"
                                                                value={this.state.totalAge}
                                                                onChange={this.onChangeAgeMonth.bind(this)}
                                                                autoComplete="off"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Form.Row> 
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>PLACE OF BIRTH</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="placeOfBirth"
                                                                value={this.state.placeOfBirth}
                                                                placeholder="Enter Place Of Birth"
                                                                onChange={this.onChangePlaceOfBirth} 
                                                                autoComplete="off"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>HEIGHT</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="height"
                                                                value={this.state.height}
                                                                placeholder="Enter Height"
                                                                onChange={this.onChangeHeight} 
                                                                autoComplete="off"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>WEIGHT</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="weight"
                                                                value={this.state.weight}
                                                                placeholder="Enter Weight"
                                                                onChange={this.onChangeWeight} 
                                                                autoComplete="off"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>SPECIAL SKILLS</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="sSkills"
                                                                value={this.state.sSkills}
                                                                placeholder="Enter Special Skills"
                                                                onChange={this.onChangeSkills} 
                                                                autoComplete="off"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>LANGUAGE SPOKEN</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="lSpoken"
                                                                value={this.state.lSpoken}
                                                                placeholder="Enter Language Spoken"
                                                                onChange={this.onChangeLSpoken} 
                                                                autoComplete="off"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>EMERGENCY CONTACT NAME</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="ecName"
                                                                value={this.state.ecName}
                                                                placeholder="Enter Emergency Contact Name"
                                                                onChange={this.onChangeECName} 
                                                                autoComplete="off"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>RELATIONSHIP</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="relationship"
                                                                value={this.state.relationship}
                                                                placeholder="Enter Relationship"
                                                                onChange={this.onChangeRelationship} 
                                                                autoComplete="off"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>EMERGENCY CONTACT NUMBER</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="ecNumber"
                                                                value={this.state.ecNumber}
                                                                placeholder="Enter Emergency Contact Number"
                                                                onChange={this.onChangeECNumber} 
                                                                autoComplete="off"         
                                                                maxLength="11" 
                                                                disabled
                                                            />
                                                            {/* <NumberFormat
                                                                name="ecNumber"
                                                                value={this.state.ecNumber}
                                                                onChange={this.onChangeECNumber} 
                                                                autoComplete="off"
                                                                format="####-####-###"
                                                                className="form-control"
                                                                placeholder="Enter Emergency Contact Number"
                                                            /> */}
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>SSS #</Form.Label>
                                                                <NumberFormat
                                                                    name="sss"
                                                                    value={this.state.sss}
                                                                    onChange={this.onChangeSSS} 
                                                                    autoComplete="off"
                                                                    format="##-#######-#"
                                                                    className="form-control"
                                                                    placeholder="Enter SSS Number"
                                                                    disabled
                                                                />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>TIN #</Form.Label>
                                                            <NumberFormat
                                                                name="tin"
                                                                value={this.state.tin}
                                                                onChange={this.onChangeTin} 
                                                                autoComplete="off"
                                                                format="###-###-###-###"
                                                                className="form-control"
                                                                placeholder="Enter TIN Number"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>PHILHEALTH #</Form.Label>
                                                            <NumberFormat
                                                                name="phic"
                                                                value={this.state.phic}
                                                                onChange={this.onChangePhilHealth} 
                                                                autoComplete="off"
                                                                format="##-#########-#"
                                                                className="form-control"
                                                                placeholder="Enter PHILHEALTH Number"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>PAGIBIG #</Form.Label>
                                                            <NumberFormat
                                                                name="pagibig"
                                                                value={this.state.pagibig}
                                                                onChange={this.onChangePagibig} 
                                                                autoComplete="off"
                                                                format="####-####-####"
                                                                className="form-control"
                                                                placeholder="Enter PAGIBIG Number"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Card.Body>
                                            </Card>
                                        </Tab>
                                        
                                        <Tab eventKey="education" title="II. EDUCATION">
                                            <Card className="card-tab-no-border">
                                                <Card.Body>
                                                    <div className="mt-1">  
                                                        <div>
                                                            <Card.Header>EDUCATION LIST</Card.Header>
                                                            <BootstrapTable
                                                                keyField = "id"
                                                                data = { this.state.educationGrid }
                                                                columns = { educationColumn }
                                                                //pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                rowClasses="noser-table-row-class no-checkbox"
                                                                striped
                                                                hover
                                                                condensed
                                                                /* cellEdit = { cellEditFactory({
                                                                    mode: 'dbclick',
                                                                    blurToSave: true,
                                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                        this.EducationGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                        }
                                                                    })
                                                                } */
                                                                selectRow = { educationSelectRow }
                                        
                                                            />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                                
                                        </Tab>

                                        <Tab eventKey="employment" title=" III. EMPLOYMENT RECORD (START WITH LATEST EMPLOYER)">
                                            <Card className="card-tab-no-border">
                                                <Card.Body>
                                                    <div className="mt-1">  
                                                        <div>
                                                            <Card.Header>EMPLOYMENT RECORDS LIST</Card.Header>
                                                            <BootstrapTable
                                                                keyField = "id"
                                                                data = { this.state.employmentRecordGrid }
                                                                columns = { employmentRecordColumn }
                                                                //pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                rowClasses="noser-table-row-class no-checkbox"
                                                                striped
                                                                hover
                                                                condensed
                                                                /* cellEdit = { cellEditFactory({
                                                                    mode: 'dbclick',
                                                                    blurToSave: true,
                                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                        this.EmploymentGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                        }
                                                                    })
                                                                } */
                                                                selectRow = { employmentRecordSelectRow }
                                        
                                                            />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                                
                                        </Tab>


                                        <Tab eventKey="familybackground" title="IV. FAMILY BACKGROUND">
                                            <Card className="card-tab-no-border">
                                                <Card.Body>
                                                    <div className="mt-1">  
                                                        <div>
                                                            <Card.Header>Family Background List</Card.Header>
                                                            <BootstrapTable
                                                                keyField = "id"
                                                                data = { this.state.familyBackgroundGrid }
                                                                columns = { familyBackgroundColumn }
                                                                //pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                rowClasses="noser-table-row-class no-checkbox"
                                                                striped
                                                                hover
                                                                condensed
                                                                /* cellEdit = { cellEditFactory({
                                                                    mode: 'dbclick',
                                                                    blurToSave: true,
                                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                        this.FamilyBackgroundGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                        }
                                                                    })
                                                                } */
                                                                selectRow = { familyBackgroundSelectRow }
                                        
                                                            />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>   
                                        </Tab>
                                                                
                                        <Tab eventKey="others" title="V. OTHERS">
                                            <Card className="card-tab-no-border">
                                                <Card.Body>
                                                    <div className="mt-1">  
                                                        <div>
                                                            <Card.Header>Others List</Card.Header>
                                                            <BootstrapTable
                                                                keyField = "id"
                                                                data = { this.state.othersGrid }
                                                                columns = { othersColumn }
                                                                //pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                rowClasses="noser-table-row-class no-checkbox"
                                                                striped
                                                                hover
                                                                condensed
                                                                /* cellEdit = { cellEditFactory({
                                                                    mode: 'dbclick',
                                                                    blurToSave: true,
                                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                        this.OtherGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                        }
                                                                    })
                                                                } */
                                                                selectRow = { othersSelectRow }
                                        
                                                            />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                                
                                        </Tab>
                                        
                                        <Tab eventKey="reference" title="VI. REFERENCES (NOT RELATED TO YOUR FAMILY)">
                                            <Card className="card-tab-no-border">
                                                <Card.Body>
                                                    <div className="mt-1">  
                                                        <div>
                                                            <Card.Header>Reference List</Card.Header>
                                                            <BootstrapTable
                                                                keyField = "id"
                                                                data = { this.state.referenceGrid }
                                                                columns = { referenceColumn }
                                                                //pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                rowClasses="noser-table-row-class no-checkbox"
                                                                striped
                                                                hover
                                                                condensed
                                                                /* cellEdit = { cellEditFactory({
                                                                    mode: 'dbclick',
                                                                    blurToSave: true,
                                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                        this.ReferenceGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                        }
                                                                    })
                                                                } */
                                                                selectRow = { referenceSelectRow }
                                        
                                                            />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                                
                                        </Tab>

                                    </Tabs>
                                    <ButtonToolbar className="mt-3">
                                        <Button  href="/pendingjoboffer" className="ml-auto" variant="danger">BACK</Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                        <div style={{height : 15}}></div>
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div>
        )
    }

}

export  default ViewApplication;
