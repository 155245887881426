import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker,  Tabs, Tab
} 
from '../../../noser-hris-component';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import NoserGrid from './BIRPayrollReportTable'

class BIRPayrollReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            clientId        :   "",
            startDate       :   "",
            endDate         :   "",
            clientDDL       :   [],
            payrollGrid     :   []
        }

    }
    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients();

    }
    GetClients = async()=> {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
       }
       axios
           .post(
               AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", clientParams
           )
           .then(res => {
               const data = res.data;
               this.setState({clientDDL:data.clients,isloading:false})
           })
   }

   handleClientChanged = (e) => {
    this.setState({alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.setState({isshow:false,clientId:""})
            return
        }
        this.state.clientId=e[0].id
    }
    
    handleSearchClick = async()=>{
        this.setState({isloading:true,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(this.state.clientId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   true
            })
            return
        }
        if(this.state.endDate===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select end date.",
                fade        :   true
            })
            return
        }
        if(this.state.startDate===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select start date.",
                fade        :   true
            })
            return
        }
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "StartDate"     :   moment(this.state.startDate).format('MM/DD/YYYY'),
            "EndDate"       :   moment(this.state.endDate).format('MM/DD/YYYY'),
            
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Report/GetPayrollSummaryReport", params)
        .then(res => {
            const data = res.data
            this.setState({
                payrollGrid:data.payrolls,isloading:false})
                if(data.status=="0"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    })
                }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleStartDateChanged = date => {
        this.setState({startDate:date})
    };
    handleEndDateChanged = date => {
        this.setState({endDate:date})
    };
    render() {
        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>Payroll Summary Report (BIR)</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                    </Form.Label>
                                    <Col sm={11}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleClientChanged}
                                            options={this.state.clientDDL}
                                            placeholder="Select Client"
                                        />
                                    </Col>
                                </Form.Group> */}
                                <Form.Row>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        START DATE
                                    </Form.Label>
                                    <Form.Group as={Col} sm={2} controlId="formGridEmail">
                                        <DatePicker
                                            ref='startDate'
                                            selected={this.state.startDate}
                                            onChange={this.handleStartDateChanged}
                                            minDate={this.minDate}
                                            value={this.props.startDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />
                                    </Form.Group>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        END DATE
                                    </Form.Label>
                                    <Form.Group as={Col} sm={2} controlId="formGridPassword">
                                        <DatePicker
                                                ref='startDate'
                                                selected={this.state.endDate}
                                                onChange={this.handleEndDateChanged}
                                                minDate={this.minDate}
                                                value={this.props.endDate}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                            />
                                    </Form.Group>
                                </Form.Row>
                                <ButtonToolbar sm={12}>
                                    <Button variant="success" className="ml-auto noser-button" onClick = { this.handleSearchClick }>SEARCH</Button>
                                </ButtonToolbar>
                                <NoserGrid
                                    data = { this.state.payrollGrid }
                                    exportCSV={true}
                                    pageSize={150}
                                />
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div>
        )
    }


}

export default BIRPayrollReport;