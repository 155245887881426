import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker,
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, FormControl} from 'react-bootstrap';
import { BsQuestionSquare } from 'react-icons/bs';
import { Input } from 'reactstrap';
 
class jobApplicationStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
          
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalCoCReferenceShow               :   false,

            category                :"",

            isLoading               :"",

           
            selectedEmployeeId      :[],
            selectedEmployeeNo      :[],
            selectedEmployeeBranch  :[],
            selectedPosition        :[],

            getClientList           :[],
            getEmployeeList         :[],
            getEmployeeNoList       :[],
            position                :[],
            location                :[],

            //addParagraph:[],
            values                  :[],
            
            dateApplied             :"",
            selectedClientName      :"",
            selectedEmployeeName    :[],
            selectedEmployeeNo      :[],            

            dateCaseFiled           :"",
            caseFiled               :"",
            venue                   :"",
            SchedOfHearing          :"",          
            prosecutor              :"",
            legal                   :"",
            latestUpdate            :"",
            updateDate              :"",
            remarks                 :"",
            status                  :"",
            AttachedDoc             :"",
            particularList: [
                {
                    "id" : "Pending",
                    "name" : "Pending"
                },
                {
                    "id" : "Closed",
                    "name" : "Closed"
                }
            ],
 	        selectedparticular      :   '',
            selectedparticularId    :   '',
            counsel:"",

            fullName:"",
            appliedPosition:"",
            applicationStatusId:"",
            dateApplied:"",
            talentGRID:[],
            venue:"",
            dateSched:"",
            retrunLogin:""

        }
        /* this.handleSubmit = this.handleSubmit.bind(this); */
    }

    componentDidMount(){
        this.setState({
            isloading:true
        })
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        //this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.NOSERUSRlogInApplicant = JSON.parse(sessionStorage.getItem("NOSERUSRlogInApplicant"))

        this.state.fullName = this.state.NOSERUSRlogInApplicant.applicantName
        this.state.appliedPosition = this.state.NOSERUSRlogInApplicant.positionApplied
        this.state.dateApplied = this.state.NOSERUSRlogInApplicant.dateApplied

        if(this.state.NOSERUSRlogInApplicant.companyId == "1"){
            this.state.retrunLogin = "/SignUpLogIn?w=1"
        }
        if(this.state.NOSERUSRlogInApplicant.companyId == "2"){
            this.state.retrunLogin = "/SignUpLogIn?w=2"
        }
        if(this.state.NOSERUSRlogInApplicant.companyId == "3"){
            this.state.retrunLogin = "/SignUpLogIn?w=3"
        }

        this.setState({isLoading:true,loadingText:"Loading client list..."})

        //this.getClientList();;

        
        this.GetApplicationList();
       
       
    }

    GetApplicationList = async()=> {

        const params = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.NOSERUSRlogInApplicant.companyId,
            "UserId":this.state.NOSERUSRlogInApplicant.userId,
            "ProfileId":"",
            "DateApplied":this.state.NOSERUSRlogInApplicant.dateApplied,
            "CivilStatus":"",
            "PositionId":"",
            "ReferenceNo" : "",
            "StatusId" : "",
            //"PoolingStatusId" : this.state.key,
        };
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Pooling/GetApplicationPoolings",  params)
            .then(res => {
            const data = res.data;
            console.log("GetApplicationList")
            console.log(data)

            
            this.setState({
                
                //filterDDL:data.applicationPools.filter(x=>x.poolingStatusId==="1"),
                //poolGRID:data.applicationPools.filter(x=>x.poolingStatusId==="1"),
                talentGRID:data.applicationPools.filter(x=>x.poolingStatusId==="2"),
                //candidateGRID:data.applicationPools.filter(x=>x.poolingStatusId==="3"),
                
                
            });

            
            if(this.state.talentGRID.length > 0){
                this.setVenue()
                
            }else{
                this.state.applicationStatusId = this.state.NOSERUSRlogInApplicant.applicationStatusId
                this.setState({
                    isloading:false                    
                });
            }

            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    setVenue=()=>{
        this.state.venue=this.state.talentGRID[0].timeVenue
        this.state.dateSched=this.state.talentGRID[0].scheduleDate
        console.log("this.state.talentGRID.length")
        console.log(this.state.talentGRID.length)
        this.setState({
            applicationStatusId:"0",
            isloading:false,
           
        })
    }

    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }

    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        console.log("Client selectedClientId " + this.state.selectedClientId );
        console.log("Client selectedClientName " + this.state.selectedClientName );
        //this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        
        this.getEmployees();
    }

    getEmployees(){
        this.setState({isloading:true})

        const getParams = {

            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""

        }

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data
            if(data.status=="1"){
                console.log("tur==")
                this.setState({getEmployeeList : data.profiles, getEmployeeNoList : data.profiles,isloading:false,})
            }else{
                console.log("else==")
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
            }
                
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
        
    }

    onChangeEmployeesList = (e) => {       

        if(e.length==0)
        {
            console.log("123123")
            this.setState({getEmployeeList: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        console.log("45678")
        this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.selectedPosition = e[0].position
       
        console.log("Work selectedEmployeeName   " + this.state.selectedEmployeeNo );
        console.log("Employee List start ");
        console.log(this.state.selectedEmployeeBranch);
        console.log("Employee List end");

      /*   if(this.state.selectedPayrollpayPeriod == ""){
            this.state.selectedPayrollPeriodsId = e[0].payPeriodId
            this.state.selectedPayrollpayPeriod = e[0].payPeriod
        } */

        //this.employeeTransactionsList();
       
        this.setState({isloading:false})
    }
    
    onChangeEmployeesNo(e){
        this.setState({selectedEmployeeNo : e.target.value})
        //console.log(e.target.value)
    }
   
    handleChangeDate = date => {
        ////console.log(date)
        this.setState({
            dateApplied: date,
            isshow:false,
        })
    }

    /* handleAddParagraph(){
        return this.state.addParagraph.map((el,i)=>
            <div className="mt-2" key={i}>

                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                    <Col>
                        <FormControl as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" />
                    </Col>
                </Form.Group>                                   
       
            </div>
        )
    } */

    handleSaveClick=()=>{
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');
        let data = {
            "client"                :   this.state.selectedClientName,
            "employeeName"          :   this.state.selectedEmployeeName,
            "position"              :   this.state.selectedPosition,
            "employeeNo"            :   this.state.selectedEmployeeNo,
            "location"              :   this.state.selectedEmployeeBranch,
            "dateCaseFiled"         :   this.state.dateCaseFiled,
            "caseFiled"             :   this.state.caseFiled,
            "venue"                 :   this.state.venue,
            "SchedOfHearing"        :   this.state.SchedOfHearing,
            "prosecutor"            :   this.state.prosecutor,
            "legal"                 :   this.state.legal,
            "latestUpdate"          :   this.state.latestUpdate,
            "updateDate"            :   this.state.updateDate,
            "remarks"               :   this.state.remarks,
            "status"                :   this.state.selectedparticular, 
            "file"                  :   this.state.AttachedDoc,
            "counsel"               :   this.state.counsel,
            "isDraft"               :   "0",
            "isDeleted"             :   "0",
            "createdby"             :   "user007",
            "createddate"           :   createddate,
            "modifiedby"            :   'user007',
            "modifieddate"          :   '0',
            "isModified"            :   '0',    
        }
        console.log(data)
        
        const addParams = {
            "_collection" : "TestCriminalCases",
            "doc_data"    : data
        }
          
        axios
        .post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
          
            this.setState({
                selectedClientName      :"",
                selectedEmployeeName    :"",
                selectedPosition        :"",
                selectedEmployeeNo      :"",
                selectedEmployeeBranch  :"",
                dateCaseFiled           :"",
                caseFiled               :"",
                venue                   :"",
                SchedOfHearing          :"",
                prosecutor              :"",
                legal                   :"",
                latestUpdate            :"",
                updateDate              :"",
                remarks                 :"",
                status                  :"", 
                AttachedDoc             :"", 
                counsel                 :"",       
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Data had successfully Save" ,
                fade            :   true
               
            })
          
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleChangedateCaseFiled=(e)=>{
        this.setState({
            dateCaseFiled   : e.target.value
        })
        //console.log(this.state.paragraph)
    }
    handleAddedcaseFiled=(e)=>{
        this.setState({
            caseFiled:e.target.value
        })
    }
    handleChangevenue=(e)=>{
        this.setState({
            venue   : e.target.value
        })
        //console.log(this.state.addedparagraph1)
    }
    handleAddedSchedOfHearing=(e)=>{
        this.setState({
            SchedOfHearing:e.target.value
        })
    }
    handleChangeprosecutor=(e)=>{
        this.setState({
            prosecutor   : e.target.value
        })
        //console.log(this.state.addedparagraph2)
    }
    handlelegal=(e)=>{
        this.setState({
            legal : e.target.value
        })
    }
    handleChangelatestUpdate=(e)=>{
        this.setState({
            latestUpdate   : e.target.value
        })
        //console.log(this.state.addedparagraph3)
    }
 
    handleChangeupdateDate=(e)=>{
        this.setState({
            updateDate   : e.target.value
        })
        //console.log(this.state.quotedStatement)
    }
    handleOnChangeremarks=(e)=>{
        this.setState({
            remarks   : e.target.value
        })
        //console.log(this.state.preparedBy)
    }
    handleOnChangestatus=(e)=>{
        this.setState({
            status   : e.target.value
        })
        //console.log(this.state.notedBy)
    }
    handleOnChangeAttachedDoc=(e)=>{
        let fileTmp = e.target.value
        this.setState({
            AttachedDoc   : fileTmp
        })
        console.log(this.state.AttachedDoc)
    }
    handleOnChangeCounsel=(e)=>{
        this.setState({
            counsel   : e.target.value
        })
        console.log(this.state.counsel)
    }
    
    onChangeparticular = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedparticular     :   '',
                selectedparticularId   :   ''
            })
            return
        }
        this.state.selectedparticular = e[0].name
        this.state.selectedparticularId = e[0].id
       
        this.setState({
            isshow   :   false,
            selectedparticular : e[0].id
        })
        console.log(this.state.selectedparticular)
      
    }

    handleUpdateBtn=()=>{

        window.location.reload(true);

    }


    render() {        
    return(
            <div>
               {/*  <Banner /> */}
                    <Container className="mt-5" fluid>
                    <div  style={{border:"solid" , backgroundColor:"#ffff" , borderWidth:1,/* borderTopRightRadius:4,borderTopLeftRadius:4, */ borderRadius:5}}>
                    <header title="Folder" style={{backgroundColor:"rgb(8, 139, 30)", width:"flex", border:"solid", borderWidth:1, height:"30px",}}>
                        <label style={{fontSize:"17px", fontWeight : "bold", marginLeft:"5px"}}> Noser Technology PH - HRIS </label>
                    </header>
                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                        <div className={this.state.color}></div>
                        {this.state.message}
                    </Alert>
                    <Container as={Row} mb="2" style={{marginTop:"15px",}}>
                

                       {/*  <Col sm="5" mt="2" style={{}}>
                            
                            <Card style={{background : "#ffff",borderTopRightRadius:4,borderTopLeftRadius:4,width:"550px"}}>
                                
                                <Card.Body >
                                    <Form>
                                    
                                        <Form.Group className="mt-4" as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                Company/Details
                                            </Form.Label>
                                            
                                        </Form.Group>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col> */}
                        <Col sm="7" style={{marginLeft:"185px"}}>
                            <Card style={{background : "#ffff",borderTopRightRadius:4,borderTopLeftRadius:4, width:"1100px"}}>
                                {/* <Card.Header style={{background : "#3fbd9a", fontWeight : "bold"}}> wjkehqwkjdgjqwgdjhqgwdjhg </Card.Header> */}
                                <Card.Body>
                                    <Form  style={{marginLeft:"10px"}}>
                                    
                                    <Form.Group /* className="mt-4" */ as={Row} controlId="formPlaintextEmail" style={{marginTop : -10}}>
                                        <Form.Label style={{fontWeight : "bold", fontSize:"20px"}}>
                                        Job Application Status
                                        </Form.Label>
                                    </Form.Group>
                                    <Form.Group /* className="mt-4" */ as={Row} controlId="formPlaintextEmail" style={{marginTop : -5}}>
                                        <Form.Label style={{fontWeight : "bold", /* fontSize:"20px" */}}>
                                        Please click UPDATE button below to see your Job Application current status
                                        </Form.Label>
                                    </Form.Group>
                                    <header title="Folder" style={{backgroundColor:"#3fbd9a", width:"flex", border:"solid", borderWidth:1, height:"4px", marginLeft:-14}}></header>
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginTop : 10}}>
                                        <Form.Label style={{fontWeight : "bold",}}>
                                        FULL NAME : 
                                        </Form.Label>
                                        <Col sm="3">
                                        <Form.Control 
                                            type="text"
                                            name="fullName" 
                                            value={this.state.fullName}
                                            //onChange={this.onChangeFolderName} 
                                            //disabled={this.state.disableEmployee}
                                            placeholder={"Auto Fill"}
                                            style={{borderWidth:0, marginTop:"-10px", height:"29px", width:"450px"}}
                                        />
                                        </Col>
                                    </Form.Group>
                                    <header title="Folder" style={{backgroundColor:"#3fbd9a", width:"flex", border:"solid", borderWidth:1, height:"2px", marginLeft:-14, marginTop:"-10px"}}></header>
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginTop : 10}}>
                                        <Form.Label style={{fontWeight : "bold",}}>
                                        POSITION APPLIED : 
                                        </Form.Label>
                                        <Col sm="3">
                                        <Form.Control 
                                            type="text"
                                            name="FolderName" 
                                            value={this.state.appliedPosition}
                                            //onChange={this.onChangeFolderName} 
                                            //disabled={this.state.disableEmployee}
                                            placeholder={"Auto Fill"}
                                            style={{borderWidth:0, marginTop:"-10px", height:"29px", width:"450px"}}
                                        />
                                        </Col>
                                    </Form.Group>
                                    <header title="Folder" style={{backgroundColor:"#3fbd9a", width:"flex", border:"solid", borderWidth:1, height:"2px", marginLeft:-14, marginTop:"-10px"}}></header>
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginTop : 10}}>
                                        <Form.Label style={{fontWeight : "bold",}}>
                                        DATE APPLIED : 
                                        </Form.Label>
                                        <Col sm="3">
                                        <Form.Control 
                                            type="text"
                                            name="FolderName" 
                                            value={this.state.dateApplied}
                                            //onChange={this.onChangeFolderName} 
                                            //disabled={this.state.disableEmployee}
                                            //placeholder={"Auto Fill"}
                                            style={{borderWidth:0, marginTop:"-10px", height:"29px", width:"450px"}}
                                        />
                                        </Col>
                                    </Form.Group>
                                    <header title="Folder" style={{backgroundColor:"#3fbd9a", width:"flex", border:"solid", borderWidth:1, height:"2px", marginLeft:-14, marginTop:"-10px"}}></header>

                                   {/*  <Form.Group as={Row} controlId="formPlaintextEmail" style={{ marginTop:"10px",}}>
                                        <Col sm="5">
                                            
                                        </Col>
                                        <Col sm="4">
                                        </Col>
                                        <Col sm="3">
                                            <ButtonToolbar>
                                            <Button  style={{minWidth:'60px', marginLeft:"180px"}} variant="success" onClick={this.handleUpdateBtn}>UPDATE</Button>
                                            &nbsp;&nbsp;
                                            </ButtonToolbar>
                                        </Col>
                                    </Form.Group> */}
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginTop : 10 , marginLeft: "20px"}}>
                                        <Form.Label style={{fontWeight : "bold",}}>
                                        Hi {this.state.fullName}.,
                                        </Form.Label>
                                    
                                    </Form.Group>

                                    {this.state.applicationStatusId =="2" ?
                                        <Form.Group  as={Row} controlId="formPlaintextEmail" style={{marginTop : 10 , marginLeft: "20px"}}>
                                            <Form.Label className="no-text-transform-li" >
                                                Your application is <l style={{fontWeight : "bold",}}>FOR REVIEW</l> you will be notified, or you can update here for the next status. THANK YOU.
                                            </Form.Label>
                                    
                                        </Form.Group>:null
                                    }

                                    {this.state.talentGRID.length > 0 ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginTop : 10 , marginLeft: "20px"}}>

                                            <Form.Label className="no-text-transform-li">
                                                Your application is for <l style={{fontWeight : "bold",}}>PRELIMINARY INTERVIEW</l> , you are scheduled to be
                                                interviewed on <l style={{fontWeight : "bold",}}>{this.state.dateSched}</l> <l style={{fontWeight : "bold",}}>{this.state.venue}</l>. 
                                               
                                            </Form.Label>
                                    
                                        </Form.Group>:null
                                    }

                                    {this.state.applicationStatusId =="3" ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginTop : 10 , marginLeft: "20px"}}>

                                            <Form.Label className="no-text-transform-li">
                                                Your application is for <l style={{fontWeight : "bold",}}>PRELILMINARY INTERVIEW</l> you will be notified, or you can update here for the next status.
                                               
                                            </Form.Label>
                                    
                                        </Form.Group>:null
                                    }

                                    {this.state.applicationStatusId =="6" ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginTop : 10 , marginLeft: "20px",/*  width:"100px" */}}>
                                            <Form.Label className="no-text-transform-li" style={{whiteSpace: "pre-wrap",}}>
                                                
                                                Your application is for <l style={{fontWeight : "bold",}}>BACKGROUND CHECK</l> you will be notified, or you can update here for the next status.

                                            </Form.Label>
                                    
                                        </Form.Group>:null
                                    }

                                    {this.state.applicationStatusId =="7" ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginTop : 10 , marginLeft: "20px",/*  width:"100px" */}}>
                                            <Form.Label className="no-text-transform-li" style={{whiteSpace: "pre-wrap",}}>
                                                Your application is for <l style={{fontWeight : "bold",}}>FINAL INTERVIEW</l> you will be notified, or you can update here for the next status.
                                                
                                            </Form.Label>
                                    
                                        </Form.Group>:null
                                    }

                                    {this.state.applicationStatusId =="13" ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginTop : 10 , marginLeft: "20px",/*  width:"100px" */}}>
                                            <Form.Label className="no-text-transform-li" style={{whiteSpace: "pre-wrap",}}>
                                            Your application is for <l style={{fontWeight : "bold",}}>DEPARTMENT ENDORSEMENT</l> you will be notified, or you can update here for the next status.
                                            </Form.Label>
                                    
                                        </Form.Group>:null
                                    }

                                    {this.state.applicationStatusId =="19" ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginTop : 10 , marginLeft: "20px",/*  width:"100px" */}}>
                                            <Form.Label className="no-text-transform-li" style={{whiteSpace: "pre-wrap",}}>
                                            Your application is for <l style={{fontWeight : "bold",}}>DEPARTMENT ENDORSEMENT APPROVAL</l> you will be notified, or you can update here for the next status.
                                            </Form.Label>
                                    
                                        </Form.Group>:null
                                    }

                                    {this.state.applicationStatusId =="8" ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginTop : 10 , marginLeft: "20px",/*  width:"100px" */}}>
                                            <Form.Label className="no-text-transform-li" style={{whiteSpace: "pre-wrap",}}>
                                            Your application is in <l style={{fontWeight : "bold",}}>JOB OFFER</l> you will be notified, or you can update here for the next status.
                                            
                                            </Form.Label>
                                    
                                        </Form.Group>:null
                                    }

                                    {this.state.applicationStatusId =="17" ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginTop : 10 , marginLeft: "20px"}}>
                                            <Form.Label className="no-text-transform-li" style={{whiteSpace: "pre-wrap",}}>
                                            Your application is in <l style={{fontWeight : "bold",}}>JOB OFFER APPROVAL</l> you will be notified, or you can update here for the next status.
                                            
                                            </Form.Label>
                                    
                                        </Form.Group>:null
                                    }

                                    {this.state.applicationStatusId =="18" ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginTop : 10 , marginLeft: "20px",/*  width:"100px" */}}>
                                            <Form.Label className="no-text-transform-li" style={{whiteSpace: "pre-wrap",}}>
                                            Your application is for further process please contact or visit <l style={{fontWeight : "bold",}}>HR Department</l> for further update.
                                          
                                            
                                            </Form.Label>
                                       
                                    
                                        </Form.Group>:null
                                        
                                    }
                                    {this.state.applicationStatusId =="18" ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginTop : 10 , marginLeft: "20px",/*  width:"100px" */}}>
                                           
                                            <Form.Label className="no-text-transform-li" style={{whiteSpace: "pre-wrap",}}>
                                            Thank you,
                                            </Form.Label>
                                           
                                    
                                        </Form.Group>:null
                                        
                                    }
                                    {this.state.applicationStatusId =="18" ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginTop : 10 , marginLeft: "20px",/*  width:"100px" */}}>
                                           
                                          
                                            <Form.Label className="no-text-transform-li" style={{whiteSpace: "pre-wrap",}}>
                                            HR Department
                                            </Form.Label>
                                    
                                        </Form.Group>:null
                                        
                                    }

                                    </Form>
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{ marginTop:"10px",}}>
                                        <Col sm="4" style={{marginLeft:"30px"}}> </Col>
                                        { /* this.state.applicationStatusId == "3" ?
                                            <Col sm="8" style={{marginLeft:"30px"}}>
                                                <NavLink to="/PreliminaryInterviewCreate"> Preliminary test </NavLink>,
                                            </Col>:<Col sm="4"> </Col> */
                                        }
                                        {/*  this.state.applicationStatusId == "6" ?
                                            <Col sm="8" style={{marginLeft:"30px"}}>
                                                <NavLink to="/BackgroundInvestigation"> background check </NavLink>,
                                            </Col>:null */
                                        }
                                        {/*  this.state.applicationStatusId == "7" ?
                                            <Col sm="8" style={{marginLeft:"30px"}}>
                                                <NavLink to="/wcreate"> final TEST </NavLink>,
                                            </Col>:null */
                                        }
                                     
                                        <Col sm="2" className='ml-auto' >
                                            <ButtonToolbar >
                                                {/* <Button style={{minWidth:'60px', }} variant="success" onClick={this.handleUpdateBtn}>UPDATE</Button>&nbsp;&nbsp; */}
                                                <Button  href={this.state.retrunLogin}className="noser-button" variant="danger" style={{minWidth:'60px', marginLeft:"90px"}}>Back</Button>
                                                {/*  <Button  style={{minWidth:'60px', backgroundColor: "rgb(8, 139, 30)", color: "#000000", border: "1px solid #000000",marginLeft:"82px"}}  >Close</Button> */}
                                           
                                                {/* &nbsp;&nbsp; */}
                                            </ButtonToolbar>
                                        </Col>
                                    </Form.Group>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Container>
                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginTop : 10 , marginLeft: "20px"}}>
                    <Col sm="4"></Col>
                    <Col sm="5"></Col>
                    <Form.Label style={{fontWeight : "bold", marginLeft:"70px"}}>
                    Noser HRIS © 2019 - 2020. All Rights Reserved
                    </Form.Label>
                    
                    </Form.Group>
                
                </div>
                       
                </Container>
                <NoserLoading show={this.state.isloading} />

               
            </div> 
        )
    }

}

export  default jobApplicationStatus
