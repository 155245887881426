import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

class CreatePayrollPerTrip extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            dataGrid : [],
            rateName : "",
            categoryDDL : [
                {
                    "id" : "1", "name" : "DRIVER"
                },
                {
                    "id" : "2", "name" : "HELPER"
                }
            ],
            categoryId      : "",
            category      :   "",
            locationDDL     : [],
            locationId      : "",
            productDDL     : [],
            productId      :   "",
            basicRate : "",
            allowanceRate : "",
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.DataOnLoad();
        this.GetLocation();
        this.GetProduct();
    }
    componentDidMount(){
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            dataGrid : [],
            rateName : "",
            categoryDDL : [
                {
                    "id" : "1", "name" : "DRIVER"
                },
                {
                    "id" : "2", "name" : "HELPER"
                }
            ],
            categoryId      :   "",
            category      :   "",
            locationDDL     : [],
            locationId      :   "",
            productDDL     : [],
            productId      :   "",
            basicRate : "",
            allowanceRate : "",
        })
        this.DataOnLoad();
        this.GetLocation();
        this.GetProduct();
    }
    DataOnLoad = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress" :   "0.0.0.0",
            "UserId"    :   this.state.userinfo.userId,
            "CompanyId":this.state.userinfo.companyId,
            "Id"        :   "",
            "CategoryId":"" ,
            "LocationId":"" ,
            "ProductId":"" ,
            "BasicRate":"" ,
            "AllowanceRate":"" 
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollPerTrip",  param)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({dataGrid:data.payrollPerTrip,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangeRateName = (e) => {
        this.setState({isshow:false})
        this.setState({ rateName: e.target.value.toUpperCase() });
    };
    handleChangedCategory = (e) => {
        this.setState({isshow:false})
        if(e.length == 0) {
            this.state.categoryId=""
            this.state.category=""
            return
        } 
        this.state.categoryId = e[0].id
        this.state.category = e[0].name
        
    }
    GetLocation = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress" :   "0.0.0.0",
            "UserId"    :   this.state.userinfo.userId,
            "CompanyId":this.state.userinfo.companyId,
            "Id"        :   ""
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollLocation",  param)
        .then(res => {
            const data = res.data.payrollLocation;
            console.log("GetPayrollLocation")
            console.log(data)
            this.setState({locationDDL:data,isloading:false});
            if(data.status==="0"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangedLocation = (e) => {
        this.setState({isshow:false})
        if(e.length == 0) {
            this.state.locationId=""
            return
        } 
        this.state.locationId = e[0].id
        
    }
    GetProduct = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress" :   "0.0.0.0",
            "UserId"    :   this.state.userinfo.userId,
            "CompanyId":this.state.userinfo.companyId,
            "Id"        :   ""
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollProduct",  param)
        .then(res => {
            const data = res.data.payrollProduct;
            console.log("GetPayrollProduct")
            console.log(data)
            this.setState({productDDL:data,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangedProduct = (e) => {
        this.setState({isshow:false})
        if(e.length == 0) {
            this.state.productId=""
            return
        } 
        this.state.productId = e[0].id
        
    }

    handleChangeBasicRate = (e) => {
        this.setState({isshow:false})
        const basicRate = e.target.value;
    
        if (!basicRate || basicRate.match(/^\d{1,}(\.\d{0,4})?$/)) {
          this.setState(() => ({ basicRate }));
        }
    };

    handleChangeAllowanceRate = (e) => {
        this.setState({isshow:false})
        const allowanceRate = e.target.value;
    
        if (!allowanceRate || allowanceRate.match(/^\d{1,}(\.\d{0,4})?$/)) {
          this.setState(() => ({ allowanceRate }));
        }
    };

    handleSubmit = (e) =>{
        this.setState({isloading:true})
                
        if(this.state.dataGrid.some(item =>item.rateName === this.state.rateName && item.category === this.state.category && item.locationId === this.state.locationId && item.productId === this.state.productId && item.basicRate === this.state.basicRate && item.allowanceRate === this.state.allowanceRate ) === true ){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Input data already exist!",
                fade            :   true
            })
            return  
        }

        if(this.state.rateName==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter rate name",
                fade        :   true
            })
            return 
        }

        if(this.state.category==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter category",
                fade        :   true
            })
            return 
        }

        if(this.state.locationId==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter location",
                fade        :   true
            })
            return 
        }

        if(this.state.productId==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter product",
                fade        :   true
            })
            return 
        }

        if(this.state.basicRate==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter basic rate",
                fade        :   true
            })
            return 
        }

        if(this.state.allowanceRate==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter allowance rate",
                fade        :   true
            })
            return 
        }

        const param = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.userinfo.companyId,
            "UserId":this.state.userinfo.userId,
            "RateName" : this.state.rateName,
            "CategoryId" : this.state.categoryId,
            "Category" : this.state.category,
            "LocationId" : this.state.locationId,
            "ProductId" : this.state.productId,
            "BasicRate" : this.state.basicRate,
            "AllowanceRate" : this.state.allowanceRate
        }
        console.log("param")
        console.log(param)
        
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/AddPayrollPerTrip",  param)
        .then(res => {
            const data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            });
            this.DataOnLoad();
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }

    
    handleModalClose = () => {
        this.props.onHide();            
    }
    handleKeyUp = (e) =>{

    }
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        CREATE PAYROLL PER TRIP
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                Rate Name
                            </Form.Label>
                            <Col>
                                <Form.Control 
                                    name='rateName'
                                    type="text" 
                                    onChange={this.handleChangeRateName} 
                                    autoComplete="off" 
                                    value={this.state.rateName}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                CATTEGORY
                            </Form.Label>
                            <Col>
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangedCategory}
                                    options={this.state.categoryDDL}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                Location
                            </Form.Label>
                            <Col>
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangedLocation}
                                    options={this.state.locationDDL}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                PRODUCT
                            </Form.Label>
                            <Col>
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangedProduct}
                                    options={this.state.productDDL}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                BASIC RATE
                            </Form.Label>
                            <Col>
                                <Form.Control 
                                    name='basicRate'
                                    type="text" 
                                    onChange={this.handleChangeBasicRate} 
                                    autoComplete="off" 
                                    value={this.state.basicRate}
                                    //onKeyPress={this.IsNumeric.bind(this)}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                ALLOWANCE RATE
                            </Form.Label>
                            <Col>
                                <Form.Control 
                                    name='allowanceRate'
                                    type="text" 
                                    onChange={this.handleChangeAllowanceRate} 
                                    autoComplete="off" 
                                    value={this.state.allowanceRate}
                                    //onKeyPress={this.IsNumeric.bind(this)}
                                />
                            </Col>
                        </Form.Group>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleSubmit }  style={{marginRight:"5px"}}>Save</Button>
                        <Button variant="danger" className="noser-button-mr1" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default CreatePayrollPerTrip