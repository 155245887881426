import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

class ManageProfiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            selected: [],
            statusList:[],
            employeeProfileList:[],
            employeeProfileListGrid:[],
            selectedSortBy:"",
            selectedSortById:"",
            selectedEmployeeName:"",
            selectedEmployeeId:"",
            selectedProfileId:"",
            selectedClientName:"",
            selectedClientId:"",
            clientList:[],

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        //this.GetSubmittedEmployeeProfiles();
        this. getStatus();
        this. getClient();
        
        sleep(1000).then(() => {
        this.setState({isLoading:false})})
        }

    handleCoverChangeSortBy = (e) => {
        if (e.length > 0) {
            this.state.selectedSortBy = e[0].name
            this.state.selectedSortById = e[0].id

        } else {
            this.state.selectedSortBy = ""
            this.state.selectedSortById = ""
        }
        
        //console.log(this.state.selectedSortBy);
        
    } 

    handleChangeClient= (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        
        this.GetEmployeeList()
    }

    getClient = async () => {
        this.setState({isloading:true,})
        //console.log("getClient");
        const clientParams = {
            "IpAddress":"0.0.0.0",
           "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId
        }

        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", clientParams)
        .then(res => {
            const data = res.data;
            //console.log("Get 5");
            //console.log(res.data); 
            this.setState({clientList : data.clients,isloading:false,})
        })
    } 
    
    GetEmployeeList = async () => {
        this.setState({isloading:true,})
        const employeeParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "ProfileId":"",
            "StatusId":""
        }
        console.log("employeeParams");
        console.log(employeeParams)
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetSubmittedEmployeeProfiles",  employeeParams)
            .then(res => {
            const data = res.data;
            console.log("Test app2");
            console.log(data);
            if(data.status=="0"){
                this.setState({employeeDDL : [],isloading:false}) 
            }
            else
            this.setState({employeeDDL : data.employeeProfiles,isloading:false}) 
           
        })

    }
    
    handleChangeEmployee= (e) => {
        //console.log(e)
            if (e.length == 0) {
                this.setState({selectedEmployeeName: null, selectedEmployeeNameId:"", selectedProfileId:''})
            return
        }
         this.state.selectedEmployeeName = e[0].name
        this.state.selectedEmployeeNameId = e[0].id
        this.state.selectedProfileId =e[0].profileId

        //this.GetSubmittedEmployeeProfiles();
        this.setState({isloading:false,})
    }

    getStatus = async () => {
        const statusParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Code":"0021"
        };
        

        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences", statusParams)
            .then(res => {
                const data = res.data;
                this.setState({
                    statusList : res.data.dataReferences})
                    
                })
                
            }

    handleSearchClick = event => {
        this.GetSubmittedEmployeeProfiles()
    }

    GetSubmittedEmployeeProfiles = async () => {
        this.setState({isloading:true,})
        const employeeParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "ProfileId":this.state.selectedProfileId,
            "StatusId":this.state.selectedSortById
        };
        console.log("Test 1");
        console.log(employeeParams)
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetSubmittedEmployeeProfiles",  employeeParams)
            .then(res => {
            const data = res.data;
            console.log("Test app2");
            console.log(data);
            this.setState({ employeeProfileListGrid: data.employeeProfiles,isloading:false});
           
        })

    }

    // handleSearchClick = event => {
    //     this.setState({employeeProfileListGrid:[]})

    //     const searchParams = {
    //         "IpAddress":"0.0.0.0",
    //         "ClientId":this.state.selectedClientId,
    //         "UserId":this.state.userinfo.userId,
    //         "RoleId":this.state.userinfo.roleId,
    //         "ProfileId":this.state.selectedProfileId,
    //         "StatusId":this.state.selectedSortById
    //     };
    //     //console.log(searchParams)
    //     axios
    //         .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetSubmittedEmployeeProfiles",  searchParams)
    //         .then(res => {
    //         const data = res.data;
    //         //console.log(res.data)
    //         this.setState({ employeeProfileListGrid: data.employeeProfiles});
    //         // this.processGrid();
    //         if(data.status=="1"){
    //             this.setState({
    //                 isloading       :   false,
    //                 alerttype       :   "Success!",
    //                 //isshow          :   true,
    //                 color           :   "success",
    //                 message         :   data.message,
    //                 fade            :   true
    //             });
    //         }
    //         else{
    //             this.setState({
    //                 isloading       :   false,
    //                 alerttype       :   "Error!",
    //                 isshow          :   true,
    //                 color           :   "danger",
    //                 message         :   data.message,
    //                 fade            :   true
    //             })
    //         }
    //         })
    //         .catch(error=>{
    //             this.setState(  {
    //                 isloading       :   false,
    //                 alerttype       :   "Error!",
    //                 isshow          :   true,
    //                 color           :   "danger",
    //                 message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
    //                 fade            :   true
    //             })
    //         })
    // }

    render() {
        const columns1 = [
            {
                dataField: 'employeeName',
                text: 'FULL NAME',
                editable : false,
                headerStyle : () => {
                    return { width  : "40%" };
                }
            },
            {
                dataField: 'status',
                text: 'APPROVAL STATUS',
                editable : false,
                headerStyle: () => {
                    return { width: "30%" };
                },
            },
            {
                dataField: 'createdDate',
                text: 'SUBMITTED DATE',
                editable : false,
                headerStyle: () => {
                    return { width: "30%" };
                },
            },
            ] 
       
       
        // const selectRow = {
        //     mode: 'checkbox',
        //     clickToSelectAndEditCell: true,
        //     onSelect: (row, isSelect, rowIndex, e) => {
        //         this.state.employeeProfileListGrid.map(function(item,i){
        //             if(item.id===row.id)
        //             {
        //                 item.isDeleted = isSelect ? "1" : "0"
        //                 item.isModified = isSelect ? "1" : "0"
        //             }
        //         })
        //      }
        // };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              //alert(e.row);
            }
        };

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.employeeProfileListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>MANNING >> MANAGE PROFILE</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeClient}
                                                options={this.state.clientList}
                                            />  
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleChangeEmployee}
                                                options={this.state.employeeDDL}
                                            />   
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        SORT BY
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleCoverChangeSortBy}
                                                options={this.state.statusList}
                                            /> 
                                        </Col>
                                    </Form.Group>
                                    
                                    <ButtonToolbar sm={12} className="mt-3">
                                        <Button variant="success" className="ml-auto noser-button-mr1" onClick={this.handleSearchClick}>
                                            Search
                                        </Button>
                                        <NavLink to="/ManageProfilesCreate">
                                            <Button className="noser-button" variant="primary" >
                                                Create
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                    <Card.Header className="mt-3">record list</Card.Header>
                                    <div className="mt-1">
                                        <BootstrapTable
                                            /* caption={Noser.TableHeader({title:"RECORD"})} */
                                            keyField = "id"
                                            data = { this.state.employeeProfileListGrid }
                                            columns = { columns1 }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            rowClasses="noser-table-row-class no-checkbox"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                            selectRow = { selectRow }

                                        />
                                    </div>
                                    <ButtonToolbar sm={12} className="mt-3">
                                        <NavLink className="ml-auto " to="/home">
                                            <Button className="noser-button" variant="danger"  href="/banner">
                                                Close
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                        
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div> 
            
        )
    }
}

export default ManageProfiles;