
import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

import PieceRateModal from './PieceRateModal'

class PieceRate extends Component {

    constructor() {
        super()
        this.state = {
            clientId : '',
            clientName : '',
            locationId : '',
            locationName:'',
            periodId:'',
            periodName:'',
            openModal:false,
            closeModal:false,
            disable:true,

            clientDDL : [],
            periodDDL:[],
            billableHours:[],
            
        }

        
    }

    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.handleSearch(e)
    }
    handleChangeClient = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length==0)
        {
            this.setState({clientId:"",clientName:""})
            return
        }
        this.state.clientId= e[0].id
        this.state.clientName= e[0].name
        this.GetPayrollPeriods()
    }
    handleChangePeriod = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length == 0) {
            this.setState({periodId:"",periodName:""})
            return
        }
        this.state.periodId = e[0].periodId
        this.state.periodName =e[0].payPeriod
    }
    GetClients = async() => {
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            this.setState({isloading:false,clientDDL:res.data.clients.filter(x=>x.payrollTypeId==="3") ? res.data.clients : []});
                
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetPayrollPeriods = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   "0",
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   "",
            "IsProcessed"   :   "0"
         };
        await axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods",  param
            )
            .then(res => {
                const data = res.data;
                console.log(data)
                this.setState({periodDDL:data.payrollPeriods,isloading:false
                })
             })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }
    handleDelete = (e) => {
        this.setState({isloading:true})

        // let newBillableHrs = []
        // for (let i = 0; i < this.state.billableHours.length; i++) {
        //     if (this.state.billableHours[i]["isDeleted"] == 1) {
        //         let obj = this.state.billableHours[i]
        //         newBillableHrs.push(obj);
        //     } 
        // }

        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId": this.state.userinfo.clientId,
            "billableHours": this.state.billableHours.filter(x=>x.isDeleted==="1")
        }
        console.log(params)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/EditUploadPieceRateBillableHours", params)
        .then(res => {
            const data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   (data.status=="0") ? "Error!" : "Success!",
                isshow      :   true,
                color       :   (data.status=="0") ? "danger" : "success",
                message     :   data.message,
                fade        :   true
            });
            if(data.status=="1"){
                this.handleSearch(e)
            }
        })
        
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    handleSearch =(e) => {
        this.setState({isloading:true,alerttype:"",isshow:false,color:"",message:"",fade:false})

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientId": this.state.clientId,
            "PeriodId": this.state.periodId
    
        }
        console.log(params)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetUploadPieceRateBillableHours", params)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({billableHours : res.data.billableHours ? res.data.billableHours : [], isloading:false})
            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    handleDownload = async(e) => {
        this.setState({isloading:true})

        if(this.state.clientId=="")
        {
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select client",
                fade            :   true
            })
            return
        }
        if(this.state.periodId=="")
        {
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select payroll period / cutoff",
                fade            :   true
            })
            return
        }

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientId": this.state.clientId,
            "PayPeriodId": this.state.periodId
    
        }
        console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/DownloadPieceRateBillableTemplate", params,{'responseType': 'blob'})
        .then(res => {
            const data = res.data;
            console.log(res)
            //let filename = ""// res.headers["content-disposition"].split(';')[1].trim().split('=')[1].replace(/"/g, '')
            let dated = moment(new Date).format('YYYY/MM/DD')
            let filename = "PieceRate1BillableHours_Template_" + this.state.clientId + "_" + dated + ".csv"
            if(filename=='' || filename==null)
            {
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while downloading template.",
                    fade            :   true
                })
            }
            else
            {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                this.setState({isloading:false})
            }
        })
        
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    render() {
        const columns = [
            /* {
                dataField   :   'clientName',
                text        :   'CLIENT',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'left',width  : "25%",fontSize:'10px' };
                  },
                style:{textAlign:'left',fontSize:'10px'}
            }, */
            // {
            //     dataField   :   'locationName',
            //     text        :   'BRANCH',
            //     editable    :    false,
            //     headerStyle : () => {
            //         return { width  : "20%",fontSize:'10px' };
            //       },
            //       style:{fontSize:'10px'}
            // },
            {
                dataField   :   'periodName',
                text        :   'PERIOD',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "15%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'totalTxn',
                text        :   'TOTAL COUNT',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "15%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'txnDate',
                text        :   'DATE UPLOAD',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "15%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'fileName',
                text        :   'FILE NAME',
                editable    :    false,
                headerStyle : () => {
                    return { width  : "55%",fontSize:'10px' };
                  },
                  style:{fontSize:'10px'}
            }
        ] 
        const columnDetails = [
            {
                dataField   :   'employeeName',
                text        :   'EMPLOYEE NAME',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'left',width  : "10%",fontSize:'10px' };
                  },
                style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'}
            },
            {
                dataField   :   'positionName',
                text        :   'POSITION NAME',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'left',width  : "10%",fontSize:'10px' };
                  },
                style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'}
            },
            {
                dataField   :   'basicAmount',
                text        :   'DTR DATE',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'left',width  : "10%",fontSize:'10px' };
                  },
                style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'}
            },
            {
                dataField   :   'remarks',
                text        :   'Remarks',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'left',width  : "20%",fontSize:'10px' };
                  },
                  style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'}
            },
            
        ]
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable=true
                this.state.billableHours.map(function(item,i){
                    if(item.id==row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if (item.isDeleted=="1")
                    isDisable =  false
                    //console.log(item.isDeleted)
                })
                this.setState({disable:isDisable})
            }
        };
        const expandRow = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "id"
                    data = { row.billableDetails }
                    columns = { columnDetails }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    expandRow
                    wrapperClasses="table-responsive"
                    rowClasses="noser-table-row-class"
                    noDataIndication={ () => <div>No details.</div> }
                /> 
                
            ),
            showExpandColumn: true,
          };
            return(
                <div>
                    <Banner />
                    <Container className="themed-container" fluid={true}>
                        <Card className="mt-5">
                            <Card.Header>Timekeeping >> Billable Hours (PIECE RATE) 123</Card.Header>
                            <Card.Body>
                                <Form >
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeClient}
                                                options={this.state.clientDDL}
                                                placeholder="Select Client"
                                                //defaultSelected={[this.state.clientName]}
                                            />
                                        </Col>
                                    </Form.Group> */}
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <Typeahead
                                                labelKey='payPeriod'
                                                id="basic-example"
                                                onChange={this.handleChangePeriod}
                                                options={this.state.periodDDL}
                                                placeholder="Select Payroll Period"

                                            />
                                        </Col>
                                    </Form.Group>
                                    <div className="mt-1">
                                        <ButtonToolbar>
                                            <Button style={{minWidth:'60px'}} className="ml-auto" variant="primary" variant="success" onClick={this.handleSearch}>Search</Button>
                                        </ButtonToolbar>
                                    </div>

                                    <div className="mt-2">
                                        <hr />
                                        <ButtonToolbar>
                                            <Button style={{minWidth:'60px',marginBottom:'2px'}} variant="primary" variant="success" onClick={this.handleDownload}>Download Template</Button>
                                        </ButtonToolbar>
                                        <Card.Header>BILLABLE HOURS (PIECE RATE)</Card.Header>
                                        <BootstrapTable
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            pagination={ paginationFactory({sizePerPage:20,hideSizePerPage:true,hidePageListOnlyOnePage:true})}
                                            keyField = "id"
                                            data = { this.state.billableHours }
                                            columns = { columns}
                                            selectRow = { selectRow }
                                            cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                            expandRow={ expandRow }
                                            noDataIndication={ () => <div>No record found.</div> }
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <ButtonToolbar>
                                            <Button style={{minWidth:'60px',marginRight:'1pt'}} className="ml-auto" variant="primary" variant="success" onClick={this.handleModalShow}>Upload</Button>
                                            <Button style={{minWidth:'60px'}} variant="primary" variant="danger" onClick={this.handleDelete} disabled={this.state.disable}>Delete</Button>
                                        </ButtonToolbar>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                    <NoserLoading show={this.state.isloading} />
                    <PieceRateModal 
                        show={this.state.openModal}
                        onHide={this.handleModalClose}
                    />
                </div>
        )
    }
}

export  default PieceRate