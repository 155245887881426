import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Redirect
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import { ja } from 'date-fns/locale';

class ClientEndorsementApproval extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            navigate    : false,

            clientAutocomplete	    :   [],
            memberNameAutocomplete	:   [],
            addressAutocomplete	    :   [],
            positionAutocomplete	:   [],
            dateEndorsed	        :   new Date(),
            attention	            :   "",
            staffingAutocomplete	:   [],
            responseAutocomplete	:   [],
            remarks	                :   "",
            date                    :   "",
            companyId               :   "",
            approvalDDL: [
                {id : "1", name: 'Approve',               value: 'Approve',},
                {id : "2", name: 'Reject',               value: 'Reject',},
            ],
            titleValue              :   "",
            remarks                 :   "",
            /* selectedClient	        :   "",
            selectedFullName	    :   "",
            selectedPositionApplied	:   "",
            currentAddress		    :   "", */
        }

    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.data = JSON.parse(sessionStorage.getItem("application_"  + this.state.userinfo.userId + this.state.userinfo.employeeId))
        this.state.registration = JSON.parse(sessionStorage.getItem("NOSERUSRlogInApplicant"))
        this.setState({
            employeeName : this.state.data.applicantName,
            refNo : this.state.data.referenceNo,
            positionName : this.state.data.position,
            address : this.state.data.address,
        })
        this.getClientList();
        this.GetPositionName();
        this.getResponse();
        this.getStaffingSpecialist()
    }


    onChangeClient(e){
        this.setState({selectedClient : e.target.value})
        ////console.log(e.target.value)

        
       /*  this.getApplicationForm(); */
    }

    getClientList() {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"      :   this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId,
        }
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", clientParams
            )
            .then(res => {
                const data = res.data;
                this.setState({
                clientAutocomplete  :   data.clients, 
                    isloading        :   false
                })
            })
    }

    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.companyId     =   ""
            return
        }
        this.state.companyId     =   e[0].id
        this.state.selectedClientName   =   e[0].name
        this.getStaffingSpecialist()
        this.getApplicationForm();
        this.setState({
            isshow:false,
        })
 
    }

    getApplicationForm() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "CompanyId"      :   this.state.companyId,
            "ProfileId"     :   "",
            "PositionId"    :   "",
            "StatusId"      :   "",
            "TINNumber"     :   "",
        }

        ////console.log("Start Get Params")
        ////console.log(getParams)
        ////console.log("End Get Params")

        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationForms", getParams
            )
            .then(res => {
                const data = res.data;
                ////console.log("Start Get Application")
                ////console.log(data)
                ////console.log("End Get Application")
                sleep(5000).then(() => {
                    this.setState({
                        memberNameAutocomplete  :   data.applicationForms,
                        isloading	            :   false
                    })
                })
                let addressData = []
                for(let i = 0; i < data.applicationForms.length; i++) {
                    
                    for(let j = 0; j < data.applicationForms[i].applicantAddresses.length; j++){
                        let obj = {
                            'city' :   data.applicationForms[i].applicantAddresses[j]['houseNumber'].concat(', ', data.applicationForms[i].applicantAddresses[j]['barangay'], ', ', data.applicationForms[i].applicantAddresses[j]['city'], ', ', data.applicationForms[i].applicantAddresses[j]['province']),
                            
                          }
                          addressData.push(obj)
                    }
                    this.setState({
                        addressAutocomplete : addressData
                    })
                    ////console.log("Start Get Address")
                    ////console.log(this.state.addressAutocomplete)
                }
            })
    }

    onChangeMemberNameList = (e) => {
        if(e.length == 0) {
            this.state.selectedFullNameId	=   ""
            return
        }
        this.state.selectedFullNameId	    =   e[0].id
        this.setState({
            isshow:false,
        })
 
    }

    onChangeCurrentAddress = (e) => {
        if(e.length == 0) {
            this.state.selectedCity	=   ""
            return
        }
        this.state.selectedCity	    =   e[0].city
        this.setState({
            isshow:false,
        })
 
    }

    GetPositionName() {
        const departmentCodeParams = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"      :   this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  departmentCodeParams)
        .then(res => {
            const data = res.data;
            ////console.log("Get Position Name");
            ////console.log(data);
            this.setState({ positionAutocomplete  : data.positions });
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    onChangePositionList = (e) => {

        if(e.length == 0) {
            this.state.selectedPositionId	=   ""
            return
        }
        this.state.selectedPositionId	    =   e[0].id
        this.setState({
            isshow:false,
        })
    }

    getStaffingSpecialist(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "CompanyId":this.state.companyId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetCoorEmployees", getParams)
        .then(res => {
            const data = res.data
            ////console.log("Coordinator List Autocomplete");
            ////console.log(data);
            if(data.status=="1")
                this.setState({staffingAutocomplete : data.employees,isloading:false}) 
            else
                this.setState({staffingAutocomplete : [],isloading:false}) 
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }

    onChangeStaffingList = (e) => {

        if(e.length == 0) {
            this.state.selectedStaffingId	=   ""
            return
        }
        this.state.selectedStaffingId	    =   e[0].id
    }

    getResponse() {
        const departmentCodeParams = {
            "IpAddress"     :   "0.0.0.0",
            "CompanyId"      :   this.state.userinfo.companyId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "0010"
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  departmentCodeParams)
        .then(res => {
            const data = res.data;
            ////console.log("Get Response");
            ////console.log(data);
            this.setState({ responseAutocomplete  : data.dataReferences });
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    onChangeResponseList = (e) => {

        if(e.length == 0) {
            this.state.selectedResponseId	=   ""
            return
        }
        this.state.selectedResponseId	    =   e[0].id
        this.setState({
            isshow:false,
        })
    }

    handleChangeDateEndorsed = date => {
        this.setState({
            dateEndorsed: date,
            isshow:false,
        });
        ////////console.log(this.state.EffectivityMonth)
    };

    onChangeAttention = (e) => {
        this.setState({
            attention	:  e.target.value ,
            isshow:false,
        })
    }

    // onChangeRemarks = (e) => {
    //     this.setState({
    //         remarks	:  e.target.value ,
    //         isshow:false,
    //     })
    // }

    handleSaveClick = (event) => {

        // if(!this.state.companyId){
        //     this.setState({0
        //         isloading:false,
        //         alerttype:"Error!",
        //         isshow:true,
        //         color:"danger",
        //         message: "Please select client",
        //         fade:true
        //     });
        //     return
        // }

        // if(!this.state.selectedFullNameId){
        //     this.setState({
        //         isloading:false,
        //         alerttype:"Error!",
        //         isshow:true,
        //         color:"danger",
        //         message: "Please select full name",
        //         fade:true
        //     });
        //     return
        // }

        // if(!this.state.selectedPositionId){
        //     this.setState({
        //         isloading:false,
        //         alerttype:"Error!",
        //         isshow:true,
        //         color:"danger",
        //         message: "Please select position",
        //         fade:true
        //     });
        //     return
        // }

        if(this.state.dateEndorsed == ""){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "Please enter date endorsed",
                fade:true
            });
            return
        }

        // if(this.state.attention == ""){
        //     this.setState({
        //         isloading:false,
        //         alerttype:"Error!",
        //         isshow:true,
        //         color:"danger",
        //         message: "Please enter attention",
        //         fade:true
        //     });
        //     return
        // }

        // if(!this.state.selectedResponseId){
        //     this.setState({
        //         isloading:false,
        //         alerttype:"Error!",
        //         isshow:true,
        //         color:"danger",
        //         message: "Please select response",
        //         fade:true
        //     });
        //     return
        // }

        if(this.state.remarks == ""){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "Please enter remarks",
                fade:true
            });
            return
        }
        let dateEndorsed = moment(this.state.dateEndorsed).format('MM/DD/YYYY');
        this.setState({isloading:true})

        const addParams = {
            "IpAddress"         :   "0.0.0.0",
            "CompanyId"          :   this.state.userinfo.companyId,
            "UserId"            :   this.state.userinfo.userId,
            "ApplicationFormId" :   this.state.data.id,
            "ResponseId"        :   this.state.selectedResponseId,
            "Remarks"           :   this.state.remarks
        };

        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Recruitment/AddEndorsementApproval",  addParams
            )
            .then(res => {
                const data = res.data;
                ////console.log("Save Results")
                ////console.log(data)
                if(data.status=="1"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true,
                        navigate    : true,
                    });
                    //window.location = "http://167.172.68.202:8080/pooling"
                    //window.location = "http://localhost:3000/pooling"
                
                }
                else {
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
            })

    }
    handleSubmitClick = event => {

        // if(!this.state.companyId){
        //     this.setState({
        //         isloading:false,
        //         alerttype:"Error!",
        //         isshow:true,
        //         color:"danger",
        //         message: "Please select client",
        //         fade:true
        //     });
        //     return
        // }

        // if(!this.state.selectedFullNameId){
        //     this.setState({
        //         isloading:false,
        //         alerttype:"Error!",
        //         isshow:true,
        //         color:"danger",
        //         message: "Please select full name",
        //         fade:true
        //     });
        //     return
        // }

        // if(!this.state.selectedPositionId){
        //     this.setState({
        //         isloading:false,
        //         alerttype:"Error!",
        //         isshow:true,
        //         color:"danger",
        //         message: "Please select position",
        //         fade:true
        //     });
        //     return
        // }

        // if(!this.state.selectedCity){
        //     this.setState({
        //         isloading:false,
        //         alerttype:"Error!",
        //         isshow:true,
        //         color:"danger",
        //         message: "Please select current address",
        //         fade:true
        //     });
        //     return
        // }

        if(this.state.dateEndorsed == ""){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "Please enter date",
                fade:true
            });
            return
        }

        // if(this.state.attention == ""){
        //     this.setState({
        //         isloading:false,
        //         alerttype:"Error!",
        //         isshow:true,
        //         color:"danger",
        //         message: "Please enter attention",
        //         fade:true
        //     });
        //     return
        // }

        if(!this.state.selectedResponseId){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "Please select response",
                fade:true
            });
            return
        }

        if(this.state.remarks == ""){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "Please enter remarks",
                fade:true
            });
            return
        }

        let dateEndorsed = moment(this.state.dateEndorsed).format('MM/DD/YYYY');
        this.setState({isloading:true})

        const submitParams = {
            "IpAddress"         :   "0.0.0.0",
            "CompanyId"          :   this.state.companyId,
            "UserId"            :   this.state.userinfo.userId,
            "ApplicationFormId" :   this.state.data.id,
            "PositionId"        :   this.state.positionName,
            "ResponseId"        :   this.state.selectedResponseId,
            "DateEndorsed"      :   dateEndorsed,
            "Remarks"           :   this.state.remarks,
         };

        console.log("Submit Params")
        console.log(submitParams)

         axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Recruitment/AddEndorsementApproval",  submitParams
            )
            .then(res => {
                const data = res.data;
                ////console.log("Submit Results")
                ////console.log(data)
                if(data.status=="1"){
                this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true,
                        navigate    :   true,
                    });
                
                }
                else {
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
            })

    }

    /* ROMEL START CODES */

    handleChangeDate = date => {
        this.setState({
            date: date,
            isshow:false,
        })
    }

    handleChangeTitle = (e) => {
        this.setState({ titleValue: e.target.value,isshow:false, });
    };

    onChangeRemarks = (e) => {
        this.setState({
            remarks	:  e.target.value ,
            isshow:false,
        })
    }

    onChangeApproval = (e) =>{
        if (e.length === 0){
            this.state.selectedResponseId = ""
        }else {
            this.state.selectedResponseId = e[0].id
        }
    }

    /* ROMEL END CODEs */

    render() {
        const { navigate } = this.state;
        if (navigate) {
            return <Redirect to="/pooling" push={true} />;
        }
        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>RECRUITMENT &#62;&#62; DEPARTMENT / SECTION (APPLICANT APPROVAL)</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        APPLICANT
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                name="employeeName"
                                                value={this.state.employeeName}
                                                disabled={true}
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        REFERENCE NUMBER
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                name="refNo"
                                                value={this.state.refNo}
                                                disabled={true}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        APPLIED POSITION
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                name="positionName"
                                                value={this.state.positionName}
                                                disabled={true}
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        ADDRESS
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                name="address"
                                                value={this.state.address}
                                                disabled={true}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formGridAddress1">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        DATE
                                        </Form.Label>
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <DatePicker
                                                ref='date'
                                                selected={this.state.date}
                                                onChange={this.handleChangeDate}
                                                minDate={this.minDate}
                                                value={this.props.date}
                                                dateFormat={"MM/dd/yyyy"}
                                                placeholderText="Select Date"
                                                className="form-control"
                                                wrapperClassName="datepicker"
                                                //showMonthDropdown
                                                //showYearDropdown
                                                //dropdownMode= "scroll"
                                                
                                            />
                                        </Form.Group>
                                    </Form.Group>
                                    
                                    <Form.Group as={Row} controlId="formGridAddress1">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        APPROVAL
                                        </Form.Label>
                                        <Col sm = "3">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeApproval}
                                                options={this.state.approvalDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        REMARKS
                                        </Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                as="textarea"
                                                rows="3"
                                                onChange={this.onChangeRemarks}
                                                autoComplete="off"
                                                value={this.state.remarks}
                                            />
                                        </Col>
                                    </Form.Group>
                                        
                                    <ButtonToolbar>
                                        {/* <Button className="noser-button" href="/applicationformlogin" variant="danger">Back</Button> */}
                                        <Button className="ml-auto noser-button-mr1" variant="success" onClick = { this.handleSaveClick }>Submit</Button>&nbsp;
                                        {/* <Button className="noser-button-mr1" variant="success" onClick = { this.handleClickApprove("1") }>Approve</Button>
                                        <Button className="noser-button-mr1" variant="danger" onClick = { this.handleClickApprove ("2")}>Reject</Button> */}
                                        <Button className="noser-button" href="/pooling" variant="danger">Back</Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }

}

export  default ClientEndorsementApproval
