import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Modal, DatePicker, NumberFormat
} 
from '../../noser-hris-component';
import EmployeeContractCreate from './EmployeeContractCreate';

class EmployeeContract
extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,      
            disable         :   true,
            
            clientId        :   "",
            typeId          :   "",
            clientDDL       :   [],
            typeDDL         :   [
                {"id":"1","name":"REGULAR"},
                {"id":"2","name":"COOP REGULAR"},
                {"id":"3","name":"TRAINEE"},
                {"id":"4","name":"PROBITIONARY"}
            ],
            employeeGRID    :   [],
        }
    }

    componentDidMount(){
        //const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL : data.clients})
            this.GetEmployeeRegularizations()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetEmployeeRegularizations = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.clientId,
            "ContractTypeId":this.state.typeId,
            "StatusId":"15"
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetContracts", params)
        .then(res => {
            const data = res.data;
            this.setState({employeeGRID : data.contracts, isloading:false})
            
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }  
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        
    }
    handleChangedClient = e =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.clientId = ""
            return
        }
        this.state.clientId = e[0].id
    }
    handleChangedType = e =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.typeId = ""
            return
        }
        this.state.typeId = e[0].id
    }
    handleSearchClick = () =>{
        this.GetEmployeeRegularizations()
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        // console.log(e)
        // let obj = {
        //     "probitionaryTypeId" : e.probitionaryTypeId,
        //     "probitionaryType" : e.probitionaryType
        // }
        this.child.initialize(e)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.GetEmployeeRegularizations()
    }
    render() { 
        const employeeCol = [
            {
                dataField: 'action',
                text: 'ACTION',
                headerStyle: () => {
                    return { width: "12%" };
                },
                editable:false,
                formatter   :   (cell, row, isSelect) => {
                        if(row.returnedById===""){
                            return (
                                <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                                variant="link" onClick={e => this.handleModalShow(row)}
                            >RETURN</Button>
                            )    
                        }
                },
            },
            {
                dataField: 'contractType',
                text: 'TYPE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'12%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'12%',whiteSpace:'nowrap'}
            },
            /* {
                dataField: 'client',
                text: 'CLIENT',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'20%',whiteSpace:'nowrap'}
            }, */
            {
                dataField: 'branch',
                text: 'BRANCH',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'22%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'22%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'position',
                text: 'POSITION',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'17%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'17%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE NAME',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'17%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'17%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'contractDateStart',
                text: 'CONTRACT DATE START',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'12%',whiteSpace:'nowrap'}},
                style:{textAlign:'center',width:'12%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'issuedByName',
                text: 'ISSUED BY',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'27%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'27%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'issuedDate',
                text: 'ISSUED DATE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'12%',whiteSpace:'nowrap'}},
                style:{textAlign:'center',width:'12%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'returnedByName',
                text: 'RETURNED BY',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'22%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'22%',whiteSpace:'nowrap'}
            },
            {
                dataField: 'returnedDate',
                text: 'RETURN DATE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'12%',whiteSpace:'nowrap'}},
                style:{textAlign:'center',width:'12%',whiteSpace:'nowrap'}
            }  
        ]
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        return(
            <div>
            <Banner />
            <Container className="themed-container" fluid={true}>
                <Card className="mt-5">
                    <Card.Header>Administrator >> Employee Contracts</Card.Header>
                    <Card.Body>
                        <Form >
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                </Form.Label>
                                <Col sm="11">
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        name="clientId"
                                        onChange={this.handleChangedClient}
                                        options={this.state.clientDDL}
                                    />
                                </Col>
                            </Form.Group> */}
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        TYPE
                                </Form.Label>
                                <Col sm="11">
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        name="typeId"
                                        onChange={this.handleChangedType}
                                        options={this.state.typeDDL}
                                    />
                                </Col>
                            </Form.Group>
                            <ButtonToolbar className="mt-1">
                                <Button variant="primary" className="ml-auto noser-button" onClick={ this.handleSearchClick }>Search</Button>
                            </ButtonToolbar>
                        </Form>
                        <div className="mt-5">
                            <Card.Header>LIST OF EMPLOYEE CONTRACTS</Card.Header>
                            <BootstrapTable
                                keyField = "id"
                                data = { this.state.employeeGRID }
                                columns = { employeeCol }
                                selectRow = { selectRow }
                                cellEdit = { cellEditFactory({ 
                                        mode: 'dbclick', 
                                        blurToSave: true,
                                        afterSaveCell: (oldValue, newValue, row, column) => { 
                                            //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                        }
                                    })
                                }
                                rowClasses="noser-table-row-class no-checkbox"
                                striped
                                hover
                                condensed
                                loading={true}
                                wrapperClasses="table-responsive"
                                rowClasses="noser-table-row-class no-checkbox"
                                pagination={ paginationFactory({sizePerPageRenderer})}
                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                            />
                    </div>
                    </Card.Body>
                </Card>
            </Container>
            <NoserLoading show={this.state.isloading} />
            {/* <RegularizationContract 
                show={this.state.openModal}
                onHide={this.handleModalClose}
                onRefModal={ref => (this.child = ref)}
            /> */}
            <EmployeeContractCreate 
                show={this.state.openModal}
                onHide={this.handleModalClose}
                onRefModal={ref => (this.child = ref)}
            />
        </div>
        );
    }
}
export  default EmployeeContract
