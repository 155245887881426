import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
}
from '../../noser-hris-component';
import { DropdownButton, Dropdown, Image,FormControl} from 'react-bootstrap';

import  TrainingRegisterModal from '../Training/TrainingModal/TrainingRegisterModal'
import { Input } from 'reactstrap';
import { isDoWhileStatement } from '@babel/types';
import Pdf from "react-to-pdf";
import ReactToPrint from "react-to-print";
import paramountLogo from "../../icon/paramountLogo.jpg"
import paraFooter from "../../icon/paraFooter.jpg"
import ImgsViewer from 'react-images-viewer'

const ref = React.createRef();


class ComponentToPrint extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            regexp: /^[0-9\b]+$/,

            fade            :   true,
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   true,
            alerttype       :   "",

            isLoading           :"",

            id                  :"",
            client              :"",
            employeeName        :"",
            position            :"",
            employeeNo          :"",
            location            :"",
            date                :"",
            irReferenceNo       :"",
            paragraph           :"",
            addedparagraph1     :"",
            addedparagraph2     :"",
            addedparagraph3     :"",
            addedparagraph4     :"",
            addedparagraph5     :"",
            addedparagraph6     :"",
            quotedStatement     :"",
            preparedBy          :"",
            prepareByPosition   :"",
            notedByPosition   :"",
            notedBy             :"",
            receivedBy          :"",
            isDraft             :"",
            isDeleted           :"",
            createdby           :"",
            createddate         :"",
            modifiedby          :"",
            modifieddate        :"",
            isModified          :"",

            articleNo               :"",
            articleName             :"",
            sectionNo               :"",
            infraction              :"",
            category                :"",

            articleNo2              :"",
            articleName2            :"",
            sectionNo2              :"",
            infraction2             :"",
            category2               :"",

            sectionNo3              :"",
            infraction3             :"",
            category3               :"",

            irDateIssued            :"",
            dateRecieveByER         :"",
            referenceNoNTE          :"",
            cocReferences           :"",
            eSignatureNotedBy       :"",
            eSignaturePreparedBy    :"",

            departmentCodeAutocomplete:"",
            irIdCheck               :"",
            dateNow                 :"",
            paragraph1              :"",
            logo                    :"https://assets.bossjob.com/companies/13161/logo/3Mwts6BLTZy6I6WhqQm9B79bIhq8dILG7uqq7SHl.png",

            articleDataList:[],
            sectionDataList:[],

            articleDataList2:[],
            sectionDataList2:[],

            articleDataList3:[],
            sectionDataList3:[],

            articleDataList4:[],
            sectionDataList4:[],

            articleDataList5:[],
            sectionDataList5:[],
            insertImage:"",
            footerTobtm:{},
            //{marginTop:"1000px",/* marginBottom:"1000px" */},
            dimensions: null,
            insertedImageSize:"",
            insertedImageWidth:"",
            insertedImageheigth:"",
        }
    }

    componentDidMount(){
        //let ne1123w = this.container.offsetHeight
        //const height = this.divElement.clientHeight;
        // console.log("ne1123w")
        // console.log(ne1123w)
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})

        this.state.irIdCheck = JSON.parse(sessionStorage.getItem("irId"))




        this.getViewDraftMemo()

    }

   /*  getNTE=()=>{
        //let irIdCheckTmp = JSON.parse(sessionStorage.getItem("irId"))

        let filter_data ={}

        const getParams ={
            "_collection" : "TestNTE",
            "filter_data" : filter_data
        }

            axios
            .post("http://134.209.99.190:8088/action/get" , getParams)
            .then(res =>{
                let data = res.data

                const itemUsersDataLists = this.buildList(data["TestNTE"])

                this.state.itemUserDataList  =  itemUsersDataLists

                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                    let obj = {
                        'id'                    :   this.state.itemUserDataList[i]['id'],
                        'client'                :   this.state.itemUserDataList[i]['client'],
                        'employeeName'          :   this.state.itemUserDataList[i]['employeeName'],
                        'position'              :   this.state.itemUserDataList[i]['position'],
                        'employeeNo'            :   this.state.itemUserDataList[i]['employeeNo'],
                        'location'              :   this.state.itemUserDataList[i]['location'],
                        'date'                  :   this.state.itemUserDataList[i]['date'],
                        'referenceNoNTE'        :   this.state.itemUserDataList[i]['referenceNoNTE'],
                        'irReferenceNo'         :   this.state.itemUserDataList[i]['irReferenceNo'],
                        'irDateIssued'          :   this.state.itemUserDataList[i]['irDateIssued'],
                        'dateRecieveByER'       :   this.state.itemUserDataList[i]['dateRecieveByER'],
                        'paragraph'             :   this.state.itemUserDataList[i]['paragraph'],
                        'addedparagraph1'       :   this.state.itemUserDataList[i]['addedparagraph1'],
                        'addedparagraph2'       :   this.state.itemUserDataList[i]['addedparagraph2'],
                        'addedparagraph3'       :   this.state.itemUserDataList[i]['addedparagraph3'],
                        'quotedStatement'       :   this.state.itemUserDataList[i]['quotedStatement'],
                        "cocArticle"            :   this.state.itemUserDataList[i]['cocArticle'],
                        "articleDiscription"    :   this.state.itemUserDataList[i]['articleDiscription'],
                        "cocSection"            :   this.state.itemUserDataList[i]['cocSection'],
                        "sectionDiscription"    :   this.state.itemUserDataList[i]['sectionDiscription'],
                        'preparedBy'            :   this.state.itemUserDataList[i]['preparedBy'],
                        'prepareByPosition'     :   this.state.itemUserDataList[i]['prepareByPosition'],
                        'notedBy'               :   this.state.itemUserDataList[i]['notedBy'],
                        'receivedBy'            :   this.state.itemUserDataList[i]['receivedBy'],
                        'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'            :   this.state.itemUserDataList[i]['isModified'],

                    }
                    List.push(obj)
                    console.log(List)


                    this.setState({
                        paragraph:obj.paragraph,
                        addedparagraph1:obj.addedparagraph1,
                        quotedStatement:obj.quotedStatement,
                        employeeName:obj.employeeName,
                        location:obj.location,
                    })


                }
            })
            .catch(error=>{
                //console.log("error: " + error)
            })
        }

    buildList(data) {
        //console.log("data start")
        let itemList =[]

        let idList                  =[]
        let clientList              =[]
        let employeeNameList        =[]
        let positionList            =[]
        let employeeNoList          =[]
        let locationList            =[]
        let dateList                =[]
        let referenceNoNTEList      =[]
        let irReferenceNoList       =[]
        let irDateIssuedList        =[]
        let dateRecieveByERList     =[]
        let paragraphList           =[]
        let addedparagraph1List     =[]
        let addedparagraph2List     =[]
        let addedparagraph3List     =[]
        let quotedStatementList     =[]
        let cocArticleList          =[]
        let articleDiscriptionList  =[]
        let cocSectionList          =[]
        let sectionDiscriptionList  =[]
        let preparedByList          =[]
        let prepareByPositionList   =[]
        let notedByList             =[]
        let receivedByList          =[]
        let isDraftList             =[]
        let isDeletedList           =[]
        let createdbyList           =[]
        let createddateList         =[]
        let modifiedbyList          =[]
        let modifieddateList        =[]
        let isModifiedList          =[]

        for (let i = 0; i < data.length; i++) {

            let s1 = data[i].split("':")

            let idClean                     = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let clientTmp                   = s1[2].split("',")[0]
            let employeeNameListTmp         = s1[3].split("',")[0]
            let positionListTmp             = s1[4].split("',")[0]
            let employeeNoListTmp           = s1[5].split(",")[0]
            let locationListTmp             = s1[6].split("',")[0]
            let dateListTmp                 = s1[7].split("',")[0]
            let referenceNoNTETmp           = s1[8].split("',")[0]
            let irReferenceNoListTmp        = s1[9].split("',")[0]
            let irDateIssuedListTmp         = s1[10].split("',")[0]
            let dateRecieveByERListTmp      = s1[11].split("',")[0]
            let paragraphListTmp            = s1[12].split("',")[0]
            let addedparagraph1ListTmp      = s1[13].split("',")[0]
            let addedparagraph2ListTmp      = s1[14].split("',")[0]
            let addedparagraph3ListTmp      = s1[15].split("',")[0]
            let quotedStatementListTmp      = s1[16].split("',")[0]
            let cocArticleListTmp           = s1[18].split("',")[0]
            let articleDiscriptionListTmp   = s1[19].split("',")[0]
            let cocSectionListTmp           = s1[20].split("',")[0]
            let sectionDiscriptionListTmp   = s1[21].split("',")[0]
            let preparedByListTmp           = s1[22].split("',")[0]
            let prepareByPositionListTmp    = s1[23].split("',")[0]
            let notedByListTmp              = s1[24].split("',")[0]
            let receivedByListTmp           = s1[25].split("',")[0]
            let isDraftListTmp              = s1[26].split("',")[0]
            let isDeletedListTmp            = s1[27].split("',")[0]
            let createdbyListTmp            = s1[28].split("',")[0]
            let createddateListTmp          = s1[29].split("',")[0]
            let modifiedbyListTmp           = s1[30].split("',")[0]
            let modifieddateListTmp         = s1[31].split("',")[0]
            let isModifiedListTmp           = s1[32].split("',")[0]


            idList.push(IdClean2.replace("'",""))
            clientList.push(clientTmp.replace("'",""))
            employeeNameList.push(employeeNameListTmp.replace("'",""))
            positionList.push(positionListTmp.replace("'",""))
            employeeNoList.push(employeeNoListTmp.replace("'","").replace(" '",""))
            locationList.push(locationListTmp.replace("'",""))
            dateList.push(dateListTmp.replace("'",""))
            referenceNoNTEList.push(referenceNoNTETmp.replace("'",""))
            irReferenceNoList.push(irReferenceNoListTmp.replace("'",""))
            irDateIssuedList.push(irDateIssuedListTmp.replace("'",""))
            dateRecieveByERList.push(dateRecieveByERListTmp.replace("'",""))
            paragraphList.push(paragraphListTmp.replace("'",""))
            addedparagraph1List.push(addedparagraph1ListTmp.replace("'",""))
            addedparagraph2List.push(addedparagraph2ListTmp.replace("'",""))
            addedparagraph3List.push(addedparagraph3ListTmp.replace("'",""))
            quotedStatementList.push(quotedStatementListTmp.replace("'",""))
            cocArticleList.push(cocArticleListTmp.replace("'",""))
            articleDiscriptionList.push(articleDiscriptionListTmp.replace("'",""))
            cocSectionList.push(cocSectionListTmp.replace("'",""))
            sectionDiscriptionList.push(sectionDiscriptionListTmp.replace("'",""))
            preparedByList.push(preparedByListTmp.replace("'",""))
            prepareByPositionList.push(prepareByPositionListTmp.replace("'",""))
            notedByList.push(notedByListTmp.replace("'",""))
            receivedByList.push(receivedByListTmp.replace("'",""))
            isDraftList.push(isDraftListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))

        }

        for (let i = 0; i < idList.length; i++) {

            let obj ={
                'id'                    :   idList[i],
                'client'                :   clientList[i],
                'employeeName'          :   employeeNameList[i],
                'position'              :   positionList[i],
                'employeeNo'            :   employeeNoList[i],
                'location'              :   locationList[i],
                'date'                  :   dateList[i],
                'referenceNoNTE'        :   referenceNoNTEList[i],
                'irReferenceNo'         :   irReferenceNoList[i],
                'irDateIssued'          :   irDateIssuedList[i],
                'dateRecieveByER'       :   dateRecieveByERList[i],
                'paragraph'             :   paragraphList[i],
                'addedparagraph1'       :   addedparagraph1List[i],
                'addedparagraph2'       :   addedparagraph2List[i],
                'addedparagraph3'       :   addedparagraph3List[i],
                'quotedStatement'       :   quotedStatementList[i],
                'cocArticle'            :   cocArticleList[i],
                'articleDiscription'    :   articleDiscriptionList[i],
                'cocSection'            :   cocSectionList[i],
                'sectionDiscription'    :   sectionDiscriptionList[i],
                'preparedBy'            :   preparedByList[i],
                'prepareByPosition'     :   prepareByPositionList[i],
                'notedBy'               :   notedByList[i],
                'receivedBy'            :   receivedByList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],

            }
            itemList.push(obj)

        }
        return itemList
    }  */

    onChangePosition=(e)=>{
        this.setState({
            position:e.target.value
        })
    }

    getViewDraftMemo=()=>{



        let dataNTE = JSON.parse(sessionStorage.getItem("data"))
        let dataNTE1 = JSON.parse(sessionStorage.getItem("viewDraftMemoTmp"))

        let newDate = new Date(dataNTE.dateOfNTE)
        let createddate = moment(newDate).format('MM');
        let createddate2 = moment(newDate).format('YYYY');
        let createddate3 = moment(newDate).format('DD');
        console.log(createddate)
        console.log(createddate2)

        let monthLeters = ""
        if(createddate == "01"){
            monthLeters = "January"
        }
        if(createddate == "02"){
            monthLeters = "February"
        }
        if(createddate == "03"){
            monthLeters = "March"
        }
        if(createddate == "04"){
            monthLeters = "April"
        }
        if(createddate == "05"){
            monthLeters = "May"
        }
        if(createddate == "06"){
            monthLeters = "June"
        }
        if(createddate == "07"){
            monthLeters = "July"
        }
        if(createddate == "08"){
            monthLeters = "August"
        }
        if(createddate == "09"){
            monthLeters = "September"
        }
        if(createddate == "10"){
            monthLeters = "October"
        }
        if(createddate == "11"){
            monthLeters = "November"
        }
        if(createddate == "12"){
            monthLeters = "December"
        }

        let wordsDate = createddate3+" "+monthLeters+" "+createddate2

        let dataCOCRefarticle1Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle1"))
        let dataCOCRefarticle2Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle2"))
        let dataCOCRefarticle3Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle3"))
        let dataCOCRefarticle4Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle4"))
        let dataCOCRefarticle5Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle5"))

        let dataCOCRefsection1Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection1"))
        let dataCOCRefsection2Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection2"))
        let dataCOCRefsection3Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection3"))
        let dataCOCRefsection4Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection4"))
        let dataCOCRefsection5Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection5"))


        this.setState({
            paragraph           :   dataNTE.paragraph,
            addedparagraph1     :   dataNTE.addedparagraph1,
            quotedStatement     :   dataNTE.quotedStatement,
            employeeName        :   dataNTE.employeeName,
            location            :   dataNTE.location,
            dateNow             :   wordsDate,
            preparedBy          :   dataNTE.preparedBy ,
            prepareByPosition   :   dataNTE.prepareByPosition ,
            notedByPosition     :   dataNTE.notedByPosition ,
            notedBy             :   dataNTE.notedBy ,
            addedparagraph2     :   dataNTE.addedparagraph2,
            addedparagraph3     :   dataNTE.addedparagraph3,
            addedparagraph4     :   dataNTE.addedparagraph4,
            addedparagraph5     :   dataNTE.addedparagraph5,
            addedparagraph6     :   dataNTE.addedparagraph6,

            eSignatureNotedBy    :   dataNTE.eSignatureNotedBy,
            eSignaturePreparedBy :   dataNTE.eSignaturePreparedBy,
            referenceNoNTE       :   dataNTE.referenceNoNTE,


            articleDataList:dataNTE.articleDataList,
            sectionDataList:dataNTE.sectionDataList,

            articleDataList2:dataNTE.articleDataList2,
            sectionDataList2:dataNTE.sectionDataList2,

            articleDataList3:dataNTE.articleDataList3,
            sectionDataList3:dataNTE.sectionDataList3,

            articleDataList4:dataNTE.articleDataList4,
            sectionDataList4:dataNTE.sectionDataList4,

            articleDataList5:dataNTE.articleDataList5,
            sectionDataList5        :dataNTE.sectionDataList5,
            insertImage             :   dataNTE.insertImage,
            insertedImageSize             :   dataNTE.insertedImageSize,
            isloading:false



        })

        let paragrapToCount = dataNTE.paragraph.length
        console.log("paragrapToCount")
        console.log(paragrapToCount)
        let quotedStatementToCount = dataNTE.quotedStatement.length
        console.log("quotedStatementToCount")
        console.log(quotedStatementToCount)
        let paragrapToCount1 = dataNTE.addedparagraph1.length
        console.log("paragrapToCount1")
        console.log(paragrapToCount1)
        let paragrapToCount2 = dataNTE.addedparagraph2.length
        console.log("paragrapToCount2")
        console.log(paragrapToCount2)
        let paragrapToCount3 = dataNTE.addedparagraph3.length
        console.log("paragrapToCount3")
        console.log(paragrapToCount3)
        let paragrapToCount4 = dataNTE.addedparagraph4.length
        console.log("paragrapToCount4")
        console.log(paragrapToCount4)
        let paragrapToCount5 = dataNTE.addedparagraph5.length
        console.log("paragrapToCount5")
        console.log(paragrapToCount5)
        let paragrapToCount6 = dataNTE.addedparagraph6.length
        console.log("paragrapToCount6")
        console.log(paragrapToCount6)
        let insertedImageSize = dataNTE.insertedImageSize
        console.log("insertedImageSize")
        console.log(insertedImageSize)

      /*   const uploadedImage = dataNTE.insertImage
        var image = new Image();

        image.src = uploadedImage;

        console.log("image.naturalWidth,image.naturalHeight");
        console.log(image.naturalWidth,image.naturalHeight); */

        let totalOfInfrac1 = 0
        let totalOfInfrac2 = 0
        let totalOfInfrac3 = 0
        let totalOfInfrac4 = 0
        let totalOfInfrac5 = 0
        
        if(dataNTE.sectionDataList.length > 0){
            let infraction1Tpm = []
            dataNTE.sectionDataList.map(function(d){
                infraction1Tpm.push(d.infraction.length)
            })
            const reducer = (accumulator, curr) => accumulator + curr;
            totalOfInfrac1 = infraction1Tpm.reduce(reducer)
            console.log("reducer1111123132123123");
            console.log(totalOfInfrac1);
        }

        if(dataNTE.sectionDataList2.length > 0){
            let infraction2Tpm = []
            dataNTE.sectionDataList2.map(function(d){
                infraction2Tpm.push(d.infraction.length)
            })
            const reducer2 = (accumulator, curr) => accumulator + curr;
            totalOfInfrac2 = infraction2Tpm.reduce(reducer2)
            console.log("reducer2");
            console.log(totalOfInfrac2);
        }
        
        if(dataNTE.sectionDataList3.length > 0){
            let infraction3Tpm = []
            dataNTE.sectionDataList3.map(function(d){
                infraction3Tpm.push(d.infraction.length)
            })
            const reducer3 = (accumulator, curr) => accumulator + curr;
            totalOfInfrac3 = infraction3Tpm.reduce(reducer3)
            console.log("reducer3");
            console.log(totalOfInfrac3);
        }

        if(dataNTE.sectionDataList4.length > 0){
            let infraction4Tpm = []
            dataNTE.sectionDataList4.map(function(d){
                infraction4Tpm.push(d.infraction.length)
            })
            const reducer4 = (accumulator, curr) => accumulator + curr;
            totalOfInfrac4 = infraction4Tpm.reduce(reducer4)
            console.log("reducer4");
            console.log(totalOfInfrac4);
        }

        if(dataNTE.sectionDataList5.length > 0){
            let infraction5Tpm = []
            dataNTE.sectionDataList5.map(function(d){
                infraction5Tpm.push(d.infraction.length)
            })
            const reducer5 = (accumulator, curr) => accumulator + curr;
            totalOfInfrac5 = infraction5Tpm.reduce(reducer5)
            console.log("reducer5");
            console.log(totalOfInfrac5);
        }

        const height11 = this.insertedImgElement.clientHeight;
        console.log("height11")
        console.log(height11)

        
        


        let allParagraph = paragrapToCount+quotedStatementToCount+paragrapToCount1+paragrapToCount2+paragrapToCount3+paragrapToCount4+paragrapToCount5+paragrapToCount6+totalOfInfrac1+totalOfInfrac2+totalOfInfrac3
        console.log("allParagraph")
        console.log(allParagraph)

        /* NOTE : MAX Size ON Screnn 10320 */

       /*  if(allParagraph > 529 && allParagraph < 541){
            console.log("height > 529 && height < 541")
            this.setState({
                footerTobtm:{marginTop:"70px",marginLeft:"390px",}
            })
        }
        if(allParagraph > 910 && allParagraph < 921){
            console.log("height > 920 && height < 921")
            this.setState({
                footerTobtm:{marginTop:"-61px",marginLeft:"390px",}
            })
        } */
        if(allParagraph >= 500 && allParagraph <= 599){
            console.log("height > 600 && height < 699")
            this.setState({
                footerTobtm:{marginTop:"-10px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 600 && allParagraph <= 699){
            console.log("height > 600 && height < 699")
            this.setState({
                footerTobtm:{marginTop:"2000px",marginLeft:"480px",}
            })
        }
        if(allParagraph >= 700 && allParagraph <= 799){
            console.log("height > 700 && height < 799")
            this.setState({
                footerTobtm:{marginTop:"2000px",marginLeft:"480px",}
            })
        }
        if(allParagraph >= 800 && allParagraph <= 899){
            console.log("height > 800 && height < 899")
            this.setState({
                footerTobtm:{marginTop:"1400px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 900 && allParagraph <= 1000){
            console.log("height > 900 && height < 1000")
            this.setState({
                footerTobtm:{marginTop:"1340px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 1001 && allParagraph <= 1100){
            console.log("height > 1001 && height < 1100")
            this.setState({
                footerTobtm:{marginTop:"1280px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 1101 && allParagraph <= 1200){
            console.log("height > 1101 && height < 1200")
            this.setState({
                footerTobtm:{marginTop:"1150px",marginLeft:"390px",}
            })
        }

        if(allParagraph >= 1201 && allParagraph <= 1301){
            console.log("height > 1201 && height < 1301")
            this.setState({
                footerTobtm:{marginTop:"1250px",marginLeft:"390px",}
            })
        }

        if(allParagraph >= 1300 && allParagraph <= 1401){
            console.log("height > 1300 && height < 1401")
            this.setState({
                footerTobtm:{marginTop:"1200px",marginLeft:"390px",}
            })
        }

        if(allParagraph >= 1400 && allParagraph <= 1501){
            console.log("height > 1400 && height < 1501")
            this.setState({
                footerTobtm:{marginTop:"1200px",marginLeft:"390px",}
            })
        }

        if(allParagraph >= 1500 && allParagraph < 1601){
            console.log("height > 1500 && height < 1601")
            this.setState({
                footerTobtm:{marginTop:"1200px",marginLeft:"390px",}
            })
        }

        if(allParagraph >= 1600 && allParagraph <= 1700){
            console.log("height > 1600 && height < 1700")
            this.setState({
                footerTobtm:{marginTop:"850px",marginLeft:"390px",}
            })
        }

        if(allParagraph >= 1701 && allParagraph <= 1800){
            console.log("height > 1701 && height < 1800")
            this.setState({
                footerTobtm:{marginTop:"1100px",marginLeft:"390px",}
            })
        }

        if(allParagraph >= 1801 && allParagraph <= 1900){
            console.log("height > 1801 && height < 1900")
            this.setState({
                footerTobtm:{marginTop:"950px",marginLeft:"390px",}
            })
        }

        if(allParagraph >= 1901 && allParagraph <= 2000){
            console.log("height > 1901 && height < 2000")
            this.setState({
                footerTobtm:{marginTop:"800px",marginLeft:"390px",}
            })
        }

        if(allParagraph >= 2001 && allParagraph <= 2100){
            console.log("height > 2001 && height < 2100")
            this.setState({
                footerTobtm:{marginTop:"410px",marginLeft:"390px",}
            })
        }

        if(allParagraph >= 2101 && allParagraph <= 2200){
            console.log("height > 2101 && height < 2200")
            this.setState({
                footerTobtm:{marginTop:"400px",marginLeft:"390px",}
            })
        }

        if(allParagraph >= 3101 && allParagraph <= 3200){
            console.log("height > 3401 && height < 3500")
            this.setState({
                footerTobtm:{marginTop:"50px",marginLeft:"390px",}
            })
        }

        if(allParagraph >= 3201 && allParagraph <= 3300){
            console.log("height > 3401 && height < 3500")
            this.setState({
                footerTobtm:{marginTop:"35px",marginLeft:"390px",}
            })
        }

        if(allParagraph >= 3301 && allParagraph <= 3400){
            console.log("height > 3401 && height < 3500")
            this.setState({
                footerTobtm:{marginTop:"20px",marginLeft:"390px",}
            })
        }

        if(allParagraph >= 3401 && allParagraph <= 3500){
            console.log("height > 3401 && height < 3500")
            this.setState({
                footerTobtm:{marginTop:"10px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 3501 && allParagraph <= 3550){ // eto max ng second page 3504 with footer
            console.log("height > 3501 && height < 3550")
            this.setState({
                footerTobtm:{marginTop:"0px",marginLeft:"390px",}
            })
        }

        if(allParagraph >= 3801 && allParagraph <= 3900){ // eto max ng second page 3860 wala footer
            console.log("height > 3801 && height < 3900")
            this.setState({
                footerTobtm:{marginTop:"1590px",marginLeft:"380px",}
            })
        }

        // if(allParagraph >= 10320 && allParagraph <= 10400){
        //     console.log("height > 1370 && height < 1380")
        //     this.setState({
        //         footerTobtm:{marginTop:"30px",marginLeft:"390px",}
        //     })
        // }
        // if(allParagraph >= 10570 && allParagraph <= 10599){
        //     console.log("height > 10570 && height < 10590")
        //    /*  this.setState({
        //         footerTobtm:{marginTop:"-10px",marginLeft:"390px",}
        //     }) */
        // }
        // if(allParagraph >= 10600 && allParagraph <= 10620){
        //     console.log("height => 10600 && height <= 10620")
        //     this.setState({
        //         footerTobtm:{marginTop:"-40px",marginLeft:"390px",}
        //     })
        // }
        /* end of first page */

        if(allParagraph > 11130 && allParagraph < 11140){
            console.log("height > 11130 && height < 11140")
            this.setState({
                footerTobtm:{marginTop:"1490px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 21550 && allParagraph <= 21600){
            console.log("height > 21550 && height < 21550")
            this.setState({
                footerTobtm:{marginTop:"1350px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 70200 && allParagraph <= 70299){
            console.log("height > 70500 && height < 70699")
            this.setState({
                footerTobtm:{marginTop:"1100px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 70300 && allParagraph <= 70399){
            console.log("height > 70500 && height < 70699")
            this.setState({
                footerTobtm:{marginTop:"1050px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 70400 && allParagraph <= 70499){
            console.log("height > 70400 && height < 70499")
            this.setState({
                footerTobtm:{marginTop:"1000px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 70500 && allParagraph <= 70599){
            console.log("height > 70500 && height < 70599")
            this.setState({
                footerTobtm:{marginTop:"950px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 70600 && allParagraph <= 70699){
            console.log("height > 70600 && height < 70699") //70679
            this.setState({
                footerTobtm:{marginTop:"850px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 70700 && allParagraph <= 70799){ //136166
            console.log("height > 70600 && height < 70700")
            this.setState({
                footerTobtm:{marginTop:"750px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 70800 && allParagraph <= 70899){
            console.log("height > 70800 && height < 70899")
            this.setState({
                footerTobtm:{marginTop:"650px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 70900 && allParagraph <= 70999){
            console.log("height > 70900 && height < 70999")
            this.setState({
                footerTobtm:{marginTop:"550px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 71000 && allParagraph <= 71099){
            console.log("height > 71000 && height < 71099")
            this.setState({
                footerTobtm:{marginTop:"450px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 71100 && allParagraph <= 71199){
            console.log("height > 71000 && height < 71099")
            this.setState({
                footerTobtm:{marginTop:"350px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 71200 && allParagraph <= 71299){
            console.log("height > 71000 && height < 71099")
            this.setState({
                footerTobtm:{marginTop:"250px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 71300 && allParagraph <= 71399){
            console.log("height > 71300 && height < 71399")
            this.setState({
                footerTobtm:{marginTop:"150px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 71400 && allParagraph <= 71499){
            console.log("height > 71300 && height < 71399")
            this.setState({
                footerTobtm:{marginTop:"50px",marginLeft:"390px",}
            })
        }
        /* end of second page */


        if(allParagraph >= 71500 && allParagraph <= 71599){
            console.log("height > 71500 && height < 71599")
            this.setState({
                footerTobtm:{marginTop:"450px",marginLeft:"390px",}
            })
        }

        if(allParagraph >= 71600 && allParagraph <= 71699){
            console.log("height > 71300 && height < 71399")
            this.setState({
                footerTobtm:{marginTop:"500px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 71700 && allParagraph <= 71799){
            console.log("height > 71700 && height < 71799")
            this.setState({
                footerTobtm:{marginTop:"450px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 71800 && allParagraph <= 71899){
            console.log("height > 71800 && height < 71899")
            this.setState({
                footerTobtm:{marginTop:"400px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 71800 && allParagraph <= 71899){
            console.log("height > 71800 && height < 71899")
            this.setState({
                footerTobtm:{marginTop:"400px",marginLeft:"390px",}
            })
        }

        // const height = this.divElement.clientHeight;
        // console.log("height")
        // console.log(height) // 999 or 884
        // const height1 = this.headerElement.clientHeight;
        // console.log("height1")
        // console.log(height1) // 20
        // const height2 = this.dateexElement.clientHeight;
        // console.log("height2")
        // console.log(height2) // 60
        // const height3 = this.toemployeeElement.clientHeight;
        // console.log("height3")
        // console.log(height3) // 39
        // const height4 = this.locationElement.clientHeight;
        // console.log("height4")
        // console.log(height4) // 17
        // const height5 = this.fromElement.clientHeight;
        // console.log("height5")
        // console.log(height5) // 38
        // const height6 = this.subjectElement.clientHeight;
        // console.log("height6")
        // console.log(height6) // 38
        // const height7 = this.refNoElement.clientHeight;
        // console.log("height7")
        // console.log(height7) // 38
        // const height8 = this.borderrxElement.clientHeight;
        // console.log("height8")
        // console.log(height8) // 15
        // const height9 = this.paragraphElement.clientHeight;
        // console.log("height9")
        // console.log(height9)
        // const height10 = this.quotedElement.clientHeight;
        // console.log("height10")
        // console.log(height10)
        // const height11 = this.insertedImgElement.clientHeight;
        // console.log("height11")
        // console.log(height11)
        // const height12 = this.addedOneElement.clientHeight;
        // console.log("height12")
        // console.log(height12)
        // const height13 = this.twoAdElement.clientHeight;
        // const height100 = this.twoAdElement.clientWidth ;
        // console.log("height13")
        // console.log(height13)
        // console.log("height100")
        // console.log(height100)
        // const height14 = this.threeAdElement.clientHeight;
        // console.log("height14")
        // console.log(height14)
        // const height15 = this.fourAdElement.clientHeight;
        // console.log("height15")
        // console.log(height15)
        // const height16 = this.fiveAdElement.clientHeight;
        // console.log("height16")
        // console.log(height16)
        // const height17 = this.sixAdElement.clientHeight;
        // console.log("height17")
        // console.log(height17)
        // const height18 = this.prepardElement.clientHeight;
        // console.log("height18")
        // console.log(height18)
        // const height19 = this.prapredBySignElement.clientHeight;
        // console.log("height19")
        // console.log(height19)
        // const height20 = this.preparedByNameElement.clientHeight;
        // console.log("height20")
        // console.log(height20)
        // const height21 = this.preparedByPosiElement.clientHeight;
        // console.log("height21")
        // console.log(height21)
        // const height22 = this.notedElement.clientHeight;
        // console.log("height22")
        // console.log(height22)
        // const height23 = this.notedBySignElement.clientHeight;
        // console.log("height23")
        // console.log(height23)
        // const height24 = this.notedByNamelement.clientHeight;
        // console.log("height24")
        // console.log(height24)
        // const height25 = this.notedPosiElement.clientHeight;
        // console.log("height25")
        // console.log(height25)
        // const height26 = this.receivedElement.clientHeight;
        // console.log("height26")
        // console.log(height26)
        // const height27 = this.receivedNameElement.clientHeight;
        // console.log("height27")
        // console.log(height27)
        // const height28 = this.signOverNameElement.clientHeight;
        // console.log("height28")
        // console.log(height28)
        // const height29 = this.ccfileElement.clientHeight;
        // console.log("height29")
        // console.log(height29)
        // const height30 = this.footerElement.clientHeight;
        // console.log("height30")
        // console.log(height30)

        // let totalHeight = height + height1+ height2+ height3+height4+height5+height6+height7+height8+height9+height10+height11+height12+height13+height14+height15+height16+height17+height18+height19+height20+height21+height22+height23+height24+height25+height26+height27+height28+height29+height30
        // console.log("totalHeight")
        // console.log(totalHeight)


        // if(totalHeight > 1720 && totalHeight < 1730){
        //     console.log("height > 1720 && height < 1730")
        //     this.setState({
        //         footerTobtm:{/* marginTop:"1000px", */marginLeft:"390px",}
        //     })
        // }
        // if(totalHeight > 1840 && totalHeight < 1850){
        //     console.log("height > 1840 && height < 1850")
        //     this.setState({
        //         footerTobtm:{marginTop:"1455px",marginLeft:"390px",}
        //     })
        // }
        // if(totalHeight > 1950 && totalHeight < 2000){ ///1969
        //     console.log("height > 1950 && height < 2000")
        //     this.setState({
        //         footerTobtm:{marginTop:"0px",marginLeft:"390px",}
        //     })
        // }
        // if(totalHeight > 2000 && totalHeight < 2050){
        //     console.log("height > 2000 && height < 2050")
        //     this.setState({
        //         footerTobtm:{marginTop:"1630px",marginLeft:"390px",}
        //     })
        // }
        // if(totalHeight > 2090 && totalHeight < 2100){
        //     console.log("height > 2090 && height < 2100")
        //     this.setState({
        //         footerTobtm:{marginTop:"1460px",marginLeft:"390px",}
        //         //footerTobtm:{marginTop:"100px",marginLeft:"390px",}
        //     })
        // }





        // if(height > 500){
        //     console.log("height > 500")

        //     if(height > 800 && height < 850){
        //         console.log("height > 800 && height < 850")
        //         this.setState({
        //             footerTobtm:{marginTop:"15px",marginLeft:"390px",}
        //         })
        //     }

        //     if(height > 850 && height < 860){
        //         console.log("height > 850 && height < 860")
        //         this.setState({
        //             footerTobtm:{marginTop:"80px",marginLeft:"390px",}
        //         })
        //     }
        //     if(height > 860 && height < 870){
        //         console.log("height > 860 && height < 870")
        //         this.setState({
        //             footerTobtm:{marginTop:"50px",marginLeft:"390px",}
        //         })
        //     }
        //     if(height > 870 && height < 890){
        //         console.log("height > 870 && height < 890")
        //         if(height === 884){
        //             console.log("height === 884")
        //             this.setState({
        //                 footerTobtm:{marginTop:"100px",marginLeft:"390px",}
        //             })
        //         }else{
        //             console.log("height != 884")
        //             this.setState({
        //                 footerTobtm:{marginTop:"1000px",marginLeft:"390px",}
        //             })
        //         }

        //     }

        //     if(height > 900 && height < 950){
        //         console.log("height > 900 && height < 950")
        //         this.setState({
        //             footerTobtm:{marginTop:"15px",marginLeft:"390px",}
        //         })
        //     }

        //     if(height > 950 && height < 970){
        //         console.log("height > 950 && height < 970")
        //         this.setState({
        //             footerTobtm:{marginTop:"72px",marginLeft:"390px",}
        //         })
        //     }
        //     if(height > 970 && height < 990){
        //         console.log("height > 970 && height < 990")
        //         this.setState({
        //             footerTobtm:{marginTop:"72px",marginLeft:"390px",}
        //         })
        //     }

        //     if(height > 990 && height < 1000){
        //         console.log("height > 970 && height < 990")
        //         this.setState({
        //             footerTobtm:{marginTop:"140px",marginLeft:"390px",}
        //         })
        //     }

        //     if(height > 1000 && height < 1100){

        //         console.log("height > 1000 && height < 1100")
        //         if( height === 1032){
        //             this.setState({
        //                 footerTobtm:{marginTop:"8px",marginLeft:"390px",}
        //             })
        //         }
        //         if( height > 1050){
        //             console.log("height > 1050")
        //             this.setState({
        //                 footerTobtm:{marginTop:"8px",marginLeft:"390px",}
        //             })
        //         }

        //     }
        //     let newstyle = {
        //       marginTop:"2000px",
        //     }/*   marginLeft:"341px", */ /* height:"150px", */ /* textAlign:"center",justifyContent:"center", */
        //    /*  this.setState({
        //         footerTobtm:{marginTop:"1650px",marginLeft:"390px",}
        //     }) */
        //    //this.state.footerTobtm = newstyle

        // }





    }

    setBack=()=>{
        let whenBack = 0
        sessionStorage.setItem("nextProcessCheck", JSON.stringify(whenBack))

    }

    render() {
        const { dimensions } = this.state;
        return( <>
            <Container  className="z" fluid>

                        <Form /* ref={el => (this.container = el)} */ ref={ (divElement) => { this.divElement = divElement } } style={{backgroundColor:"#FFFFFF"}}>
                            {/* <Col ref={ (headerElement) => { this.headerElement = headerElement } }>
                                <Image   src={paramountLogo} style={{width:"82%", height:"115%", marginLeft:"20px" , marginTop:"30px",marginBottom:"-10px"}}/>
                            </Col> */}

                            <h1 ref={ (dateexElement) => { this.dateexElement = dateexElement } } as={Row} style={{backgroundColor:'#ffffff', width:"100%",height:"60px", marginTop:"-20px" }}>
                                <Col>
                                <label style={{fontWeight : "bold" , fontSize: 23, marginTop:"15px", marginLeft:"25px"}}>
                                    Memorandum
                                </label>

                                <label style={{fontWeight : "bold" , fontSize: 23, marginLeft:"700px"  }}>
                                    {this.state.dateNow}
                                </label>
                                </Col>

                            </h1>

                            <Form.Group ref={ (toemployeeElement) => { this.toemployeeElement = toemployeeElement } } as={Col} sm="10" >
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22,marginTop:"1px", marginLeft:"25px"}}>
                                    TO
                                </Form.Label>
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22,marginTop:"1px",marginLeft:"110px"}}>:</Form.Label>
                                <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 22,marginLeft:"65px"}}>
                                    {this.state.employeeName}
                                </Form.Label>
                            </Form.Group>

                            <Form.Group ref={ (locationElement) => { this.locationElement = locationElement } } as={Row} controlId="formPlaintextEmail">
                                <Col sm="11" style={{ marginTop:"-20px",marginLeft:"250px" }}>
                                    <Form.Label row style={{fontWeight : "bold" ,fontSize: 21,}}>
                                     MEMBER ASSIGNED IN&nbsp;{this.state.location}
                                    </Form.Label>
                                </Col>
                            </Form.Group>

                            <Form.Group ref={ (fromElement) => { this.fromElement = fromElement } } as={Col} sm="10" style={{marginTop:"-15px" ,}}>
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22, marginLeft:"25px"}}>
                                    FROM
                                </Form.Label>
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22,marginTop:"-1px",marginLeft:"76px"}}>:</Form.Label>
                                <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 22,marginLeft:"65px"}}>
                                     EMPLOYEE DISCPLINE TEAM
                                </Form.Label>
                            </Form.Group>

                            <Form.Group ref={ (subjectElement) => { this.subjectElement = subjectElement } } as={Col} sm="10"  style={{marginTop:"-10px" ,}}>
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22, marginLeft:"25px"}}>
                                    SUBJECT
                                </Form.Label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22,marginTop:"-1px", marginLeft:"27px"}}>:</Form.Label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 22, marginLeft:"38px"}}>
                                    NOTICE TO EXPLAIN
                                </Form.Label>
                            </Form.Group>

                            <Form.Group ref={ (refNoElement) => { this.refNoElement = refNoElement } } as={Col} sm="10" style={{marginTop:"-10px" ,}}>
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22, marginLeft:"25px"}}>
                                REF NO.
                                </Form.Label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22,marginTop:"-1px", marginLeft:"20px"}}>:</Form.Label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 22, marginLeft:"31px"}}>
                                {this.state.referenceNoNTE }
                                </Form.Label>
                            </Form.Group>

                            <h1 ref={ (borderrxElement) => { this.borderrxElement = borderrxElement } } className="mt-3" style={{fontWeight : "bold" ,backgroundColor:'#D5D5D5', width:"100%", height:"15px" }} > </h1>

                            <Form.Group ref={ (paragraphElement) => { this.paragraphElement = paragraphElement } }   as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px",whiteSpace: "pre-wrap", }}>
                                        {this.state.paragraph}
                                    </Form.Label>
                                </Col>
                            </Form.Group>


                            <Form.Group ref={ (quotedElement) => { this.quotedElement = quotedElement } } as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label className="no-text-transform-li" row style={{ width:"85%", fontSize: 22, marginLeft:"85px", marginRight:"40px",fontWeight:"bold" ,whiteSpace: "pre-wrap",marginTop:"-10px", justifyContent:"-moz-initial" }}>
                                        <label style={{justifyContent:"center",  fontSize: 22,fontWeight:"bold" ,marginLeft:"-10px" }}>"</label>{this.state.quotedStatement}<label style={{ fontSize: 22,fontWeight:"bold"  }}>"</label>
                                    </Form.Label>
                                </Col>
                            </Form.Group>
                            {<Form.Group ref={ (insertedImgElement) => { this.insertedImgElement = insertedImgElement } } as={Row} controlId="formPlaintextEmail"style={{/* minWidth:"300px", *//*  fontWeight : "bold",fontSize: 22, pageBreakBefore:"always" , */justifyContent:"center",marginTop:"-15px", /* backgroundColor:"#ff00ff", */display:"flex",textAlign:"center",alignItems:"center" }}>
                                <Form.Label column sm="1" >
                                   {/*  <Col sm="5">      */}
                                       <Image 
                                            src={this.state.insertImage} 
                                            width={750}
                                            height={450}
                                            style={{  /* width:"900px", height:"110px", *//*  textAlign:"center", *//* justifyContent:"center", */  /* marginLeft:"60px", */}}
                                        />
                                 {/*    </Col> */}
                                </Form.Label>
                            </Form.Group>}
                            <Form.Group ref={ (addedOneElement) => { this.addedOneElement = addedOneElement } } as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap",marginTop:"-10px", }}>
                                        {this.state.addedparagraph1}
                                    </Form.Label>
                                </Col>
                            </Form.Group>

                            {this.state.articleDataList.length > 0 ?

                                this.state.articleDataList.map(x => <Form.Group  key={x.id }  className="mt-3">

                                <Col  sm="8" style={{  justifyContent:"center" ,textAlign:"center", marginLeft:"-40px", marginTop:"-10px",pageBreakBefore:"auto" ,pageBreakInside :"auto" ,pageBreakAfter:"auto"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22,/*  whiteSpace: "pre-wrap", */}}>
                                        { x.articleNo} &nbsp;&nbsp;&nbsp; {x.articleName}
                                    </Form.Label>
                                </Col>

                            </Form.Group>):null}
                            {this.state.sectionDataList.length > 0 ?
                            <Form.Group as={Row} controlId="formPlaintextEmail" /* className="mt-1" */ style={{marginLeft:"70px", marginTop:"-20px",pageBreakAfter:"auto" ,/* pageBreakInside :"auto" ,pageBreakAfter:"always" */}}>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",pageBreakAfter:"auto" ,/* pageBreakInside :"auto" ,pageBreakAfter:"always" */}}>
                                        SECTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",marginLeft:"30px",pageBreakAfter:"auto" ,/* pageBreakInside :"auto" ,pageBreakAfter:"always" */}}>
                                        INFRACTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",marginLeft:"70px",pageBreakAfter:"auto" ,/* pageBreakInside :"auto" ,pageBreakAfter:"always" */}}>
                                        CATEGORY
                                    </Form.Label>
                                </Col>
                            </Form.Group>:null}

                            {this.state.sectionDataList.length > 0 ?
                                this.state.sectionDataList.map(x => <Form.Group as={Row} key={x.id}  className="mt-3">

                                <Col style={{ textAlign:"center",pageBreakAfter:"auto" ,}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap", marginLeft:"-140px",marginTop:"-20px",/* pageBreakInside :"auto" ,pageBreakAfter:"always" */}}>
                                        {x.sectionNo}
                                    </Form.Label>
                                </Col>
                                <Col style={{textAlign:"center", marginLeft:"-100px" ,pageBreakAfter:"auto" ,}}>
                                    <Form.Label className="no-text-transform-li" row style={{fontSize: 22 , whiteSpace: "pre-wrap", minWidth:"550px", maxWidth:"550px",marginTop:"-20px",/* pageBreakInside :"auto" ,pageBreakAfter:"always" */}}>
                                        {x.infraction}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"75px", marginRight:"-125px",pageBreakAfter:"auto" ,}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap", marginTop:"-20px",/* pageBreakInside :"auto" ,pageBreakAfter:"always" */}}>
                                        {x.category}
                                    </Form.Label>
                                </Col>

                            </Form.Group>):null}

                            {/* *********************article 2************** */}
                            {this.state.articleDataList2.length > 0 ?
                                this.state.articleDataList2.map(x => <Form.Group key={x.id }  className="mt-3">

                                <Col sm="8" style={{  justifyContent:"center" ,textAlign:"center", marginLeft:"-40px", marginTop:"-10px",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22,/*  whiteSpace: "pre-wrap", */pageBreakBefore:"auto" ,}}>
                                        { x.articleNo} &nbsp;&nbsp;&nbsp; {x.articleName}
                                    </Form.Label>
                                </Col>

                            </Form.Group>):null}
                            {this.state.sectionDataList2.length > 0 ?
                            <Form.Group as={Row} controlId="formPlaintextEmail" /* className="mt-1" */ style={{marginLeft:"70px", marginTop:"-20px"}}>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",pageBreakBefore:"always" ,}}>
                                        SECTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",marginLeft:"30px",pageBreakBefore:"always" ,}}>
                                        INFRACTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",marginLeft:"70px",pageBreakBefore:"always" ,}}>
                                        CATEGORY
                                    </Form.Label>
                                </Col>
                            </Form.Group>:null}

                            {this.state.sectionDataList2.length > 0 ?
                                this.state.sectionDataList2.map(x => <Form.Group as={Row} key={x.id}  className="mt-3">

                                <Col style={{ textAlign:"center", pageBreakInside :"auto" ,pageBreakAfter:"auto"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap", marginLeft:"-140px",marginTop:"-20px",pageBreakBefore:"auto" ,pageBreakInside :"auto" , pageBreakAfter:"auto"}}>
                                        {x.sectionNo}
                                    </Form.Label>
                                </Col>
                                <Col style={{textAlign:"center", marginLeft:"-100px" ,pageBreakBefore:"auto" , pageBreakInside :"auto" ,}}>
                                    <Form.Label className="no-text-transform-li" row style={{fontSize: 22 , whiteSpace: "pre-wrap", minWidth:"550px", maxWidth:"550px",marginTop:"-20px",pageBreakBefore:"auto" ,pageBreakInside :"auto" ,pageBreakAfter:"always"}}>
                                        {x.infraction}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"75px", marginRight:"-125px",pageBreakBefore:"auto" ,pageBreakInside :"auto" ,pageBreakAfter:"auto"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap", marginTop:"-20px",pageBreakBefore:"auto" ,pageBreakInside :"auto" ,pageBreakAfter:"auto"}}>
                                        {x.category}
                                    </Form.Label>
                                </Col>

                            </Form.Group>):null}

                             {/* *********************article 3************** */}
                             {this.state.articleDataList3.length > 0 ?
                                this.state.articleDataList3.map(x => <Form.Group key={x.id }  className="mt-3">

                                <Col sm="8" style={{  justifyContent:"center" ,textAlign:"center", marginLeft:"-40px", marginTop:"-10px",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22,/*  whiteSpace: "pre-wrap", */pageBreakBefore:"auto" ,}}>
                                        { x.articleNo} &nbsp;&nbsp;&nbsp; {x.articleName}
                                    </Form.Label>
                                </Col>

                            </Form.Group>):null}
                            {this.state.sectionDataList3.length > 0 ?
                            <Form.Group as={Row} controlId="formPlaintextEmail" /* className="mt-1" */ style={{marginLeft:"70px", marginTop:"-20px"}}>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",pageBreakBefore:"auto" ,}}>
                                        SECTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",marginLeft:"30px",pageBreakBefore:"auto" ,}}>
                                        INFRACTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",marginLeft:"70px",pageBreakBefore:"auto" ,}}>
                                        CATEGORY
                                    </Form.Label>
                                </Col>
                            </Form.Group>:null}

                            {this.state.sectionDataList3.length > 0 ?
                                this.state.sectionDataList3.map(x => <Form.Group as={Row} key={x.id}  className="mt-3">

                                <Col style={{ textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap", marginLeft:"-140px",marginTop:"-20px",pageBreakBefore:"auto" ,}}>
                                        {x.sectionNo}
                                    </Form.Label>
                                </Col>
                                <Col style={{textAlign:"center", marginLeft:"-100px" }}>
                                    <Form.Label className="no-text-transform-li" row style={{fontSize: 22 , whiteSpace: "pre-wrap", minWidth:"550px", maxWidth:"550px",marginTop:"-20px",pageBreakBefore:"auto" ,}}>
                                        {x.infraction}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"75px", marginRight:"-125px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap", marginTop:"-20px",pageBreakBefore:"auto" ,}}>
                                        {x.category}
                                    </Form.Label>
                                </Col>

                            </Form.Group>):null}

                             {/* *********************article 4************** */}
                             {this.state.articleDataList4.length > 0 ?
                                this.state.articleDataList4.map(x => <Form.Group key={x.id }  className="mt-3">

                                <Col sm="8" style={{  justifyContent:"center" ,textAlign:"center", marginLeft:"-40px", marginTop:"-10px",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22,/*  whiteSpace: "pre-wrap", */pageBreakBefore:"auto" , }}>
                                        { x.articleNo} &nbsp;&nbsp;&nbsp; {x.articleName}
                                    </Form.Label>
                                </Col>

                            </Form.Group>):null}
                            {this.state.sectionDataList4.length > 0 ?
                            <Form.Group as={Row} controlId="formPlaintextEmail" /* className="mt-1" */ style={{marginLeft:"70px", marginTop:"-20px",pageBreakBefore:"auto" ,}}>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",pageBreakBefore:"auto" ,}}>
                                        SECTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",marginLeft:"30px",pageBreakBefore:"auto" ,}}>
                                        INFRACTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",marginLeft:"70px",pageBreakBefore:"auto" ,}}>
                                        CATEGORY
                                    </Form.Label>
                                </Col>
                            </Form.Group>:null}

                            {this.state.sectionDataList4.length > 0 ?
                                this.state.sectionDataList4.map(x => <Form.Group as={Row} key={x.id}  className="mt-3">

                                <Col style={{ textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap", marginLeft:"-140px",marginTop:"-20px",pageBreakBefore:"auto" ,}}>
                                        {x.sectionNo}
                                    </Form.Label>
                                </Col>
                                <Col style={{textAlign:"center", marginLeft:"-100px" }}>
                                    <Form.Label className="no-text-transform-li" row style={{fontSize: 22 , whiteSpace: "pre-wrap", minWidth:"550px", maxWidth:"550px",marginTop:"-20px",pageBreakBefore:"auto" ,}}>
                                        {x.infraction}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"75px", marginRight:"-125px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap", marginTop:"-20px",pageBreakBefore:"auto" ,}}>
                                        {x.category}
                                    </Form.Label>
                                </Col>

                            </Form.Group>):null}

                             {/* *********************article 5************** */}
                             {this.state.articleDataList5.length > 0 ?
                                this.state.articleDataList5.map(x => <Form.Group key={x.id }  className="mt-3">

                                <Col sm="8" style={{  justifyContent:"center" ,textAlign:"center", marginLeft:"-40px", marginTop:"-10px",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22,/*  whiteSpace: "pre-wrap", */pageBreakBefore:"auto" ,}}>
                                        { x.articleNo} &nbsp;&nbsp;&nbsp; {x.articleName}
                                    </Form.Label>
                                </Col>

                            </Form.Group>):null}
                            {this.state.sectionDataList5.length > 0 ?
                            <Form.Group as={Row} controlId="formPlaintextEmail" /* className="mt-1" */ style={{marginLeft:"70px", marginTop:"-20px"}}>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",pageBreakBefore:"auto" ,}}>
                                        SECTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",marginLeft:"30px",pageBreakBefore:"auto" ,}}>
                                        INFRACTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",marginLeft:"70px",pageBreakBefore:"auto" ,}}>
                                        CATEGORY
                                    </Form.Label>
                                </Col>
                            </Form.Group>:null}

                            {this.state.sectionDataList5.length > 0 ?
                                this.state.sectionDataList5.map(x => <Form.Group as={Row} key={x.id}  className="mt-3">

                                <Col style={{ textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap", marginLeft:"-140px",marginTop:"-20px",pageBreakBefore:"auto" ,}}>
                                        {x.sectionNo}
                                    </Form.Label>
                                </Col>
                                <Col style={{textAlign:"center", marginLeft:"-100px" }}>
                                    <Form.Label className="no-text-transform-li" row style={{fontSize: 22 , whiteSpace: "pre-wrap", minWidth:"550px", maxWidth:"550px",marginTop:"-20px",pageBreakBefore:"auto" ,}}>
                                        {x.infraction}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"75px", marginRight:"-125px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap", marginTop:"-20px",pageBreakBefore:"auto" ,}}>
                                        {x.category}
                                    </Form.Label>
                                </Col>

                            </Form.Group>):null}



                            {/* <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col sm="8" style={{ justifyContent:"center" ,textAlign:"center", marginLeft:"-60px", marginTop:"-10px",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22,}}>
                                        { this.state.articleNo3} &nbsp;&nbsp;&nbsp; {this.state.articleName3}
                                    </Form.Label>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"70px", marginTop:"-20px"}}>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",}}>
                                        SECTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",marginLeft:"30px",}}>
                                        INFRACTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",marginLeft:"70px",}}>
                                        CATEGORY
                                    </Form.Label>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col style={{ marginLeft:"45px", textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",}}>
                                        {this.state.sectionNo3}
                                    </Form.Label>
                                </Col>
                                <Col style={{textAlign:"center",marginRight:"20px", marginLeft:"-35px" }}>
                                    <Form.Label row style={{fontSize: 22 , whiteSpace: "pre-wrap",}}>
                                        {this.state.infraction3}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"100px", marginRight:"-70px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap", }}>
                                        {this.state.category3}
                                    </Form.Label>
                                </Col>
                            </Form.Group> */}

                           {/*  {this.state.addedparagraph2 !== "" ? */}
                                <Form.Group ref={ (twoAdElement) => { this.twoAdElement = twoAdElement } }  as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col >
                                    <Form.Label  className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap", }}>
                                        {this.state.addedparagraph2}
                                    </Form.Label>
                                </Col>
                            </Form.Group>{/* :null} */}

                            {/* {this.state.addedparagraph3 !== "" ? */}
                            <Form.Group as={Row} ref={ (threeAdElement) => { this.threeAdElement = threeAdElement } } controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap",pageBreakBefore:"auto" , }}>
                                        {this.state.addedparagraph3}
                                    </Form.Label>
                                </Col>
                            </Form.Group>{/* :null} */}

                           {/*  {this.state.addedparagraph4 !== "" ? */}
                                <Form.Group ref={ (fourAdElement) => { this.fourAdElement = fourAdElement } } as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap",pageBreakBefore:"auto" , }}>
                                        {this.state.addedparagraph4}
                                    </Form.Label>
                                </Col>
                            </Form.Group>{/* :null} */}

                            {/* {this.state.addedparagraph5 !== "" ? */}
                                <Form.Group ref={ (fiveAdElement) => { this.fiveAdElement = fiveAdElement } } as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap",pageBreakBefore:"auto" , }}>
                                            {this.state.addedparagraph5}
                                        </Form.Label>
                                    </Col>
                                </Form.Group>{/* :null
                            } */}

                           {/*  {this.state.addedparagraph6 !== "" ? */}
                                <Form.Group ref={ (sixAdElement) => { this.sixAdElement = sixAdElement } } as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap",pageBreakBefore:"auto" , }}>
                                            {this.state.addedparagraph6}
                                        </Form.Label>
                                    </Col>
                                </Form.Group>{/* :null
                            } */}

                            <Form.Group ref={ (prepardElement) => { this.prepardElement = prepardElement } } as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column style={{fontWeight : "bold",fontSize: 22,marginLeft:"40px", whiteSpace: "pre-wrap",pageBreakBefore:"always" ,marginTop:"-10px" , }}>
                                    Prepared By :
                                </Form.Label>
                            </Form.Group>

                            <Form.Group ref={ (prapredBySignElement) => { this.prapredBySignElement = prapredBySignElement } } as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 22, marginLeft:"20px",marginTop:"-23px"}}>
                                    <Col sm="5">
                                       <Image src={this.state.eSignaturePreparedBy} style={{width:"240px", height:"60px", textAlign:"center",justifyContent:"center",pageBreakBefore:"always" , }}/>
                                    </Col>
                                </Form.Label>
                            </Form.Group>

                            <Form.Group ref={ (preparedByNameElement) => { this.preparedByNameElement = preparedByNameElement } } as={Row} controlId="formPlaintextEmail">
                                <Form.Label column style={{fontWeight : "bold",fontSize: 22, marginTop:"-25px",marginLeft:"40px",pageBreakBefore:"always" ,}}>
                                    {this.state.preparedBy}
                                </Form.Label>
                            </Form.Group>
                            <Form.Group ref={ (preparedByPosiElement) => { this.preparedByPosiElement = preparedByPosiElement } } as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" style={{fontWeight : "bold",fontSize: 19, marginTop:"-19px", marginLeft:"40px",pageBreakBefore:"always" ,}}>
                                    {this.state.prepareByPosition}
                                </Form.Label>
                            </Form.Group>


                            <Form.Group ref={ (notedElement) => { this.notedElement = notedElement } } as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="1" style={{fontWeight : "bold",fontSize: 22,marginLeft:"40px",pageBreakBefore:"always" ,marginTop:"-10px",}}>
                                    Noted By :
                                </Form.Label>
                            </Form.Group>


                          {/*   <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 22, marginLeft:"40px"}}>
                                    <Col sm="10">

                                    </Col>
                                </Form.Label>
                            </Form.Group> */}

                            <Form.Group ref={ (notedBySignElement) => { this.notedBySignElement = notedBySignElement } } as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 22, marginLeft:"20px",marginTop:"-35px",pageBreakBefore:"always" ,}}>
                                    <Col sm="5">
                                       <Image src={this.state.eSignatureNotedBy} style={{width:"240px", height:"60px", textAlign:"center",justifyContent:"center", }}/>
                                    </Col>
                                </Form.Label>
                            </Form.Group>

                            <Form.Group ref={ (notedByNamelement) => { this.notedByNamelement = notedByNamelement } } as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" style={{fontWeight : "bold",fontSize: 22,marginLeft:"40px" , marginTop:"-30px",pageBreakBefore:"always" ,}}>
                                    {this.state.notedBy}
                                </Form.Label>
                            </Form.Group>

                            <Form.Group ref={ (notedPosiElement) => { this.notedPosiElement = notedPosiElement } } as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" style={{fontWeight : "bold",fontSize: 19, marginTop:"-19px", marginLeft:"40px",pageBreakBefore:"always" ,}}>
                                    {this.state.notedByPosition}
                                </Form.Label>
                            </Form.Group>

                            <Form.Group ref={ (receivedElement) => { this.receivedElement = receivedElement } } as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 22, marginLeft:"40px", }}>
                                    Received by :
                                </Form.Label>

                            </Form.Group>

                            <Form.Group ref={ (receivedNameElement) => { this.receivedNameElement = receivedNameElement } } as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 22, marginLeft:"40px",marginTop:"-15px",}}>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            name="employeeName"
                                            value={this.state.employeeName}
                                            //onChange={this.onChangePosition}
                                            autoComplete="off"
                                            //placeholder="Enter Name"
                                            style={{fontWeight:"bold",width:"500px",borderTop:"#ffffff", borderLeft:"#ffffff", borderRightColor:"#ffffff",borderRadius: "0",fontSize: 22,marginLeft:"-13px",borderBottomColor:"#000000"}}
                                        />
                                    </Col>
                                </Form.Label>
                            </Form.Group>


                            <Form.Group  ref={ (signOverNameElement) => { this.signOverNameElement = signOverNameElement } } as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 19,marginTop:"-20px" , marginLeft:"38px"}}>
                                    Sign over printed name / Date
                                </Form.Label>
                            </Form.Group>

                            <Form.Group ref={ (ccfileElement) => { this.ccfileElement = ccfileElement } } as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 19,marginTop:"-20px" , marginLeft:"38px"}}>
                                   cc:  201 File
                                </Form.Label>
                            </Form.Group>

                            {

                                <Image ref={ (footerElement) => { this.footerElement = footerElement } } src={paraFooter} style={this.state.footerTobtm}/>

                            }
                        </Form>


            </Container>
            <NoserLoading show={this.state.isloading} />

            <TrainingRegisterModal
                show={this.state.modalTrainingRegisterShow}
                onHide={this.handleModalClose}
                onRefModal={ref => (this.child = ref)}
            />

            </>

        )
    }

}


class ExportNTE extends React.Component {
    render() {
      return (
        <div>

            <ComponentToPrint ref={(el) => (this.componentRef = el)} />
            <Col sm="3" className="mt-3" as={Row} style={{marginLeft:"10px"}}>
                <ReactToPrint
                    trigger={() => <button>Print this out!</button>}
                    content={() => this.componentRef}
                />
                 <ButtonToolbar sm={12}>
                    <NavLink to="/EmployeeDiciplineMonitoring">
                        <Button variant="danger" href="/EmployeeDiciplineMonitoring" style={{minWidth:'60px',marginLeft:"10px"}} onClick={this.setBack}>
                            Close
                        </Button>
                    </NavLink>
                </ButtonToolbar>
            </Col>

            <Col sm="3" className="mt-3" as={Row} >

            </Col>

            <Col sm="3" className="mt-3" as={Row} >

            </Col>
        </div>
      );
    }
  }

export default ExportNTE
